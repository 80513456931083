import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import Routing from 'routing';
import Banner from 'components/header/banner/banner';
import Holding from 'components/header/holding/holding';
import NavBar from 'components/header/navBar/navBar';

const Header = ({
  permission,
  resetErrorBoundaries,
  setModal,
  setPermission
}) => {
  const { pathname } = useLocation();
  const isLoggedIn = permissions.isLoggedIn();

  const [showNav, setShowNav] = useState(false);

  return (
    <>
      {isLoggedIn &&
        <Banner
          setShowNav={setShowNav}
          showNav={showNav}
        />
      }

      <NavBar
        permission={permission}
        resetErrorBoundaries={resetErrorBoundaries}
        setShowNav={setShowNav}
        showNav={showNav}
      />

      {permissions.isNonBO() && !permissions.isRCVSVet() && !pathname.includes(Routing.home) &&
      !pathname.includes(Routing.abattoirAttestationDeclaration) && !pathname.includes(Routing.abattoirAttestationDeclarationTags) &&
        <Holding
          setModal={setModal}
          setPermission={setPermission}
        />
      }
    </>
  );
};

Header.propTypes = {
  permission: PropTypes.string.isRequired,
  resetErrorBoundaries: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func.isRequired
};

export default Header;
