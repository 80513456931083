import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from 'frontEnd/holdingSummary/holdingSummary.module.scss';
import navigation from 'services/navigation';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Table from 'components/table/table';
const sprintf = require('util').format;

const HoldingSummary = ({
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const speciesName = storeService.session.get.species().name;
  const cph = storeService.session.get.holding().value;

  const [data, setData] = React.useState([]);
  const [loadPending, setLoadPending] = React.useState(true);

  const tableParams = {
    request: {
      url: '/notifications',
      params: {
        cph,
        speciesName
      },
      processData: (data) => data.map((row) => ({
        ...row,
        actionRequired: t('handshake.tableDefaultAction'),
        summary: sprintf(
          '%d %s %s %s',
          row.animalTotal,
          row.species.toLowerCase(),
          t(cph === row.destinationHolding ? 'label.ontoHolding' : 'label.offHolding'),
          cph === row.destinationHolding ? row.sourceHolding : row.destinationHolding
        ),
        reference: row.requestId
      }))
    }
  };

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.keeperHolding.length) !== Routing.keeperHolding &&
        path.pathname.substring(0, Routing.handshake.length) !== Routing.handshake
      ) {
        storeService.session.removeAll.tableFilters();
        unListen();
      }
    });
  }, [history]);

  return (
    <>
      {ready && !permissions.isBackOffice() &&
        <>
          <h1 className="h1">{t('menu.holdingSummary')}</h1>
          <AboutPanel
            descriptions={[
              { text: 'about.holdingSummary.desc1' }
            ]}
            title="about.holdingSummary.title"
          />

          {data.length > 0 &&
            <div className={classes.notificationsWarning}>
              <i className={`bi bi-exclamation-circle-fill ${classes.icon}`} />
              <span className={classes.text}>{t('label.notificationsRequiringAction')}</span>
            </div>
          }

          <Table
            columns={tableColumns.holdingSummary}
            data={data}
            initialSortBy={initialSorting.holdingSummary.accessor}
            initialSortDirection={initialSorting.holdingSummary.direction}
            loadPending={loadPending}
            noDataIsError={false}
            noDataLabel="label.noOutstandingNotifications"
            params={tableParams}
            rowLinks={{
              url: () => navigation.handshakeDetails(),
              accessor: 'requestId'
            }}
            setData={setData}
            setLoadPending={setLoadPending}
            setModal={setModal}
            store={{
              filters: 'HoldingSummary',
              data: 'HoldingSummary'
            }}
          />
        </>
      }
    </>
  );
};

HoldingSummary.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default HoldingSummary;
