import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import helpers from 'services/helpers';
import classes from 'components/base/dropdownSearch/dropdownSearch.module.scss';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectSearch from 'react-select-search/dist/cjs';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import Hint from 'components/base/hint/hint';

const DropdownSearch = ({
  className,
  disabled,
  error,
  hint,
  id,
  isSearchable,
  isSearchEnabled,
  isSelect,
  label,
  name,
  onChange,
  optional,
  optionList,
  pending,
  pendingError,
  placeholder,
  tooltip,
  value,
  warning
}) => {
  const { ready, t } = useTranslation();

  const [valueObject, setValueObject] = React.useState(null);

  const selectClassName = (pending || optionList.length === 0) ? 'select-search pending' : 'select-search';

  const onChangeHandler = (selectedValue) => {
    const newEvent = {
      target: {
        name: name,
        type: 'select',
        value: selectedValue.value
      }
    };
    onChange(newEvent);
  };

  const onSelectSearchChangeHandler = (selectedValue) => {
    const newEvent = {
      target: {
        name: name,
        type: 'select',
        value: selectedValue
      }
    };
    onChange(newEvent);
  };

  const formatGroupLabel = (data) => {
    return (
      <div className={classes.groupLabel}>
        <span>{data.label}</span>
        <span className={classes.groupBadge}>{data.options.length}</span>
      </div>
    );
  };

  const getPlaceholder = () => {
    if (pending) {
      return t('label.fetchingData');
    }

    if (optionList.length === 0) {
      return t(pendingError);
    }

    return t(placeholder);
  };

  useEffect(() => {
    if (optionList.length > 0) {
      setValueObject(value);

      // let matchingOption;
      //
      // if (typeof value === 'object') {
      //   setValueObject(value);
      // } else if (typeof value === 'string') {
      //   matchingOption = optionList.find((item) => item.value === value);
      //
      //   if (!matchingOption && optionList[1] && optionList[1].items) {
      //     matchingOption = optionList[1].items.find((item) => item.value === value);
      //   }
      //
      //   if (!matchingOption && optionList[0] && optionList[0].items) {
      //     matchingOption = optionList[0].items.find((item) => item.value === value);
      //   }
      //
      //   if (!matchingOption && optionList[1] && optionList[1].options) {
      //     matchingOption = optionList[1].options.find((item) => item.value === value);
      //   }
      //
      //   if (!matchingOption && optionList[0] && optionList[0].options) {
      //     matchingOption = optionList[0].options.find((item) => item.value === value);
      //   }
      //
      //   if (matchingOption) {
      //     setValueObject(matchingOption);
      //   }
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, optionList]);

  return (
    <>
      {ready &&
        <>
          <FieldLabel
            label={label}
            name={id}
            optional={optional}
            tooltip={tooltip}
          />
          <Hint
            hint={hint}
            tooltip={tooltip}
          />
          <ErrorLabel
            label={error}
          />
          <ErrorLabel
            isWarning={true}
            label={warning}
          />

          {isSelect &&
            <div className={classes[className]}>
              <Select
                classNamePrefix="react-select"
                closeOnSelect={true}
                formatGroupLabel={formatGroupLabel}
                id={id}
                inputId={id}
                isSearchable={isSearchable}
                name={name}
                onChange={onChangeHandler}
                options={optionList}
                placeholder={t(placeholder)}
                value={valueObject}
              />
            </div>
          }
          {!isSelect &&
            <div className={classes[className]}>
              <SelectSearch
                className={selectClassName}
                closeOnSelect={true}
                disabled={disabled || pending || optionList.length === 0}
                filterOptions={helpers.dropdownSearch}
                id={id}
                inputId={id}
                name={name}
                onChange={onSelectSearchChangeHandler}
                onFocus={() => helpers.resetDropdownFocusState()}
                options={optionList}
                placeholder={getPlaceholder()}
                search = {isSearchEnabled !== undefined && isSearchEnabled !== null ? isSearchEnabled : true}
                value={valueObject}
              />
            </div>
          }
        </>
      }
    </>
  );
};

DropdownSearch.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string,
  isSearchable: PropTypes.bool,
  isSelect: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  optionList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.exact({
        type: PropTypes.string,
        name: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.exact({
            id: PropTypes.number,
            label: PropTypes.string,
            name: PropTypes.string,
            value: PropTypes.string
          })
        )
      })
    ),
    PropTypes.arrayOf(
      PropTypes.exact({
        type: PropTypes.string,
        label: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.exact({
            label: PropTypes.string,
            name: PropTypes.string,
            value: PropTypes.string
          })
        )
      })
    ),
    PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      })
    ),
    PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
        isSystemApplied: PropTypes.bool
      })
    )
  ]),
  optional: PropTypes.bool,
  pending: PropTypes.bool,
  pendingError: PropTypes.string,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.exact({
      label: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  ]),
  warning: PropTypes.string
};

export default DropdownSearch;
