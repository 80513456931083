import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import constants from 'services/constants';
import helpers from 'services/helpers';
import storeService from 'services/storeService';
import PropTypes from 'prop-types';
import Routing from 'routing';
import { initialSorting } from 'services/tableColumns';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';
import Table from 'components/table/table';
import TabsNavigational from 'components/base/tabsNavigational/tabsNavigational';

const BoSearchMovements = ({
  setModal
}) => {
  const { pathname } = useLocation();
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionMovementSearch = storeService.session.get.searchResultsMovements()?.data;

  const [data, setData] = React.useState(sessionMovementSearch ? sessionMovementSearch : []);
  const [renderTable, setRenderTable] = React.useState(false);

  const paginationParams = constants.pagination.defaultParams;
  paginationParams[constants.pagination.bottom].total = data?.length;

  const tableParams = {
    request: {}
  };

  const handleChanges = {
    tab: (id) => {
      setData([]);
      setRenderTable(false);

      storeService.session.set.activeTabId(id);
      storeService.session.removeAll.tableFilters();
      storeService.session.removeAll.searchResults();
    }
  };

  const render = {
    table: () => {
      return (
        <Table
          data={data}
          dataProvided={true}
          filtersComponents={{
            [constants.filters.outside]: 'BoSearchMovements'
          }}
          initialSortBy={initialSorting.bo.searchMovements.accessor}
          initialSortDirection={initialSorting.bo.searchMovements.direction}
          paginationParams={paginationParams}
          params={tableParams}
          renderTable={renderTable}
          rowLinks={{
            url: () => Routing.boMovementDetails,
            accessor: 'id',
            hint: (row) => t(`movementDetails.searchTable.confirmationStatus.${helpers.status.getType(t, row)}`),
            state: (row) => ({
              data: row
            })
          }}
          setData={setData}
          setModal={setModal}
          setRenderTable={setRenderTable}
          store={{
            filters: 'Movements',
            data: 'Movements'
          }}
        />
      );
    }
  };

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.boSearchMovements.length) !== Routing.boSearchMovements &&
        path.pathname.substring(0, Routing.boMovements.length) !== Routing.boMovements &&
        path.pathname.substring(0, Routing.boMovementDetails.length) !== Routing.boMovementDetails &&
        path.pathname.substring(0, Routing.boEditMovements.length) !== Routing.boEditMovements
      ) {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
        storeService.session.remove.activeTabId();
        unListen();
      }
    });
  }, [history]);

  useEffect(() => {
    const sessionActiveTabId = storeService.session.get.activeTabId();

    if (!sessionActiveTabId) {
      storeService.session.set.activeTabId(constants.tabs.movementRange);
    } else if (helpers.tab.isMovementRange(sessionActiveTabId)) {
      history.push(Routing.boSearchMovementsByDateAndCph);
    } else if (helpers.tab.isMovementRef(sessionActiveTabId)) {
      history.push(Routing.boSearchMovementsByMovementReference);
    } else if (helpers.tab.isPaperId(sessionActiveTabId)) {
      history.push(Routing.boSearchMovementsByPaperId);
    }
  }, [history]);

  return (
    <>
      {ready &&
        <>
          <PageTitle
            id="searchMovementsTitle"
            pageTitleOne="boApp.pageTitles.search.search"
            pageTitleTwo="boApp.pageTitles.search.movements"
          />
          <div className="pageContent">
            <SectionTitleBar
              icon="bi bi-search"
              id="searchMovementsTitleBar"
              title="boApp.pageTitles.movements.searchMovements"
            />

            <TabsNavigational>
              <div active={pathname === Routing.boSearchMovementsByDateAndCph} id={constants.tabs.movementRange} label={t('label.searchByDateAndCph')} onClick={handleChanges.tab} url={Routing.boSearchMovementsByDateAndCph}>
                {pathname === Routing.boSearchMovementsByDateAndCph ? render.table() : ''}
              </div>
              <div active={pathname === Routing.boSearchMovementsByMovementReference} id={constants.tabs.movementRef} label={t('label.searchMovementRef')} onClick={handleChanges.tab} url={Routing.boSearchMovementsByMovementReference}>
                {pathname === Routing.boSearchMovementsByMovementReference ? render.table() : ''}
              </div>
              <div active={pathname === Routing.boSearchMovementsByPaperId} id={constants.tabs.paperId} label={t('label.searchPaperMovementId')} onClick={handleChanges.tab} url={Routing.boSearchMovementsByPaperId}>
                {pathname === Routing.boSearchMovementsByPaperId ? render.table() : ''}
              </div>
            </TabsNavigational>
          </div>
        </>
      }
    </>
  );
};

BoSearchMovements.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default BoSearchMovements;
