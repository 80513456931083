import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import bff, { bffStatus, bffResponse, isCancel } from 'services/bff';
import classes from 'frontEnd/viewMovement/viewMovement.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import navigation from 'services/navigation';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import AddressSummary from 'components/summary/addressSummary/addressSummary';
import AnimalSummary from 'components/summary/animalSummary/animalSummary';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import DateSummary from 'components/summary/dateSummary/dateSummary';
import ExemptionSummary from 'components/summary/exemptionSummary/exemptionSummary';
import FciSummary from 'components/summary/fciSummary/fciSummary';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import PDFButton from 'components/pdfButton/pdfButton';
import TransportSummary from 'components/summary/transportSummary/transportSummary';

const ViewMovement = ({
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const currentHolding = storeService.session.get.holding().value;
  const species = storeService.session.get.species();
  const role = storeService.session.get.permissions();
  const holding = storeService.session.get.holding();

  const [data, setData] = React.useState({});
  const [searchState, setSearchState] = React.useState({});
  const [doc, setDoc] = React.useState(null);
  const [totalBatchAnimals, setTotalBatchAnimals] = React.useState(0);
  const [totalIndividualAnimals, setTotalIndividualAnimals] = React.useState(0);
  const [transportInformation, setTransportInformation] = React.useState({ recordTransportInformation: constants.option.recordTransportInformation.no });
  const [departAddress, setDepartAddress] = React.useState({});
  const [destAddress, setDestAddress] = React.useState({});
  const [printData, setPrintData] = React.useState(null);
  const [fciData, setFciData] = React.useState({});
  const [processingFlags, setProcessingFlags] = React.useState({ exempt: '', exemptions: [] });
  const [metFCIWithdrawalPeriods, setMetFCIWithdrawalPeriods] = React.useState();
  const [arrivalDate, setArrivalDate] = React.useState(null);
  const [arrivalDateError, setArrivalDateError] = React.useState(null);
  const [movementType, setMovementType] = React.useState(null);
  const [movementRef, setMovementRef] = React.useState(null);
  const [pageErrors, setPageErrors] = React.useState([]);
  const [pending, setPending] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [pollingStatus, setPollingStatus] = React.useState(null);
  const [submitConfirmedMove, setSubmitConfirmedMove] = React.useState(null);
  const [showRejectModal, setShowRejectModal] = React.useState(false);
  const [requestId, setRequestId] = React.useState(null);

  const processExemptions = (processingFlags, processExemptions) => {
    const exemptions = processingFlags.map((o) => ({
      id: o.processingFlagId,
      label: helpers.extract.processingLabel(processExemptions, o.processingFlagId),
      value: true
    }));
    const all = processExemptions.map((o) => ({
      id: o.id,
      label: o.label,
      value: helpers.extract.processingStatus(processingFlags, o.id)
    }));
    const savedProcessingFlags = {
      exempt: 'yes',
      exemptions,
      all
    };
    setProcessingFlags(savedProcessingFlags);
    storeService.session.set.movementExemptions(exemptions);
  };

  const confirmation = {
    button: {
      returnToPrevious: () => ({
        id: 'returnPrevious',
        label: 'button.return',
        onClick: () => history.push({
          pathname: navigation.search(),
          state: {
            ...searchState,
            dataChanged: true
          }
        })
      }),

      viewSummary: (requestId, requestType) => ({
        id: 'viewSummary',
        label: 'button.viewSummary',
        onClick: () => {
          setPermission(userRoleType.GENERIC);
          storeService.session.set.permissions(userRoleType.GENERIC);
          storeService.session.remove.holding();
          setTimeout(() => {
            history.push({
              pathname: Routing.activityDetails + requestId,
              state: {
                data: {
                  requestId,
                  requestType,
                  current: {
                    returnUrl: permissions.isGathering ? Routing.marketSearch : Routing.userActivity,
                    permissions: role,
                    holding
                  }
                }
              }
            });
          }, [100]);
        }
      })
    },

    link: {
      viewMovementDocument: (speciesId, ref) => ({
        label: helpers.species.isSheepId(speciesId) || helpers.species.isGoatId(speciesId) ? 'button.viewMovementDocument' : null,
        param: ref,
        type: constants.linkTypes.pdfButton
      })
    }
  };

  const processData = (cancelToken, inValue) => {
    storeService.session.set.movement(inValue);

    setData((prevState) => ({
      ...prevState,
      ...inValue
    }));
    setDoc(inValue.movementDocument);

    if ((currentHolding === inValue.sourceHolding || currentHolding === inValue.fromHolding) && inValue?.movementDocument?.destinationDetail?.arrivalDateTime) {
      setArrivalDate(inValue.movementDocument.destinationDetail.arrivalDateTime);
    }

    if (inValue.devices) {
      setTotalIndividualAnimals(inValue.devices?.length);
    }

    const batchesTotal = inValue.batches ? inValue.batches.reduce((total, batch) => total + batch.animalTotal, 0) : 0;
    setTotalBatchAnimals(batchesTotal);
    setTransportInfo(inValue.movementDocument);

    const processingFlags = helpers.extract.processingFlags(inValue);
    if (processingFlags?.length > 0) {
      helpers.get.processingFlags(cancelToken, constants.option.requestType.movement, inValue.speciesId ? inValue.speciesId : helpers.species.nameToId(inValue.species))
        .then((flagRes) => {
          if (helpers.response.isValid(flagRes.data, setModal)) {
            processExemptions(processingFlags, flagRes.data);
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            errors.BFF(error, setModal);
          }
        });
    } else {
      setProcessingFlags({ exempt: 'yes', exemptions: [] });
    }

    setPending(false);
  };

  const setTransportInfo = (inValue) => {
    const getTransportedBy = (transportInfo) => {
      let transportedBy = '';

      if (transportInfo.transporterType) {
        transportedBy = helpers.lookup.transporterType(transportInfo.transporterType);
      } else {
        transportedBy = typeof transportInfo.transporter === 'string' ? transportInfo.transporter.toLowerCase() : helpers.lookup.transportedBy(transportInfo.transporterTypeId);
      }

      return transportedBy;
    };

    if (inValue && inValue.transportDetail) {
      const tInfo = inValue.transportDetail;
      if (
        tInfo.transportAuthNumber?.length ||
        tInfo.transportVehicleRegistrationNo?.length ||
        tInfo.transporterTypeId
      ) {
        setTransportInformation({
          contactPhone: (tInfo.transporter) ? tInfo.transporter.telephoneNumber : '',
          departureDateTime: (inValue.departureDetail) ? inValue.departureDetail.departureDateTime : '',
          durationOfJourney: (inValue.departureDetail) ? inValue.departureDetail.expectedDurationOfJourney : '',
          recordTransportInformation: constants.option.recordTransportInformation.yes,
          transportAuthNumber: tInfo.transporterAuthNumber,
          transportedBy: getTransportedBy(tInfo),
          transporter: tInfo.transporter,
          vehicleRegistrationNumber: tInfo.transportVehicleRegistrationNo
        });
      }
    }
  };

  const postHandshake = (outData) => {
    bff
      .post('/handshake', outData)
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          const { data } = res;
          const storedFilters = storeService.session.get.tableFiltersGatheringSearch();

          storeService.session.set.tableFiltersGatheringSearch({
            ...storedFilters,
            newRequest: true
          });

          storeService.session.removeAll.searchResults();
          storeService.session.remove.dataChanged();

          setRequestId(data.requestId);
          setPollingStatus(bffResponse.status(data));
          setPageErrors(bffResponse.errors(data));
          setMovementRef(bffResponse.movementRef(data));

          setPending(false);
          setShowRejectModal(false);
        }
      })
      .catch((error) => {
        setPageErrors([{
          code: error?.response?.status !== 400 ? error?.response?.status : helpers.error.mapCode(error?.response?.data?.errorMessage),
          message: helpers.error.mapping(error?.response?.data?.errorMessage)
        }]);
        setPending(false);
        setShowRejectModal(false);
      });
  };

  const confirmMovement = () => {
    setSubmitted(true);
    setPending(true);
    setSubmitConfirmedMove(true);
    setPageErrors([]);

    const recordedByDestination = data.userHolding === data.destinationHolding;
    const tempData = cloneDeep(data);

    tempData.reviewBatches = tempData.batches.map((batch) => ({
      animalTotal: batch.animalTotal,
      batchNumber: batch.batchNumber,
      batchMovementId: batch.batchMovementId
    }));
    tempData.reviewDevices = tempData.animalsBeingMoved.map((device) => ({ tagNumber: device.tagNumber }));
    tempData.removedBatches = [];
    tempData.removedDevices = [];

    const outData = {
      poll: config.POLLS_ENABLED,
      requestId: data.requestId,
      isAccepted: true,
      data: {
        ...tempData,
        arrivalDate,
        cph: recordedByDestination ? (data.sourceHolding || data.fromHolding) : (data.destinationHolding || data.toHolding)
      }
    };

    delete outData.data.arrivalDateTime;

    if (recordedByDestination) {
      delete outData.data.arrivalDate;
    }

    delete outData.data.batches;
    delete outData.data.devices;
    delete outData.data.animalsBeingMoved;

    postHandshake(outData);
  };

  const rejectMovement = () => {
    setSubmitted(true);
    setPending(true);
    setSubmitConfirmedMove(false);
    setPageErrors([]);

    const recordedByDestination = data.userHolding === data.destinationHolding;
    const tempData = cloneDeep(data);

    tempData.reviewBatches = [];
    tempData.reviewDevices = [];
    tempData.removedBatches = tempData.batches.map((batch) => ({
      animalTotal: batch.animalTotal,
      batchNumber: batch.batchNumber,
      batchMovementId: batch.batchMovementId
    }));
    tempData.removedDevices = tempData.animalsBeingMoved.map((device) => ({ tagNumber: device.tagNumber }));

    const outData = {
      poll: config.POLLS_ENABLED,
      requestId: data.requestId,
      isAccepted: false,
      data: {
        ...tempData,
        cph: recordedByDestination ? (data.sourceHolding || data.fromHolding) : (data.destinationHolding || data.toHolding)
      }
    };

    if (recordedByDestination) {
      delete outData.arrivalDate;
    }

    delete outData.data.batches;
    delete outData.data.devices;
    delete outData.data.animalsBeingMoved;

    postHandshake(outData);
  };

  const fetchMovementData = (cancelToken1, cancelToken2, data) => {
    bff
      .get('/movementDetails', {
        cancelToken: cancelToken1,
        params: {
          getAnimals: 'true',
          documentRef: bffStatus.isUnconfirmed(data.status) ? data.documentRef : data.movementDocument.movementDocumentRef,
          species: species.name,
          status: data.status
        }
      })
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          if (bffStatus.isUnconfirmed(data.status)) {
            setMovementType(currentHolding === data.sourceHolding || currentHolding === data.fromHolding ? constants.option.movement.off : constants.option.movement.on);
          } else {
            setMovementType(currentHolding === data.sourceHolding ? constants.option.movement.off : constants.option.movement.on);
          }

          if (res?.data?.length > 0) {
            const firstData = res.data[0];
            const dataToProcess = {
              ...data,
              processingFlags: helpers.extract.processingFlags(firstData),
              movementDocument: firstData.movementDocument
            };

            if (bffStatus.isReported(data.status)) {
              dataToProcess.batches = firstData.batches;
              dataToProcess.devices = firstData.devices;
            }

            processData(cancelToken2, dataToProcess);
          } else {
            processData(cancelToken2, data);
          }
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });
  };

  const hasFciDetail = (document) => {
    const fciDetail = document && document.fciDetail ? document.fciDetail : null;
    return (fciDetail &&
      (
        fciDetail.isAllAnimalsFCICompliant ||
        fciDetail.nonCompliantReason ||
        fciDetail.withdrawalPeriodObservedText ||
        fciDetail.holdingRestrictions ||
        fciDetail.withdrawalPeriodMetId ||
        fciDetail.nonCompliantDevices?.length > 0
      )
    );
  };

  const downloadAnimalList = () => {
    const movementDate = data?.transferDate ? data.transferDate : data?.movementDate;
    const filename = `${data.movementDocument?.movementDocumentRef}-${helpers.date.formatDDMMYYYY(movementDate)}`;
    const individualTags = data?.devices;
    const individualTagNumbers = individualTags?.map((individualTag) => ({ [t('label.tagNumber')]: individualTag.tagNumber })).sort();

    individualTagNumbers.push({ [t('label.tagNumber')]: `${t('label.totalAnimals')}: ${individualTagNumbers.length}` });

    const batches = data?.batches.map((batch) => (
      {
        [t('label.batchNumber')]: batch.batchNumber,
        Quantity: batch.animalTotal
      })
    );

    const totalBatchAnimals = batches.reduce((acc, batch) => acc + batch.Quantity, 0);
    batches.push({ [t('label.batchNumber')]: `${t('label.totalBatches')}: ${batches.length}` });
    batches.push({ [t('label.batchNumber')]: `${t('label.totalAnimals')}: ${totalBatchAnimals}` });

    const animalList = {
      [t('label.individualTagNumbers')]: individualTagNumbers,
      Batches: batches
    };

    const workbook = helpers.generateWorkbook(animalList);
    helpers.writeWorkbookToFile(workbook, filename, 'ods');
  };

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    if (location.state?.data && location.state.data.isPreMovement !== undefined && location.state.data.movementPendingId !== undefined && location.state.data.movementId !== undefined) {
      bff
        .get('/movementAnimals', {
          cancelToken: source.token,
          params: {
            isPreMovement: location.state.data.isPreMovement,
            movementPendingId: location.state.data.movementPendingId,
            movementId: location.state.data.movementId
          }
        })
        .then((res) => {
          setData((prevState) => ({
            ...prevState,
            animalsBeingMoved: res.data.data.devices,
            batches: res.data.data.batches
          }));
        })
        .catch((error) => {
          if (!isCancel(error)) {
            errors.BFF(error, setModal);
          }
        });
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const source1 = axios.CancelToken.source();
    const source2 = axios.CancelToken.source();
    const source3 = axios.CancelToken.source();

    setSearchState(location.state.searchState);

    helpers.get.metFciWithdrawalPeriods(source1.token)
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          setMetFCIWithdrawalPeriods(res.data);
        }
      });

    fetchMovementData(source2.token, source3.token, location.state.data);

    return () => {
      source1.cancel();
      source2.cancel();
      source3.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    helpers.scrollToTop();
  }, []);

  React.useEffect(() => {
    if (data?.id && departAddress && destAddress && processingFlags?.exempt === 'yes') {
      setPrintData({
        ...data,
        departAddress,
        destAddress,
        totalBatchAnimals,
        totalIndividualAnimals,
        processingFlags,
        transportInformation,
        metFCIWithdrawalPeriods,
        fciData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, departAddress, destAddress, processingFlags, metFCIWithdrawalPeriods, fciData]);

  React.useEffect(() => {
    if (doc && doc.fciDetail) {
      setFciData({
        animalsSatisfy: doc.fciDetail.isAllAnimalsFCICompliant ? constants.option.animalsSatisfy.doSatisfy : constants.option.animalsSatisfy.doNotSatisfy,
        holdingRestrictions: doc.fciDetail.holdingRestrictions,
        id: doc.fciDetail.id, // Not currently used
        nonComplianceReason: doc.fciDetail.nonCompliantReason,
        recordFCI: constants.option.recordFCI.yes,
        withdrawalPeriod: metFCIWithdrawalPeriods ? helpers.extract.metFCIWithdrawalPeriod(metFCIWithdrawalPeriods, doc.fciDetail.withdrawalPeriodMetId) : '',
        unfitAnimals: doc.fciDetail.nonCompliantDevices
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc, metFCIWithdrawalPeriods]);

  let tagOnly = false;
  let devices = [];
  if (data.animalsBeingMoved) {
    devices = data.animalsBeingMoved;
  } else if (data.devices) {
    devices = data.devices;
    tagOnly = true;
  }

  return (
    <>
      {ready &&
        <>
          {(!submitted ||
            (
              submitted &&
              !pending &&
              pageErrors?.length > 0
            )
          ) &&
            <>
              {submitted && !pending && pageErrors?.length > 0 && // Submission error
                <Confirmation
                  errors={pageErrors}
                  label="label.submissionError"
                  setModal={setModal}
                  status={constants.status.error}
                />
              }

              {showRejectModal &&
                <ModalConfirm
                  id="confirmReject"
                  modalClose={() => setShowRejectModal(false)}
                  modalConfirm={rejectMovement}
                  modalMessage="warning.handshakeRejectConfirm"
                  modalTitle="label.rejectMovement"
                />
              }

              {data &&
                <>
                  <h1 className="h1">{t('label.movementRef')}: {data?.movementDocument?.movementDocumentRef ? data.movementDocument.movementDocumentRef : data.documentRef}</h1>
                  <h2 className="h2">{t('label.movementDetails')}</h2>

                  <DateSummary
                    confirmMode={helpers.option.movement.isOn(movementType) && bffStatus.isUnconfirmed(data.status)}
                    date1={data?.movementDocument?.departureDetail?.departureDateTime ? data.movementDocument.departureDetail.departureDateTime : data.movementDate}
                    date2={data?.movementDocument?.destinationDetail?.arrivalDateTime ? data.movementDocument.destinationDetail.arrivalDateTime : arrivalDate}
                    date2Error={arrivalDateError}
                    label="label.movementDate"
                    restrictArrivalDate={true}
                    setDate2={setArrivalDate}
                    setDate2Error={setArrivalDateError}
                    showBothDates={true}
                  />

                  <AddressSummary
                    info1={data.sourceHolding ? data.sourceHolding : data.fromHolding}
                    info2={data.destinationHolding ? data.destinationHolding : data.toHolding}
                    isGathering={true}
                    label="movementDetails.searchResults.movementDirection"
                    label1="movementDetails.searchResults.fromHolding"
                    label2="movementDetails.searchResults.toHolding"
                    queryKeeper={true}
                    setAddress1={setDepartAddress}
                    setAddress2={setDestAddress}
                    setModal={setModal}
                  />

                  <ExemptionSummary
                    data={processingFlags}
                    label="movementDetails.searchResults.typeExemptions"
                  />

                  {(data.batches || devices) &&
                    <AnimalSummary
                      animalsData={{
                        batchList: data.batches,
                        animalsBeingMoved: devices
                      }}
                      batchesDataProp="batches"
                      label="label.animalsInTotal"
                      numberBeingMoved={totalBatchAnimals + devices.length}
                      setAnimalsData={setData}
                      setModal={setModal}
                      tagOnly={tagOnly}
                    />
                  }

                  <FciSummary
                    animalsData={{
                      batches: [],
                      animalsBeingMoved: []
                    }}
                    fciData={hasFciDetail(doc) ? fciData : {}}
                    numberBeingMoved={totalBatchAnimals + devices.length}
                  />
                  {transportInformation &&
                    <TransportSummary
                      label="movementDetails.searchResults.transportInformation"
                      transportInformation={transportInformation}
                    />
                  }

                  <div className={classes.actions}>
                    <div className={classes.accept}>
                      {bffStatus.isUnconfirmed(data.status) &&
                        <Button
                          buttonType="primary"
                          disabled={helpers.option.movement.isOn(movementType) && (!arrivalDate || arrivalDateError !== '')}
                          id="confirmButton"
                          label="button.confirmMovement"
                          onClick={confirmMovement}
                        />
                      }
                      {!helpers.tab.isMovementRef(data.activeTabId) && !bffStatus.isUnconfirmed(data.status) && data.requestId &&
                        <PDFButton
                          referenceId={printData?.movementDocument?.movementDocumentRef ? printData.movementDocument.movementDocumentRef : null}
                          setModal={setModal}
                        />
                      }
                    </div>
                    {!bffStatus.isUnconfirmed(data.status) &&
                      <div className={classes.download}>
                        <Button
                          buttonType="tertiary"
                          label="button.downloadAnimalList"
                          onClick={downloadAnimalList}
                        />
                      </div>
                    }
                    {!helpers.tab.isMovementRef(data.activeTabId) && !bffStatus.isReported(data.status) && data.requestId &&
                      <div className={classes.reject}>
                        <Button
                          buttonType="danger"
                          disabled={pending}
                          label="button.rejectMovement"
                          onClick={() => setShowRejectModal(true)}
                        />
                      </div>
                    }
                    <div className={classes.exitBtn}>
                      <Button
                        buttonType="tertiary"
                        id="returnButton"
                        label="button.return"
                        onClick={() => history.push({ pathname: navigation.search(), state: searchState })}
                      />
                    </div>
                  </div>
                </>
              }
            </>
          }

          {submitted && pending &&
            <Confirmation
              label="label.submissionPending"
              setModal={setModal}
              status={constants.status.pending}
            />
          }

          {submitted && !pending && pageErrors?.length === 0 &&
            <Confirmation
              buttons={bffStatus.isPending(pollingStatus) || bffStatus.isClaPending(pollingStatus)
                ? null
                : [
                  confirmation.button.returnToPrevious(),
                  confirmation.button.viewSummary(requestId, constants.option.requestType.movementHandshake)
                ]}
              confirm={{
                id: movementRef,
                label: 'label.movementRefWithoutLink',
                onClick: bffStatus.isPending(pollingStatus) || bffStatus.isClaPending(pollingStatus)
                  ? () => {
                    storeService.session.remove.dataChanged();
                    storeService.session.set.permissions(userRoleType.GENERIC);
                    setPermission(userRoleType.GENERIC);
                    history.push(Routing.userActivity);
                  }
                  : null
              }}
              label={submitConfirmedMove ? 'boApp.label.movementConfirmed' : 'handshake.reject'}
              links={[
                confirmation.link.viewMovementDocument(species.id, { movementRef, requestId })
              ]}
              setModal={setModal}
              status={pollingStatus}
            />
          }
        </>
      }
    </>
  );
};

ViewMovement.propTypes = {
  location: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default ViewMovement;
