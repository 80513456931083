import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import bff from 'services/bff';
import Button from 'components/base/button/button';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Modal from 'components/base/modal/modal';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import ModalAddAnimalsToHolding from 'components/modalAddAnimalsToHolding/modalAddAnimalsToHolding';
import Table from 'components/table/table';
import moment from 'moment';
const sprintf = require('util').format;

const Animals = ({
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const species = storeService.session.get.species();
  const speciesId = storeService.session.get.species().id;
  const cph = storeService.session.get.holding().value;

  const holding = storeService.session.get.holding();

  const desc1 = 'about.viewAnimals.desc1-' + speciesId;
  const desc2 = helpers.species.isSheepId(speciesId) || helpers.species.isGoatId(speciesId) || helpers.species.isDeerId(speciesId) ? 'about.viewAnimals.desc2' : null;
  const desc3 = helpers.species.isSheepId(speciesId) || helpers.species.isGoatId(speciesId) || helpers.species.isDeerId(speciesId) ? 'about.viewAnimals.desc3' : null;
  const desc4 = helpers.species.isSheepId(speciesId) || helpers.species.isGoatId(speciesId) || helpers.species.isDeerId(speciesId) ? 'about.viewAnimals.desc4' : null;
  const desc5 = helpers.species.isSheepId(speciesId) || helpers.species.isGoatId(speciesId) || helpers.species.isDeerId(speciesId) ? 'about.viewAnimals.desc5' : null;

  const [loading, setLoading] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [confirmCloseModal, setConfirmCloseModal] = React.useState(false);
  const [confirmSubmitModal, setConfirmSubmitModal] = React.useState(false);
  const [confirmUploadNewFile, setConfirmUploadNewFile] = React.useState(false);

  const [uploadPending, setUploadPending] = React.useState(false);
  const [submitPending, setSubmitPending] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [modalHasChanges, setModalHasChanges] = React.useState(false);
  const [modalAnimals, setModalAnimals] = React.useState([]);
  const [modalAddDate, setModalAddDate] = React.useState(null);
  const [modalAddDateError, setModalAddDateError] = React.useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [polling, setPolling] = useState({
    requestId: '',
    status: '',
    errors: [],
    warnings: []
  });
  const [tempUploadedTags, setTempUploadedTags] = useState([]);
  const [renderTable, setRenderTable] = useState(null);

  const modal = {
    clearAll: () => {
      setModalAnimals([]);
      setModalHasChanges(false);
    },

    closeAndReset: () => {
      setModalAnimals([]);
      setModalAddDate(null);
      setModalAddDateError('');
      setShowConfirmation(false);

      setModalVisible(false);
      setModalHasChanges(false);
    },

    getContent: () => {
      return (
        <ModalAddAnimalsToHolding
          addDate={modalAddDate}
          addDateError={modalAddDateError}
          clearAll={modal.clearAll}
          columns={tableColumns.animalsOnHolding.modalColumns({ ageSortColumn: 'ageToDisplay', dobSortColumn: 'dob', sortable: true, removeAnimal: modal.removeAnimal })}
          confirmReUpload={confirmUploadNewFile}
          data={modalAnimals}
          polling={polling}
          removeAllInvalid={modal.removeAllInvalid}
          requestId={polling.requestId}
          setAddDate={handleChanges.addDate}
          setAddDateError={setModalAddDateError}
          setData={setModalAnimals}
          setModal={setModal}
          setPermission={setPermission}
          setUploadPending={setUploadPending}
          showConfirmation={showConfirmation}
          species={species}
          submitPending={submitPending}
          upload={handleChanges.confirmUpload}
          uploadPending={uploadPending}
        />
      );
    },

    getFooterContent: () => {
      const invalidAnimals = modalAnimals.filter((item) => helpers.tag.isInvalidExtended(item));
      const duplicateAnimals = modalAnimals.filter((item) => item.duplicates.length > 0);

      const summaryText = [
        sprintf(
          modalAnimals.length === 1 ? t('label.animalUploaded') : t('label.animalsUploaded'),
          modalAnimals.length
        ),
        invalidAnimals.length > 0 || duplicateAnimals.length > 0 ? '<br />' : '',
        [
          duplicateAnimals.length > 0
            ? sprintf(
              t('label.duplicateAnimals'),
              duplicateAnimals.length
            )
            : '',
          invalidAnimals.length > 0
            ? sprintf(
              invalidAnimals.length === 1 ? t('label.invalidAnimal') : t('label.invalidAnimals'),
              invalidAnimals.length
            )
            : ''
        ].filter((item) => item).join(' - ')
      ].filter((item) => item).join(' ');

      return (
        <div dangerouslySetInnerHTML={{ __html: summaryText }} />
      );
    },

    getHeaderContent: () => {
      return (
        <>
          <i className="bi bi-file-earmark" />
          {t('label.addAnimalsToHolding')}
        </>
      );
    },

    getModalId: () => {
      return 'modalAddAnimalsUploadFile';
    },

    hide: (confirmClose) => {
      if (!submitPending) {
        if (modalHasChanges && !confirmClose) {
          setConfirmCloseModal(true);
        } else if (confirmClose) {
          setConfirmCloseModal(false);
          modal.closeAndReset();
        } else {
          modal.closeAndReset();
        }
      }
    },

    removeAnimal: (row) => {
      setModalAnimals((prevState) => {
        if (row.duplicates?.length > 0) {
          row.duplicates.forEach((duplicateIndex) => {
            const duplicateItem = prevState.find((item) => item.index === duplicateIndex);
            const indexToRemove = duplicateItem.duplicates.indexOf(row.index);

            duplicateItem.duplicates.splice(indexToRemove, 1);
          });
        }

        const newModalAnimals = prevState.filter((animal) => animal.index !== row.index);

        if (newModalAnimals.length === 0) {
          setModalHasChanges(false);
        }

        return newModalAnimals;
      });
    },

    removeAllInvalid: () => {
      setModalAnimals(helpers.animal.removeAllInvalidExtended(modalAnimals));
    },

    saveDisabled: () => {
      return (
        submitPending ||
        !modalAnimals.some((item) => item.added) ||
        modalAnimals.some((item) => helpers.tag.isInvalidExtended(item) && item.added) ||
        modalAnimals.some((item) => item.duplicates.length > 0) ||
        !modalAddDate ||
        modalAddDateError !== ''
      );
    }
  };

  const handleChanges = {
    addDate: (date) => {
      let hasError = false;

      setModalHasChanges(true);
      setModalAddDate(date);
      setModalAddDateError('');

      const addDateIsInTheFuture = helpers.date.is.inTheFuture(date, 'default');
      const addDateIsOverSevenDaysAgo = helpers.date.is.overSevenDaysAgo(date, 'error.dateGT7day2');

      if (addDateIsInTheFuture) {
        setModalAddDateError(addDateIsInTheFuture);

        hasError = true;
      }

      if (!hasError && addDateIsOverSevenDaysAgo) {
        setModalAddDateError(addDateIsOverSevenDaysAgo);
      }
    },

    confirmUpload: (uploadedTags) => {
      if (modalAnimals.length > 0) {
        setTempUploadedTags(uploadedTags);
        setConfirmUploadNewFile(true);
      } else {
        setConfirmUploadNewFile(false);
        handleChanges.upload(uploadedTags);
      }
    },

    saveChanges: () => {
      setConfirmSubmitModal(false);
      setSubmitPending(true);

      const params = {
        poll: config.POLLS_ENABLED,
        holding: holding.value,
        species: species.name,
        addDate: modalAddDate,
        devices: modalAnimals.map((device) => ({
          dob: device.dobToDisplay
            ? (
              !isNaN(device.dobToDisplay)
                ? device.dobToDisplay
                : helpers.date.formatDDMMYYYYtoYYYMMDD(device.dobToDisplay)
            )
            : null,
          gender: device.genderName,
          breed: device.breedName,
          tagNumber: device.tagNumber
        }))
      };

      bff
        .post('/addAnimalsToHolding', params)
        .then((response) => {
          storeService.session.removeAll.searchResults();
          setRenderTable(false);

          setPolling({
            requestId: String(response.data.requestId),
            status: response.data.poll ? response.data.poll.requestStatus : constants.status.claPending,
            errors: response.data.poll ? response.data.poll.errors : [],
            warnings: response.data.poll ? response.data.poll.warnings : []
          });
          setShowConfirmation(true);
          setSubmitPending(false);
          setModalHasChanges(false);
        })
        .catch((error) => {
          setSubmitPending(false);
          errors.BFF(error, setModal);
        });
    },

    upload: (uploadedTags) => {
      setModalHasChanges(true);
      setConfirmUploadNewFile(false);

      setModalAnimals(uploadedTags.map((animal) => ({
        ...animal,
        added: true
      })));
    }
  };

  const tableParams = {
    request: {
      url: '/animals',
      params: {
        speciesId: species.id,
        cph
      },
      processData: (data) => helpers.animal.addDobAndAgeForDisplay(data)
    }
  };

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.animals.length) !== Routing.animals) {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
        unListen();
      }
    });
  }, [history]);

  useEffect(() => {
    if (!renderTable) {
      setRenderTable(true);
    }
  }, [renderTable]);

  const handleDownloadAnimals = () => {
    const tableFilter = storeService.session.get.tableFilters();
    const params = {
      cph,
      speciesId,
      onHoldingDate: helpers.date.now(),
      ageRange: tableFilter?.animalsOnHolding?.ageRange,
      breedName: tableFilter?.animalsOnHolding?.breedName,
      gender: tableFilter?.animalsOnHolding?.gender,
      tagNumber: tableFilter?.animalsOnHolding?.tagNumber,
      pageSize: 'all'
    };
    setLoading(true);
    bff
      .get('/animals', {
        params: params
      })
      .then((res) => {
        const timeStamp = moment().format('DD-MM-YYYY HH mm');
        const filename = `Animals on Holding ${timeStamp}`;
        const holdings = storeService.session.get.holdings();
        const workbook = helpers.generateAnimalWorkbook(holdings[cph], species.name, timeStamp, res.data.data);
        helpers.writeWorkbookToFile(workbook, filename, 'ods');
      })
      .catch((error) => {
        const errorMessage = error.response?.status === 400
          ? {
            response: {
              status: error.response.status,
              data: {
                errorMessage: '<strong>Download failed.</strong><br/><br/>There are too many animals to download in one go. Please reduce the number of animals to download and try again.'
              }
            }
          }
          : error;
        errors.BFF(errorMessage, setModal);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {ready &&
        <>
          {modalVisible &&
            <>
              <Modal
                footerContent={modal.getFooterContent()}
                headerContent={modal.getHeaderContent()}
                id={modal.getModalId()}
                modalClose={() => modal.hide(false)}
                showConfirmation={showConfirmation}
                submitChanges={() => setConfirmSubmitModal(true)}
                submitDisabled={modal.saveDisabled()}
                submitLabel="button.submit"
                submitPending={submitPending}
              >
                {modal.getContent()}
              </Modal>

              {confirmSubmitModal &&
                <ModalConfirm
                  currentOpenModalId={modal.getModalId()}
                  id="confirmSubmitModal"
                  modalClose={() => setConfirmSubmitModal(false)}
                  modalConfirm={handleChanges.saveChanges}
                  modalMessage="warning.addAnimalsToHolding"
                  modalTitle="warning.submitNewAnimals"
                />
              }

              {confirmCloseModal &&
                <ModalConfirm
                  currentOpenModalId={modal.getModalId()}
                  id="confirmCloseModal"
                  modalClose={() => setConfirmCloseModal(false)}
                  modalConfirm={() => modal.hide(true)}
                  modalMessage="warning.unsavedChanges2"
                  modalTitle="warning.youHaveUnsavedChanges"
                />
              }

              {confirmUploadNewFile &&
                <ModalConfirm
                  currentOpenModalId="modalAddAnimalsUploadFile"
                  id="confirmUploadNewFileModal"
                  modalClose={() => setConfirmUploadNewFile(false)}
                  modalConfirm={() => handleChanges.upload(tempUploadedTags)}
                  modalMessage="warning.unsavedChanges3"
                  modalTitle="warning.youHaveUnsavedChanges"
                />
              }
            </>
          }

          <h1 className="h1">{t('menu.animalsOnHolding')}</h1>
          <p className="subtitle">{t('animalsOnHolding.subtitle')}</p>
          <AboutPanel
            descriptions={[
              { text: desc1 },
              { text: desc2 },
              { text: desc3 },
              { text: desc4 },
              { text: desc5, onClick: () => setModalVisible(true) }
            ]}
            title="about.viewAnimals.title"
          />

          <Table
            columns={helpers.species.isDeerId(species.id)
              ? tableColumns.animalsOnHolding.deer
              : tableColumns.animalsOnHolding.nonDeer({ sortable: false })
            }
            data={data}
            downloadPending={loading}
            filtersComponents={{
              [constants.filters.inside]: 'AnimalsOnHolding'
            }}
            initialSortBy={initialSorting.animalsOnHolding.accessor}
            initialSortDirection={initialSorting.animalsOnHolding.direction}
            params={tableParams}
            renderTable={renderTable}
            rowLinks={{
              url: () => Routing.animals,
              accessor: 'tagNumber',
              state: (row) => ({
                data: row
              })
            }}
            setData={setData}
            setModal={setModal}
            store={{
              filters: 'AnimalsOnHolding',
              data: 'AnimalsOnHolding'
            }}
          />
          <div className="item-align-right">
            <Button
              buttonType="primary"
              disabled={loading}
              label={loading ? 'button.downloading' : 'button.downloadAllAnimals'}
              onClick={handleDownloadAnimals}
            />
          </div>
        </>
      }
    </>
  );
};

Animals.propTypes = {
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func.isRequired
};

export default Animals;
