import React from 'react';
import PropTypes from 'prop-types';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react';
import { loginRequest } from 'services/b2cService';
import classes from 'components/authNavigation/authNavigation.module.scss';
import Button from 'components/base/button/button';

const AuthNavigation = ({ landingVar }) => {
  const { instance } = useMsal();

  return (
    <div className={classes.authNavigation}>
      <AuthenticatedTemplate>
        <Button
          label="button.signOut"
          onClick={() =>
            instance.logoutRedirect({ postLogoutRedirectUri: '/' })
          }
        />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button
          label={landingVar
            ? 'button.signInVariation'
            : 'button.signIn' }
          onClick={() => instance.loginRedirect(loginRequest)}
        />
      </UnauthenticatedTemplate>
    </div>
  );
};

AuthNavigation.propTypes = {
  landingVar: PropTypes.bool
};

export default AuthNavigation;
