import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import codeHelper from 'services/codeHelper';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: codeHelper.APP_INSIGHT,
    //instrumentationKey: codeHelper.APP_INSIGHT,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    },
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    samplingPercentage: 100,
    enableUnhandledPromiseRejectionTracking: true,
    enableCorsCorrelation: true,
    distributedTracingMode: 1,
    loggingLevelConsole: 0,
    loggingLevelTelemetry: 2,
    enableDebugExceptions: true
  }
});
// distributedTracingMode from https://docs.microsoft.com/en-us/azure/azure-monitor/app/correlation
// AI = 0
// AI_AND_W3C = 1

// AI
// Request-Id: Carries the globally unique ID of the call.
// Correlation-Context: Carries the name-value pairs collection of the distributed trace properties.

// AI_AND_W3C
// W3C Trace-Context, which defines:
// traceparent: Carries the globally unique operation ID and unique identifier of the call.
// tracestate: Carries system-specific tracing context.

// * loggingLevelConsole / loggingLevelTelemetry
// * All logs with a severity level higher
// * than the configured level will be printed to console / sent as telemetry. Otherwise
// * they are suppressed. ie Level 2 will print both CRITICAL and
// * WARNING logs to console, level 1 prints only CRITICAL.
// *
// * Note: Logs sent as telemetry to instrumentation key will also
// * be logged to console if their severity meets the configured loggingConsoleLevel
// *
// * 0: ALL console logging off
// * 1: logs to console: severity >= CRITICAL
// * 2: logs to console: severity >= WARNING

appInsights.loadAppInsights();
//appInsights.trackPageView();

const appInsightsService = {
  startTrackPage: (name) => appInsights.startTrackPage(name),
  stopTrackPage: (name, url, properties, measurements) => appInsights.stopTrackPage(name, url, properties, measurements),
  trackEvent: (event) => appInsights.trackEvent(event),
  trackException: (exception) => appInsights.trackException(exception),
  trackMetric: (message) => appInsights.trackMetric(message),
  trackPageView: (name, url) => appInsights.trackPageView(name, url),
  trackTrace: (message) => appInsights.trackTrace(message),
  reactPlugin: reactPlugin,
  appInsights: appInsights
};

export default appInsightsService;
