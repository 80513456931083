import React from 'react';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import { useTranslation } from 'react-i18next';
import Routing from 'routing';
import storeService from 'services/storeService';
import Linker from 'components/base/linker/linker';
import VetSummaryInfo from 'frontEnd/vetModule/components/vetSummaryInfo';
import helpers from 'services/helpers';

const AbattoirDeclaration = ({ match, setModal }) => {
  const { ready, t } = useTranslation(),
    cphDetails = storeService.session.get.vetCPHData() ? storeService.session.get.vetCPHData() : '',
    abattoirData = storeService.session.get.abattoirCPHData() ? storeService.session.get.abattoirCPHData() : [],
    filterData = abattoirData.filter((item) => item.id === parseInt(match.params.declarationId)),
    vetAttestationNumber = helpers.vetAttestationNumber.generateVan(filterData[0]?.vetRCVS, cphDetails?.id, filterData[0]?.startDate),
    printAndDownload = () => {
      window.print();
    };
  return (
    <>
      {ready &&
        <>
          <main className={classes.mainContanier}>
            <section className={classes.titleSection + ' ' + classes.bottomBorder}>
              <h1 className={classes.pageTitle}>{t('vetContent.attestationDecRecord')}</h1>
            </section>
            <div className={classes.vetDeclarationBack}>
              <Linker
                label="button.back"
                linkType="back"
                to={Routing.abattoirAttestationResult}
              />
            </div>

            <section className={classes.bodySection}>
              <div className={classes.summaryBlock + ' ' + classes.topSpace}>
                <VetSummaryInfo setModal={setModal} vanNo={vetAttestationNumber} vetAbattoirInfo = {filterData} visitDate={helpers.date.format(new Date(filterData[0]?.startDate))} />
              </div>
              <div className={classes.declarationBlock}>
                <div className={classes.fontBold}>{t('vetContent.vetDeclaration.title')}</div>
                <p>{t('vetContent.vetDeclaration.content1')}</p>
                <div>{helpers.date.format(new Date(filterData[0]?.startDate))}</div>
                <p className={classes.declartionContent}>
                  {t('vetContent.vetDeclaration.content2-part-1')}
                  <span>&#8544;</span>&nbsp;
                  {t('vetContent.vetDeclaration.content2-part-2')}
                </p>
                <div className={classes.fontBold}>{filterData[0]?.vetName}</div>
              </div>
            </section>

            <section className={classes.buttonSection + ' ' + classes.displayBtnBoth + ' ' + classes.notForPrint}>
              <span className={classes.buttonLink} onClick={printAndDownload}>{t('button.printSave')}</span>
            </section>
          </main>
        </>
      }
    </>
  );
};

export default AbattoirDeclaration;
