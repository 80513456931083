import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/tooltip/tooltip.module.scss';
import iconTooltip from 'images/svg/icon-help.svg';
import featureToggles from 'services/featureToggles';

const Tooltip = ({
  tooltip,
  tooltipPosition
}) => {
  const { ready, t } = useTranslation();

  const [showTooltip, setTooltip] = React.useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', () => {
      setTooltip(false);
    });
  });

  const toggleTooltip = () => {
    setTooltip(!showTooltip);
  };

  const componentClassNames = [
    classes.tooltip,
    tooltipPosition ? classes[tooltipPosition] : ''
  ].join(' ').trim();

  return (
    <>
      {ready && tooltip &&
        <>
          {featureToggles.isEnabled(featureToggles.toggles.accessibilityTooltipsKeyboardFocus) &&
            <div className={componentClassNames}>
              <img
                alt={t('label.help')}
                aria-label={t('label.help')}
                className={classes.icon}
                onClick={toggleTooltip}
                onKeyPress={toggleTooltip}
                role="button"
                src={iconTooltip}
                tabIndex="0"
              />
              <div className={showTooltip ? classes.content + ' ' + classes.show : classes.content} dangerouslySetInnerHTML={{ __html: t(tooltip) }} />
            </div>
          }
          {!featureToggles.isEnabled(featureToggles.toggles.accessibilityTooltipsKeyboardFocus) &&
            <div className={componentClassNames}>
              <img alt={t('label.help')} className={classes.icon} onClick={toggleTooltip} src={iconTooltip} />
              <div className={showTooltip ? classes.content + ' ' + classes.show : classes.content} dangerouslySetInnerHTML={{ __html: t(tooltip) }} />
            </div>
          }
        </>
      }
    </>
  );
};

Tooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.string
};

export default Tooltip;
