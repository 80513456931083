import React from 'react';
import constants from 'services/constants';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import CheckBox from 'components/base/checkbox/checkbox';
import RadioButtons from 'components/base/radioButtons/radioButtons';

const MovementType = ({
  inBusinessMovement,
  isAmend,
  movementType,
  setInBusinessMovement,
  setMovementType
}) => {
  const holdings = storeService.session.get.holdings();
  const numberOfHoldings = holdings ? Object.keys(holdings).length : 0;

  return (
    <>
      <div className="section">
        <RadioButtons
          disabled={isAmend
            ? [constants.option.movement.on, constants.option.movement.off]
            : (permissions.isAbattoir() ? constants.option.movement.off : [])
          }
          ids={[
            constants.option.movement.on,
            constants.option.movement.off
          ]}
          inline={true}
          label="radioButtons.movementType.label"
          name="movementType"
          onChange={(event) => setMovementType(event.target.value)}
          value= {movementType}
        />
      </div>
      {numberOfHoldings > 1 &&
        <div className="section">
          <CheckBox
            disabled={isAmend}
            id="inBusinessMove"
            isChecked={inBusinessMovement}
            label="radioButtons.movementType.business"
            name="inBusinessMove"
            onChange={() => setInBusinessMovement(!inBusinessMovement)}
            tooltip="label.movementTypeTooltip"
            value={inBusinessMovement}
          />
        </div>
      }
    </>
  );
};

MovementType.propTypes = {
  inBusinessMovement: PropTypes.bool.isRequired,
  isAmend: PropTypes.bool,
  movementType: PropTypes.string.isRequired,
  setInBusinessMovement: PropTypes.func.isRequired,
  setMovementType: PropTypes.func.isRequired
};

export default MovementType;
