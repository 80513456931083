import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/base/button/button';
import classes from 'components/summary/userComment/userComment.module.scss';
import PropTypes from 'prop-types';

const UserComment = ({
  comment,
  label,
  onClick
}) => {
  const { ready, t } = useTranslation();

  return (
    <>
      {ready &&
        <div className={classes.userCommentRow}>
          <div className={classes.label}>{t(label)}</div>
          <div className={classes.value}>
            {comment ? comment : t('label.userCommentNotEntered')}
          </div>
          {onClick &&
              <div className={classes.action}>
                <Button
                  buttonType="link"
                  label="label.edit"
                  onClick={onClick}
                />
              </div>
          }
        </div>
      }
    </>
  );
};

UserComment.propTypes = {
  comment: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default UserComment;
