import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AddTagRange from 'components/addTagRange/addTagRange';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FileUpload from 'components/base/fileUpload/fileUpload';
import ManualTagEntry from 'components/manualTagEntry/manualTagEntry';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Table from 'components/table/table';

const AnimalsIndividuals = ({
  addAnimals,
  animalDetails,
  animalDetailsValidation,
  pending,
  removeAllInvalid,
  removeAnimal,
  removeAnimalsAll,
  resetForm,
  setAnimalDetails,
  setAnimalDetailsValidation,
  setModal,
  setPending,
  species,
  uploadAnimalDetails
}) => {
  const { ready, t } = useTranslation();

  const [selectionMethod, setSelectionMethod] = useState(constants.option.selectionMethod.manualTagNumbers);
  const [hasWarning, setHasWarning] = useState(false);
  const [paginationParams, setPaginationParams] = React.useState(constants.pagination.defaultParams);

  useEffect(() => {
    if (resetForm !== undefined) {
      setSelectionMethod(constants.option.selectionMethod.manualTagNumbers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);

  useEffect(() => {
    const errorAnimals = animalDetails.filter((item) => item.status && helpers.response.isError(item.status.type));
    const warningAnimals = animalDetails.filter((item) => item.status && helpers.response.isWarning(item.status.type));

    if (errorAnimals.length > 0 || warningAnimals.length > 0) {
      setHasWarning(true);
      setAnimalDetailsValidation(animalDetailsValidation + 1);
    } else {
      setHasWarning(false);
    }

    if (animalDetails.length === 0) {
      setSelectionMethod(constants.option.selectionMethod.manualTagNumbers);
    }

    setPaginationParams((prevState) => ({
      ...prevState,
      [constants.pagination.bottom]: {
        ...prevState[constants.pagination.bottom],
        total: animalDetails?.length
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animalDetails]);

  return (
    <>
      {ready &&
        <details className="accordion" open={animalDetailsValidation > 0 && hasWarning}>
          <summary>
            <h2>{t('movementDetails.animalsMovedTags.header') + (animalDetails.length ? ' (' + animalDetails.length + ')' : '')}</h2>
          </summary>
          <div className="section">
            <div className="section">
              <RadioButtons
                id="radioOptions"
                ids={[
                  constants.option.selectionMethod.manualTagNumbers,
                  constants.option.selectionMethod.tagNumberRange,
                  constants.option.selectionMethod.uploadFile
                ]}
                inline={true}
                name="selectionMethod"
                onChange={(event) => setSelectionMethod(event.target.value)}
                reduceSpace={true}
                value={selectionMethod}
              />
            </div>

            {helpers.option.selectionMethod.isUploadFile(selectionMethod) &&
              <FileUpload
                description={[
                  'label.upload.uploadDesc',
                  helpers.species.idToName(species.id) === 'Sheep' ? 'label.upload.uploadDescSpecies-1' : 'label.upload.uploadDescSpecies-2',
                  'label.upload.uploadDescLink'
                ]}
                hideHint={true}
                hideTooltip={true}
                modalMessage={helpers.tag.getFormatExamples(species.id).description}
                modalTitle={helpers.tag.getFormatExamples(species.id).title}
                pending={pending}
                setAnimalsToChange={uploadAnimalDetails}
                setModal={setModal}
                setPending={setPending}
                species={species}
              />
            }

            {helpers.option.selectionMethod.isTagNumberRange(selectionMethod) &&
              <div className="section">
                <AddTagRange
                  isGatheringMovement={true}
                  setAnimalsToChange={addAnimals}
                  setModal={setModal}
                  species={species}
                />
              </div>
            }

            {helpers.option.selectionMethod.isManualTagNumbers(selectionMethod) &&
              <ManualTagEntry
                addAnimals={addAnimals}
                animalsToChange={animalDetails}
                id="manualTagEntry"
                pending={pending}
                resetForm={resetForm}
                setModal={setModal}
                setPending={setPending}
                species={species}
                title="movements.animal-selection.manual-tag-entry-heading"
                type={constants.option.movement.newMovement}
              />
            }
            {animalDetails.length > 0 &&
              <div className="col-12">
                {animalDetails && helpers.tag.hasNonEidTagsSheep(species.id, animalDetails) &&
                  <ErrorLabel
                    isWarning={true}
                    label="formatExamples.tagBatchNonEidError"
                    modalMessage={helpers.tag.getFormatExamples(species.id).description}
                    modalTitle={helpers.tag.getFormatExamples(species.id).title}
                    setModal={setModal}
                  />
                }
                {animalDetails?.some((tag) => helpers.tag.isInvalid(tag)) &&
                  <ErrorLabel
                    label="formatExamples.tagBatchError"
                    modalMessage={helpers.tag.getFormatExamples(species.id).description}
                    modalTitle={helpers.tag.getFormatExamples(species.id).title}
                    setModal={setModal}
                  />
                }
                <Table
                  columns={tableColumns.movementsGatherings.individual({ actionFunction: removeAnimal })}
                  data={animalDetails}
                  dataProvided={true}
                  initialSortBy={initialSorting.movementsGatherings.individual.accessor}
                  initialSortDirection={initialSorting.movementsGatherings.individual.direction}
                  paginationParams={paginationParams}
                  removeAll={removeAnimalsAll}
                  removeAllInvalid={removeAllInvalid}
                  setData={setAnimalDetails}
                  setModal={setModal}
                  title={{
                    title: 'table.title.individualAnimals',
                    data: [
                      animalDetails?.length
                    ]
                  }}
                />
              </div>
            }
          </div>
        </details>
      }
    </>
  );
};

AnimalsIndividuals.propTypes = {
  addAnimals: PropTypes.func,
  animalDetails: PropTypes.array,
  animalDetailsValidation: PropTypes.number,
  pending: PropTypes.bool.isRequired,
  removeAllInvalid: PropTypes.func,
  removeAnimal: PropTypes.func,
  removeAnimalsAll: PropTypes.func,
  resetForm: PropTypes.bool,
  setAnimalDetails: PropTypes.func.isRequired,
  setAnimalDetailsValidation: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setPending: PropTypes.func.isRequired,
  species: PropTypes.object,
  uploadAnimalDetails: PropTypes.func
};

export default AnimalsIndividuals;
