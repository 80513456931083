import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import config from 'config';
import constants from 'services/constants';
import classes from 'backOfficeComponents/manualTagEntry/manualTagEntry.module.scss';
import Button from 'backOfficeComponents/base/button/button';
import Legend from 'backOfficeComponents/base/legend/legend';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import helpers from 'services/helpers';

const ManualTagEntry = ({
  animalDetails,
  description,
  id,
  setAnimalDetails,
  setDataChanged,
  title
}) => {
  const { ready, t } = useTranslation();

  const [textFieldTags, setTextFieldTags] = React.useState([]);

  const validateAnimalsViaTextEntry = () => {
    backOfficeTagEntry();
    setTextFieldTags([]);
  };

  const backOfficeTagEntry = () => {
    const tags = textFieldTags.length > 0
      ? textFieldTags[0].match(/\b[\w']+(?:[^\w\n]+[\w']+){0,1}\b/g)
      : null;
    const validTags = [];
    if (tags !== null && tags.length > 0) {
      tags.forEach((element) => {
        validTags.push(element);
      });
    }
    if (validTags && validTags.length !== 0) {
      const newTags = validTags.map((tag) => {
        return {
          tagNumber: tag,
          uuid: helpers.generateUUID()
        };
      });
      setAnimalDetails([
        ...animalDetails,
        ...newTags
      ]);
      setDataChanged();
    }
  };

  const addAnimalsViaTextEntry = (event) => {
    if (event.target.value.length === 0) {
      setTextFieldTags([]);
      return;
    }

    const tagArray = event.target.value.split();
    setTextFieldTags(tagArray);
  };

  return (
    <>
      {ready &&
        <fieldset className="width100percent" role="group">
          <Legend
            id={id}
            legend={title}
          />
          {description &&
            <p>{t(description)}</p>
          }

          <div className={classes.manualTagEntry}>
            <FieldEntry
              id={id}
              labelPosition={constants.labelPosition.above}
              labelText="addIndividualAnimal.copyAndPasteHint"
              name="addIndividualAnimal"
              onChange={addAnimalsViaTextEntry}
              rows={config.ROWS_TEXTAREA}
              value={textFieldTags}
            />
            <Button
              buttonType="secondary"
              disabled={!textFieldTags || !textFieldTags.length}
              id={id}
              label="button.add"
              onClick={validateAnimalsViaTextEntry}
            />
          </div>
        </fieldset>
      }
    </>
  );
};

ManualTagEntry.propTypes = {
  animalDetails: PropTypes.array,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  setAnimalDetails: PropTypes.func.isRequired,
  setDataChanged: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default ManualTagEntry;
