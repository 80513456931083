import React from 'react';
import { useTranslation } from 'react-i18next';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import Routing from 'routing';
import { tableColumns } from 'services/tableColumns';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';
import Table from 'components/table/table';

const VerifyCustomerResults = ({
  data,
  searched,
  searchPending,
  setData,
  setModal
}) => {
  const { ready, t } = useTranslation();

  const paginationParams = constants.pagination.defaultParams;
  paginationParams[constants.pagination.bottom].total = data?.length;

  return (
    <>
      {ready && searched &&
        <div className="pageContent">
          <SectionTitleBar
            icon="bi bi-card-list"
            id="verifyCustomerResultsTitle"
            title="boApp.label.searchResults"
          />
          {searchPending &&
            <span>{t('boApp.label.searching')}</span>
          }
          {!searchPending && data?.length === 0 &&
            <span>{t('boApp.label.noResults')}</span>
          }

          {data?.length > 0 &&
            <Table
              classNames={['customerResults']}
              columns={tableColumns.bo.verifyCustomer}
              data={data}
              dataProvided={true}
              paginationParams={paginationParams}
              rowLinks={{
                url: () => Routing.boEditCustomer,
                state: (row) => ({
                  data: row
                })
              }}
              setData={setData}
              setModal={setModal}
            />
          }
        </div>
      }
    </>
  );
};

VerifyCustomerResults.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  searchPending: PropTypes.bool,
  searched: PropTypes.bool,
  setData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired
};

export default VerifyCustomerResults;
