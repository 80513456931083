import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import storeService from 'services/storeService';

const Logout = () => {
  const { instance } = useMsal();

  useEffect(() => {
    storeService.cookie.removeAll.all();
    storeService.local.removeAll.all();
    storeService.session.removeAll.all();

    // call msal to logout and redirect UI to root
    instance.logoutRedirect({ postLogoutRedirectUri: '/' })
      .catch((error) => {
      console.error(error); //eslint-disable-line
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
