import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import constants from 'services/constants';
import classes from 'backOfficeComponents/base/displayValue/displayValue.module.scss';
import helpers from 'services/helpers';

const DisplayValue = ({
  id,
  labelPosition,
  labelText,
  multiItem,
  value
}) => {
  const { ready, t } = useTranslation();

  const valueChecked = helpers.replaceNull(value);
  const isArray = Array.isArray(valueChecked);

  const lPosition = (labelText && labelText.length) ? ((labelPosition) ? labelPosition : constants.labelPosition.above) : constants.labelPosition.none;

  return (
    <>
      {ready &&
        <div className={helpers.labelPosition.isAbove(lPosition) ? classes.displayValue + ' ' + classes.block : classes.displayValue} id={id}>
          <div className={classes.label}>{t(labelText)}</div>
          {isArray &&
            <div className={classes.multiValues + ' ' + classes[multiItem]}>
              {valueChecked.map((item, index) =>
                <span key={index}>{item}</span>
              )}
            </div>
          }
          {!isArray &&
            <div className={classes.value}>{t(value)}</div>
          }
        </div>
      }
    </>
  );
};

DisplayValue.propTypes = {
  id: PropTypes.string.isRequired,
  labelPosition: PropTypes.oneOf([
    constants.labelPosition.above,
    constants.labelPosition.inline,
    constants.labelPosition.none
  ]),
  labelText: PropTypes.string,
  multiItem: PropTypes.oneOf([
    constants.multiItem.row,
    constants.multiItem.column
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
};

export default DisplayValue;
