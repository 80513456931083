import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from 'components/table/filters/filtersBoAnimalActivity/filtersBoAnimalActivity.module.scss';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import Button from 'backOfficeComponents/base/button/button';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import TextEntry from 'components/base/textEntry/textEntry';

const BoAnimalActivityFilters = ({
  data,
  hasData,
  loadPending,
  setData,
  setRenderTable,
  setTableParams,
  storeFilters,
  tableParams
}) => {
  const { ready } = useTranslation();
  const history = useHistory();

  const [filterSpeciesName, setFilterSpeciesName] = React.useState(tableParams?.filters?.speciesName);
  const [filterTagNumber, setFilterTagNumber] = React.useState(tableParams?.filters?.tagNumber);
  const [searchDisabled, setSearchDisabled] = React.useState(true);

  const handleChanges = {
    speciesName: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setFilterSpeciesName(value);

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...storedFilters,
        speciesId: helpers.species.nameToId(value),
        speciesName: helpers.species.formatName(value),
        useComponent: tableParams.filters.useComponent
      });

      setData([]);
      storeService.session.removeAll.searchResults();
      storeService.session.remove[`tableFilters${storeFilters}MovementHistory`]();
      storeService.session.remove[`tableFilters${storeFilters}TaggingHistory`]();
      storeService.session.remove[`tableFilters${storeFilters}Progeny`]();

      setRenderTable(false);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          speciesName: value
        }
      }));

      history.push(Routing.boAnimalActivity);
    },

    tagNumber: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setFilterTagNumber(value);

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...storedFilters,
        tagNumber: value,
        useComponent: tableParams.filters.useComponent
      });

      setData([]);
      storeService.session.removeAll.searchResults();
      storeService.session.remove[`tableFilters${storeFilters}MovementHistory`]();
      storeService.session.remove[`tableFilters${storeFilters}TaggingHistory`]();
      storeService.session.remove[`tableFilters${storeFilters}Progeny`]();

      setRenderTable(false);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          tagNumber: value
        }
      }));

      history.push(Routing.boAnimalActivity);
    }
  };

  const runRequest = () => {
    storeService.session.remove[`tableFilters${storeFilters}MovementHistory`]();
    storeService.session.remove[`tableFilters${storeFilters}TaggingHistory`]();
    storeService.session.remove[`tableFilters${storeFilters}Progeny`]();

    storeService.session.removeAll.searchResults();
    setData([]);

    setTableParams((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        speciesId: filterSpeciesName ? helpers.species.nameToId(filterSpeciesName) : null,
        speciesName: helpers.species.formatName(filterSpeciesName),
        tagNumber: filterTagNumber
      },
      page: {
        ...prevState.page,
        index: 0
      },
      request: {
        ...prevState.request,
        url: '/animalActivity',
        params: {
          ...prevState.request.params,
          speciesId: filterSpeciesName ? helpers.species.nameToId(filterSpeciesName) : null,
          tagNumber: filterTagNumber
        }
      }
    }));
    setRenderTable(true);
  };

  useEffect(() => {
    const sheepAndGoatsPattern = new RegExp('^[a-z]{2}[0-9]{7,}$');
    const deerPattern = new RegExp('^[a-z]{3,4}[0-9]{5,}$');

    if (
      loadPending || hasData ||
      !filterSpeciesName ||
      !filterTagNumber ||
      (
        (filterSpeciesName.toLowerCase() === constants.species.animalTypes.SHEEP || filterSpeciesName.toLowerCase() === constants.species.animalTypes.GOATS) &&
        !filterTagNumber.replaceAll(' ', '').toLowerCase().match(sheepAndGoatsPattern)
      ) ||
        (
          filterSpeciesName.toLowerCase() === constants.species.animalTypes.DEER &&
          !filterTagNumber.replaceAll(' ', '').toLowerCase().match(deerPattern)
        )
    ) {
      setSearchDisabled(true);
    } else {
      setSearchDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadPending,
    hasData,
    filterSpeciesName,
    filterTagNumber
  ]);

  useEffect(() => {
    setTableParams((prevState) => ({
      ...prevState,
      request: {
        ...prevState.request,
        url: '/animalActivity',
        params: {
          ...prevState.request.params,
          speciesId: filterSpeciesName ? helpers.species.nameToId(filterSpeciesName) : null,
          tagNumber: filterTagNumber
        }
      }
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasData && !data[0].notApplied) {
      history.push(Routing.boAnimalActivityMovementHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData]);

  return (
    <>
      {ready &&
        <>
          <div className={classes.animalActivity}>
            <RadioButtons
              disabled={loadPending ? [
                constants.species.animalTypes.SHEEP,
                constants.species.animalTypes.GOATS,
                constants.species.animalTypes.DEER
              ] : null}
              id="speciesName"
              ids={[
                constants.species.animalTypes.SHEEP,
                constants.species.animalTypes.GOATS,
                constants.species.animalTypes.DEER
              ]}
              inline={true}
              name="speciesName"
              onChange={(event) => handleChanges.speciesName(event.target.value)}
              reduceSpace={true}
              suppressLabel={false}
              value={filterSpeciesName?.toLowerCase()}
            />

            <div className={classes.tagNumber}>
              <TextEntry
                disabled={loadPending}
                hideCharacterCount={true}
                id="tagNumber"
                label="label.tagNumber"
                maxLength={config.WIDTH_TAGS}
                name="tagNumber"
                onChange={(event) => handleChanges.tagNumber(event.target.value)}
                placeholder="label.enterFullTagNumber"
                value={filterTagNumber}
              />
            </div>

            <div className={classes.submitButton}>
              <Button
                buttonType="primary"
                disabled={searchDisabled}
                id="searchButton"
                label="button.search"
                onClick={runRequest}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

BoAnimalActivityFilters.propTypes = {
  hasData: PropTypes.bool.isRequired,
  loadPending: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  setRenderTable: PropTypes.func.isRequired,
  setTableParams: PropTypes.func.isRequired,
  storeFilters: PropTypes.string.isRequired,
  tableParams: PropTypes.object.isRequired
};

export default BoAnimalActivityFilters;
