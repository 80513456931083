import React from 'react';
import config from 'config';
import PropTypes from 'prop-types';
import DropdownGeneticDamIds from 'components/dropdownGeneticDamIds/dropdownGeneticDamIds';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import Tabs from 'components/base/tabs/tabs';
import TextEntry from 'components/base/textEntry/textEntry';
import constants from 'services/constants';

const GeneticDamTab = ({
  activeTabId,
  geneticDamAI,
  geneticDamId,
  geneticDamIdError,
  geneticDamTabs,
  setActiveTabId,
  setGeneticDamAI,
  setGeneticDamId,
  setModal
}) => {
  return (
    <>
      <FieldLabel
        label="label.geneticDamId"
      />
      <Tabs activeTabId={activeTabId} ref={geneticDamTabs} setActiveTabId={setActiveTabId}>
        <div id={constants.tabs.onHolding} label="label.onHolding">
          <div className="tab-border">
            <DropdownGeneticDamIds
              error={geneticDamIdError}
              name="geneticDamId"
              onChange={(event) => setGeneticDamId(event.target.value)}
              setModal={setModal}
              value={geneticDamId}
            />
          </div>
        </div>
        <div id={constants.tabs.donor} label="label.donorNotOnHolding">
          <div className="tab-border-text">
            <TextEntry
              id="geneticDamAI"
              label="label.enterSupplyTag"
              name="geneticDamAI"
              onChange={(event) => setGeneticDamAI(event.target.value)}
              value={geneticDamAI}
              width={config.WIDTH_TAGS}
            />
          </div>
        </div>
      </Tabs>
    </>
  );
};

GeneticDamTab.propTypes = {
  activeTabId: PropTypes.string.isRequired,
  geneticDamAI: PropTypes.string,
  geneticDamId: PropTypes.string,
  geneticDamIdError: PropTypes.string,
  geneticDamTabs: PropTypes.object,
  setActiveTabId: PropTypes.func.isRequired,
  setGeneticDamAI: PropTypes.func,
  setGeneticDamId: PropTypes.func,
  setModal: PropTypes.func.isRequired
};

export default GeneticDamTab;
