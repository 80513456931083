import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import bff from 'services/bff';
import classes from 'frontEnd/holdingRegister/holdingRegister.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import { initialSorting } from 'services/tableColumns';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import Modal from 'components/base/modal/modal';
import ModalAdjacentMovements from 'components/modalAdjacentMovements/modalAdjacentMovements';
import ModalAnnualInventory from 'components/modalAnnualInventory/modalAnnualInventory';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import NavBarSub from 'components/header/navBarSub/navBarSub';
import Table from 'components/table/table';

const HoldingRegister = ({
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionHolding = storeService.session.get.holding();
  const sessionSpecies = storeService.session.get.species();
  const speciesId = sessionSpecies ? sessionSpecies.id : '';
  const sessionPermissions = storeService.session.get.permissions();

  const [eventType, setEventType] = React.useState({ current: '', previous: '' });
  const [returnUrl, setReturnUrl] = React.useState('');
  const [data, setData] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const [modalMode, setModalMode] = React.useState(constants.modalActions.add);
  const [modalHasChanges, setModalHasChanges] = React.useState(false);
  const [modalSubmitted, setModalSubmitted] = React.useState(false);
  const [confirmAction, setConfirmAction] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({});
  const [deletePending, setDeletePending] = React.useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = React.useState(false);
  const [rowToDelete, setRowToDelete] = React.useState(null);
  const [polling, setPolling] = useState({
    requestId: '',
    status: '',
    errors: [],
    warnings: []
  });

  const getEndDate = (date) => {
    const formattedDate = helpers.date.format(date);
    return date && formattedDate !== '31/12/9999' ? formattedDate : null;
  };

  const supportedRequestTypes = [
    constants.option.requestType.birth,
    constants.option.requestType.correctTransfer,
    constants.option.requestType.death,
    constants.option.requestType.movement,
    constants.option.requestType.movementHandshake,
    constants.option.requestType.tagApplication,
    constants.option.requestType.tagReplacement
  ];

  const handleChanges = {
    deleteAdjacentMovement: () => {
      deleteData.setAction(constants.modalActions.delete);
      setDeletePending(true);
      setConfirmAction(false);

      const params = {
        params: {
          id: deleteData.adjacentMovementId,
          speciesId,
          holding: deleteData.currentCph,
          adjacentHolding: deleteData.adjacentHolding,
          fromDate: modalData.fromDate,
          holdingLinkTypeId: deleteData.holdingLinkTypeId
        }
      };

      if (Object.keys(deleteData.holdings).includes(deleteData.adjacentHolding)) {
        params.params.inBusiness = true;
      }

      bff
        .delete('/deleteAdjacentMovement', params)
        .then(() => {
          storeService.session.removeAll.searchResults();
          setEventType((prevState) => ({
            ...prevState,
            refresh: true
          }));

          deleteData.setSubmitStatus(constants.status.success);
          deleteData.setShowConfirmation(true);
          setDeletePending(false);
          setDeleteData({});
        })
        .catch((error) => {
          deleteData.setSubmitStatus(constants.status.error);
          errors.BFF(error, setModal);
          setDeletePending(false);
        });
    },

    deleteAnnualInventory: () => {
      setDeleteConfirmed(true);
      setDeletePending(true);
      setConfirmAction(false);

      const params = {
        cph: sessionHolding.value,
        date: rowToDelete?.inventoryDate,
        poll: config.POLLS_ENABLED,
        species: sessionSpecies.name
      };

      bff
        .post('/deleteAnnualInventory', params)
        .then((response) => {
          storeService.session.removeAll.searchResults();
          setEventType((prevState) => ({
            ...prevState,
            refresh: true
          }));

          setPolling({
            requestId: String(response.data.requestId),
            status: response.data.poll ? response.data.poll.requestStatus : constants.status.claPending,
            errors: response.data.poll ? response.data.poll.errors : [],
            warnings: response.data.poll ? response.data.poll.warnings : []
          });
          setDeletePending(false);
        })
        .catch((error) => {
          errors.BFF(error, setModal);
          setDeletePending(false);
        });
    }
  };

  const modal = {
    closeAndReset: () => {
      setConfirmAction(false);
      setModalVisible(false);
      setModalHasChanges(false);
      setDeleteConfirmed(false);
    },

    getContent: () => {
      if (helpers.holdingRegister.types.isAdjacentMovement(eventType.current)) {
        return (
          <ModalAdjacentMovements
            modalClose={() => modalHasChanges ? setConfirmAction(constants.modalActions.close) : modal.hide()}
            modalData={modalData}
            modalMode={modalMode}
            setConfirmAction={setConfirmAction}
            setDeleteData={setDeleteData}
            setModal={setModal}
            setModalHasChanges={setModalHasChanges}
            setModalSubmitted={setModalSubmitted}
          />
        );
      }

      if (helpers.holdingRegister.types.isAnnualInventory(eventType.current)) {
        if (deleteConfirmed) {
          if (deletePending && (!polling.requestId || !polling.status)) {
            return (
              <Confirmation
                label="label.submissionPending"
                setModal={setModal}
                status={constants.status.pending}
              />
            );
          }

          return (
            <Confirmation
              confirm={{ id: polling.requestId, label: 'label.reference' }}
              errors={polling.errors}
              label="label.annualInventoryCancellationSuccessful"
              setModal={setModal}
              status={polling.status}
              warnings={polling.warnings}
            />
          );
        }

        return (
          <ModalAnnualInventory
            modalClose={() => modalHasChanges ? setConfirmAction(constants.modalActions.close) : modal.hide()}
            previouslySubmittedYears={data.map((item) => item.inventoryDate.substring(0, 4))}
            setModal={setModal}
            setModalHasChanges={setModalHasChanges}
            setModalSubmitted={setModalSubmitted}
          />
        );
      }
    },

    getHeaderContent: () => {
      if (helpers.holdingRegister.types.isAdjacentMovement(eventType.current)) {
        switch (modalMode) {
          case constants.modalActions.edit:
            return t('label.updateAdjacentMovement');
          case constants.modalActions.view:
            return t('label.viewAdjacentMovement');
          case constants.modalActions.add:
          default:
            return t('label.recordAdjacentMovement');
        }
      } else if (helpers.holdingRegister.types.isAnnualInventory(eventType.current)) {
        return t('label.recordAnnualInventory');
      }
    },

    hide: () => {
      if (modalHasChanges) {
        setConfirmAction(false);
      } else {
        modal.closeAndReset();
      }
    },

    show: (mode, data) => {
      setModalMode(mode);
      setModalData(data);
      setModalVisible(true);
    }
  };

  const tableRowLinks = {
    getHint: (row) => {
      if (row.isBoConfirmOrAmend) {
        return t('label.submittedByLisCustomerSupport');
      }

      if (!helpers.holdingRegister.types.isAdjacentMovement(eventType.current) && !supportedRequestTypes.includes(row.requestTypeId)) {
        return t('label.summaryNotAvailable');
      }

      return null;
    },

    getLinkIf: (row) => {
      return (
        helpers.holdingRegister.types.isAdjacentMovement(eventType.current) ||
        (
          supportedRequestTypes.includes(row.requestTypeId) &&
          (
            (
              !helpers.holdingRegister.types.isMovementOff(eventType.current) &&
              !helpers.holdingRegister.types.isMovementOn(eventType.current)
            ) ||
            (
              helpers.holdingRegister.types.isMovementOff(eventType.current) &&
              row.destinationHolding !== constants.cph.none &&
              !row.isBoConfirmOrAmend
            ) ||
            (
              helpers.holdingRegister.types.isMovementOff(eventType.current) &&
              row.destinationHolding === constants.cph.none &&
              !row.isBoConfirmOrAmend &&
              helpers.option.requestType.isMovement(row.requestTypeId)
            ) ||
            (
              helpers.holdingRegister.types.isMovementOn(eventType.current) &&
              row.sourceHolding !== constants.cph.none &&
              !row.isBoConfirmOrAmend
            ) ||
            (
              helpers.holdingRegister.types.isMovementOn(eventType.current) &&
              row.sourceHolding === constants.cph.none &&
              !row.isBoConfirmOrAmend &&
              helpers.option.requestType.isMovement(row.requestTypeId)
            )
          )
        )
      );
    },

    getOnClick: (row) => {
      if (helpers.holdingRegister.types.isAdjacentMovement(eventType.current)) {
        modal.show(getEndDate(row.endDate) ? 'view' : 'edit', row);
      } else {
        setPermission(userRoleType.GENERIC);
        storeService.session.set.permissions(userRoleType.GENERIC);
        storeService.session.remove.holding();
      }
    },

    getState: (row) => {
      return {
        requestId: row.requestId,
        requestType: row.requestTypeId,
        current: {
          returnUrl,
          permissions: sessionPermissions,
          holding: sessionHolding
        }
      };
    },

    getUrl: (row) => {
      return supportedRequestTypes.includes(row.requestTypeId) ? Routing.activityDetails : null;
    }
  };

  const tableParams = {
    request: {
      url: '/holdingRegister',
      params: {
        cph: sessionHolding?.value,
        speciesId
      }
    }
  };

  useEffect(() => {
    const pathname = location.pathname.slice(25, -1);

    setData([]);
    storeService.session.removeAll.searchResults();

    switch (pathname) {
      case 'adjacent-movements':
        setEventType((prevState) => ({
          current: constants.holdingRegister.type.adjacentMovements,
          previous: prevState.current
        }));
        setReturnUrl(Routing.holdingRegisterAdjacentMovements);
        break;
      case 'annual-inventory':
        setEventType((prevState) => ({
          current: constants.holdingRegister.type.annualInventory,
          previous: prevState.current
        }));
        setReturnUrl(Routing.holdingRegisterAnnualInventory);
        break;
      case 'births':
        setEventType((prevState) => ({
          current: constants.holdingRegister.type.births,
          previous: prevState.current
        }));
        setReturnUrl(Routing.holdingRegisterBirths);
        break;
      case 'deaths':
        setEventType((prevState) => ({
          current: constants.holdingRegister.type.deaths,
          previous: prevState.current
        }));
        setReturnUrl(Routing.holdingRegisterDeaths);
        break;
      case 'download':
        setEventType((prevState) => ({
          current: constants.holdingRegister.type.download,
          previous: prevState.current
        }));
        setReturnUrl(Routing.holdingRegisterDownload);
        break;
      case 'movements-off':
        setEventType((prevState) => ({
          current: constants.holdingRegister.type.movementsOff,
          previous: prevState.current
        }));
        setReturnUrl(Routing.holdingRegisterMovementsOff);
        break;
      case 'movements-on':
        setEventType((prevState) => ({
          current: constants.holdingRegister.type.movementsOn,
          previous: prevState.current
        }));
        setReturnUrl(Routing.holdingRegisterMovementsOn);
        break;
      case 'tagging-applications':
        setEventType((prevState) => ({
          current: constants.holdingRegister.type.tagApplications,
          previous: prevState.current
        }));
        setReturnUrl(Routing.holdingRegisterTaggingApplications);
        break;
      case 'tagging-replacement':
        setEventType((prevState) => ({
          current: constants.holdingRegister.type.tagReplacements,
          previous: prevState.current
        }));
        setReturnUrl(Routing.holdingRegisterTaggingReplacement);
        break;
      default:
        setEventType({
          current: '',
          previous: ''
        });
        break;
    }
  }, [location]);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.holdingRegister.length) !== Routing.holdingRegister &&
          path.pathname.substring(0, Routing.activityDetails.length) !== Routing.activityDetails
      ) {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
        unListen();
      }
    });
  }, [history]);

  useEffect(() => {
    if (modalSubmitted) {
      setModalSubmitted(false);
      setEventType((prevState) => ({
        ...prevState,
        refresh: true
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalSubmitted]);

  return (
    <>
      {ready &&
        <>
          {modalVisible &&
            <Modal
              headerContent={modal.getHeaderContent()}
              id="modalAdjacentMoves"
              modalClose={() => modalHasChanges ? setConfirmAction(constants.modalActions.close) : modal.hide()}
            >
              {modal.getContent()}
            </Modal>
          }

          {confirmAction &&
            <ModalConfirm
              currentOpenModalId={helpers.holdingRegister.types.isAdjacentMovement(eventType.current)
                ? 'modalAdjacentMoves'
                : 'modalAnnualInventory'
              }
              id="confirmCloseModal"
              modalClose={() => setConfirmAction(false)}
              modalConfirm={() => {
                if (confirmAction === constants.modalActions.close) {
                  modal.closeAndReset();
                } else if (helpers.holdingRegister.types.isAdjacentMovement(eventType.current)) {
                  handleChanges.deleteAdjacentMovement();
                } else if (helpers.holdingRegister.types.isAnnualInventory(eventType.current)) {
                  handleChanges.deleteAnnualInventory();
                }
              }}
              modalMessage={confirmAction === constants.modalActions.delete
                ? (helpers.holdingRegister.types.isAdjacentMovement(eventType.current)
                  ? 'warning.deleteAdjacentMovement'
                  : 'warning.deleteAnnualInventory'
                )
                : 'warning.unsavedChanges2'
              }
              modalTitle={confirmAction === constants.modalActions.delete
                ? 'label.deleteRecord'
                : 'warning.youHaveUnsavedChanges'
              }
            />
          }

          {deleteConfirmed &&
            <Modal
              classNames={['notification']}
              headerContent={t('label.deleteRecord')}
              id="modalAdjacentMoves"
              modalClose={deletePending ? null : () => modal.hide()}
              showConfirmation={true}
              submitChanges={() => {}}
              submitPending={deletePending}
            >
              {modal.getContent()}
            </Modal>
          }

          <h1 className="h1">{t('reports.holdingRegister')}</h1>
          <NavBarSub />
          <h2 className="h2">{t(`subMenu.${eventType.current?.charAt(0).toLowerCase() + eventType.current?.slice(1)}`)}</h2>

          {(helpers.holdingRegister.types.isMovementOff(eventType.current) || helpers.holdingRegister.types.isMovementOn(eventType.current)) &&
            <AboutPanel
              descriptions={[
                { text: 'about.holdingRegisterMovements.desc1' },
                { text: 'about.holdingRegisterMovements.desc2', href: constants.url.defraSupport }
              ]}
              title="about.genericTitle.page"
            />
          }

          {helpers.holdingRegister.types.isAnnualInventory(eventType.current) &&
            <AboutPanel
              descriptions={[
                { text: 'about.annualInventory.desc1' },
                { text: 'about.annualInventory.desc2' },
                { text: helpers.species.isSheepId(speciesId) ? 'about.annualInventory.desc3-sheep' : 'about.annualInventory.desc3-goats' },
                { text: 'about.annualInventory.desc4', href: constants.url.defraGuidance }
              ]}
              open={true}
              title="about.genericTitle.page"
            />
          }

          {eventType.current &&
            <>
              <div className={classes.hrContainer}>
                <Table
                  columnParams={{
                    getEndDate,
                    modalShow: modal.show,
                    setModal,
                    showDeleteConfirm: (row) => {
                      setRowToDelete(row);
                      setConfirmAction(constants.modalActions.delete);
                    },
                    speciesId
                  }}
                  data={data}
                  eventType={eventType}
                  filtersComponents={{
                    [constants.filters.outside]: 'HoldingRegister'
                  }}
                  hidePagination={helpers.holdingRegister.types.isAnnualInventory(eventType.current)}
                  initialSortBy={helpers.holdingRegister.types.isDownload(eventType.current) ? undefined : initialSorting.holdingRegister[eventType.current].accessor}
                  initialSortDirection={helpers.holdingRegister.types.isDownload(eventType.current) ? undefined : initialSorting.holdingRegister[eventType.current].direction}
                  params={tableParams}
                  renderTable={helpers.holdingRegister.types.isDownload(eventType.current) ? false : undefined}
                  rowLinks={{
                    linkIf: tableRowLinks.getLinkIf,
                    hint: tableRowLinks.getHint,
                    url: tableRowLinks.getUrl,
                    accessor: 'requestId',
                    onClick: tableRowLinks.getOnClick,
                    state: tableRowLinks.getState
                  }}
                  setData={setData}
                  setEventType={setEventType}
                  setModal={setModal}
                  store={{
                    filters: constants.holdingRegister.table[eventType.current],
                    data: constants.holdingRegister.table[eventType.current]
                  }}
                />

                <div className={classes.actions}>
                  {helpers.holdingRegister.types.isAdjacentMovement(eventType.current) &&
                    <Button
                      buttonType="link"
                      label="label.recordAdjacentMovement"
                      onClick={() => modal.show(constants.modalActions.add)}
                    />
                  }
                  {helpers.holdingRegister.types.isAnnualInventory(eventType.current) &&
                    <Button
                      buttonType="link"
                      label="label.recordAnnualInventory"
                      onClick={modal.show}
                    />
                  }
                </div>
              </div>
            </>
          }
        </>
      }
    </>
  );
};

HoldingRegister.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func.isRequired
};

export default HoldingRegister;
