import React, { useEffect, useState } from 'react';
import classes from 'components/modalFileUpload/modalFileUpload.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import { tableColumns } from 'services/tableColumns';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FileUpload from 'components/base/fileUpload/fileUpload';
import Table from 'components/table/table';

const ModalFileUpload = ({
  clearAll,
  isTagLimitExeed,
  maxTagsAllowed,
  modalAnimals,
  notes,
  pending,
  removeAllInvalid,
  removeAnimal,
  setModal,
  setModalAnimals,
  setPending,
  species,
  upload,
  uploadInPopup
}) => {
  const [paginationParams, setPaginationParams] = useState(constants.pagination.defaultParams);
  const [uploadFile, setuploadFile] = useState(false);

  useEffect(() => {
    setuploadFile(uploadInPopup ? 'uploadInPage' : '');
    setPaginationParams((prevState) => ({
      ...prevState,
      [constants.pagination.bottom]: {
        ...prevState[constants.pagination.bottom],
        total: modalAnimals?.length
      }
    }));
  }, [modalAnimals]);

  return (
    <div className={classes.content}>
      <div className={classes.input}>
        <FileUpload
          description={[
            'label.upload.uploadDesc',
            helpers.species.idToName(species.id) === 'Sheep' ? 'label.upload.uploadDescSpecies-1' : 'label.upload.uploadDescSpecies-2',
            'label.upload.uploadDescLink'
          ]}
          hideHint={true}
          hideTooltip={true}
          maxTagsAllowed={maxTagsAllowed}
          modalMessage={helpers.tag.getFormatExamples(species.id).description}
          modalTitle={helpers.tag.getFormatExamples(species.id).title}
          notes={notes}
          pending={pending}
          setAnimalsToChange={upload}
          setModal={setModal}
          setPending={setPending}
          species={species}
        />
      </div>

      {modalAnimals && helpers.tag.hasNonEidTagsSheep(species.id, modalAnimals) &&
        <ErrorLabel
          isWarning={true}
          label="formatExamples.tagBatchNonEidError"
          modalMessage={helpers.tag.getFormatExamples(species.id).description}
          modalTitle={helpers.tag.getFormatExamples(species.id).title}
          setModal={setModal}
        />
      }

      {modalAnimals?.some((tag) => helpers.tag.isInvalid(tag)) &&
        <ErrorLabel
          label="formatExamples.tagBatchError"
          modalMessage={helpers.tag.getFormatExamples(species.id).description}
          modalTitle={helpers.tag.getFormatExamples(species.id).title}
          setModal={setModal}
        />
      }

      {isTagLimitExeed &&
        <ErrorLabel
          label="abattoirAttestation.maxTagLimitErrorMessage"
          setModal={setModal}
        />
      }

      {!pending && modalAnimals?.length > 0 &&
        <div className={classes.output + ' ' + classes[uploadFile]}>
          <div className={classes.tableContainer}>
            <Table
              clearAll={clearAll}
              columns={tableColumns.movements.modalFileUpload({ actionFunction: removeAnimal, sortable: true })}
              data={modalAnimals}
              dataProvided={true}
              paginationParams={paginationParams}
              removeAllInvalid={removeAllInvalid}
              setData={setModalAnimals}
              setModal={setModal}
              title={{
                title: 'table.title.animalsEntered',
                data: [
                  modalAnimals?.length
                ]
              }}
            />
          </div>
        </div>
      }
    </div>
  );
};

ModalFileUpload.propTypes = {
  clearAll: PropTypes.func.isRequired,
  isTagLimitExeed: PropTypes.bool,
  maxTagsAllowed: PropTypes.number,
  modalAnimals: PropTypes.array.isRequired,
  notes: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  removeAllInvalid: PropTypes.func.isRequired,
  removeAnimal: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setModalAnimals: PropTypes.func.isRequired,
  setPending: PropTypes.func.isRequired,
  species: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    notifications: PropTypes.number,
    errorFound: PropTypes.number
  }),
  upload: PropTypes.func.isRequired,
  uploadInPopup: PropTypes.bool
};

export default ModalFileUpload;
