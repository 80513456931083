import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Button from 'components/base/button/button';
import FciFormFields from 'frontEnd/movements/step4/fciFormFields/fciFormFields';
import InfoContainer from 'components/base/infoContainer/infoContainer';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Table from 'components/table/table';
import TransportInformationBox from 'components/summary/transportInformationBox/transportInformationBox';

const MovementStep4 = ({
  animalsToChange,
  movementData,
  numberBeingMoved,
  setAnimalsToChange,
  setDataChanged,
  setFCIData,
  setModal,
  species
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const speciesId = storeService.session.get.species().id;

  const [doAnimalsSatisfy, setDoAnimalsSatisfy] = React.useState('');
  const [recordFCI, setRecordFCI] = React.useState(null);
  const [choiceDisabled, setChoiceDisabled] = React.useState(true);
  const [nonComplianceReason, setNonComplianceReason] = React.useState('');
  const [withdrawalPeriod, setWithdrawalPeriod] = React.useState();
  const [holdingRestrictions, setHoldingRestrictions] = React.useState('');
  const [metFCIWithdrawalPeriods, setMetFCIWithdrawalPeriods] = React.useState();

  const paginationParams = constants.pagination.defaultParams;
  paginationParams[constants.pagination.bottom].total = animalsToChange === undefined ? 0 : animalsToChange.animalsBeingMoved === undefined ? 0 : animalsToChange.animalsBeingMoved?.length;

  const submitValues = () => {
    setFCIData(storeService.session.get.movementFci());
  };

  const nextPage = () => {
    submitValues();
    setDataChanged();
    history.push(Routing.movementsSummary);
    helpers.scrollToTop();
  };

  const canUserContinue = () => {
    if (choiceDisabled || helpers.option.fci.doNotRecord(recordFCI) || helpers.option.animals.doSatisfy(doAnimalsSatisfy)) {
      return false;
    }
    return (
      !recordFCI || !nonComplianceReason || !withdrawalPeriod || !animalsToChange.animalsBeingMoved.filter((item) => item.unfit).length > 0
    );
  };

  const handleChanges = {
    animalSelected: (animal) => {
      if (animal.unfit) {
        setAnimalsToChange((prevState) => {
          const newAnimalsBeingMoved = prevState.animalsBeingMoved?.map((item) => item.tagNumber === animal.tagNumber
            ? { ...item, unfit: false }
            : item
          );

          const newAnimalsToChange = {
            ...prevState,
            animalsBeingMoved: newAnimalsBeingMoved
          };

          storeService.session.set.movementAnimalsBeingMoved(newAnimalsBeingMoved);

          return newAnimalsToChange;
        });
      } else {
        setAnimalsToChange((prevState) => {
          const newAnimalsBeingMoved = prevState.animalsBeingMoved?.map((item) => item.tagNumber === animal.tagNumber
            ? { ...item, unfit: true }
            : item
          );

          const newAnimalsToChange = {
            ...prevState,
            animalsBeingMoved: newAnimalsBeingMoved
          };

          storeService.session.set.movementAnimalsBeingMoved(newAnimalsBeingMoved);

          return newAnimalsToChange;
        });
      }
    },

    holdingRestriction: (restrictions) => {
      setHoldingRestrictions(restrictions);
      storeService.session.set.movementFciHoldingRestrictions(restrictions);
    },

    nonComplianceReason: (reason) => {
      setNonComplianceReason(reason);
      storeService.session.set.movementFciNonComplianceReason(reason);
    },

    recordFCI: (record) => {
      setRecordFCI(record);
      storeService.session.set.movementFciRecordFci(record);

      if (helpers.option.fci.doNotRecord(record)) {
        setAnimalsToChange((prevState) => ({
          ...prevState,
          // eslint-disable-next-line no-unused-vars
          animalsBeingMoved: prevState.animalsBeingMoved?.map(({ unfit, ...item }) => item)
        }));

        setDoAnimalsSatisfy(null);
        storeService.session.remove.movementFciAnimalsSatisfy();
      } else if (
        animalsToChange.batchList?.length > 0 &&
        (
          !animalsToChange.animalsBeingMoved ||
          animalsToChange.animalsBeingMoved?.length === 0
        )
      ) {
        setDoAnimalsSatisfy(constants.option.animalsSatisfy.doSatisfy);
        storeService.session.set.movementFciAnimalsSatisfy(constants.option.animalsSatisfy.doSatisfy);
      }
    },

    satisfy: (animalsSatisfy) => {
      setDoAnimalsSatisfy(animalsSatisfy);
      storeService.session.set.movementFciAnimalsSatisfy(animalsSatisfy);

      if (helpers.option.animals.doSatisfy(animalsSatisfy)) {
        setNonComplianceReason('');
        setHoldingRestrictions('');
        storeService.session.remove.movementFciNonComplianceReason();
        storeService.session.remove.movementFciHoldingRestrictions();

        setAnimalsToChange((prevState) => ({
          ...prevState,
          // eslint-disable-next-line no-unused-vars
          animalsBeingMoved: prevState.animalsBeingMoved.map(({ unfit, ...item }) => item)
        }));
      }
    },

    withdrawalPeriod: (period) => {
      setWithdrawalPeriod(period);
      storeService.session.set.movementFciWithdrawalPeriod(period);
    }
  };

  useEffect(() => {
    if (
      animalsToChange?.animalsBeingMoved?.length > 0 ||
      animalsToChange?.batchList?.length > 0
    ) {
      setChoiceDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animalsToChange]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    helpers.get.metFciWithdrawalPeriods(source.token)
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          setMetFCIWithdrawalPeriods(res.data);
        }
      });

    const sessionRecordFCI = storeService.session.get.movementFciRecordFci();

    if (sessionRecordFCI) {
      setRecordFCI(sessionRecordFCI);

      if (helpers.option.fci.doRecord(sessionRecordFCI)) {
        const sessionAnimalsSatisfy = storeService.session.get.movementFciAnimalsSatisfy();

        if (sessionAnimalsSatisfy) {
          setDoAnimalsSatisfy(sessionAnimalsSatisfy);

          if (helpers.option.animals.doNotSatisfy(sessionAnimalsSatisfy)) {
            const sessionNonComplianceReason = storeService.session.get.movementFciNonComplianceReason();
            const sessionWithdrawalPeriod = storeService.session.get.movementFciWithdrawalPeriod();
            const sessionHoldingRestrictions = storeService.session.get.movementFciHoldingRestrictions();

            if (sessionNonComplianceReason) {
              setNonComplianceReason(sessionNonComplianceReason);
            }
            if (sessionWithdrawalPeriod) {
              setWithdrawalPeriod(sessionWithdrawalPeriod);
            }
            if (sessionHoldingRestrictions) {
              setHoldingRestrictions(sessionHoldingRestrictions);
            }
          }
        }
      }
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <>
          <h2 className="h2">{t('movements.fci-information.title')}</h2>

          <AboutPanel
            descriptions={[
              { text: 'about.movementsStep4.desc1' },
              { text: 'about.movementsStep4.desc2' }
            ]}
            title="about.genericTitle.step"
          />

          <TransportInformationBox
            movementData={movementData}
            numberBeingMoved={numberBeingMoved}
            setModal={setModal}
            speciesName={species.name}
          />
          <div className="row">
            <div className="col">
              <div className="section">
                <RadioButtons
                  ids={[
                    constants.option.recordFCI.yes,
                    constants.option.recordFCI.no
                  ]}
                  name="recordFCI"
                  onChange={(event) => handleChanges.recordFCI(event.target.value)}
                  value={recordFCI}
                />
              </div>
            </div>
          </div>

          {helpers.option.fci.doRecord(recordFCI) &&
            <>
              <InfoContainer
                content={helpers.species.isDeerId(speciesId) ? 'movements.fci-information.fciStatement.deer' : 'movements.fci-information.fciStatement.sheepAndGoats'}
                title="movements.fci-information.fciStatement.title"
              />

              <div className="section">
                <RadioButtons
                  disabled={choiceDisabled
                    ? [
                      constants.option.animalsSatisfy.doSatisfy,
                      constants.option.animalsSatisfy.doNotSatisfy
                    ]
                    : (
                      animalsToChange.batchList?.length > 0 &&
                      (
                        !animalsToChange.animalsBeingMoved ||
                        animalsToChange.animalsBeingMoved?.length === 0
                      )
                        ? [constants.option.animalsSatisfy.doNotSatisfy]
                        : []
                    )}
                  hint={animalsToChange.batchList?.length > 0 ? 'hint2' : 'hint1'}
                  ids={[
                    constants.option.animalsSatisfy.doSatisfy,
                    constants.option.animalsSatisfy.doNotSatisfy
                  ]}
                  name="doAnimalsSatisfy"
                  onChange={(event) => handleChanges.satisfy(event.target.value)}
                  value={doAnimalsSatisfy}
                />
              </div>

              {helpers.option.animals.doNotSatisfy(doAnimalsSatisfy) &&
                <>
                  <fieldset role="group">
                    <InfoContainer
                      content="movements.fci-information.fciAnimals"
                    />

                    <p className="p">{t('movements.fci-information.fciAnimalsSelect')}</p>

                    <div className="section">
                      <Table
                        columns={helpers.species.isDeerId(speciesId)
                          ? tableColumns.movements.fci.deer({ animalSelected: handleChanges.animalSelected })
                          : tableColumns.movements.fci.nonDeer({ animalSelected: handleChanges.animalSelected })
                        }
                        data={animalsToChange.animalsBeingMoved.map((item) => ({
                          ...item,
                          unfit: Boolean(item.unfit)
                        }))}
                        dataProvided={true}
                        initialSortBy={initialSorting.movements.fci.accessor}
                        initialSortDirection={initialSorting.movements.fci.direction}
                        paginationParams={paginationParams}
                        setData={setAnimalsToChange}
                        setDataProp="animalsBeingMoved"
                        setModal={setModal}
                      />
                    </div>

                    <div className="section">
                      <FciFormFields
                        holdingRestrictions={holdingRestrictions}
                        metFCIWithdrawalPeriods={metFCIWithdrawalPeriods}
                        nonComplianceReason={nonComplianceReason}
                        setHoldingRestrictions={handleChanges.holdingRestriction}
                        setNonComplianceReason={handleChanges.nonComplianceReason}
                        setWithdrawalPeriod={handleChanges.withdrawalPeriod}
                        speciesId={speciesId}
                        withdrawalPeriod={withdrawalPeriod}
                      />
                    </div>
                  </fieldset>
                </>
              }
            </>
          }
          <Button
            buttonType="primary"
            disabled={canUserContinue()}
            label="button.continue"
            onClick={nextPage}
          />
        </>
      }
    </>
  );
};

MovementStep4.propTypes = {
  animalsToChange: PropTypes.object,
  movementData: PropTypes.object,
  numberBeingMoved: PropTypes.number,
  setAnimalsToChange: PropTypes.func.isRequired,
  setDataChanged: PropTypes.func,
  setFCIData: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  species: PropTypes.object
};

export default MovementStep4;
