import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/base/checkbox/checkbox.module.scss';

const Checkbox = ({
  disabled,
  id,
  inline,
  isChecked,
  label,
  name,
  onChange,
  value
}) => {
  const { ready, t } = useTranslation();

  const componentClassNames = [
    classes.checkbox,
    inline ? classes.inline : '',
    disabled ? classes.disabled : ''
  ].join(' ').trim();

  return (
    <>
      {ready &&
        <label className={componentClassNames} htmlFor={id}>
          {t(label)}
          <input
            checked={isChecked}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            type="checkbox"
            value={value}
          />
          <span className={classes.checkmark} />
        </label>
      }
    </>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  isChecked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.bool
};

export default Checkbox;
