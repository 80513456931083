import React, { createRef, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';
import bff from 'services/bff';
import breedsHelper from 'services/breedsHelper';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import navigation from 'services/navigation';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import Routing from 'routing';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Button from 'components/base/button/button';
import CheckBox from 'components/base/checkbox/checkbox';
import Confirmation from 'components/base/confirmation/confirmation';
import InputDate from 'components/base/inputDate/inputDate';
import DropdownBreeds from 'components/dropdownBreeds/dropdownBreeds';
import DropdownDamIds from 'components/dropdownDamIds/dropdownDamIds';
import DropdownSireIds from 'components/dropdownSireIds/dropdownSireIds';
import DropdownTagNumbersAvailable from 'components/dropdownTagNumbersAvailable/dropdownTagNumbersAvailable';
import GeneticDamTab from 'components/geneticDamTab/geneticDamTab';
import Legend from 'components/base/legend/legend';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Tabs from 'components/base/tabs/tabs';
import TextEntry from 'components/base/textEntry/textEntry';

const Births = ({
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const geneticDamTabs = createRef();
  const sireTabs = createRef();

  const sessionDataChanged = storeService.session.get.dataChanged();

  const [dataChanged, setDataChanged] = React.useState(sessionDataChanged ? sessionDataChanged : false);

  const [tagNumber, setTagNumber] = useState(null);
  const [breedId, setBreedId] = useState(null);
  const [birthDamId, setBirthDamId] = useState(null);
  const [geneticDamId, setGeneticDamId] = useState(null);
  const [geneticDamAI, setGeneticDamAI] = useState(null);
  const [sireId, setSireId] = useState(null);
  const [sireAI, setSireAI] = useState(null);
  const [gender, setGender] = useState(null);
  const [embryo, setEmbryo] = useState(constants.option.embryo.no);
  const [entryDate, setEntryDate] = useState(null);
  const [entryDateError, setEntryDateError] = useState('');
  const [entryDateWarning, setEntryDateWarning] = useState('');
  const [registerAnother, setRegisterAnother] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [geneticDamIdError, setGeneticDamIdError] = useState(null);
  const [genderError, setGenderError] = useState(null);

  const [activeTabGeneticDam, setActiveTabGeneticDam] = useState(constants.tabs.onHolding);
  const [activeTabSire, setActiveTabSire] = useState(constants.tabs.onHolding);

  const sessionSpecies = storeService.session.get.species();
  const speciesId = sessionSpecies ? sessionSpecies.id : '';
  const tagNumberLabel = 'label.tagNumber' + speciesId;

  const defaults = () => {
    setTagNumber(null);
    setBreedId(null);
    setGeneticDamId(null);
    setGeneticDamAI(null);
    setGender(null);
    setEmbryo(constants.option.embryo.no);

    if (geneticDamTabs.current) {
      geneticDamTabs.current.reset();
    }
    if (sireTabs.current) {
      sireTabs.current.reset();
    }

    if (!registerAnother) {
      setBirthDamId(null);
      setSireId(null);
      setSireAI(null);
      setEntryDate(null);
      setEntryDateError('');
      setEntryDateWarning('');
    }
  };

  const validateForm = () => {
    let errorCount = 0;

    if (helpers.option.embryo.isTransfer(embryo)) {
      if (!geneticDamId && !geneticDamAI) {
        errorCount++;
        setGeneticDamIdError('error.selection');
        geneticDamTabs.current.onClickTabItem(constants.tabs.onHolding);
      }
    } else {
      setGeneticDamIdError(null);
    }

    if (!gender) {
      errorCount++;
      setGenderError('error.gender');
    }

    return errorCount === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setDisableSubmit(true);

      bff
        .post('/birth', {
          species: storeService.session.get.species().name,
          cph: storeService.session.get.holding().value,
          tagNumber,
          breed: breedId,
          breedName: breedsHelper.getBreedName(breedId),
          birthDamId: birthDamId,
          geneticDamId: geneticDamAI
            ? geneticDamAI
            : geneticDamId ? geneticDamId : '',
          geneticDamAI,
          sireId: sireAI
            ? sireAI
            : sireId ? sireId : '',
          gender,
          embryo,
          dob: entryDate
        })
        .then((res) => {
          setDataChanged(false);
          storeService.session.remove.dataChanged();
          storeService.session.set.confirmRequestId(String(res.data.requestId));

          history.push(Routing.birthsConfirm);
          helpers.scrollToTop();
          defaults();
        })
        .catch((error) => {
          errors.BFF(error, setModal);
          setDisableSubmit(false);
        });
    }
  };

  const validateDate = (date) => {
    let hasError = false;

    setEntryDateError('');
    setEntryDateWarning('');

    const dateIsOver27DaysAgo = helpers.date.is.xDaysInThePast(date, config.MAX_BIRTH_DATE_RANGE);
    const dateIsInTheFuture = helpers.date.is.inTheFuture(date, constants.dateTypes.birth);

    if (!hasError && dateIsInTheFuture) {
      setEntryDateError(dateIsInTheFuture);

      hasError = true;
    }

    if (!hasError && dateIsOver27DaysAgo) {
      setEntryDateWarning('warning.birthDateBelowRange');
    }
  };

  const handleChanges = {
    activeTabGeneticDam: (tab) => {
      setActiveTabGeneticDam(tab);
      storeService.session.set.birthActiveTabGeneticDam(tab);
    },

    activeTabSire: (tab) => {
      setActiveTabSire(tab);
      storeService.session.set.birthActiveTabSire(tab);
    },

    birthDamId: (event) => {
      setBirthDamId(event.target.value);
      storeService.session.set.birthDamId(event.target.value);
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    breed: (event) => {
      setBreedId(event.target.value);
      storeService.session.set.birthBreed({
        id: event.target.value,
        name: breedsHelper.getBreedName(event.target.value)
      });
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    date: (date) => {
      setEntryDate(date);
      storeService.session.set.birthBirthDate(date);
      validateDate(date);
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    embryo: (event) => {
      setEmbryo(event.target.value);
      setGeneticDamAI(null);
      setGeneticDamId(null);
      storeService.session.set.birthEmbryo(event.target.value);
      storeService.session.remove.birthGeneticDamId();
      storeService.session.remove.birthGeneticDamAI();
      setGeneticDamIdError(null);
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    gender: (event) => {
      setGender(event.target.value);
      storeService.session.set.birthGender(event.target.value);
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    geneticDamAI: (event) => {
      setGeneticDamAI(event);
      setGeneticDamId(null);
      storeService.session.set.birthGeneticDamAI(event);
      storeService.session.remove.birthGeneticDamId();
    },

    geneticDamId: (event) => {
      setGeneticDamId(event);
      setGeneticDamAI(null);
      storeService.session.set.birthGeneticDamId(event);
      storeService.session.remove.birthGeneticDamAI();
    },

    registerAnother: () => {
      const another = !registerAnother;
      setRegisterAnother(another);
      if (another) {
        storeService.session.set.birthRegisterAnother(another);
      } else {
        storeService.session.remove.birthRegisterAnother();
      }
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    sireAI: (event) => {
      setSireAI(event.target.value);
      setSireId(null);
      storeService.session.set.birthSireAI(event.target.value);
      storeService.session.remove.birthSireId();
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    sireId: (event) => {
      setSireId(event.target.value);
      setSireAI(null);
      storeService.session.set.birthSireId(event.target.value);
      storeService.session.remove.birthSireAI();
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    tagNumber: (event) => {
      setTagNumber(event.target.value);
      storeService.session.set.birthTagNumber(event.target.value);
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    }
  };

  const getDates = {
    dateMax: () => {
      const today = new Date();

      return helpers.date.formatYYYYMMDD(today);
    }
  };

  useEffect(() => {
    const status =
      !tagNumber ||
      !breedId ||
      !birthDamId ||
      !gender ||
      (helpers.option.embryo.isTransfer(embryo) && !geneticDamAI && !geneticDamId) ||
      !entryDate ||
      entryDateError;

    setDisableSubmit(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagNumber, breedId, birthDamId, gender, embryo, geneticDamAI, geneticDamId, entryDate, entryDateError, setDisableSubmit]);

  useEffect(() => {
    const sessionTagNumber = storeService.session.get.birthTagNumber();
    const sessionBirthDate = storeService.session.get.birthBirthDate();
    const sessionBreed = storeService.session.get.birthBreed();
    const sessionGender = storeService.session.get.birthGender();
    const sessionEmbryo = storeService.session.get.birthEmbryo();
    const sessionBirthDamId = storeService.session.get.birthDamId();
    const sessionSireId = storeService.session.get.birthSireId();
    const sessionSireAI = storeService.session.get.birthSireAI();
    const sessionActiveTabGeneticDam = storeService.session.get.birthActiveTabGeneticDam();
    const sessionActiveTabSire = storeService.session.get.birthActiveTabSire();

    if (sessionTagNumber) {
      setTagNumber(sessionTagNumber);
    }

    if (sessionBirthDate) {
      setEntryDate(sessionBirthDate);
      validateDate(sessionBirthDate);
    }

    if (sessionBreed) {
      setBreedId(sessionBreed.id ? sessionBreed.id : null);
    }

    if (sessionGender) {
      setGender(sessionGender);
    }

    if (sessionEmbryo) {
      setEmbryo(sessionEmbryo);

      if (helpers.option.embryo.isTransfer(sessionEmbryo)) {
        const sessionGeneticDamId = storeService.session.get.birthGeneticDamId();
        const sessionGeneticDamAI = storeService.session.get.birthGeneticDamAI();

        if (sessionGeneticDamId) {
          setGeneticDamId(sessionGeneticDamId);
        }

        if (sessionGeneticDamAI) {
          setGeneticDamAI(sessionGeneticDamAI);
        }
      }
    }

    if (sessionBirthDamId) {
      setBirthDamId(sessionBirthDamId);
    }

    if (sessionSireId) {
      setSireId(sessionSireId);
    }

    if (sessionSireAI) {
      setSireAI(sessionSireAI);
    }

    if (sessionActiveTabGeneticDam) {
      setActiveTabGeneticDam(sessionActiveTabGeneticDam);
    }

    if (sessionActiveTabSire) {
      setActiveTabSire(sessionActiveTabSire);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTagNumber]);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.births.length) !== Routing.births) {
        storeService.session.remove.dataChanged();
        storeService.session.removeAll.birth();
        storeService.session.removeAll.confirm();
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(Routing.births);
      }
    };
  }, [history]);

  const stepPicker = (pathname) => {
    switch (pathname) {
      case Routing.births:
      default: {
        storeService.session.removeAll.confirm();

        return (
          <>
            <div className="width50percent">
              <DropdownTagNumbersAvailable
                hint="prompt.selectFromAvailableTags"
                label={tagNumberLabel}
                name="tagNumber"
                onChange={handleChanges.tagNumber}
                setModal={setModal}
                value={tagNumber}
              />
            </div>
            <div className="section">
              <InputDate
                dontValidate={true}
                error={entryDateError}
                hint="label.entryExample"
                id="birthDate"
                labelPosition="above"
                labelText="label.dateBirth"
                maxDate={getDates.dateMax()}
                onChange={(event) => handleChanges.date(event.target.value)}
                setError={setEntryDateError}
                value={entryDate}
                warning={entryDateWarning}
                yearLength={4}
              />
            </div>
            <div className="width50percent">
              <DropdownBreeds
                label="label.breed"
                name="breedId"
                onChange={handleChanges.breed}
                value={breedId}
              />
            </div>
            <RadioButtons
              error={genderError}
              ids={[
                constants.option.gender.male,
                constants.option.gender.female
              ]}
              inline={true}
              name="gender"
              onChange={handleChanges.gender}
              value={gender}
            />
            <RadioButtons
              ids={[
                constants.option.embryo.yes,
                constants.option.embryo.no
              ]}
              inline={true}
              name="embryo"
              onChange={handleChanges.embryo}
              value={embryo}
            />
            {helpers.option.embryo.isTransfer(embryo) &&
              <div className="width50percent">
                <GeneticDamTab
                  activeTabId={activeTabGeneticDam}
                  geneticDamAI={geneticDamAI}
                  geneticDamId={geneticDamId}
                  geneticDamIdError={geneticDamIdError}
                  geneticDamTabs={geneticDamTabs}
                  setActiveTabId={handleChanges.activeTabGeneticDam}
                  setGeneticDamAI={handleChanges.geneticDamAI}
                  setGeneticDamId={handleChanges.geneticDamId}
                  setModal={setModal}
                />
              </div>
            }
            <div className="width50percent">
              <DropdownDamIds
                name="birthDamId"
                onChange={handleChanges.birthDamId}
                setModal={setModal}
                value={birthDamId}
              />
            </div>
            <fieldset className="mb-2" role="group">
              <Legend
                legend="label.sireId"
                optional={true}
              />
              <div className="width50percent">
                <Tabs activeTabId={activeTabSire} ref={sireTabs} setActiveTabId={handleChanges.activeTabSire}>
                  <div id={constants.tabs.onHolding} label="label.onHolding">
                    <div className="tab-border">
                      <DropdownSireIds
                        label="label.sireId"
                        name="sireId"
                        onChange={handleChanges.sireId}
                        optional={true}
                        setModal={setModal}
                        value={sireId}
                      />
                    </div>
                  </div>
                  <div id={constants.tabs.donor} label="label.donor">
                    <div className="tab-border-text">
                      <TextEntry
                        id="sireAI"
                        label="label.enterSupplyTag"
                        name="sireAI"
                        onChange={handleChanges.sireAI}
                        value={sireAI}
                        width={config.WIDTH_TAGS}
                      />
                    </div>
                  </div>
                </Tabs>
              </div>
            </fieldset>
            <CheckBox
              id="registerAnother"
              label="checkbox.registerAnother"
              name="registerAnother"
              onChange={handleChanges.registerAnother}
              value={registerAnother}
            />
            <div className="mt-4">
              <Button
                buttonType="primary"
                disabled={disableSubmit}
                label="button.submit"
                onClick={handleSubmit}
              />
            </div>
          </>
        );
      }

      case Routing.birthsConfirm: {
        const requestId = storeService.session.get.confirmRequestId();

        if (!requestId) {
          return <Redirect to={Routing.births} />;
        }

        return (
          <Confirmation
            confirm={{ id: requestId, label: 'label.requestId' }}
            content={{
              label: 'birth.contents',
              onClick: () => {
                setPermission(userRoleType.GENERIC);
                storeService.session.set.permissions(userRoleType.GENERIC);
                storeService.session.remove.holding();
                history.push(navigation.userActivity());
              }
            }}
            label="birth.submitted"
            setModal={setModal}
            status={constants.status.success} // Hardcoding this until we have polling for this section
          />
        );
      }
    }
  };

  return (
    <>
      {ready &&
        <>
          <Prompt
            message={(params) => {
              return (!storeService.session.get.idle() && dataChanged && (params.pathname.substring(0, Routing.births.length) !== Routing.births || params.pathname === Routing.births)) ? t('warning.unsavedChanges2') : true;
            }}
          />
          <h1 className="h1">{t('menu.births')}</h1>

          <AboutPanel
            descriptions={[
              { text: 'about.births.desc1' },
              { text: 'about.births.desc2' },
              { text: 'about.births.desc3' },
              { text: 'about.births.desc4' }
            ]}
            title="about.births.title"
          />

          <div className="row">
            <div className="col-sm-12">
              {stepPicker(location.pathname)}
            </div>
          </div>
        </>
      }
    </>
  );
};

Births.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired
};

export default Births;
