import React from 'react';
import { useTranslation } from 'react-i18next';
import constants from 'services/constants';
import classes from 'backOfficeComponents/summary/transportSummary/transportSummary.module.scss';
import PropTypes from 'prop-types';

const TransportSummary = ({
  transportInformation
}) => {
  const { ready, t } = useTranslation();
  const transportedByLabel = transportInformation?.transportedBy
    ? ('radioButtons.transportedBy.' + transportInformation.transportedBy)
    : (transportInformation?.transporterType ? transportInformation?.transporterType : '');

  return (
    <>
      {ready &&
        <>
          {(!transportInformation || !transportInformation.recordTransportInformation || transportInformation.recordTransportInformation === constants.option.recordTransportInformation.no) &&
            <div className={classes.summaryRow + ' ' + classes.noBorder}>
              {t('movements.transport-information.noInformationRecorded')}
            </div>
          }

          {transportInformation && transportInformation.recordTransportInformation && (transportInformation.recordTransportInformation !== constants.option.recordTransportInformation.no) &&
            <>
              <div className={[classes.summaryRow, (!transportInformation.contactPhone && !transportInformation.durationOfJourney) ? classes.noBottomBorder : ''].join(' ').trim()}>
                <div className={classes.label}>
                  {t('movements.transport-information.transport-details.radios-title')}
                </div>
                <div className={classes.value}>
                  {t(transportedByLabel)}
                  {transportInformation.vehicleRegistrationNumber &&
                    <>
                      {transportedByLabel ? ' - ' : null}{transportInformation.vehicleRegistrationNumber}
                    </>
                  }
                </div>
              </div>

              {transportInformation.contactPhone &&
                <div className={[classes.summaryRow, !transportInformation.durationOfJourney ? classes.noBottomBorder : ''].join(' ').trim()}>
                  <div className={classes.label}>{t('movements.transport-information.transport-details.contactTelephoneNumber')}</div>
                  <div className={classes.value}>{transportInformation.contactPhone}</div>
                </div>
              }

              {transportInformation.durationOfJourney && (transportInformation.durationOfJourney.days > 0 || transportInformation.durationOfJourney.hours > 0 || transportInformation.durationOfJourney.minutes > 0) &&
                <div className={[classes.summaryRow, classes.noBottomBorder].join(' ').trim()}>
                  <div className={classes.label}>{t('movements.transport-information.transport-details.durationOfJourney')}</div>
                  <div className={classes.value}>
                    {transportInformation.durationOfJourney.days === 1 &&
                      <>
                        {transportInformation.durationOfJourney.days}{' '}
                        {t('label.day_lower')}{' '}
                      </>
                    }
                    {transportInformation.durationOfJourney.days > 1 &&
                      <>
                        {transportInformation.durationOfJourney.days}{' '}
                        {t('label.days_lower')}{' '}
                      </>
                    }
                    {transportInformation.durationOfJourney.hours ? transportInformation.durationOfJourney.hours : 0}{' '}
                    {t('movements.transport-information.transport-details.hours').toLowerCase()}{' '}

                    {transportInformation.durationOfJourney.minutes ? transportInformation.durationOfJourney.minutes : 0}{' '}
                    {t('movements.transport-information.transport-details.minutes').toLowerCase()}
                  </div>
                </div>
              }
            </>
          }
        </>
      }
    </>
  );
};

TransportSummary.propTypes = {
  transportInformation: PropTypes.object
};

export default TransportSummary;
