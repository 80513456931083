import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router-dom';
import { Prompt } from 'react-router';
import config from 'config';
import bff, { bffResponse } from 'services/bff';
import classes from 'frontEnd/tagging/replacement/tagReplacement.module.scss';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import { bffStatus } from 'services/bff';
import permissions from 'services/permissions';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import AddReplacementTag from 'components/addReplacementTag/addReplacementTag';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import InputDate from 'components/base/inputDate/inputDate';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Table from 'components/table/table';
import TabsNavigational from 'components/base/tabsNavigational/tabsNavigational';
import TextEntry from 'components/base/textEntry/textEntry';

const TagReplacement = ({
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionSpecies = storeService.session.get.species();
  const speciesId = sessionSpecies ? sessionSpecies.id : '';
  const sessionHolding = storeService.session.get.holding();
  const cph = sessionHolding ? sessionHolding.value : '';
  const role = storeService.session.get.permissions();
  const holding = storeService.session.get.holding();

  const sessionDataChanged = storeService.session.get.dataChanged();

  const desc3Label = 'about.tagReplacements.desc3-' + speciesId;
  const desc4Label = 'about.tagReplacements.desc4-' + speciesId;

  const [animalsToChange, setAnimalsToChange] = React.useState([]);
  const [selectionMethod, setSelectionMethod] = React.useState(null);
  const [tagType, setTagType] = React.useState(null);
  const [replacementDate, setReplacementDate] = React.useState(null);
  const [replacementDateError, setReplacementDateError] = React.useState('');
  const [replacementDateWarning, setReplacementDateWarning] = React.useState('');
  const [dataChanged, setDataChanged] = React.useState(sessionDataChanged ? sessionDataChanged : false);
  const [animalsOnHolding, setAnimalsOnHolding] = React.useState([]);
  const [oldBatchNumber, setOldBatchNumber] = React.useState('');
  const [oldTagNumber, setOldTagNumber] = React.useState('');
  const [oldTagNumberError, setOldTagNumberError] = React.useState('');
  const [newBatchNumber, setNewBatchNumber] = React.useState('');
  const [newIndividualNumber, setNewIndividualNumber] = React.useState('');
  const [newTagNumber, setNewTagNumber] = React.useState('');
  const [newTagNumberError, setNewTagNumberError] = React.useState('');
  const [quantity, setQuantity] = React.useState('');
  const [renderTable, setRenderTable] = React.useState();
  const [refreshFilters, setRefreshFilters] = React.useState({});
  const [comment, setComment] = React.useState('');
  const [paginationParams, setPaginationParams] = React.useState(constants.pagination.defaultParams);
  const [submitPending, setSubmitPending] = React.useState(false);

  const defaults = () => {
    setAnimalsToChange([]);
    setComment('');
    setSelectionMethod(null);
    setAnimalsOnHolding([]);
    setTagType(null);
    setDataChanged(false);

    setOldBatchNumber('');
    setOldTagNumber('');
    setNewBatchNumber('');
    setNewIndividualNumber(null);
    setNewTagNumber(null);

    setReplacementDate(null);
    setReplacementDateError('');
    setReplacementDateWarning('');
    setSubmitPending(false);
  };

  const confirmation = {
    viewSummary: (requestId, requestType) => ({
      id: 'viewSummary',
      label: 'button.viewSummary',
      onClick: () => {
        setPermission(userRoleType.GENERIC);
        storeService.session.set.permissions(userRoleType.GENERIC);
        storeService.session.remove.holding();

        setTimeout(() => {
          history.push({
            pathname: Routing.activityDetails + requestId,
            state: {
              data: {
                requestId,
                requestType,
                current: {
                  returnUrl: permissions.isKeeper() ? Routing.taggingReplacements : Routing.userActivity,
                  permissions: role,
                  holding
                }
              }
            }
          });
        }, [100]);
      }
    }),

    viewUserActivity: () => {
      setPermission(userRoleType.GENERIC);
      storeService.session.set.permissions(userRoleType.GENERIC);
      storeService.session.remove.holding();
      history.push(Routing.userActivity);
    }
  };

  const handleSubmit = () => {
    setSubmitPending(true);

    bff
      .post('/tagReplacement', {
        poll: config.POLLS_ENABLED,
        cph: storeService.session.get.holding().value,
        comment,
        speciesName: storeService.session.get.species().name,
        tagType,
        replacementDate,
        replacementReason: helpers.option.tagType.isUpgrade(tagType) ? 'Upgrade' : 'Tag Missing',
        animalsToChange: animalsToChange.map((item) => ({
          oldTag: item.oldTag ? item.oldTag.tag : item.oldBatch.batch,
          newTag: item.newTag ? item.newTag.tag : item.newBatch.batch,
          animalTotal: item.quantity
        }))
      })
      .then((res) => {
        const { data } = res;

        storeService.session.set.confirmRequestId(bffResponse.requestId(data));
        storeService.session.set.confirmPollingStatus(bffResponse.status(data));
        storeService.session.set.confirmPollingErrors(bffResponse.errors(data));
        storeService.session.set.confirmPollingWarnings(bffResponse.warnings(data));

        storeService.session.remove.dataChanged();

        history.push(Routing.taggingReplacementsConfirm);

        setSubmitPending(false);

        helpers.scrollToTop();
        defaults();
      })
      .catch((error) => {
        errors.BFF(error, setModal);
        setSubmitPending(false);
      });
  };

  const validateDate = (date) => {
    let hasError = false;

    setReplacementDateError('');
    setReplacementDateWarning('');

    const replacementDateIsInTheFuture = helpers.date.is.inTheFuture(date, 'tagging');
    const replacementDateIsOverAYearAgo = helpers.date.is.overAYearAgo(date);

    if (!hasError && replacementDateIsInTheFuture) {
      setReplacementDateError(replacementDateIsInTheFuture);

      hasError = true;
    }

    if (!hasError && replacementDateIsOverAYearAgo) {
      setReplacementDateWarning(replacementDateIsOverAYearAgo);
    }
  };

  const handleChanges = {
    animalsToChange: (currentAnimal) => {
      if (currentAnimal.oldTag && currentAnimal.newTag && currentAnimal.oldTag.tag === currentAnimal.newTag.tag) {
        setNewTagNumberError('error.tagNumberMustDifferForReplacement');
        return;
      }

      if (currentAnimal.oldBatch && currentAnimal.newBatch && currentAnimal.oldBatch.batch === currentAnimal.newBatch.batch) {
        setNewTagNumberError('error.tagNumberMustDifferForReplacement');
        return;
      }
      const newAnimalsToChange = [...animalsToChange, currentAnimal];
      setAnimalsToChange(newAnimalsToChange);
      storeService.session.set.tagReplacementAnimalsToChange(newAnimalsToChange);
      storeService.session.remove.tagReplacementOldTagNumber();
      storeService.session.remove.tagReplacementQuantity();
      storeService.session.remove.tagReplacementNewTagNumber();
    },

    comment: (comment) => {
      if (comment) {
        storeService.session.set.tagReplacementComment(comment);
      } else {
        storeService.session.remove.tagReplacementComment();
      }

      setComment(comment);
      setDataChanged(true);
      storeService.session.set.dataChanged(true);
    },

    date: (date) => {
      setAnimalsOnHolding([]);
      setReplacementDate(date);
      storeService.session.set.tagReplacementDate(date);
      validateDate(date);

      if (helpers.option.tagMethod.isOnHolding(selectionMethod)) {
        storeService.session.removeAll.searchResults();
        setRenderTable(true);
      }

      setRefreshFilters({
        refresh: true,
        params: {
          date
        }
      });

      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    newBatchNumber: (batch) => {
      if (batch) {
        setNewBatchNumber(batch);
        storeService.session.set.tagReplacementNewBatchNumber(batch);
      } else {
        setNewBatchNumber('');
        storeService.session.remove.tagReplacementNewBatchNumber();
      }
    },

    newIndividualNumber: (tag) => {
      if (tag) {
        setNewIndividualNumber(tag);
        storeService.session.set.tagReplacementNewIndividualNumber(tag);
      } else {
        setNewIndividualNumber('');
        storeService.session.remove.tagReplacementNewIndividualNumber();
      }
    },

    newTagNumber: (tag) => {
      if (tag) {
        setNewTagNumber(tag);
        storeService.session.set.tagReplacementNewTagNumber(tag);
      } else {
        setNewTagNumber('');
        storeService.session.remove.tagReplacementNewTagNumber();
      }
    },

    oldBatchNumber: (batch) => {
      if (batch) {
        setOldBatchNumber(batch);
        storeService.session.set.tagReplacementOldBatchNumber(batch);
      } else {
        setOldBatchNumber('');
        storeService.session.remove.tagReplacementOldBatchNumber();
      }
    },

    oldTagNumber: (tag) => {
      if (tag) {
        setOldTagNumber(tag);
        storeService.session.set.tagReplacementOldTagNumber(tag);
      } else {
        setOldTagNumber('');
        storeService.session.remove.tagReplacementOldTagNumber();
      }
    },

    quantity: (quantity) => {
      if (quantity) {
        setQuantity(quantity);
        storeService.session.set.tagReplacementQuantity(quantity);
      } else {
        setQuantity('');
        storeService.session.remove.tagReplacementQuantity();
      }
    },

    removeAnimal: (animalToRemove) => {
      const sessionAnimalsToChange = storeService.session.get.tagReplacementAnimalsToChange();
      const newAnimalsToChange = sessionAnimalsToChange.filter((e) => JSON.stringify(e) !== JSON.stringify(animalToRemove));
      setAnimalsToChange(newAnimalsToChange);

      if (newAnimalsToChange?.length === 0) {
        storeService.session.remove.tagReplacementAnimalsToChange();
      } else {
        storeService.session.set.tagReplacementAnimalsToChange(newAnimalsToChange);
      }
    },

    selectionMethod: (event) => {
      setSelectionMethod(event.target.value);
      storeService.session.set.tagReplacementSelectionMethod(event.target.value);

      storeService.session.removeAll.searchResults();
      storeService.session.removeAll.tableFilters();

      setAnimalsToChange([]);
      setAnimalsOnHolding([]);
      setOldTagNumber('');
      setNewBatchNumber('');
      setNewIndividualNumber('');
      setNewTagNumber('');
    },

    selectOldTagNumber: (currentAnimal) => {
      if (!currentAnimal || (currentAnimal && !currentAnimal.tagNumber)) {
        setOldTagNumber('');
        storeService.session.remove.tagReplacementOldTagNumber();
      } else if (typeof (currentAnimal) === 'string') {
        setOldTagNumber(currentAnimal);
        storeService.session.set.tagReplacementOldTagNumber(currentAnimal);
      } else {
        setOldTagNumber(currentAnimal.tagNumber);
        storeService.session.set.tagReplacementOldTagNumber(currentAnimal.tagNumber);
      }
    },

    tagType: (event) => {
      setTagType(event.target.value);
      storeService.session.set.tagReplacementTagType(event.target.value);

      setNewIndividualNumber('');

      if (helpers.option.tagType.isBreeding(event.target.value) || helpers.option.tagType.isUpgrade(event.target.value)) {
        storeService.session.remove.tagReplacementQuantity();
        storeService.session.remove.tagReplacementNewTagNumber();
      }

      if (helpers.option.tagType.isSlaughter(event.target.value) || helpers.option.tagType.isUpgrade(event.target.value)) {
        storeService.session.remove.tagReplacementSelectionMethod();
      }

      storeService.session.remove.tagReplacementOldTagNumber();
      storeService.session.remove.tagReplacementNewBatchNumber();
      storeService.session.remove.tagReplacementNewIndividualNumber();
      storeService.session.remove.tagReplacementNewTagNumber();
      storeService.session.remove.tagReplacementAnimalsToChange();

      storeService.session.removeAll.searchResults();
      storeService.session.removeAll.tableFilters();

      setAnimalsToChange([]);
      setAnimalsOnHolding([]);
      setSelectionMethod(null);
      setOldTagNumber('');
      setNewBatchNumber('');
      setNewIndividualNumber('');
      setNewTagNumber('');
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    }
  };

  const validate = {
    oldTagNumber: (tag) => {
      if (tag && animalsToChange.find((item) => item.oldTag.tag.toLowerCase() === tag.toLowerCase())) {
        setOldTagNumberError('error.tagNumberAlreadySelectedForReplacement');
      } else {
        setOldTagNumberError('');
      }
    },

    newTagNumber: (tag) => {
      if (tag && animalsToChange.find((item) => item.newTag.tag.toLowerCase() === tag.toLowerCase())) {
        setNewTagNumberError('error.tagNumberAlreadySelectedForReplacement');
      } else if (tag && tag.toLowerCase() === oldTagNumber.toLowerCase()) {
        setNewTagNumberError('error.tagNumberMustDifferForReplacement');
      } else if (tag && tag.toLowerCase() === oldBatchNumber.toLowerCase()) {
        setNewTagNumberError('error.tagNumberMustDifferForReplacement');
      } else {
        setNewTagNumberError('');
      }
    },

    newIndividualNumber: (tag) => {
      if (tag && animalsToChange.find((item) => item.newTag.tag.toLowerCase() === (newBatchNumber + ' ' + helpers.tag.zerosPadLeft(speciesId, null, tag)).toLowerCase())) {
        setNewTagNumberError('error.tagNumberAlreadySelectedForReplacement');
      } else {
        setNewTagNumberError('');
      }
    }
  };

  const getDates = {
    replacementMax: () => {
      const today = new Date();

      return helpers.date.formatYYYYMMDD(today);
    }
  };

  const tableParams = {
    request: {
      url: '/animals',
      params: {
        speciesId: speciesId,
        cph,
        onHoldingDate: replacementDate
          ? helpers.date.startOfDay(replacementDate)
          : helpers.date.now()
      },
      processData: (data) => helpers.animal.addDobAndAgeForDisplay(data)
    }
  };

  const extractQuantity = (array) => array.reduce((sum, current) => sum + Number(current.quantity), 0);

  const display = {
    manualTags: () => {
      return (
        <div className="section">
          <AddReplacementTag
            newTagNumber={newTagNumber}
            newTagNumberError={newTagNumberError}
            oldTagNumber={oldTagNumber}
            oldTagNumberError={oldTagNumberError}
            selectionMethod={selectionMethod}
            setAnimalsToChange={handleChanges.animalsToChange}
            setModal={setModal}
            setNewTagNumber={handleChanges.newTagNumber}
            setOldTagNumber={handleChanges.oldTagNumber}
            tagType={tagType}
          />
        </div>
      );
    },

    selectFromHolding: () => {
      return (
        <>
          <div className="section">
            <Table
              addAllAbove={handleChanges.addAnimalsAll}
              columns={tableColumns.tagReplacement.onHolding({ actionType: 'add', actionFunction: handleChanges.selectOldTagNumber })}
              data={animalsOnHolding.map((item1) =>
                animalsToChange.find((item2) => item2.oldTag.tag === item1.tagNumber) ? { ...item1, added: true } : { ...item1, added: false }
              )}
              filtersComponents={{
                [constants.filters.inside]: 'AnimalsOnHolding'
              }}
              initialSortBy={initialSorting.tagReplacement.onHolding.accessor}
              initialSortDirection={initialSorting.tagReplacement.onHolding.direction}
              params={tableParams}
              refreshFilters={refreshFilters}
              renderTable={renderTable}
              setData={setAnimalsOnHolding}
              setModal={setModal}
              store={{
                filters: 'AnimalsOnHolding',
                data: 'AnimalsOnHolding'
              }}
              title="table.title.animalsOnHolding"
            />
          </div>

          {oldTagNumber &&
            <div className="section">
              <AddReplacementTag
                newBatchNumber={newBatchNumber}
                newIndividualNumber={newIndividualNumber}
                newTagNumberError={newTagNumberError}
                oldTagNumber={oldTagNumber}
                selectionMethod={selectionMethod}
                setAnimalsToChange={handleChanges.animalsToChange}
                setModal={setModal}
                setNewBatchNumber={handleChanges.newBatchNumber}
                setNewIndividualNumber={handleChanges.newIndividualNumber}
                setNewTagNumber={handleChanges.newTagNumber}
                setOldTagNumber={handleChanges.oldTagNumber}
                tagType={tagType}
              />
            </div>
          }
        </>
      );
    },

    slaughterTags: () => {
      return (
        <div className="section">
          <AddReplacementTag
            newBatchNumber={newBatchNumber}
            oldBatchNumber={oldBatchNumber}
            quantity={quantity}
            setAnimalsToChange={handleChanges.animalsToChange}
            setModal={setModal}
            setNewBatchNumber={handleChanges.newBatchNumber}
            setOldBatchNumber={handleChanges.oldBatchNumber}
            setQuantity={handleChanges.quantity}
            tagType={tagType}
          />
        </div>
      );
    },

    upgradeTags: () => {
      return (
        <div className="section">
          <AddReplacementTag
            newTagNumber={newTagNumber}
            newTagNumberError={newTagNumberError}
            oldBatchNumber={oldBatchNumber}
            selectionMethod={selectionMethod}
            setAnimalsToChange={handleChanges.animalsToChange}
            setModal={setModal}
            setNewTagNumber={handleChanges.newTagNumber}
            setOldBatchNumber={handleChanges.oldBatchNumber}
            tagType={tagType}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.taggingReplacements.length) !== Routing.taggingReplacements) {
        storeService.session.remove.dataChanged();
        storeService.session.removeAll.confirm();
        storeService.session.removeAll.searchResults();
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.tagReplacement();
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(Routing.taggingReplacements);
      }
    };
  }, [history]);

  useEffect(() => {
    if (renderTable) {
      setRenderTable();
    }
  }, [renderTable]);

  useEffect(() => {
    if (refreshFilters.refresh) {
      setRefreshFilters({});
    }
  }, [refreshFilters]);

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      [constants.pagination.bottom]: {
        ...prevState[constants.pagination.bottom],
        total: animalsToChange?.length
      }
    }));
  }, [animalsToChange]);

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      [constants.pagination.bottom]: {
        ...prevState[constants.pagination.bottom],
        totalLabel: helpers.option.tagType.isSlaughter(tagType) ? 'label.totalBatches' : null
      }
    }));
  }, [tagType]);

  useEffect(() => {
    const sessionReplacementDate = storeService.session.get.tagReplacementDate();
    const sessionTagType = storeService.session.get.tagReplacementTagType();

    if (sessionReplacementDate) {
      setReplacementDate(sessionReplacementDate);
    }

    if (sessionTagType) {
      setTagType(sessionTagType);

      if (helpers.option.tagType.isBreeding(sessionTagType)) {
        const sessionSelectionMethod = storeService.session.get.tagReplacementSelectionMethod();

        if (sessionSelectionMethod) {
          setSelectionMethod(sessionSelectionMethod);
        }
      }

      if (helpers.option.tagType.isSlaughter(sessionTagType)) {
        const sessionQuantity = storeService.session.get.tagReplacementQuantity();
        const sessionNewTagNumber = storeService.session.get.tagReplacementNewTagNumber();

        if (sessionQuantity) {
          setQuantity(sessionQuantity);
        }

        if (sessionNewTagNumber) {
          setNewIndividualNumber(sessionNewTagNumber);
        }
      }

      const sessionOldTagNumber = storeService.session.get.tagReplacementOldTagNumber();
      const sessionNewBatchNumber = storeService.session.get.tagReplacementNewBatchNumber();
      const sessionNewIndividualNumber = storeService.session.get.tagReplacementNewIndividualNumber();
      const sessionAnimalsToChange = storeService.session.get.tagReplacementAnimalsToChange();
      const sessionComment = storeService.session.get.tagReplacementComment();

      if (sessionOldTagNumber) {
        setOldTagNumber(sessionOldTagNumber);
      }

      if (sessionNewBatchNumber) {
        setNewBatchNumber(sessionNewBatchNumber);
      }

      if (sessionNewIndividualNumber) {
        setNewIndividualNumber(sessionNewIndividualNumber);
      }

      if (sessionAnimalsToChange) {
        setAnimalsToChange(sessionAnimalsToChange);
      }

      if (sessionComment) {
        setComment(sessionComment);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOldTagNumberError('');

    if (oldTagNumber) {
      validate.oldTagNumber(oldTagNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldTagNumber, animalsToChange]);

  useEffect(() => {
    setNewTagNumberError('');

    if (newTagNumber) {
      validate.newTagNumber(newTagNumber);
    }
    if (newIndividualNumber) {
      validate.newIndividualNumber(newIndividualNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTagNumber, newIndividualNumber, animalsToChange]);

  const stepPicker = (pathname) => {
    switch (pathname) {
      case Routing.taggingReplacements:
      default: {
        storeService.session.removeAll.confirm();

        return (
          <TabsNavigational>
            <div id={constants.tabs.tagApplications} label="tagging.tab1" url={Routing.taggingApplications} />
            <div active id={constants.tabs.tagReplacements} label="tagging.tab2">
              <h2 className="h2">{t('tagging.replacement.title')}</h2>

              {submitPending &&
                <Confirmation
                  label="label.submissionPending"
                  setModal={setModal}
                  status={constants.status.pending}
                />
              }

              {!submitPending &&
                <>
                  <AboutPanel
                    descriptions={[
                      { text: 'about.tagReplacements.desc1' },
                      { text: 'about.tagReplacements.desc2' },
                      { text: desc3Label },
                      { text: desc4Label },
                      { text: 'about.tagReplacements.desc5' },
                      { text: 'about.tagReplacements.desc6', href: constants.url.defraGuidance }
                    ]}
                    title="about.tagReplacements.title"
                  />

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="section">
                        <InputDate
                          dontValidate={true}
                          error={replacementDateError}
                          id="replacementDate"
                          labelPosition="above"
                          labelText="label.dateReplacement"
                          maxDate={getDates.replacementMax()}
                          onChange={(event) => handleChanges.date(event.target.value)}
                          setError={setReplacementDateError}
                          value={replacementDate}
                          warning={replacementDateWarning}
                          yearLength={4}
                        />
                      </div>

                      <div className="section">
                        <AboutPanel
                          descriptions={[
                            { text: 'label.userCommentDescriptionTagReplacement' }
                          ]}
                          title="label.userCommentTitle"
                        >
                          <TextEntry
                            id="comment"
                            maxLength={config.MAXLENGTH_COMMENT}
                            name="comment"
                            onChange={(event) => handleChanges.comment(event.target.value)}
                            placeholder={t('label.userCommentPlaceholder')}
                            value={comment}
                            width="full"
                          />
                        </AboutPanel>
                      </div>

                      <div className="section">
                        <RadioButtons
                          ids={[
                            constants.option.tag.breedingTags,
                            constants.option.tag.slaughterTags,
                            constants.option.tag.upgradeTags
                          ]}
                          name="replacementTagType"
                          onChange={handleChanges.tagType}
                          species={true}
                          value={tagType}
                        />
                      </div>
                    </div>
                  </div>

                  {replacementDate &&
                    <>
                      {helpers.option.tagType.isBreeding(tagType) &&
                      <>
                        <div className="section">
                          <RadioButtons
                            ids={[
                              constants.option.tagMethod.onHolding,
                              constants.option.tagMethod.tagsManually
                            ]}
                            name="selectionMethodTagReplacement"
                            onChange={handleChanges.selectionMethod}
                            value={selectionMethod}
                          />
                        </div>

                        {helpers.option.tagMethod.isOnHolding(selectionMethod) && display.selectFromHolding()}

                        {helpers.option.tagMethod.isTagsManually(selectionMethod) && display.manualTags()}
                      </>
                      }

                      {helpers.option.tagType.isSlaughter(tagType) && display.slaughterTags()}

                      {helpers.option.tagType.isUpgrade(tagType) && display.upgradeTags()}

                      {animalsToChange && helpers.tag.hasNonEidTagsSheep(speciesId, animalsToChange) &&
                      <ErrorLabel
                        isWarning={true}
                        label="formatExamples.tagBatchNonEidError"
                        modalMessage={helpers.tag.getFormatExamples(speciesId).description}
                        modalTitle={helpers.tag.getFormatExamples(speciesId).title}
                        setModal={setModal}
                      />
                      }

                      {animalsToChange?.some((tag) => helpers.tag.isInvalid(tag)) &&
                      <ErrorLabel
                        label="formatExamples.tagBatchError"
                        modalMessage={helpers.tag.getFormatExamples(speciesId).description}
                        modalTitle={helpers.tag.getFormatExamples(speciesId).title}
                        setModal={setModal}
                      />
                      }
                    </>
                  }

                  {animalsToChange?.length > 0 &&
                    <div className="section">
                      <Table
                        columns={helpers.option.tagType.isSlaughter(tagType)
                          ? tableColumns.tagReplacement.replacementSlaughterTags({ actionType: 'remove', actionFunction: handleChanges.removeAnimal })
                          : tableColumns.tagReplacement.replacement({ actionType: 'remove', actionFunction: handleChanges.removeAnimal })
                        }
                        data={animalsToChange}
                        dataProvided={true}
                        hideColumnHeaders={true}
                        paginationParams={paginationParams}
                        removeAll={handleChanges.removeAnimalsAll}
                        setModal={setModal}
                        title={{
                          title: helpers.option.tagType.isSlaughter(tagType) ? extractQuantity(animalsToChange) > 1 ? 'table.title.batchesEntered-plural' : 'table.title.batchesEntered' : 'table.title.replacementsEntered',
                          data: [
                            helpers.option.tagType.isSlaughter(tagType) ? extractQuantity(animalsToChange) : animalsToChange?.length
                          ]
                        }}
                      />
                    </div>
                  }

                  <div className={classes.actions}>
                    <div className={classes.submit}>
                      <Button
                        buttonType="primary"
                        disabled={
                          !replacementDate ||
                          replacementDateError ||
                          animalsToChange?.length === 0 ||
                          animalsToChange.some((item) => helpers.tag.isInvalid(item)) ||
                          submitPending
                        }
                        label="button.submit"
                        onClick={handleSubmit}
                      />
                    </div>
                    <div className={classes.exit}>
                      <Button
                        buttonType="tertiary"
                        label="button.cancelExit"
                        onClick={() => history.push(Routing.keeperHolding)}
                      />
                    </div>
                  </div>
                </>
              }
            </div>
          </TabsNavigational>
        );
      }

      case Routing.taggingReplacementsConfirm: {
        const requestId = storeService.session.get.confirmRequestId();
        const pollingStatus = storeService.session.get.confirmPollingStatus();
        const pollingErrors = storeService.session.get.confirmPollingErrors();
        const pollingWarnings = storeService.session.get.confirmPollingWarnings();

        if (!requestId) {
          return <Redirect to={Routing.taggingReplacements} />;
        }
        if (bffStatus.isClaPending(pollingStatus) || bffStatus.isPending(pollingStatus)) {
          return (
            <Confirmation
              confirm={{
                label: 'label.submissionPendingCheckViaUserActivity',
                onClick: () => confirmation.viewUserActivity()
              }}
              label="label.submissionPending"
              onClick={() => confirmation.viewUserActivity()}
              setModal={setModal}
              status={pollingStatus}
            />
          );
        }

        return (
          <Confirmation
            buttons={[
              requestId && !bffStatus.isClaPending(pollingStatus) ? confirmation.viewSummary(requestId, constants.option.requestType.tagReplacement) : null
            ].filter((item) => item)}
            confirm={{
              id: requestId,
              label: 'label.reference'
            }}
            errors={pollingErrors}
            isUnamendable={true}
            label="tagging.replacement.submitted"
            onClick={() => history.push(Routing.taggingReplacements)}
            setModal={setModal}
            status={pollingStatus}
            warnings={pollingWarnings}
          />
        );
      }
    }
  };

  return (
    <>
      {ready &&
        <>
          <Prompt
            message={(params) => {
              return (!storeService.session.get.idle() && dataChanged && (params.pathname.substring(0, Routing.taggingReplacements.length) !== Routing.taggingReplacements || params.pathname === Routing.taggingReplacements)) ? t('warning.unsavedChanges2') : true;
            }}
          />
          <h1 className="h1">{t('menu.tagging')}</h1>

          {stepPicker(location.pathname)}
        </>
      }
    </>
  );
};

TagReplacement.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired
};

export default TagReplacement;
