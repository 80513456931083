const Routing = {
  connectionCheck: '/connection-check',
  tokens: '/tokens',

  logout: '/logout',
  root: '/',
  version: '/version',
  signIn: '/signin',
  changePassword: '/change-password/',

  abattoirRoot: '/abattoir',
  aiCentreRoot: '/ai-centre',
  assemblyRoot: '/assembly',
  boRoot: '/bo',
  collectionRoot: '/collection',
  commonRoot: '/common',
  keeperRoot: '/keeper',
  marketRoot: '/market',
  otherRoot: '/other',
  portRoot: '/port',
  quarantineRoot: '/quarantine',
  showRoot: '/show',
  slaughterMarketRoot: '/slaughter-market',
  storeMarketRoot: '/store-market',
  vetRoot: '/veterinary',

  home: '/home',
  holdings: '/home/holdings',
  userActivity: '/home/user-activity',
  activityDetails: '/activity-details/',
  activityUndo: '/activity-undo/',

  abattoirMovements: '/abattoir/movements',
  abattoirMovementConfirm: '/abattoir/movements/confirm',
  abattoirMovementDetails: '/abattoir/movement-details/',
  abattoirSearch: '/abattoir/search',
  abattoirAttestation: '/abattoir/abattoirAttestation',
  abattoirAttestationTagResult: '/abattoir/abattoirAttestationTagResult',
  abattoirAttestationDeclaration: '/abattoir/abattoirAttestationDeclaration/',
  abattoirManualTags: '/abattoir/abattoirManualTag',
  abattoirUploadTags: '/abattoir/abattoirUploadTag',
  abattoirAttestationDeclarationTags: '/abattoir/abattoirAttestationDeclarationTags/',
  abattoirCheckHolding: '/abattoir/abattoirCheckHolding',
  abattoirAttestationResult: '/abattoir/abattoirAttestationResult',

  aiCentreMovements: '/ai-centre/movements',
  aiCentreMovementConfirm: '/ai-centre/movements/confirm',
  aiCentreMovementDetails: '/ai-centre/movement-details/',
  aiCentreSearch: '/ai-centre/search',

  assemblyMovements: '/assembly/movements',
  assemblyMovementConfirm: '/assembly/movements/confirm',
  assemblyMovementDetails: '/assembly/movement-details/',
  assemblySearch: '/assembly/search',

  boAnimalActivity: '/bo/animal-activity/',
  boAnimalActivityMovementHistory: '/bo/animal-activity/movement-history/',
  boAnimalActivityTaggingHistory: '/bo/animal-activity/tagging-history/',
  boAnimalActivityAnimalDetails: '/bo/animal-activity/animal-details/',
  boAnimalActivityProgeny: '/bo/animal-activity/progeny/',
  boEditCustomer: '/bo/edit-customer/',
  boEditMovements: '/bo/edit-movements/',
  boHoldingActivity: '/bo/holding-activity/',
  boHoldingActivityAdjacentMovements: '/bo/holding-activity/adjacent-movements/',
  boHoldingActivityAnimalsAdded: '/bo/holding-activity/animals-added/',
  boHoldingActivityAnimalsOnHolding: '/bo/holding-activity/animals-on-holding/',
  boHoldingActivityAnnualInventory: '/bo/holding-activity/annual-inventory/',
  boHoldingActivityAssociatedHoldings: '/bo/holding-activity/associated-holdings/',
  boHoldingActivityDeaths: '/bo/holding-activity/deaths/',
  boHoldingActivityHoldingDetails: '/bo/holding-activity/holding-details/',
  boHoldingActivityMovements: '/bo/holding-activity/movements/',
  boHoldingActivityPendingReviews: '/bo/holding-activity/pending-reviews/',
  boHoldingActivityTaggingApplications: '/bo/holding-activity/tagging-applications/',
  boHoldingActivityTaggingReplacement: '/bo/holding-activity/tagging-replacement/',
  boMovementDetails: '/bo/movement-details/',
  boMovements: '/bo/movements/',
  boMovementsConfirm: '/bo/movements/confirm',
  boRequestIdDetails: '/bo/request-id-details/',
  boSearchMovements: '/bo/search-movements',
  boSearchMovementsByDateAndCph: '/bo/search-movements/by-date-and-cph/',
  boSearchMovementsByMovementReference: '/bo/search-movements/by-movement-reference/',
  boSearchMovementsByPaperId: '/bo/search-movements/by-paper-id/',
  boSearchRequestId: '/bo/search-request-id',
  boUserActivity: '/bo/user-activity/',
  boUserActivityAll: '/bo/user-activity/all/',
  boUserActivityAnimalsAdded: '/bo/user-activity/animals-added/',
  boUserActivityAnnualInventory: '/bo/user-activity/annual-inventory/',
  boUserActivityBirths: '/bo/user-activity/births/',
  boUserActivityDeaths: '/bo/user-activity/deaths/',
  boUserActivityMovements: '/bo/user-activity/movements/',
  boUserActivityMovementAmends: '/bo/user-activity/movement-amends/',
  boUserActivityMovementReviews: '/bo/user-activity/movement-reviews/',
  boUserActivityTaggingApplications: '/bo/user-activity/tagging-applications/',
  boUserActivityTaggingReplacement: '/bo/user-activity/tagging-replacement/',
  boVerifyCustomer: '/bo/verify-customer',
  boVaRecords: '/bo/holding-activity/va-records/',
  boVaRecordDeclaration: '/bo/holding-activity/va-declaration/',

  collectionMovements: '/collectionCentre/movements',
  collectionMovementConfirm: '/collectionCentre/movements/confirm',
  collectionMovementDetails: '/collectionCentre/movement-details/',
  collectionSearch: '/collectionCentre/search',

  commonMovements: '/common/movements',
  commonMovementConfirm: '/common/movements/confirm',
  commonMovementDetails: '/common/movement-details/',
  commonSearch: '/common/search',

  marketMovements: '/market/movements',
  marketMovementConfirm: '/market/movements/confirm',
  marketMovementDetails: '/market/movement-details/',
  marketSearch: '/market/search',

  otherMovements: '/other/movements',
  otherMovementConfirm: '/other/movements/confirm',
  otherMovementDetails: '/other/movement-details/',
  otherSearch: '/other/search',

  portMovements: '/port/movements',
  portMovementConfirm: '/port/movements/confirm',
  portMovementDetails: '/port/movement-details/',
  portSearch: '/port/search',

  quarantineMovements: '/quarantine/movements',
  quarantineMovementConfirm: '/quarantine/movements/confirm',
  quarantineMovementDetails: '/quarantine/movement-details/',
  quarantineSearch: '/quarantine/search',

  showMovements: '/show/movements',
  showMovementConfirm: '/show/movements/confirm',
  showMovementDetails: '/show/movement-details/',
  showSearch: '/show/search',

  slaughterMarketMovements: '/slaughter-market/movements',
  slaughterMarketMovementConfirm: '/slaughter-market/movements/confirm',
  slaughterMarketMovementDetails: '/slaughter-market/movement-details/',
  slaughterMarketSearch: '/slaughter-market/search',

  storeMarketMovements: '/store-market/movements',
  storeMarketMovementConfirm: '/store-market/movements/confirm',
  storeMarketMovementDetails: '/store-market/movement-details/',
  storeMarketSearch: '/store-market/search',

  vetMovements: '/veterinary/movements',
  vetMovementConfirm: '/veterinary/movements/confirm',
  vetMovementDetails: '/veterinary/movement-details/',
  vetSearch: '/veterinary/search',

  animals: '/keeper/animals/',
  births: '/keeper/births/',
  birthsConfirm: '/keeper/births/confirm/',
  deaths: '/keeper/deaths/',
  deathsStep1: '/keeper/deaths/step1/',
  deathsStep2: '/keeper/deaths/step2/',
  deathsSummary: '/keeper/deaths/summary/',
  deathsConfirm: '/keeper/deaths/confirm/',
  foundConfirm: '/keeper/missing-and-found/found/confirm/',
  handshake: '/keeper/handshake/',
  holdingRegister: '/keeper/holding-register/',
  holdingRegisterAnnualInventory: '/keeper/holding-register/annual-inventory/',
  holdingRegisterBirths: '/keeper/holding-register/births/',
  holdingRegisterDeaths: '/keeper/holding-register/deaths/',
  holdingRegisterDetails: '/keeper/holding-register/holding-details/',
  holdingRegisterDownload: '/keeper/holding-register/download/',
  holdingRegisterMovementsOff: '/keeper/holding-register/movements-off/',
  holdingRegisterMovementsOn: '/keeper/holding-register/movements-on/',
  holdingRegisterAdjacentMovements: '/keeper/holding-register/adjacent-movements/',
  holdingRegisterTaggingApplications: '/keeper/holding-register/tagging-applications/',
  holdingRegisterTaggingReplacement: '/keeper/holding-register/tagging-replacement/',
  keeperHandshakeDetails: '/keeper/handshake/',
  keeperHolding: '/keeper/holding-summary/',
  missingFound: '/keeper/missing-and-found/',
  missingFoundStep1: '/keeper/missing-and-found/step1/',
  missingFoundSummary: '/keeper/missing-and-found/summary/',
  missingConfirm: '/keeper/missing-and-found/missing/confirm/',
  movements: '/keeper/movements/',
  movementsStep1: '/keeper/movements/step1/',
  movementsStep2: '/keeper/movements/step2/',
  movementsStep3: '/keeper/movements/step3/',
  movementsStep4: '/keeper/movements/step4/',
  movementsSummary: '/keeper/movements/summary/',
  movementsConfirm: '/keeper/movements/confirm',
  tagging: '/keeper/tagging/',
  taggingApplications: '/keeper/tagging/applications/',
  taggingApplicationsConfirm: '/keeper/tagging/applications/confirm/',
  taggingRecent: '/keeper/tagging/recent/',
  taggingReplacements: '/keeper/tagging/replacements/',
  taggingReplacementsConfirm: '/keeper/tagging/replacements/confirm/',

  vetPath: '/vet/',
  vetHome: '/vet/home',
  vetDetails: '/vet/details',
  vetCreate: '/vet/create',
  vetSummary: '/vet/summary',
  vetDeclaration: '/vet/declaration'
};

export default Routing;
