import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classes from 'components/table/filters/filtersBoSearchMovements/filtersBoSearchMovements.module.scss';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { holdingHelper } from 'services/holdingHelper';
import { tableColumns } from 'services/tableColumns';
import Button from 'backOfficeComponents/base/button/button';
import CPHField from 'components/base/cphField/cphField';
import DateRange from 'components/base/dateRange/dateRange';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import Legend from 'backOfficeComponents/base/legend/legend';
import RadioButtons from 'components/base/radioButtons/radioButtons';
const sprintf = require('util').format;

const BoMovementSearchByDateAndCphFilters = ({
  hasData,
  loadPending,
  setData,
  setRenderTable,
  setTableParams,
  storeFilters,
  tableParams
}) => {
  const { pathname } = useLocation();
  const { ready, t } = useTranslation();
  const history = useHistory();

  const now = helpers.date.now();
  const today = helpers.date.formatYYYYMMDD(now);

  const sessionActiveTabId = storeService.session.get.activeTabId();

  const [filterSpeciesName, setFilterSpeciesName] = React.useState(tableParams?.filters.speciesName ? tableParams.filters.speciesName : constants.species.animalTypes.SHEEP);
  const [filterMovementType, setFilterMovementType] = React.useState(tableParams?.filters?.movementType ? tableParams.filters.movementType : constants.movementTypeSearch.standard);
  const [filterFromDate, setFilterFromDate] = React.useState(tableParams?.filters?.fromDate ? tableParams.filters.fromDate : '');
  const [filterToDate, setFilterToDate] = React.useState(tableParams?.filters?.toDate ? tableParams.filters.toDate : today);
  const [filterFromCph, setFilterFromCph] = React.useState(tableParams?.filters?.fromCph);
  const [filterToCph, setFilterToCph] = React.useState(tableParams?.filters?.toCph);
  const [filterMovementRef, setFilterMovementRef] = React.useState(tableParams?.filters?.movementRef);
  const [filterPaperId, setFilterPaperId] = React.useState(tableParams?.filters?.paperId);
  const [searchDisabled, setSearchDisabled] = React.useState(true);
  const [fromDateError, setFromDateError] = React.useState('');
  const [toDateError, setToDateError] = React.useState('');
  const [crossBorderError, setCrossBorderError] = React.useState('');
  const [showRecordPaperMovementButton, setShowRecordPaperMovementButton] = React.useState(hasData || false);

  const get = {
    columnsByTab: () => {
      switch (sessionActiveTabId) {
        case constants.tabs.movementRange:
        default:
          return tableColumns.bo.searchMovements.byDateAndCph;

        case constants.tabs.movementRef:
          return tableColumns.bo.searchMovements.byMovementRef;

        case constants.tabs.paperId:
          return [];
      }
    },

    paramsByTab: () => {
      switch (sessionActiveTabId) {
        case constants.tabs.movementRange:
        default:
          return {
            crossBorderReview: helpers.option.movement.isCrossBorder(filterMovementType),
            departureHolding: filterFromCph,
            destinationHolding: filterToCph,
            endTransferDate: filterToDate,
            species: filterSpeciesName.charAt(0).toUpperCase() + filterSpeciesName.slice(1),
            speciesId: helpers.species.nameToId(filterSpeciesName),
            startTransferDate: filterFromDate
          };

        case constants.tabs.movementRef:
          return {
            bo: true,
            documentRef: filterMovementRef,
            getApproved: true
          };

        case constants.tabs.paperId:
          return {
            id: filterPaperId
          };
      }
    }
  };

  const handleChanges = {
    cph: (which, value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setData([]);
      storeService.session.removeAll.searchResults();

      if (helpers.flags.isFrom(which)) {
        setFilterFromCph(value);

        if (value !== filterFromCph) {
          setRenderTable(false);
        }

        if (value?.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { fromCph, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { fromCph, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              fromCph: value
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            fromCph: value
          });
        }
      } else if (helpers.flags.isTo(which)) {
        setFilterToCph(value);

        if (value !== filterToCph) {
          setRenderTable(false);
        }

        if (value?.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { toCph, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { toCph, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              toCph: value
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            toCph: value
          });
        }
      }
    },

    date: (which, value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setData([]);
      storeService.session.removeAll.searchResults();

      if (helpers.flags.isFrom(which)) {
        setFilterFromDate(value);

        if (value !== filterFromDate) {
          setRenderTable(false);
        }

        if (value?.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { fromDate, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { fromDate, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              fromDate: value
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            fromDate: value
          });
        }
      } else if (helpers.flags.isTo(which)) {
        setFilterToDate(value);

        if (value !== filterToDate) {
          setRenderTable(false);
        }

        if (value?.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { toDate, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { toDate, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              toDate: value
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            toDate: value
          });
        }
      }
    },

    movementRef: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setData([]);
      storeService.session.removeAll.searchResults();

      setFilterMovementRef(value);

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...storedFilters,
        movementRef: value
      });

      setRenderTable(false);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          movementRef: value
        }
      }));
    },

    movementType: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      if (value === filterMovementType) {
        setFilterMovementType('');

        delete storedFilters.movementType;

        storeService.session.set[`tableFilters${storeFilters}`](storedFilters);
      } else {
        setFilterMovementType(value);

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          movementType: value
        });
      }

      setData([]);
      storeService.session.removeAll.searchResults();

      setRenderTable(false);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          movementType: value
        }
      }));
    },

    paperId: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setData([]);
      storeService.session.removeAll.searchResults();

      setFilterPaperId(value);

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...storedFilters,
        paperId: value
      });

      setRenderTable(false);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          paperId: value
        }
      }));
    },

    speciesName: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      if (value === filterSpeciesName) {
        setFilterSpeciesName('');

        delete storedFilters.speciesId;
        delete storedFilters.speciesName;

        storeService.session.set[`tableFilters${storeFilters}`](storedFilters);
      } else {
        setFilterSpeciesName(value);

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          speciesId: helpers.species.nameToId(value),
          speciesName: helpers.species.formatName(value)
        });
      }

      setData([]);
      storeService.session.removeAll.searchResults();

      setRenderTable(false);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          speciesName: value
        }
      }));
    }
  };

  const render = {
    buttons: () => {
      return (
        <div className="actions">
          <div className="left">
            <Button
              buttonType="primary"
              disabled={searchDisabled}
              id="searchButton"
              label="button.search"
              onClick={runRequest}
            />
          </div>
          <div className="right">
            {pathname === Routing.boSearchMovementsByDateAndCph && showRecordPaperMovementButton &&
              <Button
                buttonType="primary"
                disabled={searchDisabled}
                id="recordPaperButton"
                label="button.recordPaperMovement"
                onClick={() => {
                  history.push(
                    Routing.boMovements,
                    {
                      data: {
                        species: filterSpeciesName,
                        departureCPH: filterFromCph,
                        destinationCPH: filterToCph
                      }
                    }
                  );
                }}
              />
            }
            <Button
              buttonType="tertiary"
              disabled={loadPending}
              id="resetButton"
              label="button.reset"
              onClick={resetForm}
            />
          </div>
        </div>
      );
    },

    filters: () => {
      switch (pathname) {
        case Routing.boSearchMovementsByDateAndCph:
        default:
          return (
            <>
              <RadioButtons
                classNames={['bo']}
                id="species"
                ids={[
                  constants.species.animalTypes.SHEEP,
                  constants.species.animalTypes.GOATS,
                  constants.species.animalTypes.DEER
                ]}
                inline={true}
                label="label.species"
                name="speciesName"
                onChange={(event) => handleChanges.speciesName(event.target.value)}
                reduceSpace={true}
                suppressLabel={false}
                value={filterSpeciesName.toLowerCase()}
              />
              <RadioButtons
                classNames={['bo']}
                id="movementType"
                ids={[
                  constants.movementTypeSearch.standard,
                  constants.movementTypeSearch.xb,
                  constants.movementTypeSearch.cancelled
                ]}
                inline={true}
                label="label.movementType"
                name="movementType"
                onChange={(event) => handleChanges.movementType(event.target.value)}
                reduceSpace={true}
                suppressLabel={false}
                value={filterMovementType}
              />

              <Legend
                id="movementDates"
                legend="label.movementDates"
              />
              <DateRange
                allowTomorrow={true}
                classNames={['bo']}
                fromDate={filterFromDate}
                fromDateError={fromDateError}
                fromDateRequired={false}
                maxRange={{
                  value: 1,
                  unit: constants.period.years
                }}
                setFromDate={(date) => handleChanges.date(constants.flags.from, date)}
                setFromDateError={setFromDateError}
                setToDate={(date) => handleChanges.date(constants.flags.to, date)}
                setToDateError={setToDateError}
                toDate={filterToDate}
                toDateError={toDateError}
                yearLength={2}
              />

              <Legend
                id="premises"
                legend="label.premises"
              />
              {crossBorderError &&
                <ErrorLabel
                  id="errorLabel"
                  label={crossBorderError}
                />
              }
              <div className={classes.premises}>
                <div className={classes.cph}>
                  <CPHField
                    classNames={['small']}
                    cph={filterFromCph}
                    id="fromCph"
                    isFormGroup={true}
                    label="label.departureHolding"
                    setCph={(cph) => handleChanges.cph(constants.flags.from, cph)}
                    width="quarter"
                  />
                </div>
                <div className={classes.cph}>
                  <CPHField
                    classNames={['small']}
                    cph={filterToCph}
                    id="toCph"
                    isFormGroup={true}
                    label="label.destinationHolding"
                    setCph={(cph) => handleChanges.cph(constants.flags.to, cph)}
                    width="quarter"
                  />
                </div>
              </div>
            </>
          );

        case Routing.boSearchMovementsByMovementReference:
          return (
            <div className={classes.movementId}>
              <FieldEntry
                alphaNumeric={true}
                id="movementRef"
                isSplCharPasteAllowed={true}
                labelPosition={constants.labelPosition.above}
                labelText="label.movementRef"
                maxLength={config.WIDTH_MOVEMENT_REF}
                name="movementRef"
                onChange={(event) => handleChanges.movementRef(event.target.value)}
                value={filterMovementRef}
                width={config.WIDTH_MOVEMENT_ID}
              />
            </div>
          );

        case Routing.boSearchMovementsByPaperId:
          return (
            <div className={classes.paperId}>
              <FieldEntry
                alphaNumeric={true}
                id="paperId"
                isSplCharPasteAllowed={true}
                labelPosition={constants.labelPosition.above}
                labelText="label.paperId"
                name="paperId"
                onChange={(event) => handleChanges.paperId(event.target.value)}
                value={filterPaperId}
                width={config.WIDTH_MOVEMENT_ID}
              />
            </div>
          );
      }
    }
  };

  const resetForm = () => {
    setFilterSpeciesName(config.SPECIES_DEFAULT);
    setFilterMovementType(constants.movementTypeSearch.standard);
    setFilterFromDate('');
    setFilterToDate(today);
    setFilterFromCph('');
    setFilterToCph('');
    setFilterMovementRef('');
    setFilterPaperId('');
    setFromDateError('');
    setToDateError('');
    setRenderTable(false);

    storeService.session.remove.searchResultsMovements();
    setData([]);

    setShowRecordPaperMovementButton(false);
  };

  const runRequest = () => {
    storeService.session.set[`tableFilters${storeFilters}PageIndex`](0);
    storeService.session.removeAll.searchResults();
    setData([]);

    setTableParams((prevState) => ({
      ...prevState,
      newRequest: true,
      columns: get.columnsByTab(),
      filters: {
        ...get.paramsByTab()
      },
      page: {
        ...prevState.page,
        index: 0
      },
      request: {
        ...prevState.request,
        url: constants.bo.movementSearch.tabs[sessionActiveTabId].endpoint(filterMovementType),
        params: {
          ...prevState.request.params,
          ...get.paramsByTab()
        },
        processData: (data) => data.map((row) => ({
          ...row,
          species: row.speciesId ? helpers.species.idToName(row.speciesId) : row.species,
          movementRef: row.movementReferenceUndone ? row.movementReferenceUndone : row.movementRef,
          movementStatus: helpers.status.getType(t, row)
        })),
        redirectTo: helpers.tab.isPaperId(sessionActiveTabId)
          ? (data) => {
            if ([
              constants.paperDocumentStatus.COMPLETE,
              constants.paperDocumentStatus.HAS_PROCESSING_ERRORS
            ].includes(data[0].status)) {
              return {
                warning: sprintf(t('label.paperIdIsMovementRecord'), data[0].movementDocumentRef)
              };
            }

            return {
              pathname: Routing.boMovements + data[0].id,
              state: {
                data: {
                  data: data[0]
                }
              }
            };
          }
          : null
      }
    }));
    setRenderTable(true);
    setShowRecordPaperMovementButton(true);
  };

  useEffect(() => {
    if (filterFromCph?.length === config.LENGTH_HOLDING_CPH || filterToCph?.length === config.LENGTH_HOLDING_CPH) {
      let error = '';
      const fromCphIsEnglish = holdingHelper.cph.isEnglish(filterFromCph) || holdingHelper.cph.isTest(filterFromCph);
      const toCphIsEnglish = holdingHelper.cph.isEnglish(filterToCph) || holdingHelper.cph.isTest(filterToCph);

      if (filterFromCph?.length === config.LENGTH_HOLDING_CPH && !fromCphIsEnglish && filterToCph?.length === config.LENGTH_HOLDING_CPH && !toCphIsEnglish) {
        error = 'error.crossBorder2CPHs';
      } else if ((filterFromCph && !fromCphIsEnglish && !filterToCph) || (filterToCph && !toCphIsEnglish && !filterFromCph)) {
        error = 'error.crossBorder1CPH';
      }
      setCrossBorderError(error);
    } else {
      setCrossBorderError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterToCph, filterFromCph, setCrossBorderError]);

  useEffect(() => {
    if (loadPending ||
      (sessionActiveTabId === constants.tabs.movementRange && (!filterFromDate || !filterToDate || fromDateError || toDateError || crossBorderError || (!filterFromCph && !filterToCph) || (filterFromCph?.length > 0 && filterFromCph?.length < config.LENGTH_HOLDING_CPH) || (filterToCph?.length > 0 && filterToCph?.length < config.LENGTH_HOLDING_CPH))) ||
      (sessionActiveTabId === constants.tabs.movementRef && !filterMovementRef) ||
      (sessionActiveTabId === constants.tabs.paperId && !filterPaperId)
    ) {
      setSearchDisabled(true);
    } else {
      setSearchDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadPending,
    // hasData,
    filterSpeciesName,
    filterMovementType,
    filterFromDate,
    filterToDate,
    filterFromCph,
    filterToCph,
    filterMovementRef,
    filterPaperId,
    fromDateError,
    toDateError,
    crossBorderError
  ]);

  useEffect(() => {
    setSearchDisabled(true);

    setTableParams((prevState) => ({
      ...prevState,
      columns: get.columnsByTab(),
      request: {
        ...prevState.request,
        url: constants.bo.movementSearch.tabs[sessionActiveTabId]?.endpoint(filterMovementType),
        params: {
          ...prevState.request.params,
          ...get.paramsByTab()
        },
        processData: (data) => data.map((row) => ({
          ...row,
          species: row.speciesId ? helpers.species.idToName(row.speciesId) : row.species,
          movementRef: row.movementReferenceUndone ? row.movementReferenceUndone : row.movementRef,
          movementStatus: helpers.status.getType(t, row)
        })),
        redirectTo: helpers.tab.isPaperId(sessionActiveTabId)
          ? (data) => {
            if ([
              constants.paperDocumentStatus.COMPLETE,
              constants.paperDocumentStatus.HAS_PROCESSING_ERRORS
            ].includes(data[0].status)) {
              return {
                warning: sprintf(t('label.paperIdIsMovementRecord'), data[0].movementDocumentRef)
              };
            }

            return {
              pathname: Routing.boMovements + data[0].id,
              state: {
                data: {
                  data: data[0]
                }
              }
            };
          }
          : null
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <>
          {render.filters()}
          {render.buttons()}
        </>
      }
    </>
  );
};

BoMovementSearchByDateAndCphFilters.propTypes = {
  hasData: PropTypes.bool.isRequired,
  loadPending: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  setRenderTable: PropTypes.func.isRequired,
  setTableParams: PropTypes.func.isRequired,
  storeFilters: PropTypes.string.isRequired,
  tableParams: PropTypes.object.isRequired
};

export default BoMovementSearchByDateAndCphFilters;
