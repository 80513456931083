import React, { useEffect, useState } from 'react';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import { useTranslation } from 'react-i18next';
import Routing from 'routing';
import vetModuleHelper from 'services/vetModuleHelper';
import { isCancel } from 'services/bff';
import storeService from 'services/storeService';
import Linker from 'components/base/linker/linker';
import Address from 'components/address/address';
import constants from 'services/constants';
import helpers from 'services/helpers';
import searchHelper from 'services/searchHelper';

const AbattoirDeclarationTags = ({ match, setModal }) => {
  const { ready, t } = useTranslation(),
    abattoirTagItems = storeService.session.get.abattoirTagsData() ? storeService.session.get.abattoirTagsData() : [],
    filterData = abattoirTagItems.filter((item) => item.id === parseInt(match.params.tagId)),
    [attestationRecord, setAttestationRecord] = useState({}),
    [vanNo, setVanNo] = useState(''),
    [, setSearched] = React.useState(false),
    [, setSearchNoResults] = React.useState(false),
    [holdingResult, setHoldingResult] = React.useState(false),
    printAndDownload = () => {
      window.print();
    };

  const cphNo = filterData[0]?.setPic;

  useEffect(() => {
    vetModuleHelper.fetchVetDetails(cphNo, 'true')
      .then((res) => {
        const vetResult = res.data.data[0];
        setAttestationRecord(vetResult);
        const vetAttestationNumber = helpers.vetAttestationNumber.generateVan(vetResult.vetRCVS, vetResult.holding, vetResult.startDate);
        setVanNo(vetAttestationNumber);
      })
      .catch((error) => {
        if (!isCancel(error)) {
          return false;
        }
      });

    const query = {};
    if (cphNo && helpers.validate.cphNumber(cphNo)) {
      query.cph = cphNo;
    }
    searchHelper.searchCPH(query, setSearched, setSearchNoResults)
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          const resData = res.data.data?.[0];
          if (resData) {
            setHoldingResult(resData);
          }
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setSearchNoResults(true);
        }
      });
    helpers.scrollToTop();
  }, [cphNo]);

  return (
    <>
      {ready && holdingResult && attestationRecord &&
        <>
          <main className={classes.mainContanier}>
            <section className={classes.titleSection + ' ' + classes.bottomBorder}>
              <h1 className={classes.pageTitle}>{t('vetContent.attestationDecRecord')}</h1>
            </section>

            <div className={classes.vetDeclarationBack}>
              <Linker
                label="button.back"
                linkType="back"
                to={Routing.abattoirAttestationTagResult}
              />
            </div>

            <section className={classes.bodySection}>
              <div className={classes.summaryBlock + ' ' + classes.topSpace}>
                <div className={classes.summaryRow}>
                  <div className={classes.label}>{t('vetContent.visitDate')}</div>
                  <div className={classes.value}>{helpers.date.format(attestationRecord.startDate)}</div>
                </div>
                {vanNo &&
                  <div className={classes.summaryRow}>
                    <div className={classes.label}>{t('vetContent.vetAttestationNumber')}</div>
                    <div className={classes.value}>{vanNo ? vanNo : '-'}</div>
                  </div>
                }
                <div className={classes.summaryRow}>
                  <div className={classes.label}>{t('label.holdingName')}</div>
                  <div className={classes.value}>{holdingResult.propertyName ? holdingResult.propertyName : '-'}</div>
                </div>
                <div className={classes.summaryRow}>
                  <div className={classes.label}>{t('label.holdingAddress')}</div>
                  <div className={classes.value}>
                    <Address
                      cph={cphNo}
                      displayCPH = {false}
                      format={{ address: constants.address.format.onlyAddress }}
                      setModal={setModal}
                    />
                  </div>
                </div>
                <div className={classes.summaryRow}>
                  <div className={classes.label}>{t('label.cphNumber')}</div>
                  <div className={classes.value}>{attestationRecord.holding}</div>
                </div>
                <div className={classes.summaryRow}>
                  <div className={classes.label}>{t('vetContent.vetClaims.veterinarianName')}</div>
                  <div className={classes.value}>{attestationRecord.vetName}</div>
                </div>
                <div className={classes.summaryRow}>
                  <div className={classes.label}>{t('vetContent.vetClaims.practiceName')}</div>
                  <div className={classes.value}>{attestationRecord.vetEmployer}</div>
                </div>
                <div className={classes.summaryRow}>
                  <div className={classes.label}>{t('vetContent.vetClaims.practiceAddress')}</div>
                  <div className={classes.value}>{attestationRecord.vetEmploymentAddress}</div>
                </div>
                <div className={classes.summaryRow}>
                  <div className={classes.label}>{t('vetContent.vetClaims.rcvsNumber')}</div>
                  <div className={classes.value}>{attestationRecord.vetRCVS}</div>
                </div>
              </div>
              <div className={classes.declarationBlock}>
                <div className={classes.fontBold}>{t('vetContent.vetDeclaration.title')}</div>
                <p>{t('vetContent.vetDeclaration.content1')}</p>
                <div>{helpers.date.format(attestationRecord.startDate)}</div>
                <p className={classes.declartionContent}>
                  {t('vetContent.vetDeclaration.content2-part-1')}
                  <span>&#8544;</span>&nbsp;
                  {t('vetContent.vetDeclaration.content2-part-2')}
                </p>
                <div className={classes.fontBold}>{attestationRecord.vetName}</div>
              </div>
            </section>

            <section className={classes.buttonSection + ' ' + classes.displayBtnBoth + ' ' + classes.notForPrint}>
              <span className={classes.buttonLink} onClick={printAndDownload}>{t('button.printSave')}</span>
            </section>
          </main>
        </>
      }
    </>
  );
};

export default AbattoirDeclarationTags;
