import React from 'react';
import classes from 'backOfficeComponents/cphEntry/cphEntry.module.scss';
import constants, { fieldWidthType } from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Address from 'backOfficeComponents/address/address';
import CPHField from 'components/base/cphField/cphField';
import ErrorLabel from 'components/base/errorLabel/errorLabel';

const CPHEntry = ({
  addressClassNames,
  cphError,
  data,
  disabled,
  errorClassNames,
  format,
  hideTooltip,
  labelText,
  name,
  optional,
  setCphType,
  setCphValid,
  setData,
  setModal,
  setUserName
}) => {
  const handleChanges = {
    onChange: (event) => {
      setData(event.target.value.trim());
    }
  };

  return (
    <>
      <CPHField
        classNames={['bo', 'small']}
        cph={data}
        disabled={disabled}
        error={helpers.response.isError(cphError?.type) && !cphError.inline ? cphError.value : ''}
        hideTooltip={hideTooltip}
        id={name}
        label={labelText}
        onBlur={handleChanges.onBlur}
        optional={optional}
        setCph={setData}
        warningLabel={helpers.response.isWarning(cphError?.type) ? cphError.value : ''}
        width={fieldWidthType.QUARTER}
      />

      {helpers.response.isError(cphError?.type) && cphError?.inline &&
        <div className={classes.nonEnglishCph}>
          <ErrorLabel
            classNames={['inline-bo']}
            label={cphError.value}
          />
        </div>
      }

      {(!cphError?.type || !cphError?.inline) &&
        <div className={addressClassNames ? addressClassNames.map((className) => classes[className]) : ''}>
          <Address
            cph={data}
            errorClassNames={errorClassNames}
            format={{
              address: format?.address ? format.address : constants.address.format.full,
              name: format?.name ? format.name : constants.address.format.full,
              type: format?.type ? format.type : constants.address.format.full
            }}
            setCphType={setCphType}
            setCphValid={setCphValid}
            setModal={setModal}
            setUserName={setUserName}
          />
        </div>
      }
    </>
  );
};

CPHEntry.propTypes = {
  addressClassNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  cphError: PropTypes.exact({
    type: PropTypes.oneOf([
      constants.errorAndWarning.error,
      constants.errorAndWarning.warning,
      ''
    ]),
    inline: PropTypes.bool,
    value: PropTypes.string
  }),
  data: PropTypes.string,
  disabled: PropTypes.bool,
  errorClassNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  format: PropTypes.exact({
    address: PropTypes.oneOf([
      constants.address.format.full,
      constants.address.format.short,
      constants.address.format.line,
      constants.address.format.inline,
      constants.address.format.none
    ]),
    name: PropTypes.oneOf([
      constants.address.format.full,
      constants.address.format.inline,
      constants.address.format.none
    ]),
    type: PropTypes.oneOf([
      constants.address.format.full,
      constants.address.format.none
    ])
  }),
  hideTooltip: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  setCphType: PropTypes.func,
  setCphValid: PropTypes.func,
  setData: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setUserName: PropTypes.func
};

export default CPHEntry;
