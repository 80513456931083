import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/modalAddBatch/modalAddBatch.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import { tableColumns } from 'services/tableColumns';
import AddBatch from 'components/addBatch/addBatch';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Table from 'components/table/table';

const ModalAddBatch = ({
  batchList,
  clearAll,
  modalAnimals,
  pending,
  removeAllInvalid,
  removeBatch,
  setModal,
  setModalAnimals,
  setPending,
  species,
  updateBatchList
}) => {
  const { ready } = useTranslation();

  const [paginationParams, setPaginationParams] = useState({
    ...constants.pagination.defaultParams,
    [constants.pagination.bottom]: {
      ...constants.pagination.defaultParams[constants.pagination.bottom],
      totalLabel: 'label.totalBatches'
    }
  });

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      [constants.pagination.bottom]: {
        ...prevState[constants.pagination.bottom],
        total: modalAnimals?.length
      }
    }));
  }, [modalAnimals]);

  return (
    <>
      {ready &&
        <div className={classes.content}>
          <div className={classes.input}>
            <AddBatch
              animalTotalLabel="movements.animal-selection.batch.input-field-two"
              batchDescription="movements.animal-selection.batch.desc"
              batchHeader="movements.animal-selection.batch.title"
              batchList={batchList}
              batchNumberOptional={true}
              buttonLabel="movements.animal-selection.batch.submit"
              pending={pending}
              setModal={setModal}
              setPending={setPending}
              species={species}
              updateBatchList={updateBatchList}
            />
          </div>

          {modalAnimals?.some((batch) => helpers.batch.isInvalid(batch)) &&
            <ErrorLabel
              label="formatExamples.tagBatchError"
              modalMessage={helpers.batch.getFormatExamples(species.id).description}
              modalTitle={helpers.batch.getFormatExamples(species.id).title}
              setModal={setModal}
            />
          }

          {modalAnimals?.length > 0 &&
            <div className={classes.output}>
              <div className={classes.tableContainer}>
                <Table
                  clearAll={clearAll}
                  columns={tableColumns.movements.modalAddBatch({ speciesId: species.id, actionFunction: removeBatch })}
                  data={modalAnimals}
                  dataProvided={true}
                  paginationParams={paginationParams}
                  removeAllInvalid={removeAllInvalid}
                  setData={setModalAnimals}
                  setModal={setModal}
                  title={{
                    title: helpers.text.pluralCheck(modalAnimals?.reduce((total, item) => total + parseInt(item.animalTotal), 0), 'table.title.batches'),
                    data: [
                    modalAnimals?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
                    ]
                  }}
                />
              </div>
            </div>
          }
        </div>
      }
    </>
  );
};

ModalAddBatch.propTypes = {
  batchList: PropTypes.array.isRequired,
  clearAll: PropTypes.func,
  modalAnimals: PropTypes.array.isRequired,
  pending: PropTypes.bool.isRequired,
  removeAllInvalid: PropTypes.func,
  removeBatch: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setModalAnimals: PropTypes.func.isRequired,
  setPending: PropTypes.func.isRequired,
  species: PropTypes.exact({
    id: PropTypes.number,
    name: PropTypes.string,
    notifications: PropTypes.number,
    errorFound: PropTypes.number
  }).isRequired,
  updateBatchList: PropTypes.func.isRequired
};

export default ModalAddBatch;
