import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/errorLabel/errorLabel.module.scss';
import helpers from 'services/helpers';

const ErrorLabel = ({
  classNames,
  extraContent,
  isSuccess,
  isWarning,
  label,
  modalMessage,
  modalTitle,
  setModal
}) => {
  const { ready, t } = useTranslation();

  // Dirty fix as a colon in the string causes everything before the colon to be lost
  const checkColon = label && typeof label === 'string' ? label.split(':') : [];
  const outputLabel = checkColon.length > 1 ? label : t(label);

  const componentClassNames = [
    classes.error,
    classes.message,
    isWarning ? classes.warning : '',
    isSuccess ? classes.success : '',
    ...(classNames ? classNames.map((className) => classes[className]) : [])
  ].join(' ').trim();

  return (
    <>
      {ready && outputLabel &&
        <>
          {!setModal &&
            <div className={componentClassNames}>
              <span dangerouslySetInnerHTML={{ __html: outputLabel + (extraContent ? ' ' + t(extraContent) : '') }} />
            </div>
          }

          {setModal &&
            <span className={componentClassNames}>
              <p>
                {helpers.text.getContentsLink(
                  t,
                  outputLabel,
                  () => setModal({
                    classNames: ['wide'],
                    modalTitle: modalTitle,
                    modalMessage: modalMessage
                  })
                )}
              </p>
            </span>
          }
        </>
      }
    </>
  );
};

ErrorLabel.propTypes = {
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  extraContent: PropTypes.string,
  isSuccess: PropTypes.bool,
  isWarning: PropTypes.bool,
  label: PropTypes.string,
  modalMessage: PropTypes.string,
  modalTitle: PropTypes.string,
  setModal: PropTypes.func
};

export default ErrorLabel;
