import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'frontEnd/movementsGatherings/foodChainInformation/foodChainInformation.module.scss';
import config from 'config';
import constants from 'services/constants';
import he from 'he';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { tableColumns } from 'services/tableColumns';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Table from 'components/table/table';
import TextEntry from 'components/base/textEntry/textEntry';

const FoodChainInformation = ({
  animals,
  batchList,
  choiceDisabled,
  disabledIcon,
  fciData,
  metFCIWithdrawalPeriods,
  panelTitle,
  setAnimals,
  setDataChanged,
  setFciData,
  setModal,
  speciesId
}) => {
  const { ready, t } = useTranslation();

  const [recordFCI, setRecordFCI] = React.useState(choiceDisabled ? null : constants.option.recordFCI.no);
  const [withdrawalPeriod, setWithdrawalPeriod] = React.useState(null);
  const [doAnimalsSatisfy, setDoAnimalsSatisfy] = React.useState(constants.option.animalsSatisfy.doSatisfy);
  const [unfitAnimalTagNumbers, setUnfitAnimalTagNumbers] = React.useState([]);
  const [nonComplianceReason, setNonComplianceReason] = React.useState();
  const [holdingRestrictions, setHoldingRestrictions] = React.useState();

  const paginationParams = constants.pagination.defaultParams;
  paginationParams[constants.pagination.bottom].total = animals?.length;

  const dataHasChanged = () => {
    storeService.session.set.dataChanged(true);
    setDataChanged(true);
  };

  const handleChanges = {
    animalSelected: (tagNumber) => {
      setAnimals((prevState) => prevState.map((animal) => ({
        ...animal,
        unfit: animal.tagNumber === tagNumber ? !animal.unfit : animal.unfit
      })));

      setUnfitAnimalTagNumbers((prevState) => {
        if (prevState.includes(tagNumber)) {
          return prevState.filter((animalTagNumber) => animalTagNumber !== tagNumber);
        }

        return [...prevState, tagNumber];
      });

      setFciData((prevState) => {
        let unfitAnimals;
        if (prevState.unfitAnimals) {
          if (prevState.unfitAnimals.includes(tagNumber)) {
            unfitAnimals = prevState.unfitAnimals.filter((animalTagNumber) => animalTagNumber !== tagNumber);
          } else {
            unfitAnimals = [...prevState.unfitAnimals, tagNumber];
          }
        } else {
          unfitAnimals = [tagNumber];
        }

        storeService.session.set.movementFciUnfitAnimals(unfitAnimals);

        return { ...prevState, unfitAnimals };
      });
    },

    changeHoldingRestrictions: (event) => {
      storeService.session.set.movementFciHoldingRestrictions(event.target.value);
      setHoldingRestrictions(event.target.value);

      setFciData((prevState) => ({
        ...prevState,
        holdingRestrictions: event.target.value
      }));

      dataHasChanged();
    },

    nonComplianceReason: (event) => {
      storeService.session.set.movementFciNonComplianceReason(event.target.value);
      setNonComplianceReason(event.target.value);

      setFciData((prevState) => ({
        ...prevState,
        nonComplianceReason: event.target.value
      }));

      dataHasChanged();
    },

    recordFCI: (record) => {
      setRecordFCI(record);
      storeService.session.set.movementFciRecordFci(record);

      if (helpers.option.fci.doNotRecord(record)) {
        setDoAnimalsSatisfy(null);
        setWithdrawalPeriod(null);
        setUnfitAnimalTagNumbers([]);
      } else if (batchList?.length > 0 && (!animals || animals?.length === 0)) {
        setDoAnimalsSatisfy(constants.option.animalsSatisfy.doSatisfy);
      }

      setFciData((prevState) => ({
        ...prevState,
        recordFCI: record,
        animalsSatisfy: record === constants.option.recordFCI.yes ? constants.option.animalsSatisfy.doSatisfy : null
      }));

      dataHasChanged();
    },

    withdrawalPeriod: (eventTarget) => {
      metFCIWithdrawalPeriods.forEach((record) => {
        if (record.code === eventTarget.value) {
          storeService.session.set.movementFciWithdrawalPeriod(record);
          setWithdrawalPeriod(record);

          setFciData((prevState) => ({
            ...prevState,
            withdrawalPeriod: record
          }));
        }
      });

      dataHasChanged();
    },

    setDoAnimalsSatisfy: (satisfy) => {
      storeService.session.set.movementFciAnimalsSatisfy(satisfy);
      setDoAnimalsSatisfy(satisfy);

      if (helpers.option.animals.doSatisfy(satisfy)) {
        setNonComplianceReason(null);
        setHoldingRestrictions(null);
        setWithdrawalPeriod(null);
        setUnfitAnimalTagNumbers([]);
      }

      setFciData((prevState) => ({
        ...prevState,
        animalsSatisfy: satisfy
      }));

      dataHasChanged();
    }
  };

  useEffect(() => {
    const sessionRecordFCI = storeService.session.get.movementFciRecordFci();

    if (sessionRecordFCI) {
      setRecordFCI(sessionRecordFCI);

      if (helpers.option.fci.doRecord(sessionRecordFCI)) {
        const sessionAnimalsSatisfy = storeService.session.get.movementFciAnimalsSatisfy();

        if (sessionAnimalsSatisfy) {
          setDoAnimalsSatisfy(sessionAnimalsSatisfy);

          if (helpers.option.animals.doNotSatisfy(sessionAnimalsSatisfy)) {
            const sessionUnfitAnimals = storeService.session.get.movementFciUnfitAnimals();
            const sessionNonComplianceReason = storeService.session.get.movementFciNonComplianceReason();
            const sessionWithdrawalPeriod = storeService.session.get.movementFciWithdrawalPeriod();
            const sessionHoldingRestrictions = storeService.session.get.movementFciHoldingRestrictions();

            if (sessionUnfitAnimals.length > 0) {
              setUnfitAnimalTagNumbers(sessionUnfitAnimals);
            }
            if (sessionNonComplianceReason) {
              setNonComplianceReason(sessionNonComplianceReason);
            }
            if (sessionWithdrawalPeriod) {
              setWithdrawalPeriod(sessionWithdrawalPeriod);
            }
            if (sessionHoldingRestrictions) {
              setHoldingRestrictions(sessionHoldingRestrictions);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWithdrawalPeriod(fciData.withdrawalPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fciData.withdrawalPeriod]);

  return (
    <>
      {ready &&
        <details className="accordion">
          <summary>
            <h2><i className={disabledIcon ? 'bi' : 'bi bi-info-circle'} /> {t(panelTitle)}</h2>
          </summary>
          <div>
            <div className="section">
              <RadioButtons
                classNames={['bo']}
                id="recordFci"
                ids={[
                  constants.option.recordFCI.yes,
                  constants.option.recordFCI.no
                ]}
                name="recordFCI"
                onChange={(event) => handleChanges.recordFCI(event.target.value)}
                value={recordFCI}
              />
            </div>

            {recordFCI === constants.option.recordFCI.yes &&
              <>
                <div className="section">
                  <RadioButtons
                    classNames={['bo']}
                    disabled={choiceDisabled
                      ? [
                        constants.option.animalsSatisfy.doSatisfy,
                        constants.option.animalsSatisfy.doNotSatisfy
                      ]
                      : (
                        batchList?.length > 0 &&
                        (
                          !animals ||
                          animals?.length === 0
                        )
                          ? [constants.option.animalsSatisfy.doNotSatisfy]
                          : []
                      )}
                    id="animalsSatisfy"
                    ids={[
                      constants.option.animalsSatisfy.doSatisfy,
                      constants.option.animalsSatisfy.doNotSatisfy
                    ]}
                    name="boAnimalsSatisfy"
                    onChange={(event) => handleChanges.setDoAnimalsSatisfy(event.target.value)}
                    value={doAnimalsSatisfy}
                  />
                </div>

                {doAnimalsSatisfy === constants.option.animalsSatisfy.doNotSatisfy &&
                  <>
                    {fciData &&
                      <Table
                        columns={helpers.species.isDeerId(speciesId)
                          ? tableColumns.fci.deer({ unfitAnimalTagNumbers, animalSelected: handleChanges.animalSelected })
                          : tableColumns.fci.nonDeer({ unfitAnimalTagNumbers, animalSelected: handleChanges.animalSelected })
                        }
                        data={animals}
                        dataProvided={true}
                        paginationParams={paginationParams}
                        setData={setAnimals}
                        setModal={setModal}
                      />
                    }

                    {metFCIWithdrawalPeriods &&
                      <RadioButtons
                        classNames={['bo']}
                        id="withdrawalPeriod"
                        ids={metFCIWithdrawalPeriods.map((item) => ({
                          id: item.code,
                          label: item.name
                        }))}
                        name="medicineWithdrawal"
                        onChange={(event) => handleChanges.withdrawalPeriod(event.target)}
                        value={withdrawalPeriod ? withdrawalPeriod.code : null}
                      />
                    }
                    {!metFCIWithdrawalPeriods &&
                      <ErrorLabel
                        isWarning={true}
                        label="label.noData"
                      />
                    }

                    <div className={classes.foodChainInformation}>
                      <div className={classes.input}>
                        <TextEntry
                          hint="movements.fci-information.input-field-one-hint"
                          id="fciNonCompliance"
                          label="movements.fci-information.input-field-one"
                          maxLength={255}
                          name="fciNonCompliance"
                          onChange={handleChanges.nonComplianceReason}
                          rows={config.ROWS_FCI_NON_COMPLIANCE}
                          tooltip="movements.fci-information.input-field-one-hint"
                          value={nonComplianceReason ? he.decode(nonComplianceReason) : ''}
                          width="full"
                        />
                      </div>

                      <div className={classes.input}>
                        <TextEntry
                          hint="movements.fci-information.character-limit-255-hint"
                          id="holdingRestrictions"
                          label={helpers.species.isDeerId(speciesId) ? 'movements.fci-information.input-field-three-deer' : 'movements.fci-information.input-field-three-sheepAndGoats' }
                          maxLength={255}
                          name="holdingRestrictions"
                          onChange={handleChanges.changeHoldingRestrictions}
                          rows={config.ROWS_HOLDING_RESTRICTIONS}
                          value={holdingRestrictions ? he.decode(holdingRestrictions) : ''}
                          width="full"
                        />
                      </div>
                    </div>
                  </>
                }
              </>
            }
          </div>
        </details>
      }
    </>
  );
};

FoodChainInformation.propTypes = {
  animals: PropTypes.array,
  batchList: PropTypes.array,
  choiceDisabled: PropTypes.bool,
  disabledIcon: PropTypes.bool,
  fciData: PropTypes.object,
  panelTitle: PropTypes.string,
  setAnimals: PropTypes.func,
  setDataChanged: PropTypes.func,
  setFciData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  speciesId: PropTypes.number
};

export default FoodChainInformation;
