import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import VerifyCustomerInformation from 'backOfficeComponents/sections/verifyCustomerInformation/verifyCustomerInformation';
// import VerifyCustomerRecentRecords from 'backOfficeComponents/sections/verifyCustomerRecentRecords/verifyCustomerRecentRecords';

const BoEditCustomer = ({
  location,
  setModal
}) => {
  useEffect(() => {
    helpers.scrollToTop(document.getElementById('appContent'));
  }, []);

  return (
    <>
      {!location.state &&
        <Redirect to={Routing.boVerifyCustomer} />
      }

      {location.state?.data &&
        <>
          <PageTitle
            id="verifyCustomerTitle"
            pageTitleOne="boApp.pageTitles.customers.customers"
            pageTitleTwo="boApp.pageTitles.customers.verifyCustomer"
          />

          <VerifyCustomerInformation
            data={location.state.data}
            setModal={setModal}
          />

          {/*<VerifyCustomerRecentRecords*/}
          {/*  data={location.state.data}*/}
          {/*  setModal={setModal}*/}
          {/*  tables={[*/}
          {/*    {*/}
          {/*      requestType: constants.option.requestType.movement,*/}
          {/*      title: 'boApp.label.recentMovements',*/}
          {/*      id: 'movement'*/}
          {/*    },*/}
          {/*    {*/}
          {/*      requestType: constants.option.requestType.death,*/}
          {/*      title: 'boApp.label.recentDeaths',*/}
          {/*      id: 'death'*/}
          {/*    }*/}
          {/*  ]}*/}
          {/*/>*/}
        </>
      }
    </>
  );
};

BoEditCustomer.propTypes = {
  location: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default BoEditCustomer;
