import React, { useEffect } from 'react';
import classes from 'components/modalAnimalsOnHolding/modalAnimalsOnHolding.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { initialSorting, tableColumns } from 'services/tableColumns';
import Table from 'components/table/table';

const ModalAnimalsOnHolding = ({
  addAll,
  animalsToChange,
  clearAll,
  departureDate,
  modalAnimals,
  setModal,
  setModalAnimals,
  setModalAnimalsInitial,
  toggleAllAnimals,
  toggleAnimal
}) => {
  const sessionSpecies = storeService.session.get.species();
  const speciesId = sessionSpecies ? sessionSpecies.id : '';
  const sessionHolding = storeService.session.get.holding();
  const cph = sessionHolding ? sessionHolding.value : '';

  const [animalsOnHolding, setAnimalsOnHolding] = React.useState([]);
  const [numberOfAnimalsOnHolding, setNumberOfAnimalsOnHolding] = React.useState(0);

  const tableParams = {
    request: {
      url: '/animals',
      params: {
        speciesId,
        cph,
        onHoldingDate: helpers.date.endOfDay(departureDate)
      },
      processData: (data) => helpers.animal.addDobAndAgeForDisplay(data)
    }
  };

  useEffect(() => {
    const animalsToChangeOnHoldingsOnly = JSON.parse(JSON.stringify(animalsToChange.filter((item) => item.onHolding)));

    setModalAnimalsInitial(animalsToChangeOnHoldingsOnly);
    setModalAnimals(animalsToChangeOnHoldingsOnly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.content}>
      <div className={classes.output}>
        <div className={classes.tableContainer}>
          <Table
            addAll={modalAnimals.length < numberOfAnimalsOnHolding ? addAll : null}
            addAllAbove={modalAnimals.length < numberOfAnimalsOnHolding ? () => toggleAllAnimals(animalsOnHolding, true) : null}
            clearAll={clearAll}
            columns={tableColumns.movements.modalAnimalsOnHolding({ actionType: 'toggle', actionFunction: toggleAnimal, sortable: true })}
            data={animalsOnHolding.map((item1) =>
              modalAnimals.find((item2) => item2.tagNumber === item1.tagNumber)
                ? { ...item1, added: true }
                : { ...item1, added: false }
            )}
            filtersComponents={{
              [constants.filters.inside]: 'AnimalsOnHolding'
            }}
            initialSortBy={initialSorting.movements.modalAnimalsOnHolding.accessor}
            initialSortDirection={initialSorting.movements.modalAnimalsOnHolding.direction}
            params={tableParams}
            setData={setAnimalsOnHolding}
            setDataTotal={setNumberOfAnimalsOnHolding}
            setModal={setModal}
            store={{
              filters: 'AnimalsOnHolding',
              data: 'AnimalsOnHolding'
            }}
            title="table.title.animalsOnHolding"
          />
        </div>
      </div>
    </div>
  );
};

ModalAnimalsOnHolding.propTypes = {
  addAll: PropTypes.func.isRequired,
  animalsToChange: PropTypes.array.isRequired,
  clearAll: PropTypes.func.isRequired,
  departureDate: PropTypes.string.isRequired,
  modalAnimals: PropTypes.array.isRequired,
  setModal: PropTypes.func.isRequired,
  setModalAnimals: PropTypes.func.isRequired,
  setModalAnimalsInitial: PropTypes.func.isRequired,
  toggleAllAnimals: PropTypes.func.isRequired,
  toggleAnimal: PropTypes.func.isRequired
};

export default ModalAnimalsOnHolding;
