import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import SelectSearch from 'react-select-search/dist/cjs';

const DropdownAgeRange = ({
  all,
  onChange,
  speciesId,
  value
}) => {
  const { ready, t } = useTranslation();

  const species = storeService.session.get.species();

  const getItemList = () => {
    let theArray = [];

    if (all) {
      theArray.push({ value: 'all', name: t('dropdowns.ageRange.all') });
    }

    theArray.push({ value: 'x', name: t('dropdowns.ageRange.unknown') });

    switch (speciesId ? speciesId : species.id) {
      default:
        theArray = [
          ...theArray,
          { value: 'a', name: t('dropdowns.ageRange.0-12months') },
          { value: 'g', name: t('dropdowns.ageRange.1-2years') },
          { value: 'h', name: t('dropdowns.ageRange.2-5years') },
          { value: 'i', name: t('dropdowns.ageRange.5-10years') },
          { value: 'j', name: t('dropdowns.ageRange.10+years') }
        ];
        break;
      case 3: // Cattle
        theArray = [
          ...theArray,
          { value: 'a', name: t('dropdowns.ageRange.0-12months') },
          { value: 'b', name: t('dropdowns.ageRange.12-30months') },
          { value: 'c', name: t('dropdowns.ageRange.30-60months') },
          { value: 'd', name: t('dropdowns.ageRange.5-6years') },
          { value: 'e', name: t('dropdowns.ageRange.6-8years') },
          { value: 'f', name: t('dropdowns.ageRange.8-10years') },
          { value: 'j', name: t('dropdowns.ageRange.10+years') }
        ];
        break;
    }

    return theArray;
  };

  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    setSelectedValues(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {ready &&
        <SelectSearch
          className="select-search"
          closeOnSelect={false}
          id="ageRangeDropdown"
          inputId="ageRangeDropdown"
          multiple
          name="ageRange"
          onChange={onChange}
          onFocus={() => helpers.resetDropdownFocusState()}
          options={getItemList()}
          placeholder={t('dropdowns.ageRange.label')}
          printOptions="on-focus"
          value={selectedValues}
        />
      }
    </>
  );
};

DropdownAgeRange.propTypes = {
  all: PropTypes.bool,
  onChange: PropTypes.func,
  speciesId: PropTypes.number,
  value: PropTypes.array
};

export default DropdownAgeRange;
