import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';
import breedsHelper from 'services/breedsHelper';
import recentlyUsedHelper from 'services/recentlyUsedHelper';

const DropdownBreeds = ({
  className,
  error,
  label,
  name,
  onChange,
  optional,
  value
}) => {
  let itemList = [];
  const speciesId = storeService.session.get.species() ? storeService.session.get.species().id : 1;

  const [recentlyUsed, setRecentlyUsed] = useState(recentlyUsedHelper.getRecentlyUsedList('Breed' + speciesId));
  const [optionsToSend, setOptionsToSend] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    if (itemList.length === 0) {
      getData();
    } else {
      updateRecentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    itemList = breedsHelper.getBreeds();

    if (itemList.length === 0) {
      const breedsCheck = setInterval(() => {
        itemList = breedsHelper.getBreeds();

        if (itemList.length > 0) {
          setItemList();
          clearInterval(breedsCheck);
        }
      }, 500);
    } else {
      setItemList();
    }
  };

  const setItemList = () => {
    let listLabel = '';
    if (recentlyUsed) {
      setOptionsToSend((arr) => [...arr, { name: '', type: 'group', items: recentlyUsed }]);
      if (recentlyUsed.length) {
        listLabel = '---';
      }
    }
    setOptionsToSend((arr) => [...arr, { name: listLabel, type: 'group', items: itemList }]);

    setPending(false);
  };

  const updateRecentList = () => {
    if (JSON.stringify(recentlyUsed) !== JSON.stringify(recentlyUsedHelper.getRecentlyUsedList('Breed' + speciesId))) {
      setRecentlyUsed(recentlyUsedHelper.getRecentlyUsedList('Breed' + speciesId));
      setOptionsToSend([]);
      setOptionsToSend((arr) => [...arr, { name: 'Recently Used', type: 'group', items: recentlyUsed }]);
      setOptionsToSend((arr) => [...arr, { name: 'All Breeds', type: 'group', items: itemList }]);
    }
  };

  const dropdownLabel = () => {
    return label?.length > 0 ? label : 'label.breed';
  };

  return (
    <DropdownSearch
      className={className}
      error={error}
      id="breedDropdown"
      inputId="breed"
      label={dropdownLabel()}
      name={name}
      onChange={onChange}
      optional={optional}
      optionList={optionsToSend}
      pending={pending}
      pendingError="error.noBreeds"
      placeholder="prompt.pleaseSelect"
      value={value}
    />
  );
};

DropdownBreeds.propTypes = {
  className: PropTypes.object,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  value: PropTypes.string
};

export default DropdownBreeds;
