import React, { useEffect } from 'react';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { useTranslation } from 'react-i18next';
import DropdownYear from 'components/dropdownYear/dropdownYear';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import Hint from 'components/base/hint/hint';
import TextEntry from 'components/base/textEntry/textEntry';

const AnnualYear = ({
  displayName,
  groupHint,
  groupLabel,
  label,
  month,
  name,
  onChange,
  previouslySubmittedYears,
  setLoadPending,
  setModal,
  setModalHasChanges,
  value
}) => {
  const { ready, t } = useTranslation();

  useEffect(() => {
    const source = axios.CancelToken.source();

    bff
      .get('/annualDate', {
        cancelToken: source.token,
        params: {
          cph: storeService.session.get.holding().value,
          species: storeService.session.get.species().name
        }
      })
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setLoadPending)) {
          const mth = parseInt(res.data.month) - 1;
          const today = moment();
          const incoming = moment().month(mth).date(res.data.day);
          let theYear = parseInt(today.format('YYYY'));
          if (today.diff(incoming, constants.period.days) < 0) {
            theYear -= 1;
          }
          const monthUi = t('reports.annualInventory.firstDate') + ' ' + t('months.' + (mth + 1).toString());
          onChange({ target: { name: displayName, value: monthUi } });
          if (!previouslySubmittedYears.includes(theYear.toString())) {
            onChange({ target: { name: name, value: theYear.toString() } });
          }
          onChange({ target: { name: 'day', value: res.data.day } });
          onChange({ target: { name: 'month', value: res.data.month } });
          onChange({ target: { name: 'stockType', value: res.data.stockType } });
          setLoadPending(false);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <>
          <FieldLabel
            label={groupLabel}
          />
          <Hint
            hint={groupHint}
          />
          <div className="row">
            <div className="col-md-3">
              <TextEntry
                disabled={true}
                id="inventoryMonth"
                name="inventoryMonth"
                value={month}
              />
            </div>
            <div className="col-md-3">
              <DropdownYear
                doNotAllowCurrentYear={helpers.date.is.beforeDate(helpers.date.todayStartOfDay(), helpers.date.toDate(moment().year(), 12, 1))}
                label={label}
                name={name}
                onChange={(event) => {
                  setModalHasChanges(true);
                  onChange(event);
                }}
                previouslySubmittedYears={previouslySubmittedYears}
                value={value}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

AnnualYear.propTypes = {
  displayName: PropTypes.string.isRequired,
  groupHint: PropTypes.string.isRequired,
  groupLabel: PropTypes.string.isRequired,
  label: PropTypes.string,
  month: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  previouslySubmittedYears: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  setLoadPending: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setModalHasChanges: PropTypes.func,
  value: PropTypes.string.isRequired
};

export default AnnualYear;
