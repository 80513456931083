import React, { useState, useEffect } from 'react';
import axios from 'axios';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import recentlyUsedHelper from 'services/recentlyUsedHelper';
import storeService from 'services/storeService';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';

const DropdownSireIds = ({
  error,
  label,
  name,
  onChange,
  optional,
  setModal,
  value
}) => {
  const [pending, setPending] = useState(true);
  const [optionsToSend, setOptionsToSend] = useState([]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const recentlyUsed = recentlyUsedHelper.getRecentlyUsedList('SireId');
    if (!recentlyUsed || !recentlyUsed.some((item) => item.value === selectedValue)) {
      recentlyUsedHelper.setRecentlyUsedList('SireId', selectedValue);
    }
    onChange(event);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const params = {
      cph: storeService.session.get.holding().value,
      speciesId: storeService.session.get.species().id,
      onHoldingDate: helpers.date.now(),
      gender: 'M',
      dropdown: true
    };

    const setData = (data) => {
      if (data.length > 0) {
        const recentlyUsed = recentlyUsedHelper.getRecentlyUsedList('SireId');
        if (recentlyUsed) {
          setOptionsToSend([
            { label: 'Recently Used', type: 'group', options: recentlyUsed },
            { label: 'All Sires', type: 'group', options: data }
          ]);
        } else {
          setOptionsToSend([
            { label: 'All Sires', type: 'group', options: data }
          ]);
        }
      }
    };

    helpers.get.animalsOnHolding(
      source.token,
      params,
      setModal,
      setPending,
      setData
    );

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownSearch
      error={error}
      id="sireIdDropdown"
      isSearchable={true}
      isSelect={true}
      label={label}
      name={name}
      onChange={handleChange}
      optional={optional}
      optionList={optionsToSend}
      pending={pending}
      pendingError="error.noBirthTags"
      placeholder="prompt.pleaseSelect"
      value={value}
    />
  );
};

DropdownSireIds.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  setModal: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default DropdownSireIds;
