import axios from 'axios';
import errors from 'services/errors';
import codeHelper from 'services/codeHelper';
import constants from 'services/constants';
import { protectedResources } from 'services/b2cService';
import msalInstance from 'msalClient';
import storeService from 'services/storeService';
const printf = require('util').format;

const baseURL = codeHelper.BFF_URL || 3001;
const bff = axios.create({ baseURL: baseURL });

const isCancel = (error) => {
  return axios.isCancel(error);
};

const consolidateErrorOrWarning = (array) => array.reduce((acc, current) => {
  return acc.find((item) => item.code === current.code && item.message === current.message) ? acc : acc.concat([current]);
}, []);

const bffStatus = {
  isCancelled: (status) => status === constants.status.cancelled,

  isClaPending: (status) => status === constants.status.claPending,

  isCompletedError: (status) => status === constants.status.completed_error,

  isCompletedWarning: (status) => status === constants.status.completed_warning,

  isError: (status) => status === constants.status.error,

  isPending: (status) => status === constants.status.pending,

  isProcessError: (status) => status === constants.status.processErrors,

  isReported: (status) => status === constants.status.reported,

  isSuccess: (status) => status === constants.status.success,

  isUnconfirmed: (status) => status === constants.status.unconfirmed,

  getStatus: (reqStatus) => bffStatus.isPending(reqStatus) ? constants.status.claPending : reqStatus
};

const bffResponse = {
  asyncId: (data) => data && data.asyncId ? String(data.asyncId) : null,

  errors: (data) => {
    if (!data) {
      return [];
    }
    if (data.poll?.errors) {
      return consolidateErrorOrWarning(data.poll.errors);
    }
    return data.errors ? consolidateErrorOrWarning(data.errors) : [];
  },

  movementRef: (data) => {
    if (!data) {
      return null;
    }
    if (data.movementDocumentRef) {
      return String(data.movementDocumentRef);
    } else if (data.poll?.content?.documentRef) {
      return String(data.poll.content.documentRef);
    }
    return data.poll?.content?.movementDocument?.movementDocumentRef ? String(data.poll.content.movementDocument.movementDocumentRef) : null;
  },

  paperId: (data) => data && data.paperId ? String(data.paperId) : null,

  requestId: (data) => {
    if (!data) {
      return null;
    }
    if (data.requestId) {
      return String(data.requestId);
    }
    if (bffStatus.isCompletedError(data.poll?.requestStatus)) {
      return String(data.poll.requestId);
    }
    return null;
  },

  status: (data) => {
    if (!data) {
      return null;
    }
    const { poll } = data;
    if (poll) {
      if (poll.requestStatus) {
        return bffStatus.getStatus(data.poll.requestStatus);
      } else if (poll.warnings !== undefined && poll.warnings?.length > 0) {
        return constants.status.completed_warning;
      } else if (poll.errors !== undefined && poll.errors?.length > 0) {
        return constants.status.completed_error;
      }
    }
    if (data.requestStatus) {
      return bffStatus.getStatus(data.requestStatus);
    }
    if (data.warnings !== undefined && data.warnings?.length > 0) {
      return constants.status.completed_warning;
    }
    if ((data.errors !== undefined && data.errors?.length > 0) || (data.errorCode === 400)) {
      return constants.status.completed_error;
    }
    if (!bffResponse.movementRef(data) && bffResponse.paperId(data)) {
      return null;
    }
    return bffResponse.paperId(data) ? constants.status.success : null;
  },

  warnings: (data) => {
    if (!data) {
      return [];
    }
    if (data.poll?.warnings) {
      return consolidateErrorOrWarning(data.poll.warnings);
    }
    return data.warnings ? consolidateErrorOrWarning(data.warnings) : [];
  }
};

bff.interceptors.request.use(
  async (config) => {
    // Try to get token here ----------------------------------------
    // From https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/926f1c2ba0598575e23dfd8cdd8b79fa3a3d19ff/lib/msal-react/docs/getting-started.md#acquiring-an-access-token-outside-of-a-react-component
    // Can't use normal approach i.e. useMsal() as we are outside a React component
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (activeAccount && accounts.length > 0) {
      const response = await msalInstance.acquireTokenSilent({
        scopes: protectedResources.bff.scopes,
        account: activeAccount || accounts[0]
      });

      storeService.cookie.set.xOnBehalfOf(response.idToken);
      config.headers['X-On-Behalf-Of'] = response.idToken;
      config.headers.Authorization = printf('Bearer %s', response.accessToken);
    }
    return config;
  },
  (error) => {
    errors.BFF(error);
    return Promise.reject(error);
  }
);

bff.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { isCancel, consolidateErrorOrWarning, bffStatus, bffResponse };
export default bff;
