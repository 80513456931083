import { holdingHelper } from 'services/holdingHelper';
import bff, { isCancel } from 'services/bff';
import breedsHelper from 'services/breedsHelper';
import configHelper from 'services/configHelper';
import errors from 'services/errors';
import helpers from 'services/helpers';
import permissions from 'services/permissions';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';

const getRequestTypes = (setModal, setLoading) => {
  bff
    .get('/requestTypes')
    .then((res) => {
      if (helpers.response.isValid(res.data, setModal)) {
        storeService.session.set.requestTypes(res.data.data);
      }

      setLoading((prevState) => ({
        ...prevState,
        requestTypes: false
      }));
    })
    .catch((error) => {
      if (!isCancel(error)) {
        errors.BFF(error, setModal);
      }
    });
};

const getHoldingInfo = (setModal, setLoading, history) => {
  const processHoldings = (history, data) => {
    const holdings = {};

    data.forEach((holding) => {
      holdings[holding.value] = {
        ...holding,
        region: holdingHelper.findRegion(holding.value)
      };
    });

    storeService.session.set.holdings(holdings);
    if (permissions.isBackOffice()) {
      history.push(Routing.boMovements);
    } else {
      history.push(Routing.home);
    }
  };

  bff
    .get('/holdings')
    .then((res) => {
      if (helpers.response.isValid(res.data, setModal)) {
        processHoldings(history, res.data);
      }

      setLoading((prevState) => ({
        ...prevState,
        holdings: false
      }));
    })
    .catch((error) => {
      if (!isCancel(error)) {
        errors.BFF(error, setModal);
      }
    });
};

const initialise = (setModal, setLoading, history, role) => {
  if (role === userRoleType.RCVSVET) {
    history.push(Routing.vetPath);
  } else {
    setLoading({
      breeds: true,
      config: true,
      holdings: true,
      requestTypes: true
    });
    configHelper.fetchConfig(setModal, setLoading);
    breedsHelper.fetchBreeds(setModal, setLoading);
    if (role === userRoleType.GENERIC) {
      getHoldingInfo(setModal, setLoading, history);
    }
    getRequestTypes(setModal, setLoading);
  }
};

export default initialise;
