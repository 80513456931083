import React from 'react';
import Routing from 'routing';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from 'frontEnd/abattoirAttestation/abattoirAttestationHome.module.scss';
import storeService from 'services/storeService';

const AbattoirAttestation = () => {

  const { ready, t } = useTranslation(),
    history = useHistory(),
    routeToManualTags = () => {
      history.push(Routing.abattoirManualTags);
    },
    routeToUploadTags = () => {
      history.push(Routing.abattoirUploadTags);
    },
    routeToCheckHolding = () => {
      history.push(Routing.abattoirCheckHolding);
    };

  // To remove selected tags(manual/upload) tags from session.
  if (storeService.session.get.abattoirSelectedTagDetails()) {
    storeService.session.remove.abattoirSelectedTagDetails();
  }

  return (
    <>
      {ready &&
        <>
          <h1 className="h1">{t('abattoirAttestation.vetHeading')}</h1>
          <div className={classes.vetBlock}>
            <h3 className={classes.vetTitle}>{t('abattoirAttestation.vetTitle1')}</h3>
            <p className={classes.vetdesc}>{t('abattoirAttestation.vetDesc1')}</p>
            <span className={classes.vetLink} onClick={routeToManualTags}>{t('abattoirAttestation.clickHere')}</span>
          </div>
          <div className={classes.vetBlock}>
            <h3 className={classes.vetTitle}>{t('abattoirAttestation.vetTitle2')}</h3>
            <p className={classes.vetdesc}>{t('abattoirAttestation.vetDesc2')}</p>
            <span className={classes.vetLink} onClick={routeToUploadTags}>{t('abattoirAttestation.clickHere')}</span>
          </div>
          <div className={classes.vetBlock}>
            <h3 className={classes.vetTitle}>{t('abattoirAttestation.vetTitle3')}</h3>
            <p className={classes.vetdesc}>{t('abattoirAttestation.vetDesc3')}</p>
            <span className={classes.vetLink} onClick={routeToCheckHolding}>{t('abattoirAttestation.clickHere')}</span>
          </div>
        </>
      }
    </>
  );
};

export default AbattoirAttestation;
