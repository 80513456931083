import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import searchHelper from 'services/searchHelper';
import Routing from 'routing';
import helpers from 'services/helpers';
import { isCancel } from 'services/bff';
import storeService from 'services/storeService';
import Linker from 'components/base/linker/linker';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import CPHField from 'components/base/cphField/cphField';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Button from 'components/base/button/button';

const AbattoirCheckHolding = ({ setModal }) => {

  const { ready, t } = useTranslation(),
    history = useHistory(),
    [cphNumber, setCphNumber] = React.useState(''),
    [cphNumberError, setCphNumberError] = React.useState(''),
    [buttonDisabled, setButtonDisabled] = React.useState(true),
    [searched, setSearched] = React.useState(false),
    [searchNoResults, setSearchNoResults] = React.useState(false),
    redirectToNextPage = (resultPage) => {
      history.push(resultPage);
    },
    updateCphData = (cph) => {
      const status = helpers.validate.cphNumber(cph);
      setCphNumberError('');
      setCphNumber(cph);
      setButtonDisabled(!status);
      setSearchNoResults(false);
    },
    searchForAbattoirHolding = () => {
      const query = {};
      if (cphNumber && helpers.validate.cphNumber(cphNumber)) {
        query.cph = cphNumber;
      }
      searchHelper.searchCPH(query, setSearched, setSearchNoResults)
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal)) {
            const resData = res.data.data?.[0];
            if (resData) {
              setSearchNoResults(false);
              if (storeService.session.get.vetCPHData()) {
                storeService.session.remove.vetCPHData();
                storeService.session.set.vetCPHData(resData);
              } else {
                storeService.session.set.vetCPHData(resData);
              }
              redirectToNextPage(Routing.abattoirAttestationResult);
            } else {
              setSearchNoResults(true);
            }
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setSearchNoResults(true);
          }
        });
    };
  return (
    <>
      {ready &&
        <>
          <h1 className="h1">{t('abattoirAttestation.vetHeading')}</h1>
          <div >
            <Linker
              label="button.back"
              linkType="back"
              to={Routing.abattoirAttestation}
            />
          </div>
          <section className={classes.bodySection}>
            <h3 className="h3">{t('abattoirAttestation.vetTitle3')}</h3>
            <div className={classes.flexRow + ' ' + classes.alignFlexEnd}>
              <div className={classes.cphNumber}>
                <CPHField
                  cph={cphNumber}
                  error={cphNumberError}
                  id="abattoirHolding"
                  label="vetHomePage.enterCphNo"
                  setCph={updateCphData}
                />
              </div>
              <div className={classes.action}>
                <Button
                  buttonType="secondary"
                  disabled={buttonDisabled}
                  id="search-by-cph-detail"
                  label="button.search"
                  onClick={searchForAbattoirHolding}
                />
              </div>
            </div>
            <div>
              { searched &&
                <>
                  {searchNoResults &&
                    <ErrorLabel
                      error={true}
                      label={'label.cphNotFound'}
                    />
                  }
                </>
              }
            </div>
          </section>
        </>
      }
    </>
  );
};

export default AbattoirCheckHolding;
