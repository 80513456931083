import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Routing from 'routing';
import Linker from 'components/base/linker/linker';
import helpers from 'services/helpers';
import ModalFileUpload from 'components/modalFileUpload/modalFileUpload';
import storeService from 'services/storeService';
import PropTypes from 'prop-types';
import Button from 'components/base/button/button';
import classes from 'frontEnd/abattoirAttestation/uploadTags/uploadTags.module.scss';
import constants from 'services/constants';

const AbattoirUploadTags = ({
  setModal
}) => {

  const { ready, t } = useTranslation(),
    history = useHistory();
  const [tagLimitExeed, setTagLimitExeed] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [searchDisabled, setSearchDisabled] = React.useState(true);
  const [modalAnimals, setModalAnimals] = React.useState([]);
  const species = storeService.session.get.species() || {};
  const tagNumber = modalAnimals.map((animal) => {
    return animal.tagNumber;
  });

  const modal = {
    clearAll: () => {
      setModalAnimals([]);
      setTagLimitExeed(false);
    },

    removeAllInvalid: () => {
      setModalAnimals(helpers.animal.removeAllInvalid(modalAnimals));
      storeService.session.set.abattoirSelectedTagDetails([]);
    },

    removeAnimal: (animal) => {
      setModalAnimals((prevState) => {
        const newModalAnimals = helpers.tag.remove([...prevState], animal);
        if (newModalAnimals.length === 0) {
          storeService.session.set.abattoirSelectedTagDetails([]);
        } else {
          storeService.session.set.abattoirSelectedTagDetails(newModalAnimals);
        }
        return newModalAnimals;
      });

      setTimeout(() => {
        document.activeElement.blur();
      }, 1);
    }
  };

  const handleChanges = {
    upload: (uploadedTags) => {
      const newUploadedTags = [
        ...modalAnimals,
        ...uploadedTags.filter((el) => !modalAnimals.some((animal) => animal.tagNumber === el.tagNumber))
          .map((animal) => ({ ...animal, added: true }))
      ];
      setModalAnimals(newUploadedTags);
      storeService.session.set.abattoirSelectedTagDetails(newUploadedTags);
    },
    getAbattoirVetAttestationList: () => {
      storeService.session.remove.abattoirTagDetails();
      storeService.session.set.abattoirTagDetails({ species: species.name, deviceIdentifiers: tagNumber, prevPage: 'uploadTag' });
      history.push(Routing.abattoirAttestationTagResult);
    }
  };

  // Store selected tags in session and restore when page loads
  useEffect(() => {
    const selectedTags = storeService.session.get.abattoirSelectedTagDetails();
    if (selectedTags) {
      setModalAnimals(selectedTags);
    }
  }, []);

  // Enable Search button based on condition - Atleast one tag must be added and no invalid tag
  useEffect(() => {
    setTagLimitExeed(false);
    const isAnyInvalidTag = modalAnimals.some((item) => helpers.tag.isInvalid(item) && item.added);
    !(isAnyInvalidTag) && modalAnimals.length >= 1 && modalAnimals.length <= constants.abattoirTag.maxTagLimit ? setSearchDisabled(false) : setSearchDisabled(true);

    if (modalAnimals.length > constants.abattoirTag.maxTagLimit) {
      setTagLimitExeed(true);
    }
  }, [modalAnimals]);

  return (
    <>
      {ready &&
        <>
          <h1 className="h1">{t('abattoirAttestation.vetHeading')}</h1>
          <div>
            <Linker
              label="button.back"
              linkType="back"
              to={Routing.abattoirAttestation}
            />
          </div>
          <h3 className="h3">{t('abattoirAttestation.checkMultipleTagNumbers')}</h3>
          <div>
            <ModalFileUpload
              clearAll={modal.clearAll}
              isTagLimitExeed = {tagLimitExeed}
              maxTagsAllowed = {200}
              modalAnimals={modalAnimals}
              notes={t('abattoirAttestation.maxRecordAllowedNotes')}
              pending={pending}
              removeAllInvalid={modal.removeAllInvalid}
              removeAnimal={modal.removeAnimal}
              setModal={setModal}
              setModalAnimals={setModalAnimals}
              setPending={setPending}
              species={species}
              upload={handleChanges.upload}
              uploadInPopup={true}
            />
          </div>
          <div className={classes.searchButton}>
            <Button
              buttonType="primary"
              disabled={searchDisabled}
              id="searchButton"
              label="button.search"
              onClick={handleChanges.getAbattoirVetAttestationList}
            />
          </div>
        </>
      }
    </>
  );
};
AbattoirUploadTags.propTypes = {
  setModal: PropTypes.func.isRequired
};
export default AbattoirUploadTags;
