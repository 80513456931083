import React, { useEffect, useState } from 'react';
import classes from 'components/modalManualTagNumbers/modalManualTagNumbers.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import { tableColumns } from 'services/tableColumns';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import ManualTagEntry from 'components/manualTagEntry/manualTagEntry';
import Table from 'components/table/table';

const ModalManualTagNumbers = ({
  addAnimals,
  animalsToChange,
  clearAll,
  modalAnimals,
  pending,
  removeAllInvalid,
  removeAnimal,
  setModal,
  setModalAnimals,
  setPending,
  species
}) => {
  const [paginationParams, setPaginationParams] = useState(constants.pagination.defaultParams);

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      [constants.pagination.bottom]: {
        ...prevState[constants.pagination.bottom],
        total: modalAnimals?.length
      }
    }));
  }, [modalAnimals]);

  return (
    <div className={classes.content}>
      <div className={classes.input}>
        <ManualTagEntry
          addAnimals={addAnimals}
          animalsToChange={animalsToChange}
          id="manualTagEntry"
          pending={pending}
          setModal={setModal}
          setPending={setPending}
          species={species}
          title="movements.animal-selection.manual-tag-entry-heading"
          tooltip="tooltips.movements.manualTagEntry"
          type={constants.option.movement.newMovement}
        />
      </div>

      {modalAnimals && helpers.tag.hasNonEidTagsSheep(species.id, modalAnimals) &&
        <ErrorLabel
          isWarning={true}
          label="formatExamples.tagBatchNonEidError"
          modalMessage={helpers.tag.getFormatExamples(species.id).description}
          modalTitle={helpers.tag.getFormatExamples(species.id).title}
          setModal={setModal}
        />
      }

      {modalAnimals?.some((tag) => helpers.tag.isInvalid(tag)) &&
        <ErrorLabel
          label="formatExamples.tagBatchError"
          modalMessage={helpers.tag.getFormatExamples(species.id).description}
          modalTitle={helpers.tag.getFormatExamples(species.id).title}
          setModal={setModal}
        />
      }

      {modalAnimals?.length > 0 &&
        <div className={classes.output}>
          <div className={classes.tableContainer}>
            <Table
              clearAll={clearAll}
              columns={tableColumns.movements.modalManualTagNumbers({ actionFunction: removeAnimal, sortable: true })}
              data={modalAnimals}
              dataProvided={true}
              paginationParams={paginationParams}
              removeAllInvalid={removeAllInvalid}
              setData={setModalAnimals}
              setModal={setModal}
              title={{
                title: 'table.title.animalsEntered',
                data: [
                  modalAnimals?.length
                ]
              }}
            />
          </div>
        </div>
      }
    </div>
  );
};

ModalManualTagNumbers.propTypes = {
  addAnimals: PropTypes.func.isRequired,
  animalsToChange: PropTypes.array.isRequired,
  clearAll: PropTypes.func.isRequired,
  modalAnimals: PropTypes.array.isRequired,
  pending: PropTypes.bool.isRequired,
  removeAllInvalid: PropTypes.func.isRequired,
  removeAnimal: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setModalAnimals: PropTypes.func.isRequired,
  setPending: PropTypes.func.isRequired,
  species: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    notifications: PropTypes.number,
    errorFound: PropTypes.number
  })
};

export default ModalManualTagNumbers;
