import React, { useState, useEffect } from 'react';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';

const DropdownBatchNumbersAvailable = ({
  className,
  label,
  name,
  onChange,
  setModal,
  tooltip,
  validationPending,
  value
}) => {
  const [itemList, setItemList] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const source = axios.CancelToken.source();

    bff
      .get('/availableBatches', {
        cancelToken: source.token,
        params: {
          speciesId: storeService.session.get.species().id,
          cph: storeService.session.get.holding().value
        }
      })
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          setItemList((prevState) => {
            return helpers.sortArrayOfObjects([
              ...prevState,
              ...res.data.map((item) => ({
                label: item,
                name: item,
                value: item
              }))
            ], 'holdingRegisterId', constants.sorting.ascending);
          });

          if (res.data?.length === 1) {
            onChange({
              target: {
                name: name,
                type: 'select',
                value: res.data[0]
              }
            });
          }

          setPending(false);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setPending(false);
          errors.BFF(error, setModal);
        }
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownSearch
      className={className}
      disabled={pending || validationPending}
      hint={tooltip}
      id="tagNumberDropdown"
      isSearchEnabled={false}
      label={label}
      name={name}
      onChange={onChange}
      optionList={itemList}
      pending={pending}
      pendingError="error.noBirthTags"
      placeholder="prompt.pleaseSelect"
      value={value}
    />
  );
};

DropdownBatchNumbersAvailable.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  validationPending: PropTypes.bool,
  value: PropTypes.string
};

export default DropdownBatchNumbersAvailable;
