import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import constants from 'services/constants';
import storeService from 'services/storeService';
import PropTypes from 'prop-types';
import Routing from 'routing';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';
import Table from 'components/table/table';

const BoSearchRequestId = ({
  setModal
}) => {
  const { ready } = useTranslation();
  const history = useHistory();

  const sessionMovementSearch = storeService.session.get.searchResultsMovements()?.data;

  const [data, setData] = React.useState(sessionMovementSearch ? sessionMovementSearch : []);
  const [renderTable, setRenderTable] = React.useState(false);

  const paginationParams = constants.pagination.defaultParams;
  paginationParams[constants.pagination.bottom].total = data?.length;

  const tableParams = {
    request: {}
  };

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.boSearchRequestId.length) !== Routing.boSearchRequestId) {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
        unListen();
      }
    });
  }, [history]);

  return (
    <>
      {ready &&
        <>
          <PageTitle
            id="searchRequestIdTitle"
            pageTitleOne="boApp.pageTitles.search.search"
            pageTitleTwo="boApp.pageTitles.search.requestId"
          />
          <div className="pageContent">
            <SectionTitleBar
              icon="bi bi-search"
              id="searchRequestIdTitleBar"
              title="boApp.pageTitles.requestId.searchRequestId"
            />

            <Table
              data={data}
              dataProvided={true}
              filtersComponents={{
                [constants.filters.outside]: 'BoSearchRequestId'
              }}
              paginationParams={paginationParams}
              params={tableParams}
              renderTable={renderTable}
              setData={setData}
              setModal={setModal}
              setRenderTable={setRenderTable}
              store={{
                filters: 'RequestId'
              }}
            />
          </div>
        </>
      }
    </>
  );
};

BoSearchRequestId.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default BoSearchRequestId;
