import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/base/messageBanner/messageBanner.module.scss';
import PropTypes from 'prop-types';
import Legend from 'backOfficeComponents/base/legend/legend';

const MessageBanner = ({
  id,
  isWarning,
  messages
}) => {
  const { ready, t } = useTranslation();

  const componentClassNames = [
    classes.error,
    classes.message,
    isWarning ? classes.warning : ''
  ].join(' ').trim();

  return (
    <>
      {ready && messages && messages.length > 0 &&
        <span className={componentClassNames}>
          <Legend id={id} legend={isWarning ? t('warning.warning') : t('boApp.error.youHaveErrors')} />
          <ul>
            {messages.map((message, index) => (
              <li key={index}> {t(message)}</li>
            ))}
          </ul>
        </span>
      }
    </>
  );
};

MessageBanner.propTypes = {
  id: PropTypes.string.isRequired,
  isWarning: PropTypes.bool,
  messages: PropTypes.array
};

export default MessageBanner;
