import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/summary/fciSummary/fciSummary.module.scss';
import he from 'he';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Button from 'components/base/button/button';

const FciSummary = ({
  animalsData,
  collapsible,
  fciData,
  isActivityDetails,
  numberBeingMoved,
  onClick,
  open
}) => {
  const { ready, t } = useTranslation();

  const numberOfUnfitAnimals = animalsData?.animalsBeingMoved?.filter((item) => item.unfit).length || 0;

  const FCINotSatisfied = () => {
    return (
      <>
        {numberOfUnfitAnimals > 0 &&
          <p className="p">
            {numberOfUnfitAnimals} {t('label.of')} {numberBeingMoved} {t('label.animals')}
          </p>
        }
        {fciData.withdrawalPeriod &&
          <p className={classes.p}>
            {' '}
            {t('label.withdrawalPeriod')}:{' '}
            {fciData.withdrawalPeriod.name}
          </p>
        }
        {fciData.nonComplianceReason &&
          <p className={classes.p}>
            {' '}
            {t('label.reason')}: {fciData?.nonComplianceReason ? he.decode(fciData.nonComplianceReason) : ''}
          </p>
        }
        {fciData.holdingRestrictions &&
          <p className={classes.p}>
            {' '}
            {t('label.holdingRestrictions')}: {fciData?.holdingRestrictions ? he.decode(fciData.holdingRestrictions) : ''}
          </p>
        }
      </>
    );
  };

  const FCISatisfied = () => {
    return <p>{t('label.allAnimalsSatisfyFciStatements')}</p>;
  };

  const FCINotSupplied = () => {
    return <p>{t('label.noFciInformationRecorded')}</p>;
  };

  return (
    <>
      {ready &&
        <>
          {isActivityDetails &&
            <>
              <div className={classes.summaryRow}>
                <div className={classes.label}>{t('label.fciDetails')}</div>
                <div>
                  {(!fciData || !helpers.option.fci.doRecord(fciData.recordFCI)) &&
                    <FCINotSupplied />
                  }
                  {fciData && helpers.option.fci.doRecord(fciData.recordFCI) && (helpers.option.animals.doSatisfy(fciData.animalsSatisfy) || (animalsData && !animalsData.animalsBeingMoved)) &&
                    <FCISatisfied />
                  }
                  {fciData && helpers.option.fci.doRecord(fciData.recordFCI) && !helpers.option.animals.doSatisfy(fciData.animalsSatisfy) && animalsData && animalsData.animalsBeingMoved &&
                    <>
                      {(collapsible || collapsible === undefined) &&
                        <details className={classes.details} open={open ? 'open' : null}>
                          <summary className={classes.summary}>
                            {t('label.fciStatementsNotSatisfiedForAllAnimals')}
                          </summary>
                          <div className={classes.content}>
                            <FCINotSatisfied />
                          </div>
                        </details>
                      }
                      {!collapsible &&
                        <FCINotSatisfied />
                      }
                    </>
                  }
                </div>
              </div>
            </>
          }

          {!isActivityDetails &&
            <>
              <div className={classes.fciDetails}>
                <div className={classes.title}>
                  <h2>{t('label.fciDetails')}</h2>
                </div>
                {onClick &&
                  <div className={classes.actions}>
                    <Button
                      buttonType="link"
                      label="label.edit"
                      onClick={onClick}
                    />
                  </div>
                }
              </div>
              <div className={classes.fci}>
                {(!fciData || !helpers.option.fci.doRecord(fciData.recordFCI)) &&
                  <FCINotSupplied />
                }
                {fciData && helpers.option.fci.doRecord(fciData.recordFCI) && (helpers.option.animals.doSatisfy(fciData.animalsSatisfy) || (animalsData && !animalsData.animalsBeingMoved)) &&
                  <FCISatisfied />
                }
                {fciData && helpers.option.fci.doRecord(fciData.recordFCI) && !helpers.option.animals.doSatisfy(fciData.animalsSatisfy) && animalsData && animalsData.animalsBeingMoved &&
                  <>
                    <details className={classes.details} open={open ? 'open' : null}>
                      <summary className={classes.summary}>
                        {t('label.fciStatementsNotSatisfiedForAllAnimals')}
                      </summary>
                      <div className={classes.content}>
                        <FCINotSatisfied />
                      </div>
                    </details>
                  </>
                }
              </div>
            </>
          }
        </>
      }
    </>
  );
};

FciSummary.propTypes = {
  animalsData: PropTypes.object.isRequired,
  collapsible: PropTypes.bool,
  fciData: PropTypes.object,
  isActivityDetails: PropTypes.bool,
  numberBeingMoved: PropTypes.number.isRequired,
  onClick: PropTypes.func
};

export default FciSummary;
