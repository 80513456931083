import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/aboutPanel/aboutPanel.module.scss';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';

const AboutPanel = ({
  children,
  descriptions,
  keepOpen,
  linkText,
  linkUrl,
  onToggle,
  open,
  styleContent,
  title
}) => {
  const { ready, t } = useTranslation();

  const componentClassNames = [
    classes.content,
    styleContent === false ? classes.noSidebar : ''
  ].join(' ').trim();

  const getContentsLink = (text, onClick, href) => {
    return (
      <div>
        {helpers.text.getContentsLink(
          t,
          text,
          onClick,
          href
        )}
      </div>
    );
  };

  return (
    <>
      {ready &&
        <details className={classes.aboutPanel} onClick={(event) => keepOpen ? event.preventDefault() : false} onToggle={onToggle} open={open ? 'open' : null}>
          <summary className={classes.summary}>
            <span>{t(title)}</span>
          </summary>
          <div className={componentClassNames}>
            {descriptions?.filter((desc) => desc.text).map((desc) => (
              <div key={desc.text}>
                {getContentsLink(desc.text, desc.onClick, desc.href)}
              </div>
            ))}
            {linkText && linkUrl &&
              <a href={linkUrl}><h3>{t(linkText)}</h3></a>
            }
            {children}
          </div>
        </details>
      }
    </>
  );
};

AboutPanel.propTypes = {
  children: PropTypes.any,
  descriptions: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      href: PropTypes.string
    })
  ),
  keepOpen: PropTypes.bool,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  styleContent: PropTypes.bool,
  title: PropTypes.string.isRequired
};

export default AboutPanel;
