import React from 'react';
import classes from 'components/base/numberEntry/numberEntry.module.scss';
import PropTypes from 'prop-types';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import Hint from 'components/base/hint/hint';

const NumberEntry = ({
  disabled,
  error,
  hint,
  id,
  label,
  max,
  min,
  name,
  onBlur,
  onChange,
  reference,
  value,
  warning
}) => {
  const onKeyDownHandler = (event) => {
    const eventObject = window.event ? window.event : event;

    const isDigit = (
      eventObject.key === '0' || eventObject.key === '1' || eventObject.key === '2' ||
      eventObject.key === '3' || eventObject.key === '4' || eventObject.key === '5' ||
      eventObject.key === '6' || eventObject.key === '7' || eventObject.key === '8' ||
      eventObject.key === '9');

    if (!isDigit &&
      eventObject.keyCode !== 'v' && !eventObject.ctrlKey &&
      eventObject.key !== 'Tab' && eventObject.key !== 'Backspace' && eventObject.key !== 'Delete' &&
      eventObject.key !== 'ArrowLeft' && eventObject.key !== 'ArrowRight' && eventObject.key !== 'ArrowUp' && eventObject.key !== 'ArrowDown') {
      eventObject.preventDefault();
    }
  };

  const onChangeHandler = (event) => {
    const newEvent = {
      target: {
        name: name,
        type: 'select',
        value: event.target.value
      }
    };
    onChange(newEvent);
  };

  return (
    <>
      <FieldLabel
        label={label}
        name={id}
      />
      <Hint
        hint={hint}
      />
      <ErrorLabel
        label={error}
      />
      <ErrorLabel
        isWarning={true}
        label={warning}
      />
      <input
        className={classes.numberEntry}
        disabled={disabled}
        id={id}
        max={max}
        min={min}
        name={name}
        onBlur={onBlur}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        onWheel={() => document.activeElement.blur()}
        ref={reference}
        type="number"
        value={value ?? ''}
      />
    </>
  );
};

NumberEntry.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  min: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  reference: PropTypes.object,
  value: PropTypes.any,
  warning: PropTypes.string
};

export default NumberEntry;
