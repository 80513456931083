import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import classes from 'components/table/filters/filtersUserActivity/filtersUserActivity.module.scss';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import { tableColumns, initialSorting } from 'services/tableColumns';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';
import DateRange from 'components/base/dateRange/dateRange';

const UserActivityFilters = ({
  loadPending,
  setData,
  setModal,
  setRenderTable,
  setTableParams,
  storeFilters,
  tableParams
}) => {
  const { ready, t } = useTranslation();

  const sessionAnimalsAdded = storeService.session.get.animalsAdded();

  const now = helpers.date.now();
  const today = helpers.date.formatYYYYMMDD(now);
  const threeMonthsAgo = helpers.date.formatYYYYMMDD(helpers.date.subtractPeriod(now, 3, constants.period.months));

  const holdings = storeService.session.get.holdings();
  const hasKeeperHoldings = Object.values(holdings).some((holding) => holding.role === userRoleType.KEEPER);

  const getRequestTypeOptions = (animalsAdded) => {
    const initialRequestTypeOptions = [
      { label: t('label.movements'), name: t('label.movements'), value: constants.option.requestType.movement },
      { label: t('label.amendedMovements'), name: t('label.amendedMovements'), value: constants.option.requestType.correctTransfer },
      { label: t('label.reviewedNotifications'), name: t('label.reviewedNotifications'), value: constants.option.requestType.movementHandshake }
    ];

    if (hasKeeperHoldings) {
      initialRequestTypeOptions.push({ label: t('label.deaths'), name: t('label.deaths'), value: constants.option.requestType.death });
      initialRequestTypeOptions.push({ label: t('label.tagApplications'), name: t('label.tagApplications'), value: constants.option.requestType.tagApplication });
      initialRequestTypeOptions.push({ label: t('label.replacementTags'), name: t('label.replacementTags'), value: constants.option.requestType.tagReplacement });
      initialRequestTypeOptions.push({ label: t('label.birthsIndividualTagging'), name: t('label.birthsIndividualTagging'), value: constants.option.requestType.birth });

      if (animalsAdded) {
        initialRequestTypeOptions.push({ label: t('label.animalsAdded'), name: t('label.animalsAdded'), value: constants.option.requestType.animalsAdded });
      }
    }

    return initialRequestTypeOptions;
  };

  const [filterRequestType, setFilterRequestType] = React.useState(tableParams?.filters?.requestType);
  const [filterFromDate, setFilterFromDate] = React.useState(tableParams?.filters?.fromDate ? tableParams.filters.fromDate : threeMonthsAgo);
  const [filterToDate, setFilterToDate] = React.useState(tableParams?.filters?.toDate ? tableParams.filters.toDate : today);
  const [fromDateError, setFromDateError] = React.useState('');
  const [toDateError, setToDateError] = React.useState('');
  const [animalsAddedPending, setAnimalsAddedPending] = React.useState(true);
  const [requestTypeOptions, setRequestTypeOptions] = React.useState(getRequestTypeOptions(sessionAnimalsAdded));
  const [searchEnabled, setSearchEnabled] = React.useState(false);

  const handleChanges = {
    date: (which, value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      if (helpers.flags.isFrom(which)) {
        setFilterFromDate(value);

        if (value !== filterFromDate) {
          setRenderTable(false);
        }

        if (value?.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { fromDate, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { fromDate, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              fromDate: value
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            fromDate: value
          });
        }
      } else if (helpers.flags.isTo(which)) {
        setFilterToDate(value);

        if (value !== filterToDate) {
          setRenderTable(false);
        }

        if (value?.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { toDate, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { toDate, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              toDate: value
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            toDate: value
          });
        }
      }
    },

    requestType: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setFilterRequestType(value);

      if (value !== filterRequestType) {
        setRenderTable(false);
      }

      if (value?.length === 0) {
        setTableParams((prevState) => {
          // eslint-disable-next-line no-unused-vars
          const { requestType, sortBy, sortDirection, ...prevStateFilters } = prevState.filters;

          return {
            ...prevState,
            filters: prevStateFilters
          };
        });

        // eslint-disable-next-line no-unused-vars
        const { requestType, sortBy, sortDirection, ...prevStoredFilters } = storedFilters;

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...prevStoredFilters
        });
      } else {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            requestType: value
          }
        }));

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          requestType: value,
          sortBy: 'requestId',
          sortDirection: constants.sorting.descending
        });
      }
    }
  };

  const taggingEventProperties = (row) => {
    switch (filterRequestType) {
      case constants.option.requestType.birth:
        return {
          dateField: row.content.dateOfApplication,
          quantity: row.content.applicationItems?.length
        };

      case constants.option.requestType.tagApplication:
        return {
          dateField: row.content.dateOfApplication,
          quantity: row.content.devices?.length + row.content.batches.reduce((total, batch) => total + batch.animalTotal, 0)
        };

      case constants.option.requestType.tagReplacement:
        return {
          dateField: row.content.replacementDate,
          quantity: row.content.replaceItems?.length
        };

      default:
        return {};
    }
  };

  const getAnimalCount = (row) => {
    if (filterRequestType === constants.option.requestType.movementHandshake) {
      const numberOfAnimalsInBatches = row.content.reviewMovement.acceptedBatches?.length > 0 ? row.content.reviewMovement.acceptedBatches.reduce((total, row) => total + parseInt(row.animalTotal), 0) : 0;
      const numberOfIndividualAnimals = row.content.reviewMovement.acceptedDevices?.length;

      return numberOfAnimalsInBatches + numberOfIndividualAnimals;
    } else if (filterRequestType === constants.option.requestType.death) {
      const numberOfBatchAnimalTotal = row.content.batchDetails.reduce((total, item) => {
        return total + item.animalTotal;
      }, 0);

      return numberOfBatchAnimalTotal + row.content.killedDevices?.length;
    }

    return null;
  };

  const runRequest = () => {
    if (!filterFromDate) {
      setFilterFromDate(threeMonthsAgo);
    }
    if (!filterToDate) {
      setFilterToDate(today);
    }

    storeService.session.set[`tableFilters${storeFilters}PageIndex`](0);
    storeService.session.remove[`tableFilters${storeFilters}SortBy`]();
    storeService.session.removeAll.searchResults();
    setData([]);

    setTableParams((prevState) => ({
      ...prevState,
      columns: tableColumns.userActivity[filterRequestType],
      filters: {
        requestType: filterRequestType,
        fromDate: filterFromDate,
        toDate: filterToDate
      },
      page: {
        ...prevState.page,
        index: 0
      },
      request: {
        ...prevState.request,
        params: {
          ...prevState.request.params,
          requestType: filterRequestType,
          fromDate: filterFromDate,
          toDate: filterToDate
        },
        processData: (data) => data.map((row) => ({
          ...row,
          requestType: filterRequestType,
          movementDocumentRef: filterRequestType === constants.option.requestType.movement ? row.content.movementDocument.movementDocumentRef : row.content.documentRef,
          status: row.isFullUndone ? constants.status.cancelled : row.requestStatus,
          taggingDate: taggingEventProperties(row).dateField,
          taggingQuantity: taggingEventProperties(row).quantity,
          notificationQuantity: getAnimalCount(row),
          deathQuantity: getAnimalCount(row)
        }))
      },
      sort: {
        by: initialSorting.userActivity[filterRequestType].accessor,
        direction: initialSorting.userActivity[filterRequestType].direction
      }
    }));
    setRenderTable(true);
  };

  useEffect(() => {
    if (loadPending || animalsAddedPending || !filterRequestType || fromDateError !== '' || toDateError !== '') {
      setSearchEnabled(false);
    } else {
      setSearchEnabled(true);
    }
  }, [
    loadPending,
    animalsAddedPending,
    filterRequestType,
    fromDateError,
    toDateError
  ]);

  useEffect(() => {
    if (filterRequestType) {
      setTableParams((prevState) => ({
        ...prevState,
        columns: tableColumns.userActivity[filterRequestType],
        request: {
          ...prevState.request,
          params: {
            ...prevState.request.params,
            requestType: filterRequestType,
            fromDate: filterFromDate,
            toDate: filterToDate
          },
          processData: (data) => data.map((row) => ({
            ...row,
            requestType: filterRequestType,
            movementDocumentRef: filterRequestType === constants.option.requestType.movement ? row.content.movementDocument.movementDocumentRef : row.content.documentRef,
            status: row.isFullUndone ? constants.status.cancelled : row.requestStatus,
            taggingDate: taggingEventProperties(row).dateField,
            taggingQuantity: taggingEventProperties(row).quantity,
            notificationQuantity: getAnimalCount(row),
            deathQuantity: getAnimalCount(row)
          }))
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (sessionAnimalsAdded) {
      setAnimalsAddedPending(false);
    } else {
      bff
        .get('/userActivity', {
          cancelToken: source.token,
          params: {
            requestType: constants.option.requestType.animalsAdded,
            includeUndo: false,
            countOnly: true
          }
        })
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal, setAnimalsAddedPending)) {
            setAnimalsAddedPending(false);
            setRequestTypeOptions(getRequestTypeOptions(res.data && res.data.count > 0));
            storeService.session.set.animalsAdded(res.data && res.data.count > 0);
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setAnimalsAddedPending(false);
            errors.BFF(error, setModal);
          }
        });
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <div className={classes.filters}>
          <div className={classes.requestType}>
            <DropdownSearch
              disabled={loadPending || animalsAddedPending}
              id="requestTypeDropdown"
              label="label.eventType"
              name="requestType"
              onChange={(event) => handleChanges.requestType(event.target.value)}
              optionList={requestTypeOptions}
              placeholder="prompt.pleaseSelect"
              value={filterRequestType}
            />
          </div>
          <DateRange
            disabled={loadPending || animalsAddedPending}
            fromDate={filterFromDate}
            fromDateError={fromDateError}
            inline={true}
            loadPending={loadPending}
            setFromDate={(date) => handleChanges.date(constants.flags.from, date)}
            setFromDateError={setFromDateError}
            setToDate={(date) => handleChanges.date(constants.flags.to, date)}
            setToDateError={setToDateError}
            submit={runRequest}
            submitDisabled={!searchEnabled}
            submitLabel="button.search"
            toDate={filterToDate}
            toDateError={toDateError}
          />
        </div>
      }
    </>
  );
};

UserActivityFilters.propTypes = {
  loadPending: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setRenderTable: PropTypes.func.isRequired,
  setTableParams: PropTypes.func.isRequired,
  storeFilters: PropTypes.string.isRequired,
  tableParams: PropTypes.object.isRequired
};

export default UserActivityFilters;
