import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/summary/dateSummary/dateSummary.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from 'components/base/button/button';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import InputDate from 'components/base/inputDate/inputDate';

const DateSummary = ({
  confirmMode,
  date1,
  date2,
  date2Error,
  label,
  maxDate,
  onClick,
  restrictArrivalDate,
  setDate2,
  setDate2Error,
  showBothDates
}) => {
  const { ready, t } = useTranslation();

  let dateString;
  if (date1 && date1.entryDay && date1.entryMonth && date1.entryYear) {
    dateString = moment().year(date1.entryYear).month(date1.entryMonth - 1).date(date1.entryDay);
  } else if (date1) {
    dateString = date1;
  }

  const validate = {
    arrivalDate: (date) => {
      if (date) {
        setDate2Error('');
        const error = helpers.date.is.sameDayOrNextDay(date, date1)
          ? ''
          : 'error.arrivalDateInTheFuture';
        setDate2Error(error ? error : '');
      }
    }
  };

  const getDates = {
    arrivalDateMin: () => {
      return helpers.date.formatYYYYMMDD(date1);
    },

    arrivalDateMax: () => {
      if (restrictArrivalDate) {
        return helpers.date.formatYYYYMMDD(
          helpers.date.momentToJSDate(
            helpers.date.momentMinDate(
              helpers.date.addPeriod(date1, 1, constants.period.days),
              helpers.date.todayEndOfDay()
            )
          )
        );
      }

      return helpers.date.formatYYYYMMDD(new Date());
    }
  };

  const handleChanges = {
    arrivalDate: (date) => {
      setDate2(date);
      validate.arrivalDate(date);
    }
  };

  return (
    <>
      {ready &&
        <div className={classes.summaryRow}>
          <div className={classes.label}>{t(label)}</div>
          <div className={classes.value}>
            <div className={classes.date}>
              {(showBothDates || date2 || confirmMode) &&
                <span className={classes.title}>{t('label.departureDate')}</span>
              }
              {helpers.date.format(dateString)}
            </div>
            {(showBothDates || date2 || confirmMode) &&
              <div className={classes.date}>
                <span className={classes.title}>{t('label.arrivalDate')}</span>
                {confirmMode &&
                  <>
                    <InputDate
                      blockStyling={true}
                      dontValidate={true}
                      error={date2Error}
                      id="arrivalDate"
                      maxDate={maxDate ?? getDates.arrivalDateMax()}
                      minDate={getDates.arrivalDateMin()}
                      onChange={(event) => handleChanges.arrivalDate(event.target.value)}
                      setError={setDate2Error}
                      value={date2}
                      yearLength={2}
                    />

                    {!date2 &&
                      <ErrorLabel
                        label="label.pleaseEnterArrivalDate"
                      />
                    }
                  </>
                }
                {!confirmMode && date2 && helpers.date.format(date2)}
                {!confirmMode && !date2 && <>-</>}
              </div>
            }
          </div>
          {onClick &&
            <div className={classes.action}>
              <Button
                buttonType="link"
                label="label.edit"
                onClick={onClick}
              />
            </div>
          }
        </div>
      }
    </>
  );
};

DateSummary.propTypes = {
  confirmMode: PropTypes.bool,
  date1: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  date2: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  date2Error: PropTypes.string,
  label: PropTypes.string,
  maxDate: PropTypes.string,
  onClick: PropTypes.func,
  restrictArrivalDate: PropTypes.bool,
  setDate2: PropTypes.func,
  setDate2Error: PropTypes.func,
  showBothDates: PropTypes.bool
};

export default DateSummary;
