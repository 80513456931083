import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/base/button/button';
import constants from 'services/constants';
import classes from 'components/summary/transportSummary/transportSummary.module.scss';
import PropTypes from 'prop-types';

const TransportSummary = ({
  label,
  onClick,
  transportInformation
}) => {
  const { ready, t } = useTranslation();

  const transportedByLabel = transportInformation ? ('radioButtons.transportedBy.' + transportInformation.transportedBy) : '';

  return (
    <>
      {ready &&
        <div className={classes.summaryRow}>
          <div className={classes.label}>{t(label)}</div>
          <div className={classes.value}>
            {(!transportInformation || !transportInformation.recordTransportInformation || transportInformation.recordTransportInformation === constants.option.recordTransportInformation.no) &&
              t('movements.transport-information.noInformationRecorded')
            }
            {transportInformation?.recordTransportInformation && transportInformation.recordTransportInformation !== constants.option.recordTransportInformation.no &&
              <>
                <p className={classes.p}>
                  {t('movements.transport-information.transport-details.radios-title')}:{' '}
                  {t(transportedByLabel)}
                  {transportInformation.vehicleRegistrationNumber &&
                    <>
                      {' '}- {transportInformation.vehicleRegistrationNumber}
                    </>
                  }
                </p>
                {transportInformation.contactPhone &&
                  <p className={classes.p}>
                    {t('movements.transport-information.transport-details.contactTelephoneNumber')}:{' '}
                    {transportInformation.contactPhone}
                  </p>
                }
                {transportInformation.durationOfJourney && (transportInformation.durationOfJourney.days > 0 || transportInformation.durationOfJourney.hours > 0 || transportInformation.durationOfJourney.minutes > 0) &&
                  <p className={classes.p}>
                    {t('movements.transport-information.transport-details.durationOfJourney')}:{' '}
                    {transportInformation.durationOfJourney.days === 1 &&
                      <>
                        {transportInformation.durationOfJourney.days}{' '}
                        {t('label.day_lower')}{' '}
                      </>
                    }
                    {transportInformation.durationOfJourney.days > 1 &&
                      <>
                        {transportInformation.durationOfJourney.days}{' '}
                        {t('label.days_lower')}{' '}
                      </>
                    }
                    {transportInformation.durationOfJourney.hours ? transportInformation.durationOfJourney.hours : 0}{' '}
                    {t('movements.transport-information.transport-details.hours').toLowerCase()}{' '}

                    {transportInformation.durationOfJourney.minutes ? transportInformation.durationOfJourney.minutes : 0}{' '}
                    {t('movements.transport-information.transport-details.minutes').toLowerCase()}
                  </p>
                }
              </>
            }
          </div>
          {onClick &&
            <div className={classes.action}>
              <Button
                buttonType="link"
                label="label.edit"
                onClick={onClick}
              />
            </div>
          }
        </div>
      }
    </>
  );
};

TransportSummary.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  transportInformation: PropTypes.object
};

export default TransportSummary;
