import axios from 'axios';
import bff from 'services/bff';

const queryList = [
  'cph',
  'fsa',
  'keeperName',
  'keeperTelephone',
  'keeperEmail',
  'holdingName',
  'holdingType',
  'holdingAddress',
  'holdingPostcode',
  'holdingFlock'
];

const searchHelper = {
  searchCPH: (searchQuery, setSearched, setSearchNoResults) => {
    const source = axios.CancelToken.source();
    setSearched(true);
    setSearchNoResults(false);

    // Passing all params through to BFF search
    const params = {};
    queryList.forEach((queryItem) => {
      if (searchQuery[queryItem]) {
        params[queryItem] = searchQuery[queryItem];
      }
    });

    const searchByCPH = searchQuery.cph || searchQuery.fsa;
    const api = searchByCPH ? '/holdingDetails' : '/searchHolding';

    return bff
      .get(api, {
        cancelToken: source.token,
        params
      });
  }
};
export default searchHelper;
