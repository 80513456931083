import React from 'react';
import { holdingHelper } from 'services/holdingHelper';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';

const DropdownHoldings = ({
  error,
  label,
  name,
  onChange
}) => {
  const currentHolding = storeService.session.get.holding();
  const allHoldings = storeService.session.get.holdings();

  const otherHoldings = () => {
    return Object.values(allHoldings)
      .filter((holding) => holdingHelper.region.isEngland(holding.region))
      .filter((holding) => holding.value !== currentHolding.value)
      .map((holding) => ({
        label: holding.value,
        name: holding.value,
        value: holding.value
      }));
  };

  return (
    <DropdownSearch
      error={error}
      id="holdingsDropdown"
      label={label}
      name={name}
      onChange={onChange}
      optionList={otherHoldings()}
      placeholder="prompt.pleaseSelect"
    />
  );
};

DropdownHoldings.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func
};

export default DropdownHoldings;
