import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/summary/dateSummary/dateSummary.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Button from 'backOfficeComponents/base/button/button';
import InputDate from 'components/base/inputDate/inputDate';
import Legend from 'backOfficeComponents/base/legend/legend';

const DateSummary = ({
  arrivalDate,
  arrivalDateError,
  confirmMode,
  departureDate,
  label,
  onClick,
  setArrivalDate,
  setArrivalDateError
}) => {
  const { ready, t } = useTranslation();

  const validate = {
    arrivalDate: (date) => {
      if (date) {
        const error = helpers.date.is.sameDayOrNextDay(date, departureDate)
          ? ''
          : 'error.arrivalDateInTheFuture';
        setArrivalDateError(error ? error : '');
      }
    }
  };

  const getDates = {
    arrivalDateMin: () => {
      return helpers.date.formatYYYYMMDD(departureDate);
    },

    arrivalDateMax: () => {
      return helpers.date.formatYYYYMMDD(
        helpers.date.momentToJSDate(
          helpers.date.momentMinDate(
            helpers.date.addPeriod(departureDate, 1, constants.period.days),
            helpers.date.todayEndOfDay()
          )
        )
      );
    }
  };

  const handleChanges = {
    arrivalDate: (date) => {
      setArrivalDate(date);
      validate.arrivalDate(date);
    }
  };

  React.useEffect(() => {
    if (confirmMode) {
      handleChanges.arrivalDate(arrivalDate ? arrivalDate : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmMode]);

  return (
    <>
      {ready &&
        <div className={classes.summaryRow}>
          <div className={classes.label}>{t(label)}</div>
          <div className={classes.dates}>
            <div className={classes.date}>
              <Legend id="departureDateLabel" legend="label.departureDate" />
              {helpers.date.format(departureDate)}
            </div>

            {(arrivalDate || confirmMode) &&
              <div className={classes.date}>
                <Legend id="arrivalDateLabel" legend="label.arrivalDate" />
                {confirmMode &&
                  <InputDate
                    blockStyling={true}
                    dontValidate={true}
                    error={arrivalDateError}
                    id="dateFrom"
                    maxDate={getDates.arrivalDateMax()}
                    minDate={getDates.arrivalDateMin()}
                    onChange={(event) => handleChanges.arrivalDate(event.target.value)}
                    setError={setArrivalDateError}
                    value={arrivalDate ? helpers.date.formatYYYYMMDD(arrivalDate) : ''}
                    yearLength={2}
                  />
                }
                {!confirmMode && arrivalDate && helpers.date.format(arrivalDate)}
              </div>
            }
          </div>
          {onClick &&
            <div className={classes.action}>
              <Button
                buttonType="link"
                id="dateSummaryButton"
                label="label.edit"
                onClick={onClick}
              />
            </div>
          }
        </div>
      }
    </>
  );
};

DateSummary.propTypes = {
  arrivalDate: PropTypes.string,
  arrivalDateError: PropTypes.string,
  confirmMode: PropTypes.bool,
  departureDate: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  setArrivalDate: PropTypes.func,
  setArrivalDateError: PropTypes.func
};

export default DateSummary;
