import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CattleIcon } from 'images/svg/icon-cattle.svg';
import { ReactComponent as SheepIcon } from 'images/svg/icon-sheep.svg';
import { ReactComponent as GoatIcon } from 'images/svg/icon-goat.svg';
import { ReactComponent as PigIcon } from 'images/svg/icon-pig.svg';
import { ReactComponent as DeerIcon } from 'images/svg/icon-deer.svg';
import helpers from 'services/helpers';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/base/pageTitle/pageTitle.module.scss';

const PageTitle = ({
  id,
  pageTitleOne,
  pageTitleTwo,
  speciesId
}) => {
  const { ready, t } = useTranslation();
  return (
    <>
      {ready &&
        <div className={classes.pageTitle}>
          <h1 className={classes.h1}>
            <span className={classes.icon}>
              {helpers.species.isCattleId(speciesId) &&
                <CattleIcon className={classes.icon} />
              }
              {helpers.species.isSheepId(speciesId) &&
                <SheepIcon className={classes.icon} />
              }
              {helpers.species.isGoatId(speciesId) &&
                <GoatIcon className={classes.icon} />
              }
              {helpers.species.isPigId(speciesId) &&
                <PigIcon className={classes.icon} />
              }
              {helpers.species.isDeerId(speciesId) &&
                <DeerIcon className={classes.icon} />
              }
            </span>
            <span className={classes.pageTitleOne} id={id + 'One'}>{t(pageTitleOne)}</span>
            {pageTitleTwo &&
              <>
                <i className={'bi bi-arrow-right-short ' + classes.arrow} />
                <span className={classes.pageTitleTwo} id={id + 'Two'}>{t(pageTitleTwo)}</span>
              </>
            }
          </h1>
        </div>
      }
    </>
  );
};

PageTitle.propTypes = {
  id: PropTypes.string.isRequired,
  pageTitleOne: PropTypes.string.isRequired,
  pageTitleTwo: PropTypes.string
};

export default PageTitle;
