import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { isCancel } from 'services/bff';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import VerifyCustomerSearch from 'backOfficeComponents/sections/verifyCustomerSearch/verifyCustomerSearch';
import VerifyCustomerResults from 'backOfficeComponents/sections/verifyCustomerResults/verifyCustomerResults';

const initialSearchInputs = {
  keeperName: '',
  keeperTelephone: '',
  keeperEmail: '',
  holdingCPH: '',
  holdingName: '',
  holdingAddress: '',
  holdingPostcode: '',
  holdingFlock: ''
};

const matchIgnoredError = (error) => {
  const matchedError = [
    'Error: Request failed with status code 400',
    'Error code: 404 - Not Found'
  ].map((errorMessage) => error.includes(errorMessage));

  return matchedError?.length > 0;
};

const hasOnlyCPHData = (data) => data?.holdingCPH
  ? Object.entries(data).filter((item) => item[0] !== 'holdingCPH' && item[1] !== '').length === 0
  : false;

const canSearchKeeperDetail = (data) => data?.id && (data?.propertyName || data?.postCode);

const validate = {
  keeperNameExists: (input) => input.keeperName !== '',

  holdingCPHExists: (input) => input.holdingCPH !== '',

  postCodeIsValid: (input) => input.holdingPostcode?.length >= 3,

  otherHoldingDetailsExists: (input) => (input.holdingName + input.holdingAddress + input.holdingFlock) !== ''
};

const mapping = {
  holdingInfo: (holding, holdingTypeName) => ({
    cph: helpers.replaceNull(holding.id),
    holdingName: helpers.replaceNull(holding.propertyName),
    holdingAddress: [holding.address1, holding.address2, holding.town, holding.county].filter(Boolean).join(', '),
    holdingPostcode: helpers.replaceNull(holding.postCode),
    holdingTypeFullName: holding.holdingType ? helpers.replaceNull(holding.holdingType?.fullName) : holdingTypeName
  }),

  keeperDetail: (keeper) => ({
    keeperName: [keeper.title, keeper.firstName, keeper.lastName].filter(Boolean).join(' '),
    keeperEmail: helpers.replaceNull(keeper.email)
  }),

  keepersDetail: (holdings, holdingTypeName) => holdings?.map((holding) => ({
    ...holding,
    ...mapping.holdingInfo(holding, holdingTypeName),
    ...mapping.keeperDetail(holding)
  }))
};

const BoVerifyCustomer = ({
  setModal
}) => {
  const { ready } = useTranslation();

  const [search, setSearch] = React.useState(false);
  const [searchInputs, setSearchInputs] = React.useState(initialSearchInputs);
  const [enabledSearch, setEnabledSearch] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [searchPending, setSearchPending] = React.useState(false);
  const [searched, setSearched] = React.useState(false);
  const [error, setError] = React.useState([]);

  const handleChanges = {
    onChange: (e) => {
      const updatedInputs = {
        ...searchInputs,
        [e.target.name]: e.target.value
      };
      setSearchInputs(updatedInputs);

      let isValidCHP = true;
      if (validate.holdingCPHExists(updatedInputs)) {
        isValidCHP = helpers.validate.cphNumber(updatedInputs.holdingCPH);
      }
      setError([]);
      setEnabledSearch(isValidCHP &&
        (
          validate.keeperNameExists(updatedInputs) ||
          validate.postCodeIsValid(updatedInputs) ||
          validate.otherHoldingDetailsExists(updatedInputs) ||
          (
            validate.holdingCPHExists(updatedInputs) &&
            isValidCHP
          )
        )
      );
    },

    onSearch: () => {
      const errorArray = [];

      if (!validate.keeperNameExists(searchInputs) &&
        !validate.postCodeIsValid(searchInputs) &&
        !validate.otherHoldingDetailsExists(searchInputs) &&
        !hasOnlyCPHData(searchInputs)
      ) {
        errorArray.push('boApp.error.searchCriteriaRequired');
      }
      setError(errorArray);
      setSearch(errorArray.length === 0);
    },

    onClear: () => {
      setSearchInputs(initialSearchInputs);
      setData([]);
      setError([]);
    }
  };

  const completedSearchResponse = () => {
    setSearchPending(false);
    setSearched(true);
    setSearch(false);
  };

  React.useEffect(() => {
    const source1 = axios.CancelToken.source();
    const source2 = axios.CancelToken.source();

    const fetchKeeperDetail = (input, hasOnlyCPHData) => {
      const holding = {};
      for (const property in input) {
        holding[property] = input[property]?.trim();
      }
      helpers.get.searchHoldingKeeper(source2.token,
        {
          cph: holding.cph || holding.holdingCPH,
          keeperLastName: holding.keeperName,
          keeperTelephone: holding.keeperTelephone,
          keeperEmail: holding.keeperEmail,
          holdingName: holding.holdingName,
          holdingAddress: holding.holdingAddress,
          holdingPostcode: holding.holdingPostcode,
          holdingFlock: holding.holdingFlock,
          normal: true
        }
      )
        .then(({ data }) => {
          if (helpers.response.isValid(data, setModal, setSearchPending)) {
            if (data?.data?.length > 0) {
              setData(mapping.keepersDetail(data.data, null));
            } else if (hasOnlyCPHData) {
              setData([input]);
            }
            completedSearchResponse();
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            if (!matchIgnoredError(error.toString())) {
              errors.BFF(error, setModal);
            }
            completedSearchResponse();
          }
        });
    };

    const handleResponseWithHolding = (holding) => {
      if (holding && helpers.response.isValid(holding, setModal, setSearchPending)) {
        if (canSearchKeeperDetail(holding)) {
          fetchKeeperDetail({
            cph: holding.id,
            holdingName: holding.propertyName,
            holdingPostcode: holding.postCode
          }, true);
        } else {
          setData([mapping.holdingInfo(holding, '')]);
          completedSearchResponse();
        }
      } else {
        setData([]);
        completedSearchResponse();
      }
    };

    if (search) {
      setData([]);
      setSearchPending(true);

      if (hasOnlyCPHData(searchInputs)) {
        helpers.get.holdingDetails({ cancelToken: source1.token, cph: searchInputs.holdingCPH, queryKeeper: true })
          .then(({ data }) => {
            handleResponseWithHolding(data.data[0]);
          })
          .catch((error) => {
            if (!isCancel(error)) {
              if (!matchIgnoredError(error.toString())) {
                errors.BFF(error, setModal);
              }
              completedSearchResponse();
            }
          });
      } else {
        fetchKeeperDetail(searchInputs, false);
      }
    }

    return () => {
      source1.cancel();
      source2.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    helpers.scrollToTop(document.getElementById('appContent'));
  }, []);

  return (
    <>
      {ready &&
        <>
          <PageTitle
            id="verifyCustomerTitle"
            pageTitleOne="boApp.pageTitles.customers.customers"
            pageTitleTwo="boApp.pageTitles.customers.verifyCustomer"
          />

          <VerifyCustomerSearch
            data={searchInputs}
            enabledSearch={enabledSearch}
            error={error}
            onClear={handleChanges.onClear}
            onSearch={handleChanges.onSearch}
            pending={searchPending}
            setData={handleChanges.onChange}
          />

          <VerifyCustomerResults
            data={data}
            searched={searched}
            searchPending={searchPending}
            setData={setData}
            setModal={setModal}
          />
        </>
      }
    </>
  );
};

BoVerifyCustomer.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default BoVerifyCustomer;
