/**
 *
 * Implementing a service allowing the currently configured feature list to be accessed.
 * The feature toggles will be pulled from the BFF and can then be checked on the string name.
 *
 **/

import appInsightsService from 'services/appInsightsService';
import bff, { isCancel } from 'services/bff';
import helpers from 'services/helpers';
import storeService from 'services/storeService';

const featureToggles = {
  init: (setModal) => {
    bff
      .get('/featureList')
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          storeService.session.set.features(res.data);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          appInsightsService.trackException(error);
          storeService.session.set.features({});
        }
      });
  },

  isEnabled: (featureName) => {
    const allFeatures = storeService.session.get.features();

    if (allFeatures) {
      return allFeatures[featureName];
    }

    return false;
  },

  toggles: {
    accessibilityTooltipsKeyboardFocus: 'accessibility tooltips keyboard focus',
    version_1_0: 'version 1.0',
    version_1_1: 'version 1.1'
  }
};

export default featureToggles;
