import React from 'react';
import breedsHelper from 'services/breedsHelper';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import he from 'he';
import moment from 'moment';
import navigation from 'services/navigation';
import Routing from 'routing';
import storeService from 'services/storeService';
import Button from 'components/base/button/button';
import CheckBox from 'components/base/checkbox/checkbox';
import Linker from 'components/base/linker/linker';
import PDFButton from 'components/pdfButton/pdfButton';

const columns = {
  manualTagDetails: {
    tagNumber: {
      header: 'Tag number',
      accessor: 'tagNumber',
      sortable: true
    },
    attestationCph: {
      header: 'Attestation CPH',
      accessor: 'setPic',
      sortable: false
    },
    status: {
      header: 'Status',
      accessor: 'programStatusCode',
      render: ({ value }) => {
        if (value === 'ATTESTED') {
          return ('Valid');
        }
        return ('Invalid');
      }
    },
    record: {
      header: 'Record',
      accessor: 'id',
      render: ({ row }) => {
        if (row.setPic !== '') {
          if (helpers.tag.canLink(row.id)) {
            return (
              <Linker
                label={'View'}
                to={Routing.abattoirAttestationDeclarationTags + row.id}
              />
            );
          }
        }
        return ('');
      }
    }
  },

  vetAttestationData: {
    id: {
      header: 'Reference number',
      accessor: 'id'
    },
    vetAttestationStatus: {
      header: 'Veterinary attestation status',
      accessor: 'vetAttestationStatus'
    },
    viewLink: {
      header: 'Record',
      accessor: 'id',
      render: ({ value }) => {
        if (helpers.tag.canLink(value)) {
          return (
            <Linker
              label={'View'}
              to={Routing.abattoirAttestationDeclaration + value}
            />
          );
        }
      }
    }
  },
  animalDetails: {
    breed: {
      accessor: 'breedName',
      header: 'label.breed'
    },

    dob: {
      accessor: 'dob',
      classNames: ['date'],
      header: 'label.dateBirth',
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    dateOfMovement: {
      accessor: 'dom',
      classNames: ['date'],
      header: 'label.dateMovement',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    dateOfTagging: (params) => ({
      accessor: 'replacementDate',
      classNames: ['date'],
      header: <span dangerouslySetInnerHTML={{ __html: params.t('label.dateOfInitialTagging') }} />,
      sortable: true,
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    }),

    departure: {
      accessor: 'from',
      header: 'label.departureHoldingFrom',
      sortable: true
    },

    destination: {
      accessor: 'to',
      header: 'label.destinationHoldingTo',
      sortable: true
    },

    gender: {
      accessor: 'genderName',
      classNames: ['gender'],
      header: 'label.gender',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    },

    initialTagNumber: {
      accessor: 'replacedIdentifier',
      header: 'label.initialTagNumber',
      sortable: true
    },

    // location: {
    //   accessor: 'location',
    //   header: 'label.location',
    //   render: ({ t, value }) => {
    //     return (
    //       <>{value === 'Unknown' ? t('label.unknown') : value}</>
    //     );
    //   }
    // },

    movementReference: {
      accessor: 'movementDocumentRef',
      header: 'label.movementRef',
      sortable: true
    },

    replacementTagNumber: {
      accessor: 'replacingIdentifier',
      header: 'label.replacementTagNumber',
      sortable: true
    },

    requestId: {
      accessor: 'requestId',
      classNames: ['hide-m', 'align-right', 'request-id'],
      header: 'label.requestId'
    },

    taggingReference: {
      accessor: 'requestId',
      classNames: ['hide-m', 'align-right', 'request-id'],
      header: 'label.reference',
      sortable: true,
      render: ({ t, value }) => {
        return (
          <>{value ? value : t('label.na')}</>
        );
      }
    },

    tagNumber: (params) => ({
      accessor: 'tagNumber',
      header: 'label.tagNumber',
      render: ({ value }) => {
        if (helpers.tag.canLink(value)) {
          return (
            <Linker
              label={value}
              onClick={() => params.setShowProgeny(false)}
              to={Routing.animals + value}
            />
          );
        }

        return (
          <>{value}</>
        );
      }
    })
  },

  animalsOnHolding: {
    action: (params) => ({
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        return (
          <Button
            buttonType="remove"
            id={'remove-' + row.index + '-' + row.tagNumber.trim().replaceAll(' ', '')}
            label="label.remove"
            onClick={() => params.removeAnimal(row)}
          />
        );
      }
    }),

    age: (params) => ({
      accessor: 'ageToDisplay',
      header: 'label.age',
      sortable: true,
      sortColumn: params.ageSortColumn ? params.ageSortColumn : 'endBirthPeriod',
      sortReverse: true,
      render: ({ t, value }) => {
        return (
          <>{value === 0 ? t('label.ageOneYear.under') : value === '-' ? value : value >= 1 ? value + t('label.ageOneYear.above') : value}</>
        );
      }
    }),

    breed: (params) => ({
      accessor: 'breedName',
      header: 'label.breed',
      sortable: params.sortable,
      render: ({ t, value }) => {
        return (
          <>{value ? he.decode(value) : t('label.unknown')}</>
        );
      }
    }),

    dob: (params) => ({
      accessor: 'dobToDisplay',
      header: 'label.yearDob',
      sortable: true,
      sortColumn: params.dobSortColumn ? params.dobSortColumn : 'beginBirthPeriod',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    }),

    gender: (params) => ({
      accessor: 'genderName',
      classNames: ['gender'],
      header: 'label.gender',
      sortable: params.sortable,
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    }),

    status: {
      classNames: ['status', 'status-wide'],
      header: 'label.status',
      render: ({ row, t }) => {
        if (row.duplicates.length > 0) {
          return (
            <>{t('label.duplicate')}</>
          );
        }

        if (helpers.tag.isInvalidExtended(row)) {
          return (
            <>{t('label.invalid' + helpers.tag.getInvalidField(row))}</>
          );
        }

        if (helpers.tag.isNonEid(row)) {
          return (
            <>{t('label.nonEid')}</>
          );
        }

        return '';
      }
    },

    tagNumber: {
      accessor: 'tagNumber',
      header: 'label.tagNumber',
      sortable: true
    }
  },

  animalSummary: {
    age: {
      accessor: 'ageToDisplay',
      classNames: ['hide-l', 'age'],
      header: 'label.age',
      sortable: true,
      render: ({ t, value }) => {
        return (
          <>{value === 0 ? t('label.ageOneYear.under') : value === '-' ? value : value >= 1 ? value + t('label.ageOneYear.above') : value}</>
        );
      }
    },

    batchNumber: (params) => ({
      accessor: 'batchNumber',
      header: 'label.batchNumber' + params.speciesId,
      sortable: true,
      render: ({ t, value }) => {
        return (
          <>{value ? value : t('label.notSupplied')}</>
        );
      }
    }),

    batchNumberNew: (params) => ({
      accessor: 'newBatchNumber',
      header: 'label.newBatchNumber' + params.speciesId,
      sortable: true
    }),

    batchNumberOld: (params) => ({
      accessor: 'oldBatchNumber',
      header: 'label.oldBatchNumber' + params.speciesId,
      sortable: true
    }),

    breed: {
      accessor: 'breedName',
      classNames: ['hide-m'],
      header: 'label.breed',
      sortable: true,
      render: ({ row, t, value }) => {
        return (
          <>{value
            ? he.decode(value)
            : (row.breed
              ? he.decode(row.breed)
              : t('label.unknown')
            )
          }</>
        );
      }
    },

    damId: {
      accessor: 'damId',
      header: 'label.damId',
      sortable: true
    },

    dob: {
      accessor: 'dobToDisplay',
      classNames: ['hide-s', 'align-right', 'year-dob'],
      header: 'label.yearDob',
      sortable: true,
      render: ({ row, t, value }) => {
        return (
          <>{value && value !== '-'
            ? value
            : (row.dob
              ? row.dob
              : t('label.unknown'))
          }</>
        );
      }
    },

    dobOnly: {
      accessor: 'dobToDisplay',
      classNames: ['hide-s', 'align-right', 'year-dob'],
      header: 'label.dob',
      sortable: true,
      render: ({ row, t, value }) => {
        return (
          <>{value && value !== '-'
            ? value
            : (row.dob
              ? row.dob
              : t('label.unknown'))
          }</>
        );
      }
    },

    gender: {
      accessor: 'genderName',
      classNames: ['hide-m', 'gender'],
      header: 'label.gender',
      sortable: true,
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    },

    geneticDamId: {
      accessor: 'geneticDamId',
      header: 'label.geneticDamId',
      sortable: true
    },

    genotype: {
      accessor: 'genotype',
      classNames: ['genotype'],
      header: 'label.genotype',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{value ? he.decode(value) : ''}</>
        );
      }
    },

    quantity: {
      accessor: 'animalTotal',
      classNames: ['align-right', 'qty'],
      header: 'label.totalAnimals',
      sortable: true
    },

    replacedBy: {
      render: ({ t }) => {
        return (
          <>{t('tagging.replacement.replacedBy')}</>
        );
      }
    },

    sireId: {
      accessor: 'sireId',
      header: 'label.sireId',
      sortable: true
    },

    tagNumber: {
      accessor: 'tagNumber',
      classNames: ['tag-number'],
      header: 'label.tagNumber',
      sortable: true
    },

    tagNumberNew: {
      accessor: 'newTagNumber',
      header: 'label.newTagNumber',
      sortable: true
    },

    tagNumberOld: {
      accessor: 'oldTagNumber',
      header: 'label.oldTagNumber',
      sortable: true
    }
  },

  bo: {
    animalActivity: {
      appliedBy: {
        accessor: 'replacementHolding',
        header: 'label.appliedBy',
        render: ({ value }) => {
          return (
            <>{value ? value : '-'}</>
          );
        }
      },

      arrivalDate: {
        accessor: 'arrivalDateTime',
        classNames: ['hide-m', 'date'],
        header: 'label.arrivalDate',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? helpers.date.format(value) : '-'}</>
          );
        }
      },

      breed: {
        accessor: 'breed.name',
        header: 'label.breed',
        sortable: true,
        render: ({ t, value }) => {
          return (
            <>{value ? he.decode(value) : t('label.unknown')}</>
          );
        }
      },

      dob: {
        accessor: 'beginBirthPeriod',
        classNames: ['date'],
        header: 'label.dateBirth',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      departureCph: {
        accessor: 'sourceHolding',
        header: 'label.departureCPH',
        sortable: true,
        render: ({ row, value }) => {
          return (
            <>{value === constants.cph.none ? row.destinationHolding : value}</>
          );
        }
      },

      departureDate: {
        accessor: 'departureDateTime',
        classNames: ['date'],
        header: 'label.departureDate',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? helpers.date.format(value) : '-'}</>
          );
        }
      },

      destinationCph: {
        accessor: 'destinationHolding',
        header: 'label.destinationCPH',
        sortable: true,
        render: ({ row, value }) => {
          return (
            <>{row.sourceHolding === constants.cph.none ? constants.cph.none : value}</>
          );
        }
      },

      gender: {
        accessor: 'genderSpecies.name',
        classNames: ['gender'],
        header: 'label.gender',
        sortable: true,
        render: ({ t, value }) => {
          return (
            <>{value && value !== '-' ? value : t('label.unknown')}</>
          );
        }
      },

      movementRef: {
        accessor: 'movementDocumentRef',
        header: 'label.movementRef',
        sortable: true
      },

      replacementDate: {
        accessor: 'replacementDate',
        classNames: ['date'],
        header: 'label.applicationDate',
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      replacedIdentifier: {
        accessor: 'replacedIdentifier',
        header: 'label.tagNumber'
      },

      replacingIdentifier: {
        accessor: 'replacingIdentifier',
        header: 'label.replacementTagNumber'
      },

      requestId: (params) => ({
        accessor: 'requestId',
        classNames: ['align-right', 'request-id'],
        header: 'label.requestId',
        sortable: params.sortable
      }),

      systemMove: {
        accessor: 'isSystemMove',
        header: 'label.systemMove',
        sortable: true,
        render: ({ t, value }) => (
          <>{t(value ? 'label.yes' : 'label.no')}</>
        )
      },

      tagNumber: {
        accessor: 'tagNumber',
        header: 'label.tagNumber',
        sortable: true
      }
    },

    holdingActivity: {
      adjacentCph: {
        accessor: 'adjacentHolding',
        header: 'label.adjacentMoveCph'
      },

      age: {
        accessor: 'ageToDisplay',
        header: 'label.age',
        sortable: true,
        sortColumn: 'endBirthPeriod',
        sortReverse: true,
        render: ({ t, value }) => {
          return (
            <>{value === 0 ? t('label.ageOneYear.under') : value === '-' ? value : value >= 1 ? value + t('label.ageOneYear.above') : value}</>
          );
        }
      },

      animalsAddedDate: {
        accessor: 'content.dateAnimalsWereLocated',
        classNames: ['date'],
        header: 'label.eventDate',
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      birthYear: {
        accessor: 'dobToDisplay',
        classNames: ['align-right', 'year-dob'],
        header: 'label.yearDob',
        sortable: true,
        sortColumn: 'beginBirthPeriod',
        render: ({ t, value }) => {
          return (
            <>{value && value !== '-' ? value : t('label.unknown')}</>
          );
        }
      },

      breedName: {
        accessor: 'breedName',
        header: 'label.breed',
        sortable: true,
        render: ({ t, value }) => {
          return (
            <>{value ? he.decode(value) : t('label.unknown')}</>
          );
        }
      },

      breedName2: {
        accessor: 'breed.name',
        header: 'label.breed',
        render: ({ t, value }) => {
          return (
            <>{value ? he.decode(value) : t('label.unknown')}</>
          );
        }
      },

      cph: {
        accessor: 'cph',
        header: 'label.cph'
      },

      createRequestId: {
        accessor: 'createRequestId',
        classNames: ['hide-m', 'align-right', 'request-id'],
        header: 'label.requestId',
        sortable: true
      },

      dateArrival: {
        accessor: 'arrivalDateTime',
        classNames: ['date'],
        header: 'label.dateArrival',
        sortable: true,
        render: ({ value }) => helpers.date.format(value)
      },

      dateDeparture: {
        accessor: 'departureDateTime',
        classNames: ['date'],
        header: 'label.dateDeparture',
        sortable: true,
        render: ({ value }) => helpers.date.format(value)
      },

      departureHolding: {
        accessor: 'sourceHolding',
        header: 'label.departureCPH',
        sortable: true,
        render: ({ row }) => row.sourceHolding + (helpers.option.recordBy.isByDeparture(row.recordedBy) ? '*' : '')
      },

      destinationHolding: {
        accessor: 'destinationHolding',
        header: 'label.destinationCPH',
        sortable: true,
        render: ({ row }) => row.destinationHolding + (helpers.option.recordBy.isByDestination(row.recordedBy) ? '*' : '')
      },

      disposalLocation: {
        accessor: 'disposalLocation',
        header: 'label.locationOfCarcass',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? he.decode(value) : ''}</>
          );
        }
      },

      dob: {
        accessor: 'dobToDisplay',
        header: 'label.yearDob',
        sortable: true,
        sortColumn: 'beginBirthPeriod',
        render: ({ t, value }) => {
          return (
            <>{value && value !== '-' ? value : t('label.unknown')}</>
          );
        }
      },

      endDate: {
        accessor: 'endDate',
        classNames: ['date'],
        header: 'label.endDate',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? helpers.date.format(value) : '-'}</>
          );
        }
      },

      formatAppliedDate: {
        accessor: 'dateApplied',
        classNames: ['date'],
        header: 'label.dateOfId',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      formatDeathDate: {
        accessor: 'deathDate',
        classNames: ['date'],
        header: 'holdingRegister.header.formatDeathDate',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      formatEnteredDate: {
        accessor: 'enteredDate',
        classNames: ['date'],
        header: 'holdingRegister.header.formatEnteredDate',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      formatInventoryDate: {
        accessor: 'inventoryDate',
        classNames: ['date'],
        header: 'holdingRegister.header.formatInventoryDate',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      formatMovementDate: {
        accessor: 'movementDate',
        classNames: ['date'],
        header: 'label.movementDate',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      formatReplacementDate: {
        accessor: 'replacementDate',
        classNames: ['date'],
        header: 'holdingRegister.header.formatReplacementDate',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      frequency: {
        accessor: 'holdingLinkTypeOption.name',
        header: 'label.frequencyOfMovement',
        render: ({ t, value }) => {
          return (
            <>{t('label.animalsMoved')} {value.toLowerCase()}</>
          );
        }
      },

      genderId: {
        accessor: 'genderId',
        classNames: ['gender'],
        header: 'label.gender',
        render: ({ t, value }) => {
          return (
            <>{t('label.' + helpers.gender.idToName(value))}</>
          );
        }
      },

      genderName: {
        accessor: 'genderName',
        classNames: ['gender'],
        header: 'label.gender',
        render: ({ t, value }) => {
          return (
            <>{value && value !== '-' ? value : t('label.unknown')}</>
          );
        }
      },

      genotype: {
        accessor: 'genotype',
        classNames: ['genotype'],
        header: 'label.genotype',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? he.decode(value) : ''}</>
          );
        }
      },

      holdingName: {
        accessor: 'name',
        header: 'label.holdingName'
      },

      holdingType: {
        header: 'label.holdingType',
        render: ({ row }) => {
          return (
            <>{row.holdingType.fullName} [{row.holdingType.name}]</>
          );
        }
      },

      id: {
        accessor: 'id',
        classNames: ['hide-m', 'align-right', 'request-id'],
        header: 'label.requestId',
        sortable: true
      },

      movementDirection: {
        header: 'label.direction',
        render: ({ row }) => {
          return (
            <>{(row.userHolding === row.sourceHolding ? constants.option.movement.off : constants.option.movement.on).toUpperCase()}</>
          );
        }
      },

      movementRef: {
        accessor: 'documentRef',
        classNames: ['align-right'],
        header: 'label.movementRef',
        sortable: true
      },

      noOfAnimalsIdentified: {
        accessor: 'animalTotal',
        classNames: ['align-right', 'qty'],
        header: 'label.qty',
        sortable: true
      },

      noOfAnimalsInInventory: (params) => ({
        accessor: 'quantity',
        classNames: ['align-right', 'qty-wide'],
        header: 'holdingRegister.header.noOfAnimalsInInventory.' + (params.speciesId ? params.speciesId : '0'),
        sortable: true
      }),

      noOfAnimalsMoved: {
        accessor: 'animalTotal',
        classNames: ['align-right'],
        header: 'label.numberMoved',
        sortable: true
      },

      noOfAnimalsDied: {
        accessor: 'animalTotal',
        classNames: ['align-right', 'qty-wide'],
        header: 'label.qty',
        sortable: true
      },

      noOfAnimalsReplaced: {
        accessor: 'quantity',
        classNames: ['align-right', 'qty'],
        header: 'label.qty'
      },

      otherCph: {
        header: 'label.otherCph',
        render: ({ row }) => {
          return (
            <>{row.userHolding === row.sourceHolding ? row.sourceHolding : row.destinationHolding}</>
          );
        }
      },

      quantity: {
        accessor: 'animalTotal',
        classNames: ['align-right', 'qty'],
        header: 'label.qty',
        sortable: true
      },

      quantityDevicesWithDetail: {
        accessor: 'content.devicesWithDetail',
        classNames: ['align-right', 'qty-wide'],
        header: 'label.quantity',
        render: ({ value }) => {
          return (
            <>{value.length}</>
          );
        }
      },

      replacedAnimalIdentifier: {
        accessor: 'replacedAnimalIdentifier',
        header: 'label.previousTagNumber',
        sortable: true,
        render: ({ row }) => {
          return (
            <span dangerouslySetInnerHTML={{ __html: row.replacedAnimalIdentifier }} />
          );
        }
      },

      replacingAnimalIdentifier: {
        accessor: 'replacingAnimalIdentifier',
        header: 'label.replacementTagNumber',
        sortable: true,
        render: ({ row }) => {
          return (
            <span dangerouslySetInnerHTML={{ __html: row.replacingAnimalIdentifier }} />
          );
        }
      },

      requestId: {
        accessor: 'requestId',
        classNames: ['hide-m', 'align-right', 'request-id'],
        header: 'label.requestId',
        sortable: true
      },

      speciesId: {
        accessor: 'speciesId',
        classNames: ['species'],
        header: 'label.species',
        render: ({ value }) => {
          return (
            <>{value ? helpers.species.idToName(value) : ''}</>
          );
        }
      },

      speciesName: {
        accessor: 'species',
        classNames: ['species'],
        header: 'label.species'
      },

      speciesNameContent: {
        accessor: 'content.species',
        classNames: ['species'],
        header: 'label.species',
        sortable: true,
        sortReverse: true
      },

      status: {
        accessor: 'requestStatus',
        classNames: ['status'],
        header: 'label.requestStatus',
        render: ({ t, value }) => {
          switch (value) {
            case constants.status.success:
              return <i aria-label={t('label.status-' + value)} className="request-status status-success" title={t('label.status-' + value)} />;
            case constants.status.completed_warning:
              return <i aria-label={t('label.status-' + value)} className="request-status status-completedWarning" title={t('label.status-' + value)} />;
            case constants.status.completed_error:
              return <i aria-label={t('label.status-' + value)} className="request-status status-completedError" title={t('label.status-' + value)} />;
            case constants.status.error:
              return <i aria-label={t('label.status-' + value)} className="request-status status-error" title={t('label.status-' + value)} />;
            case constants.status.cancelled:
              return <i aria-label={t('label.status-' + value)} className="request-status status-cancelled" title={t('label.status-' + value)} />;
            case constants.status.claPending:
              return <i aria-label={t('label.status-' + value)} className="request-status status-pending" title={t('label.status-' + value)} />;
            case constants.status.pending:
              return <i aria-label={t('label.status-' + value)} className="request-status status-pending" title={t('label.status-' + value)} />;
            case constants.status.processing:
              return <i aria-label={t('label.status-' + value)} className="request-status status-pending" title={t('label.status-' + value)} />;
            default:
              return null;
          }
        }
      },

      startDate: {
        accessor: 'startDate',
        classNames: ['date'],
        header: 'label.startDate',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? helpers.date.format(value) : '-'}</>
          );
        }
      },

      tagNumber: {
        accessor: 'tagNumber',
        header: 'label.tagNumber',
        sortable: true
      },

      tagOrBatchNumber: {
        accessor: 'animalIdentifiers',
        header: 'label.tagNumberOrBatch',
        sortable: true,
        sortColumn: 'delimitedAnimalIdentifier',
        render: ({ value }) => {
          return (
            <>{value.join(', ')}</>
          );
        }
      },

      vaRCVS: {
        accessor: 'id',
        header: 'RCVS number'
      },

      vaStartDate: {
        accessor: 'startDate',
        header: 'Visit date',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? helpers.date.format(value) : '-'}</>
          );
        }
      },

      vaExpiryDate: {
        accessor: 'vetAttestationExpiryDate',
        header: 'Expiry date',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? helpers.date.format(value) : '-'}</>
          );
        }
      },

      vaStatus: {
        accessor: 'vetAttestationStatus',
        header: 'Vet attestation status',
        sortable: true
      },

      vaViewLink: {
        header: 'Record',
        accessor: 'id',
        render: ({ value }) => {
          if (helpers.tag.canLink(value)) {
            return (
              <Linker
                label={'View'}
                to={Routing.boVaRecordDeclaration + value}
              />
            );
          }
        }
      }
    },

    movementSearch: {
      actionPaper: (params) => ({
        accessor: 'tagNumber',
        header: 'label.view',
        render: ({ row }) => {
          const id = row.values.id ? row.values.id : row.documentRef;
          return (
            <Linker
              ariaLabel="label.actionNotification"
              icon="bi bi-binoculars"
              id={'viewLinker' + id}
              label="button.view"
              linkType="linkerDefault"
              state={{
                data: {
                  ...row,
                  onMovement: helpers.option.recordBy.isByDestination(row.recordedBy),
                  search: {
                    activeTabId: params.activeTabId,
                    searchEnabled: true,
                    paperId: params.paperId
                  }
                }
              }}
              to={navigation.paperDetails(id)}
            />
          );
        }
      }),

      approved: {
        accessor: 'confirmed',
        classNames: ['confirmed'],
        header: 'label.confirmedShort',
        render: ({ row, t }) => helpers.messageStatus.isUnknown(row.movementStatus) ? t('label.no') : row.movementStatus
      },

      arrival: {
        accessor: 'movementDocument',
        classNames: ['date'],
        header: 'backOfficeLanding.arrivalDate',
        render: ({ row }) => {
          const { arrivalDate } = row.movementDocument.destinationDetail;

          if (arrivalDate) {
            return helpers.date.format(helpers.date.toDate(
              arrivalDate.year,
              arrivalDate.month,
              arrivalDate.day
            ));
          }

          return null;
        }
      },

      confirmed: {
        classNames: ['align-center', 'confirmed'],
        header: 'label.confirmedShort',
        render: ({ row, t }) => (
          <>
            {helpers.messageStatus.isUnknown(row.movementStatus) ? t('label.no') : row.movementStatus}
          </>
        )
      },

      dateArrival: {
        accessor: 'arrivalDateTime',
        classNames: ['date'],
        header: 'label.dateArrival',
        sortable: true,
        render: ({ value }) => helpers.date.format(value)
      },

      dateDeparture: {
        accessor: 'departureDateTime',
        classNames: ['date'],
        header: 'label.dateDeparture',
        sortable: true,
        render: ({ value }) => helpers.date.format(value)
      },

      depart: {
        accessor: 'movementDocument2',
        classNames: ['date'],
        header: 'backOfficeLanding.departureDate',
        sortable: true,
        render: ({ row, t }) => row.movementDocument?.departureDetail?.departureDate
          ? helpers.date.format(helpers.date.toDate(
            row.movementDocument.departureDetail.departureDate.year,
            row.movementDocument.departureDetail.departureDate.month,
            row.movementDocument.departureDetail.departureDate.day
          ))
          : t('label.noData')
      },

      departureHolding: {
        accessor: 'sourceHolding',
        classNames: ['departure-cph'],
        header: 'label.departureCPH',
        sortable: true,
        render: ({ row }) => row.sourceHolding + (helpers.option.recordBy.isByDeparture(row.recordedBy) ? '*' : '')
      },

      destinationHolding: {
        accessor: 'destinationHolding',
        classNames: ['destination-cph'],
        header: 'label.destinationCPH',
        sortable: true,
        render: ({ row }) => row.destinationHolding + (helpers.option.recordBy.isByDestination(row.recordedBy) ? '*' : '')
      },

      fromHolding: {
        accessor: 'fromHolding',
        classNames: ['departure-cph'],
        header: 'label.departureCPH',
        sortable: true,
        render: ({ row }) => row.fromHolding + (helpers.option.recordBy.isByDeparture(row.recordedBy) ? '*' : '')
      },

      movementDate: {
        accessor: 'movementDate',
        classNames: ['date'],
        header: 'label.transferDate',
        sortable: true,
        render: ({ value }) => helpers.date.format(value)
      },

      movementRef: {
        accessor: 'movementRef',
        classNames: ['ref', 'hide-m', 'align-right'],
        header: 'label.referenceShort',
        sortable: true
      },

      paperArrivalCPH: {
        accessor: 'movementDocument.destinationDetail.holding',
        header: 'label.receivingCPH',
        sortable: true
      },

      paperCount: {
        accessor: 'movementDocument.destinationDetail.totalAnimalsReceived',
        header: 'label.qty',
        sortable: true
      },

      paperDepartCPH: {
        accessor: 'movementDocument.departureDetail.holding',
        header: 'label.departureCPH',
        sortable: true
      },

      paperId: {
        accessor: 'id',
        header: 'label.paperId',
        sortable: true
      },

      quantity: {
        accessor: 'animalTotal',
        classNames: ['align-right', 'qty'],
        header: 'label.qty',
        sortable: true
      },

      species: {
        accessor: 'species',
        classNames: ['species'],
        header: 'label.species',
        sortable: true
      },

      toHolding: {
        accessor: 'toHolding',
        classNames: ['destination-cph'],
        header: 'label.destinationCPH',
        sortable: true,
        render: ({ row }) => row.toHolding + (helpers.option.recordBy.isByDestination(row.recordedBy) ? '*' : '')
      }
    },

    userActivity: {
      quantity: {
        accessor: 'content.animalCount',
        classNames: ['hide-s', 'align-right', 'qty'],
        header: 'label.qty'
      },

      requestId: {
        accessor: 'requestId',
        classNames: ['hide-m', 'align-right', 'request-id'],
        header: 'label.requestId'
      },

      species: {
        accessor: 'content.species',
        classNames: ['hide-s', 'species'],
        header: 'label.species',
        render: ({ t, value }) => {
          return (
            <>{t('label.species-' + value.toLowerCase())}</>
          );
        }
      },

      status: {
        accessor: 'status',
        classNames: ['status'],
        header: 'label.requestStatus',
        render: ({ t, value }) => {
          switch (value) {
            case constants.status.success:
              return <i aria-label={t('label.status-' + value)} className="request-status status-success" title={t('label.status-' + value)} />;
            case constants.status.completed_warning:
              return <i aria-label={t('label.status-' + value)} className="request-status status-completedWarning" title={t('label.status-' + value)} />;
            case constants.status.completed_error:
              return <i aria-label={t('label.status-' + value)} className="request-status status-completedError" title={t('label.status-' + value)} />;
            case constants.status.error:
              return <i aria-label={t('label.status-' + value)} className="request-status status-error" title={t('label.status-' + value)} />;
            case constants.status.cancelled:
              return <i aria-label={t('label.status-' + value)} className="request-status status-cancelled" title={t('label.status-' + value)} />;
            case constants.status.claPending:
              return <i aria-label={t('label.status-' + value)} className="request-status status-pending" title={t('label.status-' + value)} />;
            case constants.status.pending:
              return <i aria-label={t('label.status-' + value)} className="request-status status-pending" title={t('label.status-' + value)} />;
            case constants.status.processErrors:
              return <i aria-label={t('label.status-' + value)} className="request-status status-error" title={t('label.status-' + value)} />;
            case constants.status.processing:
              return <i aria-label={t('label.status-' + value)} className="request-status status-pending" title={t('label.status-' + value)} />;
            default:
              return null;
          }
        }
      },

      userCph: {
        accessor: 'content.holding',
        classNames: ['user-cph'],
        header: 'label.userCPH',
        render: ({ value }) => {
          return (
            <>{value ? value : '-'}</>
          );
        }
      },

      all: {
        dateReported: {
          accessor: 'requestDate',
          classNames: ['date'],
          header: 'label.reportedDate',
          render: ({ value }) => {
            return (
              <>{value ? helpers.date.format(value) : '-'}</>
            );
          }
        },

        eventType: {
          accessor: 'requestTypeId',
          header: 'label.eventType',
          render: ({ value }) => {
            const eventType = storeService.session.get.requestTypes().find((type) => type.id === value).name;

            return (
              <>{eventType ? eventType : '-'}</>
            );
          }
        }
      },

      animalsAdded: {
        eventDate: {
          accessor: 'content.dateAnimalsWereLocated',
          classNames: ['date'],
          header: 'label.eventDate',
          render: ({ value }) => {
            return (
              <>{helpers.date.format(value)}</>
            );
          }
        },

        quantity: {
          accessor: 'animalsAddedQuantity',
          classNames: ['hide-s', 'align-right', 'qty'],
          header: 'label.qty'
        }
      },

      annualInventory: {
        dateEntered: {
          accessor: 'annualInventory.enteredDate',
          classNames: ['date'],
          header: 'holdingRegister.header.formatEnteredDate',
          render: ({ value }) => {
            return (
              <>{helpers.date.format(value)}</>
            );
          }
        },

        inventoryDate: {
          accessor: 'annualInventory.inventoryDate',
          classNames: ['date'],
          header: 'holdingRegister.header.formatInventoryDate',
          render: ({ value }) => {
            return (
              <>{helpers.date.format(value)}</>
            );
          }
        },

        quantity: {
          accessor: 'annualInventory.total',
          classNames: ['hide-s', 'align-right', 'qty'],
          header: 'label.qty'
        },

        species: {
          accessor: 'annualInventory.species',
          classNames: ['species'],
          header: 'label.species'
        },

        userCph: {
          accessor: 'annualInventory.holding',
          classNames: ['user-cph'],
          header: 'label.userCPH'
        }
      },

      deaths: {
        carcassLocation: {
          accessor: 'content.approvedDisposalRoute',
          classNames: ['hide-m', 'carcass-location'],
          header: 'label.carcassLocation',
          render: ({ value }) => {
            return (
              <>{value ? he.decode(value) : '-'}</>
            );
          }
        },

        eventDate: {
          accessor: 'content.killDate',
          classNames: ['date'],
          header: 'label.eventDate',
          render: ({ value }) => {
            return (
              <>{helpers.date.format(value)}</>
            );
          }
        },

        quantity: {
          accessor: 'deathQuantity',
          classNames: ['hide-s', 'align-right', 'qty'],
          header: 'label.qty'
        }
      },

      movements: {
        arrivalDate: {
          accessor: 'content.movementDocument.destinationDetail.arrivalDateTime',
          classNames: ['hide-m', 'date'],
          header: 'label.arrivalDate',
          render: ({ value }) => {
            return (
              <>{value ? helpers.date.format(value) : '-'}</>
            );
          }
        },

        departureCph: {
          accessor: 'content.sourceHolding',
          classNames: ['departure-cph'],
          header: 'label.departureCPH'
        },

        departureDate: {
          accessor: 'content.movementDocument.departureDetail.departureDateTime',
          classNames: ['date'],
          header: 'label.departureDate',
          render: ({ value }) => {
            return (
              <>{value ? helpers.date.format(value) : '-'}</>
            );
          }
        },

        destinationCph: {
          accessor: 'content.destinationHolding',
          classNames: ['destination-cph'],
          header: 'label.destinationCPH'
        },

        movementReference: {
          accessor: 'content.movementDocument.movementDocumentRef',
          classNames: ['movement-reference', 'hide-m', 'align-right'],
          header: 'label.movementRef',
          render: ({ value }) => {
            return (
              <>{value ? value : '-'}</>
            );
          }
        }
      },

      movementReviews: {
        arrivalDate: {
          accessor: 'content.reviewMovement.arrivalDate',
          classNames: ['hide-m', 'date'],
          header: 'label.arrivalDate',
          render: ({ value }) => {
            return (
              <>{value ? helpers.date.format(value) : '-'}</>
            );
          }
        },

        dateReported: {
          accessor: 'requestDate',
          classNames: ['date'],
          header: 'label.reportedDate',
          render: ({ value }) => {
            return (
              <>{value ? helpers.date.format(value) : '-'}</>
            );
          }
        },

        movementReference: {
          accessor: 'content.documentRef',
          classNames: ['movement-reference', 'hide-m', 'align-right'],
          header: 'label.movementRef'
        },

        quantity: {
          accessor: 'notificationQuantity',
          classNames: ['align-right', 'qty-wide'],
          header: 'label.qtyAccepted'
        }
      },

      tagging: {
        eventDate: {
          accessor: 'taggingDate',
          classNames: ['date'],
          header: 'label.eventDate',
          render: ({ value }) => {
            return (
              <>{helpers.date.format(value)}</>
            );
          }
        },

        quantity: {
          accessor: 'taggingQuantity',
          classNames: ['align-right', 'qty'],
          header: 'label.qty'
        }
      }
    },

    verifyCustomer: {
      cphNumber: {
        accessor: 'cph',
        header: 'boApp.label.cphNumber',
        sortable: true
      },

      holdingAddress: {
        accessor: 'holdingAddress',
        header: 'boApp.label.holdingAddress',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? he.decode(value) : ''}</>
          );
        }
      },

      holdingName: {
        accessor: 'holdingName',
        header: 'boApp.label.holdingName',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? he.decode(value) : ''}</>
          );
        }
      },

      holdingPostcode: {
        accessor: 'holdingPostcode',
        header: 'boApp.label.holdingPostcode',
        sortable: true
      },

      holdingType: {
        accessor: 'holdingTypeFullName',
        header: 'boApp.label.holdingType',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? he.decode(value) : ''}</>
          );
        }
      },

      keeperName: {
        accessor: 'keeperName',
        header: 'boApp.label.keeperName',
        sortable: true,
        render: ({ value }) => {
          return (
            <>{value ? he.decode(value) : ''}</>
          );
        }
      }
    }
  },

  deathsMissingAndFound: {
    action: (params) => ({
      accessor: 'tagNumber',
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        if (params.actionType === 'toggle') {
          return (
            <Button
              buttonType={row.added ? 'remove' : 'add'}
              id={'toggle-' + row.tagNumber.replaceAll(' ', '')}
              label={row.added ? 'label.remove' : 'label.add'}
              onClick={(event, id) => params.actionFunction(row, id)}
            />
          );
        }
        return (
          <Button
            buttonType={params.actionType === 'add' ? 'add' : 'remove'}
            label={'label.' + params.actionType}
            onClick={() => params.actionFunction(row)}
          />
        );
      }
    }),

    actionBatch: (params) => ({
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        return (
          <Button
            buttonType="remove"
            label="label.remove"
            onClick={() => params.removeBatch(row)}
          />
        );
      }
    }),

    age: (params) => ({
      accessor: 'ageToDisplay',
      classNames: ['hide-l', 'age'],
      header: 'label.age',
      sortable: params.sortable !== undefined ? params.sortable : true,
      sortColumn: 'endBirthPeriod',
      render: ({ t, value }) => {
        return (
          <>{value === 0 ? t('label.ageOneYear.under') : value === '-' ? value : value >= 1 ? value + t('label.ageOneYear.above') : value}</>
        );
      }
    }),

    batchNumber: (params) => ({
      accessor: 'batchNumber',
      header: 'label.batchNumber' + params.speciesId,
      render: ({ t, value }) => {
        return (
          <>{value ? value : t('label.notSupplied')}</>
        );
      }
    }),

    breed: (params) => ({
      accessor: 'breedName',
      classNames: ['hide-m'],
      header: 'label.breed',
      sortable: params.sortable !== undefined ? params.sortable : true,
      render: ({ t, value }) => {
        return (
          <>{value ? he.decode(value) : t('label.unknown')}</>
        );
      }
    }),

    dob: (params) => ({
      accessor: 'dobToDisplay',
      classNames: ['hide-s', 'align-right', 'year-dob'],
      header: 'label.yearDob',
      sortable: params.sortable !== undefined ? params.sortable : true,
      sortColumn: 'beginBirthPeriod',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    }),

    gender: (params) => ({
      accessor: 'genderName',
      classNames: ['hide-m', 'gender'],
      header: 'label.gender',
      sortable: params.sortable !== undefined ? params.sortable : true,
      sortColumn: 'genderId',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    }),

    quantity: {
      accessor: 'animalTotal',
      classNames: ['align-right', 'qty'],
      header: 'label.totalAnimals'
    },

    status: {
      classNames: ['status', 'status-wide'],
      header: 'label.status',
      render: ({ row, t }) => {
        return (
          <>{helpers.batch.isInvalid(row) ? t('label.invalid') : ''}</>
        );
      }
    },

    tagNumber: (params) => ({
      accessor: 'tagNumber',
      header: 'label.tagNumber',
      sortable: params.sortable !== undefined ? params.sortable : true
    })
  },

  editCustomer: {
    address: {
      accessor: 'address',
      header: 'boApp.label.address',
      sortable: true
    },

    dateOfDeath: {
      accessor: 'dateOfDeath',
      classNames: ['date'],
      header: 'boApp.label.dateOfDeath',
      sortable: true
    },

    deathReference: {
      accessor: 'id',
      header: 'boApp.label.deathReference',
      sortable: true
    },

    departureDate: {
      accessor: 'departureDate',
      classNames: ['date'],
      header: 'boApp.label.departureDate',
      sortable: true
    },

    disposalRoute: {
      accessor: 'disposalRoute',
      header: 'boApp.label.disposalRoute',
      sortable: true
    },

    id: {
      accessor: 'id',
      header: 'boApp.label.id',
      sortable: true
    },

    movementReference: {
      accessor: 'movementRef',
      header: 'boApp.label.movementReference',
      sortable: true
    },

    numberOfAnimals: {
      accessor: 'numberOfAnimals',
      header: 'boApp.label.numberOfAnimals',
      sortable: true
    },

    species: {
      accessor: 'species',
      classNames: ['species'],
      header: 'label.species',
      sortable: true
    }
  },

  fci: {
    tagNumber: (params) => ({
      accessor: 'tagNumber',
      header: 'label.tagNumber',
      sortable: true,
      render: ({ row }) => {
        return (
          <CheckBox
            checkboxClassNames={['bo-checkbox']}
            id={'animal' + row.tagNumber}
            isChecked={row.unfit}
            key={row.tagNumber}
            label={row.tagNumber}
            name={'animal' + row.tagNumber}
            onChange={() => params.animalSelected(row.tagNumber)}
            type="checkbox"
            value={Boolean(row.tagNumber)}
          />
        );
      }
    }),

    breed: {
      accessor: 'breedId',
      classNames: ['hide-m'],
      header: 'label.breed',
      sortable: true,
      render: ({ row }) => {
        return (
          <>{breedsHelper.getBreedName(row.breedId)}</>
        );
      }
    },

    gender: {
      accessor: 'gender',
      classNames: ['hide-m', 'gender'],
      header: 'label.gender',
      sortable: true,
      render: ({ row }) => {
        return (
          <>{row.genderName}</>
        );
      }
    },

    dob: {
      accessor: 'dob',
      classNames: ['hide-s', 'align-right', 'year-dob'],
      header: 'label.yearDob',
      sortable: true,
      render: ({ row }) => {
        return (
          <>{helpers.date.format(row.dob)}</>
        );
      }
    }
  },

  gatheringSearch: {
    arrivalDate: (params) => ({
      accessor: params.movementStatus === 'unconfirmed' ? 'arrivalDateTime' : 'movementDocument.destinationDetail.arrivalDateTime',
      classNames: ['date'],
      header: 'label.arrivalDate',
      sortable: helpers.tab.isMovementRange(params.activeTabId) && params.movementStatus === 'unconfirmed' && params.movementType === 'off',
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    }),

    cph: (params) => ({
      accessor: helpers.option.movement.isOn(params.movementType)
        ? (params.movementRef ? 'fromHolding' : 'sourceHolding')
        : (params.movementRef ? 'toHolding' : 'destinationHolding'),
      header: helpers.option.movement.isOn(params.movementType) ? 'label.departureCPH' : 'label.destinationCPH',
      sortable: helpers.tab.isMovementRange(params.activeTabId)
    }),

    departureDate: (params) => ({
      accessor: params.movementStatus === 'unconfirmed' ? 'departureDateTime' : 'movementDocument.departureDetail.departureDateTime',
      classNames: ['date'],
      header: 'label.departureDate',
      sortable: helpers.tab.isMovementRange(params.activeTabId) && params.movementStatus === 'unconfirmed',
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    }),

    movementReference: (params) => ({
      accessor: helpers.tab.isMovementRange(params.activeTabId) && params.movementStatus === 'unconfirmed' ? 'documentRef' : 'movementDocument.movementDocumentRef',
      header: 'label.movementRef',
      sortable: helpers.tab.isMovementRange(params.activeTabId) && params.movementStatus === 'unconfirmed',
      render: ({ row }) => {
        return (
          <>{row?.movementDocument?.movementDocumentRef ? row.movementDocument.movementDocumentRef : row.documentRef}</>
        );
      }
    }),

    quantity: (params) => ({
      accessor: 'animalTotal',
      classNames: ['align-right', 'qty'],
      header: 'label.qty',
      sortable: helpers.tab.isMovementRange(params.activeTabId),
      render: ({ value }) => {
        return (
          <>{value}</>
        );
      }
    })
  },

  handshake: {
    actionRemoveIndividual: (params) => ({
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        return (
          <Button
            buttonType="remove"
            label="label.remove"
            onClick={() => params.removeAnimal(row)}
          />
        );
      }
    }),

    actionRestoreIndividual: (params) => ({
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        return (
          <Button
            buttonType="link"
            label="label.restore"
            onClick={() => params.restoreAnimal(row)}
          />
        );
      }
    }),

    actionRemoveBatch: (params) => ({
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        return (
          <Button
            buttonType="remove"
            label="label.remove"
            onClick={() => params.removeBatch(row)}
          />
        );
      }
    }),

    actionRestoreBatch: (params) => ({
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        return (
          <Button
            buttonType="link"
            label="label.restore"
            onClick={() => params.restoreBatch(row)}
          />
        );
      }
    }),

    age: {
      accessor: 'ageToDisplay',
      classNames: ['date'],
      header: 'label.age',
      render: ({ t, value }) => {
        return (
          <>{value === 0 ? t('label.ageOneYear.under') : value === '-' ? value : value >= 1 ? value + t('label.ageOneYear.above') : value}</>
        );
      }
    },

    batchNumber: (params) => ({
      accessor: 'batchNumber',
      header: 'label.batchNumber' + params.speciesId
    }),

    breed: {
      accessor: 'breed',
      header: 'label.breed'
    },

    dob: {
      accessor: 'dobToDisplay',
      classNames: ['date'],
      header: 'label.yearDob'
    },

    gender: {
      accessor: 'genderName',
      classNames: ['gender'],
      header: 'label.gender'
    },

    quantity: {
      accessor: 'animalTotal',
      header: 'label.qty'
    },

    status: {
      classNames: ['status'],
      header: 'label.status',
      render: ({ row, t }) => {
        return (
          <>{helpers.tag.isInvalid(row) ? t('label.invalid') : ''}</>
        );
      }
    },

    tagNumber: {
      accessor: 'tagNumber',
      header: 'label.tagNumber'
    }
  },

  holdingRegister: {
    adjacentCph: {
      accessor: 'adjacentHolding',
      header: 'label.adjacentMoveCph'
    },

    birthYear: {
      accessor: 'beginBirthPeriod',
      classNames: ['align-right', 'year-of-birth'],
      header: 'holdingRegister.header.birthYear',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{value ? moment(value).format('YYYY') : ''}</>
        );
      }
    },

    breed: {
      accessor: 'breed.name',
      header: 'holdingRegister.header.breed',
      sortable: true,
      render: ({ t, value }) => {
        return (
          <>{value ? he.decode(value) : t('label.unknown')}</>
        );
      }
    },

    comments: {
      accessor: 'comment',
      classNames: ['comment'],
      header: 'holdingRegister.header.comments',
      hint: (value) => value ? value : null,
      sortable: true,
      render: ({ value }) => {
        return (
          <span>{value}</span>
        );
      }
    },

    deleteRecord: (params) => ({
      classNames: ['action', 'align-right'],
      header: 'holdingRegister.header.deleteRecord',
      render: ({ row }) => {
        return (
          <Button
            buttonType="linkDanger"
            label="button.delete"
            onClick={() => params.showDeleteConfirm(row)}
          />
        );
      }
    }),

    disposalLocation: {
      accessor: 'disposalLocation',
      header: 'holdingRegister.header.disposalLocation',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{value ? he.decode(value) : ''}</>
        );
      }
    },

    download: (params) => ({
      classNames: ['download', 'align-right'],
      header: 'holdingRegister.header.downloadMovementDocument',
      render: ({ row }) => {
        if (helpers.holdingRegister.downloadPdfLink(row)) {
          return (
            <PDFButton
              buttonType="link"
              label="holdingRegister.button.download"
              requestId={row.requestId}
              requestType={row.requestTypeId}
              setModal={params.setModal}
            />
          );
        }

        return <></>;
      }
    }),

    endDate: {
      accessor: 'endDate',
      classNames: ['date'],
      header: 'label.endDate',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{value ? helpers.date.format(value) : '-'}</>
        );
      }
    },

    formatAppliedDate: {
      accessor: 'dateApplied',
      classNames: ['date'],
      header: 'holdingRegister.header.formatAppliedDate',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    formatArrivalDate: {
      accessor: 'movementDate',
      classNames: ['date'],
      header: 'holdingRegister.header.formatArrivalDate',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    formatDeathDate: {
      accessor: 'deathDate',
      classNames: ['date'],
      header: 'holdingRegister.header.formatDeathDate',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    formatDepartureDate: {
      accessor: 'movementDate',
      classNames: ['date'],
      header: 'holdingRegister.header.formatDepartureDate',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    formatEnteredDate: {
      accessor: 'enteredDate',
      classNames: ['date'],
      header: 'holdingRegister.header.formatEnteredDate',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    formatInventoryDate: {
      accessor: 'inventoryDate',
      classNames: ['date'],
      header: 'holdingRegister.header.formatInventoryDate',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    formatMovementDate: {
      accessor: 'movementDate',
      classNames: ['date'],
      header: 'holdingRegister.header.formatMovementDate',
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    formatReplacementDate: {
      accessor: 'replacementDate',
      classNames: ['date'],
      header: 'holdingRegister.header.formatReplacementDate',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    frequency: {
      accessor: 'holdingLinkTypeOption.name',
      header: 'label.frequencyOfMovement',
      render: ({ t, value }) => {
        return (
          <>{t('label.animalsMoved')} {value.toLowerCase()}</>
        );
      }
    },

    genotype: {
      accessor: 'genotype',
      classNames: ['genotype'],
      header: 'holdingRegister.header.genotype',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{value ? he.decode(value) : ''}</>
        );
      }
    },

    noOfAnimalsDied: {
      accessor: 'animalTotal',
      classNames: ['align-right', 'number-died'],
      header: 'holdingRegister.header.noOfAnimalsDied',
      sortable: true
    },

    noOfAnimalsIdentified: {
      accessor: 'animalTotal',
      classNames: ['align-right', 'number-identified'],
      header: 'holdingRegister.header.noOfAnimalsIdentified',
      sortable: true
    },

    noOfAnimalsInInventory: (params) => ({
      accessor: 'quantity',
      classNames: ['align-right', 'number-inventory'],
      header: 'holdingRegister.header.noOfAnimalsInInventory.' + params.speciesId,
      sortable: true
    }),

    noOfAnimalsMoved: {
      accessor: 'animalTotal',
      classNames: ['align-right', 'number-moved'],
      header: 'holdingRegister.header.noOfAnimalsMoved',
      sortable: true
    },

    noOfAnimalsReplaced: {
      accessor: 'quantity',
      classNames: ['align-right', 'number-replaced'],
      header: 'holdingRegister.header.noOfAnimalsReplaced'
    },

    ownUseOff: {
      accessor: 'comment',
      classNames: ['comment'],
      header: 'holdingRegister.header.ownUse',
      hint: (value) => value ? value : null,
      sortable: true,
      render: ({ value }) => {
        return (
          <span>{value}</span>
        );
      }
    },

    ownUseOn: {
      accessor: 'comment',
      classNames: ['comment'],
      header: 'holdingRegister.header.ownUse',
      hint: (value) => value ? value : null,
      sortable: true,
      render: ({ value }) => {
        return (
          <span>{value}</span>
        );
      }
    },

    replacedAnimalIdentifier: {
      accessor: 'replacedAnimalIdentifier',
      header: 'holdingRegister.header.replacedAnimalIdentifier',
      sortable: true,
      render: ({ row }) => {
        return (
          <span dangerouslySetInnerHTML={{ __html: row.replacedAnimalIdentifier }} />
        );
      }
    },

    replacingAnimalIdentifier: {
      accessor: 'replacingAnimalIdentifier',
      header: 'holdingRegister.header.replacingAnimalIdentifier',
      sortable: true,
      render: ({ row }) => {
        return (
          <span dangerouslySetInnerHTML={{ __html: row.replacingAnimalIdentifier }} />
        );
      }
    },

    sourceHoldingFrom: {
      accessor: 'sourceHolding',
      header: 'holdingRegister.header.sourceHoldingFrom',
      sortable: true
    },

    sourceHoldingTo: {
      accessor: 'destinationHolding',
      header: 'holdingRegister.header.sourceHoldingTo',
      sortable: true
    },

    startDate: {
      accessor: 'startDate',
      classNames: ['date'],
      header: 'label.startDate',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{value ? helpers.date.format(value) : '-'}</>
        );
      }
    },

    tagOrBatchNumber: {
      accessor: 'animalIdentifiers',
      header: 'holdingRegister.header.tagOrBatchNumber',
      sortable: true,
      sortColumn: 'delimitedAnimalIdentifier',
      render: ({ value }) => {
        return (
          <>{value.join(', ')}</>
        );
      }
    },

    transportHaulierName: {
      accessor: 'transportHaulierName',
      classNames: ['haulier'],
      header: 'holdingRegister.header.transportHaulierName',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{value ? he.decode(value) : ''}</>
        );
      }
    },

    transportVehicleRegistrationNo: {
      accessor: 'transportVehicleRegistrationNo',
      classNames: ['registration-number'],
      header: 'holdingRegister.header.transportVehicleRegistrationNo',
      sortable: true
    },

    update: (params) => ({
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        const formattedEndDate = params.getEndDate(row.endDate);

        return (
          <Button
            buttonType="link"
            label={formattedEndDate ? 'button.view' : 'button.update'}
            onClick={() => params.modalShow(formattedEndDate ? 'view' : 'edit', row)}
          />
        );
      }
    })
  },

  holdingSelection: {
    action: (params) => ({
      accessor: 'cph',
      classNames: ['select-holding', 'align-right'],
      render: ({ row }) => (
        <Button
          buttonType="link"
          label="label.selectHolding"
          onClick={() => params.selectHolding(row)}
        />
      )
    }),

    cph: {
      accessor: 'cph',
      header: 'label.cphNumber'
    },

    holdingAddress: {
      accessor: 'holdingAddress',
      header: 'label.holdingAddress'
    },

    holdingName: {
      accessor: 'holdingName',
      header: 'label.holdingName'
    }
  },

  holdingSummary: {
    actionRequired: {
      accessor: 'actionRequired',
      header: 'handshake.tableTitle.actionRequired'
    },
    movementDate: {
      accessor: 'movementDate',
      classNames: ['date'],
      header: 'handshake.tableTitle.movementDate',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{helpers.date.format(value)}</>
        );
      }
    },

    summary: {
      accessor: 'summary',
      header: 'handshake.tableTitle.movementSummary'
    },

    documentRef: {
      accessor: 'documentRef',
      classNames: ['align-right'],
      header: 'handshake.tableTitle.reference',
      sortable: true
    }
  },

  movements: {
    action: (params) => ({
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        return (
          <Button
            buttonType={row.added || params.isAmend ? 'remove' : 'add'}
            id={'toggle-' + (row.batchNumber ? row.batchNumber.trim().replaceAll(' ', '') : (row.tagNumber ? row.tagNumber.trim().replaceAll(' ', '') : row.index))}
            label={row.added || params.isAmend ? 'label.remove' : 'label.add'}
            onClick={() => params.actionFunction(row)}
          />
        );
      }
    }),

    age: {
      accessor: 'ageToDisplay',
      header: 'label.age',
      sortable: true,
      sortColumn: 'endBirthPeriod',
      sortReverse: true,
      render: ({ t, value }) => {
        return (
          <>{value === 0 ? t('label.ageOneYear.under') : value === '-' ? value : value >= 1 ? value + t('label.ageOneYear.above') : value}</>
        );
      }
    },

    ageToDisplay: {
      accessor: 'ageToDisplay',
      header: 'label.age',
      sortable: true,
      sortColumn: 'age',
      sortReverse: true,
      render: ({ t, value }) => {
        return (
          <>{value === 0 ? t('label.ageOneYear.under') : value === '-' ? value : value >= 1 ? value + t('label.ageOneYear.above') : value}</>
        );
      }
    },

    batchNumber: (params) => ({
      accessor: 'batchNumber',
      header: 'label.batchNumber' + params.speciesId,
      sortable: true,
      render: ({ t, value }) => {
        return (
          <>{value ? value : t('label.notSupplied')}</>
        );
      }
    }),

    breedId: {
      accessor: 'breedId',
      classNames: ['hide-m'],
      header: 'label.breed',
      sortable: true,
      render: ({ value }) => {
        return (
          <>{breedsHelper.getBreedName(value)}</>
        );
      }
    },

    breedName: {
      accessor: 'breedName',
      header: 'label.breed',
      sortable: true,
      render: ({ t, value }) => {
        return (
          <>{value ? he.decode(value) : t('label.unknown')}</>
        );
      }
    },

    dob: {
      accessor: 'dobToDisplay',
      classNames: ['hide-s', 'align-right', 'year-dob'],
      header: 'label.yearDob',
      sortable: true,
      sortColumn: 'beginBirthPeriod',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    },

    dobFci: {
      accessor: 'dob',
      classNames: ['hide-s', 'align-right', 'year-dob'],
      header: 'label.yearDob',
      sortable: true,
      sortColumn: 'beginBirthPeriod',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? helpers.date.format(value) : t('label.unknown')}</>
        );
      }
    },

    dobToDisplay: {
      accessor: 'dobToDisplay',
      classNames: ['hide-s', 'align-right', 'year-dob'],
      header: 'label.yearDob',
      sortable: true,
      sortColumn: 'dob',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    },

    genderId: {
      accessor: 'genderName',
      classNames: ['hide-m', 'gender'],
      header: 'label.gender',
      sortable: true,
      sortColumn: 'genderId',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    },

    genderName: {
      accessor: 'genderName',
      classNames: ['hide-m', 'gender'],
      header: 'label.gender',
      sortable: true,
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    },

    quantity: {
      accessor: 'animalTotal',
      classNames: ['align-right', 'qty-wide'],
      header: 'label.quantity',
      sortable: true
    },

    status: {
      classNames: ['status', 'status-wide'],
      header: 'label.status',
      render: ({ row, t }) => {
        if (helpers.tag.isInvalid(row)) {
          return (
            <>{t('label.invalid')}</>
          );
        }

        if (helpers.tag.isNonEid(row)) {
          return (
            <>{t('label.nonEid')}</>
          );
        }

        return '';
      }
    },

    tagNumber: (params) => ({
      accessor: 'tagNumber',
      header: 'label.tagNumber',
      sortable: params.sortable
    }),

    tagNumberCheckbox: (params) => ({
      accessor: 'tagNumber',
      header: 'label.tagNumber',
      sortable: true,
      render: ({ row, value }) => {
        return (
          <CheckBox
            id={'animal' + value}
            isChecked={row.unfit}
            key={value}
            label={value}
            name={'animal' + value}
            onChange={() => params.animalSelected(row)}
            type="checkbox"
            value={Boolean(value)}
          />
        );
      }
    })
  },

  movementsGatherings: {
    action: (params) => ({
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        return (
          <Button
            buttonType="remove"
            id={'toggle-' + (row.batchNumber ? row.batchNumber.trim().replaceAll(' ', '') : (row.tagNumber ? row.tagNumber.trim().replaceAll(' ', '') : row.index))}
            label="label.remove"
            onClick={() => params.actionFunction(row)}
          />
        );
      }
    }),

    batchNumber: (params) => ({
      accessor: 'batchNumber',
      header: 'label.batchNumber' + params.speciesId,
      sortable: true,
      render: ({ t, value }) => {
        return (
          <>{value ? value : t('label.notSupplied')}</>
        );
      }
    }),

    quantity: {
      accessor: 'animalTotal',
      header: 'label.totalAnimals',
      sortable: true
    },

    status: {
      classNames: ['status', 'status-wide'],
      header: 'label.status',
      render: ({ row, t }) => {
        return (
          <>{helpers.batch.isInvalid(row) ? t('label.invalid') : ''}</>
        );
      }
    },

    tagNumber: {
      accessor: 'tagNumber',
      header: 'label.tagNumber',
      sortable: true
    }
  },

  paperMovements: {
    action: (params) => ({
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        return (
          <Button
            buttonType="linkDanger"
            label="label.remove"
            onClick={() => params.removeRow(row)}
          />
        );
      }
    }),

    dateTime: {
      accessor: 'date',
      header: 'boApp.label.dateTime',
      sortable: true,
      render: ({ row }) => {
        return (
          <>{helpers.date.formatTime(row.date)}</>
        );
      }
    },

    batchNumber: (params) => ({
      accessor: 'batchNumber',
      header: 'label.batchNumber' + params.speciesId,
      sortable: true
    }),

    note: {
      accessor: 'text',
      header: 'boApp.label.note',
      sortable: true
    },

    quantity: {
      accessor: 'animalTotal',
      header: 'label.totalAnimals',
      sortable: true
    },

    statusBatch: {
      classNames: ['status'],
      id: 'status',
      header: 'label.status',
      render: ({ row, t }) => {
        return (
          <>{helpers.tag.isInvalid(row) ? t('label.invalid') : ''}</>
        );
      }
    },

    statusIndividual: {
      classNames: ['status'],
      id: 'status',
      header: 'label.status',
      render: ({ row, t }) => {
        return (
          <>{helpers.tag.isInvalid(row) ? row.status.value === 'DUPLICATE' ? t('label.duplicate') : t('label.invalid') : ''}</>
        );
      }
    },

    tagNumber: {
      accessor: 'tagNumber',
      header: 'label.tagNumber',
      sortable: true
    },

    user: {
      accessor: 'user',
      header: 'boApp.label.byUser',
      sortable: true
    }
  },

  spacer: {
    x1: {
      classNames: ['spacer-col-x1']
    },

    x2: {
      classNames: ['spacer-col-x2']
    },

    x3: {
      classNames: ['spacer-col-x3']
    },

    x4: {
      classNames: ['spacer-col-x4']
    }
  },

  tagApplication: {
    action: (params) => ({
      accessor: 'tagNumber',
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        return (
          <Button
            buttonType="remove"
            label="label.remove"
            onClick={() => params.actionFunction(row)}
          />
        );
      }
    }),

    batchNumber: (params) => ({
      accessor: 'batchNumber',
      header: 'label.batchNumber' + params.speciesId,
      sortable: true,
      render: ({ t, value }) => {
        return (
          <>{value ? value : t('label.notSupplied')}</>
        );
      }
    }),

    breed: {
      accessor: 'breedId',
      header: 'label.breed',
      render: ({ value }) => {
        return (
          <>{breedsHelper.getBreedName(value)}</>
        );
      }
    },

    dob: {
      accessor: 'dob',
      classNames: ['date'],
      header: 'label.yearDob',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? moment(helpers.date.formatForSubmission(value)).year() : t('label.unknown')}</>
        );
      }
    },

    gender: {
      accessor: 'genderName',
      classNames: ['gender'],
      header: 'label.gender',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    },

    quantity: {
      accessor: 'animalTotal',
      classNames: ['align-right', 'qty'],
      header: 'label.totalAnimals',
      sortable: true
    },

    status: {
      classNames: ['status', 'status-wide'],
      header: 'label.status',
      render: ({ row, t }) => {
        return (
          <>{helpers.batch.isInvalid(row) ? t('label.invalid') : ''}</>
        );
      }
    },

    tagNumber: {
      accessor: 'tagNumber',
      header: 'label.tagNumber',
      sortable: true
    }
  },

  tagReplacement: {
    action: (params) => ({
      accessor: 'tagNumber',
      classNames: ['action', 'align-right'],
      render: ({ row }) => {
        if (row.added) {
          return (
            <></>
          );
        }

        return (
          <Button
            buttonType={params.actionType === 'add' ? 'add' : 'remove'}
            label={'label.' + params.actionType}
            onClick={() => params.actionFunction(row)}
          />
        );
      }
    }),

    age: (params) => ({
      accessor: 'ageToDisplay',
      classNames: ['hide-l', 'age'],
      header: 'label.age',
      sortable: params.sortable !== undefined ? params.sortable : true,
      sortColumn: 'endBirthPeriod',
      render: ({ t, value }) => {
        return (
          <>{value === 0 ? t('label.ageOneYear.under') : value === '-' ? value : value >= 1 ? value + t('label.ageOneYear.above') : value}</>
        );
      }
    }),

    breed: {
      accessor: 'breedName',
      classNames: ['hide-m'],
      header: 'label.breed',
      render: ({ t, value }) => {
        return (
          <>{value ? he.decode(value) : t('label.unknown')}</>
        );
      }
    },

    dob: (params) => ({
      accessor: 'dobToDisplay',
      classNames: ['hide-s', 'align-right', 'year-dob'],
      header: 'label.yearDob',
      sortable: params.sortable !== undefined ? params.sortable : true,
      sortColumn: 'beginBirthPeriod',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    }),

    gender: (params) => ({
      accessor: 'genderName',
      classNames: ['hide-m', 'gender'],
      header: 'label.gender',
      sortable: params.sortable !== undefined ? params.sortable : true,
      sortColumn: 'genderId',
      render: ({ t, value }) => {
        return (
          <>{value && value !== '-' ? value : t('label.unknown')}</>
        );
      }
    }),

    newBatchNumber: {
      accessor: 'newBatch.batch'
    },

    newTagNumber: {
      accessor: 'newTag.tag'
    },

    oldBatchNumber: {
      accessor: 'oldBatch.batch',
      render: ({ row }) => {
        if (row.oldBatch.batch === config.UNKNOWN.toUpperCase()) {
          return <>{helpers.text.sentenceCase(row.oldBatch.batch)}</>;
        }

        return <>{row.oldBatch.batch}</>;
      }
    },

    oldTagNumber: {
      accessor: 'oldTag.tag'
    },

    quantity: {
      accessor: 'quantity',
      classNames: ['align-right', 'qty'],
      header: 'label.totalAnimals'
    },

    replacedBy: {
      render: ({ t }) => {
        return (
          <>{t('tagging.replacement.replacedBy')}</>
        );
      }
    },

    status: {
      classNames: ['status', 'status-wide'],
      header: 'label.status',
      render: ({ row, t }) => {
        if (helpers.tag.isInvalid(row.oldTag) && helpers.tag.isInvalid(row.newTag)) {
          return <>{t('label.invalidBothTags')}</>;
        } else if (helpers.tag.isInvalid(row.oldTag)) {
          return <>{t('label.invalidOldTag')}</>;
        } else if (helpers.tag.isInvalid(row.newTag)) {
          return <>{t('label.invalidNewTag')}</>;
        } else if (helpers.tag.isInvalid(row.oldBatch) && helpers.tag.isInvalid(row.newBatch)) {
          return <>{t('label.invalidBothBatches')}</>;
        } else if (helpers.tag.isInvalid(row.oldBatch)) {
          return <>{t('label.invalidOldBatch')}</>;
        } else if (helpers.tag.isInvalid(row.newBatch)) {
          return <>{t('label.invalidNewBatch')}</>;
        }

        return <></>;
      }
    },

    tagNumber: (params) => ({
      accessor: 'tagNumber',
      header: 'label.tagNumber',
      sortable: params.sortable !== undefined ? params.sortable : true
    })
  },

  userActivity: {
    status: {
      accessor: 'status',
      classNames: ['status'],
      header: 'label.requestStatus',
      render: ({ t, value }) => {
        switch (value) {
          case constants.status.success:
            return <i aria-label={t('label.status-' + value)} className="request-status status-success" title={t('label.status-' + value)} />;
          case constants.status.completed_warning:
            return <i aria-label={t('label.status-' + value)} className="request-status status-completedWarning" title={t('label.status-' + value)} />;
          case constants.status.completed_error:
            return <i aria-label={t('label.status-' + value)} className="request-status status-completedError" title={t('label.status-' + value)} />;
          case constants.status.error:
            return <i aria-label={t('label.status-' + value)} className="request-status status-error" title={t('label.status-' + value)} />;
          case constants.status.cancelled:
            return <i aria-label={t('label.status-' + value)} className="request-status status-cancelled" title={t('label.status-' + value)} />;
          case constants.status.claPending:
            return <i aria-label={t('label.status-' + value)} className="request-status status-pending" title={t('label.status-' + value)} />;
          case constants.status.pending:
            return <i aria-label={t('label.status-' + value)} className="request-status status-pending" title={t('label.status-' + value)} />;
          case constants.status.processErrors:
            return <i aria-label={t('label.status-' + value)} className="request-status status-error" title={t('label.status-' + value)} />;
          case constants.status.processing:
            return <i aria-label={t('label.status-' + value)} className="request-status status-pending" title={t('label.status-' + value)} />;
          default:
            return null;
        }
      }
    },

    animalsAdded: {
      eventDate: {
        accessor: 'content.dateAnimalsWereLocated',
        classNames: ['date'],
        header: 'label.eventDate',
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      quantity: {
        accessor: 'content.devices',
        header: 'label.qty',
        classNames: ['hide-s', 'align-right', 'qty'],
        render: ({ value }) => {
          return (
            <>{value.length}</>
          );
        }
      },

      quantityAnimalsAdded: {
        accessor: 'content.devicesWithDetail',
        header: 'label.qty',
        classNames: ['hide-s', 'align-right', 'qty'],
        render: ({ value }) => {
          return (
            <>{value.length}</>
          );
        }
      },

      requestId: {
        accessor: 'requestId',
        classNames: ['hide-m', 'align-right', 'request-id'],
        header: 'label.reference'
      },

      species: {
        accessor: 'content.species',
        classNames: ['hide-s', 'species'],
        header: 'label.species',
        render: ({ t, value }) => {
          return (
            <>{t('label.species-' + value.toLowerCase())}</>
          );
        }
      },

      userCph: {
        accessor: 'content.holding',
        header: 'label.userCPH',
        render: ({ value }) => {
          return (
            <>{value ? value : '-'}</>
          );
        }
      }
    },

    deaths: {
      carcassLocation: {
        classNames: ['hide-m', 'carcass-location'],
        header: 'label.carcassLocation',
        render: ({ row }) => {
          return (
            <>{row.content.approvedDisposalRoute ? he.decode(row.content.approvedDisposalRoute) : (row.content.disposalHolding ? he.decode(row.content.disposalHolding) : '-')}</>
          );
        }
      },

      eventDate: {
        accessor: 'content.killDate',
        classNames: ['date'],
        header: 'label.eventDate',
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      quantity: {
        accessor: 'deathQuantity',
        classNames: ['hide-s', 'align-right', 'qty'],
        header: 'label.qty'
      },

      requestId: {
        accessor: 'requestId',
        classNames: ['hide-m', 'align-right', 'request-id'],
        header: 'label.reference'
      },

      species: {
        accessor: 'content.species',
        classNames: ['species'],
        header: 'label.species',
        render: ({ t, value }) => {
          return (
            <>{t('label.species-' + value.toLowerCase())}</>
          );
        }
      },

      userCph: {
        accessor: 'content.holding',
        header: 'label.userCPH',
        render: ({ value }) => {
          return (
            <>{value ? value : '-'}</>
          );
        }
      }
    },

    movements: {
      arrivalDate: {
        accessor: 'content.movementDocument.destinationDetail.arrivalDateTime',
        classNames: ['hide-m', 'date'],
        header: 'label.arrivalDate',
        render: ({ value }) => {
          return (
            <>{value ? helpers.date.format(value) : '-'}</>
          );
        }
      },

      departureCph: {
        accessor: 'content.sourceHolding',
        header: 'label.departureCPH'
      },

      departureDate: {
        accessor: 'content.movementDocument.departureDetail.departureDateTime',
        classNames: ['date'],
        header: 'label.departureDate',
        render: ({ value }) => {
          return (
            <>{value ? helpers.date.format(value) : '-'}</>
          );
        }
      },

      destinationCph: {
        accessor: 'content.destinationHolding',
        header: 'label.destinationCPH'
      },

      movementReference: {
        accessor: 'content.movementDocument.movementDocumentRef',
        classNames: ['hide-m', 'align-right'],
        header: 'label.movementRef',
        render: ({ value }) => {
          return (
            <>{value ? value : '-'}</>
          );
        }
      },

      quantity: {
        accessor: 'content.animalCount',
        classNames: ['hide-s', 'align-right', 'qty'],
        header: 'label.qty'
      },

      species: {
        accessor: 'content.species',
        classNames: ['hide-s', 'species'],
        header: 'label.species',
        render: ({ t, value }) => {
          return (
            <>{t('label.species-' + value.toLowerCase())}</>
          );
        }
      }
    },

    reviewedNotifications: {
      dateReported: {
        accessor: 'requestDate',
        classNames: ['date'],
        header: 'label.reportedDate',
        render: ({ value }) => {
          return (
            <>{value ? helpers.date.format(value) : '-'}</>
          );
        }
      },

      movementReference: {
        accessor: 'content.documentRef',
        classNames: ['hide-m', 'align-right'],
        header: 'label.movementRef'
      },

      quantity: {
        accessor: 'notificationQuantity',
        classNames: ['align-right', 'qty-wide'],
        header: 'label.qtyAccepted'
      },

      userCph: {
        accessor: 'content.holding',
        header: 'label.userCPH',
        render: ({ value }) => {
          return (
            <>{value ? value : '-'}</>
          );
        }
      }
    },

    tagging: {
      eventDate: {
        accessor: 'taggingDate',
        classNames: ['date'],
        header: 'label.eventDate',
        render: ({ value }) => {
          return (
            <>{helpers.date.format(value)}</>
          );
        }
      },

      quantity: {
        accessor: 'taggingQuantity',
        classNames: ['align-right', 'qty'],
        header: 'label.qty'
      },

      requestId: {
        accessor: 'requestId',
        classNames: ['hide-m', 'align-right', 'request-id'],
        header: 'label.reference'
      },

      species: {
        accessor: 'content.species',
        classNames: ['hide-s', 'species'],
        header: 'label.species',
        render: ({ t, value }) => {
          return (
            <>{t('label.species-' + value.toLowerCase())}</>
          );
        }
      },

      userCph: {
        accessor: 'content.holding',
        header: 'label.userCPH',
        render: ({ value }) => {
          return (
            <>{value ? value : '-'}</>
          );
        }
      }
    }
  }
};

const tableColumns = {
  bo: {
    animalActivity: {
      movements: (params) => [
        columns.bo.animalActivity.movementRef,
        columns.bo.animalActivity.departureCph,
        columns.bo.animalActivity.departureDate,
        columns.bo.animalActivity.destinationCph,
        columns.bo.animalActivity.arrivalDate,
        columns.bo.animalActivity.requestId(params),
        columns.bo.animalActivity.systemMove
      ],

      progenies: [
        columns.bo.animalActivity.tagNumber,
        columns.bo.animalActivity.breed,
        columns.bo.animalActivity.gender,
        columns.bo.animalActivity.dob
      ],

      tagHistory: (params) => [
        columns.bo.animalActivity.replacementDate,
        columns.bo.animalActivity.replacedIdentifier,
        columns.bo.animalActivity.replacingIdentifier,
        columns.bo.animalActivity.appliedBy,
        columns.bo.animalActivity.requestId(params)
      ]
    },

    holdingActivity: {
      adjacentMovements: [
        columns.bo.holdingActivity.speciesId,
        columns.bo.holdingActivity.startDate,
        columns.bo.holdingActivity.adjacentCph,
        columns.bo.holdingActivity.endDate,
        columns.bo.holdingActivity.frequency,
        columns.bo.holdingActivity.id
      ],

      animalsAdded: [
        columns.bo.holdingActivity.speciesNameContent,
        columns.bo.holdingActivity.animalsAddedDate,
        columns.bo.holdingActivity.quantityDevicesWithDetail,
        columns.bo.holdingActivity.requestId,
        columns.bo.holdingActivity.status
      ],

      animalsOnHolding: [
        columns.bo.holdingActivity.tagNumber,
        columns.bo.holdingActivity.breedName,
        columns.bo.holdingActivity.genderName,
        columns.bo.holdingActivity.dob,
        columns.bo.holdingActivity.age
      ],

      annualInventory: (params) => [
        columns.bo.holdingActivity.speciesId,
        columns.bo.holdingActivity.formatInventoryDate,
        columns.bo.holdingActivity.noOfAnimalsInInventory(params),
        columns.spacer.x1,
        columns.bo.holdingActivity.formatEnteredDate,
        columns.bo.holdingActivity.createRequestId
      ],

      associatedHoldings: [
        columns.bo.holdingActivity.cph,
        columns.bo.holdingActivity.holdingName,
        columns.bo.holdingActivity.holdingType
      ],

      deaths: [
        columns.bo.holdingActivity.speciesId,
        columns.bo.holdingActivity.formatDeathDate,
        columns.bo.holdingActivity.tagOrBatchNumber,
        columns.bo.holdingActivity.noOfAnimalsDied,
        columns.spacer.x1,
        columns.bo.holdingActivity.disposalLocation,
        columns.bo.holdingActivity.requestId
      ],

      movements: [
        columns.bo.holdingActivity.speciesId,
        columns.bo.holdingActivity.departureHolding,
        columns.bo.holdingActivity.dateDeparture,
        columns.bo.holdingActivity.destinationHolding,
        columns.bo.holdingActivity.dateArrival,
        columns.bo.holdingActivity.noOfAnimalsMoved,
        columns.bo.holdingActivity.requestId
      ],

      pendingReviews: [
        columns.bo.holdingActivity.speciesName,
        columns.bo.holdingActivity.formatMovementDate,
        columns.bo.holdingActivity.quantity,
        columns.spacer.x1,
        columns.bo.holdingActivity.movementDirection,
        columns.bo.holdingActivity.otherCph,
        columns.bo.holdingActivity.movementRef,
        columns.bo.holdingActivity.requestId
      ],

      tagApplications: [
        columns.bo.holdingActivity.speciesId,
        columns.bo.holdingActivity.formatAppliedDate,
        columns.bo.holdingActivity.tagOrBatchNumber,
        columns.bo.holdingActivity.noOfAnimalsIdentified,
        columns.bo.holdingActivity.birthYear,
        columns.spacer.x1,
        columns.bo.holdingActivity.breedName2,
        columns.bo.holdingActivity.genderId,
        columns.bo.holdingActivity.genotype,
        columns.bo.holdingActivity.requestId
      ],

      tagReplacements: [
        columns.bo.holdingActivity.speciesId,
        columns.bo.holdingActivity.formatReplacementDate,
        columns.bo.holdingActivity.noOfAnimalsReplaced,
        columns.spacer.x1,
        columns.bo.holdingActivity.replacingAnimalIdentifier,
        columns.bo.holdingActivity.replacedAnimalIdentifier,
        columns.bo.holdingActivity.requestId
      ],

      vaRecords: [
        columns.bo.holdingActivity.vaRCVS,
        columns.bo.holdingActivity.vaStartDate,
        columns.bo.holdingActivity.vaExpiryDate,
        columns.bo.holdingActivity.vaStatus,
        columns.bo.holdingActivity.vaViewLink
      ]
    },

    searchMovements: {
      byDateAndCph: [
        columns.bo.movementSearch.species,
        columns.bo.movementSearch.movementRef,
        columns.spacer.x1,
        columns.bo.movementSearch.departureHolding,
        columns.bo.movementSearch.dateDeparture,
        columns.bo.movementSearch.destinationHolding,
        columns.bo.movementSearch.dateArrival,
        columns.bo.movementSearch.quantity,
        columns.bo.movementSearch.confirmed
      ],

      byMovementRef: [
        columns.bo.movementSearch.species,
        columns.bo.movementSearch.movementRef,
        columns.spacer.x1,
        columns.bo.movementSearch.fromHolding,
        columns.bo.movementSearch.dateDeparture,
        columns.bo.movementSearch.toHolding,
        columns.bo.movementSearch.dateArrival,
        columns.bo.movementSearch.quantity,
        columns.bo.movementSearch.approved
      ]
    },

    userActivity: {
      animalsAdded: [
        columns.bo.userActivity.species,
        columns.bo.userActivity.animalsAdded.eventDate,
        columns.bo.userActivity.userCph,
        columns.bo.userActivity.animalsAdded.quantity,
        columns.bo.userActivity.requestId,
        columns.bo.userActivity.status
      ],

      all: [
        columns.bo.userActivity.all.dateReported,
        columns.bo.userActivity.all.eventType,
        columns.bo.userActivity.requestId,
        columns.bo.userActivity.status
      ],

      annualInventory: [
        columns.bo.userActivity.annualInventory.species,
        columns.bo.userActivity.annualInventory.userCph,
        columns.bo.userActivity.annualInventory.inventoryDate,
        columns.bo.userActivity.annualInventory.quantity,
        columns.spacer.x1,
        columns.bo.userActivity.annualInventory.dateEntered,
        columns.bo.userActivity.requestId,
        columns.bo.userActivity.status
      ],

      births: [
        columns.bo.userActivity.species,
        columns.bo.userActivity.tagging.eventDate,
        columns.bo.userActivity.userCph,
        columns.bo.userActivity.tagging.quantity,
        columns.bo.userActivity.requestId,
        columns.bo.userActivity.status
      ],

      deaths: [
        columns.bo.userActivity.species,
        columns.bo.userActivity.deaths.eventDate,
        columns.bo.userActivity.userCph,
        columns.bo.userActivity.deaths.quantity,
        columns.bo.userActivity.deaths.carcassLocation,
        columns.bo.userActivity.requestId,
        columns.bo.userActivity.status
      ],

      movements: [
        columns.bo.userActivity.species,
        columns.bo.userActivity.movements.departureDate,
        columns.bo.userActivity.movements.departureCph,
        columns.bo.userActivity.movements.arrivalDate,
        columns.bo.userActivity.movements.destinationCph,
        columns.bo.userActivity.quantity,
        columns.bo.userActivity.movements.movementReference,
        columns.bo.userActivity.requestId,
        columns.bo.userActivity.status
      ],

      movementAmends: [
        columns.bo.userActivity.species,
        columns.bo.userActivity.movements.departureDate,
        columns.bo.userActivity.movements.departureCph,
        columns.bo.userActivity.movements.arrivalDate,
        columns.bo.userActivity.movements.destinationCph,
        columns.bo.userActivity.quantity,
        columns.bo.userActivity.movements.movementReference,
        columns.bo.userActivity.requestId,
        columns.bo.userActivity.status
      ],

      movementReviews: [
        columns.bo.userActivity.movementReviews.dateReported,
        columns.bo.userActivity.movementReviews.arrivalDate,
        columns.bo.userActivity.userCph,
        columns.bo.userActivity.movementReviews.quantity,
        columns.bo.userActivity.movementReviews.movementReference,
        columns.bo.userActivity.requestId,
        columns.bo.userActivity.status
      ],

      tagApplications: [
        columns.bo.userActivity.species,
        columns.bo.userActivity.tagging.eventDate,
        columns.bo.userActivity.userCph,
        columns.bo.userActivity.tagging.quantity,
        columns.bo.userActivity.requestId,
        columns.bo.userActivity.status
      ],

      tagReplacements: [
        columns.bo.userActivity.species,
        columns.bo.userActivity.tagging.eventDate,
        columns.bo.userActivity.userCph,
        columns.bo.userActivity.tagging.quantity,
        columns.bo.userActivity.requestId,
        columns.bo.userActivity.status
      ]
    },

    verifyCustomer: [
      columns.bo.verifyCustomer.cphNumber,
      columns.bo.verifyCustomer.keeperName,
      columns.bo.verifyCustomer.holdingName,
      columns.bo.verifyCustomer.holdingAddress,
      columns.bo.verifyCustomer.holdingPostcode,
      columns.bo.verifyCustomer.holdingType
    ]
  },

  animalDetails: {
    movements: [
      columns.animalDetails.dateOfMovement,
      columns.animalDetails.departure,
      columns.animalDetails.destination,
      columns.animalDetails.movementReference
    ],

    progenies: {
      deer: (params) => [
        columns.animalDetails.tagNumber(params)
      ],

      nonDeer: (params) => [
        columns.animalDetails.dob,
        columns.animalDetails.tagNumber(params),
        columns.animalDetails.gender,
        columns.animalDetails.breed
        // columns.animalDetails.location
      ]
    },

    tagReplacements: (params) => [
      columns.animalDetails.dateOfTagging(params),
      columns.animalDetails.initialTagNumber,
      columns.animalDetails.replacementTagNumber,
      columns.animalDetails.taggingReference
    ]
  },

  animalsOnHolding: {
    deer: [
      columns.animalsOnHolding.tagNumber
    ],

    nonDeer: (params) => [
      columns.animalsOnHolding.tagNumber,
      columns.animalsOnHolding.breed(params),
      columns.animalsOnHolding.gender(params),
      columns.animalsOnHolding.dob(params),
      columns.animalsOnHolding.age(params)
    ],

    modalColumns: (params) => [
      columns.animalsOnHolding.tagNumber,
      columns.animalsOnHolding.breed(params),
      columns.animalsOnHolding.gender(params),
      columns.animalsOnHolding.dob(params),
      columns.animalsOnHolding.age(params),
      columns.animalsOnHolding.status,
      columns.animalsOnHolding.action(params)
    ]
  },

  animalSummary: {
    batch: (params) => [
      columns.animalSummary.quantity,
      columns.animalSummary.batchNumber(params)
    ],

    batchTagReplacement: (params) => [
      columns.animalSummary.quantity,
      columns.animalSummary.batchNumberOld(params),
      columns.animalSummary.replacedBy,
      columns.animalSummary.batchNumberNew(params)
    ],

    birth: [
      columns.animalSummary.tagNumber,
      columns.animalSummary.breed,
      columns.animalSummary.gender,
      columns.animalSummary.dobOnly,
      columns.animalSummary.damId,
      columns.animalSummary.sireId,
      columns.animalSummary.geneticDamId,
      columns.animalSummary.genotype
    ],

    individual: {
      bo: [
        columns.animalSummary.tagNumber
      ],

      deer: [
        columns.animalSummary.tagNumber
      ],

      nonDeer: [
        columns.animalSummary.tagNumber,
        columns.animalSummary.breed,
        columns.animalSummary.gender,
        columns.animalSummary.dob,
        columns.animalSummary.age
      ]
    },

    individualActivityDetails: [
      columns.animalSummary.tagNumber
    ],

    individualActivityDetailsAnimalsAdded: [
      columns.animalSummary.tagNumber,
      columns.spacer.x2,
      columns.animalSummary.breed,
      columns.animalSummary.gender,
      columns.animalSummary.dob,
      columns.spacer.x2,
      columns.animalSummary.age
    ],

    individualTagReplacement: [
      columns.animalSummary.tagNumberOld,
      columns.animalSummary.replacedBy,
      columns.animalSummary.tagNumberNew
    ]
  },

  deathsMissingAndFound: {
    batch: (params) => [
      columns.deathsMissingAndFound.quantity,
      columns.deathsMissingAndFound.batchNumber(params),
      columns.deathsMissingAndFound.status,
      columns.deathsMissingAndFound.actionBatch(params)
    ],

    individual: (params) => [
      columns.deathsMissingAndFound.tagNumber(params),
      columns.deathsMissingAndFound.breed(params),
      columns.deathsMissingAndFound.gender(params),
      columns.deathsMissingAndFound.dob(params),
      columns.deathsMissingAndFound.age(params),
      columns.deathsMissingAndFound.action(params)
    ]
  },

  editCustomer: {
    death: [
      columns.editCustomer.deathReference,
      columns.editCustomer.dateOfDeath,
      columns.editCustomer.species,
      columns.editCustomer.numberOfAnimals,
      columns.editCustomer.disposalRoute
    ],

    movement: [
      columns.editCustomer.movementReference,
      columns.editCustomer.departureDate,
      columns.editCustomer.species,
      columns.editCustomer.numberOfAnimals,
      columns.editCustomer.address
    ]
  },

  fci: {
    deer: (params) => [
      columns.fci.tagNumber(params)
    ],

    nonDeer: (params) => [
      columns.fci.tagNumber(params)
    ]
  },

  gatheringSearch: {
    byDateAndCph: (params) => [
      columns.gatheringSearch.departureDate(params),
      columns.gatheringSearch.arrivalDate(params),
      columns.gatheringSearch.cph(params),
      columns.gatheringSearch.quantity(params),
      columns.gatheringSearch.movementReference(params)
    ],

    byMovementReference: (params) => [
      columns.gatheringSearch.departureDate(params),
      columns.gatheringSearch.arrivalDate(params),
      columns.gatheringSearch.cph(params),
      columns.gatheringSearch.quantity(params),
      columns.gatheringSearch.movementReference(params)
    ]
  },

  handshake: {
    batch: (params) => [
      columns.handshake.batchNumber(params),
      columns.handshake.quantity,
      columns.handshake.status,
      columns.handshake.actionRemoveBatch(params)
    ],

    batchRemoved: (params) => [
      columns.handshake.batchNumber(params),
      columns.handshake.quantity,
      columns.handshake.actionRestoreBatch(params)
    ],

    individual: {
      deer: (params) => [
        columns.handshake.tagNumber,
        columns.handshake.actionRemoveIndividual(params)
      ],

      nonDeer: (params) => [
        columns.handshake.tagNumber,
        columns.handshake.breed,
        columns.handshake.gender,
        columns.handshake.dob,
        columns.handshake.age,
        columns.handshake.status,
        columns.handshake.actionRemoveIndividual(params)
      ]
    },

    individualRemoved: {
      deer: (params) => [
        columns.handshake.tagNumber,
        columns.handshake.actionRestoreIndividual(params)
      ],

      nonDeer: (params) => [
        columns.handshake.tagNumber,
        columns.handshake.breed,
        columns.handshake.gender,
        columns.handshake.dob,
        columns.handshake.age,
        columns.handshake.actionRestoreIndividual(params)
      ]
    }
  },

  holdingRegister: {
    [constants.holdingRegister.type.adjacentMovements]: (params) => [
      columns.holdingRegister.startDate,
      columns.holdingRegister.frequency,
      columns.holdingRegister.adjacentCph,
      columns.holdingRegister.endDate,
      columns.holdingRegister.update(params)
    ],

    [constants.holdingRegister.type.annualInventory]: (params) => [
      columns.holdingRegister.formatInventoryDate,
      columns.holdingRegister.noOfAnimalsInInventory(params),
      columns.spacer.x4,
      columns.holdingRegister.formatEnteredDate,
      columns.holdingRegister.deleteRecord(params)
    ],

    [constants.holdingRegister.type.births]: [
      columns.holdingRegister.formatAppliedDate,
      columns.holdingRegister.tagOrBatchNumber,
      columns.holdingRegister.noOfAnimalsIdentified,
      columns.holdingRegister.birthYear,
      columns.holdingRegister.breed,
      columns.holdingRegister.genotype,
      columns.holdingRegister.ownUseOff
    ],

    [constants.holdingRegister.type.deaths]: [
      columns.holdingRegister.formatDeathDate,
      columns.holdingRegister.tagOrBatchNumber,
      columns.holdingRegister.noOfAnimalsDied,
      columns.spacer.x1,
      columns.holdingRegister.breed,
      columns.holdingRegister.disposalLocation
    ],

    [constants.holdingRegister.type.movementsOff]: (params) => [
      columns.holdingRegister.formatDepartureDate,
      columns.holdingRegister.noOfAnimalsMoved,
      columns.holdingRegister.tagOrBatchNumber,
      columns.holdingRegister.sourceHoldingTo,
      columns.holdingRegister.transportHaulierName,
      columns.holdingRegister.transportVehicleRegistrationNo,
      columns.holdingRegister.ownUseOff,
      columns.holdingRegister.download(params)
    ],

    [constants.holdingRegister.type.movementsOn]: [
      columns.holdingRegister.formatArrivalDate,
      columns.holdingRegister.noOfAnimalsMoved,
      columns.holdingRegister.tagOrBatchNumber,
      columns.holdingRegister.sourceHoldingFrom,
      columns.holdingRegister.ownUseOn
    ],

    [constants.holdingRegister.type.tagApplications]: [
      columns.holdingRegister.formatAppliedDate,
      columns.holdingRegister.tagOrBatchNumber,
      columns.holdingRegister.noOfAnimalsIdentified,
      columns.holdingRegister.birthYear,
      columns.spacer.x1,
      columns.holdingRegister.breed,
      columns.holdingRegister.genotype,
      columns.holdingRegister.ownUseOff
    ],

    [constants.holdingRegister.type.tagReplacements]: [
      columns.holdingRegister.formatReplacementDate,
      columns.holdingRegister.noOfAnimalsReplaced,
      columns.holdingRegister.replacingAnimalIdentifier,
      columns.holdingRegister.replacedAnimalIdentifier,
      columns.holdingRegister.comments
    ]
  },

  holdingSelection: (params) => [
    columns.holdingSelection.cph,
    columns.holdingSelection.holdingName,
    columns.holdingSelection.holdingAddress,
    columns.holdingSelection.action(params)
  ],

  holdingSummary: [
    columns.holdingSummary.actionRequired,
    columns.holdingSummary.movementDate,
    columns.holdingSummary.summary,
    columns.holdingSummary.documentRef
  ],
  vetAttestationDetails: [
    columns.vetAttestationData.id,
    columns.vetAttestationData.vetAttestationStatus
  ],
  abattoirAttestationDetails: [
    columns.vetAttestationData.id,
    columns.vetAttestationData.vetAttestationStatus,
    columns.vetAttestationData.viewLink
  ],
  manualTagDetails: [
    columns.manualTagDetails.tagNumber,
    columns.manualTagDetails.attestationCph,
    columns.manualTagDetails.status,
    columns.manualTagDetails.record
  ],

  movements: {
    batch: (params) => [
      columns.movements.batchNumber(params),
      columns.movements.quantity,
      columns.movements.action(params)
    ],

    fci: {
      deer: (params) => [
        columns.movements.tagNumberCheckbox(params)
      ],

      nonDeer: (params) => [
        columns.movements.tagNumberCheckbox(params),
        columns.movements.breedId,
        columns.movements.genderName,
        columns.movements.dobFci
      ]
    },

    individual: {
      deer: (params) => [
        columns.movements.tagNumber(params),
        columns.movements.action(params)
      ],

      nonDeer: (params) => [
        columns.movements.tagNumber(params),
        columns.movements.breedName,
        columns.movements.genderName,
        columns.movements.dobToDisplay,
        columns.movements.ageToDisplay,
        columns.movements.action(params)
      ]
    },

    modalAnimalsOnHolding: (params) => [
      columns.movements.tagNumber(params),
      columns.movements.breedName,
      columns.movements.genderId,
      columns.movements.dob,
      columns.movements.age,
      columns.movements.action(params)
    ],

    modalAddBatch: (params) => [
      columns.movements.quantity,
      columns.movements.batchNumber(params),
      columns.movements.status,
      columns.movements.action(params)
    ],

    modalFileUpload: (params) => [
      columns.movements.tagNumber(params),
      columns.movements.status,
      columns.movements.action(params)
    ],

    modalManualTagNumbers: (params) => [
      columns.movements.tagNumber(params),
      columns.movements.status,
      columns.movements.action(params)
    ],

    modalTagNumberRange: (params) => [
      columns.movements.tagNumber(params),
      columns.movements.status,
      columns.movements.action(params)
    ]
  },

  movementsGatherings: {
    batch: (params) => [
      columns.movementsGatherings.quantity,
      columns.movementsGatherings.batchNumber(params),
      columns.movementsGatherings.status,
      columns.movementsGatherings.action(params)
    ],

    individual: (params) => [
      columns.movementsGatherings.tagNumber,
      columns.movementsGatherings.status,
      columns.movementsGatherings.action(params)
    ]
  },

  paperMovements: {
    batch: (params) => [
      columns.paperMovements.quantity,
      columns.paperMovements.batchNumber(params),
      columns.paperMovements.statusBatch,
      columns.paperMovements.action(params)
    ],

    individual: (params) => [
      columns.paperMovements.tagNumber,
      columns.paperMovements.statusIndividual,
      columns.paperMovements.action(params)
    ],

    userNotes: (params) => [
      columns.paperMovements.dateTime,
      columns.paperMovements.note,
      columns.paperMovements.user,
      columns.paperMovements.action(params)
    ]
  },

  tagApplication: {
    availableTags: (params) => [
      columns.tagApplication.tagNumber,
      columns.tagApplication.status,
      columns.tagApplication.action(params)
    ],

    batches: (params) => [
      columns.tagApplication.quantity,
      columns.tagApplication.batchNumber(params),
      columns.tagApplication.status,
      columns.tagApplication.action(params)
    ]

    // individuals: (params) => [
    //   columns.tagApplication.tagNumber,
    //   columns.tagApplication.breed,
    //   columns.tagApplication.gender,
    //   columns.tagApplication.dob,
    //   columns.tagApplication.status,
    //   columns.tagApplication.action(params)
    // ]
  },

  tagReplacement: {
    onHolding: (params) => [
      columns.tagReplacement.tagNumber(params),
      columns.tagReplacement.breed,
      columns.tagReplacement.gender(params),
      columns.tagReplacement.dob(params),
      columns.tagReplacement.age(params),
      columns.tagReplacement.action(params)
    ],

    replacement: (params) => [
      columns.tagReplacement.oldTagNumber,
      columns.tagReplacement.replacedBy,
      columns.tagReplacement.newTagNumber,
      columns.tagReplacement.status,
      columns.tagReplacement.action(params)
    ],

    replacementSlaughterTags: (params) => [
      columns.tagReplacement.quantity,
      columns.tagReplacement.oldBatchNumber,
      columns.tagReplacement.replacedBy,
      columns.tagReplacement.newBatchNumber,
      columns.tagReplacement.status,
      columns.tagReplacement.action(params)
    ]
  },

  userActivity: {
    [constants.option.requestType.animalsAdded]: [
      columns.userActivity.animalsAdded.eventDate,
      columns.userActivity.animalsAdded.userCph,
      columns.userActivity.animalsAdded.quantityAnimalsAdded,
      columns.userActivity.animalsAdded.species,
      columns.userActivity.animalsAdded.requestId,
      columns.userActivity.status
    ],

    [constants.option.requestType.birth]: [
      columns.userActivity.tagging.eventDate,
      columns.userActivity.tagging.userCph,
      columns.userActivity.tagging.quantity,
      columns.userActivity.tagging.species,
      columns.userActivity.tagging.requestId,
      columns.userActivity.status
    ],

    [constants.option.requestType.death]: [
      columns.userActivity.deaths.eventDate,
      columns.userActivity.deaths.userCph,
      columns.userActivity.deaths.quantity,
      columns.userActivity.deaths.species,
      columns.userActivity.deaths.carcassLocation,
      columns.userActivity.deaths.requestId,
      columns.userActivity.status
    ],

    [constants.option.requestType.movement]: [
      columns.userActivity.movements.departureDate,
      columns.userActivity.movements.departureCph,
      columns.userActivity.movements.arrivalDate,
      columns.userActivity.movements.destinationCph,
      columns.userActivity.movements.quantity,
      columns.userActivity.movements.species,
      columns.userActivity.movements.movementReference,
      columns.userActivity.status
    ],

    [constants.option.requestType.correctTransfer]: [
      columns.userActivity.movements.departureDate,
      columns.userActivity.movements.departureCph,
      columns.userActivity.movements.arrivalDate,
      columns.userActivity.movements.destinationCph,
      columns.userActivity.movements.quantity,
      columns.userActivity.movements.species,
      columns.userActivity.movements.movementReference,
      columns.userActivity.status
    ],

    [constants.option.requestType.movementHandshake]: [
      columns.userActivity.reviewedNotifications.dateReported,
      columns.userActivity.reviewedNotifications.userCph,
      columns.userActivity.reviewedNotifications.quantity,
      columns.userActivity.reviewedNotifications.movementReference,
      columns.userActivity.status
    ],

    [constants.option.requestType.tagApplication]: [
      columns.userActivity.tagging.eventDate,
      columns.userActivity.tagging.userCph,
      columns.userActivity.tagging.quantity,
      columns.userActivity.tagging.species,
      columns.userActivity.tagging.requestId,
      columns.userActivity.status
    ],

    [constants.option.requestType.tagReplacement]: [
      columns.userActivity.tagging.eventDate,
      columns.userActivity.tagging.userCph,
      columns.userActivity.tagging.quantity,
      columns.userActivity.tagging.species,
      columns.userActivity.tagging.requestId,
      columns.userActivity.status
    ]
  },

  vetAbattoirManualTag: {
    individual: (params) => [
      columns.movements.tagNumber(params),
      columns.movements.status,
      columns.movements.action(params)
    ]
  }
};

const initialSorting = {
  manualTagDetails: {
    tagNumber: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    }
  },
  animalDetails: {
    movements: {
      accessor: 'dom',
      direction: constants.sorting.ascending
    },

    progenies: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    },

    tagReplacements: {
      accessor: 'replacementDate',
      direction: constants.sorting.ascending
    },
    vaRecords: {
      accessor: 'startDate',
      direction: constants.sorting.descending
    }
  },

  animalsOnHolding: {
    accessor: 'tagNumber',
    direction: constants.sorting.ascending
  },

  animalSummary: {
    batch: {
      accessor: 'batchNumber',
      direction: constants.sorting.ascending
    },

    individual: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    },

    individualTagReplacement: {
      accessor: 'oldTagNumber',
      direction: constants.sorting.ascending
    }
  },

  bo: {
    animalActivity: {
      movements: {
        accessor: 'tagNumber',
        direction: constants.sorting.ascending
      },

      progenies: {
        accessor: 'tagNumber',
        direction: constants.sorting.ascending
      },

      tagHistory: {
        accessor: 'tagNumber',
        direction: constants.sorting.ascending
      }
    },

    holdingActivity: {
      adjacentMovements: {
        accessor: 'startDate',
        direction: constants.sorting.descending
      },

      animalsAdded: {
        accessor: 'requestDate',
        direction: constants.sorting.descending
      },

      animalsOnHolding: {
        accessor: 'tagNumber',
        direction: constants.sorting.ascending
      },

      annualInventory: {
        accessor: 'enteredDate',
        direction: constants.sorting.descending
      },

      associatedHoldings: {
        accessor: 'cph',
        direction: constants.sorting.ascending
      },

      deaths: {
        accessor: 'deathDate',
        direction: constants.sorting.descending
      },

      holdingDetails: {},

      movements: {
        accessor: 'movementDate',
        direction: constants.sorting.descending
      },

      pendingReviews: {
        accessor: 'movementDate',
        direction: constants.sorting.descending
      },

      tagApplications: {
        accessor: 'dateApplied',
        direction: constants.sorting.descending
      },

      tagReplacements: {
        accessor: 'replacementDate',
        direction: constants.sorting.descending
      },

      vaRecords: {
        accessor: 'startDate',
        direction: constants.sorting.descending
      }
    },

    searchMovements: {
      accessor: 'movementDocument.departureDetail.departureDateTime',
      direction: constants.sorting.descending
    },

    userActivity: {
      animalsAdded: {
        accessor: 'requestDate',
        direction: constants.sorting.descending
      },

      all: {
        accessor: 'requestDate',
        direction: constants.sorting.descending
      },

      annualInventory: {
        accessor: 'requestId',
        direction: constants.sorting.descending
      },

      births: {
        accessor: 'requestId',
        direction: constants.sorting.descending
      },

      deaths: {
        accessor: 'requestId',
        direction: constants.sorting.descending
      },

      movements: {
        accessor: 'requestId',
        direction: constants.sorting.descending
      },

      movementAmends: {
        accessor: 'requestId',
        direction: constants.sorting.descending
      },

      movementReviews: {
        accessor: 'requestId',
        direction: constants.sorting.descending
      },

      tagApplications: {
        accessor: 'requestId',
        direction: constants.sorting.descending
      },

      tagReplacements: {
        accessor: 'requestId',
        direction: constants.sorting.descending
      }
    }
  },

  deathsMissingAndFound: {
    batch: {
      accessor: 'batchNumber',
      direction: constants.sorting.ascending
    },

    individual: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    }
  },

  editCustomer: {
    death: {
      accessor: 'dateOfDeath',
      direction: constants.sorting.descending
    },

    movement: {
      accessor: 'departureDate',
      direction: constants.sorting.descending
    }
  },

  gatheringSearch: {
    byDateAndCph: {
      unconfirmed: {
        accessor: 'departureDateTime',
        direction: constants.sorting.descending
      },

      reported: {
        accessor: 'transferDate',
        direction: constants.sorting.descending
      }
    },

    byMovementReference: {

    }
  },

  handshake: {
    batch: {
      accessor: 'batchNumber',
      direction: constants.sorting.ascending
    },
    individual: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    }
  },

  holdingRegister: {
    [constants.holdingRegister.type.adjacentMovements]: {
      accessor: 'startDate',
      direction: constants.sorting.descending
    },

    [constants.holdingRegister.type.annualInventory]: {
      accessor: 'enteredDate',
      direction: constants.sorting.descending
    },

    [constants.holdingRegister.type.births]: {
      accessor: 'dateApplied',
      direction: constants.sorting.descending
    },

    [constants.holdingRegister.type.deaths]: {
      accessor: 'deathDate',
      direction: constants.sorting.descending
    },

    [constants.holdingRegister.type.movementsOff]: {
      accessor: 'movementDate',
      direction: constants.sorting.descending
    },

    [constants.holdingRegister.type.movementsOn]: {
      accessor: 'movementDate',
      direction: constants.sorting.descending
    },

    [constants.holdingRegister.type.tagApplications]: {
      accessor: 'dateApplied',
      direction: constants.sorting.descending
    },

    [constants.holdingRegister.type.tagReplacements]: {
      accessor: 'replacementDate',
      direction: constants.sorting.descending
    },

    [constants.holdingRegister.type.vaRecords]: {
      accessor: 'startDate',
      direction: constants.sorting.descending
    }
  },

  holdingSummary: {
    accessor: 'movementDate',
    direction: constants.sorting.descending
  },
  vetDetails: {
    accessor: 'startDate',
    direction: constants.sorting.descending
  },

  movements: {
    batch: {
      accessor: 'batchNumber',
      direction: constants.sorting.ascending
    },

    fci: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    },

    individual: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    },

    modalAnimalsOnHolding: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    }
  },

  movementsGatherings: {
    batch: {
      accessor: 'batchNumber',
      direction: constants.sorting.ascending
    },

    individual: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    }
  },

  tagApplication: {
    availableTags: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    },

    batches: {
      accessor: 'batchNumber',
      direction: constants.sorting.ascending
    },

    individuals: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    }
  },

  tagReplacement: {
    onHolding: {
      accessor: 'tagNumber',
      direction: constants.sorting.ascending
    },

    replacement: {

    },

    replacementSlaughterTags: {

    }
  },

  userActivity: {
    [constants.option.requestType.animalsAdded]: {
      accessor: 'requestId',
      direction: constants.sorting.descending
    },

    [constants.option.requestType.birth]: {
      accessor: 'requestId',
      direction: constants.sorting.descending
    },

    [constants.option.requestType.death]: {
      accessor: 'requestId',
      direction: constants.sorting.descending
    },

    [constants.option.requestType.movement]: {
      accessor: 'requestId',
      direction: constants.sorting.descending
    },

    [constants.option.requestType.correctTransfer]: {
      accessor: 'requestId',
      direction: constants.sorting.descending
    },

    [constants.option.requestType.movementHandshake]: {
      accessor: 'requestId',
      direction: constants.sorting.descending
    },

    [constants.option.requestType.tagApplication]: {
      accessor: 'requestId',
      direction: constants.sorting.descending
    },

    [constants.option.requestType.tagReplacement]: {
      accessor: 'requestId',
      direction: constants.sorting.descending
    }
  }
};

export { tableColumns, initialSorting };
