import React, { useState } from 'react';
import config from 'config';
import constants from 'services/constants';
import classes from 'backOfficeComponents/addTagRange/addTagRange.module.scss';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Button from 'backOfficeComponents/base/button/button';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import FieldLabel from 'backOfficeComponents/base/fieldLabel/fieldLabel';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Legend from 'backOfficeComponents/base/legend/legend';

const AddTagRange = ({
  animalDetails,
  setAnimalDetails,
  setDataChanged,
  speciesId
}) => {
  const [batchNumberError, setBatchNumberError] = React.useState(false);
  const [totalAnimalsError, setTotalAnimalsError] = React.useState(false);
  const [totalAnimalsLimitError, setTotalAnimalsLimitError] = React.useState(false);
  const [batchNumber, setBatchNumber] = useState('');
  const [startNumber, setStartNumber] = useState('');
  const [totalAnimals, setTotalAnimals] = useState('');

  const tagRangeSubTitle = 'tagging.application.tagRangeSubTitle' + (speciesId ? speciesId : '');
  const batchNumberLabel = 'label.batchNumber' + (speciesId ? speciesId : '');

  const validate = {
    totalAnimals: (input) => {
      const regex = /^[0-9]*$/;
      const validResult = regex.test(input);
      setTotalAnimalsError(!validResult);
      return validResult;
    },

    totalAnimalsLimit: (input) => {
      const validResult = parseInt(input) > 0 && parseInt(input) <= config.MAX_TOTAL_ANIMAL_RANGE;
      setTotalAnimalsLimitError(!validResult);
      return validResult;
    }
  };

  const clearFields = () => {
    setBatchNumber('');
    setStartNumber('');
    setTotalAnimals('');
  };

  const submitRange = () => {
    if (!validate.totalAnimalsLimit(totalAnimals)) {
      setTotalAnimalsLimitError(true);
    } else if (!validate.totalAnimals(totalAnimals)) {
      setTotalAnimalsError(true);
    } else {
      setTotalAnimalsLimitError(false);
      setTotalAnimalsError(false);
      setBatchNumberError(false);
      clearFields();

      const batchNum = helpers.tag.trim(batchNumber);
      const startNum = helpers.tag.trim(startNumber);

      if (typeof setAnimalDetails === 'function') {
        const tags = [];
        for (let i = 0; i < totalAnimals; i++) {
          const numberOfLeadingZeros = (startNum.match(/^0+/) || [''])[0].length;

          let tagNum = parseInt(startNum) + i;

          if (numberOfLeadingZeros > 0 && String(tagNum).length < startNum.length) {
            tagNum = helpers.text.zerosPadLeft(tagNum, startNum.length);
          }

          tags.push(batchNum + ' ' + tagNum);
        }

        setAnimalDetails([
          ...animalDetails,
          ...tags
            .filter((tag) => !animalDetails.some((item) => item.tagNumber === tag))
            .map((tag) => ({
              tagNumber: tag,
              uuid: helpers.generateUUID()
            }))
        ]);
        setDataChanged();
      }
    }
  };

  const batchNumberChange = (event) => {
    setBatchNumber(event.target.value);
  };

  const startNumberChange = (event) => {
    setStartNumber(event.target.value);
  };

  const totalAnimalsChange = (event) => {
    setTotalAnimals(event.target.value);
  };

  const isDisabled = () => (!batchNumber || !totalAnimals);

  return (
    <>
      <fieldset role="group">
        <Legend
          id="addTageRange"
          legend="tagging.application.addTagNumberRange"
        />
        <FieldLabel
          id="numberRangeLabel"
          label={tagRangeSubTitle}
          name="numberRangeLabel"
        />

        {batchNumberError &&
          <ErrorLabel
            id="batchNumber"
            label="movements.animal-selection.batch.input-field-one-error"
          />
        }
        {totalAnimalsError &&
          <ErrorLabel
            id="totalAnimal"
            label="movements.animal-selection.batch.input-field-two-error"
          />
        }
        {totalAnimalsLimitError &&
          <ErrorLabel
            id="totalAnimalsLimit"
            label="movements.animal-selection.batch.total-animal-exceeded-limit"
          />
        }

        <div className={classes.tagNumberRange}>
          <div className={classes.batchNumber}>
            <FieldEntry
              hideCharacterCount={true}
              id="batchNumber"
              labelPosition={constants.labelPosition.above}
              labelText={batchNumberLabel}
              maxLength={config.WIDTH_GROUP_ID}
              name="batchNumber"
              onChange={batchNumberChange}
              value={batchNumber}
              width={config.WIDTH_GROUP_ID}
            />
          </div>
          <div className={classes.startNumber}>
            <FieldEntry
              id="startNumber"
              inputType={constants.inputType.number}
              labelPosition={constants.labelPosition.above}
              labelText="label.startNumber"
              name="startNumber"
              onChange={startNumberChange}
              value={startNumber}
              width={config.WIDTH_TAGS}
            />
          </div>
          <div className={classes.totalAnimals}>
            <FieldEntry
              id="totalAnimals"
              inputType={constants.inputType.number}
              labelPosition={constants.labelPosition.above}
              labelText="movements.animal-selection.batch.input-field-two"
              name="totalAnimals"
              onChange={totalAnimalsChange}
              value={totalAnimals}
              width={config.WIDTH_TOTAL_NUMBER}
            />
          </div>
          <div className={classes.action}>
            <Button
              buttonType="secondary"
              disabled={isDisabled()}
              id="addRangeButton"
              label="button.addRange"
              onClick={submitRange}
            />
          </div>
        </div>
      </fieldset>
    </>
  );
};

AddTagRange.propTypes = {
  animalDetails: PropTypes.array,
  setAnimalDetails: PropTypes.func.isRequired,
  setDataChanged: PropTypes.func.isRequired,
  speciesId: PropTypes.number
};

export default AddTagRange;
