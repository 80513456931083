import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/fieldLabel/fieldLabel.module.scss';
import Tooltip from 'components/base/tooltip/tooltip';

const FieldLabel = ({
  classNames,
  extraContent,
  id,
  label,
  label2,
  name,
  optional,
  tooltip,
  tooltipPosition
}) => {
  const { ready, t } = useTranslation();

  const outputLabel = t(label);
  const outputLabel2 = (' ' + t(label2));

  const componentClassNames = [
    classes.label,
    ...(classNames ? classNames.map((className) => classes[className]) : [])
  ].join(' ').trim();

  return (
    <>
      {ready && (outputLabel || outputLabel2) &&
        <label className={componentClassNames} htmlFor={name} id={id}>
          <span dangerouslySetInnerHTML={{ __html: outputLabel }} />
          {outputLabel2 ? outputLabel2 : ''}
          {extraContent ? ' ' + extraContent : ''}
          {optional &&
            <span className={classes.optional}>{t('label.optional')}</span>
          }
          {tooltip &&
            <Tooltip
              tooltip={tooltip}
              tooltipPosition={tooltipPosition}
            />
          }
        </label>
      }
    </>
  );
};

FieldLabel.propTypes = {
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  extraContent: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  label2: PropTypes.string,
  name: PropTypes.string,
  optional: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string
};

export default FieldLabel;
