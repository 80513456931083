import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/summary/fciSummary/fciSummary.module.scss';
import he from 'he';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Button from 'backOfficeComponents/base/button/button';

const FciSummary = ({
  animalsData,
  fciData,
  numberBeingMoved,
  onClick
}) => {
  const { ready, t } = useTranslation();

  const [showFCI, setShowFCI] = React.useState(false);

  const toggleFCI = () => {
    setShowFCI(!showFCI);
  };

  const FCINotSatisfied = () => {
    return (
      <>
        <span className={classes.showFCI} onClick={toggleFCI}>
          {showFCI &&
            <i className={'bi bi-chevron-down ' + classes.icon} />
          }
          {!showFCI &&
            <i className={'bi bi-chevron-right ' + classes.icon} />
          }
          {t('label.fciStatementsNotSatisfiedForAllAnimals')}
        </span>

        {showFCI &&
          <div className={classes.fciSummary}>
            {fciData.unfitAnimals &&
              <p className={classes.totalAnimals}>
                {fciData.unfitAnimals.length} {t('label.of')}{' '}
                {numberBeingMoved} {t('label.animals')}
              </p>
            }
            {fciData.withdrawalPeriod &&
              <>
                <span className={classes.title}>{t('label.withdrawalPeriod')}</span>
                <p>{fciData.withdrawalPeriod.name}</p>
              </>
            }
            {fciData.nonComplianceReason &&
              <>
                <span className={classes.title}>{t('label.reason')}</span>
                <p>{fciData?.nonComplianceReason ? he.decode(fciData.nonComplianceReason) : ''}</p>
              </>
            }
            {fciData.holdingRestrictions &&
              <>
                <span className={classes.title}>{t('label.holdingRestrictions')}</span>
                <p>{fciData?.holdingRestrictions ? he.decode(fciData.holdingRestrictions) : ''}</p>
              </>
            }
          </div>
        }
      </>
    );
  };

  const FCISatisfied = () => {
    return <span>{t('label.allAnimalsSatisfyFciStatements')}</span>;
  };

  const FCINotSupplied = () => {
    return <span>{t('label.noFciInformationRecorded')}</span>;
  };

  return (
    <>
      {ready &&
        <div className={classes.summaryRow + ' ' + classes.noBorder}>
          <div className={classes.value}>
            {(!fciData || !helpers.option.fci.doRecord(fciData.recordFCI)) &&
              <FCINotSupplied />
            }
            {fciData && helpers.option.fci.doRecord(fciData.recordFCI) && (helpers.option.animals.doSatisfy(fciData.animalsSatisfy) || !animalsData?.animalsBeingMoved) &&
              <FCISatisfied />
            }
            {fciData && helpers.option.fci.doRecord(fciData.recordFCI) && !helpers.option.animals.doSatisfy(fciData.animalsSatisfy) && animalsData?.animalsBeingMoved &&
              <FCINotSatisfied />
            }
          </div>
          {onClick &&
            <div className={classes.actions}>
              <Button
                buttonType="link"
                id="fciSummaryButton"
                label="label.edit"
                onClick={onClick}
              />
            </div>
          }
        </div>
      }
    </>
  );
};

FciSummary.propTypes = {
  animalsData: PropTypes.object,
  fciData: PropTypes.object,
  numberBeingMoved: PropTypes.number,
  onClick: PropTypes.func
};

export default FciSummary;
