import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search/dist/cjs';

const DropdownGender = ({
  all,
  onChange,
  unknown,
  value
}) => {
  const { ready, t } = useTranslation();

  const getItemList = () => {
    const theArray = [];

    if (all) {
      theArray.push({ value: 'all', name: t('dropdowns.gender.allGenders') });
    }
    if (unknown) {
      theArray.push({ value: '-', name: t('dropdowns.gender.unknown') });
    }
    theArray.push({ value: constants.option.gender.male, name: t('dropdowns.gender.male') });
    theArray.push({ value: constants.option.gender.female, name: t('dropdowns.gender.female') });

    return theArray;
  };

  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    setSelectedValues(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {ready &&
        <SelectSearch
          className="select-search"
          closeOnSelect={false}
          id="genderDropdown"
          inputId="genderDropdown"
          multiple
          name="gender"
          onChange={onChange}
          onFocus={() => helpers.resetDropdownFocusState()}
          options={getItemList()}
          placeholder={t('dropdowns.gender.label')}
          printOptions="on-focus"
          value={selectedValues}
        />
      }
    </>
  );
};

DropdownGender.propTypes = {
  all: PropTypes.bool,
  onChange: PropTypes.func,
  unknown: PropTypes.bool,
  value: PropTypes.array
};

export default DropdownGender;
