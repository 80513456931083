import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Routing from 'routing';
import Linker from 'components/base/linker/linker';
import ManualTagEntry from 'components/manualTagEntry/manualTagEntry';
import PropTypes from 'prop-types';
import Button from 'components/base/button/button';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import helpers from 'services/helpers';
import storeService from 'services/storeService';
import Table from 'components/table/table';
import { tableColumns } from 'services/tableColumns';
import constants from 'services/constants';

const AbattoirManualTags = ({ setModal }) => {

  const { ready, t } = useTranslation(),
    history = useHistory(),
    [pending, setPending] = React.useState(false),
    [disableSearchBtn, setDisableSearchBtn] = useState(true),
    [animalTagNumber, setAnimalTagNumber] = useState([]),
    [isTagLimtExeed, setIsTagLimtExeed] = useState(false),
    species = storeService.session.get.species() || {};
  const tagNumber = animalTagNumber.map((animal) => {
    return animal.tagNumber;
  });

  // Pagination for the table
  const [paginationParams, setPaginationParams] = useState({
    batch: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        totalLabel: 'label.totalBatches'
      }
    },
    individual: constants.pagination.defaultParams
  });

  const speciesId = species ? species.id : '';
  const handleChanges = {
    // Add Animal tags
    addAnimalTags: (tags) => {
      const uniqueNewTags = tags.filter((tag) => !animalTagNumber.find((tagItem) => tagItem.tagNumber === tag.tagNumber));
      const newAnimalTagNumbers = [
        ...animalTagNumber,
        ...uniqueNewTags.map((item) => ({
          tagNumber: helpers.tag.trim(item.tagNumber),
          added: true,
          valid: item.valid
        }))
      ];
      setAnimalTagNumber(newAnimalTagNumbers);
      storeService.session.set.abattoirSelectedTagDetails(newAnimalTagNumbers);
    },

    // Remove Animal tag(s) from the table
    removeAnimalTag: (tag) => {
      setAnimalTagNumber((prevState) => {
        const newTags = helpers.tag.remove(prevState, tag);
        if (newTags.length === 0) {
          storeService.session.set.abattoirSelectedTagDetails([]);
        } else {
          storeService.session.set.abattoirSelectedTagDetails(newTags);
        }
        return newTags;
      });
    },

    // Remove All Animal tags from the table
    removeAllAnimalTags: () => {
      setAnimalTagNumber([]);
      storeService.session.set.abattoirSelectedTagDetails([]);
    },

    // Remove All invalid Animal tags from the table
    removeAllInvalid: () => {
      setAnimalTagNumber(helpers.animal.removeAllInvalid(animalTagNumber));
    }

  };

  // Search button OnClick functions
  const routeToResult = () => {
    storeService.session.remove.abattoirTagDetails();
    storeService.session.set.abattoirTagDetails({ species: species.name, deviceIdentifiers: tagNumber, prevPage: 'manualTag' });
    history.push(Routing.abattoirAttestationTagResult);
  };

  // Store selected tags in session and restore when page loads
  useEffect(() => {
    const selectedAnimalSessionData = storeService.session.get.abattoirSelectedTagDetails();
    if (selectedAnimalSessionData) {
      setAnimalTagNumber(selectedAnimalSessionData);
    }
  }, []);

  // Set pagination for the table
  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      individual: {
        ...prevState.individual,
        [constants.pagination.bottom]: {
          ...prevState.individual[constants.pagination.bottom],
          total: animalTagNumber?.length
        }
      }
    }));
  }, [animalTagNumber]);

  // Search button validation
  useEffect(() => {
    setIsTagLimtExeed(false);
    const status =
      !pending &&
      (
        (
          animalTagNumber?.length > 0 && animalTagNumber?.length <= constants.abattoirTag.maxTagLimit &&
          !animalTagNumber.some((tag) => helpers.tag.isInvalid(tag))
        )
      );
    setDisableSearchBtn(!status);

    if (animalTagNumber?.length > constants.abattoirTag.maxTagLimit) {
      setIsTagLimtExeed(true);
    }
  }, [pending, animalTagNumber]);

  return (
    <>
      {ready &&
        <>
          <h1 className="h1">{t('abattoirAttestation.vetHeading')}</h1>
          <div >
            <Linker
              label="button.back"
              linkType="back"
              to={Routing.abattoirAttestation}
            />
          </div>
          <h3 className="h3">{t('abattoirAttestation.vetTitle1')}</h3>
          <fieldset role="group">
            <div className="section">
              <ManualTagEntry
                addAnimals={handleChanges.addAnimalTags}
                animalsToChange={animalTagNumber}
                notes={t('abattoirAttestation.maxRecordAllowedNotesManual')}
                pending={pending}
                setModal={setModal}
                setPending={setPending}
                species={species}
                title="movements.animal-selection.manual-tag-entry-heading"
                tooltip="tooltips.movements.manualTagEntry"
                type={constants.option.movement.newMovement}
              />
            </div>
            {isTagLimtExeed &&
              <ErrorLabel
                label="abattoirAttestation.maxTagLimitErrorMessage"
                setModal={setModal}
              />
            }
            {animalTagNumber?.length > 0 &&
              <>
                {helpers.tag.hasNonEidTagsSheep(speciesId, animalTagNumber) &&
                  <ErrorLabel
                    isWarning={true}
                    label="formatExamples.tagBatchNonEidError"
                    modalMessage={helpers.tag.getFormatExamples(speciesId).description}
                    modalTitle={helpers.tag.getFormatExamples(speciesId).title}
                    setModal={setModal}
                  />
                }
                {animalTagNumber.some((tag) => helpers.tag.isInvalid(tag)) &&
                  <ErrorLabel
                    label="formatExamples.tagBatchError"
                    modalMessage={helpers.tag.getFormatExamples(speciesId).description}
                    modalTitle={helpers.tag.getFormatExamples(speciesId).title}
                    setModal={setModal}
                  />
                }
                <div className="section">
                  <Table
                    clearAll={handleChanges.removeAllAnimalTags}
                    columns={tableColumns.vetAbattoirManualTag.individual({ actionFunction: handleChanges.removeAnimalTag, sortable: true })}
                    data={animalTagNumber}
                    dataProvided={true}
                    paginationParams={paginationParams.individual}
                    removeAllInvalid={handleChanges.removeAllInvalid}
                    setData={setAnimalTagNumber}
                    setModal={setModal}
                    title={{
                      title: 'table.title.animalsEntered',
                      data: [
                        animalTagNumber?.length
                      ]
                    }}
                  />
                </div>
              </>
            }
          </fieldset>

          <div >
            <Button
              buttonType="primary"
              disabled={disableSearchBtn}
              label="button.search"
              onClick={routeToResult}
            />
          </div>
        </>
      }
    </>
  );
};

AbattoirManualTags.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default AbattoirManualTags;
