import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router-dom';
import { Prompt } from 'react-router';
import { bffStatus } from 'services/bff';
import userRoleType from 'services/userRoleType';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import helpers from 'services/helpers';
import Confirmation from 'components/base/confirmation/confirmation';
import MissingAndFoundStep1 from 'frontEnd/deaths/missingAndFound/step1/missingAndFoundStep1';
import Summary from 'frontEnd/deaths/summary/summary';
import TabsNavigational from 'components/base/tabsNavigational/tabsNavigational';
import constants from 'services/constants';

const MissingAndFound = ({
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionMissingAndFoundData = storeService.session.get.missingAndFound();
  const sessionDataChanged = storeService.session.get.dataChanged();
  const role = storeService.session.get.permissions();
  const holding = storeService.session.get.holding();

  const [missingOrFound, setMissingOrFound] = useState(sessionMissingAndFoundData ? sessionMissingAndFoundData.reportType : null);
  const [missingOrFoundData, setMissingOrFoundData] = useState(sessionMissingAndFoundData ? sessionMissingAndFoundData : null);
  const [dataChanged, setDataChanged] = React.useState(sessionDataChanged ? sessionDataChanged : false);
  const sessionSpecies = storeService.session.get.species();
  const speciesId = sessionSpecies ? sessionSpecies.id : '';

  const confirmation = {
    viewSummary: (requestId, requestType) => ({
      id: 'viewSummary',
      label: 'button.viewSummary',
      onClick: () => {
        setPermission(userRoleType.GENERIC);
        storeService.session.set.permissions(userRoleType.GENERIC);
        storeService.session.remove.holding();

        setTimeout(() => {
          history.push({
            pathname: Routing.activityDetails + requestId,
            state: {
              data: {
                requestId,
                requestType,
                current: {
                  returnUrl: permissions.isKeeper() ? Routing.missingFound : Routing.userActivity,
                  permissions: role,
                  holding
                }
              }
            }
          });
        }, [100]);
      }
    })
  };

  const stepPicker = (pathname) => {
    switch (pathname) {
      case Routing.missingFoundStep1:
      default: {
        storeService.session.removeAll.confirm();

        return (
          <TabsNavigational>
            <div id={constants.tabs.deaths} label="label.deaths" url={Routing.deaths} />
            <div active id={constants.tabs.missingAndFound} label="label.missing&Found">
              <MissingAndFoundStep1
                missingOrFound={missingOrFound}
                missingOrFoundData={missingOrFoundData}
                setDataChanged={setDataChanged}
                setMissingOrFound={setMissingOrFound}
                setMissingOrFoundData={setMissingOrFoundData}
                setModal={setModal}
              />
            </div>
          </TabsNavigational>
        );
      }

      case Routing.missingFoundSummary: {
        if (!missingOrFoundData) {
          return <Redirect to={Routing.missingFoundStep1} />;
        }

        return (
          <Summary
            currentHolding={holding}
            missingOrFound={missingOrFound}
            missingOrFoundData={missingOrFoundData}
            setData={setMissingOrFoundData}
            setDataChanged={setDataChanged}
            setMissingOrFound={setMissingOrFound}
            setModal={setModal}
          />
        );
      }

      case Routing.missingConfirm:
      case Routing.foundConfirm: {
        const requestId = storeService.session.get.confirmRequestId();
        const pollingStatus = storeService.session.get.confirmPollingStatus();
        const pollingErrors = storeService.session.get.confirmPollingErrors();
        const pollingWarnings = storeService.session.get.confirmPollingWarnings();
        const movementRef = storeService.session.get.confirmMovementReference();

        if (!requestId) {
          return <Redirect to={Routing.missingFoundStep1} />;
        }

        return (
          <Confirmation
            buttons={[
              requestId && !bffStatus.isClaPending(pollingStatus) ? confirmation.viewSummary(requestId, constants.option.requestType.movement) : null
            ].filter((item) => item)}
            confirm={{
              id: movementRef ? movementRef : requestId,
              label: movementRef ? 'label.movementRef' : 'label.reference'
            }}
            errors={pollingErrors}
            isUnamendable={true}
            label={pathname === Routing.missingConfirm ? 'label.missing' : 'label.found'}
            setModal={setModal}
            status={pollingStatus}
            warnings={pollingWarnings}
          />
        );
      }
    }
  };

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.missingFound.length) !== Routing.missingFound) {
        storeService.session.remove.dataChanged();
        storeService.session.removeAll.confirm();
        storeService.session.removeAll.missingAndFound();
        storeService.session.removeAll.searchResults();
        storeService.session.removeAll.tableFilters();
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(Routing.missingFound);
      }
    };
  }, [history]);

  const summaryDescription = ((helpers.option.reportType.isMissing(missingOrFound)) ? 'deaths.missingSummaryDesc' : 'deaths.foundSummaryDesc') + speciesId;
  const title = (missingOrFound === null) ? 'menu.missing&Found'
    : (helpers.option.reportType.isMissing(missingOrFound)) ? 'menu.missing' : 'menu.found';

  return (
    <>
      {ready &&
        <>
          <Prompt
            message={(params) => {
              return (!storeService.session.get.idle() && dataChanged && (params.pathname.substring(0, Routing.missingFound.length) !== Routing.missingFound || params.pathname === Routing.missingFound)) ? t('warning.unsavedChanges2') : true;
            }}
          />
          <h1 className="h1">{t(title)}</h1>
          {location.pathname === Routing.missingFoundSummary && <p className="p">{t(summaryDescription)}</p>}
          {stepPicker(location.pathname)}
        </>
      }
    </>
  );
};

MissingAndFound.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired
};

export default MissingAndFound;
