import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { bffStatus } from 'services/bff';
import classes from 'components/modalAddAnimalsToHolding/modalAddAnimalsToHolding.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import navigation from 'services/navigation';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Confirmation from 'components/base/confirmation/confirmation';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FileUpload from 'components/base/fileUpload/fileUpload';
import InputDate from 'components/base/inputDate/inputDate';
import Table from 'components/table/table';

const ModalAddAnimalsToHolding = ({
  addDate,
  addDateError,
  clearAll,
  columns,
  confirmReUpload,
  data,
  polling,
  removeAllInvalid,
  requestId,
  setAddDate,
  setAddDateError,
  setData,
  setModal,
  setPermission,
  setUploadPending,
  showConfirmation,
  species,
  submitPending,
  upload,
  uploadPending
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const dataHasInvalidTags = data.some((tag) => helpers.tag.isInvalidExtended(tag));
  const dataHasNonEidTags = data.some((tag) => helpers.tag.isNonEid(tag) && tag.added);

  const [infoOpen, setInfoOpen] = useState(true);

  const getDates = {
    reportMax: () => {
      const today = new Date();

      return helpers.date.formatYYYYMMDD(today);
    },

    reportMin: () => {
      const today = new Date();

      return helpers.date.formatYYYYMMDD(
        helpers.date.momentToJSDate(
          helpers.date.momentMinDate(
            helpers.date.subtractPeriod(helpers.date.formatYYYYMMDD(today), 7, constants.period.days),
            helpers.date.todayEndOfDay()
          )
        )
      );
    }
  };

  const getHelpAndGuidance = () => {
    const translations = [];

    for (let i = 1; i <= 39; i++) {
      translations.push('label.addAnimalsToHoldingHelpAndGuidance-content.content-' + i);
    }

    return [
      ...translations.map((line) => t(line)),
      ...[
        t('label.addAnimalsToHoldingHelpAndGuidance-content.content-40'),
        t(helpers.tag.getFormatExamples(species.id).description)
      ],
      ...[
        t('label.addAnimalsToHoldingHelpAndGuidance-content.content-41'),
        '<ul>',
        ...storeService.session.get.breedsAll()[species.id].map((breed) => '<li>' + breed.name + '</li>'),
        '</ul>'
      ].join('')
    ].join('');
  };

  const handleChanges = {
    reportDate: (date) => {
      setAddDate(date);
    }
  };

  const confirmation = {
    button: {
      viewSummary: (requestId, requestType) => ({
        id: 'viewSummary',
        label: 'button.viewSummary',
        onClick: () => {
          setPermission(userRoleType.GENERIC);
          storeService.session.set.permissions(userRoleType.GENERIC);
          storeService.session.remove.holding();

          setTimeout(() => {
            history.push({
              pathname: Routing.activityDetails + requestId,
              state: {
                data: {
                  requestId,
                  requestType
                }
              }
            });
          }, [100]);
        }
      })
    }
  };

  const getConfirmation = {
    content: () => {
      switch (polling?.status) {
        case constants.status.completed_error:
          return 'label.addAnimalsPartialErrorContent';

        case constants.status.completed_warning:
          return 'label.uploadAnimalsPartialErrorContent';

        default:
        case constants.status.success:
          return null;
      }
    },

    label: () => {
      switch (polling?.status) {
        case constants?.status.completed_error:
          return 'label.addAnimalsToHoldingStatus-CompletedError';

        case constants.status.completed_warning:
          return 'label.addAnimalsToHoldingStatus-CompletedWarning';

        default:
        case constants.status.success:
          return 'label.addAnimalsToHoldingStatus-Success';
      }
    }
  };

  const paginationParams = constants.pagination.defaultParams;
  paginationParams[constants.pagination.bottom].total = data?.length;

  return (
    <>
      {ready &&
        <>
          <div className={classes.content}>
            {showConfirmation &&
              <Confirmation
                buttons={[
                  requestId && !bffStatus.isClaPending(polling.status) ? confirmation.button.viewSummary(requestId, constants.option.requestType.animalsAdded) : null
                ].filter((item) => item)}
                confirm={{
                  id: polling?.requestId,
                  label: 'label.reference',
                  content: getConfirmation.content(),
                  onClick: () => {
                    setPermission(userRoleType.GENERIC);
                    storeService.session.set.permissions(userRoleType.GENERIC);
                    storeService.session.remove.holding();
                    history.push(navigation.userActivity());
                  }
                }}
                errors={polling?.errors}
                isUnamendable={true}
                label={getConfirmation.label()}
                setModal={setModal}
                status={polling?.status}
                warnings={polling?.warnings}
              />
            }
            {!showConfirmation &&
              <>
                {submitPending &&
                  <Confirmation
                    label="label.submissionPending"
                    setModal={setModal}
                    status={constants.status.pending}
                  />
                }
                {!submitPending &&
                  <>
                    <AboutPanel
                      open={infoOpen}
                      styleContent={false}
                      title="label.information"
                    >
                      <ErrorLabel
                        isWarning={true}
                        label="label.addAnimalsToHoldingNotes"
                      />
                    </AboutPanel>

                    <div className="section date">
                      <InputDate
                        blockStyling={true}
                        disabled={data.length > 0}
                        dontValidate={true}
                        error={addDateError}
                        id="onHoldingDate"
                        labelText="label.dateAnimalsSeenOnHolding"
                        maxDate={getDates.reportMax()}
                        minDate={getDates.reportMin()}
                        onChange={(event) => handleChanges.reportDate(event.target.value)}
                        setError={setAddDateError}
                        value={addDate}
                        yearLength={4}
                      />
                    </div>

                    {addDate &&
                      <div className={classes.input}>
                        <FileUpload
                          acceptExtendedCsv={true}
                          addDate={addDate}
                          confirmReUpload={confirmReUpload}
                          description={[
                            'label.upload.uploadDescLink-AddAnimalsToHolding'
                          ]}
                          hideHint={true}
                          hideLabel={true}
                          hideTooltip={true}
                          modalMessage={getHelpAndGuidance()}
                          modalTitle="label.addAnimalsToHoldingHelpAndGuidance-title"
                          onUpload={() => setInfoOpen(false)}
                          pending={uploadPending}
                          setAnimalsToChange={upload}
                          setModal={setModal}
                          setPending={setUploadPending}
                          species={species}
                        />
                      </div>
                    }
                    {data && dataHasInvalidTags &&
                      <ErrorLabel
                        label="formatExamples.tagBatchAddAnimalsToHolding"
                        modalMessage={getHelpAndGuidance()}
                        modalTitle="label.addAnimalsToHoldingHelpAndGuidance-title"
                        setModal={setModal}
                      />
                    }
                    {data && !dataHasInvalidTags && dataHasNonEidTags &&
                      <ErrorLabel
                        isWarning={true}
                        label="formatExamples.tagBatchNonEidError"
                        modalMessage={helpers.tag.getFormatExamples(species.id).description}
                        modalTitle={helpers.tag.getFormatExamples(species.id).title}
                        setModal={setModal}
                      />
                    }

                    {!uploadPending && data.length > 0 &&
                      <div className={classes.output}>
                        <div className={classes.tableContainer}>
                          <Table
                            clearAll={clearAll}
                            columns={columns}
                            data={data}
                            dataProvided={true}
                            initialSortBy="tagNumber"
                            initialSortDirection={constants.sorting.ascending}
                            paginationParams={paginationParams}
                            pending={false}
                            removeAllInvalid={removeAllInvalid}
                            renderTable={true}
                            setData={setData}
                            setModal={setModal}
                          />
                        </div>
                      </div>
                    }
                  </>
                }
              </>
            }
          </div>
        </>
      }
    </>
  );
};

ModalAddAnimalsToHolding.propTypes = {
  addDate: PropTypes.string,
  addDateError: PropTypes.string.isRequired,
  clearAll: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  confirmReUpload: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  polling: PropTypes.exact({
    requestId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    status: PropTypes.string,
    errors: PropTypes.array,
    warnings: PropTypes.array
  }),
  removeAllInvalid: PropTypes.func.isRequired,
  requestId: PropTypes.string,
  setAddDate: PropTypes.func.isRequired,
  setAddDateError: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setUploadPending: PropTypes.func.isRequired,
  showConfirmation: PropTypes.bool.isRequired,
  species: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    notifications: PropTypes.number,
    errorFound: PropTypes.number
  }),
  submitPending: PropTypes.bool.isRequired,
  upload: PropTypes.func.isRequired,
  uploadPending: PropTypes.bool.isRequired
};

export default ModalAddAnimalsToHolding;
