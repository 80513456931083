import React, { useEffect, useState } from 'react';
import constants from 'services/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';

const DropdownMonth = ({
  error,
  label,
  name,
  onChange,
  optional,
  value
}) => {
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    const theArray = [];
    const now = moment().month(0);
    for (let i = 0; i < 12; i++) {
      theArray.push({ value: now.format('MMMM'), name: now.format('MMMM') });
      now.add(1, constants.period.months);
    }
    setItemList(theArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownSearch
      error={error}
      id="monthDropdown"
      label={label}
      name={name}
      onChange={onChange}
      optional={optional}
      optionList={itemList}
      placeholder="prompt.pleaseSelect"
      value={value}
    />
  );
};

DropdownMonth.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  value: PropTypes.string
};

export default DropdownMonth;
