import bff from 'services/bff';
import axios from 'axios';

const vetModuleHelper = {
  fetchVetDetails: (cphNo, isOnlyValidattestation) => {
    const source = axios.CancelToken.source();
    const api = '/getVetAttestationsDetails';
    return bff
      .get(api, {
        cancelToken: source.token,
        params: {
          cphNo,
          showOnlyValidAttestation: isOnlyValidattestation
        }
      });
  },
  fetchAbattoirVetAttestationList: (species, deviceIdentifiers) => {
    const source = axios.CancelToken.source();
    const api = '/speciesIdAndDeviceIdentifiers';
    return bff
      .get(api, {
        cancelToken: source.token,
        params: {
          species,
          deviceIdentifiers
        }
      });
  }
};

export default vetModuleHelper;
