import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import classes from 'components/table/filters/filtersGatheringSearch/filtersGatheringSearch.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { initialSorting, tableColumns } from 'services/tableColumns';
import Button from 'components/base/button/button';
import CPHField from 'components/base/cphField/cphField';
import DateRange from 'components/base/dateRange/dateRange';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Tabs from 'components/base/tabs/tabs';

const maxMovementRefLength = parseInt(config.WIDTH_MOVEMENT_REF);

const GatheringSearchFilters = ({
  loadPending,
  setData,
  setRenderTable,
  setResultsLabel,
  setTableParams,
  storeFilters,
  tableParams
}) => {
  const { ready, t } = useTranslation();

  const sessionCph = storeService.session.get.holding().value;
  const sessionSpecies = storeService.session.get.species();

  const now = helpers.date.now();
  const today = helpers.date.formatYYYYMMDD(now);
  const sevenDaysAgo = helpers.date.formatYYYYMMDD(helpers.date.subtractPeriod(now, 7, constants.period.days));

  const [filterActiveTabId, setFilterActiveTabId] = React.useState(tableParams?.filters?.activeTabId ? tableParams.filters.activeTabId : constants.tabs.movementRange);
  const [filterMovementType, setFilterMovementType] = React.useState(tableParams?.filters?.movementType ? tableParams.filters.movementType : (permissions.isAbattoir() ? constants.option.movement.on : ''));
  const [filterCphNumber, setFilterCphNumber] = React.useState(tableParams?.filters?.cphNumber ? tableParams.filters.cphNumber : '');
  const [filterMovementRef, setFilterMovementRef] = React.useState(tableParams?.filters?.movementRef ? tableParams.filters.movementRef : '');
  const [filterMovementStatus, setFilterMovementStatus] = React.useState(tableParams?.filters?.movementStatus ? tableParams.filters.movementStatus : null);
  const [filterFromDate, setFilterFromDate] = React.useState(tableParams?.filters?.fromDate ? tableParams.filters.fromDate : sevenDaysAgo);
  const [filterToDate, setFilterToDate] = React.useState(tableParams?.filters?.toDate ? tableParams.filters.toDate : today);
  const [fromDateError, setFromDateError] = React.useState('');
  const [toDateError, setToDateError] = React.useState('');
  const [searchDisabled, setSearchDisabled] = React.useState(true);
  const [resetTable, setResetTable] = React.useState(tableParams?.filters?.newRequest ?? false);

  const handleChanges = {
    cphNumber: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setFilterCphNumber(value);

      if (value.length === 0) {
        setTableParams((prevState) => {
          // eslint-disable-next-line no-unused-vars
          const { cphNumber, ...prevStateFilters } = prevState.filters;

          return {
            ...prevState,
            filters: prevStateFilters
          };
        });

        // eslint-disable-next-line no-unused-vars
        const { cphNumber, ...prevStoredFilters } = storedFilters;

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...prevStoredFilters
        });
      } else {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            cphNumber: value
          }
        }));

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          cphNumber: value
        });
      }
    },

    date: (which, value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      if (helpers.flags.isFrom(which)) {
        setFilterFromDate(value);

        if (value.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { fromDate, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { fromDate, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              fromDate: value
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            fromDate: value
          });
        }
      } else if (helpers.flags.isTo(which)) {
        setFilterToDate(value);

        if (value.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { toDate, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { toDate, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              toDate: value
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            toDate: value
          });
        }
      }
    },

    movementRef: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      let result = '';
      if (value) {
        const reference = value.replace(/[^0-9]/g, '');
        if (reference) {
          result = reference.substring(0, maxMovementRefLength);
        }
      }

      setFilterMovementRef(value);

      if (result.length === 0) {
        setTableParams((prevState) => {
          // eslint-disable-next-line no-unused-vars
          const { movementRef, ...prevStateFilters } = prevState.filters;

          return {
            ...prevState,
            filters: prevStateFilters
          };
        });

        // eslint-disable-next-line no-unused-vars
        const { movementRef, ...prevStoredFilters } = storedFilters;

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...prevStoredFilters
        });
      } else {
        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          movementRef: result
        });
      }
    },

    movementStatus: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setFilterMovementStatus(value);

      if (value.length === 0) {
        setTableParams((prevState) => {
          // eslint-disable-next-line no-unused-vars
          const { movementStatus, ...prevStateFilters } = prevState.filters;

          return {
            ...prevState,
            filters: prevStateFilters
          };
        });

        // eslint-disable-next-line no-unused-vars
        const { movementStatus, ...prevStoredFilters } = storedFilters;

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...prevStoredFilters
        });
      } else {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            movementStatus: value
          }
        }));

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          movementStatus: value
        });
      }
    },

    movementType: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setFilterMovementType(value);

      if (value.length === 0) {
        setTableParams((prevState) => {
          // eslint-disable-next-line no-unused-vars
          const { movementType, ...prevStateFilters } = prevState.filters;

          return {
            ...prevState,
            filters: prevStateFilters
          };
        });

        // eslint-disable-next-line no-unused-vars
        const { movementType, ...prevStoredFilters } = storedFilters;

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...prevStoredFilters
        });
      } else {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            movementType: value
          }
        }));

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          movementType: value
        });
      }
    },

    tab: (value, firstSet) => {
      if (!firstSet) {
        resetForm();
      }

      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setFilterActiveTabId(value);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          tab: value
        },
        request: {
          ...prevState.request,
          url: helpers.tab.isMovementRange(value) ? '/gatheringMovements' : '/movementDetails'
        }
      }));

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...storedFilters,
        activeTabId: value,
        pageIndex: 0
      });
    }
  };

  const tab = {
    byDateAndCph: () => {
      return (
        <div id={constants.tabs.movementRange} label={t('label.searchByDateAndCph')}>
          <div className="section">
            <RadioButtons
              disabled={[
                permissions.isAbattoir() ? constants.option.movement.off : null
              ]}
              ids={[
                constants.option.movement.on,
                constants.option.movement.off
              ]}
              inline={true}
              label="radioButtons.movementType.label"
              name="movementType"
              onChange={(event) => handleChanges.movementType(event.target.value)}
              suppressHint={true}
              suppressLabel={true}
              value={filterMovementType}
            />
          </div>

          <div className={'section ' + classes.dateRange}>
            <DateRange
              allowTomorrow={helpers.option.movement.isOff(filterMovementType)}
              fromDate={filterFromDate}
              fromDateError={fromDateError}
              loadPending={loadPending}
              maxRange={{
                value: 1,
                unit: constants.period.years
              }}
              setFromDate={(date) => handleChanges.date(constants.flags.from, date)}
              setFromDateError={setFromDateError}
              setToDate={(date) => handleChanges.date(constants.flags.to, date)}
              setToDateError={setToDateError}
              toDate={filterToDate}
              toDateError={toDateError}
            />
          </div>

          <div className={'section ' + classes.otherFields}>
            <RadioButtons
              ids={[
                constants.status.unconfirmed,
                constants.status.reported
              ]}
              inline={true}
              legendClassNames={['nonBold']}
              name="movementStatus"
              onChange={(event) => handleChanges.movementStatus(event.target.value)}
              tooltip="radioButtons.movementStatus.tooltip"
              value={filterMovementStatus}
            />
          </div>

          <div className={'section ' + classes.otherFields}>
            <div className={classes.field}>
              <CPHField
                cph={filterCphNumber}
                id="cphNumber"
                isFormGroup={true}
                label="label.searchByCPH"
                optional={true}
                setCph={handleChanges.cphNumber}
                width="quarter"
              />
            </div>
          </div>
        </div>
      );
    },

    byMovementRef: () => {
      return (
        <div id={constants.tabs.movementRef} label={t('label.searchMovementRef')} >
          <div className={'section ' + classes.movementRef}>
            <FieldEntry
              id="movementRef"
              labelPosition={constants.labelPosition.above}
              labelText="label.movementRef"
              maxLength={config.WIDTH_MOVEMENT_REF}
              name="movementRef"
              onChange={(event) => handleChanges.movementRef(event.target.value.trim())}
              value={filterMovementRef}
              width={config.WIDTH_MOVEMENT_ID}
            />
          </div>
        </div>
      );
    }
  };

  const resetForm = () => {
    const validKeys = ['pageIndex', 'pageSize'];
    const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

    storeService.session.removeAll.searchResults();
    if (storedFilters) {
      Object.keys(storedFilters).forEach((key) => {
        if (!validKeys.includes(key)) {
          delete storedFilters[key];
        }
      });
    }
    storeService.session.set[`tableFilters${storeFilters}`]({
      ...storedFilters
    });

    setFilterMovementType(permissions.isAbattoir() ? constants.option.movement.on : '');
    setFilterFromDate(sevenDaysAgo);
    setFilterToDate(today);
    setFromDateError('');
    setToDateError('');
    setFilterMovementStatus(null);
    setFilterCphNumber('');
    setFilterMovementRef('');

    setSearchDisabled(true);
    setRenderTable(false);
    setData([]);
  };

  const runRequest = () => {
    if (!filterFromDate) {
      setFilterFromDate(sevenDaysAgo);
    }
    if (!filterToDate) {
      setFilterToDate(today);
    }

    storeService.session.set[`tableFilters${storeFilters}PageIndex`](0);
    storeService.session.remove[`tableFilters${storeFilters}SortBy`]();
    storeService.session.removeAll.searchResults();
    setData([]);

    if (helpers.tab.isMovementRange(filterActiveTabId)) {
      if (filterMovementStatus === 'unconfirmed') {
        setResultsLabel('label.unconfirmedMovement');
      } else if (filterMovementStatus === 'reported') {
        setResultsLabel('label.reportedMovement');
      }

      setTableParams((prevState) => ({
        ...prevState,
        columns: tableColumns.gatheringSearch.byDateAndCph({ activeTabId: filterActiveTabId, movementType: filterMovementType, movementStatus: filterMovementStatus }),
        filters: {
          activeTabId: filterActiveTabId,
          movementType: filterMovementType,
          cphNumber: filterCphNumber,
          movementStatus: filterMovementStatus,
          fromDate: filterFromDate,
          toDate: filterToDate
        },
        page: {
          ...prevState.page,
          index: 0
        },
        request: {
          ...prevState.request,
          params: {
            ...prevState.request.params,
            speciesName: sessionSpecies.name,
            speciesId: sessionSpecies.id,
            cph: sessionCph
          }
        },
        sort: {
          by: filterMovementStatus === 'unconfirmed' ? initialSorting.gatheringSearch.byDateAndCph.unconfirmed.accessor : initialSorting.gatheringSearch.byDateAndCph.reported.accessor,
          direction: filterMovementStatus === 'unconfirmed' ? initialSorting.gatheringSearch.byDateAndCph.unconfirmed.direction : initialSorting.gatheringSearch.byDateAndCph.reported.direction
        }
      }));

      storeService.session.set[`tableFilters${storeFilters}SortBy`](filterMovementStatus === 'unconfirmed' ? initialSorting.gatheringSearch.byDateAndCph.unconfirmed.accessor : initialSorting.gatheringSearch.byDateAndCph.reported.accessor);
      storeService.session.set[`tableFilters${storeFilters}SortDirection`](filterMovementStatus === 'unconfirmed' ? initialSorting.gatheringSearch.byDateAndCph.unconfirmed.direction : initialSorting.gatheringSearch.byDateAndCph.reported.direction);
    } else {
      setTableParams((prevState) => ({
        ...prevState,
        columns: tableColumns.gatheringSearch.byMovementReference({ activeTabId: filterActiveTabId, movementRef: filterMovementRef }),
        filters: {
          activeTabId: filterActiveTabId,
          documentRef: filterMovementRef
        },
        page: {
          ...prevState.page,
          index: 0
        },
        request: {
          ...prevState.request,
          params: {
            ...prevState.request.params,
            speciesId: sessionSpecies.id
          }
        },
        sort: {
          by: initialSorting.gatheringSearch.byMovementReference.accessor,
          direction: initialSorting.gatheringSearch.byMovementReference.direction
        }
      }));

      storeService.session.set[`tableFilters${storeFilters}SortBy`](initialSorting.gatheringSearch.byMovementReference.accessor);
      storeService.session.set[`tableFilters${storeFilters}SortDirection`](initialSorting.gatheringSearch.byMovementReference.direction);
    }
    setRenderTable(true);
  };

  useEffect(() => {
    if (resetTable) {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...storedFilters,
        newRequest: false
      });

      setResetTable(false);
      runRequest();
    }
    if (helpers.tab.isMovementRange(filterActiveTabId)) {
      if (loadPending || !filterMovementType || !filterMovementStatus || !filterFromDate || !filterToDate || fromDateError !== '' || toDateError !== '' || (filterCphNumber.length > 0 && filterCphNumber.length < config.LENGTH_HOLDING_CPH)) {
        setSearchDisabled(true);
      } else {
        setSearchDisabled(false);
      }
    } else if (helpers.tab.isMovementRef(filterActiveTabId)) {
      if (loadPending || !filterMovementRef) {
        setSearchDisabled(true);
      } else {
        setSearchDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadPending,
    filterActiveTabId,
    filterMovementType,
    filterMovementRef,
    filterMovementStatus,
    filterCphNumber,
    filterFromDate,
    filterToDate,
    fromDateError,
    toDateError
  ]);

  useEffect(() => {
    if (helpers.tab.isMovementRange(filterActiveTabId)) {
      setTableParams((prevState) => ({
        ...prevState,
        columns: tableColumns.gatheringSearch.byDateAndCph({ activeTabId: filterActiveTabId, movementType: filterMovementType, movementStatus: filterMovementStatus }),
        request: {
          ...prevState.request,
          params: {
            ...prevState.request.params,
            speciesName: sessionSpecies.name,
            speciesId: sessionSpecies.id,
            cph: sessionCph,
            cphNumber: filterCphNumber,
            movementType: filterMovementType,
            movementStatus: filterMovementStatus,
            fromDate: filterFromDate,
            toDate: filterToDate
          }
        }
      }));
    } else if (helpers.tab.isMovementRef(filterActiveTabId)) {
      setTableParams((prevState) => ({
        ...prevState,
        columns: tableColumns.gatheringSearch.byMovementReference({ activeTabId: filterActiveTabId, movementRef: filterMovementRef }),
        request: {
          ...prevState.request,
          params: {
            ...prevState.request.params,
            speciesName: sessionSpecies.name,
            documentRef: filterMovementRef
          }
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <>
          <h1 className="h1">{t('label.movementSearch')}</h1>
          <Tabs
            activeTabId={filterActiveTabId}
            setActiveTabId={handleChanges.tab}
          >
            {tab.byDateAndCph()}
            {tab.byMovementRef()}
          </Tabs>

          <div className={classes.actions}>
            <div className={classes.left}>
              <Button
                buttonType="primary"
                disabled={searchDisabled}
                label="button.search"
                onClick={runRequest}
              />
            </div>
            <div className={classes.right}>
              <Button
                buttonType="tertiary"
                disabled={loadPending}
                label="button.reset"
                onClick={resetForm}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

GatheringSearchFilters.propTypes = {
  loadPending: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  setRenderTable: PropTypes.func.isRequired,
  setResultsLabel: PropTypes.func.isRequired,
  setTableParams: PropTypes.func.isRequired,
  storeFilters: PropTypes.string.isRequired,
  tableParams: PropTypes.object.isRequired
};

export default GatheringSearchFilters;
