import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { bffStatus } from 'services/bff';
import classes from 'components/base/confirmation/confirmation.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import permissionsHelper from 'services/permissions';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import navigation from 'services/navigation';
import Button from 'components/base/button/button';
import Linker from 'components/base/linker/linker';
import PDFButton from 'components/pdfButton/pdfButton';
const sprintf = require('util').format;

const checkIcon = 'bi bi-check-circle ' + classes.icon;
const crossIcon = 'bi bi-x-circle ' + classes.icon;
const hourGlassIcon = 'bi bi-hourglass ' + classes.icon;

const displayHtml = (className, content) => <p className={className} dangerouslySetInnerHTML={{ __html: content }} />;

const clearSession = () => {
  storeService.session.removeAll.birth();
  storeService.session.removeAll.death();
  storeService.session.removeAll.missingAndFound();
  storeService.session.removeAll.movement();
  storeService.session.removeAll.tagApplication();
  storeService.session.removeAll.tagReplacement();
};

const hasReference = (confirm) => confirm && confirm.id && confirm.label;

const displayLinkContents = (label, onClick) => {
  const contentsMatch = label.match(new RegExp('<link>(.*)</link>'));

  if (contentsMatch) {
    const stringParts = label.split(contentsMatch[0]);

    return [
      stringParts[0],
      <Button
        buttonType="link"
        key="link"
        label={contentsMatch[1]}
        onClick={onClick}
      />,
      stringParts[1]
    ];
  }

  return [
    label
  ];
};

const Confirmation = ({
  buttons,
  confirm,
  content,
  errors,
  isUnamendable,
  label,
  links,
  setModal,
  specificErrorLabel,
  status,
  warnings
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const [isBackButtonClicked, setBackButtonPress] = React.useState(false);

  const onBackButtonEvent = (event) => {
    event.preventDefault();
    if (!isBackButtonClicked) {
      setBackButtonPress(true);
      window.location.href = navigation.home();
    } else {
      window.history.pushState(null, null, window.location.pathname);
      setBackButtonPress(false);
    }
  };

  const specificErrorCode = helpers.error.amendResponseError(errors);
  const isUndoMovementErrorCode = helpers.error.isUndoMovementErrorCode(errors);
  const isKeeper = permissionsHelper.isKeeper();

  const isCancellationPending =
    label.toLowerCase().includes('cancellation') &&
    (constants.status.pending === status ||
      constants.status.claPending === status);

  const isCancellationError =
    label.toLowerCase().includes('cancellation') &&
    constants.status.completed_error === status;

  const referenceNumber =
    label.toLowerCase().includes('movement') && confirm?.movementReference
      ? confirm?.movementReference
      : confirm?.id;

  if (!status ||
    bffStatus.isSuccess(status) ||
    (isKeeper && bffStatus.isCompletedError(status)) ||
    (isKeeper && bffStatus.isCompletedWarning(status)) ||
    bffStatus.isClaPending(status)
  ) {
    clearSession();
  }

  const display = {
    content: (label, onClick) => (
      <div className={classes.whatNow}>
        {onClick &&
          <p>
            {displayLinkContents(t(label), onClick).map((item) => item)}
          </p>
        }
        {!onClick && displayHtml(classes.parsedHtml, t(label))}
      </div>
    ),

    messages: (messages, className) => {
      if (specificErrorCode) {
        return displayHtml(classes.messages + ' ' + className,
          sprintf(t(specificErrorLabel ? specificErrorLabel : 'error.amendMovementError'), specificErrorCode));
      }

      const newMessages = helpers.error.consolidate(messages);

      return (
        <>
          {newMessages &&
            <ul className={classes.messages + ' ' + className}>
              {newMessages.map((error) => (
                <li key={error.code + error.message}>{error.code}{': '}{helpers.error.mapping(error.message)}</li>
              ))}
            </ul>
          }
        </>
      );
    },

    getReference: () => {
      if (confirm?.id && (confirm.id !== 'undefined' || confirm.movementReference !== 'undefined') && !(isCancellationError || isCancellationPending)) {
        let reference = null;
        if (confirm.label) {
          const content = confirm.label.includes('label.') ? t(confirm.label) : confirm.label;

          if (content.includes('%s') || content.includes('%d')) {
            reference = sprintf(content, referenceNumber);
          } else {
            reference = sprintf('%s %d', content, referenceNumber);
          }
        } else {
          reference = sprintf('%s %d', t('label.movementRef'), referenceNumber);
        }
        reference = ' - ' + reference;

        return confirm.onClick ? displayLinkContents(reference, confirm.onClick) : reference;
      }
      return null;
    },

    statusAndMovementRef: (withCheckIcon, labelWithReference, labelWithoutReference, statusClass, statusLabel) => {
      const reference = display.getReference();

      return (
        <div className={classes.title}>
          {withCheckIcon === true && (<i className={checkIcon} />)}
          {withCheckIcon === false && (<i className={crossIcon} />)}
          <span className={classes.bold}>{t(label)}<span className={classes.normal}>{reference}</span></span>
          {confirm?.contents?.length > 0 &&
            confirm.contents.map((content, index) => <p className={classes.normal} key={index}>{t(content)}</p>)
          }
          {((confirm?.id && confirm?.label) || status) && (!specificErrorCode) &&
            <div className={classes.text}>
              {statusLabel && statusClass && !(isCancellationError || isCancellationPending) &&
                <span className={classes.statusText + ' ' + statusClass}>{t(statusLabel)}</span>
              }
              {!statusLabel && status && withCheckIcon !== false &&
                <span className={classes.statusText + ' ' + classes.errorWarning}>{t('label.status-' + status)}</span>
              }
            </div>
          }
          {labelWithoutReference !== null &&
            displayHtml(classes.normal, t(reference ? labelWithReference : labelWithoutReference))
          }
        </div>
      );
    },

    status: {
      completedError: () => (
        <div className={classes.confirmation + ' ' + classes.error}>
          {display.statusAndMovementRef(false,
            confirm?.content ? confirm.content : 'label.movementPartialErrorContent',
            confirm?.content ? confirm.content : isCancellationError || isCancellationPending ? '' : null,
            classes.errorWarning,
            'label.confirm-Errors'
          )
          }
          {display.messages(errors, classes.error)}
          {confirm?.footer?.label
            ? display.content(confirm.footer.label, confirm.footer.onClick)
            : displayHtml(
              classes.normal,
              t(hasReference(confirm)
                ? isUnamendable
                  ? 'label.notAmendablePartialErrorFooter'
                  : isCancellationError || isCancellationPending
                    ? `${t(confirm.label)} ${referenceNumber} ${t('label.cancellationErrorFooter')}`
                    : (specificErrorCode !== '14104' && isUndoMovementErrorCode === '4076') ? 'label.movementPartialErrorCustomerSupport' : 'label.movementPartialErrorFooter'
                : 'label.movementErrorFooter'
              )
            )
          }
        </div>
      ),

      completedErrorAndWarning: () => (
        <div className={classes.confirmation + ' ' + classes.error}>
          {display.statusAndMovementRef(false,
            confirm?.content ? confirm.content : 'label.movementPartialErrorContent',
            confirm?.content ? confirm.content : isCancellationError || isCancellationPending ? '' : 'label.movementErrorContent',
            classes.errorWarning,
            'label.confirm-Errors'
          )
          }
          {display.messages(errors, classes.error)}

          <div className={classes.text}>
            <span className={classes.statusText + ' ' + classes.errorWarning}>{t('label.confirm-Warnings')}</span>
          </div>
          {displayHtml(classes.errorWarning, t('label.movementWarningContent'))}
          {display.messages(warnings, classes.errorWarning)}
          {confirm?.footer?.label
            ? display.content(confirm.footer.label, confirm.footer.onClick)
            : displayHtml(
              classes.normal,
              t(hasReference(confirm)
                ? isUnamendable
                  ? 'label.notAmendablePartialErrorFooter'
                  : isCancellationError || isCancellationPending
                    ? `${t(confirm.label)} ${referenceNumber} ${t('label.cancellationErrorFooter')}`
                    : 'label.movementPartialErrorFooter'
                : 'label.movementErrorFooter'
              )
            )
          }
        </div>
      ),

      completedWarning: () => (
        <div className={classes.confirmation + ' ' + classes.warning}>
          {display.statusAndMovementRef(true,
            confirm?.content ? confirm.content : 'label.movementWarningContent',
            confirm?.content ? confirm.content : 'label.movementWarningContent',
            classes.warning,
            'label.confirm-Warnings')
          }
          {display.messages(warnings, classes.warning)}
          {confirm?.footer?.label
            ? display.content(confirm.footer.label, confirm.footer.onClick)
            : displayHtml(
              classes.normal,
              t(
                isUnamendable
                  ? 'label.notAmendableWarningFooter'
                  : 'label.movementWarningFooter'
              )
            )
          }
        </div>
      ),

      error: () => (
        <div className={classes.confirmation + ' ' + classes.error}>
          <p className={classes.title}>
            <i className={crossIcon} />
            <span>{t(label)}</span>
          </p>

          {confirm && confirm.id &&
            <div className={classes.text}>
              {t('label.id')} {display.getReference()}
            </div>
          }

          {display.messages(errors, classes.error)}
        </div>
      ),

      pending: () => (
        <div className={classes.confirmation + ' ' + classes.pending}>
          <p className={classes.title}>
            <i className={hourGlassIcon} />
            <span>{isCancellationError || isCancellationPending ? displayLinkContents(t(label), content.onClick) : t(label)}</span>
          </p>
          {!(isCancellationError || isCancellationPending) && <div className={classes.text}>
            {t('label.pleaseWait')}
          </div>}
        </div>
      ),

      polling: () => (
        <div className={classes.confirmation + ' ' + classes.pending}>
          <p className={classes.title}>
            <i className={hourGlassIcon} />
            <span>{isCancellationError || isCancellationPending || content?.onClick ? displayLinkContents(t(label), content.onClick) : t(label)}</span>
          </p>
          {!(isCancellationError || isCancellationPending) &&
            <div className={classes.text}>
              {displayLinkContents(t('label.submissionPendingCheckViaUserActivity'), confirm.onClick)}
            </div>
          }
        </div>
      ),

      success: () => (
        <div className={classes.confirmation + ' ' + classes.confirm}>
          <p className={classes.title}>
            <i className={checkIcon} />
            <span>{t(label)}</span>
            {confirm && confirm.id && confirm.label &&
              <span className={classes.normal}>{display.getReference()}</span>
            }
          </p>

          {((confirm && confirm.id && confirm.label) || status) &&
            <div className={classes.text}>
              {confirm?.content &&
                <p>{t(confirm.content)}</p>
              }

              {status && confirm?.content !== 'label.submissionPendingCheckLater' && !confirm?.isUndo &&
                <span className={classes.statusText}>{t('label.statusSuccess')}</span>
              }
              {confirm?.footer?.label && display.content(confirm.footer.label, confirm.footer.onClick)}
            </div>
          }
        </div>
      )
    },

    link: {
      list: (linkers) => {
        return linkers.map((link, index) => {
          if (link.type === constants.linkTypes.pdfButton) {
            return display.link.toViewPDF(link, index);
          } else if (link.type === constants.linkTypes.url) {
            return display.link.toUrl(link, index);
          }

          return null;
        });
      },

      toViewPDF: (link, index) => (
        link?.param?.movementRef
          ? <div className={classes.action} key={'link' + index}>
            <PDFButton
              buttonType="tertiary"
              label={link.label}
              referenceId={link.param.movementRef ? link.param.movementRef : link.param}
              requestId={link.param.requestId}
              setModal={setModal}
            />
          </div>
          : null
      ),

      toUrl: (link, index) => (
        <div className={classes.action} key={'link' + index}>
          <Linker
            label={link.label}
            to={link.param}
          />
        </div>
      )
    },

    buttons: (buttons) => {
      const result = buttons.map((button, index) => (
        <div className={classes.action} key={'button' + index}>
          <Button
            buttonType="primary"
            id={button.id}
            label={button.label}
            onClick={button.onClick ? button.onClick : null}
          />
        </div>
      ));

      return <>{result}</>;
    }
  };

  React.useEffect(() => {
    helpers.scrollToTop();
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const unListen = history.listen((path) => {
      if (
        (path.pathname.substring(0, Routing.movementsConfirm.length) !== Routing.movementsConfirm)
      ) {
        clearSession();
        unListen();
      }
    });
  }, [history]);

  return (
    <>
      {ready &&
        <>
          {status &&
            <>
              {bffStatus.isPending(status) && display.status.pending()}
              {bffStatus.isClaPending(status) && display.status.polling()}
              {(bffStatus.isCompletedError(status)) && warnings?.length > 0 && display.status.completedErrorAndWarning()}
              {(bffStatus.isCompletedError(status)) && (warnings?.length === 0 || !warnings) && display.status.completedError()}
              {(bffStatus.isCompletedWarning(status)) && display.status.completedWarning()}
              {bffStatus.isError(status) && display.status.error()}
            </>
          }

          {(!status || bffStatus.isSuccess(status)) && display.status.success()}

          {content && content.label && !isCancellationPending && display.content(content.label, content.onClick)}

          <div className={classes.actionButtons}>
            {buttons && display.buttons(buttons)}
            {links && display.link.list(links)}
          </div>
        </>
      }
    </>
  );
};

Confirmation.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  confirm: PropTypes.exact({
    id: PropTypes.string,
    isUndo: PropTypes.bool,
    label: PropTypes.string,
    movementReference: PropTypes.string,
    content: PropTypes.string,
    footer: PropTypes.exact({
      label: PropTypes.string,
      onClick: PropTypes.func
    }),
    onClick: PropTypes.func
  }),
  content: PropTypes.exact({
    label: PropTypes.string,
    onClick: PropTypes.func
  }),
  errors: PropTypes.arrayOf(
    PropTypes.exact({
      code: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      message: PropTypes.string,
      target: PropTypes.string
    })
  ),
  isUnamendable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  links: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.string,
        param: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object
        ]),
        type: PropTypes.oneOf([
          constants.linkTypes.pdfButton,
          constants.linkTypes.url
        ]),
        movementDocumentRef: PropTypes.string,
        requestType: PropTypes.number
      })
    ),
    PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.string,
        param: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]),
        type: PropTypes.oneOf([
          constants.linkTypes.pdfButton,
          constants.linkTypes.url
        ])
      })
    )
  ]),
  setModal: PropTypes.func.isRequired,
  specificErrorLabel: PropTypes.string,
  status: PropTypes.oneOf([
    constants.status.claPending,
    constants.status.completed_error,
    constants.status.completed_warning,
    constants.status.error,
    constants.status.pending,
    constants.status.processErrors,
    constants.status.success
  ]).isRequired,
  warnings: PropTypes.arrayOf(
    PropTypes.exact({
      code: PropTypes.string,
      message: PropTypes.string,
      target: PropTypes.string
    })
  )
};

export default Confirmation;
