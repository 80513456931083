import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/summary/addressSummary/addressSummary.module.scss';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import Address from 'backOfficeComponents/address/address';
import Button from 'backOfficeComponents/base/button/button';
import Legend from 'backOfficeComponents/base/legend/legend';

const AddressSummary = ({
  destinationHolding,
  info1,
  info2,
  info3,
  info3isComment,
  isManual,
  isPermitMove,
  label,
  label1,
  label2,
  label3,
  onClick,
  setModal,
  showBorder
}) => {
  const { ready, t } = useTranslation();

  return (
    <>
      {ready &&
        <div className={[classes.summaryRow, showBorder ? null : classes.noBorder].join(' ').trim()}>
          <div className={classes.label}>{t(label)}</div>
          <div className={classes.movement}>
            <div className={classes.address}>
              {label1 && <Legend id="addressSummaryLabel" legend={label1} /> }
              {info1 && <Legend id="addressSummaryInfo" legend={info1} legendStyle="reducedMargin" />}
              {!isManual &&
                <Address
                  cph={info1}
                  format={{
                    address: constants.address.format.short
                  }}
                  setModal={setModal}
                />
              }
            </div>

            {label2 && info2 &&
              <div className={classes.address}>
                {label2 && <Legend id="addressSummary2Label" legend={label2} /> }
                {info2 && <Legend id="addressSummary2Info" legend={info2} legendStyle="reducedMargin" /> }
                {!isPermitMove &&
                  <Address
                    cph={info2}
                    format={{
                      address: constants.address.format.short
                    }}
                    setModal={setModal}
                  />
                }
              </div>
            }

            {label3 && info3 &&
              <div className={classes.address}>
                {label3 && <Legend id="addressSummary3Label" legend={label3} /> }
                {info3 && <Legend id="addressSummary3Info" legend={info3} legendStyle="reducedMargin" /> }
                {!isPermitMove && !destinationHolding && !info3isComment &&
                  <Address
                    cph={info3}
                    format={{
                      address: constants.address.format.short
                    }}
                    setModal={setModal}
                  />
                }
              </div>
            }
          </div>

          {onClick &&
            <div className={classes.action}>
              <Button
                buttonType="link"
                id="addressSummaryButton"
                label="label.edit"
                onClick={onClick}
              />
            </div>
          }
        </div>
      }
    </>
  );
};

AddressSummary.propTypes = {
  info1: PropTypes.string,
  info2: PropTypes.string,
  info3: PropTypes.string,
  info3isComment: PropTypes.bool,
  isManual: PropTypes.bool,
  isPermitMove: PropTypes.bool,
  label: PropTypes.string,
  label1: PropTypes.string,
  label2: PropTypes.string,
  label3: PropTypes.string,
  onClick: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  showBorder: PropTypes.bool
};

export default AddressSummary;
