import React, { useState, useEffect } from 'react';
import { isCancel } from 'services/bff';
import classes from 'components/addTagRange/addTagRange.module.scss';
import config from 'config';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Button from 'components/base/button/button';
import DropdownBatchNumbersAvailable from 'components/dropdownBatchNumbersAvailable/dropdownBatchNumbersAvailable';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Hint from 'components/base/hint/hint';
import Legend from 'components/base/legend/legend';
import NumberEntry from 'components/base/numberEntry/numberEntry';
import TextEntry from 'components/base/textEntry/textEntry';

const AddTagRange = ({
  isDropdown,
  isGatheringMovement,
  setAnimalsToChange,
  setModal,
  species
}) => {
  const [quantityLimitError, setQuantityLimitError] = useState(false);
  const [batchNumber, setBatchNumber] = useState('');
  const [batchNumberValid, setBatchNumberValid] = useState(false);
  const [batchNumberNonEid, setBatchNumberNonEid] = useState(false);
  const [startNumber, setStartNumber] = useState('');
  const [quantity, setQuantity] = useState('');
  const [pending, setPending] = useState(false);

  const tagRangeSubTitle = isGatheringMovement ? 'tagging.application.tagRangeSubTitle' + species.id : 'tooltips.movements.tagNumberRange';
  const batchNumberLabel = 'label.batchNumber' + species.id;

  const validate = {
    batchNumber: () => {
      return helpers.species.isSheepId(species.id) && batchNumber.substr(0, 2).toUpperCase() === 'UK' && (batchNumber.substr(2).length === 6 || batchNumber.substr(2).length === 7);
    },

    batchNumberEid: (input) => {
      setBatchNumberNonEid(helpers.species.isSheepId(species.id) && input.substr(0, 2).toUpperCase() === 'UK' && input.substr(2).length === 6);
    },

    quantityLimit: (input) => {
      const validResult = parseInt(input) > 0 && parseInt(input) <= config.MAX_TOTAL_ANIMAL_RANGE;
      setQuantityLimitError(!validResult);
      return validResult;
    }
  };

  const handleChanges = {
    padStartNumber: (batch, tag) => {
      if (!batchNumberNonEid) {
        setStartNumber(helpers.tag.zerosPadLeft(species.id, batch, tag));
      }
    }
  };

  const submitRange = () => {
    const batchNum = helpers.tag.trim(batchNumber);
    const startNum = helpers.tag.trim(startNumber);

    if (!validate.quantityLimit(quantity)) {
      setQuantityLimitError(true);
    } else {
      setQuantityLimitError(false);
      setPending(true);

      const tagNumbers = [];

      for (let i = 0; i < quantity; i++) {
        const tagNumber = parseInt(startNum) + i;
        let strLength;

        if (batchNumberNonEid) {
          strLength = startNum.length;
        }

        const fullTagNumber = batchNum + ' ' + helpers.tag.zerosPadLeft(species.id, batchNum, tagNumber, strLength);

        tagNumbers.push(fullTagNumber);
      }

      helpers.validate.tagNumbers(species.name, [], tagNumbers)
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal, setPending)) {
            setPending(false);
            setBatchNumberValid(false);
            setBatchNumberNonEid(false);

            setBatchNumber('');
            setStartNumber('');
            setQuantity('');

            const listOfTags = res.data.tagNumbers.map((tag) => ({
              tagNumber: tag.formattedDevice.tagNumber ? tag.formattedDevice.tagNumber : tag.originalIdentifier,
              valid: tag.state
            }));

            setAnimalsToChange(listOfTags);
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setPending(false);
            errors.BFF(error, setModal);
          }
        });
    }
  };

  const isDisabled = () => {
    return (
      pending ||
      (isDropdown && (!batchNumber || !startNumber || !quantity)) ||
      (!isDropdown && (!batchNumber || !quantity))
    );
  };

  useEffect(() => {
    if (batchNumber && validate.batchNumber()) {
      setBatchNumberValid(true);
    } else {
      setBatchNumberValid(false);
    }
  }, [batchNumber, validate]);

  return (
    <>
      <fieldset role="group">
        <Legend
          legend="tagging.application.addTagNumberRange"
          tooltip={tagRangeSubTitle}
          tooltipPosition="bottom"
        />

        {quantityLimitError &&
          <ErrorLabel
            label="movements.animal-selection.batch.total-animal-exceeded-limit"
          />
        }
        {isDropdown &&
          <Hint
            hint={'prompt.selectAvailableBatch-tagApplication-' + species.id}
          />
        }
        <div className={classes.addBatchRow}>
          {isDropdown &&
            <div className={classes.batchNumber}>
              <DropdownBatchNumbersAvailable
                label={batchNumberLabel}
                name="availableBatches"
                onChange={(event) => setBatchNumber(event.target.value)}
                setModal={setModal}
                validationPending={pending}
                value={batchNumber}
              />
            </div>
          }
          {!isDropdown &&
            <div className={classes.batchNumber}>
              <TextEntry
                disabled={pending}
                hideCharacterCount={true}
                id="batchNumber"
                label={batchNumberLabel}
                maxLength={config.WIDTH_BATCH_NUMBER}
                name="batchNumber"
                onBlur={(event) => {
                  validate.batchNumberEid(event.target.value);
                  handleChanges.padStartNumber(event.target.value, startNumber);
                }}
                onChange={(event) => setBatchNumber(event.target.value)}
                value={batchNumber}
                width={config.WIDTH_BATCH_NUMBER}
              />
            </div>
          }
          <div className={classes.startNumber}>
            <NumberEntry
              disabled={pending || (helpers.species.isSheepId(species.id) && !isDropdown && !batchNumberValid)}
              id="startNumber"
              label="label.startNumber"
              max={helpers.species.isGoatId(species.id) ? 999999 : 99999 }
              min="1"
              name="startNumber"
              onBlur={(event) => handleChanges.padStartNumber(batchNumber, event.target.value)}
              onChange={(event) => setStartNumber(event.target.value)}
              value={startNumber}
            />
          </div>
          <div className={classes.quantity}>
            <NumberEntry
              disabled={pending}
              id="quantity"
              label="label.quantity"
              min="1"
              name="quantity"
              onChange={(event) => setQuantity(event.target.value)}
              value={quantity}
              width={config.WIDTH_TOTAL_NUMBER}
            />
          </div>
          <div>
            <Button
              buttonType="secondary"
              disabled={isDisabled()}
              label="button.addRange"
              onClick={submitRange}
            />
          </div>
        </div>
        {batchNumberValid && batchNumberNonEid &&
          <ErrorLabel
            isWarning={true}
            label="formatExamples.tagBatchNonEidError"
            modalMessage={helpers.tag.getFormatExamples(species.id).description}
            modalTitle={helpers.tag.getFormatExamples(species.id).title}
            setModal={setModal}
          />

        }
      </fieldset>
    </>
  );
};

AddTagRange.propTypes = {
  isDropdown: PropTypes.bool,
  isGatheringMovement: PropTypes.bool,
  setAnimalsToChange: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  species: PropTypes.object
};

export default AddTagRange;
