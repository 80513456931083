import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';

const gatheringRoles = [
  userRoleType.ABATTOIR,
  userRoleType.AICENTRE,
  userRoleType.ASSEMBLY,
  userRoleType.COLLECTION,
  userRoleType.COMMON,
  userRoleType.GENERIC,
  userRoleType.MARKET,
  userRoleType.OTHER,
  userRoleType.PORT,
  userRoleType.QUARANTINE,
  userRoleType.SHOW,
  userRoleType.SLAUGHTERMARKET,
  userRoleType.STOREMARKET,
  userRoleType.VET
];

const validateRoles = [
  userRoleType.ABATTOIR,
  userRoleType.AICENTRE,
  userRoleType.ASSEMBLY,
  userRoleType.BACKOFFICE,
  userRoleType.COLLECTION,
  userRoleType.COMMON,
  userRoleType.GENERIC,
  userRoleType.KEEPER,
  userRoleType.MARKET,
  userRoleType.OTHER,
  userRoleType.PORT,
  userRoleType.QUARANTINE,
  userRoleType.SHOW,
  userRoleType.SLAUGHTERMARKET,
  userRoleType.STOREMARKET,
  userRoleType.VET,
  userRoleType.RCVSVET
];

const nonBORoles = [
  userRoleType.ABATTOIR,
  userRoleType.AICENTRE,
  userRoleType.ASSEMBLY,
  userRoleType.COLLECTION,
  userRoleType.COMMON,
  userRoleType.GENERIC,
  userRoleType.KEEPER,
  userRoleType.MARKET,
  userRoleType.OTHER,
  userRoleType.PORT,
  userRoleType.QUARANTINE,
  userRoleType.SHOW,
  userRoleType.SLAUGHTERMARKET,
  userRoleType.STOREMARKET,
  userRoleType.VET,
  userRoleType.RCVSVET
];

const permissionsHelper = {
  gatheringRoles,
  isAbattoir: () => storeService.session.get.permissions() === userRoleType.ABATTOIR,
  isAICentre: () => storeService.session.get.permissions() === userRoleType.AICENTRE,
  isAssembly: () => storeService.session.get.permissions() === userRoleType.ASSEMBLY,
  isBackOffice: () => storeService.session.get.permissions() === userRoleType.BACKOFFICE,
  isCollection: () => storeService.session.get.permissions() === userRoleType.COLLECTION,
  isCommon: () => storeService.session.get.permissions() === userRoleType.COMMON,
  isGathering: () => gatheringRoles.includes(storeService.session.get.permissions()),
  isGeneric: () => storeService.session.get.permissions() === userRoleType.GENERIC,
  isKeeper: () => storeService.session.get.permissions() === userRoleType.KEEPER,
  isLoggedIn: () => validateRoles.includes(storeService.session.get.permissions()),
  isMarket: () => storeService.session.get.permissions() === userRoleType.MARKET,
  isOther: () => storeService.session.get.permissions() === userRoleType.OTHER,
  isPort: () => storeService.session.get.permissions() === userRoleType.PORT,
  isQuarantine: () => storeService.session.get.permissions() === userRoleType.QUARANTINE,
  isNonBO: () => nonBORoles.includes(storeService.session.get.permissions()),
  isShow: () => storeService.session.get.permissions() === userRoleType.SHOW,
  isSlaughterMarket: () => storeService.session.get.permissions() === userRoleType.SLAUGHTERMARKET,
  isStoreMarket: () => storeService.session.get.permissions() === userRoleType.STOREMARKET,
  isUserAllowed: (users) => users.includes(storeService.session.get.permissions()),
  isVet: () => storeService.session.get.permissions() === userRoleType.VET,
  isRCVSVet: () => storeService.session.get.permissions() === userRoleType.RCVSVET,
  nonBORoles,
  validateRoles
};

export default permissionsHelper;
