import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import classes from 'frontEnd/movements/step3/transportDetailsForm/transportDetailsForm.module.scss';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import moment from 'moment';
import storeService from 'services/storeService';
import Address from 'components/address/address';
import Dropdown from 'components/base/dropdown/dropdown';
import DurationInput from 'frontEnd/movements/step3/durationInputFields/durationInputFields';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Legend from 'components/base/legend/legend';
import TextEntry from 'components/base/textEntry/textEntry';
import { isEqual } from 'lodash';

const TransportDetailsForm = ({
  authorisationNumber,
  contactDetailsIndex,
  contactPhone,
  contactPhoneError,
  departureDate,
  departureTime,
  driverFirstName,
  driverFirstNameError,
  driverLastName,
  driverLastNameError,
  durationOfJourney,
  hoursError,
  minutesError,
  movementData,
  movementType,
  ownerFirstName,
  ownerFirstNameError,
  ownerLastName,
  ownerLastNameError,
  setAuthorisationNumber,
  setContactDetailsIndex,
  setContactPhone,
  setContactPhoneError,
  setDepartureTime,
  setDriverFirstName,
  setDriverFirstNameError,
  setDriverLastName,
  setDriverLastNameError,
  setDurationOfJourney,
  setHoursError,
  setMinutesError,
  setModal,
  setOwnerFirstName,
  setOwnerFirstNameError,
  setOwnerLastName,
  setOwnerLastNameError,
  setTransporter,
  setTransportError,
  setVehicleRegistrationNumber,
  transportedBy,
  transporter,
  transportError,
  vehicleRegistrationNumber,
  vehicleRegistrationNumberError
}) => {
  const { ready, t } = useTranslation();

  const holding = storeService.session.get.holding();

  const [dropdownOptions, setDropdownOptions] = React.useState([]);

  let depTime;
  if (departureTime && Number.isInteger(departureTime.hours) && Number.isInteger(departureTime.minutes)) {
    depTime = new Date();
    depTime.setHours(departureTime.hours);
    depTime.setMinutes(departureTime.minutes);
  }

  const getContactLabel = (contact) => {
    const items = [];

    if ((contact.driverFirstName && contact.driverFirstName !== '') || (contact.driverLastName && contact.driverLastName !== '')) {
      contact = {
        ...contact,
        name: contact.driverFirstName + ' ' + contact.driverLastName
      };
    }
    if (contact.haulier && contact.haulier !== '') {
      items.push(contact.haulier.trim());
    }
    if (contact.name && contact.name !== '') {
      items.push(contact.name.trim());
    }
    if (contact.phone && contact.phone !== '') {
      items.push(contact.phone.trim());
    }
    if (contact.vehicle && contact.vehicle !== '') {
      items.push(contact.vehicle.trim());
    }
    return items.join(', ');
  };

  const createContactsDropdownOptions = (contactList) => {
    return contactList !== null
      ? contactList.map((contact, index) => ({ value: String(index), label: getContactLabel(contact) }))
      : [];
  };

  const validate = {
    name: (input, whoseName, firstOrLast) => {
      if (input.length > 0) {
        if (whoseName === 'driver') {
          if (firstOrLast === 'first') {
            if (validate.text(input)) {
              setDriverFirstNameError(false);
            } else {
              setDriverFirstNameError(true);
            }
          } else if (firstOrLast === 'last') {
            if (validate.text(input)) {
              setDriverLastNameError(false);
            } else {
              setDriverLastNameError(true);
            }
          }
        } else if (whoseName === 'owner') {
          if (firstOrLast === 'first') {
            if (validate.text(input)) {
              setOwnerFirstNameError(false);
            } else {
              setOwnerFirstNameError(true);
            }
          } else if (firstOrLast === 'last') {
            if (validate.text(input)) {
              setOwnerLastNameError(false);
            } else {
              setOwnerLastNameError(true);
            }
          }
        }
      }
    },

    phoneNumber: (input) => {
      // eslint-disable-next-line no-useless-escape
      // const regex = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
      const regex = /^[\d\s]*$/;
      if (regex.test(input)) {
        setContactPhoneError('');
        setContactPhone(input);
        return true;
      }
      setContactPhoneError(t('error.incorrectPhoneFormat'));
      return false;
    },

    text: (input) => {
      const minLength = 2;
      const maxLength = 32;

      return !(input.length < minLength || input.length > maxLength);
    },

    transporter: (input) => {
      setTransporter(input);

      if (validate.text(input)) {
        setTransportError(false);
      } else {
        setTransportError(true);
      }
    }
  };

  const onSelectContactDetails = (event) => {
    const selectedIndex = parseInt(event.target.value);
    let localStoreGetter;

    switch (transportedBy) {
      case constants.option.transporter.departureKeeper:
        localStoreGetter = !helpers.option.movement.isOn(movementType) ? 'recentlyUsedDepartureTransportDetail' : 'recentlyUsedDestinationTransportDetail';
        break;
      case constants.option.transporter.destinationKeeper:
        localStoreGetter = !helpers.option.movement.isOn(movementType) ? 'recentlyUsedDestinationTransportDetail' : 'recentlyUsedDepartureTransportDetail';
        break;
      case constants.option.transporter.haulier:
        localStoreGetter = 'recentlyUsedHaulierTransportDetail';
        break;
      default:
        break;
    }

    const storedContact = storeService.local.get[localStoreGetter]()[selectedIndex];

    if (storedContact) {
      if (selectedIndex !== undefined && selectedIndex !== null) {
        setContactDetailsIndex(selectedIndex);
      }
      if (storedContact.phone) {
        setContactPhone(storedContact.phone);
      }
      if (storedContact.driverFirstName) {
        setDriverFirstName(storedContact.driverFirstName);
      }
      if (storedContact.driverLastName) {
        setDriverLastName(storedContact.driverLastName);
      }
      if (storedContact.vehicle) {
        setVehicleRegistrationNumber(storedContact.vehicle);
      }
      if (storedContact.authNumber) {
        setAuthorisationNumber(storedContact.authNumber);
      }
      if (storedContact.ownerFirstName) {
        setOwnerFirstName(storedContact.ownerFirstName);
      }
      if (storedContact.ownerLastName) {
        setOwnerLastName(storedContact.ownerLastName);
      }

      if (helpers.option.transporter.isHaulier(transportedBy)) {
        setTransporter(storedContact.haulier);
        document.getElementById('haulierInput').value = storedContact.haulier;
      }
    } else {
      setContactDetailsIndex(null);
    }
  };

  const get = {
    maxTime: () => {
      const maxTime = new Date();

      if (
        (helpers.option.movement.isOff(movementType) && helpers.date.is.tomorrow(departureDate)) ||
        (!helpers.option.movement.isOff(movementType) && helpers.date.is.today(departureDate))
      ) {
        maxTime.setHours(moment().hours());
        maxTime.setMinutes(moment().minutes());
      } else {
        maxTime.setHours(23);
        maxTime.setMinutes(59);
      }

      return maxTime;
    },

    minTime: () => {
      const minTime = new Date();
      minTime.setHours(0);
      minTime.setMinutes(0);

      return minTime;
    },

    transporterHolding: () => {
      if (helpers.option.movement.isOn(movementType)) {
        if (helpers.option.transporter.isDepartureKeeper(transportedBy)) {
          return movementData.otherHolding.value;
        }

        return holding.value;
      }

      if (helpers.option.movement.isOff(movementType)) {
        if (helpers.option.transporter.isDepartureKeeper(transportedBy)) {
          return holding.value;
        }

        return movementData.otherHolding.value;
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    let storedDropdownOptions;
    switch (transportedBy) {
      case constants.option.transporter.departureKeeper:
        storedDropdownOptions = !helpers.option.movement.isOn(movementType)
          ? createContactsDropdownOptions(storeService.local.get.recentlyUsedDepartureTransportDetail())
          : createContactsDropdownOptions(storeService.local.get.recentlyUsedDestinationTransportDetail());
        if (!isEqual(dropdownOptions, storedDropdownOptions)) {
          setDropdownOptions(storedDropdownOptions);
        }
        break;

      case constants.option.transporter.destinationKeeper:
        storedDropdownOptions = !helpers.option.movement.isOn(movementType)
          ? createContactsDropdownOptions(storeService.local.get.recentlyUsedDestinationTransportDetail())
          : createContactsDropdownOptions(storeService.local.get.recentlyUsedDepartureTransportDetail());
        if (!isEqual(dropdownOptions, storedDropdownOptions)) {
          setDropdownOptions(storedDropdownOptions);
        }
        break;

      case constants.option.transporter.haulier:
        storedDropdownOptions = createContactsDropdownOptions(storeService.local.get.recentlyUsedHaulierTransportDetail());
        if (!isEqual(dropdownOptions, storedDropdownOptions)) {
          setDropdownOptions(storedDropdownOptions);
        }
        break;

      default:
        break;
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportedBy]);

  return (
    <>
      {ready &&
        <>
          <div className="section">
            <fieldset role="group">
              <Legend
                legend="radioButtons.transportedBy.transporterDetails"
              />
              {helpers.option.transporter.isHaulier(transportedBy) && dropdownOptions?.length > 0 &&
                <div className="input">
                  <Dropdown
                    id="contact-detail"
                    label="movements.transport-information.transport-details.recentlyUsedTransportDetails"
                    name="contact-detail"
                    onChange={(event) => onSelectContactDetails(event)}
                    optional={true}
                    optionList={dropdownOptions}
                    value={contactDetailsIndex}
                  />
                </div>
              }

              {transportError &&
                <ErrorLabel label="movements.transport-information.transport-details.errors.transport-error" />
              }

              {!helpers.option.transporter.isHaulier(transportedBy) &&
                <div className="input">
                  <Address
                    cph={get.transporterHolding()}
                    format={{
                      address: constants.address.format.line
                    }}
                    id="address"
                    queryKeeper={true}
                    setModal={setModal}
                  />
                </div>
              }

              {helpers.option.transporter.isHaulier(transportedBy) &&
                <div className="input">
                  <TextEntry
                    hideCharacterCount={true}
                    id={transportedBy + 'Input'}
                    label="label.haulageCompany"
                    maxLength={config.WIDTH_TRANSPORTER_NAME}
                    maxWidth={true}
                    name={transportedBy + 'Input'}
                    onChange={(event) => validate.transporter(event.target.value)}
                    optional={helpers.option.transporter.isHaulier(transportedBy)}
                    value={transporter}
                  />
                </div>
              }

              {!helpers.option.transporter.isHaulier(transportedBy) && dropdownOptions?.length > 0 &&
                <div className="input">
                  <Dropdown
                    id="contact-detail"
                    label="movements.transport-information.transport-details.recentlyUsedTransportDetails"
                    name="contact-detail"
                    onChange={(event) => onSelectContactDetails(event)}
                    optional={true}
                    optionList={dropdownOptions}
                    value={contactDetailsIndex}
                  />
                </div>
              }

              {contactPhoneError &&
                <ErrorLabel label="movements.transport-information.transport-details.errors.contactTelephoneNumber-error" />
              }

              <div className="input">
                <TextEntry
                  hideCharacterCount={true}
                  id="phoneNumber"
                  label="movements.transport-information.transport-details.contactTelephoneNumber"
                  maxLength={config.WIDTH_PHONE_NUMBER}
                  maxWidth={true}
                  name="phoneNumber"
                  onBlur={(event) => validate.phoneNumber(event.target.value)}
                  onChange={(event) => setContactPhone(event.target.value)}
                  optional={true}
                  value={contactPhone}
                />
              </div>

              <div className={classes.driverInformation}>
                <h4 className="h4">{t('movements.transport-information.transport-details.driverName')}</h4>

                {(driverFirstNameError || driverLastNameError) &&
                  <ErrorLabel label="movements.transport-information.transport-details.errors.driverName-error" />
                }

                <div className="inline">
                  <div className="input">
                    <TextEntry
                      hideCharacterCount={true}
                      id="driver-first-name"
                      label="movements.transport-information.transport-details.firstName"
                      maxLength="32"
                      maxWidth={true}
                      name="driver-first-name"
                      onBlur={(event) => validate.name(event.target.value, 'driver', 'first')}
                      onChange={(event) => setDriverFirstName(event.target.value)}
                      optional={true}
                      value={driverFirstName}
                    />
                  </div>

                  <div className="input">
                    <TextEntry
                      hideCharacterCount={true}
                      id="driver-last-name"
                      label="movements.transport-information.transport-details.lastName"
                      maxLength="32"
                      maxWidth={true}
                      name="driver-last-name"
                      onBlur={(event) => validate.name(event.target.value, 'driver', 'last')}
                      onChange={(event) => setDriverLastName(event.target.value)}
                      optional={true}
                      value={driverLastName}
                    />
                  </div>
                </div>
              </div>

              <div className="input">
                <TextEntry
                  errorLabel={vehicleRegistrationNumberError}
                  hideCharacterCount={true}
                  id="vehicleRegistrationNumber"
                  label="movements.transport-information.transport-details.vehicleRegistrationNumber"
                  maxLength="10"
                  maxWidth={true}
                  name="vehicleRegistrationNumber"
                  onChange={(event) => setVehicleRegistrationNumber(event.target.value)}
                  optional={true}
                  value={vehicleRegistrationNumber}
                />
              </div>

              <div className="input">
                <TextEntry
                  hideCharacterCount={true}
                  id="authorisationNumber"
                  label="movements.transport-information.transport-details.authorisationNumber"
                  maxLength={config.WIDTH_TRANSPORTER_AUTH}
                  maxWidth={true}
                  name="authorisationNumber"
                  onChange={(event) => setAuthorisationNumber(event.target.value)}
                  optional={true}
                  value={authorisationNumber}
                  width={config.WIDTH_TRANSPORTER_AUTH}
                />
              </div>

              <h4 className="h4">{t('movements.transport-information.transport-details.ownerName')}</h4>

              {(ownerFirstNameError || ownerLastNameError) &&
                <ErrorLabel label="movements.transport-information.transport-details.errors.ownerName-error" />
              }

              <div className="inline">
                <div className="input">
                  <TextEntry
                    hideCharacterCount={true}
                    id="owner-first-name"
                    label="movements.transport-information.transport-details.firstName"
                    maxLength="32"
                    maxWidth={true}
                    name="owner-first-name"
                    onBlur={(event) => validate.name(event.target.value, 'owner', 'first')}
                    onChange={(event) => setOwnerFirstName(event.target.value)}
                    optional={true}
                    value={ownerFirstName}
                  />
                </div>

                <div className="input">
                  <TextEntry
                    hideCharacterCount={true}
                    id="owner-last-name"
                    label="movements.transport-information.transport-details.lastName"
                    maxLength="32"
                    maxWidth={true}
                    name="owner-last-name"
                    onBlur={(event) => validate.name(event.target.value, 'owner', 'last')}
                    onChange={(event) => setOwnerLastName(event.target.value)}
                    optional={true}
                    value={ownerLastName}
                  />
                </div>
              </div>
            </fieldset>
          </div>

          <div className="section">
            <fieldset role="group">
              <Legend
                legend="movements.transport-information.transport-details.departureTime"
                optional={true}
              />
              <DurationInput
                id="departureTime"
                isTimePicker={true}
                label="movements.transport-information.transport-details.departureTime"
                maxTime={get.maxTime()}
                minTime={get.minTime()}
                setTime={setDepartureTime}
                time={depTime ? depTime : null}
              />
            </fieldset>
          </div>

          <div className="section">
            <fieldset role="group">
              <Legend
                legend="movements.transport-information.transport-details.durationOfJourney"
                optional={true}
                tooltip="movements.transport-information.transport-details.durationOfJourneyDesc"
              />
              <DurationInput
                hoursError={hoursError}
                label="movements.transport-information.transport-details.durationOfJourney"
                minutesError={minutesError}
                setHoursError={setHoursError}
                setMinutesError={setMinutesError}
                setTime={setDurationOfJourney}
                time={durationOfJourney}
              />
            </fieldset>
          </div>
        </>
      }
    </>
  );
};

TransportDetailsForm.propTypes = {
  authorisationNumber: PropTypes.string,
  contactDetailsIndex: PropTypes.number,
  contactPhone: PropTypes.string,
  contactPhoneError: PropTypes.string,
  departureDate: PropTypes.string,
  departureTime: PropTypes.object,
  driverFirstName: PropTypes.string,
  driverFirstNameError: PropTypes.bool,
  driverLastName: PropTypes.string,
  driverLastNameError: PropTypes.bool,
  durationOfJourney: PropTypes.object,
  hoursError: PropTypes.bool,
  minutesError: PropTypes.bool,
  movementData: PropTypes.object,
  movementType: PropTypes.string,
  ownerFirstName: PropTypes.string,
  ownerFirstNameError: PropTypes.bool,
  ownerLastName: PropTypes.string,
  ownerLastNameError: PropTypes.bool,
  setAuthorisationNumber: PropTypes.func,
  setContactDetailsIndex: PropTypes.func,
  setContactPhone: PropTypes.func,
  setContactPhoneError: PropTypes.func,
  setDepartureTime: PropTypes.func,
  setDriverFirstName: PropTypes.func,
  setDriverFirstNameError: PropTypes.func,
  setDriverLastName: PropTypes.func,
  setDriverLastNameError: PropTypes.func,
  setDurationOfJourney: PropTypes.func,
  setHoursError: PropTypes.func,
  setMinutesError: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setOwnerFirstName: PropTypes.func,
  setOwnerFirstNameError: PropTypes.func,
  setOwnerLastName: PropTypes.func,
  setOwnerLastNameError: PropTypes.func,
  setTransportError: PropTypes.func,
  setTransporter: PropTypes.func,
  setVehicleRegistrationNumber: PropTypes.func,
  transportError: PropTypes.bool,
  transportedBy: PropTypes.string,
  transporter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  vehicleRegistrationNumber: PropTypes.string,
  vehicleRegistrationNumberError: PropTypes.string
};

export default TransportDetailsForm;
