import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Routing from 'routing';
import classes from 'frontEnd/movements/step3/movementStep3.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import storeService from 'services/storeService';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Button from 'components/base/button/button';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import TransportInformationBox from 'components/summary/transportInformationBox/transportInformationBox';
import TransportDetails from 'frontEnd/movements/step3/transportDetails/transportDetails';
import { remove } from 'lodash';

const MovementStep3 = ({
  animalDetails,
  fromSummary,
  movementData,
  movementType,
  numberBeingMoved,
  setDataChanged,
  setModal,
  setTransportDetails,
  species
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const [recordTransportInformation, setRecordTransportInformation] = React.useState(null);
  const [transportedBy, setTransportedBy] = React.useState();
  const [contactDetailsIndex, setContactDetailsIndex] = React.useState(null);
  const [transportError, setTransportError] = React.useState(false);
  const [transporter, setTransporter] = React.useState('');

  const [contactPhone, setContactPhone] = React.useState('');
  const [contactPhoneError, setContactPhoneError] = React.useState('');

  const [driverFirstName, setDriverFirstName] = React.useState('');
  const [driverFirstNameError, setDriverFirstNameError] = React.useState(false);
  const [driverLastName, setDriverLastName] = React.useState('');
  const [driverLastNameError, setDriverLastNameError] = React.useState(false);

  const [vehicleRegistrationNumber, setVehicleRegistrationNumber] = React.useState('');
  const [vehicleRegistrationNumberError, setVehicleRegistrationNumberError] = React.useState('');
  const [authorisationNumber, setAuthorisationNumber] = React.useState('');

  const [ownerFirstName, setOwnerFirstName] = React.useState('');
  const [ownerFirstNameError, setOwnerFirstNameError] = React.useState(false);
  const [ownerLastName, setOwnerLastName] = React.useState('');
  const [ownerLastNameError, setOwnerLastNameError] = React.useState(false);

  const [departureTime, setDepartureTime] = React.useState({
    hours: null,
    minutes: null
  });
  const [durationOfJourney, setDurationOfJourney] = React.useState({
    days: '',
    hours: '',
    minutes: ''
  });
  const [durationOfJourneyError, setDurationOfJourneyError] = React.useState(null);

  const [hoursError, setHoursError] = React.useState(false);
  const [minutesError, setMinutesError] = React.useState(false);

  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const validate = {
    registrationNumber: (input) => {
      if (input && !input.match(/^[a-z0-9\s]+$/i)) {
        setVehicleRegistrationNumberError('error.registrationNumber');
      } else {
        setVehicleRegistrationNumberError('');
      }
    }
  };

  const updateTransportDetail = (getStoredDetails, setStoredDetails, drvFirstName, drvLastName, ownFirstName, ownLastName, phone, vehicle, authNumber, transporter) => {
    const tempDrvFirstName = drvFirstName ? drvFirstName.trim() : '';
    const tempDrvLastName = drvLastName ? drvLastName.trim() : '';
    const tempOwnFirstName = ownFirstName ? ownFirstName.trim() : '';
    const tempOwnLastName = ownLastName ? ownLastName.trim() : '';
    const tempPhone = phone ? phone.trim() : '';
    const tempVehicle = vehicle ? vehicle.trim() : '';
    const tempAuthNumber = authNumber ? authNumber.trim() : '';
    const tempHaulier = transporter ? transporter.trim() : '';

    if (tempDrvFirstName !== '' ||
      tempDrvLastName !== '' ||
      tempOwnFirstName !== '' ||
      tempOwnLastName !== '' ||
      tempPhone !== '' ||
      tempVehicle !== '' ||
      tempAuthNumber !== '' ||
      tempHaulier !== '') {
      let details = getStoredDetails();
      details = details !== null ? details : [];
      const newRecord = {
        driverFirstName: tempDrvFirstName,
        driverLastName: tempDrvLastName,
        ownerFirstName: tempOwnFirstName,
        ownerLastName: tempOwnLastName,
        phone: tempPhone,
        vehicle: tempVehicle,
        authNumber: tempAuthNumber
      };

      if (tempHaulier !== '') {
        newRecord.haulier = tempHaulier;
      }

      const existingElement = remove(details, newRecord)[0];

      if (existingElement) {
        details.unshift(existingElement);
      } else {
        details.unshift(newRecord);
      }

      setStoredDetails(details);
    }
  };

  const submitValues = () => {
    if (helpers.option.transporter.isDepartureKeeper(transportedBy)) {
      if (!helpers.option.movement.isOn(movementType)) {
        updateTransportDetail(
          storeService.local.get.recentlyUsedDepartureTransportDetail,
          storeService.local.set.recentlyUsedDepartureTransportDetail,
          driverFirstName, driverLastName,
          ownerFirstName, ownerLastName,
          contactPhone, vehicleRegistrationNumber, authorisationNumber
        );
      } else {
        updateTransportDetail(
          storeService.local.get.recentlyUsedDestinationTransportDetail,
          storeService.local.set.recentlyUsedDestinationTransportDetail,
          driverFirstName, driverLastName,
          ownerFirstName, ownerLastName,
          contactPhone, vehicleRegistrationNumber, authorisationNumber
        );
      }
    } else if (helpers.option.transporter.isDestinationKeeper(transportedBy)) {
      if (!helpers.option.movement.isOn(movementType)) {
        updateTransportDetail(
          storeService.local.get.recentlyUsedDestinationTransportDetail,
          storeService.local.set.recentlyUsedDestinationTransportDetail,
          driverFirstName, driverLastName,
          ownerFirstName, ownerLastName,
          contactPhone, vehicleRegistrationNumber, authorisationNumber
        );
      } else {
        updateTransportDetail(
          storeService.local.get.recentlyUsedDepartureTransportDetail,
          storeService.local.set.recentlyUsedDepartureTransportDetail,
          driverFirstName, driverLastName,
          ownerFirstName, ownerLastName,
          contactPhone, vehicleRegistrationNumber, authorisationNumber
        );
      }
    } else if (helpers.option.transporter.isHaulier(transportedBy)) {
      updateTransportDetail(
        storeService.local.get.recentlyUsedHaulierTransportDetail,
        storeService.local.set.recentlyUsedHaulierTransportDetail,
        driverFirstName, driverLastName,
        ownerFirstName, ownerLastName,
        contactPhone, vehicleRegistrationNumber, authorisationNumber, transporter
      );
    }

    setTransportDetails(storeService.session.get.movementTransportInformation());
  };

  const nextPage = () => {
    submitValues();
    setDataChanged();
    history.push(fromSummary ? Routing.movementsSummary : Routing.movementsStep4);
    helpers.scrollToTop();
  };

  const checkSubmitButtonStatus = () => {
    if (recordTransportInformation === constants.option.recordTransportInformation.no) {
      setSubmitDisabled(false);
    } else if (recordTransportInformation === constants.option.recordTransportInformation.yes) {
      if (transportedBy === constants.option.transporter.haulier) {
        setSubmitDisabled(
          Boolean(
            !(transporter || transportedBy) ||
            vehicleRegistrationNumberError ||
            transportError ||
            driverFirstNameError ||
            driverLastNameError ||
            ownerFirstNameError ||
            ownerLastNameError ||
            durationOfJourneyError ||
            Boolean(contactPhoneError) ||
            hoursError ||
            minutesError
          )
        );
      } else {
        setSubmitDisabled(
          Boolean(
            !transportedBy ||
            vehicleRegistrationNumberError ||
            transportError ||
            driverFirstNameError ||
            driverLastNameError ||
            ownerFirstNameError ||
            ownerLastNameError ||
            durationOfJourneyError ||
            Boolean(contactPhoneError) ||
            hoursError ||
            minutesError
          )
        );
      }
    }

    return true;
  };

  const clearTransportDetails = () => {
    setContactDetailsIndex(null);
    setTransporter('');
    setContactPhone('');
    setDriverFirstName('');
    setDriverLastName('');
    setVehicleRegistrationNumber('');
    setAuthorisationNumber('');
    setOwnerFirstName('');
    setOwnerLastName('');

    storeService.session.remove.movementTransporter();
    storeService.session.remove.movementContactPhone();
    storeService.session.remove.movementDriverFirstName();
    storeService.session.remove.movementDriverLastName();
    storeService.session.remove.movementVehicleRegistrationNumber();
    storeService.session.remove.movementAuthorisationNumber();
    storeService.session.remove.movementOwnerFirstName();
    storeService.session.remove.movementOwnerLastName();
  };

  const handleChanges = {
    authorisationNumber: (authNum) => {
      setAuthorisationNumber(authNum);
      storeService.session.set.movementAuthorisationNumber(authNum);
    },

    contactPhone: (phone) => {
      setContactPhone(phone);
      storeService.session.set.movementContactPhone(phone);
    },

    departureTime: (time) => {
      setDepartureTime(time);
      storeService.session.set.movementDepartureTime(time);
    },

    driverFirstName: (firstName) => {
      setDriverFirstName(firstName);
      storeService.session.set.movementDriverFirstName(firstName);
    },

    driverLastName: (lastName) => {
      setDriverLastName(lastName);
      storeService.session.set.movementDriverLastName(lastName);
    },

    durationOfJourney: (duration) => {
      setDurationOfJourney(duration);
      storeService.session.set.movementDurationOfJourney(duration);
    },

    ownerFirstName: (firstName) => {
      setOwnerFirstName(firstName);
      storeService.session.set.movementOwnerFirstName(firstName);
    },

    ownerLastName: (lastName) => {
      setOwnerLastName(lastName);
      storeService.session.set.movementOwnerLastName(lastName);
    },

    recordTransportInformation: (record) => {
      setRecordTransportInformation(record);
      storeService.session.set.movementRecordTransportInformation(record);

      if (record === constants.option.recordTransportInformation.no) {
        storeService.session.remove.movementTransportedBy();
        clearTransportDetails();

        setDepartureTime({
          hours: null,
          minutes: null
        });
        setDurationOfJourney({
          days: '',
          hours: '',
          minutes: ''
        });

        storeService.session.remove.movementDurationOfJourney();
        storeService.session.remove.movementDepartureTime();
      }
    },

    transportedBy: (transportedBy) => {
      setTransportedBy(transportedBy);
      storeService.session.set.movementTransportedBy(transportedBy);

      clearTransportDetails();
    },

    transporter: (transporter) => {
      setTransporter(transporter);
      storeService.session.set.movementTransporter(transporter);
    },

    vehicleRegistrationNumber: (regNum) => {
      setVehicleRegistrationNumber(regNum);
      storeService.session.set.movementVehicleRegistrationNumber(regNum);

      validate.registrationNumber(regNum);
    }
  };

  useEffect(() => {
    const sessionRecordTransportInformation = storeService.session.get.movementRecordTransportInformation();

    if (sessionRecordTransportInformation) {
      setRecordTransportInformation(sessionRecordTransportInformation);

      if (sessionRecordTransportInformation === constants.option.recordTransportInformation.yes) {
        const sessionTransportedBy = storeService.session.get.movementTransportedBy();

        if (sessionTransportedBy) {
          setTransportedBy(sessionTransportedBy);

          const sessionTransporter = storeService.session.get.movementTransporter();
          const sessionContactPhone = storeService.session.get.movementContactPhone();
          const sessionDriverFirstName = storeService.session.get.movementDriverFirstName();
          const sessionDriverLastName = storeService.session.get.movementDriverLastName();
          const sessionVehicleRegistrationNumber = storeService.session.get.movementVehicleRegistrationNumber();
          const sessionAuthorisationNumber = storeService.session.get.movementAuthorisationNumber();
          const sessionOwnerFirstName = storeService.session.get.movementOwnerFirstName();
          const sessionOwnerLastName = storeService.session.get.movementOwnerLastName();
          const sessionDepartureTime = storeService.session.get.movementDepartureTime();
          const sessionDurationOfJourney = storeService.session.get.movementDurationOfJourney();

          const hasOnlyZeros = (obj) => Object.values(obj).every((val) => val === 0);

          if (sessionTransporter) {
            setTransporter(sessionTransporter);
          }
          if (sessionContactPhone) {
            setContactPhone(sessionContactPhone);
          }
          if (sessionDriverFirstName) {
            setDriverFirstName(sessionDriverFirstName);
          }
          if (sessionDriverLastName) {
            setDriverLastName(sessionDriverLastName);
          }
          if (sessionVehicleRegistrationNumber) {
            setVehicleRegistrationNumber(sessionVehicleRegistrationNumber);
            validate.registrationNumber(sessionVehicleRegistrationNumber);
          }
          if (sessionAuthorisationNumber) {
            setAuthorisationNumber(sessionAuthorisationNumber);
          }
          if (sessionOwnerFirstName) {
            setOwnerFirstName(sessionOwnerFirstName);
          }
          if (sessionOwnerLastName) {
            setOwnerLastName(sessionOwnerLastName);
          }
          if (sessionDepartureTime && !hasOnlyZeros(sessionDepartureTime)) {
            setDepartureTime(sessionDepartureTime);
          }
          if (sessionDurationOfJourney && !hasOnlyZeros(sessionDurationOfJourney)) {
            setDurationOfJourney(sessionDurationOfJourney);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkSubmitButtonStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authorisationNumber,
    contactPhone,
    contactPhoneError,
    departureTime,
    durationOfJourney,
    durationOfJourneyError,
    driverFirstName,
    driverFirstNameError,
    driverLastName,
    driverLastNameError,
    hoursError,
    minutesError,
    ownerFirstName,
    ownerFirstNameError,
    ownerLastName,
    ownerLastNameError,
    recordTransportInformation,
    transportedBy,
    transporter,
    transportError,
    vehicleRegistrationNumber,
    vehicleRegistrationNumberError
  ]);

  return (
    <>
      {ready &&
        <>
          <h2 className="h2">{t('label.transportInformation')}</h2>

          <AboutPanel
            descriptions={[
              { text: 'about.movementsStep3.desc1' }
            ]}
            title="about.genericTitle.step"
          />

          <TransportInformationBox
            animalDetails={animalDetails}
            movementData={movementData}
            numberBeingMoved={numberBeingMoved}
            setModal={setModal}
            speciesName={species.name}
          />
          <div className="row">
            <div className="col">
              <div className="section">
                <RadioButtons
                  ids={[
                    constants.option.recordTransportInformation.yes,
                    constants.option.recordTransportInformation.no
                  ]}
                  name="recordTransportInformation"
                  onChange={(event) => handleChanges.recordTransportInformation(event.target.value)}
                  value={recordTransportInformation}
                />
              </div>
            </div>
          </div>
          <div className={classes.transportDetails}>
            {recordTransportInformation === constants.option.recordTransportInformation.yes &&
              <TransportDetails
                authorisationNumber={authorisationNumber}
                contactDetailsIndex={contactDetailsIndex}
                contactPhone={contactPhone}
                contactPhoneError={contactPhoneError}
                departureDate={movementData && movementData.departureDate ? movementData.departureDate : null}
                departureTime={departureTime}
                driverFirstName={driverFirstName}
                driverFirstNameError={driverFirstNameError}
                driverLastName={driverLastName}
                driverLastNameError={driverLastNameError}
                durationOfJourney={durationOfJourney}
                durationOfJourneyError={durationOfJourneyError}
                hoursError={hoursError}
                minutesError={minutesError}
                movementData={movementData}
                movementType={movementType}
                ownerFirstName={ownerFirstName}
                ownerFirstNameError={ownerFirstNameError}
                ownerLastName={ownerLastName}
                ownerLastNameError={ownerLastNameError}
                setAuthorisationNumber={handleChanges.authorisationNumber}
                setContactDetailsIndex={setContactDetailsIndex}
                setContactPhone={handleChanges.contactPhone}
                setContactPhoneError={setContactPhoneError}
                setDepartureTime={handleChanges.departureTime}
                setDriverFirstName={handleChanges.driverFirstName}
                setDriverFirstNameError={setDriverFirstNameError}
                setDriverLastName={handleChanges.driverLastName}
                setDriverLastNameError={setDriverLastNameError}
                setDurationOfJourney={handleChanges.durationOfJourney}
                setDurationOfJourneyError={setDurationOfJourneyError}
                setHoursError={setHoursError}
                setMinutesError={setMinutesError}
                setModal={setModal}
                setOwnerFirstName={handleChanges.ownerFirstName}
                setOwnerFirstNameError={setOwnerFirstNameError}
                setOwnerLastName={handleChanges.ownerLastName}
                setOwnerLastNameError={setOwnerLastNameError}
                setTransportedBy={handleChanges.transportedBy}
                setTransporter={handleChanges.transporter}
                setTransportError={setTransportError}
                setVehicleRegistrationNumber={handleChanges.vehicleRegistrationNumber}
                transportedBy={transportedBy}
                transporter={transporter}
                transportError={transportError}
                vehicleRegistrationNumber={vehicleRegistrationNumber}
                vehicleRegistrationNumberError={vehicleRegistrationNumberError}
              />
            }
            <Button
              buttonType="primary"
              disabled={submitDisabled}
              label="button.continue"
              onClick={nextPage}
            />
          </div>
        </>
      }
    </>
  );
};

MovementStep3.propTypes = {
  animalDetails: PropTypes.object,
  fromSummary: PropTypes.bool,
  movementData: PropTypes.object,
  movementType: PropTypes.string,
  numberBeingMoved: PropTypes.number,
  setDataChanged: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setTransportDetails: PropTypes.func,
  species: PropTypes.object
};

export default MovementStep3;
