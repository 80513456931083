import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'components/base/button/button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Routing from 'routing';
import storeService from 'services/storeService';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import InputDate from 'components/base/inputDate/inputDate';
import helpers from 'services/helpers';
import VetTitle from 'frontEnd/vetModule/components/vetTitle';
import VetClaimsInfo from 'frontEnd/vetModule/components/vetClaimsInfo';

const VetCreate = ({ setModal }) => {
  const { instance } = useMsal();
  const { ready, t } = useTranslation(),
    history = useHistory(),
    [isCreateDateValid, setIsCreateDateValid] = useState(false),
    [createDateError, setCreateDateError] = React.useState(''),
    [attestationDate, setAttestationDate] = React.useState('');

  // Reatin Attestation date from session storage
  useEffect(() => {
    if (storeService.session.get.vetCPHData()) {
      const newAttestationDate = storeService.session.get.vetCPHData().attestationDate || '';
      if (newAttestationDate) {
        setAttestationDate(newAttestationDate);
        setIsCreateDateValid(true);
      }
    }
    window.scrollTo(0, 0);
  }, []);

  const routeToDetailsPage = () => {
    history.push(Routing.vetDetails);
  };

  const routeToSummaryPage = () => {
    history.push(Routing.vetSummary);
  };

  // Onselect date from calender
  const handleVetDate = (date) => {
    let hasDateError = false;
    setIsCreateDateValid(false);
    setCreateDateError('');

    if (date) {
      const dateIsInTheFuture = helpers.date.is.inTheFuture(date);
      const dateOverAYearAge = helpers.date.is.overAYearAgo(date);

      // Future date validation
      if (dateIsInTheFuture) {
        setCreateDateError('error.genericDateAboveRange');
        hasDateError = true;
      }

      if (dateOverAYearAge) {
        setCreateDateError('error.dateGT1year');
        hasDateError = true;
      }

      if (!hasDateError) {
        setIsCreateDateValid(true);
        if (storeService.session.get.vetCPHData()) {
          const newCphData = { ...storeService.session.get.vetCPHData(), attestationDate: date };
          storeService.session.remove.vetCPHData();
          storeService.session.set.vetCPHData(newCphData);
        }
      }
    } else {
      const newCphData = { ...storeService.session.get.vetCPHData(), attestationDate: '' };
      storeService.session.remove.vetCPHData();
      storeService.session.set.vetCPHData(newCphData);
    }
  };
  if (storeService.session.get.restrictSummaryBack()) {
    helpers.restrictBackFromVetSummary();
    instance.logoutRedirect({ postLogoutRedirectUri: '/' })
      .catch((error) => {
      console.error(error); //eslint-disable-line
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  return (
    <>
      {ready &&
      <>
        <main className={classes.mainContanier}>
          <VetTitle setModal={setModal} />

          <section className={classes.bodySection}>
            <h3 className={classes.sectionTitle}>{t('vetContent.createNew')}</h3>
            <div>
              <InputDate
                error={createDateError}
                id={'attestationCreateDate'}
                labelPosition="above"
                labelText={t('vetContent.visitDate')}
                maxDate={helpers.vetmaxDate.todayDate()}
                minDate={helpers.pastOneYearFromToday.datePastOneYear()}
                onChange={(event) => handleVetDate(event.target.value)}
                setError={setCreateDateError}
                value={attestationDate}
                yearLength={4}
              />
            </div>
            <div className={classes.summaryBlock + ' ' + classes.topSpace}>
              <VetClaimsInfo border="noBorder" />
            </div>
          </section>

          <section className={classes.buttonSection + ' ' + classes.displayBtnBoth}>
            <Button
              buttonType="tertiary"
              label="button.back"
              onClick={routeToDetailsPage}
            />
            <Button
              buttonType="primary"
              disabled={!isCreateDateValid || createDateError !== ''}
              label="label.next"
              onClick={routeToSummaryPage}
            />
          </section>
        </main>
      </>
      }
    </>
  );
};

export default VetCreate;
