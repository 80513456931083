import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/linker/linker.module.scss';

const Linker = ({
  ariaLabel,
  disabled,
  label,
  linkType,
  onClick,
  state,
  to
}) => {
  const { ready, t } = useTranslation();

  const componentClassNames = [
    linkType && linkType === 'back' ? classes.backLink : '',
    linkType && linkType !== 'back' ? classes[linkType] : '',
    !linkType || (linkType && linkType !== 'back') ? classes.link : ''
  ].join(' ').trim();

  return (
    <>
      {ready &&
        <Link
          aria-label={t(ariaLabel)}
          className={componentClassNames}
          disabled={disabled}
          onClick={onClick}
          to={{
            pathname: to ? to : '',
            state: state ? state : null
          }}
        >
          {linkType === 'back' &&
            <i className={`bi bi-chevron-left + '' + ${classes.icon}`} />
          }
          {t(label)}
        </Link>
      }
    </>
  );
};

Linker.propTypes = {
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  linkType: PropTypes.string,
  onClick: PropTypes.func,
  state: PropTypes.exact({
    data: PropTypes.any
  }),
  to: PropTypes.string
};

export default Linker;
