import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import constants from 'services/constants';
import navigation from 'services/navigation';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import Table from 'components/table/table';

const MovementSearchGatherings = ({
  setModal
}) => {
  const { ready } = useTranslation();
  const history = useHistory();

  const sessionSearchResults = storeService.session.get.searchResultsMovements();
  const [data, setData] = React.useState(sessionSearchResults ? sessionSearchResults : []);
  const [renderTable, setRenderTable] = React.useState(false);

  React.useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.abattoirSearch.length) !== Routing.abattoirSearch &&
        path.pathname.substring(0, Routing.abattoirMovementDetails.length) !== Routing.abattoirMovementDetails &&
        path.pathname.substring(0, Routing.assemblySearch.length) !== Routing.assemblySearch &&
        path.pathname.substring(0, Routing.assemblyMovementDetails.length) !== Routing.assemblyMovementDetails &&
        path.pathname.substring(0, Routing.collectionSearch.length) !== Routing.collectionSearch &&
        path.pathname.substring(0, Routing.collectionMovementDetails.length) !== Routing.collectionMovementDetails &&
        path.pathname.substring(0, Routing.marketSearch.length) !== Routing.marketSearch &&
        path.pathname.substring(0, Routing.marketMovementDetails.length) !== Routing.marketMovementDetails &&
        path.pathname.substring(0, Routing.showSearch.length) !== Routing.showSearch &&
        path.pathname.substring(0, Routing.showMovementDetails.length) !== Routing.showMovementDetails
      ) {
        storeService.session.removeAll.searchResults();
        storeService.session.removeAll.tableFilters();
        unListen();
      }
    });
  }, [history]);

  return (
    <>
      {ready &&
        <>
          <Table
            data={data}
            filtersComponents={{
              [constants.filters.outside]: 'GatheringSearch'
            }}
            renderTable={renderTable || data.length > 0}
            rowLinks={{
              url: () => navigation.movementDetails(),
              accessor: 'requestId',
              state: (row) => ({
                data: {
                  ...row,
                  activeTabId: storeService.session.get.tableFiltersGatheringSearch().activeTabId
                }
              })
            }}
            setData={setData}
            setModal={setModal}
            setRenderTable={setRenderTable}
            store={{
              filters: 'GatheringSearch',
              data: 'GatheringSearch'
            }}
          />
        </>
      }
    </>
  );
};

MovementSearchGatherings.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default MovementSearchGatherings;
