import React, { useState, useEffect } from 'react';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';

const DropdownTagNumbersAvailable = ({
  applicationDate,
  className,
  disabled,
  includeSystemAppliedTags,
  label,
  name,
  onChange,
  setModal,
  tooltip,
  value
}) => {
  const [itemList, setItemList] = useState([]);
  const [systemAppliedTagsPresent, setSystemAppliedTagsPresent] = useState(false);
  const [notAppliedTagsPending, setNotAppliedTagsPending] = useState(false);
  const [systemAppliedTagsPending, setSystemAppliedTagsPending] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (!notAppliedTagsPending) {
      setNotAppliedTagsPending(true);

      bff
        .get('/tagsNotApplied', {
          cancelToken: source.token,
          params: {
            speciesId: storeService.session.get.species().id,
            cph: storeService.session.get.holding().value
          }
        })
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal, setNotAppliedTagsPending)) {
            setItemList((items) => helpers.sortArrayOfObjects([
              ...items,
              ...res.data
            ], 'name', constants.sorting.ascending));

            setNotAppliedTagsPending(false);
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setNotAppliedTagsPending(false);
            errors.BFF(error, setModal);
          }
        });
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (includeSystemAppliedTags && !systemAppliedTagsPending && applicationDate) {
      setSystemAppliedTagsPending(true);

      bff
        .get('/systemAppliedTags', {
          cancelToken: source.token,
          params: {
            applicationDate: helpers.date.formatForSubmission(applicationDate)
          }
        })
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal, setSystemAppliedTagsPending)) {
            setItemList((items) => helpers.sortArrayOfObjects([
              ...items.filter((tag) => !tag.isSystemApplied),
              ...res.data.map((tag) => ({
                label: tag.systemAppliedTagNumber,
                name: tag.systemAppliedTagNumber + ' *',
                value: tag.systemAppliedTagNumber,
                isSystemApplied: true
              }))
            ], 'name', constants.sorting.ascending));
            setSystemAppliedTagsPresent(Boolean(res.data?.length));
            setSystemAppliedTagsPending(false);
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setSystemAppliedTagsPending(false);
            errors.BFF(error, setModal);
          }
        });
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeSystemAppliedTags, applicationDate]);

  return (
    <DropdownSearch
      className={className}
      disabled={disabled}
      hint={tooltip}
      id="tagNumberDropdown"
      label={label}
      name={name}
      onChange={onChange}
      optionList={itemList}
      pending={notAppliedTagsPending || systemAppliedTagsPending}
      pendingError="error.noBirthTags"
      placeholder="prompt.pleaseSelectOrEnter"
      tooltip={systemAppliedTagsPresent ? 'tooltips.tagApplications.systemAppliedTags' : ''}
      value={value}
    />
  );
};

DropdownTagNumbersAvailable.propTypes = {
  applicationDate: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  includeSystemAppliedTags: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  value: PropTypes.string
};

export default DropdownTagNumbersAvailable;
