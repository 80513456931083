import React from 'react';
import bff, { isCancel } from 'services/bff';
import Button from 'components/base/button/button';

const ConnectionCheck = () => {
  const [pending, setPending] = React.useState(false);
  const [connectionOK, setConnectionOK] = React.useState(null);

  const checkConnection = () => {
    setPending(true);
    setConnectionOK(null);

    bff
      .get('/requestTypes')
      .then(() => {
        setPending(false);
        setConnectionOK(true);
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setPending(false);
          setConnectionOK(false);
        }
      });
  };

  return (
    <>
      <Button
        buttonType="tertiary"
        disabled={pending}
        label="Check connection"
        onClick={checkConnection}
      />
      {connectionOK === true &&
        <p>Connection OK</p>
      }
      {connectionOK === false &&
        <p>Connection OK - 401 Unauthorised</p>
      }
    </>
  );
};
export default ConnectionCheck;
