import React from 'react';
import classes from 'pages/landing/landingModal/landingModal.module.scss';
import PropTypes from 'prop-types';

const LandingModal = ({
  children,
  onClick,
  open = false
}) => {
  return (
    <div
      className={`${classes.modal} ${open ? classes.modalOpen : ''}`}
      onClick={onClick}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          aria-label="Close modal"
          className={classes.modalClose}
          onClick={onClick}
        >
          <span className={classes.modalCloseIcon} />
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
};

LandingModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.node
    ),
    PropTypes.node
  ]),
  onClick: PropTypes.func,
  open: PropTypes.bool
};

export default LandingModal;
