import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Switch, useHistory } from 'react-router-dom';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType, InteractionType } from '@azure/msal-browser';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { b2cPolicies } from 'services/b2cService';
import appInsightsService from 'services/appInsightsService';
import constants from 'services/constants';
import featureToggles from 'services/featureToggles';
import initialise from 'services/initialise';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import ActivityDetails from 'frontEnd/activityDetails/activityDetails';
import Animals from 'frontEnd/animals/animals';
import AnimalDetails from 'frontEnd/animals/animalDetails/animalDetails';
import Births from 'frontEnd/births/births';
import BoAnimalActivity from 'backOffice/animalActivity/animalActivity';
import BoEditCustomer from 'backOffice/editCustomer/editCustomer';
import BoEditMovement from 'backOffice/editMovement/editMovement';
import BoHoldingActivity from 'backOffice/holdingActivity/holdingActivity';
import BoPaperDetails from 'backOffice/paperDetails/paperDetails';
import BoSearchMovements from 'backOffice/searchMovements/searchMovements';
import BoSearchRequestId from 'backOffice/searchRequestId/searchRequestId';
import BoUserActivity from 'backOffice/userActivity/userActivity';
import BoVARecordDeclaration from 'backOffice/vaModule/vaRecordDeclaration';
import BoVerifyCustomer from 'backOffice/verifyCustomer/verifyCustomer';
import BoViewMovement from 'backOffice/viewMovement/viewMovement';
import BoViewRequestId from 'backOffice/viewRequestId/viewRequestId';
import ConnectionCheck from 'pages/connectionCheck/connectionCheck';
import Deaths from 'frontEnd/deaths/deaths';
import Handshake from 'frontEnd/holdingSummary/handshake/handshake';
import HoldingSummary from 'frontEnd/holdingSummary/holdingSummary';
import HoldingDetails from 'frontEnd/holdingRegister/holdingDetails/holdingDetails';
import HoldingRegister from 'frontEnd/holdingRegister/holdingRegister';
import Home from 'frontEnd/home/home';
import Landing from 'pages/landing/landing';
import Logout from 'pages/logout/logout';
import MissingAndFound from 'frontEnd/deaths/missingAndFound/missingAndFound';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import MovementsGatherings from 'frontEnd/movementsGatherings/movementsGatherings';
import Movements from 'frontEnd/movements/movements';
import MovementSearchGatherings from 'frontEnd/movementSearch/movementSearch';
import ProtectedRoute from 'pages/protectedRoute/protectedRoute';
import TagApplication from 'frontEnd/tagging/application/tagApplication';
import TagReplacement from 'frontEnd/tagging/replacement/tagReplacement';
import Tokens from 'pages/tokens/tokens';
import ViewMovement from 'frontEnd/viewMovement/viewMovement';
import VetHomePage from 'frontEnd/vetModule/vetHome';
import VetCreate from 'frontEnd/vetModule/vetCreate/vetCreate';
import VetDeclaration from 'frontEnd/vetModule/vetDeclaration/vetDeclaration';
import VetDetails from 'frontEnd/vetModule/vetDetails/vetDetails';
import VetSummary from 'frontEnd/vetModule/vetSummary/vetSummary';
import AbattoirAttestation from 'frontEnd/abattoirAttestation/abattoirAttestationHome';
import AbattoirAttestationTagResult from 'frontEnd/abattoirAttestation/abattoirAttestationTagResult/abattoirAttestationTagResult';
import AbattoirAttestationDeclaration from 'frontEnd/abattoirAttestation/abattoirAttestationDeclaration/abattoirAttestationDeclaration';
import AbattoirManualTags from 'frontEnd/abattoirAttestation/manualTags/manualTags';
import AbattoirUploadTags from 'frontEnd/abattoirAttestation/uploadTags/uploadTags';
import AbattoirAttestationDeclarationTags from 'frontEnd/abattoirAttestation/abattoirAttestationDeclarationTags/abattoirAttestationDeclarationTags';
import AbattoirCheckHolding from 'frontEnd/abattoirAttestation/checkHolding/checkHolding';
import AbattoirAttestationResult from 'frontEnd/abattoirAttestation/abattoirAttestationResult/abattoirAttestationResult';
import helpers from 'services/helpers';

let initialised = false;

const Pages = () => {
  const { instance } = useMsal();
  const history = useHistory();

  const sessionPermission = storeService.session.get.permissions();

  const [eventError, setEventError] = React.useState({});
  const [permission, setPermission] = React.useState(sessionPermission ? sessionPermission : '');
  const [modal, setModal] = React.useState(null);
  const [loading, setLoading] = React.useState({
    breeds: false,
    holdings: false,
    requestTypes: false
  });

  const props = {
    permission,
    setModal,
    setPermission
  };

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword)
              .catch((error) => {
              console.error(error); //eslint-disable-line
              });
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup(b2cPolicies.authorities.forgotPassword)
              .catch((error) => {
                console.error(error); //eslint-disable-line
              });
          }
        }
      }
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf('AADB2C90091') === -1) {
          setEventError({
            id: 'login',
            title: 'error.b2c.login',
            message: event.error.errorMessage
          });
          appInsightsService.trackException(event.error);
        }
        storeService.cookie.removeAll.msal();
      }
      if (event.eventType === EventType.LOGOUT_FAILURE) {
        if (event.error) {
          setEventError({
            id: 'logout',
            title: 'error.b2c.logout',
            message: event.error
          });
          appInsightsService.trackException(event.error);
        }
      }
      if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        setEventError(null);
        if (event?.payload) {
          const account = event.payload.account;
          instance.setActiveAccount(account);
          storeService.cookie.set.username(event.payload.idTokenClaims.name);

          if (!initialised) {
            initialised = true;

            if (!sessionPermission) {
              const role = helpers.getUserRole(event.payload.idTokenClaims.roles);
              setPermission(role);
              storeService.session.set.permissions(role);

              featureToggles.init(setModal);
              initialise(setModal, setLoading, history, role);
              if (role === userRoleType.BACKOFFICE) {
                history.push(Routing.boVerifyCustomer);
              }
              if (role === userRoleType.RCVSVET) {
                storeService.session.set.vetClaims(event.payload.idTokenClaims);
              }
            }
          }

          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          const acr = event.payload.idTokenClaims.acr;
          if (acr === b2cPolicies.names.forgotPassword) {
            setEventError({
              id: 'forgotPassword',
              title: 'label.forgotPassword',
              message: 'error.msal.forgetPassword'
            });
            return instance.logout();
          } else if (acr === b2cPolicies.names.editProfile) {
            setEventError({
              id: 'editProfile',
              title: 'label.editProfile',
              message: 'error.msal.editProfile'
            });
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  return (
    <>
      {modal &&
        <ModalConfirm
          classNames={modal.classNames}
          id="customMessage"
          labelCancel={modal.labelCancel ? modal.labelCancel : 'button.ok'}
          labelConfirm={modal.labelConfirm ? modal.labelConfirm : null}
          modalClose={() => {
            if (modal.modalClose) {
              modal.modalClose();
            }
            setModal(null);
          }}
          modalConfirm={modal.modalConfirm ? modal.modalConfirm : null}
          modalMessage={modal.modalMessage}
          modalTitle={modal.modalTitle}
        />
      }

      <Switch>
        {/* Login */}
        <Route exact={true} path={Routing.connectionCheck} render={() => <ConnectionCheck />} />
        <Route exact={true} path={Routing.tokens} render={() => <Tokens />} />
        <Route exact={true} path={Routing.root} render={() => <Landing eventError={eventError} setEventError={setEventError} setLoading={setLoading} setModal={setModal} setPermission={setPermission} />} />
        <Route exact={true} path={Routing.logout} render={() => <Logout />} />
        <Redirect exact={true} from={Routing.home} to={sessionPermission === userRoleType.RCVSVET ? Routing.vetHome : Routing.holdings } />
        <Redirect exact={true} from={Routing.changePassword} to={Routing.logout} />
        <Redirect exact={true} from={Routing.activityDetails} to={Routing.userActivity} />

        <ProtectedRoute {...props} isExact={true} loading={loading} MountComponent={Home} path={Routing.holdings} usersAllowed={[userRoleType.GENERIC]} />
        <ProtectedRoute {...props} isExact={true} loading={loading} MountComponent={Home} path={Routing.userActivity} usersAllowed={[userRoleType.GENERIC]} />

        {/* New Vet */}
        <Redirect exact={true} from={Routing.vetPath} to={Routing.vetHome} />
        <ProtectedRoute {...props} MountComponent={VetHomePage} path={Routing.vetHome} usersAllowed={[userRoleType.RCVSVET]} />
        <ProtectedRoute {...props} MountComponent={VetCreate} path={Routing.vetCreate} usersAllowed={[userRoleType.RCVSVET]} />
        <ProtectedRoute {...props} MountComponent={VetDeclaration} path={Routing.vetDeclaration} usersAllowed={[userRoleType.RCVSVET]} />
        <ProtectedRoute {...props} MountComponent={VetDetails} path={Routing.vetDetails} usersAllowed={[userRoleType.RCVSVET]} />
        <ProtectedRoute {...props} MountComponent={VetSummary} path={Routing.vetSummary} usersAllowed={[userRoleType.RCVSVET]} />

        {/* Keeper */}
        <Redirect exact={true} from={Routing.keeperRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.movements} to={Routing.movementsStep1} />
        <Redirect exact={true} from={Routing.deaths} to={Routing.deathsStep1} />
        <Redirect exact={true} from={Routing.missingFound} to={Routing.missingFoundStep1} />
        <Redirect exact={true} from={Routing.keeperHandshakeDetails} to={Routing.keeperHolding} />
        <Redirect exact={true} from={Routing.activityUndo} to={Routing.userActivity} />
        <Redirect exact={true} from={Routing.tagging} to={Routing.taggingApplications} />
        <Redirect exact={true} from={Routing.holdingRegister} to={Routing.holdingRegisterDetails} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingSummary} path={Routing.keeperHolding} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} MountComponent={AnimalDetails} path={Routing.animals + ':tagNumber'} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={Animals} path={Routing.animals} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} MountComponent={Movements} path={Routing.movements} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} MountComponent={Deaths} path={Routing.deaths} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} MountComponent={MissingAndFound} path={Routing.missingFound} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} MountComponent={Handshake} path={Routing.keeperHandshakeDetails + ':handshakeId'} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} MountComponent={ActivityDetails} path={Routing.activityDetails + ':activityId'} usersAllowed={[userRoleType.ABATTOIR, userRoleType.AICENTRE, userRoleType.ASSEMBLY, userRoleType.COLLECTION, userRoleType.COMMON, userRoleType.GENERIC, userRoleType.KEEPER, userRoleType.MARKET, userRoleType.OTHER, userRoleType.PORT, userRoleType.QUARANTINE, userRoleType.SHOW, userRoleType.SLAUGHTERMARKET, userRoleType.STOREMARKET, userRoleType.VET]} />
        <ProtectedRoute {...props} MountComponent={ActivityDetails} path={Routing.activityUndo + ':activityId'} usersAllowed={[userRoleType.ABATTOIR, userRoleType.AICENTRE, userRoleType.ASSEMBLY, userRoleType.COLLECTION, userRoleType.COMMON, userRoleType.GENERIC, userRoleType.KEEPER, userRoleType.MARKET, userRoleType.OTHER, userRoleType.PORT, userRoleType.QUARANTINE, userRoleType.SHOW, userRoleType.SLAUGHTERMARKET, userRoleType.STOREMARKET, userRoleType.VET]} />
        <ProtectedRoute {...props} MountComponent={Births} path={Routing.births} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} MountComponent={TagReplacement} path={Routing.taggingReplacements} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} MountComponent={TagApplication} path={Routing.taggingApplications} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingDetails} path={Routing.holdingRegisterDetails} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingRegister} page={constants.holdingRegister.type.annualInventory} path={Routing.holdingRegisterAnnualInventory} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingRegister} page={constants.holdingRegister.type.births} path={Routing.holdingRegisterBirths} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingRegister} page={constants.holdingRegister.type.deaths} path={Routing.holdingRegisterDeaths} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingRegister} page={constants.holdingRegister.type.download} path={Routing.holdingRegisterDownload} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingRegister} page={constants.holdingRegister.type.movementsOff} path={Routing.holdingRegisterMovementsOff} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingRegister} page={constants.holdingRegister.type.movementsOn} path={Routing.holdingRegisterMovementsOn} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingRegister} page={constants.holdingRegister.type.adjacentMovements} path={Routing.holdingRegisterAdjacentMovements} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingRegister} page={constants.holdingRegister.type.tagApplications} path={Routing.holdingRegisterTaggingApplications} usersAllowed={[userRoleType.KEEPER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={HoldingRegister} page={constants.holdingRegister.type.tagReplacements} path={Routing.holdingRegisterTaggingReplacement} usersAllowed={[userRoleType.KEEPER]} />

        {/* BackOffice */}
        <Redirect exact={true} from={Routing.boRoot} to={Routing.boVerifyCustomer} />
        <Redirect exact={true} from={Routing.boSearchMovements} to={Routing.boSearchMovementsByDateAndCph} />
        <ProtectedRoute {...props} MountComponent={BoPaperDetails} path={Routing.boMovements} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} MountComponent={BoPaperDetails} path={Routing.boMovementsConfirm} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} MountComponent={BoViewMovement} path={Routing.boMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} MountComponent={BoViewRequestId} path={Routing.boRequestIdDetails + ':requestId'} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoAnimalActivity} path={Routing.boAnimalActivity} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoAnimalActivity} path={Routing.boAnimalActivityAnimalDetails} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoAnimalActivity} path={Routing.boAnimalActivityMovementHistory} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoAnimalActivity} path={Routing.boAnimalActivityProgeny} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoAnimalActivity} path={Routing.boAnimalActivityTaggingHistory} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoEditMovement} path={Routing.boEditMovements} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivity} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityAnimalsAdded} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityAnimalsOnHolding} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityAnnualInventory} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityAdjacentMovements} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityAssociatedHoldings} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityDeaths} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityHoldingDetails} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityMovements} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityPendingReviews} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityTaggingApplications} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boHoldingActivityTaggingReplacement} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoSearchMovements} path={Routing.boSearchMovementsByDateAndCph} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoSearchMovements} path={Routing.boSearchMovementsByMovementReference} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoSearchMovements} path={Routing.boSearchMovementsByPaperId} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoSearchRequestId} path={Routing.boSearchRequestId} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivity} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivityAnimalsAdded} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivityAnnualInventory} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivityAll} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivityBirths} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivityDeaths} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivityMovements} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivityMovementAmends} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivityMovementReviews} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivityTaggingApplications} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoUserActivity} path={Routing.boUserActivityTaggingReplacement} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoEditCustomer} path={Routing.boEditCustomer} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoVerifyCustomer} path={Routing.boVerifyCustomer} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoHoldingActivity} path={Routing.boVaRecords} usersAllowed={[userRoleType.BACKOFFICE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={BoVARecordDeclaration} path={Routing.boVaRecordDeclaration + ':declarationId'} usersAllowed={[userRoleType.BACKOFFICE]} />
        {/* Abattoir */}
        <Redirect exact={true} from={Routing.abattoirRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.abattoirMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.abattoirMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.ABATTOIR]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.abattoirMovements} usersAllowed={[userRoleType.ABATTOIR]} />
        <ProtectedRoute {...props} MountComponent={AbattoirAttestation} path={Routing.abattoirAttestation} usersAllowed={[userRoleType.ABATTOIR]} />
        <ProtectedRoute {...props} MountComponent={AbattoirAttestationTagResult} path={Routing.abattoirAttestationTagResult} usersAllowed={[userRoleType.ABATTOIR]} />
        <ProtectedRoute {...props} MountComponent={AbattoirManualTags} path={Routing.abattoirManualTags} usersAllowed={[userRoleType.ABATTOIR]} />
        <ProtectedRoute {...props} MountComponent={AbattoirUploadTags} path={Routing.abattoirUploadTags} usersAllowed={[userRoleType.ABATTOIR]} />
        <ProtectedRoute {...props} MountComponent={AbattoirCheckHolding} path={Routing.abattoirCheckHolding} usersAllowed={[userRoleType.ABATTOIR]} />
        <ProtectedRoute {...props} MountComponent={AbattoirAttestationResult} path={Routing.abattoirAttestationResult} usersAllowed={[userRoleType.ABATTOIR]} />
        <ProtectedRoute {...props} MountComponent={AbattoirAttestationDeclarationTags} path={Routing.abattoirAttestationDeclarationTags + ':tagId'} usersAllowed={[userRoleType.ABATTOIR]} />
        <ProtectedRoute {...props} MountComponent={AbattoirAttestationDeclaration} path={Routing.abattoirAttestationDeclaration + ':declarationId'} usersAllowed={[userRoleType.ABATTOIR]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.abattoirSearch} usersAllowed={[userRoleType.ABATTOIR]} />

        {/* AI Centre */}
        <Redirect exact={true} from={Routing.aiCentreRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.aiCentreMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.aiCentreMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.AICENTRE]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.aiCentreMovements} usersAllowed={[userRoleType.AICENTRE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.aiCentreSearch} usersAllowed={[userRoleType.AICENTRE]} />

        {/* Assembly */}
        <Redirect exact={true} from={Routing.assemblyRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.assemblyMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.assemblyMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.ASSEMBLY]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.assemblyMovements} usersAllowed={[userRoleType.ASSEMBLY]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.assemblySearch} usersAllowed={[userRoleType.ASSEMBLY]} />

        {/* Collections */}
        <Redirect exact={true} from={Routing.collectionRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.collectionMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.collectionMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.COLLECTION]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.collectionMovements} usersAllowed={[userRoleType.COLLECTION]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.collectionSearch} usersAllowed={[userRoleType.COLLECTION]} />

        {/* Common */}
        <Redirect exact={true} from={Routing.commonRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.commonMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.commonMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.COMMON]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.commonMovements} usersAllowed={[userRoleType.COMMON]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.commonSearch} usersAllowed={[userRoleType.COMMON]} />

        {/* Markets */}
        <Redirect exact={true} from={Routing.marketRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.marketMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.marketMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.MARKET]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.marketMovements} usersAllowed={[userRoleType.MARKET]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.marketSearch} usersAllowed={[userRoleType.MARKET]} />

        {/* Other */}
        <Redirect exact={true} from={Routing.otherRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.otherMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.otherMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.OTHER]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.otherMovements} usersAllowed={[userRoleType.OTHER]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.otherSearch} usersAllowed={[userRoleType.OTHER]} />

        {/* Port */}
        <Redirect exact={true} from={Routing.portRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.portMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.portMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.PORT]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.portMovements} usersAllowed={[userRoleType.PORT]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.portSearch} usersAllowed={[userRoleType.PORT]} />

        {/* Quarantine */}
        <Redirect exact={true} from={Routing.quarantineRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.quarantineMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.quarantineMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.QUARANTINE]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.quarantineMovements} usersAllowed={[userRoleType.QUARANTINE]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.quarantineSearch} usersAllowed={[userRoleType.QUARANTINE]} />

        {/* Shows */}
        <Redirect exact={true} from={Routing.showRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.showMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.showMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.SHOW]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.showMovements} usersAllowed={[userRoleType.SHOW]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.showSearch} usersAllowed={[userRoleType.SHOW]} />

        {/* Slaughter Market */}
        <Redirect exact={true} from={Routing.slaughterMarketRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.slaughterMarketMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.slaughterMarketMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.SLAUGHTERMARKET]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.slaughterMarketMovements} usersAllowed={[userRoleType.SLAUGHTERMARKET]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.slaughterMarketSearch} usersAllowed={[userRoleType.SLAUGHTERMARKET]} />

        {/* Store Market */}
        <Redirect exact={true} from={Routing.storeMarketRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.storeMarketMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.storeMarketMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.STOREMARKET]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.storeMarketMovements} usersAllowed={[userRoleType.STOREMARKET]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.storeMarketSearch} usersAllowed={[userRoleType.STOREMARKET]} />

        {/* Vet */}
        <Redirect exact={true} from={Routing.vetRoot} to={Routing.holdings} />
        <Redirect exact={true} from={Routing.vetMovementDetails} to={Routing.holdings} />
        <ProtectedRoute {...props} MountComponent={ViewMovement} path={Routing.vetMovementDetails + ':movementDocumentId'} usersAllowed={[userRoleType.VET]} />
        <ProtectedRoute {...props} MountComponent={MovementsGatherings} path={Routing.vetMovements} usersAllowed={[userRoleType.VET]} />
        <ProtectedRoute {...props} exact={true} MountComponent={MovementSearchGatherings} path={Routing.vetSearch} usersAllowed={[userRoleType.VET]} />

        {/* Version */}
        <Route exact={true} path={Routing.version} render={() => {
          window.location.href = 'version.html';
        }} />
      </Switch>
    </>
  );
};

const App = ({ msalInstance }) => {
  const userConfirmation = React.useCallback((message, callback) => {
    const node = document.getElementById('custom-prompt'),
      handleConfirm = () => {
        storeService.session.set.restrictBackFlow(true);
        cleanUp(true);
      },
      handleSummaryConfirm = () => {
        storeService.session.set.restrictSummaryBack(true);
        cleanUp(true);
      };
    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
      callback(answer);
    };
    if (message === 'vetDeclaration-restrict-back') {
      ReactDOM.render(
        <ModalConfirm
          cleanUp={cleanUp}
          id="dataChangedDeclaredId"
          labelCancel="vetContent.vetDeclaration.restrict-label-cancel"
          labelConfirm="vetContent.vetDeclaration.restrict-label-yes"
          modalConfirm={() => handleConfirm()}
          modalMessage="vetContent.vetDeclaration.restrict-modal-message"
          modalTitle="vetContent.vetDeclaration.restrict-title"
        />,
        node
      );
    } else if (message === 'vetSummary-restrict-back') {
      ReactDOM.render(
        <ModalConfirm
          cleanUp={cleanUp}
          id="dataChangedSummaryId"
          labelCancel="vetContent.vetDeclaration.restrict-label-cancel"
          labelConfirm="vetContent.vetDeclaration.restrict-label-yes"
          modalConfirm={() => handleSummaryConfirm()}
          modalMessage="vetContent.vetSummary.restrict-modal-message"
          modalTitle="vetContent.vetSummary.restrict-title"
        />,
        node
      );
    } else {
      ReactDOM.render(
        <ModalConfirm
          cleanUp={cleanUp}
          id="dataChanged"
          modalMessage="warning.unsavedChanges2"
          modalTitle="warning.youHaveUnsavedChanges"
        />,
        node
      );
    }
  }, []);

  return (
    <BrowserRouter getUserConfirmation={userConfirmation}>
      <MsalProvider instance={msalInstance}>
        <AppInsightsContext.Provider value={appInsightsService.reactPlugin}>
          <Pages />
          <div id="custom-prompt" />
        </AppInsightsContext.Provider>
      </MsalProvider>
    </BrowserRouter>
  );
};

export default App;
