import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/summary/animalSummary/animalSummary.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { tableColumns } from 'services/tableColumns';
import Button from 'components/base/button/button';
import Table from 'components/table/table';

const AnimalSummary = ({
  animalsData,
  batchesDataProp,
  label,
  onClick,
  setAnimalsData,
  setModal,
  tagOnly = false
}) => {
  const { ready, t } = useTranslation();

  const speciesId = storeService.session.get.species().id;

  const [paginationParams, setPaginationParams] = React.useState({
    batch: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        totalLabel: 'label.totalBatches'
      }
    },
    individual: constants.pagination.defaultParams
  });

  const numberOfAnimalsInBatches = animalsData?.batchList?.length > 0 ? animalsData.batchList.reduce((total, item) => total + parseInt(item.animalTotal), 0) : 0;
  const numberOfIndividualAnimals = animalsData?.animalsBeingMoved ? animalsData.animalsBeingMoved.length : (animalsData?.animalsToChange ? animalsData.animalsToChange.length : 0);
  const totalAnimalsInMovement = numberOfAnimalsInBatches + numberOfIndividualAnimals;

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      batch: {
        ...prevState.batch,
        [constants.pagination.bottom]: {
          ...prevState.batch[constants.pagination.bottom],
          total: animalsData?.batchList?.length
        }
      },
      individual: {
        ...prevState.individual,
        [constants.pagination.bottom]: {
          ...prevState.individual[constants.pagination.bottom],
          total: animalsData?.animalsBeingMoved ? animalsData.animalsBeingMoved.length : (animalsData?.animalsToChange ? animalsData.animalsToChange.length : 0)
        }
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animalsData]);

  return (
    <>
      {ready &&
        <>
          <div className={classes.animalSummary}>
            <div className={classes.title}>
              <h2>{t('label.animalDetails')}</h2>
            </div>

            {onClick &&
              <div className={classes.actions}>
                <Button
                  buttonType="link"
                  label="label.edit"
                  onClick={onClick}
                />
              </div>
            }
          </div>
          <div className={classes.animals}>
            {animalsData &&
              <>
                {numberOfAnimalsInBatches > 0 &&
                  <details className={classes.details}>
                    <summary className={classes.summary}>
                      {numberOfAnimalsInBatches}{' '}
                      {t(helpers.text.pluralCheck(numberOfAnimalsInBatches, 'label.animalIdentifiedByBatch'))}
                    </summary>
                    <div className={classes.tagList}>
                      <Table
                        columns={tableColumns.animalSummary.batch({ speciesId })}
                        data={animalsData.batchList}
                        dataProvided={true}
                        paginationParams={paginationParams.batch}
                        setData={setAnimalsData}
                        setDataProp={batchesDataProp}
                        setModal={setModal}
                        title={{
                          title: helpers.text.pluralCheck(animalsData.batchList?.reduce((total, item) => total + parseInt(item.animalTotal), 0), 'table.title.batches'),
                          data: [
                            animalsData.batchList?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
                          ]
                        }}
                      />
                    </div>
                  </details>
                }

                {numberOfIndividualAnimals > 0 &&
                  <details className={classes.details}>
                    <summary className={classes.summary}>
                      {numberOfIndividualAnimals}{' '}
                      {t(helpers.text.pluralCheck(numberOfIndividualAnimals, 'label.animalIdentifiedIndividually'))}
                    </summary>
                    <div className={classes.tagList}>
                      <Table
                        columns={tagOnly || helpers.species.isDeerId(speciesId)
                          ? tableColumns.animalSummary.individual.deer
                          : tableColumns.animalSummary.individual.nonDeer
                        }
                        data={animalsData.animalsBeingMoved}
                        dataProvided={true}
                        paginationParams={paginationParams.individual}
                        setData={setAnimalsData}
                        setDataProp={tagOnly ? 'devices' : 'animalsBeingMoved'}
                        setModal={setModal}
                        title={{
                          title: 'table.title.individualAnimals',
                          data: [
                            animalsData.animalsBeingMoved?.length
                          ]
                        }}
                      />
                    </div>
                  </details>
                }
              </>
            }

            {((animalsData &&
              numberOfAnimalsInBatches > 0 &&
              numberOfIndividualAnimals > 0) ||
              !animalsData) &&
              <>
                {totalAnimalsInMovement} {t(label)}
              </>
            }
          </div>
        </>
      }
    </>
  );
};

AnimalSummary.propTypes = {
  animalsData: PropTypes.object,
  batchesDataProp: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  setAnimalsData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  tagOnly: PropTypes.bool
};

export default AnimalSummary;
