import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Routing from 'routing';
import Linker from 'components/base/linker/linker';
import storeService from 'services/storeService';
import Table from 'components/table/table';
import PropTypes from 'prop-types';
import { tableColumns, initialSorting } from 'services/tableColumns';
import classes from 'frontEnd/abattoirAttestation/abattoirAttestationTagResult/abattoirAttestationTagResult.module.scss';

const AbattoirAttestationTagResult = ({ setModal }) => {
  const { ready, t } = useTranslation(),
    history = useHistory(),
    [loadPending, setLoadPending] = React.useState(true),
    [tagAnimalList, setTagAnimalList] = useState([]),
    [renderTable, setRenderTable] = React.useState(false),
    tagDetails = storeService.session.get.abattoirTagDetails() || {};

  const species = tagDetails.species ? tagDetails.species : '';
  const deviceIdentifiers = tagDetails.deviceIdentifiers ? tagDetails.deviceIdentifiers : '';
  const returnUrl = tagDetails.prevPage === 'manualTag' ? Routing.abattoirManualTags : Routing.abattoirUploadTags,
    printAndDownload = () => {
      window.print();
    };

  /* Table param for the post call */
  const tableParamsForPost = {
    request: {
      url: '/speciesIdAndDeviceIdentifiers',
      postData: {
        species: species,
        deviceIdentifiers: deviceIdentifiers
      }
    }
  };

  useEffect(() => {
    if (storeService.session.get.abattoirTagsData()) {
      storeService.session.remove.abattoirTagsData();
      storeService.session.set.abattoirTagsData(tagAnimalList);
    } else {
      storeService.session.set.abattoirTagsData(tagAnimalList);
    }
  }, [tagAnimalList]);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.abattoirAttestationTagResult.length) !== Routing.abattoirAttestationTagResult) {
        storeService.session.removeAll.tableFilters();
        unListen();
      }
    });
  }, [history]);

  return (
    <>
      {ready && tagAnimalList &&
        <>
          <h1 className="h1">{t('abattoirAttestation.vetHeading')}</h1>
          <div>
            <Linker
              label="button.back"
              linkType="back"
              to={returnUrl}
            />
          </div>
          <div>
            <h3 className="h3">{t('abattoirAttestation.abattoirAttestationResult')}</h3>
            {tagAnimalList &&
              <Table
                classNames={['vetAnimalTable']}
                columns={tableColumns.manualTagDetails}
                data={tagAnimalList}
                dataProvided={false}
                generatePostCall={true}
                initialSortBy={initialSorting.manualTagDetails.tagNumber.accessor}
                initialSortDirection={initialSorting.manualTagDetails.tagNumber.direction}
                loadPending={loadPending}
                noDataLabel="label.noData"
                params={tableParamsForPost}
                postResponse={true}
                renderTable={renderTable}
                setData={setTagAnimalList}
                setLoadPending={setLoadPending}
                setModal={setModal}
                setRenderTable={setRenderTable}
                store={{
                  filters: 'AbattoirTags',
                  data: 'AbattoirTags'
                }}
              />
            }
          </div>

          {tagAnimalList.length > 0 &&
            <span className={classes.abattoirPrint} onClick={printAndDownload} >{t('button.printSave')}</span>
          }
        </>
      }
    </>
  );
};

AbattoirAttestationTagResult.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default AbattoirAttestationTagResult;
