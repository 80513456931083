import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classes from 'frontEnd/movements/step3/durationInputFields/durationInputFields.module.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import NumberEntry from 'components/base/numberEntry/numberEntry';
import ErrorLabel from 'components/base/errorLabel/errorLabel';

const DurationInput = ({
  hoursError,
  id,
  isTimePicker,
  label,
  maxTime,
  minTime,
  minutesError,
  setHoursError,
  setMinutesError,
  setTime,
  time
}) => {
  const handleChanges = {
    timePicker: (input) => {
      setTime({
        hours: input ? moment(input).hours() : null,
        minutes: input ? moment(input).minutes() : null
      });
    },

    days: (input, prevState) => {
      setTime({ ...prevState, days: parseInt(input) });
    },

    hours: (input, prevState) => {
      if (!isNaN(input) && input <= 23) {
        setTime({ ...prevState, hours: parseInt(input) });
        setHoursError(false);
      } else {
        setHoursError(true);
      }
    },

    minutes: (input, prevState) => {
      if (!isNaN(input) && input <= 59) {
        setTime({ ...prevState, minutes: parseInt(input) });
        setMinutesError(false);
      } else {
        setMinutesError(true);
      }
    }
  };

  return (
    <>
      {(hoursError || minutesError) &&
        <ErrorLabel label="movements.transport-information.transport-details.errors.time-error" />
      }

      {isTimePicker &&
        <>
          <FieldLabel
            label="movements.transport-information.transport-details.departureTime"
            name={id}
          />
          <div className={'timePicker ' + classes.timePicker}>
            <DatePicker
              className={classes.timePickerInput}
              dateFormat="h:mm aa"
              maxTime={maxTime}
              minTime={minTime}
              onChange={handleChanges.timePicker}
              selected={time}
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              timeIntervals={15}
            />
          </div>
        </>
      }

      {!isTimePicker &&
        <>
          {/*<Hint*/}
          {/*  hint="movements.transport-information.transport-details.durationOfJourneyDesc"*/}
          {/*/>*/}
          <div className={classes.durationInput}>
            <div className={classes.days}>
              <NumberEntry
                id={label + 'Days'}
                label="movements.transport-information.transport-details.days"
                max="31"
                min="0"
                name="durationOfJourney"
                onBlur={(event) => handleChanges.days(event.target.value, time)}
                onChange={(event) => setTime({ ...time, days: event.target.value })}
                value={time ? time.days : ''}
              />
            </div>
            <div className={classes.hours}>
              <NumberEntry
                id={label + 'Hours'}
                inputError={hoursError}
                label="movements.transport-information.transport-details.hours"
                max="23"
                min="0"
                name="durationOfJourney"
                onBlur={(event) => handleChanges.hours(event.target.value, time)}
                onChange={(event) => setTime({ ...time, hours: event.target.value })}
                value={time ? time.hours : ''}
              />
            </div>
            <div className={classes.minutes}>
              <NumberEntry
                id={label + 'Minutes'}
                inputError={minutesError}
                label="movements.transport-information.transport-details.minutes"
                max="59"
                min="0"
                name="durationOfJourney"
                onBlur={(event) => handleChanges.minutes(event.target.value, time)}
                onChange={(event) => setTime({ ...time, minutes: event.target.value })}
                value={time ? time.minutes : ''}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

DurationInput.propTypes = {
  hoursError: PropTypes.bool,
  id: PropTypes.string,
  isTimePicker: PropTypes.bool,
  label: PropTypes.string,
  maxTime: PropTypes.object,
  minTime: PropTypes.object,
  minutesError: PropTypes.bool,
  setHoursError: PropTypes.func,
  setMinutesError: PropTypes.func,
  setTime: PropTypes.func,
  time: PropTypes.object
};

export default DurationInput;
