import React from 'react';
import classes from 'components/base/loading/loading.module.scss';

const Loading = () => {
  return (
    <div className={classes.loading}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loading;
