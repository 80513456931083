import React, { useEffect, useRef } from 'react';
import classes from 'backOfficeComponents/sections/sourceDocument/sourceDocument.module.scss';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import { useTranslation } from 'react-i18next';
import Legend from 'components/base/legend/legend';
import PropTypes from 'prop-types';
import userRoleType from 'services/userRoleType';
import CheckBox from 'backOfficeComponents/base/checkbox/checkbox';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import InputDate from 'components/base/inputDate/inputDate';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';

const SourceDocument = ({
  data,
  props,
  setData,
  setDataChanged,
  setProps
}) => {
  const { t } = useTranslation();
  const documentIdRef = useRef(null);

  useEffect(() => {
    if (props.paperId === '') {
      documentIdRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.validation > 0) {
      validateElements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.validation]);

  useEffect(() => {
    if (data.documentType !== 'keeper' || !data.speciesId) {
      setData((prevState) => ({ ...prevState, permitMove: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.documentType]);

  const getError = (label) => {
    return { type: constants.errorAndWarning.error, value: label };
  };

  const dateError = () => {
    return props.receivedDateError && helpers.response.isError(props.receivedDateError.type) ? props.receivedDateError.value : null;
  };

  const dateWarning = () => {
    return props.receivedDateError && helpers.response.isWarning(props.receivedDateError.type) ? props.receivedDateError.value : null;
  };

  const validateDocumentId = () => {
    let value = '';
    if (data.documentId) {
      if (data.documentId.length > config.WIDTH_DOCUMENT_ID) {
        value = getError('movementDetails.sourceDocument.documentIdLengthError');
      }
    } else if (data.documentId?.length === 0) {
      value = getError('movementDetails.sourceDocument.documentIdError');
    }
    setProps((prevState) => ({
      ...prevState,
      documentIdError: value
    }));
    return !value;
  };

  const validateDate = (date) => {
    let results = true;
    let value = '';
    const dateToValidate = date ? date : data.receivedDate;

    if (!dateToValidate) {
      value = getError('movementDetails.sourceDocument.dateReceivedError');
      results = false;
    } else if (helpers.date.is.invalid(dateToValidate)) {
      value = getError('movementDetails.sourceDocument.dateReceivedError');
      results = false;
    } else if (helpers.date.is.inTheFuture(dateToValidate)) {
      value = getError('movementDetails.sourceDocument.dateReceivedFutureError');
      results = false;
    }

    setProps((prevState) => ({
      ...prevState,
      receivedDateError: value
    }));
    return results;
  };

  const validateSpecies = () => {
    const results = (!data.speciesId || data.speciesId.length === 0);
    const value = results ? getError('movementDetails.sourceDocument.speciesError') : '';
    setProps((prevState) => ({
      ...prevState,
      speciesError: value
    }));
    return !results;
  };

  const validateDocumentType = () => {
    const results = (!data.documentType);
    const value = results ? getError('movementDetails.sourceDocument.documentTypeError') : '';
    setProps((prevState) => ({
      ...prevState,
      documentTypeError: value
    }));
    return !results;
  };

  const validateDocumentSource = () => {
    const results = (!data.documentSource);
    const value = results ? getError('movementDetails.sourceDocument.documentSourceError') : '';
    setProps((prevState) => ({
      ...prevState,
      documentSourceError: value
    }));
    return !results;
  };

  const validateElements = () => {
    const idValid = validateDocumentId();
    const dateValid = validateDate();
    const speciesValid = validateSpecies();
    const typeValid = validateDocumentType();
    const sourceValid = validateDocumentSource();

    setProps((prevState) => ({
      ...prevState,
      isValid: idValid && dateValid && speciesValid && typeValid && sourceValid
    }));
  };

  const handleChanges = {
    document: (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setData((prevState) => ({ ...prevState, [name]: value }));
      setDataChanged();
    },

    receivedDate: (date) => {
      if (date !== data.receivedDate) {
        validateDate(date);
        setData((prev) => ({ ...prev, receivedDate: date }));
        setDataChanged();
      }
    },

    species: (event) => {
      const speciesId = helpers.species.nameToId(event.target.value);
      setData((prevState) => ({ ...prevState, speciesId }));
      setDataChanged();
    },

    permitMove: (event) => {
      const permitMove = event.target.checked;
      setData((prevState) => {
        return {
          ...prevState,
          permitMove
        };
      });
      setDataChanged();
    }
  };

  const getDates = {
    receivedDate: () => {
      const today = new Date();

      return helpers.date.formatYYYYMMDD(today);
    }
  };

  return (
    <>
      <SectionTitleBar
        icon="bi bi-file-earmark-binary"
        id="sourceDocumentTitle"
        title="boApp.sectionTitles.sourceDocument"
      />

      <div className={classes.sourceDocument}>
        <div className={classes.documentId}>
          <FieldEntry
            alphaNumeric={true}
            errorLabel={helpers.response.isError(props.documentIdError.type) ? props.documentIdError.value : ''}
            id="documentId"
            inputError={helpers.response.isError(props.documentIdError.type)}
            inputType={constants.inputType.text}
            labelPosition={constants.labelPosition.above}
            labelText="movementDetails.sourceDocument.documentId"
            maxLength={config.WIDTH_DOCUMENT_ID}
            name="documentId"
            onChange={(event) => handleChanges.document(event)}
            reference={documentIdRef}
            value={data.documentId}
            warningLabel={helpers.response.isWarning(props.documentIdError.type) ? props.documentIdError.value : ''}
          />

          <InputDate
            blockStyling={true}
            dontValidate={true}
            error={dateError()}
            id="dateReceived"
            labelPosition="above"
            labelText="movementDetails.sourceDocument.dateReceived"
            maxDate={getDates.receivedDate()}
            onChange={(event) => handleChanges.receivedDate(event.target.value)}
            setError={(error) => setProps((prevState) => ({
              ...prevState,
              receivedDateError: { type: constants.errorAndWarning.error, value: error }
            }))}
            value={data.receivedDate}
            warning={dateWarning()}
            yearLength={2}
          />
        </div>
        <div className={classes.species}>
          <RadioButtons
            classNames={['bo']}
            error={helpers.response.isError(props.speciesError.type) ? props.speciesError.value : ''}
            id="speciesName"
            ids={[
              constants.species.animalTypes.SHEEP,
              constants.species.animalTypes.GOATS,
              constants.species.animalTypes.DEER
            ]}
            name="speciesName"
            onChange={(event) => handleChanges.species(event)}
            value={helpers.species.idToName(data.speciesId).toLowerCase()}
            warningLabel={helpers.response.isWarning(props.speciesError.type) ? props.speciesError.value : ''}
          />
        </div>
        <div className={classes.documentType}>
          <RadioButtons
            classNames={['bo']}
            error={helpers.response.isError(props.documentTypeError.type) ? props.documentTypeError.value : ''}
            id="documentType"
            ids={[
              userRoleType.KEEPER,
              userRoleType.MARKET,
              userRoleType.ABATTOIR
            ]}
            name="documentType"
            onChange={(event) => handleChanges.document(event)}
            value={data.documentType}
            warningLabel={helpers.response.isWarning(props.documentTypeError.type) ? props.documentTypeError.value : ''}
          />
        </div>
        {helpers.userRole.isKeeper(data.documentType) && data.speciesId !== null &&
          <div className={classes.permitMove}>
            <Legend
              disabled={false}
              legend={t('label.permitMove')}
            />
            <CheckBox
              id="permitMove"
              isChecked={data.permitMove}
              label="label.permitMoveNonCPH"
              name="permitMove"
              onChange={(event) => handleChanges.permitMove(event)}
              value={data.permitMove}
            />
          </div>
        }
      </div>
    </>
  );
};

SourceDocument.propTypes = {
  setData: PropTypes.func.isRequired,
  setDataChanged: PropTypes.func.isRequired
};

export default SourceDocument;
