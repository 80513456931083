import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import AddressSummary from 'components/summary/addressSummary/addressSummary';
import Button from 'components/base/button/button';
import OtherHoldingSelection from 'components/otherHoldingSelection/otherHoldingSelection';

const DeathsStep2 = ({
  deathData,
  setDeathData,
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionCurrentHolding = storeService.session.get.holding();
  const sessionOtherHolding = storeService.session.get.deathOtherHolding();

  const [otherHolding, setOtherHolding] = React.useState(sessionOtherHolding);
  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const nextPage = () => {
    history.push(Routing.deathsSummary);
    helpers.scrollToTop();
  };

  const handleChanges = {
    otherHolding: (holding) => {
      const recentlyUsedHolding = storeService.local.get.recentlyUsedDeathHoldings()[holding.value];
      const isManual = recentlyUsedHolding
        ? recentlyUsedHolding.isManual
        : holding
          ? holding.isManual
          : false;

      setDeathData({
        ...deathData,
        otherHolding: {
          ...holding,
          isManual
        }
      });
      setOtherHolding({
        ...holding,
        isManual
      });

      storeService.session.set.deathOtherHolding({
        ...holding,
        isManual
      });
    }
  };

  return (
    <>
      {ready &&
        <>
          <div className="row">
            <div className="col-md-12">
              <h3 className="h3">{t('label.locationOfCarcassOptional')}</h3>
              <p className="p">
                {' '}
                {t('deaths.selectLocationOfCarcass')}
              </p>
              {!otherHolding &&
                <OtherHoldingSelection
                  autoSelect={true}
                  currentHolding={sessionCurrentHolding}
                  isDeath={true}
                  otherHolding={otherHolding}
                  setModal={setModal}
                  setOtherHolding={handleChanges.otherHolding}
                  setSubmitDisabled={setSubmitDisabled}
                />
              }

              {otherHolding &&
                <AddressSummary
                  blockLayout={true}
                  info1={otherHolding ? otherHolding.value : null}
                  isGathering={false}
                  isManual={otherHolding.isManual}
                  label="label.carcassLocation"
                  onClick={() => {
                    storeService.session.remove.deathOtherHolding();
                    setOtherHolding(null);
                  }}
                  queryKeeper={true}
                  setModal={setModal}
                />
              }
            </div>
          </div>
          <div className="padAll10" />
          <Button
            buttonType="primary"
            disabled={submitDisabled}
            label="button.continue"
            onClick={nextPage}
          />
        </>
      }
    </>
  );
};

DeathsStep2.propTypes = {
  deathData: PropTypes.object,
  setDeathData: PropTypes.func,
  setModal: PropTypes.func.isRequired
};

export default DeathsStep2;
