import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';
import Table from 'components/table/table';

const BoUserActivity = ({
  location,
  setModal
}) => {
  const { ready } = useTranslation();
  const history = useHistory();

  const sessionUserActivity = storeService.session.get.searchResultsUserActivity();

  const [eventType, setEventType] = React.useState({ current: { label: '', url: '' }, previous: {} });
  // const [returnUrl, setReturnUrl] = React.useState('');
  const [data, setData] = React.useState(sessionUserActivity ? sessionUserActivity.data : []);
  const [renderTable, setRenderTable] = React.useState(false);

  const tableParams = {
    request: {}
  };

  useEffect(() => {
    if (location.pathname !== Routing.boUserActivity) {
      setEventType((prevState) => ({
        current: Object.values(constants.bo.userActivity.eventTypes).find((item) => item.url === location.pathname),
        previous: prevState.current
      }));
    }
  }, [location]);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.boUserActivity.length) !== Routing.boUserActivity &&
        path.pathname.substring(0, Routing.boUserActivityAnimalsAdded.length) !== Routing.boUserActivityAnimalsAdded &&
        path.pathname.substring(0, Routing.boUserActivityAll.length) !== Routing.boUserActivityAll &&
        path.pathname.substring(0, Routing.boUserActivityAnnualInventory.length) !== Routing.boUserActivityAnnualInventory &&
        path.pathname.substring(0, Routing.boUserActivityDeaths.length) !== Routing.boUserActivityDeaths &&
        path.pathname.substring(0, Routing.boUserActivityMovements.length) !== Routing.boUserActivityMovements &&
        path.pathname.substring(0, Routing.boUserActivityMovementReviews.length) !== Routing.boUserActivityMovementReviews &&
        path.pathname.substring(0, Routing.boUserActivityTaggingApplications.length) !== Routing.boUserActivityTaggingApplications &&
        path.pathname.substring(0, Routing.boUserActivityTaggingReplacement.length) !== Routing.boUserActivityTaggingReplacement
      ) {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
        unListen();
      }
    });
  }, [history]);

  return (
    <>
      {ready &&
        <>
          <PageTitle
            id="userActivityTitle"
            pageTitleOne="boApp.pageTitles.userActivity.userActivity"
            pageTitleTwo={location.pathname !== Routing.boUserActivity ? Object.values(constants.bo.userActivity.eventTypes).find((area) => area.url === location.pathname).subtitle : ''}
          />

          <div className="pageContent">
            <SectionTitleBar
              icon="bi bi-search"
              id="userActivityTitleBar"
              title="boApp.label.userActivity"
            />

            <Table
              data={data}
              eventType={eventType.current}
              fetchingDataLabel="label.fetchingUserActivity"
              filtersComponents={{
                [constants.filters.outside]: 'BoUserActivity'
              }}
              params={tableParams}
              renderTable={renderTable || data?.length > 0}
              setData={setData}
              setEventType={setEventType}
              setModal={setModal}
              setRenderTable={setRenderTable}
              store={{
                filters: 'UserActivity',
                data: 'UserActivity'
              }}
            />
          </div>
        </>
      }
    </>
  );
};

BoUserActivity.propTypes = {
  location: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default BoUserActivity;
