import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';
import Table from 'components/table/table';

const BoAnimalActivity = ({
  location,
  setModal
}) => {
  const { ready } = useTranslation();
  const history = useHistory();

  const sessionAnimalActivity = storeService.session.get.searchResultsAnimalActivity();

  const [data, setData] = React.useState(sessionAnimalActivity ? sessionAnimalActivity.data : []);
  const [renderTable, setRenderTable] = React.useState(false);

  const tableParams = {
    filters: {
      useComponent: 'BoAnimalActivity'
    },
    request: {}
  };

  const getPageSubTitle = () => {
    switch (location.pathname) {
      case Routing.boAnimalActivityMovementHistory:
        return 'label.movementHistory';
      case Routing.boAnimalActivityTaggingHistory:
        return 'label.taggingHistory';
      case Routing.boAnimalActivityAnimalDetails:
        return 'label.animalDetails';
      case Routing.boAnimalActivityProgeny:
        return 'label.progeny';
      default:
        return '';
    }
  };

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.boAnimalActivity.length) !== Routing.boAnimalActivity &&
        path.pathname.substring(0, Routing.boAnimalActivity.length) !== Routing.boAnimalActivityMovementHistory &&
        path.pathname.substring(0, Routing.boAnimalActivity.length) !== Routing.boAnimalActivityTaggingHistory &&
        path.pathname.substring(0, Routing.boAnimalActivity.length) !== Routing.boAnimalActivityAnimalDetails &&
        path.pathname.substring(0, Routing.boAnimalActivity.length) !== Routing.boAnimalActivityProgeny
      ) {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
        unListen();
      }
    });
  }, [history]);

  return (
    <>
      {ready &&
        <>
          <PageTitle
            id="animalActivityTitle"
            pageTitleOne="boApp.pageTitles.animalActivity.animalActivity"
            pageTitleTwo={getPageSubTitle()}
          />

          <div className="pageContent">
            <SectionTitleBar
              icon="bi bi-search"
              id="animalActivityTitleBar"
              title="boApp.label.animalActivity"
            />

            <Table
              data={data}
              fetchingDataLabel="label.fetchingAnimalActivity"
              filtersComponents={{
                [constants.filters.outside]: 'BoAnimalActivity'
              }}
              params={tableParams}
              renderTable={renderTable || data?.length > 0}
              setData={setData}
              setModal={setModal}
              setRenderTable={setRenderTable}
              store={{
                filters: 'AnimalActivity',
                data: 'AnimalActivity'
              }}
            />
          </div>
        </>
      }
    </>
  );
};

BoAnimalActivity.propTypes = {
  location: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default BoAnimalActivity;
