import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import constants from 'services/constants';
import classes from 'backOfficeComponents/base/fieldEntry/fieldEntry.module.scss';
import FieldLabel from 'backOfficeComponents/base/fieldLabel/fieldLabel';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Hint from 'backOfficeComponents/base/hint/hint';
import helpers from 'services/helpers';

const FieldEntry = ({
  alphaNumeric,
  blockStyling,
  disabled,
  errorLabel,
  fieldDescription,
  hint,
  id,
  inputType,
  isSplCharPasteAllowed,
  labelPosition,
  labelText,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onMouseLeave,
  optional,
  placeholder,
  reference,
  rows,
  tooltip,
  value,
  warningLabel,
  width
}) => {
  const { ready, t } = useTranslation();
  const [lastKey, setLastKey] = useState();

  const lPosition = (labelText && labelText.length) ? ((labelPosition) ? labelPosition : constants.labelPosition.above) : constants.labelPosition.none;
  const inputBaseClassName = classes.textbox;
  const inputErrorClassName = classes.error;
  const inputClassName = width ? inputBaseClassName + ' ' + classes[width] + ' ' + inputErrorClassName : inputBaseClassName + ' ' + inputErrorClassName;

  const onChangeHandler = (event) => {
    let ignoreSpecialChar = '';
    if (isSplCharPasteAllowed) {
      const specialCharPattern = /[^\w\d]/gi;
      const pastedVal = event.target.value;
      ignoreSpecialChar = pastedVal.replace(specialCharPattern, '');
    }
    if (onChange) {
      const newEvent = {
        target: {
          name: name,
          type: 'select',
          value: isSplCharPasteAllowed ? ignoreSpecialChar : event.target.value
        }
      };
      onChange(newEvent);
    }
  };

  const onKeyDownHandlerInput = (event) => {
    if (alphaNumeric) {
      if (event.key.match(/_/) || !event.key.match(/[\w\d]/)) {
        event.preventDefault();
      }
    } else if (event.key >= 0 && lastKey !== 'Tab' && event.target.value.length === event.target.maxLength) {
      event.preventDefault();
    }

    setLastKey(event.key);
  };

  const onKeyDownHandlerTextarea = (event) => {
    if (event.keyCode === 13 && event.target.value.split('\n').length >= parseInt(maxLength)) {
      event.preventDefault();
    }
  };

  const onBlurHandler = (event) => {
    if (onBlur) {
      const newEvent = {
        target: {
          name: name,
          type: 'select',
          value: event.target.value
        }
      };
      onBlur(newEvent);
    }
  };

  const onMouseLeaveHandler = (event) => {
    if (onMouseLeave) {
      const newEvent = {
        target: {
          name: name,
          type: 'select',
          value: event.target.value
        }
      };
      onMouseLeave(newEvent);
    }
  };

  const onPasteHandlerInput = (event) => {
    const key = event.clipboardData.getData('text');
    if (alphaNumeric && (key.match(/_/) || !key.match(/[\w\d]/))) {
      event.preventDefault();
    }
  };

  const fieldLabel = () => {
    return (
      <FieldLabel
        id={id + 'label'}
        label={labelText}
        name={id + 'label'}
        optional={optional}
        tooltip={tooltip}
      />);
  };

  return (
    <>
      {ready &&
        <div className={classes.formGroup}>
          {helpers.labelPosition.isAbove(lPosition) && fieldLabel()}
          {fieldDescription &&
            <span>{t(fieldDescription)}</span>
          }
          <Hint
            hint={hint}
            id={id}
          />
          <ErrorLabel
            id={id}
            label={errorLabel}
          />
          <ErrorLabel
            id={id}
            isWarning={true}
            label={warningLabel}
          />
          <div className={blockStyling ? classes.block : classes.inline}>
            {helpers.labelPosition.isInline(lPosition) && fieldLabel()}
            {(!rows || rows === 1) &&
              <input
                className={inputClassName}
                disabled={disabled}
                id={id}
                max={max}
                maxLength={maxLength}
                min={min}
                name={name}
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandlerInput}
                onMouseLeave={onMouseLeaveHandler}
                onPaste={onPasteHandlerInput}
                onWheel={() => inputType === constants.inputType.number ? document.activeElement.blur() : false}
                placeholder={placeholder}
                ref={reference}
                type={inputType && inputType !== undefined ? inputType : constants.inputType.text}
                value={value && value !== undefined ? value : ''}
              />
            }
            {rows && rows > 1 &&
              <textarea
                className={inputClassName}
                disabled={disabled}
                id={id}
                maxLength={maxLength}
                name={name}
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandlerTextarea}
                ref={reference}
                rows={rows}
                value={value && value !== undefined ? value : ''}
              />
            }
          </div>
        </div>
      }
    </>
  );
};

FieldEntry.propTypes = {
  blockStyling: PropTypes.bool,
  disabled: PropTypes.bool,
  errorLabel: PropTypes.string,
  fieldDescription: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputType: PropTypes.oneOf([
    constants.inputType.encrypted,
    constants.inputType.number,
    constants.inputType.text
  ]),
  labelPosition: PropTypes.oneOf([
    constants.labelPosition.above,
    constants.labelPosition.inline,
    constants.labelPosition.none
  ]),
  labelText: PropTypes.string,
  max: PropTypes.number,
  maxLength: PropTypes.string,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onMouseLeave: PropTypes.func,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
  reference: PropTypes.object,
  rows: PropTypes.number,
  tooltip: PropTypes.string,
  value: PropTypes.any,
  warningLabel: PropTypes.string,
  width: PropTypes.string
};

export default FieldEntry;
