import React from 'react';
import classes from 'backOfficeComponents/base/checkboxGroup/checkboxGroup.module.scss';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import CheckBox from 'backOfficeComponents/base/checkbox/checkbox';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldLabel from 'backOfficeComponents/base/fieldLabel/fieldLabel';
import Hint from 'backOfficeComponents/base/hint/hint';
import Legend from 'backOfficeComponents/base/legend/legend';

const CheckboxGroup = ({
  disabledOptions,
  error,
  hint,
  id,
  inline,
  label,
  legendLabel,
  name,
  objects,
  onChange,
  optional,
  species
}) => {
  const getLabel = (label, species) => {
    return (species) ? label + storeService.session.get.species().id : label;
  };

  const checkboxLabel = (label, species) => {
    const prefix = name ? name + '.' : '';
    return prefix + getLabel(label, species);
  };

  const className = inline ? classes.checkboxGroup + ' ' + classes.inline : classes.checkboxGroup;

  return (
    <fieldset className={classes.formGroup} id={id + 'checkboxGroup'} role="group">
      {legendLabel &&
        <Legend
          id={id}
          legend={legendLabel}
          optional={optional}
        />
      }
      <FieldLabel
        id={getLabel(label, species) + 'CheckBoxFieldLabel'}
        label={getLabel(label, species)}
        name={getLabel(label, species) + 'CheckBoxFieldLabel'}
        optional={optional}
      />
      <Hint
        hint={hint}
        id={id}
      />
      <ErrorLabel
        id={id}
        label={error}
      />
      <div className={className}>
        {objects && objects.map((obj) => (
          <CheckBox
            disabled={disabledOptions?.some((item) => item.id === obj.id) || obj.disabled}
            id={obj.id ? String(obj.id) : obj.label}
            inline={inline}
            isChecked={obj.value}
            key={obj.label}
            label={checkboxLabel(obj.label, species)}
            name={obj.label}
            onChange={onChange}
            value={obj.value}
          />
        ))}
      </div>
    </fieldset>
  );
};

CheckboxGroup.propTypes = {
  disabledOptions: PropTypes.array,
  error: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  label: PropTypes.string,
  legendLabel: PropTypes.string,
  name: PropTypes.string,
  objects: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        label: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.bool
      })
    ),
    PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        label: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.bool,
        disabled: PropTypes.bool
      })
    )
  ]).isRequired,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  species: PropTypes.number
};

export default CheckboxGroup;
