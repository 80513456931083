import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import Routing from 'routing';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import AddressSummary from 'components/summary/addressSummary/addressSummary';
import Button from 'components/base/button/button';
import CheckBox from 'components/base/checkbox/checkbox';
import CheckboxGroup from 'components/base/checkboxGroup/checkboxGroup';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import InputDate from 'components/base/inputDate/inputDate';
import OtherHoldingSelection from 'components/otherHoldingSelection/otherHoldingSelection';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import TextEntry from 'components/base/textEntry/textEntry';
import configHelper from 'services/configHelper';

const MovementStep1 = ({
  actions,
  currentHolding,
  fromSummary,
  inBusinessMovement,
  isAmend,
  movementData,
  movementType,
  setDataChanged,
  setInBusinessMovement,
  setIsAmend,
  setModal,
  setMovementData,
  setMovementType,
  species
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const speciesId = species.id;
  const holdings = storeService.session.get.holdings();
  const numberOfHoldings = holdings ? Object.keys(holdings).length : 0;
  const departureDaysAhead = configHelper.get.offMoveMaxDays(speciesId);

  const [movementExempt, setMovementExempt] = React.useState(constants.option.exemption.no);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [recordCPH, setRecordCPH] = React.useState(constants.option.recordCPH.no);
  const [showExemptions, setShowExemptions] = React.useState(false);
  const [otherHolding, setOtherHolding] = React.useState(null);
  const [departureDate, setDepartureDate] = React.useState(null);
  const [arrivalDate, setArrivalDate] = React.useState(null);
  const [departureDateError, setDepartureDateError] = React.useState('');
  const [departureDateWarning, setDepartureDateWarning] = useState('');
  const [arrivalDateError, setArrivalDateError] = React.useState('');
  const [holdingSelectionError, setHoldingSelectionError] = React.useState(false);
  const [arrivalDateDisabled, setArrivalDateDisabled] = React.useState(true);
  const [comment, setComment] = React.useState('');
  const [isMoveToCommonGround, setIsMoveToCommonGround] = React.useState(false);

  const validateDate = (type, whichDate, date, businessMove) => {
    if (helpers.flags.isDeparture(whichDate)) {
      setDepartureDateError('');
      setDepartureDateWarning('');
    } else if (helpers.flags.isDestination(whichDate)) {
      setArrivalDateError('');
    }

    const dateIsInTheFuture = helpers.date.is.inTheFuture(date, 'movement');
    const isDeparture = helpers.flags.isDeparture(whichDate);
    const isOff = helpers.option.movement.isOff(type);
    const isOn = helpers.option.movement.isOn(type);
    const isPermitMove = helpers.option.movement.isPermitMove(type);

    if (isDeparture && (isMoveToCommonGround || (businessMove && isOff) || isOn || isPermitMove) && dateIsInTheFuture !== null) {
      setDepartureDateError('error.departureDateInTheFuture');
      return;
    }

    const isDestination = helpers.flags.isDestination(whichDate);
    if (isDestination && helpers.date.is.beforeDate(date, departureDate)) {
      setArrivalDateError('movements.arrival.earlierThanDeparture');
      return;
    }

    if (isDestination && helpers.date.is.xPeriodAfterDate(date, departureDate, 1, constants.period.days)) {
      setArrivalDateError('movements.arrival.moreThan1DayAfterDeparture');
      return;
    }

    const dateIsOverThreeDaysAgo = helpers.date.is.overThreeDaysAgo(date);
    if (isDeparture && dateIsOverThreeDaysAgo) {
      setDepartureDateWarning(dateIsOverThreeDaysAgo);
      return;
    }

    const isCattle = helpers.species.isCattleId(speciesId);
    if (isCattle && dateIsInTheFuture) {
      if (isDeparture) {
        setDepartureDateError(dateIsInTheFuture);
      } else if (isDestination) {
        setArrivalDateError(dateIsInTheFuture);
      }
      return;
    }

    const dateIsXDayAhead = helpers.date.is.xDaysInTheFuture(date, departureDaysAhead);
    if (!isCattle && dateIsXDayAhead !== null) {
      if (isOn || isMoveToCommonGround) {
        if (isDeparture) {
          setDepartureDateError(dateIsXDayAhead);
        } else if (isDestination) {
          setArrivalDateError(dateIsXDayAhead);
        }
      }
      return;
    }

    const dateIsXDayAheadOverLimit = helpers.date.is.xDaysInTheFutureOverLimit(date, departureDaysAhead);
    if (!isCattle && dateIsXDayAheadOverLimit !== null) {
      if (isDeparture) {
        setDepartureDateError(dateIsXDayAheadOverLimit);
      } else if (isDestination) {
        setArrivalDateError(dateIsXDayAheadOverLimit);
      }
    }
  };

  const submitValues = () => {
    const movementDetails = {
      ...movementData,
      arrivalDate,
      comment: comment,
      departureDate,
      otherHolding: !helpers.species.isCattleId(speciesId) || (helpers.species.isCattleId(speciesId) && recordCPH) ? otherHolding : null,
      movementType,
      inBusinessMovement,
      exempt: movementExempt,
      exemptions: movementData?.exemptions
    };

    setMovementData(movementDetails);
  };

  const nextPage = () => {
    submitValues();
    history.push(fromSummary ? Routing.movementsSummary : Routing.movementsStep2);
    helpers.scrollToTop();
  };

  const findPermitExemption = (mandatoryExemption, id) => Object.values(mandatoryExemption).some((exemption) => exemption.id === id);

  const render = {
    comment: () => (
      <TextEntry
        fieldDescription={helpers.option.movement.isPermitMove(movementType) ? 'movements.permitMove.comment.description' : ''}
        id="comment"
        label={helpers.option.movement.isPermitMove(movementType) ? 'movements.permitMove.comment.title' : ''}
        maxLength={config.MAXLENGTH_COMMENT}
        name="comment"
        onChange={(event) => handleChanges.comment(event.target.value)}
        placeholder={helpers.option.movement.isPermitMove(movementType) ? t('movements.permitMove.comment.placeholder') : t('label.userCommentPlaceholder')}
        value={comment}
        width="full"
      />
    )
  };

  const handleChanges = {
    arrivalDate: (date) => {
      setArrivalDate(date);
      storeService.session.set.movementArrivalDate(date);
      validateDate(movementType, constants.flags.destination, date, inBusinessMovement);
    },

    comment: (comment) => {
      const validatePermitMoveComment = (input) => {
        // eslint-disable-next-line no-useless-escape
        const regex = /^[a-zA-Z0-9\s\,\.]*$/;
        return regex.test(input);
      };

      if (comment === '') {
        setComment('');
        storeService.session.remove.movementComment();
      } else if (helpers.option.movement.isPermitMove(movementType) && validatePermitMoveComment(comment)) {
        setComment(comment);
        storeService.session.set.movementComment(comment);
      } else {
        setComment(comment);
        storeService.session.set.movementComment(comment);
      }
    },

    departureDate: (date) => {
      setDepartureDate(date);
      storeService.session.set.movementDepartureDate(date);
      validateDate(movementType, constants.flags.departure, date, inBusinessMovement);

      if (helpers.option.movement.isPermitMove(movementType)) {
        setArrivalDate(date);
        storeService.session.set.movementArrivalDate(date);
      } else {
        setArrivalDate(null);
        setArrivalDateError('');
        storeService.session.remove.movementArrivalDate();
      }
    },

    inBusinessMovement: (inBusiness) => {
      if (inBusiness) {
        storeService.session.set.movementInBusiness(true);
      } else {
        storeService.session.remove.movementInBusiness();
      }
      setInBusinessMovement(inBusiness);
      storeService.session.remove.movementOtherHolding();
      setOtherHolding(null);
      setDataChanged();
      validateDate(movementType, constants.flags.departure, departureDate, inBusiness);
    },

    otherHolding: (holding) => {
      setOtherHolding(holding);
      storeService.session.set.movementOtherHolding(holding);

      setDataChanged();
    },

    openOrHiddenExemptionList: (isOpen) => {
      if (isOpen) {
        setShowExemptions(true);
      } else {
        setShowExemptions(false);
        setMovementExempt(constants.option.exemption.no);
        storeService.session.set.movementExempt(constants.option.exemption.no);

        const clearedExemptions = movementData.exemptions.map((o) => ({
          ...o,
          value: false,
          disabled: !inBusinessMovement
            ? findPermitExemption(constants.permitMovesMandatory, o.id)
            : false
        }));

        setMovementData((prevState) => ({ ...prevState, exemptions: clearedExemptions }));
        storeService.session.set.movementExemptions(clearedExemptions);
      }
    },

    exemption: (event) => {
      const selectedExemptions = movementData.exemptions.map((o) => ({
        ...o,
        value: (event.target.id === String(o.id)) ? !o.value : o.value,
        disabled: helpers.option.movement.isPermitMove(movementType) || !inBusinessMovement
          ? findPermitExemption(constants.permitMovesMandatory, o.id)
          : false
      }));

      const exemptionSelected = selectedExemptions.some((e) => e.value === true);

      if (exemptionSelected) {
        setMovementExempt(constants.option.exemption.yes);
        storeService.session.set.movementExempt(constants.option.exemption.yes);
      } else {
        setMovementExempt(constants.option.exemption.no);
        storeService.session.set.movementExempt(constants.option.exemption.no);
      }

      setMovementData((prevState) => ({ ...prevState, exemptions: selectedExemptions }));
      storeService.session.set.movementExemptions(selectedExemptions);

      setDataChanged();
    },

    movementType: (type) => {
      setMovementType(type);
      storeService.session.set.movementType(type);

      if (helpers.option.movement.isOff(type)) {
        setArrivalDate(null);
        setArrivalDateError('');
        storeService.session.remove.movementArrivalDate();
      }

      if (helpers.species.isCattleId(speciesId)) {
        if (!storeService.session.get.movementRecordCPH()) {
          storeService.session.set.movementRecordCPH(constants.option.recordCPH.no);
        }
      }

      if (!storeService.session.get.movementExempt()) {
        storeService.session.set.movementExempt(constants.option.exemption.no);
      }

      if (helpers.option.movement.isPermitMove(type)) {
        setInBusinessMovement(false);
        storeService.session.remove.movementInBusiness();

        if (departureDate) {
          setArrivalDate(departureDate);
          setArrivalDateError('');
          storeService.session.set.movementArrivalDate(departureDate);
        }

        if (comment) {
          setComment('');
          storeService.session.remove.movementComment();
        }

        setOtherHolding(null);
        storeService.session.remove.movementOtherHolding();
        storeService.session.remove.activeTabIdOtherHoldingSelection();

        const permitMoveExemptions = movementData.exemptions.map((o) => {
          const isPermitMoveExemption = findPermitExemption(constants.permitMovesMandatory, o.id);

          return {
            ...o,
            value: isPermitMoveExemption,
            disabled: isPermitMoveExemption
          };
        });

        setMovementData((prevState) => ({ ...prevState, exemptions: permitMoveExemptions }));
        storeService.session.set.movementExemptions(permitMoveExemptions);

        setShowExemptions(true);
        setMovementExempt(constants.option.exemption.yes);
        storeService.session.set.movementExempt(constants.option.exemption.yes);
      } else {
        setArrivalDate(null);
        setArrivalDateError('');
        storeService.session.remove.movementArrivalDate();

        setComment('');
        storeService.session.remove.movementComment();

        setShowExemptions(false);
        setMovementExempt(constants.option.exemption.no);
        storeService.session.set.movementExempt(constants.option.exemption.no);
      }
      validateDate(type, constants.flags.arrivalDate, arrivalDate, inBusinessMovement);
      validateDate(type, constants.flags.departure, departureDate, inBusinessMovement);

      setDataChanged();
    },

    recordCPH: (record) => {
      setRecordCPH(record);
      storeService.session.set.movementRecordCPH(record);

      if (record === constants.option.recordCPH.no) {
        setOtherHolding(null);
      }

      setDataChanged();
    }
  };

  const getDates = {
    arrivalMin: () => {
      const date = helpers.date.formatYYYYMMDD(new Date(departureDate));
      return date;
    },

    arrivalMax: () => {
      const date = helpers.date.formatYYYYMMDD(
        helpers.date.momentToJSDate(
          helpers.date.momentMinDate(
            helpers.date.addPeriod(departureDate, 1, constants.period.days),
            helpers.date.todayEndOfDay()
          )
        )
      );
      return date;
    },

    departureMax: () => {
      const daysAhead = new Date();
      if (helpers.option.movement.isOff(movementType) && !isMoveToCommonGround && !inBusinessMovement) {
        daysAhead.setDate(daysAhead.getDate() + departureDaysAhead);
      }
      return helpers.date.formatYYYYMMDD(daysAhead);
    }
  };

  useEffect(() => {
    const sessionMovement = storeService.session.get.movement();

    const sessionDepartureDate = storeService.session.get.movementDepartureDate();
    const sessionArrivalDate = storeService.session.get.movementArrivalDate();
    const sessionRecordCph = storeService.session.get.movementRecordCPH();
    const sessionMovementExempt = storeService.session.get.movementExempt();
    const sessionOtherHolding = storeService.session.get.movementOtherHolding();
    const sessionComment = storeService.session.get.movementComment();
    const sessionShowExemptions = sessionMovementExempt === 'yes';

    if (!sessionMovement) {
      setIsAmend(false);
    }

    if (sessionDepartureDate) {
      setDepartureDate(sessionDepartureDate);
      validateDate(movementType, constants.flags.departure, sessionDepartureDate, inBusinessMovement);
    }

    if (sessionArrivalDate) {
      setArrivalDate(sessionArrivalDate);
      validateDate(movementType, constants.flags.destination, sessionArrivalDate, inBusinessMovement);
    }

    if (sessionOtherHolding) {
      setOtherHolding(sessionOtherHolding);
    }

    if (sessionRecordCph) {
      setRecordCPH(sessionRecordCph);
    }

    if (sessionMovementExempt) {
      setMovementExempt(sessionMovementExempt);
      setShowExemptions(sessionShowExemptions);
    }

    if (sessionComment) {
      setComment(sessionComment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (otherHolding && otherHolding.value === currentHolding.value) {
      setHoldingSelectionError(true);
    } else {
      setHoldingSelectionError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherHolding, currentHolding]);

  useEffect(() => {
    const exemptionOptionsSelected = () => {
      return movementData.exemptions?.some((item) => item.value);
    };

    setSubmitDisabled(
      ((helpers.option.movement.isOn(movementType) || inBusinessMovement) && (!arrivalDate || arrivalDateError !== '')) ||
      !departureDate ||
      departureDateError !== '' ||
      (helpers.species.isCattleId(speciesId) && recordCPH === constants.option.recordCPH.yes && !otherHolding) ||
      (!helpers.species.isCattleId(speciesId) && (!otherHolding && !helpers.option.movement.isPermitMove(movementType))) ||
      holdingSelectionError ||
      (helpers.option.movement.isExempt(movementExempt) && !exemptionOptionsSelected()) ||
      (!comment && helpers.option.movement.isPermitMove(movementType)) ||
      (isMoveToCommonGround && (!arrivalDate || arrivalDateError !== ''))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movementType, arrivalDate, arrivalDateError, comment, departureDate, departureDateError, speciesId, otherHolding, holdingSelectionError, movementExempt, movementData, isMoveToCommonGround]);

  useEffect(() => {
    setArrivalDateDisabled(!departureDate || departureDateError !== '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departureDate, departureDateError]);

  useEffect(() => {
    if (movementData?.exemptions) {
      setMovementData((prevState) => {
        const selectedExemptions = prevState.exemptions.map((o) => ({
          ...o,
          disabled: helpers.option.movement.isPermitMove(movementType) || !inBusinessMovement
            ? findPermitExemption(constants.permitMovesMandatory, o.id)
            : false
        }));

        storeService.session.set.movementExemptions(selectedExemptions);

        return {
          ...prevState,
          exemptions: selectedExemptions
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inBusinessMovement, showExemptions]);

  useEffect(() => {
    if (helpers.option.movement.isMoveToCommonGround(movementType, otherHolding?.value)) {
      setIsMoveToCommonGround(true);

      if (helpers.date.is.inTheFuture(departureDate)) {
        setDepartureDateError('error.departureDateInTheFuture');
      }
    } else {
      setIsMoveToCommonGround(false);

      validateDate(movementType, constants.flags.departure, departureDate, inBusinessMovement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherHolding]);

  return (
    <>
      {ready &&
        <>
          <h2 className="h2">{t('movements.movement-date.title')}</h2>

          <AboutPanel
            descriptions={[
              { text: 'about.movementsStep1.desc', href: constants.url.defraGuidance }
            ]}
            title="about.genericTitle.step"
          />

          <div className="row">
            <div className="col">
              <div className="section">
                <h3 className="h3">{t('movements.title-type')}</h3>
                {inBusinessMovement && movementType === null &&
                  <ErrorLabel
                    id="errorMovementDirection"
                    label="movements.selectDirection"
                  />
                }
                <RadioButtons
                  disabled={isAmend
                    ? [constants.option.movement.on, constants.option.movement.off, constants.option.movement.permit]
                    : []
                  }
                  ids={[
                    constants.option.movement.on,
                    constants.option.movement.off,
                    constants.option.movement.permit
                  ]}
                  inline={true}
                  name="movementType"
                  onChange={(event) => handleChanges.movementType(event.target.value)}
                  suppressLabel={true}
                  value={movementType}
                />
              </div>

              {numberOfHoldings > 1 && !helpers.option.movement.isPermitMove(movementType)
                ? <div className="section">
                  <CheckBox
                    disabled={isAmend}
                    id="inBusinessMove"
                    isChecked={inBusinessMovement}
                    label="radioButtons.movementType.business"
                    name="inBusinessMove"
                    onChange={() => handleChanges.inBusinessMovement(!inBusinessMovement)}
                    tooltip="label.movementTypeTooltip"
                    value={inBusinessMovement}
                  />

                  {inBusinessMovement &&
                    <AboutPanel
                      descriptions={[
                        { text: 'about.movementsStep1.inBusiness.desc1' }
                      ]}
                      title="about.movementsStep1.inBusiness.title"
                    />
                  }
                </div>
                : <AboutPanel
                  descriptions={[
                    { text: 'movements.permitMove.about.description' }
                  ]}
                  title="movements.permitMove.about.title"
                />
              }

              {movementType !== null &&
                <>
                  <h3 className="h3">{t('movements.title-date')}</h3>
                  <div className="section">
                    <InputDate
                      dontValidate={true}
                      error={helpers.error.cfgDepartureDateError(departureDateError, departureDaysAhead)}
                      id="departureDate"
                      labelPosition="above"
                      labelText={helpers.option.movement.isPermitMove(movementType) ? 'movements.permitMove.date.label' : 'movements.movement-date.departureDate'}
                      maxDate={getDates.departureMax()}
                      onChange={(event) => handleChanges.departureDate(event.target.value)}
                      setError={setDepartureDateError}
                      value={departureDate}
                      warning={departureDateWarning}
                      yearLength={4}
                    />
                  </div>

                  {(helpers.option.movement.isOn(movementType) || inBusinessMovement || isMoveToCommonGround) &&
                    <div className="section">
                      <InputDate
                        disabled={arrivalDateDisabled}
                        dontValidate={true}
                        error={isMoveToCommonGround && !arrivalDate ? 'label.commonGroundPleaseEnterArrivalDate' : arrivalDateError}
                        errorPosition={isMoveToCommonGround && !arrivalDate ? constants.labelPosition.inline : constants.labelPosition.above}
                        id="arrivalDate"
                        labelPosition={constants.labelPosition.above}
                        labelText="movements.movement-date.arrivalDate"
                        maxDate={getDates.arrivalMax()}
                        minDate={getDates.arrivalMin()}
                        onChange={(event) => handleChanges.arrivalDate(event.target.value)}
                        setError={setArrivalDateError}
                        value={arrivalDate}
                        yearLength={4}
                      />
                    </div>
                  }
                </>
              }
            </div>
          </div>

          {movementType && !helpers.option.movement.isPermitMove(movementType) &&
            <AboutPanel
              descriptions={[
                { text: 'label.userCommentDescriptionMovement' }
              ]}
              open={actions?.userCommentEdit}
              title="label.userCommentTitle"
            >
              {render.comment()}
            </AboutPanel>
          }

          {helpers.option.movement.isPermitMove(movementType) &&
            <div className="section">
              {render.comment()}
            </div>
          }

          {movementType !== null &&
            <>
              {helpers.species.isCattleId(speciesId) && !helpers.option.movement.isPermitMove(movementType) &&
                <div className="section">
                  <RadioButtons
                    ids={[
                      constants.option.recordCPH.no,
                      constants.option.recordCPH.yes
                    ]}
                    label={helpers.option.movement.isOn(movementType) ? 'radioButtons.recordCattleCPH.labelOn' : 'radioButtons.recordCattleCPH.labelOff'}
                    name="recordCattleCPH"
                    onChange={(event) => handleChanges.recordCPH(event.target.value)}
                    value={recordCPH}
                  />
                </div>
              }

              {(!helpers.species.isCattleId(speciesId) || (helpers.species.isCattleId(speciesId) && recordCPH === constants.option.recordCPH.yes)) && !helpers.option.movement.isPermitMove(movementType) &&
                <>
                  {!otherHolding &&
                    <>
                      <h3 className="h3">{helpers.option.movement.isOn(movementType)
                        ? t('movements.departure.title')
                        : t('movements.destination.title')}
                      </h3>

                      {!inBusinessMovement &&
                        <p className="p">
                          {helpers.option.movement.isOn(movementType)
                            ? t('movements.departure.descOff')
                            : t('movements.destination.descOn')
                          }
                        </p>
                      }

                      {holdingSelectionError &&
                        <ErrorLabel label="movements.departure.error" />
                      }

                      <OtherHoldingSelection
                        autoSelect={true}
                        currentHolding={currentHolding}
                        inBusinessMovement={inBusinessMovement}
                        movementType={movementType}
                        otherHolding={otherHolding}
                        setInBusinessMovement={setInBusinessMovement}
                        setModal={setModal}
                        setMovementType={setMovementType}
                        setOtherHolding={handleChanges.otherHolding}
                      />
                    </>
                  }

                  {otherHolding &&
                    <div className="section">
                      <AddressSummary
                        blockLayout={true}
                        info1={helpers.option.movement.isOn(movementType) ? otherHolding.value : currentHolding.value }
                        info2={helpers.option.movement.isOn(movementType) ? currentHolding.value : otherHolding.value }
                        isGathering={false}
                        label="label.selectedHoldings"
                        label1="label.departureHoldingFrom"
                        label2="label.destinationHoldingTo"
                        onClick={() => {
                          storeService.session.remove.movementOtherHolding();
                          setOtherHolding(null);
                        }}
                        setModal={setModal}
                        storeIn1="holdingAddress"
                        storeIn2="movementOtherHoldingAddress"
                      />
                    </div>
                  }
                </>
              }

              {movementData?.exemptions?.length > 0 &&
                <>
                  <h3 className="h3">{t('movements.standstillExemptions.title')}</h3>
                  <p className="p" dangerouslySetInnerHTML={{ __html: t('movements.standstillExemptions.subTitle') }} />

                  <AboutPanel
                    descriptions={[
                      { text: 'about.movementsStep1.standstillExemptions.desc1' }
                    ]}
                    keepOpen={helpers.option.movement.isPermitMove(movementType)}
                    onToggle={(event) => handleChanges.openOrHiddenExemptionList(event.target.open)}
                    open={showExemptions}
                    title="about.genericTitle.viewAndSelect"
                  />

                  {showExemptions &&
                    <div className="section">
                      <CheckboxGroup
                        objects={movementData.exemptions}
                        onChange={handleChanges.exemption}
                      />
                    </div>
                  }
                </>
              }

              <Button
                buttonType="primary"
                disabled={submitDisabled}
                label="button.continue"
                onClick={nextPage}
              />
            </>
          }
        </>
      }
    </>
  );
};

MovementStep1.propTypes = {
  actions: PropTypes.object,
  currentHolding: PropTypes.object.isRequired,
  fromSummary: PropTypes.bool,
  inBusinessMovement: PropTypes.bool.isRequired,
  isAmend: PropTypes.bool,
  movementData: PropTypes.object.isRequired,
  movementType: PropTypes.string,
  setDataChanged: PropTypes.func.isRequired,
  setInBusinessMovement: PropTypes.func.isRequired,
  setIsAmend: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setMovementData: PropTypes.func.isRequired,
  setMovementType: PropTypes.func.isRequired,
  species: PropTypes.object.isRequired
};

export default MovementStep1;
