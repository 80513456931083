import React from 'react';
import classes from 'backOfficeComponents/sections/paperTransport/paperTransport.module.scss';
import config from 'config';
import constants from 'services/constants';
import he from 'he';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import helpers from 'services/helpers';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';

const PaperTransport = ({
  haulierNameError,
  panelTitle,
  setDataChanged,
  setTransportDetails,
  transportAuthNumberError,
  transportDetails,
  transportDetailsError
}) => {
  const optional = false;

  const handleChanges = {
    authorisationNumber: (authNum) => {
      storeService.session.set.movementAuthorisationNumber(authNum);
      setTransportDetails((prevState) => ({
        ...prevState,
        transportAuthorisationNumber: authNum
      }));
      setDataChanged();
    },

    transportedBy: (transportedBy) => {
      storeService.session.set.movementTransportedBy(transportedBy);
      setTransportDetails((prevState) => ({
        ...prevState,
        transportedBy
      }));
      setDataChanged();
    },

    transportName: (transporterName) => {
      storeService.session.set.movementTransporter(transporterName);
      setTransportDetails(((prevState) => ({
        ...prevState,
        transporterName
      })));
      setDataChanged();
    },

    vehicleRegistrationNumber: (regNum) => {
      storeService.session.set.movementVehicleRegistrationNumber(regNum);
      setTransportDetails((prevState) => ({
        ...prevState,
        transportVehicleRegistrationNo: regNum
      }));
      setDataChanged();
    }
  };

  return (
    <div className={classes.transportDetails}>
      <SectionTitleBar
        icon="bi bi-truck"
        id="sourceDocumentSectionTitle"
        title={panelTitle}
      />

      <RadioButtons
        classNames={['bo']}
        error={helpers.response.isError(transportDetailsError.type) ? transportDetailsError.value : ''}
        id="transporterRadio"
        ids={[
          constants.option.transporter.departureKeeper,
          constants.option.transporter.destinationKeeper,
          constants.option.transporter.haulier
        ]}
        inline={true}
        name="transportedBy"
        onChange={(event) => handleChanges.transportedBy(event.target.value)}
        value={transportDetails ? transportDetails.transportedBy : ''}
        warning={helpers.response.isWarning(transportDetailsError.type) ? transportDetailsError.value : ''}
      />

      <div className={classes.vehicleAndHaulier}>
        <div className={classes.vehicleRegiatration}>
          <FieldEntry
            id="vehicleRegistrationNumber"
            labelPosition={constants.labelPosition.above}
            labelText="label.vehicleRegistration"
            maxLength={config.WIDTH_REGISTRATION}
            name="vehicleRegistrationNumber"
            onChange={(event) => handleChanges.vehicleRegistrationNumber(event.target.value)}
            optional={optional}
            value={transportDetails ? transportDetails.transportVehicleRegistrationNo : ''}
            width={config.WIDTH_REGISTRATION}
          />
        </div>
        <div className={classes.haulierName}>
          <FieldEntry
            errorLabel={helpers.response.isError(haulierNameError.type) ? haulierNameError.value : ''}
            id="transporterName"
            labelPosition={constants.labelPosition.above}
            labelText="label.haulageCompany"
            maxLength={config.WIDTH_TRANSPORTER_NAME}
            name="transporterName"
            onChange={(event) => handleChanges.transportName(event.target.value)}
            optional={optional}
            value={transportDetails?.transporterName ? he.decode(transportDetails.transporterName) : ''}
            warningLabel={helpers.response.isWarning(haulierNameError.type) ? haulierNameError.value : ''}
            width={config.WIDTH_TRANSPORTER_NAME}
          />
        </div>
        <div className={classes.transportAuthorisationNumber}>
          <FieldEntry
            errorLabel={helpers.response.isError(transportAuthNumberError.type) ? transportAuthNumberError.value : ''}
            id="transporterAuthorisation"
            labelPosition={constants.labelPosition.above}
            labelText="label.transportAuth"
            maxLength={config.WIDTH_TRANSPORTER_AUTH}
            name="transporterAuthorisation"
            onChange={(event) => handleChanges.authorisationNumber(event.target.value)}
            optional={optional}
            value={transportDetails?.transportAuthorisationNumber ? he.decode(transportDetails.transportAuthorisationNumber) : ''}
            warningLabel={helpers.response.isWarning(transportAuthNumberError.type) ? transportAuthNumberError.value : ''}
            width={config.WIDTH_TRANSPORTER_AUTH}
          />
        </div>
      </div>
    </div>
  );
};

PaperTransport.propTypes = {
  haulierNameError: PropTypes.object,
  panelTitle: PropTypes.string,
  setDataChanged: PropTypes.func,
  setTransportDetails: PropTypes.func,
  transportAuthNumberError: PropTypes.object,
  transportDetails: PropTypes.object,
  transportDetailsError: PropTypes.object
};

export default PaperTransport;
