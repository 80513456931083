import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'components/base/button/button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Routing from 'routing';
import config from 'config';
import constants from 'services/constants';
import storeService from 'services/storeService';
import bff, { bffResponse } from 'services/bff';
import errors from 'services/errors';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import VetTitle from 'frontEnd/vetModule/components/vetTitle';
import VetSummaryInfo from 'frontEnd/vetModule/components/vetSummaryInfo';
import helpers from 'services/helpers';
import { Prompt } from 'react-router';

const hourGlassIcon = 'bi bi-hourglass ' + classes.icon;

const VetSummary = ({ location, setModal }) => {
  const { instance } = useMsal();
  const history = useHistory(),
    [pending, setPending] = React.useState(false),
    [submitted, setSubmitted] = React.useState(false),
    [isDisabled, setIsDisabled] = React.useState(false),
    [responseData, setResponseData] = React.useState({}),
    [isRefreshDisabled, setIsRefreshDisabled] = React.useState(false),
    { ready, t } = useTranslation(),
    claimsDetails = storeService.session.get.vetClaims() ? storeService.session.get.vetClaims() : '',
    newAttestationDate = storeService.session.get.vetCPHData()?.attestationDate || '',
    cph = storeService.session.get.vetCPHData()?.id || '',
    routeToCreatePage = () => {
      history.push(Routing.vetCreate);
    },
    data = {
      programCode: 'ATTEST',
      clear: false,
      set: true,
      holdingStatuses: [
        {
          holding: cph,
          statusAttributes: [
            {
              Name: 'VetRCVS',
              Data: claimsDetails.rcvsmembershipno
            },
            {
              Name: 'VetName',
              Data: claimsDetails.name
            },
            {
              Name: 'VetEmployer',
              Data: claimsDetails.vetemployer
            },
            {
              Name: 'VetEmploymentAddress',
              Data: claimsDetails.vetemploymentaddress
            }
          ],
          programStatusCode: 'ATTESTED',
          updateDate: newAttestationDate,
          reason: ''
        }
      ]
    },
    bffFunctionCall = (isRefresh, data, responseData) => {
      bff
        .post('/vetAttestation', {
          poll: config.POLLS_ENABLED,
          refreshCall: isRefresh,
          data: data,
          responseData: responseData
        })
        .then((res) => {
          const { data } = res,
            vetRequestId = bffResponse.requestId(data);
          setResponseData(data);
          if (vetRequestId !== '' && (data?.poll?.requestStatus === constants.status.success || data?.poll?.requestStatus === constants.status.completed_warning)) {
            history.push(Routing.vetDeclaration);
            setSubmitted(false);
            setPending(false);
            setIsDisabled(false);
          } else if (vetRequestId !== '' && data?.poll?.requestStatus === constants.status.completed_error) {
            setModal({
              modalTitle: 'vetContent.vetDeclaration.Attestation-error',
              modalMessage: [
                data?.poll?.errors[0].message,
                'error.unexpectedError1',
                'error.unexpectedError2'
              ]
            });
            setSubmitted(false);
            setPending(false);
            setIsDisabled(false);
          } else {
            setIsRefreshDisabled(true);
          }
        })
        .catch((error) => {
          errors.BFF(error, setModal);
          setIsDisabled(false);
          setSubmitted(false);
          setPending(false);
        });
    },
    handleRefreshData = () => {
      setIsRefreshDisabled(false);
      bffFunctionCall(true, data, { requestId: responseData.requestId });
    },
    handleSubmitPost = () => {
      setSubmitted(true);
      setPending(true);
      setIsDisabled(true);
      bffFunctionCall(false, data, responseData);
    };

  if (storeService.session.get.restrictBackFlow()) {
    helpers.restrictBackFromVetDeclaration();
    instance.logoutRedirect({ postLogoutRedirectUri: '/' })
      .catch((error) => {
      console.error(error); //eslint-disable-line
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  return (
    <>
      {ready &&
      <>
        <Prompt
          message={(params) => {
            return (
              !storeService.session.get.idle() &&
              (location.pathname !== params.pathname && params.pathname !== '/logout' && params.pathname !== '/vet/declaration' && pending)
            )
              ? t('vetContent.vetSummary.vetSummary-restrict-back')
              : true;
          }}
        />
        <main className={classes.mainContanier}>
          <VetTitle setModal={setModal} />
          { !submitted && <>
            <section className={classes.bodySection}>
              <h3 className={classes.sectionTitle}>{t('vetContent.previewVisitDetail')}</h3>
              <p className={classes.sectionTitleInfo}>{t('vetContent.summaryReviewInfo')}</p>
              <div className={classes.summaryBlock + ' ' + classes.topSpace + ' '}>
                <VetSummaryInfo setModal={setModal} />
              </div>
            </section>
            <section className={classes.buttonSection + ' ' + classes.displayBtnBoth}>
              <Button
                buttonType="tertiary"
                disabled={isDisabled}
                label="Back"
                onClick={routeToCreatePage}
              />
              <Button
                buttonType="primary"
                disabled={isDisabled}
                label="Confirm & Submit"
                onClick={handleSubmitPost}
              />
            </section>
          </>
          }

        </main>
        { submitted && <>
          <div className={classes.notificationSection}>
            <div className={classes.titleText}> <i className={hourGlassIcon} /> {t('vetContent.vetDeclaration.vetSubmissionPendingText1')}</div>
            <div>{t('vetContent.vetDeclaration.vetSubmissionPendingText2')}</div>
          </div>
          <section className={classes.buttonSection + ' ' + classes.displayBtnRight}>
            <Button
              buttonType="primary"
              disabled={!isRefreshDisabled}
              label="Refresh"
              onClick={handleRefreshData}
            />
          </section>
        </> }

      </>
      }
    </>
  );
};

export default VetSummary;
