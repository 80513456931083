import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/table/filters/filtersBoSearchRequestId/filtersBoSearchRequestId.module.scss';
import config from 'config';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import Button from 'backOfficeComponents/base/button/button';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';

const BoMovementSearchByDateAndCphFilters = ({
  loadPending,
  setData,
  setRenderTable,
  setTableParams,
  storeFilters,
  tableParams
}) => {
  const { ready } = useTranslation();

  const [filterRequestId, setFilterRequestId] = React.useState(tableParams?.filters?.requestId);
  const [searchDisabled, setSearchDisabled] = React.useState(true);
  const [resetTable, setResetTable] = React.useState(tableParams?.filters?.newRequest ?? false);

  const handleChanges = {
    requestId: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      setData([]);
      storeService.session.removeAll.searchResults();

      setFilterRequestId(value);

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...storedFilters,
        requestId: value
      });

      setRenderTable(false);

      setTableParams((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          requestId: value
        }
      }));
    }
  };

  const runRequest = () => {
    storeService.session.set[`tableFilters${storeFilters}PageIndex`](0);
    storeService.session.removeAll.searchResults();
    setData([]);

    setTableParams((prevState) => ({
      ...prevState,
      newRequest: true,
      columns: [],
      filters: {
        requestId: filterRequestId
      },
      page: {
        ...prevState.page,
        index: 0
      },
      request: {
        ...prevState.request,
        url: '/searchRequestId',
        params: {
          ...prevState.request.params,
          requestId: filterRequestId
        },
        redirectTo: (data) => ({
          pathname: Routing.boRequestIdDetails + filterRequestId,
          state: {
            data: {
              requestId: filterRequestId,
              ...data
            }
          }
        })
      }
    }));
    setRenderTable(true);
  };

  useEffect(() => {
    if (loadPending || !filterRequestId) {
      setSearchDisabled(true);
    } else {
      setSearchDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadPending,
    filterRequestId
  ]);

  useEffect(() => {
    setSearchDisabled(true);

    setTableParams((prevState) => ({
      ...prevState,
      columns: [],
      request: {
        ...prevState.request,
        url: '/searchRequestId',
        params: {
          ...prevState.request.params,
          requestId: filterRequestId
        },
        redirectTo: (data) => ({
          pathname: Routing.boRequestIdDetails + filterRequestId,
          state: {
            data: {
              requestId: filterRequestId,
              ...data
            }
          }
        })
      }
    }));

    if (resetTable) {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      storeService.session.set[`tableFilters${storeFilters}`]({
        ...storedFilters,
        newRequest: false
      });
      setResetTable(false);
      runRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <>
          <div className={classes.requestId}>
            <FieldEntry
              id="requestId"
              isSplCharPasteAllowed={true}
              labelPosition={constants.labelPosition.above}
              labelText="label.requestId"
              name="requestId"
              onChange={(event) => handleChanges.requestId(event.target.value)}
              value={filterRequestId}
              width={config.WIDTH_MOVEMENT_ID}
            />
          </div>
          <div className="actions">
            <div className="left">
              <Button
                buttonType="primary"
                disabled={searchDisabled}
                id="searchButton"
                label="button.search"
                onClick={runRequest}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

BoMovementSearchByDateAndCphFilters.propTypes = {
  loadPending: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  setRenderTable: PropTypes.func.isRequired,
  setTableParams: PropTypes.func.isRequired,
  storeFilters: PropTypes.string.isRequired,
  tableParams: PropTypes.object.isRequired
};

export default BoMovementSearchByDateAndCphFilters;
