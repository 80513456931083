import React from 'react';
import PropTypes from 'prop-types';
import constants from 'services/constants';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import TransportDetailsForm from 'frontEnd/movements/step3/transportDetailsForm/transportDetailsForm';

const TransportDetails = ({
  authorisationNumber,
  authorisationNumberError,
  contactDetailsIndex,
  contactPhone,
  contactPhoneError,
  departureDate,
  departureTime,
  driverFirstName,
  driverFirstNameError,
  driverLastName,
  driverLastNameError,
  durationOfJourney,
  durationOfJourneyError,
  hoursError,
  minutesError,
  movementData,
  movementType,
  ownerFirstName,
  ownerFirstNameError,
  ownerLastName,
  ownerLastNameError,
  setAuthorisationNumber,
  setAuthorisationNumberError,
  setContactDetailsIndex,
  setContactPhone,
  setContactPhoneError,
  setDepartureTime,
  setDriverFirstName,
  setDriverFirstNameError,
  setDriverLastName,
  setDriverLastNameError,
  setDurationOfJourney,
  setDurationOfJourneyError,
  setHoursError,
  setMinutesError,
  setModal,
  setOwnerFirstName,
  setOwnerFirstNameError,
  setOwnerLastName,
  setOwnerLastNameError,
  setTransportedBy,
  setTransporter,
  setTransportError,
  setVehicleRegistrationNumber,
  transportedBy,
  transporter,
  transportError,
  vehicleRegistrationNumber,
  vehicleRegistrationNumberError
}) => {
  return (
    <>
      <div className="section">
        <RadioButtons
          ids={[
            constants.option.transporter.departureKeeper,
            constants.option.transporter.destinationKeeper,
            constants.option.transporter.haulier
          ]}
          name="transportedBy"
          onChange={(event) => setTransportedBy(event.target.value)}
          value={transportedBy}
        />
      </div>
      {transportedBy &&
        <TransportDetailsForm
          authorisationNumber={authorisationNumber}
          authorisationNumberError={authorisationNumberError}
          contactDetailsIndex={contactDetailsIndex}
          contactPhone={contactPhone}
          contactPhoneError={contactPhoneError}
          departureDate={departureDate}
          departureTime={departureTime}
          driverFirstName={driverFirstName}
          driverFirstNameError={driverFirstNameError}
          driverLastName={driverLastName}
          driverLastNameError={driverLastNameError}
          durationOfJourney={durationOfJourney}
          durationOfJourneyError={durationOfJourneyError}
          hoursError={hoursError}
          minutesError={minutesError}
          movementData={movementData}
          movementType={movementType}
          ownerFirstName={ownerFirstName}
          ownerFirstNameError={ownerFirstNameError}
          ownerLastName={ownerLastName}
          ownerLastNameError={ownerLastNameError}
          setAuthorisationNumber={setAuthorisationNumber}
          setAuthorisationNumberError={setAuthorisationNumberError}
          setContactDetailsIndex={setContactDetailsIndex}
          setContactPhone={setContactPhone}
          setContactPhoneError={setContactPhoneError}
          setDepartureTime={setDepartureTime}
          setDriverFirstName={setDriverFirstName}
          setDriverFirstNameError={setDriverFirstNameError}
          setDriverLastName={setDriverLastName}
          setDriverLastNameError={setDriverLastNameError}
          setDurationOfJourney={setDurationOfJourney}
          setDurationOfJourneyError={setDurationOfJourneyError}
          setHoursError={setHoursError}
          setMinutesError={setMinutesError}
          setModal={setModal}
          setOwnerFirstName={setOwnerFirstName}
          setOwnerFirstNameError={setOwnerFirstNameError}
          setOwnerLastName={setOwnerLastName}
          setOwnerLastNameError={setOwnerLastNameError}
          setTransporter={setTransporter}
          setTransportError={setTransportError}
          setVehicleRegistrationNumber={setVehicleRegistrationNumber}
          transportedBy={transportedBy}
          transporter={transporter}
          transportError={transportError}
          vehicleRegistrationNumber={vehicleRegistrationNumber}
          vehicleRegistrationNumberError={vehicleRegistrationNumberError}
        />
      }
    </>
  );
};

TransportDetails.propTypes = {
  authorisationNumber: PropTypes.string,
  authorisationNumberError: PropTypes.string,
  contactDetailsIndex: PropTypes.number,
  contactPhone: PropTypes.string,
  contactPhoneError: PropTypes.string,
  departureDate: PropTypes.string,
  departureTime: PropTypes.object,
  driverFirstName: PropTypes.string,
  driverFirstNameError: PropTypes.bool,
  driverLastName: PropTypes.string,
  driverLastNameError: PropTypes.bool,
  durationOfJourney: PropTypes.object,
  durationOfJourneyError: PropTypes.string,
  hoursError: PropTypes.bool,
  minutesError: PropTypes.bool,
  movementData: PropTypes.object,
  movementType: PropTypes.string,
  ownerFirstName: PropTypes.string,
  ownerFirstNameError: PropTypes.bool,
  ownerLastName: PropTypes.string,
  ownerLastNameError: PropTypes.bool,
  setAuthorisationNumber: PropTypes.func,
  setAuthorisationNumberError: PropTypes.func,
  setContactDetailsIndex: PropTypes.func,
  setContactPhone: PropTypes.func,
  setContactPhoneError: PropTypes.func,
  setDepartureTime: PropTypes.func,
  setDriverFirstName: PropTypes.func,
  setDriverFirstNameError: PropTypes.func,
  setDriverLastName: PropTypes.func,
  setDriverLastNameError: PropTypes.func,
  setDurationOfJourney: PropTypes.func,
  setDurationOfJourneyError: PropTypes.func,
  setHoursError: PropTypes.func,
  setMinutesError: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setOwnerFirstName: PropTypes.func,
  setOwnerFirstNameError: PropTypes.func,
  setOwnerLastName: PropTypes.func,
  setOwnerLastNameError: PropTypes.func,
  setTransportError: PropTypes.func,
  setTransportedBy: PropTypes.func,
  setTransporter: PropTypes.func,
  setVehicleRegistrationNumber: PropTypes.func,
  transportError: PropTypes.bool,
  transportedBy: PropTypes.string,
  transporter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  vehicleRegistrationNumber: PropTypes.string,
  vehicleRegistrationNumberError: PropTypes.string
};

export default TransportDetails;
