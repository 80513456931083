import React, { useEffect, useState } from 'react';
import Button from 'components/base/button/button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import storeService from 'services/storeService';
import Routing from 'routing';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import VetTitle from 'frontEnd/vetModule/components/vetTitle';
import { tableColumns, initialSorting } from 'services/tableColumns';
import Table from 'components/table/table';

const VetDetails = ({ setModal }) => {

  const history = useHistory(),
    { ready, t } = useTranslation(),
    routeToHomePage = () => {
      history.push(Routing.vetHome);
    },
    routeToCreatePage = () => {
      history.push(Routing.vetCreate);
    },
    cphData = storeService.session.get.vetCPHData(),
    cphNo = cphData ? cphData.id : '',
    [loadPending, setLoadPending] = React.useState(true),
    [vetAttestationList, setVetAttestationList] = useState([]),
    tableParams = {
      request: {
        url: '/getVetAttestationsDetails',
        params: {
          cphNo,
          showOnlyValidAttestation: 'false'
        }
      }
    };

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.vetDetails.length) !== Routing.vetDetails) {
        storeService.session.removeAll.tableFilters();
        unListen();
      }
    });
  }, [history]);

  return (
    <>
      {ready &&
      <>
        <main className={classes.mainContanier}>

          <VetTitle setModal={setModal} />

          <section className={classes.bodySection}>
            <h3 className={classes.sectionTitle + ' ' + classes.noBottomSpace}>{t('vetContent.previousVisit')}</h3>
            <Table
              columns={tableColumns.vetAttestationDetails}
              data={vetAttestationList}
              dataProvided={false}
              fetchingDataLabel="label.fetchingData"
              initialSortBy={initialSorting.vetDetails.accessor}
              initialSortDirection={initialSorting.vetDetails.direction}
              loadPending={loadPending}
              noDataLabel="label.noPrevDigitalRecord"
              params={tableParams}
              setData={setVetAttestationList}
              setLoadPending={setLoadPending}
              setModal={setModal}
              store={{
                filters: 'VetAttestation',
                data: 'VetAttestation'
              }}
            />
          </section>

          <section className={classes.buttonSection + ' ' + classes.displayBtnBoth}>
            <Button
              buttonType="tertiary"
              label="Back"
              onClick={routeToHomePage}
            />
            <Button
              buttonType="primary"
              label="Next"
              onClick={routeToCreatePage}
            />
          </section>
        </main>
      </>
      }
    </>
  );
};

export default VetDetails;
