import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Routing from 'routing';
import storeService from 'services/storeService';
import Linker from 'components/base/linker/linker';
import VetHoldingInfo from 'frontEnd/vetModule/components/vetHoldingInfo';
import { tableColumns, initialSorting } from 'services/tableColumns';
import Table from 'components/table/table';
import classes from 'frontEnd/abattoirAttestation/abattoirAttestationTagResult/abattoirAttestationTagResult.module.scss';

const AbattoirAttestationResult = ({ setModal }) => {
  const { ready, t } = useTranslation(),
    history = useHistory(),
    [loadPending, setLoadPending] = React.useState(true),
    [vetAttestationList, setVetAttestationList] = React.useState([]),
    cphData = storeService.session.get.vetCPHData(),
    cphNo = cphData ? cphData.id : '',
    printAndDownload = () => {
      window.print();
    },
    tableParams = {
      request: {
        url: '/getVetAttestationsDetails',
        params: {
          cphNo,
          showOnlyValidAttestation: 'false'
        }
      }
    };
  useEffect(() => {
    if (storeService.session.get.abattoirCPHData()) {
      storeService.session.remove.abattoirCPHData();
      storeService.session.set.abattoirCPHData(vetAttestationList);
    } else {
      storeService.session.set.abattoirCPHData(vetAttestationList);
    }
  }, [vetAttestationList]);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.abattoirAttestationResult.length) !== Routing.abattoirAttestationResult) {
        storeService.session.removeAll.tableFilters();
        unListen();
      }
    });
  }, [history]);

  return (
    <>
      {ready &&
        <>
          <h1 className="h1">{t('abattoirAttestation.vetHeading')}</h1>
          <div >
            <Linker
              label="button.back"
              linkType="back"
              to={Routing.abattoirCheckHolding}
            />
          </div>
          <div>
            <h3 className="h3">{t('abattoirAttestation.abattoirAttestationResult')}</h3>
            <VetHoldingInfo setModal={setModal} />
          </div>
          <section>
            <Table
              classNames={['vetAnimalTable']}
              columns={tableColumns.abattoirAttestationDetails}
              data={vetAttestationList}
              dataProvided={false}
              initialSortBy={initialSorting.vetDetails.accessor}
              initialSortDirection={initialSorting.vetDetails.direction}
              loadPending={loadPending}
              noDataLabel="label.noData"
              params={tableParams}
              setData={setVetAttestationList}
              setLoadPending={setLoadPending}
              setModal={setModal}
              store={{
                filters: 'VetAttestation',
                data: 'VetAttestation'
              }}
            />
          </section>
          {vetAttestationList.length > 0 &&
            <span className={classes.abattoirPrint} onClick={printAndDownload} >{t('button.printSave')}</span>
          }
        </>
      }
    </>
  );
};

export default AbattoirAttestationResult;
