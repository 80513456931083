import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Routing from 'routing';
import storeService from 'services/storeService';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import Address from 'components/address/address';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import searchHelper from 'services/searchHelper';
import { isCancel } from 'services/bff';
import Button from 'backOfficeComponents/base/button/button';
import printClasses from 'backOffice/vaModule/vaRecordDeclaration.module.scss';
import CompanyPrintLogo from 'images/svg/logo-livestock-blue.svg';

const VARecordDeclaration = ({ match, setModal }) => {
  const { ready, t } = useTranslation(),
    history = useHistory(),
    [, setSearchNoResults] = React.useState(false),
    [holdingBOResult, setHoldingBOResult] = React.useState(false),
    searchedData = storeService.session.get.searchResults() ? storeService.session.get.searchResults().holdingActivity.data : [],
    filterData = searchedData.filter((item) => item.id === parseInt(match.params.declarationId)),
    boCphNo = filterData[0]?.holding,
    vetAttestationNumber = helpers.vetAttestationNumber.generateVan(filterData[0]?.vetRCVS, filterData[0]?.holding, filterData[0]?.startDate),
    printAndDownload = () => {
      window.print();
    },
    handleReturnRouting = () => {
      history.push(Routing.boVaRecords);
    };

  useEffect(() => {
    const query = {};
    if (boCphNo && helpers.validate.cphNumber(boCphNo)) {
      query.cph = boCphNo;
      searchHelper.searchCPH(query, setHoldingBOResult, setSearchNoResults)
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal)) {
            const resData = res.data.data?.[0];
            if (resData) {
              setHoldingBOResult(resData);
            }
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setSearchNoResults(true);
          }
        });
    }

  }, [boCphNo]);

  useEffect(() => {
    const searchResult = storeService.session.get.searchResults()?.holdingActivity?.data;
    if (!searchResult) {
      history.push(Routing.boHoldingActivity);
    }
  }, []);

  return (
    <>
      {ready && holdingBOResult &&
        <>
          <section className={printClasses.boVaDeclaration}>
            <div className={printClasses.boTitleSection}>
              <PageTitle
                id="holdingActivityTitle"
                pageTitleOne="label.vaRecord"
                pageTitleTwo={boCphNo}
              />
            </div>

            <div className="pageContent">
              <div className={printClasses.boheaderPrint}>
                <img
                  alt="Livestock Information Service"
                  className={printClasses.logo}
                  src={CompanyPrintLogo}
                />
              </div>
              <main className={classes.mainContanier} id="printId">
                <section className={classes.titleSection + ' ' + classes.bottomBorder}>
                  <h1 className={classes.pageTitle}>{t('vetContent.attestationDecRecord')}</h1>
                </section>
                <section className={classes.bodySection}>
                  <div className={classes.summaryBlock + ' ' + classes.topSpace}>
                    <div className={classes.summaryRow}>
                      <div className={classes.label}>{t('vetContent.visitDate')}</div>
                      <div className={classes.value}>{helpers.date.format(filterData[0]?.startDate)}</div>
                    </div>
                    {vetAttestationNumber &&
                      <div className={classes.summaryRow}>
                        <div className={classes.label}>{t('vetContent.vetAttestationNumber')}</div>
                        <div className={classes.value}>{vetAttestationNumber ? vetAttestationNumber : '-'}</div>
                      </div>
                    }
                    <div className={classes.summaryRow}>
                      <div className={classes.label}>{t('label.holdingName')}</div>
                      <div className={classes.value}>{holdingBOResult.propertyName ? holdingBOResult.propertyName : '-'}</div>
                    </div>
                    <div className={classes.summaryRow}>
                      <div className={classes.label}>{t('label.holdingAddress')}</div>
                      <div className={classes.value}>
                        <Address
                          cph={boCphNo}
                          displayCPH = {false}
                          format={{ address: constants.address.format.onlyAddress }}
                          setModal={setModal}
                        />
                      </div>
                    </div>
                    <div className={classes.summaryRow}>
                      <div className={classes.label}>{t('label.cphNumber')}</div>
                      <div className={classes.value}>{filterData[0]?.holding}</div>
                    </div>
                    <div className={classes.summaryRow}>
                      <div className={classes.label}>{t('vetContent.vetClaims.veterinarianName')}</div>
                      <div className={classes.value}>{filterData[0]?.vetName}</div>
                    </div>
                    <div className={classes.summaryRow}>
                      <div className={classes.label}>{t('vetContent.vetClaims.practiceName')}</div>
                      <div className={classes.value}>{filterData[0]?.vetEmployer}</div>
                    </div>
                    <div className={classes.summaryRow}>
                      <div className={classes.label}>{t('vetContent.vetClaims.practiceAddress')}</div>
                      <div className={classes.value}>{filterData[0]?.vetEmploymentAddress}</div>
                    </div>
                    <div className={classes.summaryRow}>
                      <div className={classes.label}>{t('vetContent.vetClaims.rcvsNumber')}</div>
                      <div className={classes.value}>{filterData[0]?.vetRCVS}</div>
                    </div>
                  </div>
                  <div className={classes.declarationBlock}>
                    <div className={classes.fontBold}>{t('vetContent.vetDeclaration.title')}</div>
                    <p>{t('vetContent.vetDeclaration.content1')}</p>
                    <div>{helpers.date.format(filterData[0]?.startDate)}</div>
                    <p className={classes.declartionContent}>
                      {t('vetContent.vetDeclaration.content2-part-1')}
                      <span>&#8544;</span>&nbsp;
                      {t('vetContent.vetDeclaration.content2-part-2')}
                    </p>
                    <div className={classes.fontBold}>{filterData[0]?.vetName}</div>
                  </div>
                </section>
                <div className="actions   d-print-none">
                  <div className="left">
                    <Button
                      buttonType="primary"
                      id="cancelButton"
                      label="button.printSave"
                      onClick={printAndDownload}
                    />
                  </div>
                  <div className="right">
                    <Button
                      buttonType="tertiary"
                      id="cancelButton"
                      label="button.return"
                      onClick={handleReturnRouting}
                    />
                  </div>
                </div>
              </main>
            </div>
          </section>
        </>
      }
    </>
  );
};

export default VARecordDeclaration;
