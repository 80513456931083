import React, { createRef } from 'react';
import { CSVReader } from 'react-papaparse';
import classes from 'backOfficeComponents/base/fileUpload/fileUpload.module.scss';
import config from 'config';
import PropTypes from 'prop-types';
import Button from 'backOfficeComponents/base/button/button';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldLabel from 'backOfficeComponents/base/fieldLabel/fieldLabel';
import Hint from 'backOfficeComponents/base/hint/hint';
import Legend from 'backOfficeComponents/base/legend/legend';
import helpers from 'services/helpers';

const FileUpload = ({
  animalDetails,
  hideHint,
  id,
  pending,
  setAnimalDetails,
  setDataChanged,
  setPending
}) => {
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [fileError, setFileError] = React.useState(false);
  const [fileTypeError, setFileTypeError] = React.useState(false);
  const [maxRecordsError, setMaxRecordsError] = React.useState('');
  const buttonRef = createRef();

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    const regex = /^.*\.(csv)$/;
    if (regex.test(buttonRef.current.state.file.name)) {
      setSelectedFile([]);
      data.forEach((element) => {
        element.data.forEach((el) => {
          const trimmedElement = el.replaceAll(' ', '').toLowerCase();

          if (trimmedElement.length > 0 &&
            (
              trimmedElement.match(/^[a-z]{2}[0-9]{1,}/) ||
              trimmedElement.match(/^[0-9]{15,16}/) ||
              trimmedElement.match(/^[0-9a-f]{8}/)
            )
          ) {
            setSelectedFile((prevArray) => [...prevArray, el]);
          }
        });
      });
      setFileTypeError(false);
    } else {
      setFileTypeError(true);
      setFileError(false);
    }
  };

  const validateTags = (tags) => {
    const uniqueTags = [...new Set(tags)].map((tag) => tag.trim());

    if (uniqueTags.length > config.MAX_UPLOAD_RECORDS) {
      setMaxRecordsError('error.maxRecordsNumber');
    } else {
      setMaxRecordsError('');
      setAnimalDetails([
        ...animalDetails,
        ...uniqueTags.map((item) => ({
          tagNumber: item,
          uuid: helpers.generateUUID()
        }))
      ]);
      setDataChanged();
      setPending(false);
      setFileError(false);
      setSelectedFile([]);
    }
  };

  return (
    <fieldset id={id} role="group">
      <Legend
        id={id}
        legend="label.uploadAFile"
      />
      {!hideHint &&
        <Hint
          hint="label.uploadDesc"
          id={id}
        />
      }
      <div className={classes.fileUpload}>
        {fileError &&
          <ErrorLabel
            id={id}
            label="error.fileUploadedHasInvalidTags"
          />
        }
        {fileTypeError &&
          <ErrorLabel
            id={id}
            label="error.fileTypeError"
          />
        }

        <FieldLabel
          id="uploadFile"
          label="label.chooseUpload"
          name="uploadFile"
        />
        <div className={classes.fileBrowser}>
          <CSVReader
            config={{
              delimiter: ',',
              encoding: 'utf8'
            }}
            noClick
            noDrag
            onFileLoad={handleOnFileLoad}
            ref={buttonRef}
          >
            {({ file }) => (
              <div className={classes.browseWrap}>
                <Button
                  buttonType="upload"
                  disabled={pending}
                  id="browseButton"
                  label="label.browse"
                  onClick={handleOpenDialog}
                />{' '}
                {selectedFile.length > 0 && file && file.name}
              </div>
            )}
          </CSVReader>
          <div className={classes.upload}>
            <Button
              buttonType="secondary"
              disabled={selectedFile.length === 0 || fileTypeError || pending}
              id="uploadButton"
              label="button.upload"
              onClick={() => validateTags(selectedFile)}
            />
          </div>
        </div>
        {maxRecordsError &&
          <ErrorLabel
            id="maxRecords"
            label={maxRecordsError}
          />
        }
      </div>
    </fieldset>
  );
};

FileUpload.propTypes = {
  animalDetails: PropTypes.array,
  hideHint: PropTypes.bool,
  id: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  setAnimalDetails: PropTypes.func.isRequired,
  setDataChanged: PropTypes.func.isRequired,
  setPending: PropTypes.func.isRequired
};

export default FileUpload;
