import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import constants from 'services/constants';
import helpers from 'services/helpers';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AddBatch from 'components/addBatch/addBatch';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Table from 'components/table/table';

const AnimalsBatches = ({
  batchList,
  batchListValidation,
  batchTotal,
  pending,
  resetForm,
  setBatchList,
  setBatchTotal,
  setModal,
  setPending,
  updateBatchList
}) => {
  const { ready, t } = useTranslation();

  const isAbattoir = permissions.isAbattoir();
  const sessionSpecies = storeService.session.get.species();

  const [paginationParams, setPaginationParams] = React.useState({
    ...constants.pagination.defaultParams,
    [constants.pagination.bottom]: {
      ...constants.pagination.defaultParams[constants.pagination.bottom],
      totalLabel: 'label.totalBatches'
    }
  });

  const handleChanges = {
    removeAllInvalid: () => {
      updateBatchList(helpers.animal.removeAllInvalid(batchList));
    },

    removeBatch: (batch) => {
      setBatchList((prevState) => {
        const newBatchList = helpers.batch.remove([...prevState], batch);

        if (newBatchList.length === 0) {
          storeService.session.remove.movementBatchList();
        } else {
          storeService.session.set.movementBatchList(newBatchList);
        }

        return newBatchList;
      });

      setTimeout(() => {
        document.activeElement.blur();
      }, 1);
    },

    removeBatchesAll: () => {
      updateBatchList([]);
    }
  };

  const warningDisplay = () => {
    return (
      <div id="abattoirWarning">
        <ErrorLabel
          isWarning={true}
          label="warning.abattoirBatch"
        />
      </div>
    );
  };

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      [constants.pagination.bottom]: {
        ...prevState[constants.pagination.bottom],
        total: batchList?.length
      }
    }));
  }, [batchList]);

  return (
    <>
      {ready &&
        <details className="accordion" open={batchListValidation > 0}>
          <summary>
            <h2>{t('movementDetails.animalsMoved.heading') + (batchTotal ? ' (' + batchTotal + ')' : '')}</h2>
          </summary>
          <div className="section">
            {isAbattoir && warningDisplay()}

            <div className="section">
              <AddBatch
                animalTotalLabel="label.noOfAnimals"
                batchDescription="movements.animal-selection.batch.desc"
                batchHeader="movements.animal-selection.batch.title"
                batchList={batchList}
                batchNumberOptional={true}
                batchTotal={batchTotal}
                buttonLabel="movements.animal-selection.batch.submit"
                id="addBatch"
                pending={pending}
                resetForm={resetForm}
                setBatchTotal={setBatchTotal}
                setModal={setModal}
                setPending={setPending}
                species={sessionSpecies}
                updateBatchList={updateBatchList}
              />
            </div>

            {batchTotal > 0 &&
              <>
                {batchList?.some((batch) => helpers.batch.isInvalid(batch)) &&
                  <ErrorLabel
                    label="formatExamples.tagBatchError"
                    modalMessage={helpers.batch.getFormatExamples(sessionSpecies.id).description}
                    modalTitle={helpers.batch.getFormatExamples(sessionSpecies.id).title}
                    setModal={setModal}
                  />
                }
                <Table
                  clearAll={handleChanges.removeBatchesAll}
                  columns={tableColumns.movementsGatherings.batch({ actionFunction: handleChanges.removeBatch, speciesId: sessionSpecies.id })}
                  data={batchList}
                  dataProvided={true}
                  initialSortBy={initialSorting.movementsGatherings.batch.accessor}
                  initialSortDirection={initialSorting.movementsGatherings.batch.direction}
                  paginationParams={paginationParams}
                  removeAllInvalid={handleChanges.removeAllInvalid}
                  setData={setBatchList}
                  setModal={setModal}
                  title={{
                    title: helpers.text.pluralCheck(batchList?.reduce((total, item) => total + parseInt(item.animalTotal), 0), 'table.title.batches'),
                    data: [
                      batchList?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
                    ]
                  }}
                />
              </>
            }
          </div>
        </details>
      }
    </>
  );
};

AnimalsBatches.propTypes = {
  batchList: PropTypes.array,
  batchListValidation: PropTypes.number,
  batchTotal: PropTypes.number,
  pending: PropTypes.bool,
  resetForm: PropTypes.bool,
  setBatchList: PropTypes.func.isRequired,
  setBatchTotal: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setPending: PropTypes.func,
  updateBatchList: PropTypes.func
};

export default AnimalsBatches;
