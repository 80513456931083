import React, { useEffect } from 'react';
import classes from 'backOfficeComponents/sections/locationAddressDate/locationAddressDate.module.scss';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import CPHEntry from 'backOfficeComponents/cphEntry/cphEntry';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import InputDate from 'components/base/inputDate/inputDate';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';
import NumberEntry from 'components/base/numberEntry/numberEntry';

const LocationAddressDate = ({
  cphError,
  data,
  dateError,
  dateLabel,
  departureDaysAhead,
  id,
  isPaper,
  labelPosition,
  labelText,
  maxDate,
  movementType,
  numberReceivedError,
  numberReceivedLabel,
  panelTitle,
  pmMove,
  setCphValid,
  setData,
  setDataChanged,
  setDateError,
  setModal,
  speciesId
}) => {
  const numberOfHoldings = 0;

  const isOptional = () => {
    if (!numberReceivedLabel && helpers.species.isCattleId(speciesId)) {
      return true;
    }
    return false;
  };

  const dataHasChanged = () => {
    storeService.session.set.dataChanged(true);
    setDataChanged(true);
  };

  const changeFunction = (event) => {
    const newValue = event.target.value;
    switch (event.target.name) {
      case 'manualCPH':
        validatePermitMoveComment(newValue);
        if (pmMove && !validatePermitMoveComment(newValue)) {
          break;
        }
        setData((prevState) => ({ ...prevState, manualCPH: newValue }));
        dataHasChanged();
        break;
      case 'comment':
        validatePermitMoveComment(newValue);
        if (pmMove && !validatePermitMoveComment(newValue)) {
          break;
        }
        setData((prevState) => ({ ...prevState, comment: newValue }));
        dataHasChanged();
        break;
      case 'date':
        if (id === 'departureLocation' && !isPaper) {
          if (helpers.option.movement.isOff(movementType)) {
            const dateIsXDayAheadOverLimit = helpers.date.is.xDaysInTheFutureOverLimit(newValue, departureDaysAhead);
            if (dateIsXDayAheadOverLimit) {
              setDateError({
                type: constants.errorAndWarning.error,
                value: helpers.error.cfgDepartureDateError(dateIsXDayAheadOverLimit, departureDaysAhead)
              });
            } else {
              const dateIsXDayAhead = helpers.date.is.xDaysInTheFuture(newValue, departureDaysAhead);
              if (dateIsXDayAhead) {
                setDateError({ type: constants.errorAndWarning.error, value: dateIsXDayAhead });
              }
            }
          }
          storeService.session.set.movementDepartureDate(newValue);
        } else if (id === 'receivingLocation' && !isPaper) {
          storeService.session.set.movementArrivalDate(newValue);
        }
        if (event.target.value !== data.date) {
          setData((prevState) => ({ ...prevState, date: newValue }));
          dataHasChanged();
        }
        break;
      case 'numberReceived': {
        storeService.session.set.movementAnimalTotal(newValue);
        setData((prevState) => ({ ...prevState, numberReceived: newValue }));
        dataHasChanged();
        break;
      }
      default:
        break;
    }
  };

  const setCPHField = (inValue) => {
    if (id === 'departureLocation') {
      storeService.session.set.movementFromHolding(inValue);
    } else if (id === 'receivingLocation') {
      storeService.session.set.movementToHolding(inValue);
    }
    setData((prevState) => ({ ...prevState, CPH: inValue }));
    dataHasChanged();
  };

  const validatePermitMoveComment = (input) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^[a-zA-Z0-9\s\,\.]*$/;
    return regex.test(input);
  };

  useEffect(() => {
    if (!validatePermitMoveComment(data.manualCPH)) {
      setData((prevState) => ({ ...prevState, manualCPH: '' }));
      dataHasChanged();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmMove]);

  return (
    <>
      <SectionTitleBar
        icon="bi bi-geo-alt"
        id={id + 'SectionTitle'}
        title={panelTitle}
      />
      <div className={classes.locationAndDate}>
        {numberOfHoldings <= 2 &&
          <div className={classes.cph}>
            <CPHEntry
              cphError={{
                type: cphError.type,
                value: cphError.value
              }}
              data={data.CPH}
              disabled={pmMove && !isPaper}
              hideTooltip={pmMove}
              labelText={labelText}
              name={id + 'CPH'}
              setCphValid={setCphValid}
              setData={setCPHField}
              setModal={setModal}
            />
          </div>
        }

        {isPaper &&
          <div className={classes.manualCPH}>
            <FieldEntry
              id={id + 'manualCPH'}
              labelPosition={labelPosition}
              labelText={ pmMove ? 'movements.permitMove.comment.titleBo' : 'label.manualCPH' }
              maxLength={ String(config.MAXLENGTH_COMMENT)}
              name="manualCPH"
              onChange={changeFunction}
              reduceSpace={true}
              rows={config.ROWS_CPH_MANUAL}
              value={data.manualCPH}
            />
          </div>
        }

        <div className={pmMove ? classes.pmDate : classes.date}>
          <InputDate
            dontValidate={true}
            error={dateError && helpers.response.isError(dateError.type) ? dateError.value : ''}
            id={isPaper ? id + 'entryDate' : 'dateFrom'}
            labelPosition={labelPosition}
            labelText={dateLabel}
            maxDate={maxDate}
            monthsShown={2}
            onChange={changeFunction}
            optional={isOptional()}
            reduceSpace={true}
            setError={setDateError}
            suppressLabel={true}
            value={data.date}
            warning={dateError && helpers.response.isWarning(dateError.type) ? dateError.value : ''}
            yearLength={2}
          />
        </div>

        {pmMove && !isPaper &&
          <div className={classes.comment}>
            <FieldEntry
              id={id + 'comment'}
              labelPosition={labelPosition}
              labelText="movements.permitMove.comment.titleBo"
              maxLength={String(config.MAXLENGTH_COMMENT)}
              name="comment"
              onChange={changeFunction}
              reduceSpace={true}
              value={data.comment}
            />
          </div>
        }
      </div>

      {numberReceivedLabel?.length > 0 &&
        <div className={classes.animalsReceived}>
          <NumberEntry
            error={helpers.response.isError(numberReceivedError.type) ? numberReceivedError.value : ''}
            id={id + 'numberReceived'}
            label={numberReceivedLabel}
            max={Math.pow(10, config.WIDTH_NUM_RECEIVED) - 1}
            min={0}
            name="numberReceived"
            onChange={changeFunction}
            value={data.numberReceived}
            warning={helpers.response.isWarning(numberReceivedError.type) ? numberReceivedError.value : ''}
          />
        </div>
      }
    </>
  );
};

LocationAddressDate.propTypes = {
  cphError: PropTypes.exact({
    type: PropTypes.oneOf([
      constants.errorAndWarning.error,
      constants.errorAndWarning.warning,
      ''
    ]),
    value: PropTypes.string
  }),
  data: PropTypes.object,
  dateError: PropTypes.object,
  dateLabel: PropTypes.string,
  departureDaysAhead: PropTypes.number,
  id: PropTypes.string.isRequired,
  isPaper: PropTypes.bool,
  labelPosition: PropTypes.string,
  labelText: PropTypes.string,
  maxDate: PropTypes.string,
  movementType: PropTypes.string,
  numberReceivedError: PropTypes.object,
  numberReceivedLabel: PropTypes.string,
  panelTitle: PropTypes.string,
  pmMove: PropTypes.bool,
  setCphValid: PropTypes.func,
  setData: PropTypes.func,
  setDataChanged: PropTypes.func,
  setDateError: PropTypes.func,
  setModal: PropTypes.func.isRequired
};

export default LocationAddressDate;
