import React, { useEffect } from 'react';
import permissions from 'services/permissions';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';
import { ReactComponent as CattleIcon } from 'images/svg/icon-cattle.svg';
import { ReactComponent as SheepIcon } from 'images/svg/icon-sheep.svg';
import { ReactComponent as GoatIcon } from 'images/svg/icon-goat.svg';
import { ReactComponent as PigIcon } from 'images/svg/icon-pig.svg';
import { ReactComponent as DeerIcon } from 'images/svg/icon-deer.svg';
import { holdingHelper } from 'services/holdingHelper';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import userRoleType from 'services/userRoleType';
import breedsHelper from 'services/breedsHelper';
import classes from 'frontEnd/home/home.module.scss';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import navigation from 'services/navigation';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Address from 'components/address/address';
import TabsNavigational from 'components/base/tabsNavigational/tabsNavigational';
import UserActivity from 'frontEnd/home/userActivity/userActivity';
const sprintf = require('util').format;

const HoldingButton = ({
  children,
  className,
  holdingKey,
  id,
  onClick
}) => {
  return (
    <button
      className={className}
      id={id}
      key={holdingKey}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const Home = ({
  loading,
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const myHoldingTabActive = location.pathname === Routing.holdings;
  const userActivityTabActive = location.pathname === Routing.userActivity;

  const [totalNotifications, setTotalNotifications] = React.useState(0);
  const [holdings, setHoldings] = React.useState([]);
  const [loadPending, setLoadPending] = React.useState(true);
  const [numHoldings, setNumHoldings] = React.useState([]);
  const [bannerClasses, setBannerClasses] = React.useState([classes.notificationsBanner, 'loading'].join(' '));
  const [disableHoldingAccess, setDisableHoldingAccess] = React.useState(false);

  storeService.session.remove.holding();
  storeService.session.remove.species();

  const hasKeeperHoldings = holdings.some((holding) => holding.role === userRoleType.KEEPER);

  const noteClassname = (animalType) => {
    return animalType.notifications > 0 ? [classes.holding, classes.error].join(' ') : classes.holding;
  };

  const selectHolding = (holdingId, speciesId) => {
    if (disableHoldingAccess) {
      setModal({
        modalMessage: 'uiFeatures.featureDisabled',
        modalTitle: 'uiFeatures.featureDisabledTitle'
      });
    } else {
      const selectedHolding = holdings.find((holding) => holding.value === holdingId);
      storeService.session.set.holding(selectedHolding);
      storeService.session.set.permissions(selectedHolding.role);
      setPermission(selectedHolding.role);

      if (selectedHolding.animalTypes?.length) {
        storeService.session.set.animals(selectedHolding.animalTypes.map((animal) => animal.name));
        breedsHelper.fetchBreedsOnHolding(null, setModal, holdingId, speciesId);
        breedsHelper.update(selectedHolding.animalTypes.find((animal) => animal.id === speciesId));
      } else {
        storeService.session.set.animals(['none']);
      }

      if (permissions.isKeeper()) {
        history.push(Routing.keeperHolding);
      } else if (permissions.isAbattoir()) {
        history.push(navigation.search());
      } else {
        history.push(navigation.movements());
      }
    }

  };

  const toggleHolding = (holding) => {
    Array.from(document.getElementsByClassName('accordion')).forEach((item) => {
      if (item.id !== 'holding' + holding) {
        item.removeAttribute('open');
      }
    });
  };

  useEffect(() => {
    setPermission(userRoleType.GENERIC);

    bff
      .get('/rha')
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          setDisableHoldingAccess(JSON.parse(res.data.statusText));
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setDisableHoldingAccess(false);
        }
      });

    const sourcesArray = [];
    const sessionHoldings = storeService.session.get.holdings();
    const numberOfHoldings = sessionHoldings ? Object.entries(sessionHoldings)?.length : 0;
    setNumHoldings(numberOfHoldings);

    if (sessionHoldings && numberOfHoldings > 0) {
      const sortedHoldings = orderBy(sessionHoldings, 'value').filter((holding) => holdingHelper.region.isEngland(holding.region));

      sortedHoldings.forEach((holding) => {
        if (helpers.userRole.isKeeper(holding.role)) {
          holding.loadPending = true;
        }
        holding.notifications = 0;
        holding.isShown = false;
        holding.animalTypes.sort((a, b) => constants.species.order.indexOf(a.id) - constants.species.order.indexOf(b.id));
      });

      setHoldings(sortedHoldings);

      sortedHoldings.forEach((holding) => {
        if (helpers.userRole.isKeeper(holding.role)) {
          const source = axios.CancelToken.source();
          sourcesArray.push(source);
          const cph = holding.value;
          const holdingsQueryString = holding.animalTypes.map((species) => ({ cph, species: species.name }));

          bff
            .get('/notificationsCount', {
              cancelToken: source.token,
              params: {
                holdings: JSON.stringify(holdingsQueryString)
              }
            })
            .then((res) => {
              if (helpers.response.isValid(res.data, setModal)) {
                const specificHolding = res.data.data[holding.value];
                holding.animalTypes.forEach((species) => {
                  species.notifications = specificHolding[species.name];
                });

                const numberOfNotifications = holding.animalTypes.reduce((total, species) => total + species.notifications, 0);
                holding.notifications = numberOfNotifications;
                holding.loadPending = false;

                setHoldings([...sortedHoldings]);
                setTotalNotifications(totalNotifications + numberOfNotifications);

                const animalTypesNotifications = {};
                holding.animalTypes.forEach((species) => {
                  animalTypesNotifications[species.name] = species.notifications;
                });
              }
            })
            .catch((error) => {
              if (!isCancel(error)) {
                holding.loadPending = false;
                holding.error = true;
                errors.BFF(error, setModal);
              }
            });
        }
      });
    }

    return () => {
      sourcesArray.forEach((source) => source.cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pending = holdings?.length > 0 ? holdings.some((holding) => holding.loadPending) : true;
    const notifications = holdings.reduce((total, item) => total + item.notifications, 0);
    setLoadPending(pending);

    setTotalNotifications(notifications);

    if (pending) {
      setBannerClasses([classes.notificationsBanner, classes.notificationsLoading].join(' '));
    } else if (notifications > 0) {
      setBannerClasses([classes.notificationsBanner, classes.notificationsPresent].join(' '));
    } else {
      setBannerClasses([classes.notificationsBanner, classes.notificationsNotPresent].join(' '));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdings, loadPending, totalNotifications]);

  return (
    <>
      {ready && permissions.isLoggedIn && !permissions.isBackOffice() &&
        <div className={classes.holdings}>
          <h1 className="h1">{t('menu.home')}</h1>
          <AboutPanel
            descriptions={[
              { text: hasKeeperHoldings ? 'about.home.desc1-1' : 'about.home.desc1-2' },
              { text: 'about.home.desc2' },
              { text: hasKeeperHoldings ? 'about.home.desc3' : '' }
            ]}
            title="about.genericTitle.page"
          />

          <TabsNavigational>
            <div active={myHoldingTabActive} id={constants.tabs.holdings} label="label.holdings" url={navigation.holdings()}>
              {hasKeeperHoldings &&
                <div className={'totalNotifications ' + bannerClasses} role="alert">
                  <h3 className="h3">{t('label.notifications')}</h3>
                  <ul>
                    {loadPending &&
                      <li className={classes.fetching}>{t('label.fetchingNotifications')}</li>
                    }
                    {!loadPending &&
                      <>
                        {totalNotifications === 0 &&
                          <li>{t('label.youHave0Notification')}</li>
                        }
                        {totalNotifications > 0 &&
                          <>
                            <li className={classes.notificationCount}>{sprintf(t(totalNotifications === 1 ? 'label.youHave1Notification' : 'label.youHaveXNotifications'), totalNotifications)}</li>
                            <li className={classes.notificationDescription}>{t('label.notificationsDescription')}</li>
                          </>
                        }
                      </>
                    }
                  </ul>
                </div>
              }
              {loading.holdings &&
                <h3 className="h3">{t('label.fetchingHoldings')}</h3>
              }

              {!loading.holdings &&
                <>
                  {holdings?.length === 0 &&
                    <>
                      <h3 className="h3">{t('label.noHoldings')}</h3>
                      <p dangerouslySetInnerHTML={{ __html: t('label.pleaseContactCustomerSupport') }} />
                    </>
                  }

                  {holdings?.length > 0 &&
                    <>
                      <h3 className="h3">{t('label.selectAHolding')}</h3>

                      {holdings.map((holding) => {
                        const strippedHolding = (holding.value).replace(/\//g, '');

                        return (
                          <details
                            className={'accordion home ' + (numHoldings === 1 ? 'single' : '')}
                            id={'holding' + strippedHolding}
                            key={holding.value}
                            onClick={() => toggleHolding(strippedHolding)}
                            open={numHoldings === 1}
                          >
                            <summary>
                              <h2>
                                <span className="cphAddress">
                                  <Address
                                    cph={holding.value}
                                    displayCPH={true}
                                    format={{
                                      address: constants.address.format.inline
                                    }}
                                    queryKeeper={true}
                                    setModal={setModal}
                                  />
                                </span>

                                {helpers.userRole.isKeeper(holding.role) &&
                                  <>
                                    {holding.loadPending &&
                                      <span className="fetchingTotalNotifications">{t('label.fetchingNotifications')}</span>
                                    }
                                    {!holding.loadPending && holding.notifications > 0 &&
                                      <span className="notificationCount">
                                        {holding.notifications}{' '}
                                        {t(helpers.text.pluralCheck(holding.notifications, 'label.unactionedNotification'))}
                                      </span>
                                    }
                                    {!holding.loadPending && holding.error &&
                                      <span className="notificationCount">{t('error.unableToRetrieve')}</span>
                                    }
                                  </>
                                }
                              </h2>
                            </summary>
                            <div>
                              {holding.animalTypes.map((animalType) => (
                                <HoldingButton
                                  className={noteClassname(animalType)}
                                  holdingKey={holding.value + '_' + animalType.id}
                                  id={(animalType.name).toLowerCase() + (holding.value).replace(/\//g, '')}
                                  key={holding.value + '_' + animalType.id}
                                  onClick={() => selectHolding(holding.value, animalType.id)}
                                >
                                  <div className={classes.species}>
                                    {helpers.species.isCattleId(animalType.id) &&
                                      <CattleIcon alt={t('animal.' + animalType.id)} className={classes.icon} />
                                    }
                                    {helpers.species.isSheepId(animalType.id) &&
                                      <SheepIcon alt={t('animal.' + animalType.id)} className={classes.icon} />
                                    }
                                    {helpers.species.isGoatId(animalType.id) &&
                                      <GoatIcon alt={t('animal.' + animalType.id)} className={classes.icon} />
                                    }
                                    {helpers.species.isPigId(animalType.id) &&
                                      <PigIcon alt={t('animal.' + animalType.id)} className={classes.icon} />
                                    }
                                    {helpers.species.isDeerId(animalType.id) &&
                                      <DeerIcon alt={t('animal.' + animalType.id)} className={classes.icon} />
                                    }
                                  </div>
                                  <div className={[
                                    classes.notificationsWrapper,
                                    animalType.notifications > 0 ? classes.notifications : ''
                                  ].join(' ').trim()}>
                                    <p className={classes.speciesName}><span className={classes.bold}>{t('label.species')}</span>: {t('animal.' + animalType.id)}</p>
                                    {helpers.userRole.isKeeper(holding.role) &&
                                      <>
                                        {holding.loadPending &&
                                          <span className={classes.fetching}>{t('label.fetchingNotifications')}</span>
                                        }
                                        {!holding.loadPending &&
                                          <span className={animalType.notifications > 0 ? classes.bold : classes.normal}>{t('label.notifications')} ({animalType.notifications})</span>
                                        }
                                        {!holding.loadPending && holding.error &&
                                          <span className={classes.bold}>{t('error.unableToRetrieve')}</span>
                                        }
                                      </>
                                    }
                                  </div>
                                </HoldingButton>
                              ))}
                            </div>
                          </details>
                        );
                      })}
                    </>
                  }
                </>
              }
            </div>
            <div active={userActivityTabActive} classNames={holdings?.length > 0 ? [] : ['hidden']} id={constants.tabs.userActivity} label="label.userActivity" url={holdings?.length > 0 ? navigation.userActivity() : null}>
              {!loading.holdings && holdings?.length > 0 &&
                <UserActivity
                  location="home"
                  setModal={setModal}
                />
              }
            </div>
          </TabsNavigational>
        </div>
      }
    </>
  );
};

Home.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func.isRequired
};

export default Home;
