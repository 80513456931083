import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classes from 'backOffice/holdingActivity/holdingActivity.module.scss';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import PageTitle from 'backOfficeComponents/base/pageTitle/pageTitle';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';
import Table from 'components/table/table';

const BoHoldingActivity = ({
  location,
  setModal
}) => {
  const { ready } = useTranslation();
  const history = useHistory();

  const sessionHoldingActivity = storeService.session.get.searchResultsHoldingActivity();

  const [eventType, setEventType] = React.useState({ current: { label: '', url: '' }, previous: {} });
  // const [returnUrl, setReturnUrl] = React.useState('');
  const [data, setData] = React.useState(sessionHoldingActivity ? sessionHoldingActivity.data : []);
  const [renderTable, setRenderTable] = React.useState(false);

  const tableParams = {
    request: {}
  };

  useEffect(() => {
    if (location.pathname !== Routing.boHoldingActivity) {
      setEventType((prevState) => ({
        current: Object.values(constants.bo.holdingActivity.eventTypes).find((item) => item.url === location.pathname),
        previous: prevState.current
      }));
    }
  }, [location]);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.boHoldingActivity.length) !== Routing.boHoldingActivity &&
        path.pathname.substring(0, Routing.boHoldingActivityAnimalsAdded.length) !== Routing.boHoldingActivityAnimalsAdded &&
        path.pathname.substring(0, Routing.boHoldingActivityAnimalsOnHolding.length) !== Routing.boHoldingActivityAnimalsOnHolding &&
        path.pathname.substring(0, Routing.boHoldingActivityAnnualInventory.length) !== Routing.boHoldingActivityAnnualInventory &&
        path.pathname.substring(0, Routing.boHoldingActivityAdjacentMovements.length) !== Routing.boHoldingActivityAdjacentMovements &&
        path.pathname.substring(0, Routing.boHoldingActivityAssociatedHoldings.length) !== Routing.boHoldingActivityAssociatedHoldings &&
        path.pathname.substring(0, Routing.boHoldingActivityDeaths.length) !== Routing.boHoldingActivityDeaths &&
        path.pathname.substring(0, Routing.boHoldingActivityHoldingDetails.length) !== Routing.boHoldingActivityHoldingDetails &&
        path.pathname.substring(0, Routing.boHoldingActivityMovements.length) !== Routing.boHoldingActivityMovements &&
        path.pathname.substring(0, Routing.boHoldingActivityPendingReviews.length) !== Routing.boHoldingActivityPendingReviews &&
        path.pathname.substring(0, Routing.boHoldingActivityTaggingApplications.length) !== Routing.boHoldingActivityTaggingApplications &&
        path.pathname.substring(0, Routing.boHoldingActivityTaggingReplacement.length) !== Routing.boHoldingActivityTaggingReplacement &&
        path.pathname.substring(0, Routing.boVaRecords.length) !== Routing.boVaRecords
      ) {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
        unListen();
      }
    });
  }, [history]);

  useEffect(() => {
    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(Routing.boVaRecords);
      }
    };
  }, [history]);

  return (
    <>
      {ready &&
        <>
          <PageTitle
            id="holdingActivityTitle"
            pageTitleOne="boApp.pageTitles.holdingActivity.holdingActivity"
            pageTitleTwo={location.pathname !== Routing.boHoldingActivity ? Object.values(constants.bo.holdingActivity.eventTypes).find((area) => area.url === location.pathname).label : ''}
          />

          <div className="pageContent">
            <SectionTitleBar
              icon="bi bi-search"
              id="holdingActivityTitleBar"
              title="boApp.label.holdingActivity"
            />

            <div className={classes.hdContainer}>
              <Table
                data={data}
                eventType={eventType.current}
                fetchingDataLabel="label.fetchingHoldingActivity"
                filtersComponents={{
                  [constants.filters.outside]: 'BoHoldingActivity',
                  [constants.filters.inside]: eventType.current.url === Routing.boHoldingActivityAnimalsOnHolding ? 'AnimalsOnHolding' : null
                }}
                noDataLabel={ eventType.current.url === Routing.boVaRecords ? 'label.noPrevDigitalRecord' : ''}
                params={tableParams}
                renderTable={renderTable || data?.length > 0}
                setData={setData}
                setEventType={setEventType}
                setModal={setModal}
                setRenderTable={setRenderTable}
                store={{
                  filters: 'HoldingActivity',
                  data: 'HoldingActivity'
                }}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

BoHoldingActivity.propTypes = {
  location: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default BoHoldingActivity;
