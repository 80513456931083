import React from 'react';
import { defineCustomElements } from '@duetds/date-picker/dist/loader';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';

defineCustomElements(window).catch((error) => {
  console.error(error); //eslint-disable-line
});

const useListener = (ref, eventName, handler) => {
  React.useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      element.addEventListener(eventName, handler);
      return () => element.removeEventListener(eventName, handler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, handler, ref]);
};

const dateFormat = (yearLength) => new RegExp(
  `^(\\d{1,2})\\D(\\d{1,2})\\D(\\d{${yearLength}})$`
);

const localisation = {
  buttonLabel: 'Choose date',
  placeholder: 'DD/MM/YYYY',
  selectedDateMessage: 'Selected date is',
  prevMonthLabel: 'Previous month',
  nextMonthLabel: 'Next month',
  monthSelectLabel: 'Month',
  yearSelectLabel: 'Year',
  closeLabel: 'Close window',
  keyboardInstruction: 'You can use arrow keys to navigate dates',
  calendarHeading: 'Choose a date',
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
};

const mapDateFormat = (yearLength) => yearLength && yearLength === 4 ? 'DD/MM/YYYY' : 'DD/MM/YY';

const dateAdapter = (yearLength) => ({
  parse: (value = '', createDate) => {
    const matches = value.match(dateFormat(yearLength));
    if (matches) {
      const getYear = (yearToParse, yearLength) => {
        if (yearLength === 4) {
          return yearToParse.replace(/^0+/, (element) => {
            return '1'.repeat(element.length);
          });
        }
        return 20 + yearToParse;
      };
      return createDate(getYear(matches[3], yearLength), matches[2], matches[1]);
    }
    return null;
  },
  format: (date) => helpers.date.formatJSDate(date, mapDateFormat(yearLength))
});

const DatePicker = ({
  id,
  onBlur,
  onChange,
  onClose,
  onFocus,
  onOpen,
  value,
  yearLength,
  ...props
}) => {

  const ref = React.useRef(null);

  useListener(ref, 'duetChange', onChange);
  useListener(ref, 'duetFocus', onFocus);
  useListener(ref, 'duetBlur', (event) => {
    onBlur(event, id);
  });
  useListener(ref, 'duetOpen', onOpen);
  useListener(ref, 'duetClose', onClose);

  React.useEffect(() => {
    const fieldCheck = setInterval(() => {
      const theField = document.getElementById(id);
      if (theField) {
        theField.setAttribute('maxlength', 6 + (yearLength ? yearLength : 4));
        clearInterval(fieldCheck);
      }
    }, 500);
  }, [id, yearLength]);

  React.useEffect(() => {
    ref.current.localization = { ...localisation, placeholder: mapDateFormat(yearLength) };
    ref.current.dateAdapter = dateAdapter(yearLength);
  }, [yearLength]);

  return (
    <duet-date-picker
      identifier={id}
      ref={ref}
      value={value}
      {...props}
    />);
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onFocus: PropTypes.func,
  onOpen: PropTypes.func,
  value: PropTypes.string,
  yearLength: PropTypes.number
};

export default DatePicker;
