import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classes from 'frontEnd/movements/summary/summary.module.scss';
import helpers from 'services/helpers';
import navigation from 'services/navigation';
import PropTypes from 'prop-types';
import Routing from 'routing';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import AddressSummary from 'components/summary/addressSummary/addressSummary';
import AnimalSummary from 'components/summary/animalSummary/animalSummary';
import Button from 'components/base/button/button';
import DateSummary from 'components/summary/dateSummary/dateSummary';
import ExemptionSummary from 'components/summary/exemptionSummary/exemptionSummary';
import FciSummary from 'components/summary/fciSummary/fciSummary';
import TransportInformationBox from 'components/summary/transportInformationBox/transportInformationBox';
import TransportSummary from 'components/summary/transportSummary/transportSummary';
import UserComment from 'components/summary/userComment/userComment';
import storeService from 'services/storeService';

const Summary = ({
  amend,
  amendChangedData,
  currentHolding,
  fciData,
  finalAnimalDetails,
  location,
  movementData,
  pending,
  setActions,
  setFinalAnimalDetails,
  setFromSummary,
  setModal,
  species,
  submitMovement,
  transportInformation
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const numberBeingMoved = finalAnimalDetails && finalAnimalDetails.numberOfAnimalsSelected;
  const fciStoredData = storeService.session.get.movementFci();
  fciData = fciData?.animalsSatisfy !== fciStoredData?.animalsSatisfy ? fciStoredData : fciData;

  const returnToPreviousUrl = () => {
    let returnUrl = navigation.userActivity();
    let movementDetails = '';

    if (location?.state?.data?.current?.returnUrl) {
      returnUrl = location.state.data.current.returnUrl;
    }

    if (location?.state?.data && location.state.returnUrl) {
      const data = location.state.data;
      returnUrl = location.state.returnUrl;

      movementDetails = {
        data: { ...data }
      };
    }

    history.push(returnUrl, movementDetails);
  };

  return (
    <>
      {ready &&
        <>
          <h2 className="h2">{t('label.movementSummary')}</h2>

          <AboutPanel
            descriptions={[
              { text: 'about.movementsSummary.desc1' },
              { text: 'about.movementsSummary.desc2' }
            ]}
            title="about.genericTitle.step"
          />

          <TransportInformationBox
            movementData={movementData}
            numberBeingMoved={numberBeingMoved}
            setModal={setModal}
            speciesName={species.name}
          />
          <div className="row">
            <div className="col">
              <DateSummary
                date1={movementData.departureDate}
                date2={
                  (helpers.option.movement.isOff(movementData.movementType) && !movementData.inBusinessMovement && !helpers.option.movement.isMoveToCommonGround(movementData.movementType, movementData.otherHolding.value)) ||
                  helpers.option.movement.isPermitMove(movementData.movementType)
                    ? null
                    : movementData.arrivalDate
                }
                label={helpers.option.movement.isOff(movementData.movementType) || helpers.option.movement.isPermitMove(movementData.movementType) ? 'movements.movement-date.dateLabel' : 'movements.movement-date.dateLabels'}
                onClick={() => {
                  setActions({ userCommentEdit: false });
                  setFromSummary(true);
                  history.push(Routing.movementsStep1);
                }}
              />
              {helpers.option.movement.isPermitMove(movementData.movementType)
                ? <AddressSummary
                  info1={currentHolding && currentHolding.value}
                  info2={t('movements.permitMove.title')}
                  info3={movementData.comment}
                  isGathering={false}
                  isPermitMove={true}
                  label="label.movementDirection"
                  label1="label.departureHoldingFrom"
                  label2="label.destinationHoldingTo"
                  label3="movements.permitMove.comment.titleKeeperSummary"
                  onClick={() => {
                    setActions({ userCommentEdit: false });
                    setFromSummary(true);
                    history.push(Routing.movementsStep1);
                  }}
                  queryKeeper={true}
                  setModal={setModal}
                />
                : <AddressSummary
                  info1={helpers.option.movement.isOff(movementData.movementType)
                    ? currentHolding && currentHolding.value
                    : movementData.otherHolding ? movementData.otherHolding.value : t('label.notSpecified')
                  }
                  info2={helpers.option.movement.isOff(movementData.movementType)
                    ? movementData.otherHolding ? movementData.otherHolding.value : t('label.notSpecified')
                    : currentHolding && currentHolding.value
                  }
                  isGathering={false}
                  label="label.movementDirection"
                  label1="label.departureHoldingFrom"
                  label2="label.destinationHoldingTo"
                  onClick={() => {
                    setActions({ userCommentEdit: false });
                    setFromSummary(true);
                    history.push(Routing.movementsStep1);
                  }}
                  queryKeeper={true}
                  setModal={setModal}
                />
              }
              {!helpers.option.movement.isPermitMove(movementData.movementType) &&
                <UserComment
                  comment={movementData.comment ? movementData.comment : t('label.userCommentNotEntered')}
                  label="label.userComment"
                  onClick={() => {
                    setActions({ userCommentEdit: true });
                    setFromSummary(true);
                    history.push(Routing.movementsStep1);
                  }}
                />
              }
              <ExemptionSummary
                data={movementData}
                label="animalsMoved.summaryLabel"
                onClick={() => {
                  setActions({ userCommentEdit: false });
                  setFromSummary(true);
                  history.push(Routing.movementsStep1);
                }}
              />
              <AnimalSummary
                animalsData={finalAnimalDetails}
                batchesDataProp="batchList"
                label="label.animalsInTotal"
                numberBeingMoved={numberBeingMoved}
                onClick={() => {
                  setActions({ userCommentEdit: false });
                  setFromSummary(true);
                  history.push(Routing.movementsStep2);
                }}
                setAnimalsData={setFinalAnimalDetails}
                setModal={setModal}
              />
              <TransportSummary
                label="label.transportInformation"
                onClick={() => {
                  setActions({ userCommentEdit: false });
                  setFromSummary(true);
                  history.push(Routing.movementsStep3);
                }}
                transportInformation={transportInformation}
              />
              {!helpers.species.isCattleId(species.id) && finalAnimalDetails &&
                <FciSummary
                  animalsData={finalAnimalDetails}
                  fciData={fciData}
                  numberBeingMoved={numberBeingMoved}
                  onClick={() => {
                    setActions({ userCommentEdit: false });
                    setFromSummary(true);
                    history.push(Routing.movementsStep4);
                  }}
                />
              }
            </div>
          </div>

          <div className={classes.actions}>
            <div className={classes.submit}>
              <Button
                buttonType="primary"
                disabled={pending || (amend && !amendChangedData)}
                label={amend ? 'button.submitAmendment' : 'button.submit'}
                onClick={submitMovement}
              />
            </div>
            {!amend &&
              <div className={classes.exit}>
                <Button
                  buttonType="tertiary"
                  label="button.cancelExit"
                  onClick={() => history.push(Routing.keeperHolding)}
                />
              </div>
            }

            {amend &&
              <div className={classes.exit}>
                <Button
                  buttonType="tertiary"
                  label="button.exitNoChanges"
                  onClick={returnToPreviousUrl}
                />
              </div>
            }
          </div>
        </>
      }
    </>
  );
};

Summary.propTypes = {
  amend: PropTypes.bool,
  amendChangedData: PropTypes.bool,
  currentHolding: PropTypes.object,
  fciData: PropTypes.object,
  finalAnimalDetails: PropTypes.object,
  location: PropTypes.object.isRequired,
  movementData: PropTypes.object,
  pending: PropTypes.bool,
  setActions: PropTypes.func,
  setFinalAnimalDetails: PropTypes.func.isRequired,
  setFromSummary: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  species: PropTypes.object,
  submitMovement: PropTypes.func,
  transportInformation: PropTypes.object
};

export default Summary;
