import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/table/pagination/pagination.module.scss';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
const sprintf = require('util').format;

const Pagination = ({
  columnsNumber,
  loadPending,
  location,
  numberOfPages,
  numberOfRecords,
  page,
  paginationParams,
  resultsLabel,
  setTableParams,
  store
}) => {
  const { ready, t } = useTranslation();

  const [render, setRender] = React.useState({
    showingXofY: false,
    total: false,
    pageXofY: false,
    recordsPerPage: false
  });

  const componentClassNames = [
    'tablePagination',
    loadPending ? classes.loadPending : ''
  ].join(' ').trim();

  const pageTotals = (index, size, numRecords) => {
    if (numRecords <= size) {
      return numRecords;
    }
    const totals = ((index + 1) * size);
    return sprintf(
      '%d - %s',
      (page.index * size) + 1,
      (totals > numRecords) ? numRecords : totals
    );
  };

  const jumpToPage = (pageIndex) => {
    setTableParams((prevState) => {
      let targetPage;

      if (pageIndex === 'prev') {
        targetPage = prevState.page.index - 1;
      } else if (pageIndex === 'next') {
        targetPage = prevState.page.index + 1;
      } else {
        targetPage = parseInt(pageIndex);
      }

      if (store?.filters) {
        storeService.session.set[`tableFilters${store.filters}PageIndex`](targetPage);
      }
      if (store?.data) {
        storeService.session.removeAll.searchResults();
      }

      return {
        ...prevState,
        newRequest: false,
        page: {
          ...prevState.page,
          index: targetPage
        }
      };
    });
  };

  useEffect(() => {
    let showingXofY;
    let total;
    let pageXofY;
    let recordsPerPage;

    if (
      !paginationParams?.[location]?.hideShowingXofY ||
      (
        paginationParams?.[location]?.hideShowingXofY &&
        numberOfRecords > page.size
      )
    ) {
      showingXofY = true;
      total = false;
    } else if (
      location === constants.pagination.bottom &&
      paginationParams?.[location]?.hideShowingXofY &&
      !paginationParams?.[location]?.hideTotal &&
      numberOfRecords <= page.size
    ) {
      showingXofY = false;
      total = true;
    }

    if (
      numberOfPages > 1 &&
      (
        !paginationParams?.[location]?.hidePageXofY ||
        numberOfRecords > page.size
      )
    ) {
      pageXofY = true;
    } else {
      pageXofY = false;
    }

    if (numberOfRecords > config.DEFAULT_TABLE_PAGE_SIZE && !paginationParams?.[location]?.hideRecordsPerPage) {
      recordsPerPage = true;
    } else {
      recordsPerPage = false;
    }

    setRender((prevState) => ({
      ...prevState,
      showingXofY,
      total,
      pageXofY,
      recordsPerPage
    }));

  }, [paginationParams, location, page, numberOfPages, numberOfRecords]);

  return (
    <>
      {ready && (render.showingXofY || render.total || render.pageXofY || render.recordsPerPage) &&
        <tr className={componentClassNames} role="row">
          <td colSpan={columnsNumber}>
            <div className={classes.paginationContent}>
              <div className={classes.count}>
                {render.showingXofY &&
                  <>
                    {t('label.showing')}{' '}
                    <span className="numRecords">{pageTotals(page.index, page.size, numberOfRecords)}</span>
                    {' '}{t('label.of')}{' '}
                    <span className="numRecords">{numberOfRecords}</span>
                    {' '}{t(helpers.text.pluralCheck(numberOfRecords, resultsLabel ? resultsLabel : 'label.record'))}
                  </>
                }

                {!render.showingXofY &&
                  <>
                    {paginationParams?.[location]?.totalLabel ? t(paginationParams[location].totalLabel) : t('label.total')}
                    {' '}<span className="numRecords">{paginationParams?.[location]?.total}</span>
                  </>
                }
              </div>

              <div className={classes.pages}>
                {render.pageXofY &&
                  <>
                    {numberOfRecords > page.size &&
                      <button
                        aria-labelledby="previous"
                        className={classes.btnPagination}
                        disabled={loadPending || page.index === 0}
                        onClick={() => jumpToPage('prev')}
                        type="button"
                        value={t('label.previous')}
                      >
                        <i className={classes.arrowLeft} />
                        <span className={classes.srOnly} id="previous">{t('label.previous')}</span>
                      </button>
                    }

                    <div>
                      <span>{t('label.page')}</span>
                      <select
                        className={[classes.select, classes['digits' + numberOfPages.toString().length]].join(' ')}
                        onChange={(event) => jumpToPage(event.target.value)}
                        value={page.index}
                      >
                        {Array.from(Array(numberOfPages).keys()).map((page) => <option key={page} value={page}>{page + 1}</option>)}
                      </select>
                      <span>{t('label.of') + ' ' + (numberOfPages > 0 ? numberOfPages : 1)}</span>
                    </div>

                    {numberOfRecords > page.size &&
                      <button
                        aria-labelledby="next"
                        className={classes.btnPagination}
                        disabled={loadPending || page.index === numberOfPages - 1}
                        onClick={() => jumpToPage('next')}
                        type="button"
                        value={t('label.next')}
                      >
                        <i className={classes.arrowRight} />
                        <span className={classes.srOnly} id="next">{t('label.next')}</span>
                      </button>
                    }
                  </>
                }
              </div>

              <div className={classes.pageSize}>
                {render.recordsPerPage &&
                  <>
                    <label className={classes.label} htmlFor={'pageSize' + location}>{t('label.recordsPerPage')}</label>
                    <select
                      className={classes.select}
                      disabled={loadPending}
                      id={'pageSize' + location}
                      onChange={(event) => setTableParams((prevState) => {
                        if (store?.filters) {
                          storeService.session.removeAll.searchResults();
                          storeService.session.set[`tableFilters${store.filters}PageIndex`](0);
                          storeService.session.set[`tableFilters${store.filters}PageSize`](Number(event.target.value));
                        }

                        return {
                          ...prevState,
                          newRequest: false,
                          page: {
                            index: 0,
                            size: Number(event.target.value)
                          }
                        };
                      })}
                      value={page.size}
                    >
                      {t('label.resultsPage')}
                      {[10, 20, 30, 40, 50].map((numberPerPage) => (
                        <option key={numberPerPage} value={numberPerPage}>
                          {numberPerPage}
                        </option>
                      ))}
                    </select>
                  </>
                }
              </div>
            </div>
          </td>
        </tr>
      }
    </>
  );
};

Pagination.propTypes = {
  columnsNumber: PropTypes.number.isRequired,
  loadPending: PropTypes.bool,
  location: PropTypes.string,
  numberOfPages: PropTypes.number.isRequired,
  numberOfRecords: PropTypes.number.isRequired,
  page: PropTypes.exact({
    index: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired
  }).isRequired,
  paginationParams: PropTypes.object,
  resultsLabel: PropTypes.string,
  setTableParams: PropTypes.func.isRequired,
  store: PropTypes.exact({
    filters: PropTypes.string,
    data: PropTypes.string
  })
};

export default Pagination;
