import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import AddBatch from 'components/addBatch/addBatch';
import Button from 'components/base/button/button';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import InputDate from 'components/base/inputDate/inputDate';
import ManualTagEntry from 'components/manualTagEntry/manualTagEntry';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Table from 'components/table/table';

const MissingAndFoundStep1 = ({
  missingOrFound,
  setDataChanged,
  setMissingOrFound,
  setMissingOrFoundData,
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionSpecies = storeService.session.get.species();
  const speciesId = sessionSpecies ? sessionSpecies.id : '';
  const sessionHolding = storeService.session.get.holding();
  const cph = sessionHolding ? sessionHolding.value : '';
  const isCattle = helpers.species.isCattleId(speciesId);

  const dateFoundLabel = 'deaths.dateFound' + speciesId;
  const dateLostLabel = 'deaths.dateMissing' + speciesId;
  const missingFoundLabel = 'deaths.missingReportedFound' + speciesId;

  const [animalsOnHolding, setAnimalsOnHolding] = useState([]);
  const [animalsBeingMoved, setAnimalsBeingMoved] = useState([]);
  const [selectionMethod, setSelectionMethod] = useState(null);
  const [entryDate, setEntryDate] = useState(null);
  const [entryDateError, setEntryDateError] = useState('');
  const [entryDateWarning, setEntryDateWarning] = useState('');
  const [pending, setPending] = useState(false);
  const [batchList, setBatchList] = useState([]);
  const [batchTotal, setBatchTotal] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [renderTable, setRenderTable] = useState();
  const [refreshFilters, setRefreshFilters] = useState({});
  const [paginationParams, setPaginationParams] = useState({
    batch: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        totalLabel: 'label.totalBatches'
      }
    },
    individual: constants.pagination.defaultParams
  });

  const nextPage = () => {
    history.push(Routing.missingFoundSummary);
    helpers.scrollToTop();
  };

  const validateDate = (date) => {
    let hasError = false;

    setEntryDateError('');
    setEntryDateWarning('');

    const dateIsInTheFuture = helpers.date.is.inTheFuture(date, constants.option.missingOrFound.found);
    const dateIsOverAYearAgo = helpers.date.is.overAYearAgo(date);

    if (!hasError && dateIsInTheFuture) {
      setEntryDateError(dateIsInTheFuture);

      hasError = true;
    }

    if (!hasError && dateIsOverAYearAgo) {
      setEntryDateWarning(dateIsOverAYearAgo);
    }
  };

  const handleChanges = {
    addAnimal: (animal) => {
      const newAnimal = { ...animal, animalTotal: 1 };
      const newAnimalsOnHolding = [...animalsOnHolding];
      if (animal.tagNumber !== '') {
        const indexToRemove = newAnimalsOnHolding.findIndex((item) => item.tagNumber === animal.tagNumber);
        newAnimalsOnHolding.splice(indexToRemove, 1);
        setAnimalsOnHolding(newAnimalsOnHolding);
      }
      setMissingOrFoundData((prevState) => ({
        ...prevState,
        animalsBeingMoved: [...animalsBeingMoved, newAnimal]
      }));
      setAnimalsBeingMoved([...animalsBeingMoved, newAnimal]);
      storeService.session.set.missingAndFoundAnimalsBeingMoved([...animalsBeingMoved, newAnimal]);
    },

    addAnimals: (tags) => {
      const uniqueNewTags = tags.filter((tag) => !animalsBeingMoved.find((tagItem) => tagItem.tagNumber === tag.tagNumber));
      const newAnimalsBeingMoved = [
        ...animalsBeingMoved,
        ...uniqueNewTags
      ].map((item) => ({
        ...item,
        tagNumber: item.tagNumber
      }));

      setMissingOrFoundData((prevState) => ({
        ...prevState,
        animalsBeingMoved: newAnimalsBeingMoved
      }));

      setAnimalsBeingMoved(newAnimalsBeingMoved);
      storeService.session.set.missingAndFoundAnimalsBeingMoved(newAnimalsBeingMoved);
    },

    addAnimalsAll: (rows) => {
      const newAnimalsBeingMoved = [
        ...animalsBeingMoved,
        ...rows.filter((item) => !item.added)
      ];

      setMissingOrFoundData((prevState) => ({
        ...prevState,
        animalsBeingMoved: newAnimalsBeingMoved
      }));
      setAnimalsBeingMoved(newAnimalsBeingMoved);
      storeService.session.set.missingAndFoundAnimalsBeingMoved(newAnimalsBeingMoved);
      setAnimalsOnHolding(animalsOnHolding.filter((animal) => !rows.includes(animal)));
    },

    batchList: (batch) => {
      setMissingOrFoundData((prevState) => ({
        ...prevState,
        batchList: batch
      }));
      setBatchList(batch);
      storeService.session.set.missingAndFoundBatchList(batch);
    },

    date: (date) => {
      setMissingOrFoundData((prevState) => ({
        ...prevState,
        date
      }));
      setEntryDate(date);
      storeService.session.set.missingAndFoundDate(date);
      validateDate(date);

      setRefreshFilters({
        refresh: true,
        params: {
          date
        }
      });

      if (helpers.option.reportType.isMissing(missingOrFound) && helpers.option.selectionMethod.isIndividual(selectionMethod)) {
        storeService.session.removeAll.searchResults();
        setAnimalsOnHolding([]);
        setRenderTable(true);
      }

      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    identificationType: (type) => {
      setMissingOrFoundData((prevState) => ({
        ...prevState,
        batchList: [],
        animalsBeingMoved: [],
        selectionMethod: type
      }));

      setSelectionMethod(type);
      setAnimalsBeingMoved([]);
      setAnimalsOnHolding([]);
      setBatchList([]);
      setBatchTotal(0);
      storeService.session.set.missingAndFoundIdentificationType(type);
      storeService.session.remove.missingAndFoundAnimalsBeingMoved();
      storeService.session.remove.missingAndFoundBatchList();
    },

    removeAnimal: (animal) => {
      setMissingOrFoundData((prevState) => {
        const newAnimalsBeingMoved = helpers.tag.remove(prevState.animalsBeingMoved, animal);

        return {
          ...prevState,
          animalsBeingMoved: newAnimalsBeingMoved
        };
      });

      setAnimalsBeingMoved((prevState) => {
        const newState = helpers.tag.remove(prevState, animal);

        if (newState?.length === 0) {
          storeService.session.remove.missingAndFoundAnimalsBeingMoved();
        } else {
          storeService.session.set.missingAndFoundAnimalsBeingMoved(newState);
        }

        return newState;
      });

      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    removeAnimalsAll: () => {
      setMissingOrFoundData((prevState) => ({
        ...prevState,
        animalsBeingMoved: []
      }));
      setAnimalsBeingMoved([]);
      storeService.session.remove.missingAndFoundAnimalsBeingMoved();
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    removeAnimalsAllInvalid: () => {
      const newAnimalsBeingMoved = helpers.animal.removeAllInvalid(animalsBeingMoved);
      setMissingOrFoundData((prevState) => ({
        ...prevState,
        animalsBeingMoved: newAnimalsBeingMoved
      }));
      setAnimalsBeingMoved(newAnimalsBeingMoved);
      storeService.session.set.missingAndFoundAnimalsBeingMoved(newAnimalsBeingMoved);
    },

    removeBatch: (batch) => {
      setMissingOrFoundData((prevState) => {
        const newBatchList = helpers.batch.remove(prevState.batchList, batch);

        return {
          ...prevState,
          batchList: newBatchList
        };
      });

      setBatchList((prevState) => {
        const newState = helpers.batch.remove(prevState, batch);

        if (newState?.length === 0) {
          storeService.session.remove.missingAndFoundBatchList();
        } else {
          storeService.session.set.missingAndFoundBatchList(newState);
        }

        return newState;
      });
    },

    reportType: (event) => {
      setMissingOrFound(event.target.value);
      storeService.session.set.missingAndFoundReportType(event.target.value);

      setEntryDate(null);
      setSelectionMethod(null);
      setAnimalsBeingMoved([]);
      setAnimalsOnHolding([]);
      setBatchList([]);
      setBatchTotal(0);
      setMissingOrFoundData(null);

      storeService.session.remove.missingAndFoundDate();
      storeService.session.remove.missingAndFoundIdentificationType();
      storeService.session.remove.missingAndFoundAnimalsBeingMoved();
      storeService.session.remove.missingAndFoundBatchList();

      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    toggleAnimal: (animal) => {
      const newAnimal = { ...animal, animalTotal: 1 };

      if (animal.tagNumber !== '') {
        if (animal.added) {
          setMissingOrFoundData((prevState) => {
            const newAnimalsBeingMoved = helpers.tag.remove(prevState.animalsBeingMoved, animal);

            return {
              ...prevState,
              animalsBeingMoved: newAnimalsBeingMoved
            };
          });

          setAnimalsBeingMoved((prevState) => {
            const newState = helpers.tag.remove(prevState, animal);

            if (newState?.length === 0) {
              storeService.session.remove.missingAndFoundAnimalsBeingMoved();
            } else {
              storeService.session.set.missingAndFoundAnimalsBeingMoved(newState);
            }

            return newState;
          });
        } else {
          setMissingOrFoundData((prevState) => ({
            ...prevState,
            animalsBeingMoved: prevState.animalsBeingMoved
              ? [
                ...prevState.animalsBeingMoved,
                newAnimal
              ]
              : [newAnimal]
          }));

          setAnimalsBeingMoved((prevState) => {
            storeService.session.set.missingAndFoundAnimalsBeingMoved([
              ...prevState,
              newAnimal
            ]);

            return [
              ...prevState,
              newAnimal
            ];
          });
        }

        const sessionSearchResults = storeService.session.get.searchResultsAnimalsOnHolding();
        const sessionAnimalToToggle = sessionSearchResults.data.find((item) => item.tagNumber === animal.tagNumber);
        sessionAnimalToToggle.added = !sessionAnimalToToggle.added;
        storeService.session.set.searchResultsAnimalsOnHolding(sessionSearchResults);
        storeService.session.set.dataChanged(true);
        setDataChanged(true);
      }
    }
  };

  const getDates = {
    dateMax: () => {
      const today = new Date();

      return helpers.date.formatYYYYMMDD(today);
    }
  };

  const tableParams = {
    request: {
      url: '/animals',
      params: {
        speciesId: speciesId,
        cph,
        onHoldingDate: entryDate ? helpers.date.endOfDay(entryDate) : helpers.date.endOfDay(helpers.date.now())
      },
      processData: (data) => helpers.animal.addDobAndAgeForDisplay(data)
    }
  };

  const display = {
    aboutContent: () => {
      switch (true) {
        case (helpers.option.reportType.isFound(missingOrFound)): {
          return ('about.missing.desc1-found');
        }
        case (helpers.option.reportType.isMissing(missingOrFound)): {
          return ('about.missing.desc1-missing');
        }
        default:
          return null;
      }
    },

    addBatch: () => {
      return (
        <div className="section">
          <AddBatch
            animalTotalLabel="deaths.animal-selection.batch.input-field-two"
            batchDescription="deaths.animal-selection.batch.desc"
            batchHeader="deaths.animal-selection.batch.title"
            batchList={batchList}
            batchNumberOptional={true}
            batchTotal={batchTotal}
            buttonLabel="deaths.animal-selection.batch.submit"
            pending={pending}
            setBatchTotal={setBatchTotal}
            setModal={setModal}
            setPending={setPending}
            species={sessionSpecies}
            updateBatchList={handleChanges.batchList}
          />

          {batchList?.some((batch) => helpers.batch.isInvalid(batch)) &&
            <ErrorLabel
              label="formatExamples.tagBatchError"
              modalMessage={helpers.batch.getFormatExamples(speciesId).description}
              modalTitle={helpers.batch.getFormatExamples(speciesId).title}
              setModal={setModal}
            />
          }

          {batchList.length > 0 &&
            <Table
              columns={tableColumns.deathsMissingAndFound.batch({ removeBatch: handleChanges.removeBatch, speciesId: sessionSpecies.id })}
              data={batchList}
              dataProvided={true}
              initialSortBy={initialSorting.deathsMissingAndFound.batch.accessor}
              initialSortDirection={initialSorting.deathsMissingAndFound.batch.direction}
              paginationParams={paginationParams.batch}
              setData={setBatchList}
              setModal={setModal}
              title={{
                title: helpers.text.pluralCheck(batchList?.reduce((total, item) => total + parseInt(item.animalTotal), 0), 'table.title.batchesSelected'),
                data: [
                  batchList?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
                ]
              }}
            />
          }
        </div>
      );
    },

    manualTags: () => {
      return (
        <>
          <div className="section">
            <ManualTagEntry
              addAnimals={handleChanges.addAnimals}
              animalsToChange={animalsBeingMoved}
              pending={pending}
              setModal={setModal}
              setPending={setPending}
              species={sessionSpecies}
              title="movements.animal-selection.manual-tag-entry-heading"
              type={constants.option.movement.newMovement}
            />
          </div>

          {animalsBeingMoved?.length > 0 &&
            <>
              {helpers.tag.hasNonEidTagsSheep(speciesId, animalsBeingMoved) &&
                <ErrorLabel
                  isWarning={true}
                  label="formatExamples.tagBatchNonEidError"
                  modalMessage={helpers.tag.getFormatExamples(speciesId).description}
                  modalTitle={helpers.tag.getFormatExamples(speciesId).title}
                  setModal={setModal}
                />
              }

              {animalsBeingMoved.some((tag) => helpers.tag.isInvalid(tag)) &&
                <ErrorLabel
                  label="formatExamples.tagBatchError"
                  modalMessage={helpers.tag.getFormatExamples(speciesId).description}
                  modalTitle={helpers.tag.getFormatExamples(speciesId).title}
                  setModal={setModal}
                />
              }

              <div className="section">
                <Table
                  columns={tableColumns.deathsMissingAndFound.individual({ actionType: 'remove', actionFunction: handleChanges.removeAnimal })}
                  data={animalsBeingMoved}
                  dataProvided={true}
                  paginationParams={paginationParams.individual}
                  removeAll={handleChanges.removeAnimalsAll}
                  removeAllInvalid={handleChanges.removeAnimalsAllInvalid}
                  setData={setAnimalsBeingMoved}
                  setModal={setModal}
                  title={{
                    title: 'table.title.animalsEntered',
                    data: [
                      animalsBeingMoved?.length
                    ]
                  }}
                />
              </div>
            </>
          }
        </>
      );
    },

    selectFromHolding: () => {
      return (
        <>
          <Table
            addAllAbove={handleChanges.addAnimalsAll}
            columns={tableColumns.deathsMissingAndFound.individual({ actionType: 'toggle', actionFunction: handleChanges.toggleAnimal })}
            data={animalsOnHolding.map((item1) =>
              animalsBeingMoved.find((item2) => item2.tagNumber === item1.tagNumber) ? { ...item1, added: true } : { ...item1, added: false }
            )}
            filtersComponents={{
              [constants.filters.inside]: 'AnimalsOnHolding'
            }}
            initialSortBy={initialSorting.deathsMissingAndFound.individual.accessor}
            initialSortDirection={initialSorting.deathsMissingAndFound.individual.direction}
            params={tableParams}
            refreshFilters={refreshFilters}
            renderTable={renderTable}
            setData={setAnimalsOnHolding}
            setModal={setModal}
            store={{
              filters: 'AnimalsOnHolding',
              data: 'AnimalsOnHolding'
            }}
            title="table.title.animalsOnHolding"
          />

          {animalsBeingMoved?.length > 0 &&
            <Table
              columns={tableColumns.deathsMissingAndFound.individual({ actionType: 'remove', actionFunction: handleChanges.removeAnimal })}
              data={animalsBeingMoved}
              dataProvided={true}
              paginationParams={paginationParams.individual}
              removeAll={handleChanges.removeAnimalsAll}
              setData={setAnimalsBeingMoved}
              setModal={setModal}
              title={{
                title: 'table.title.animalsSelected',
                data: [
                  animalsBeingMoved?.length
                ]
              }}
            />
          }
        </>
      );
    }
  };

  useEffect(() => {
    const status =
      !pending &&
      entryDate &&
      entryDateError === '' &&
      (
        (
          batchList?.length > 0 &&
          !batchList.some((batch) => helpers.batch.isInvalid(batch))
        ) ||
        (
          animalsBeingMoved?.length > 0 &&
          !animalsBeingMoved.some((tag) => helpers.tag.isInvalid(tag))
        )
      );
    setSubmitDisabled(!status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending, entryDate, entryDateError, batchList, animalsBeingMoved]);

  useEffect(() => {
    const sessionReportType = storeService.session.get.missingAndFoundReportType();

    if (sessionReportType) {
      const sessionEntryDate = storeService.session.get.missingAndFoundDate();
      const sessionIdentificationType = storeService.session.get.missingAndFoundIdentificationType();

      setMissingOrFound(sessionReportType);

      if (sessionEntryDate) {
        setEntryDate(sessionEntryDate);
        validateDate(sessionEntryDate);
      }

      if (sessionIdentificationType) {
        const sessionAnimalsBeingMoved = storeService.session.get.missingAndFoundAnimalsBeingMoved();
        const sessionBatchList = storeService.session.get.missingAndFoundBatchList();

        setSelectionMethod(sessionIdentificationType);

        if (sessionAnimalsBeingMoved) {
          setAnimalsBeingMoved(sessionAnimalsBeingMoved);
        }

        if (sessionBatchList) {
          setBatchList(sessionBatchList);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (renderTable) {
      setRenderTable();
    }
  }, [renderTable]);

  useEffect(() => {
    if (refreshFilters.refresh) {
      setRefreshFilters({});
    }
  }, [refreshFilters]);

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      individual: {
        ...prevState.individual,
        [constants.pagination.bottom]: {
          ...prevState.individual[constants.pagination.bottom],
          total: animalsBeingMoved?.length
        }
      }
    }));
  }, [animalsBeingMoved]);

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      batch: {
        ...prevState.batch,
        [constants.pagination.bottom]: {
          ...prevState.batch[constants.pagination.bottom],
          total: batchList?.length
        }
      }
    }));
  }, [batchList]);

  return (
    <>
      {ready &&
        <>
          <h2 className="h2">{t('label.missing&Found')}</h2>

          {missingOrFound &&
            <AboutPanel
              descriptions={[
                { text: display.aboutContent() }
              ]}
              title="about.genericTitle.step"
            />
          }

          <div className="section">
            <RadioButtons
              ids={[
                constants.option.missingOrFound.missing,
                constants.option.missingOrFound.found
              ]}
              name="missingOrFound"
              onChange={handleChanges.reportType}
              species={true}
              value={missingOrFound}
            />
          </div>

          {missingOrFound &&
            <>
              <div className="section">
                <InputDate
                  dontValidate={true}
                  error={entryDateError}
                  id="missingOrFoundDate"
                  labelPosition="above"
                  labelText={
                    helpers.option.reportType.isFound(missingOrFound)
                      ? dateFoundLabel
                      : dateLostLabel
                  }
                  maxDate={getDates.dateMax()}
                  onChange={(event) => handleChanges.date(event.target.value)}
                  setError={setEntryDateError}
                  value={entryDate}
                  warning={entryDateWarning}
                  yearLength={4}
                />
              </div>
              {helpers.option.reportType.isFound(missingOrFound) &&
                <h2 className="h2">{t(missingFoundLabel)}</h2>
              }
              <div className="section">
                <RadioButtons
                  ids={[
                    {
                      id: constants.option.selectionMethod.individual,
                      showIf: helpers.option.reportType.isMissing(missingOrFound)
                    },
                    constants.option.selectionMethod.manualTagNumbers,
                    {
                      id: constants.option.selectionMethod.batch,
                      showIf: !isCattle
                    }
                  ]}
                  name="identificationTypeMissingAndFound"
                  onChange={(event) => handleChanges.identificationType(event.target.value)}
                  value={selectionMethod}
                />
              </div>
            </>
          }

          {helpers.option.reportType.isMissing(missingOrFound) && helpers.option.selectionMethod.isIndividual(selectionMethod) && display.selectFromHolding()}
          {helpers.option.selectionMethod.isManualTagNumbers(selectionMethod) && display.manualTags()}
          {helpers.option.selectionMethod.isBatch(selectionMethod) && !isCattle && display.addBatch()}

          <div className="actions">
            <Button
              buttonType="primary"
              disabled={submitDisabled}
              label="button.continue"
              onClick={nextPage}
            />
          </div>
        </>
      }
    </>
  );
};

MissingAndFoundStep1.propTypes = {
  missingOrFound: PropTypes.string,
  setDataChanged: PropTypes.func,
  setMissingOrFound: PropTypes.func,
  setMissingOrFoundData: PropTypes.func,
  setModal: PropTypes.func.isRequired
};

export default MissingAndFoundStep1;
