import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CattleIcon } from 'images/svg/icon-cattle.svg';
import { ReactComponent as SheepIcon } from 'images/svg/icon-sheep.svg';
import { ReactComponent as GoatIcon } from 'images/svg/icon-goat.svg';
import { ReactComponent as PigIcon } from 'images/svg/icon-pig.svg';
import { ReactComponent as DeerIcon } from 'images/svg/icon-deer.svg';
import classes from 'components/header/holding/holding.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import Address from 'components/address/address';
import Loading from 'components/base/loading/loading';

const Holding = ({
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();

  const [holding, setHolding] = React.useState(storeService.session.get.holding());
  const [species, setSpecies] = React.useState(storeService.session.get.species());

  useEffect(() => {
    const interval = setInterval(() => {
      const sessionHolding = storeService.session.get.holding();
      const sessionSpecies = storeService.session.get.species();

      if (sessionHolding && sessionSpecies) {
        clearInterval(interval);
        setHolding(sessionHolding);
        setSpecies(sessionSpecies);
        setPermission(sessionHolding.role);
      }
    }, 500);
  }, [setPermission]);

  return (
    <>
      {ready &&
        <div className="container">
          <div aria-label={t('label.selectedHolding')} className={classes.selectedHolding} role="region">
            {(!holding || !species) &&
              <div className={classes.loading}><Loading /></div>
            }

            {holding && species &&
              <>
                <div className={classes.icon}>
                  {helpers.species.isCattleId(species.id) &&
                    <CattleIcon className={classes.icon} />
                  }
                  {helpers.species.isSheepId(species.id) &&
                    <SheepIcon className={classes.icon} />
                  }
                  {helpers.species.isGoatId(species.id) &&
                    <GoatIcon className={classes.icon} />
                  }
                  {helpers.species.isPigId(species.id) &&
                    <PigIcon className={classes.icon} />
                  }
                  {helpers.species.isDeerId(species.id) &&
                    <DeerIcon className={classes.icon} />
                  }
                </div>

                <div className={classes.cph}>
                  <span className={classes.title}>{t('label.holdingId')}</span>
                  <span>{holding.value}</span>
                </div>
                <div className={classes.address}>
                  <span className={classes.title}>{t('label.holdingPanel')}</span>
                  <Address
                    cph={holding.value}
                    format={{
                      address: constants.address.format.line
                    }}
                    queryKeeper={true}
                    setModal={setModal}
                  />
                </div>

                <div className={classes.species}>
                  <span className={classes.title}>{t('label.species')}</span>
                  <span>{species.name}</span>
                </div>
              </>
            }
          </div>
        </div>
      }
    </>
  );
};

Holding.propTypes = {
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func
};

export default Holding;
