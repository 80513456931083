import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/sections/paperAnimalBatch/paperAnimalBatch.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { tableColumns } from 'services/tableColumns';
import AddBatch from 'backOfficeComponents/addBatch/addBatch';
import Table from 'components/table/table';

const PaperAnimalBatch = ({
  batchList,
  setBatchList,
  setBatchTotal,
  setData,
  setDataChanged,
  setModal,
  speciesId
}) => {
  const { ready, t } = useTranslation();

  const [paginationParams, setPaginationParams] = useState(constants.pagination.defaultParams);

  const handleChanges = {
    removeAllBatches: () => {
      setBatchList([]);
      if (setBatchTotal && typeof setBatchTotal === 'function') {
        setBatchTotal(0);
      }
      storeService.session.remove.movementBatchList();
      setDataChanged();
    },

    removeAllInvalid: () => {
      setBatchList((prevState) => {
        const newBatchList = helpers.animal.removeAllInvalid(prevState);

        if (setBatchTotal && typeof setBatchTotal === 'function') {
          setBatchTotal(newBatchList.reduce((total, item) => total + parseInt(item.animalTotal), 0));
        }
        storeService.session.set.movementBatchList(newBatchList);

        return newBatchList;
      });
      setDataChanged();
    },

    removeBatch: (batch) => {
      setBatchList((prevState) => {
        const newBatchList = helpers.batch.remove(prevState, batch);

        if (setBatchTotal && typeof setBatchTotal === 'function') {
          setBatchTotal(newBatchList.reduce((total, item) => total + parseInt(item.animalTotal), 0));
        }
        storeService.session.set.movementBatchList(newBatchList);

        return newBatchList;
      });
      setDataChanged();
    }
  };

  const extraContent = batchList?.length > 0 ? '(' + batchList.reduce((total, item) => total + parseInt(item.animalTotal), 0) + ')' : '';

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      [constants.pagination.bottom]: {
        ...prevState[constants.pagination.bottom],
        total: batchList?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
      }
    }));
  }, [batchList]);

  return (
    <>
      {ready &&
        <details className="accordion">
          <summary>
            <h2><i className="bi bi-collection" /> {t('movementDetails.animalsMoved.heading')} {extraContent}</h2>
          </summary>
          <div>
            <AddBatch
              batchHeader="movements.animal-selection.batch.title"
              batchList={batchList}
              buttonLabel="movements.animal-selection.batch.submit"
              id="addBatch"
              setBatchList={setBatchList}
              setBatchTotal={setBatchTotal}
              setDataChanged={setDataChanged}
              speciesId={speciesId}
            />

            {batchList?.length > 0 &&
              <>
                <Table
                  columns={tableColumns.paperMovements.batch({ removeRow: handleChanges.removeBatch, speciesId })}
                  data={batchList}
                  dataProvided={true}
                  paginationParams={paginationParams}
                  removeAllAnimals={handleChanges.removeAllBatches}
                  removeAllInvalid={handleChanges.removeAllInvalid}
                  setData={setData}
                  setModal={setModal}
                />

                <div className={classes.itemsTotal}>
                  <span className={classes.label}> <i className="bi bi-list-check" /> {t('boApp.label.totalAnimals')} {String(batchList.reduce((total, item) => total + parseInt(item.animalTotal), 0))} </span>
                </div>
              </>
            }
          </div>
        </details>
      }
    </>
  );
};

PaperAnimalBatch.propTypes = {
  batchList: PropTypes.array,
  setBatchList: PropTypes.func.isRequired,
  setBatchTotal: PropTypes.func,
  setData: PropTypes.func.isRequired,
  setDataChanged: PropTypes.func.isRequired,
  setModal: PropTypes.func,
  speciesId: PropTypes.number
};

export default PaperAnimalBatch;
