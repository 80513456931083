import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/button/button.module.scss';

const Button = ({
  ariaLabel,
  autoFocus,
  buttonType,
  classNames,
  disabled,
  extraContent,
  icon,
  id,
  label,
  onClick,
  tabIndex,
  type
}) => {
  const { ready, t } = useTranslation();

  const componentClassNames = [
    buttonType ? classes[buttonType] : classes.primary,
    ...(classNames ? classNames.map((className) => classes[className]) : [])
  ].join(' ').trim();

  return (
    <>
      {ready &&
        <button
          aria-label={t(ariaLabel)}
          autoFocus={autoFocus}
          className={componentClassNames}
          disabled={disabled}
          id={id}
          onClick={onClick ? (event) => onClick(event, id) : null}
          tabIndex={tabIndex}
          type={type ? type : 'button'}
        >
          {buttonType === 'calendar' && icon &&
            <span> {icon}</span>
          }

          {buttonType === 'iconLink' && icon &&
            <i className={icon + ' ' + classes.icon} />
          }

          {buttonType !== 'calendar' && buttonType !== 'iconLink' && icon &&
            <>
              {icon}
            </>
          }

          {label &&
            <span className="buttonLabel">
              {t(label)}
              {extraContent &&
                <span className={classes.extraContent}>{extraContent}</span>
              }
            </span>
          }
        </button>
      }
    </>
  );
};

Button.propTypes = {
  ariaLabel: PropTypes.string,
  autoFocus: PropTypes.bool,
  buttonType: PropTypes.oneOf([
    'add',
    'addAll',
    'calendar',
    'clear',
    'danger',
    'downloadReport',
    'iconLink',
    'link',
    'linkDanger',
    'modalClose',
    'primary',
    'remove',
    'removeAll',
    'secondary',
    'subscribe',
    'tab',
    'tertiary',
    'upload'
  ]),
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  disabled: PropTypes.bool,
  extraContent: PropTypes.string,
  icon: PropTypes.any,
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.string,
  type: PropTypes.string
};

export default Button;
