import React from 'react';
import PropTypes from 'prop-types';
import constants from 'services/constants';
import classes from 'backOfficeComponents/sections/verifyCustomerSearch/verifyCustomerSearch.module.scss';
import Button from 'backOfficeComponents/base/button/button';
import CPHField from 'components/base/cphField/cphField';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import MessageBanner from 'backOfficeComponents/base/messageBanner/messageBanner';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';

const VerifyCustomerSearch = ({
  data,
  enabledSearch,
  error,
  onClear,
  onSearch,
  pending,
  setData
}) => {
  return (
    <div className="pageContent">
      <SectionTitleBar
        icon="bi bi-search"
        id="verifyCustomerTitleBar"
        title="boApp.label.searchCustomer"
      />
      {error &&
        <MessageBanner
          id="VerifyCustomerSearch"
          messages={error}
        />
      }
      <div className={classes.verifyCustomerSearch}>
        <div className={classes.holdingDetails}>
          <CPHField
            classNames={['bo', 'small']}
            cph={data.holdingCPH}
            id="holdingCPH"
            isFormGroup={true}
            label="boApp.label.holdingCph"
            readOnly={pending}
            setCph={(value) => setData({
              target: {
                name: 'holdingCPH',
                type: 'string',
                value
              }
            })}
          />

          <FieldEntry
            disabled={pending}
            id="holdingName"
            inputType={constants.inputType.text}
            labelPosition={constants.labelPosition.above}
            labelText="boApp.label.holdingName"
            name="holdingName"
            onChange={setData}
            value={data.holdingName}
          />

          <FieldEntry
            disabled={pending}
            id="holdingAddress"
            inputType={constants.inputType.text}
            labelPosition={constants.labelPosition.above}
            labelText="boApp.label.holdingAddress"
            name="holdingAddress"
            onChange={setData}
            value={data.holdingAddress}
          />

          <FieldEntry
            disabled={pending}
            id="holdingPostcode"
            inputType={constants.inputType.text}
            labelPosition={constants.labelPosition.above}
            labelText="boApp.label.holdingPostcode"
            name="holdingPostcode"
            onChange={setData}
            value={data.holdingPostcode}
            width="half"
          />

          <FieldEntry
            disabled={pending}
            id="holdingFlock"
            inputType={constants.inputType.text}
            labelPosition={constants.labelPosition.above}
            labelText="boApp.label.holdingFlock"
            name="holdingFlock"
            onChange={setData}
            value={data.holdingFlock}
          />
        </div>
        <div className={classes.keeperDetails}>
          <FieldEntry
            disabled={pending}
            id="keeperName"
            inputType={constants.inputType.text}
            labelPosition={constants.labelPosition.above}
            labelText="boApp.label.keeperSurname"
            name="keeperName"
            onChange={setData}
            value={data.keeperName}
          />

          <FieldEntry
            disabled={pending}
            id="keeperTelephone"
            inputType={constants.inputType.text}
            labelPosition={constants.labelPosition.above}
            labelText="boApp.label.keeperTelephone"
            name="keeperTelephone"
            onChange={setData}
            value={data.keeperTelephone}
          />

          <FieldEntry
            disabled={pending}
            id="keeperEmail"
            inputType={constants.inputType.text}
            labelPosition={constants.labelPosition.above}
            labelText="boApp.label.keeperEmail"
            name="keeperEmail"
            onChange={setData}
            value={data.keeperEmail}
          />
        </div>
      </div>

      <div className="actions">
        <div className="left">
          <Button
            buttonType="primary"
            disabled={!enabledSearch || pending}
            id="search"
            label="Search"
            onClick={onSearch}
          />
        </div>
        <div className="right">
          <Button
            buttonType="tertiary"
            disabled={pending}
            id="clear"
            label="Clear"
            onClick={onClear}
          />
        </div>
      </div>
    </div>
  );
};

VerifyCustomerSearch.propTypes = {
  data: PropTypes.object,
  error: PropTypes.array,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
  setData: PropTypes.func
};

export default VerifyCustomerSearch;
