import React, { useEffect, createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router-dom';
import { Prompt } from 'react-router';
import bff, { bffResponse } from 'services/bff';
import breedsHelper from 'services/breedsHelper';
import classes from 'frontEnd/tagging/application/tagApplication.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import recentlyUsedHelper from 'services/recentlyUsedHelper';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import { tableColumns, initialSorting } from 'services/tableColumns';
import { bffStatus } from 'services/bff';
import permissions from 'services/permissions';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import AddBatch from 'components/addBatch/addBatch';
import AddTagRange from 'components/addTagRange/addTagRange';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import DropdownBatchNumbersAvailable from 'components/dropdownBatchNumbersAvailable/dropdownBatchNumbersAvailable';
import DropdownBreeds from 'components/dropdownBreeds/dropdownBreeds';
import DropdownDamIds from 'components/dropdownDamIds/dropdownDamIds';
import DropdownMonth from 'components/dropdownMonth/dropdownMonth';
import DropdownSireIds from 'components/dropdownSireIds/dropdownSireIds';
import DropdownYear from 'components/dropdownYear/dropdownYear';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FileUpload from 'components/base/fileUpload/fileUpload';
import GeneticDamTab from 'components/geneticDamTab/geneticDamTab';
import InputDate from 'components/base/inputDate/inputDate';
import ManualTagEntry from 'components/manualTagEntry/manualTagEntry';
import NumberEntry from 'components/base/numberEntry/numberEntry';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Table from 'components/table/table';
import TabsNavigational from 'components/base/tabsNavigational/tabsNavigational';
import TextEntry from 'components/base/textEntry/textEntry';
const sprintf = require('util').format;

const getDates = {
  applicationMax: () => helpers.date.formatYYYYMMDD(new Date()),

  birthMax: (value) => value
    ? helpers.date.formatYYYYMMDD(
      helpers.date.momentToJSDate(
        helpers.date.momentMinDate(
          helpers.date.stringDateToMoment(value),
          helpers.date.todayEndOfDay()
        )
      )
    )
    : helpers.date.formatYYYYMMDD(new Date())
};

const TagApplication = ({
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionDataChanged = storeService.session.get.dataChanged();
  const species = storeService.session.get.species();
  const speciesId = species.id;
  const isSheepOrGoat = helpers.species.isSheepId(speciesId) || helpers.species.isGoatId(speciesId);
  const batchHeaderLabel = 'tagging.batchTitle' + speciesId;

  const desc1Label = 'about.tagApplications.desc1-' + speciesId;
  const batchNumberLabel = 'label.batchNumber' + speciesId;

  const [tagType, setTagType] = React.useState(null);
  const [applicationDate, setApplicationDate] = React.useState(null);
  const [animalsToChange, setAnimalsToChange] = React.useState([]);
  const [selectionMethod, setSelectionMethod] = React.useState(null);
  const [warnings, setWarnings] = React.useState({});
  const [yearOfBirth, setYearOfBirth] = React.useState(null);
  const [applicationDateError, setApplicationDateError] = React.useState('');
  const [birthDate, setBirthDate] = React.useState(null);
  const [birthDateError, setBirthDateError] = React.useState('');
  const [birthDateWarning, setBirthDateWarning] = React.useState('');
  const [dataChanged, setDataChanged] = React.useState(sessionDataChanged ? sessionDataChanged : false);
  const [breedId, setBreedId] = React.useState('');
  const [gender, setGender] = React.useState(null);
  const [monthOfBirth, setMonthOfBirth] = React.useState(null);
  const [genotype, setGenotype] = React.useState('');
  const [animalsOnHolding, setAnimalsOnHolding] = React.useState([]);
  const [pending, setPending] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = React.useState(false);
  const [birthDamId, setBirthDamId] = React.useState('');
  const [sireId, setSireId] = React.useState('');
  const [embryo, setEmbryo] = React.useState(null);
  const [geneticDamId, setGeneticDamId] = React.useState('');
  const [geneticDamAI, setGeneticDamAI] = React.useState('');
  const [geneticDamIdError, setGeneticDamIdError] = React.useState('');
  const [batchNumber, setBatchNumber] = useState('');
  const [tagNumber, setTagNumber] = useState('');
  const [activeTabGeneticDam, setActiveTabGeneticDam] = useState('');
  const [comment, setComment] = React.useState('');
  const [submitPending, setSubmitPending] = React.useState(false);

  const paginationParams = constants.pagination.defaultParams;
  const role = storeService.session.get.permissions();
  const holding = storeService.session.get.holding();

  paginationParams[constants.pagination.bottom].total = animalsToChange?.length;

  const geneticDamTabs = createRef();

  const confirmation = {
    viewSummary: (requestId, requestType) => ({
      id: 'viewSummary',
      label: 'button.viewSummary',
      onClick: () => {
        setPermission(userRoleType.GENERIC);
        storeService.session.set.permissions(userRoleType.GENERIC);
        storeService.session.remove.holding();

        setTimeout(() => {
          history.push({
            pathname: Routing.activityDetails + requestId,
            state: {
              data: {
                requestId,
                requestType,
                current: {
                  returnUrl: permissions.isKeeper() ? Routing.taggingApplications : Routing.userActivity,
                  permissions: role,
                  holding
                }
              }
            }
          });
        }, [100]);
      }
    }),

    viewUserActivity: () => {
      setPermission(userRoleType.GENERIC);
      storeService.session.set.permissions(userRoleType.GENERIC);
      storeService.session.remove.holding();
      history.push(Routing.userActivity);
    }
  };

  const checkWarning = (oldValue, newValue, label, fieldName) => {
    const oldAnimalsToChange = storeService.session.get.tagApplicationAnimalsToChange();
    if (oldAnimalsToChange?.length > 0 && oldValue !== newValue) {
      setWarnings((prev) => ({ ...prev, [fieldName]: sprintf(t('tagging.warning'), label, label) }));
    }
  };

  const handleChanges = {
    addAnimals: (animals) => {
      const newAnimalsToChange = [
        ...animalsToChange,
        ...animals.filter((newAnimal) => !animalsToChange.some((existingAnimal) => existingAnimal.tagNumber === newAnimal.tagNumber)).map((animal) => ({
          ...animal,
          added: true
        }))
      ];

      setAnimalsToChange(newAnimalsToChange);
      storeService.session.set.tagApplicationAnimalsToChange(newAnimalsToChange);
    },

    batchList: (batches) => {
      setAnimalsToChange(batches);
      storeService.session.set.tagApplicationAnimalsToChange(batches);
    },

    batchNumber: (batch) => {
      setBatchNumber(batch);
      storeService.session.set.tagApplicationBatchNumber(batch);
    },

    birthDamId: (event) => {
      const newBirthDamId = event.target.value;
      setBirthDamId(newBirthDamId);
      storeService.session.set.tagApplicationBirthDamId(newBirthDamId);
      if (!isSheepOrGoat) {
        recentlyUsedHelper.setRecentlyUsedList('BirthDamId', newBirthDamId);
      }
    },

    breed: (event) => {
      const breedId = event.target.value;
      const oldBreed = storeService.session.get.tagApplicationBreed();
      const oldBreedId = oldBreed ? oldBreed.id : null;
      checkWarning(breedId, oldBreedId, 'Breed', 'breed');
      setBreedId(breedId);
      const breedName = breedsHelper.getBreedName(breedId);
      const breedObject = { value: breedId, label: breedName, name: breedName };
      recentlyUsedHelper.setRecentlyUsedBreedList(breedObject, speciesId);
      storeService.session.set.tagApplicationBreed({
        id: breedId,
        name: breedsHelper.getBreedName(breedId)
      });
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    comment: (comment) => {
      if (comment) {
        const oldComment = storeService.session.get.tagApplicationComment();
        checkWarning(comment, oldComment, 'Comment', 'comment');
        storeService.session.set.tagApplicationComment(comment);
      } else {
        storeService.session.remove.tagApplicationComment();
      }

      setComment(comment);
      setDataChanged(true);
      storeService.session.set.dataChanged(true);
    },

    date: (date, whichDate) => {
      if (helpers.dateType.isTagApplication(whichDate)) {
        const oldDate = storeService.session.get.tagApplicationDate();
        checkWarning(date, oldDate, 'ApplicationDate', 'date');
        setApplicationDate(date);
        storeService.session.set.tagApplicationDate(date);
        validateDate(date, whichDate);
        storeService.session.set.dataChanged(true);
        setDataChanged(true);
      } else if (helpers.dateType.isBirth(whichDate)) {
        const oldDate = storeService.session.get.tagApplicationBirthDate();
        checkWarning(date, oldDate, 'BirthDate', 'date');
        setBirthDate(date);
        storeService.session.set.tagApplicationBirthDate(date);
        validateDate(date, whichDate);
        storeService.session.set.dataChanged(true);
        setDataChanged(true);
      }
    },

    embryo: (event) => {
      setEmbryo(event.target.value);
      storeService.session.set.tagApplicationEmbryo(event.target.value);

      if (!helpers.option.embryo.isTransfer(event.target.value)) {
        storeService.session.remove.tagApplicationGeneticDamAI();
        storeService.session.remove.tagApplicationGeneticDamId();
      }
    },

    gender: (event) => {
      const newGender = event.target.value;
      const oldGender = storeService.session.get.tagApplicationGender();
      checkWarning(newGender, oldGender, 'Gender', 'gender');
      setGender(newGender);
      storeService.session.set.tagApplicationGender(newGender);
    },

    geneticDamAI: (event) => {
      setGeneticDamAI(event);
      storeService.session.set.tagApplicationGeneticDamAI(event);
      storeService.session.remove.tagApplicationGeneticDamId();
    },

    geneticDamId: (event) => {
      if (isSheepOrGoat) {
        setGeneticDamId(event.target.value);
        storeService.session.set.tagApplicationGeneticDamId(event.target.value);
      } else {
        setGeneticDamId(event);
        storeService.session.set.tagApplicationGeneticDamId(event);
        storeService.session.remove.tagApplicationGeneticDamAI();
      }
    },

    genotype: (event) => {
      setGenotype(event.target.value);
      storeService.session.set.tagApplicationGenotype(event.target.value);
    },

    monthOfBirth: (event) => {
      setMonthOfBirth(event.target.value);
      storeService.session.set.tagApplicationMonthOfBirth(event.target.value);
    },

    padTagNumber: (batch, tag) => {
      const paddedTagNumber = helpers.tag.zerosPadLeft(speciesId, batch, tag);

      setTagNumber(paddedTagNumber);
      storeService.session.set.tagApplicationTagNumber(paddedTagNumber);
    },

    removeAnimal: (animal) => {
      setAnimalsToChange((prevState) => {
        const newAnimalsToChange = helpers.tag.remove([...prevState], animal);

        if (newAnimalsToChange.length === 0) {
          storeService.session.remove.tagApplicationAnimalsToChange();
          setWarnings({});
        } else {
          storeService.session.set.tagApplicationAnimalsToChange(newAnimalsToChange);
        }

        return newAnimalsToChange;
      });

      if (animal.location === 'On holding') {
        setAnimalsOnHolding([
          ...animalsOnHolding,
          animal
        ]);
      }

      setTimeout(() => {
        document.activeElement.blur();
      }, 1);
    },

    removeAnimalsAll: () => {
      const animalsToMoveBack = animalsToChange.filter((animal) => animal.location === 'On holding');
      setAnimalsOnHolding([...animalsOnHolding, ...animalsToMoveBack]);
      setAnimalsToChange([]);
      setWarnings({});
      storeService.session.remove.tagApplicationAnimalsToChange();
    },

    removeAnimalsAllInvalid: () => {
      const animalsToChangeCopy = helpers.animal.removeAllInvalid(animalsToChange);
      if (animalsToChangeCopy?.length === 0) {
        setWarnings({});
      }
      setAnimalsToChange(animalsToChangeCopy);
      storeService.session.set.tagApplicationAnimalsToChange(animalsToChangeCopy);
    },

    removeBatch: (batch) => {
      setAnimalsToChange((prevState) => {
        const newAnimalsToChange = helpers.batch.remove([...prevState], batch);

        if (newAnimalsToChange.length === 0) {
          storeService.session.remove.tagApplicationAnimalsToChange();
        } else {
          storeService.session.set.tagApplicationAnimalsToChange(newAnimalsToChange);
        }

        if (newAnimalsToChange?.length === 0) {
          setWarnings({});
        }
        return newAnimalsToChange;
      });

      setTimeout(() => {
        document.activeElement.blur();
      }, 1);
    },

    selectionMethod: (event) => {
      setSelectionMethod(event.target.value);
      storeService.session.set.tagApplicationSelectionMethod(event.target.value);

      setAnimalsToChange([]);
      storeService.session.remove.tagApplicationAnimalsToChange();
    },

    sireId: (event) => {
      setSireId(event.target.value);
      storeService.session.set.tagApplicationSireId(event.target.value);
      if (!isSheepOrGoat) {
        recentlyUsedHelper.setRecentlyUsedList('SireId', event.target.value);
      }
    },

    tagNumber: (tag) => {
      setTagNumber(tag);
      storeService.session.set.tagApplicationTagNumber(tag);
    },

    tagType: (event) => {
      const newTagType = event.target.value;
      setTagType(newTagType);
      storeService.session.set.tagApplicationTagType(newTagType);

      if (helpers.option.tagType.isBreeding(newTagType) || helpers.option.tagType.isSlaughter(newTagType)) {
        setBirthDate(null);
        storeService.session.remove.tagApplicationBirthDate();

        setGenotype('');
        storeService.session.remove.tagApplicationGenotype();

        setEmbryo(null);
        storeService.session.remove.tagApplicationEmbryo();

        setBirthDamId('');
        storeService.session.remove.tagApplicationBirthDamId();

        setGeneticDamAI('');
        storeService.session.remove.tagApplicationGeneticDamAI();

        setGeneticDamId('');
        storeService.session.remove.tagApplicationGeneticDamId();

        setBirthDamId('');
        storeService.session.remove.tagApplicationBirthDamId();

        setSireId('');
        storeService.session.remove.tagApplicationSireId();
      } else if (helpers.option.tagType.isIndividual(newTagType)) {
        setYearOfBirth(null);
        storeService.session.remove.tagApplicationYearOfBirth();

        setMonthOfBirth(null);
        storeService.session.remove.tagApplicationMonthOfBirth();

        setSelectionMethod(null);
        storeService.session.remove.tagApplicationSelectionMethod();

        setAnimalsToChange([]);

        storeService.session.remove.tagApplicationAnimalsToChange();
      }

      if (helpers.option.tagType.isBreeding(newTagType)) {
        setAnimalsToChange([]);
      } else if (helpers.option.tagType.isSlaughter(newTagType)) {
        setAnimalsToChange([]);
      } else if (helpers.option.tagType.isIndividual(newTagType)) {
        setAnimalsToChange('');
      }

      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    upload: (uploadedTags) => {
      const newAnimalsToChange = [
        ...animalsToChange,
        ...uploadedTags.filter((el) => !animalsToChange.some((animal) => animal.tagNumber === el.tagNumber))
      ];

      setAnimalsToChange(newAnimalsToChange);
      storeService.session.set.tagApplicationAnimalsToChange(newAnimalsToChange);
    },

    yearOfBirth: (event) => {
      const newYearOfBirth = event.target.value;
      const oldYearOfBirth = storeService.session.get.tagApplicationYearOfBirth();
      checkWarning(newYearOfBirth, oldYearOfBirth, 'YearOfBirth', 'dob');
      setYearOfBirth(newYearOfBirth);
      storeService.session.set.tagApplicationYearOfBirth(newYearOfBirth);
    }
  };

  const defaults = () => {
    setBreedId('');
    setComment('');
    setGender(null);
    setYearOfBirth(null);
    setMonthOfBirth(null);
    setTagType(null);
    setApplicationDate(null);
    setApplicationDateError('');
    setBirthDate(null);
    setBirthDateError('');
    setBirthDateWarning('');
    setBirthDamId('');
    setSireId('');
    setGeneticDamIdError('');
    setGeneticDamAI('');
    setGeneticDamId('');
    setTagNumber('');
  };

  const validateDate = (date, whichDate) => {
    let hasError = false;

    setApplicationDateError('');
    setBirthDateError('');
    setBirthDateWarning('');

    if (helpers.dateType.isTagApplication(whichDate)) {
      const applicationDateIsInTheFuture = helpers.date.is.inTheFuture(date, whichDate);
      const birthDateIsAfterApplicationDate = helpers.date.is.afterDate(birthDate, date);

      if (!hasError && applicationDateIsInTheFuture) {
        setApplicationDateError(applicationDateIsInTheFuture);

        hasError = true;
      }

      if (!hasError && birthDateIsAfterApplicationDate) {
        setBirthDateError('error.applicationDateBeforeDateOfBirth');
      }
    } else if (helpers.dateType.isBirth(whichDate)) {
      const birthDateIsInTheFuture = helpers.date.is.inTheFuture(date, whichDate);
      const birthDateIsAfterApplicationDate = helpers.date.is.afterDate(date, applicationDate);

      if (!hasError && birthDateIsInTheFuture) {
        setBirthDateError(birthDateIsInTheFuture);

        hasError = true;
      }

      if (!hasError && birthDateIsAfterApplicationDate) {
        setBirthDateError('error.applicationDateBeforeDateOfBirth');

        hasError = true;
      }

      if (!hasError && !isSheepOrGoat) {
        setBirthDateWarning(helpers.date.is.overSevenDaysAgo(date));
      }
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setSubmitDisabled(true);
      setSubmitPending(true);

      const breedName = breedsHelper.getBreedName(breedId);

      let animalsToSubmit;

      if (helpers.option.tagType.isBreeding(tagType)) {
        animalsToSubmit = animalsToChange.map((animal) => ({ tagNumber: animal.tagNumber }));
      } else if (helpers.option.tagType.isIndividual(tagType)) {
        animalsToSubmit = batchNumber + ' ' + tagNumber;
      } else {
        animalsToSubmit = animalsToChange;
      }

      let data = {
        poll: config.POLLS_ENABLED,
        cph: storeService.session.get.holding().value,
        comment,
        speciesName: storeService.session.get.species().name,
        entryDate: applicationDate,
        dob: birthDate || yearOfBirth,
        breedName: breedName === constants.species.internalLabel.UNKNOWN ? null : breedName,
        gender,
        animalsToChange: animalsToSubmit
      };

      if (helpers.option.tagType.isIndividual(tagType)) {
        data = {
          ...data,
          type: tagType,
          geneticDamId,
          geneticDamAI,
          damId: birthDamId,
          sireId,
          genotype
        };
      } else {
        data = {
          ...data,
          type: 'manualTagNumbers'
        };
      }

      bff
        .post('/tagApplication', data)
        .then((res) => {
          const { data } = res;

          storeService.session.set.confirmRequestId(bffResponse.requestId(data));
          storeService.session.set.confirmPollingStatus(bffResponse.status(data));
          storeService.session.set.confirmPollingErrors(bffResponse.errors(data));
          storeService.session.set.confirmPollingWarnings(bffResponse.warnings(data));
          storeService.session.set.confirmRequestType(tagType);

          storeService.session.remove.dataChanged();

          history.push(Routing.taggingApplicationsConfirm);

          setDataChanged(false);
          setSubmitPending(false);

          helpers.scrollToTop();
          defaults();
        })
        .catch((error) => {
          errors.BFF(error, setModal);
          setSubmitDisabled(false);
          setSubmitPending(false);
        });
    }
  };

  const validateForm = () => {
    let errorCount = 0;

    if (embryo === constants.option.embryo.yes) {
      const validGeneticDamId = geneticDamId || geneticDamAI;
      setGeneticDamIdError(validGeneticDamId ? '' : 'error.selection');
      errorCount += (validGeneticDamId) ? 0 : 1;
    } else {
      setGeneticDamIdError('');
    }

    return errorCount === 0;
  };

  useEffect(() => {
    let status = false;
    const enterDatesOK = !pending && applicationDate !== null && applicationDateError === '' && birthDateError === '';

    if ((helpers.option.tagType.isBreeding(tagType) || helpers.option.tagType.isSlaughter(tagType)) && !isSheepOrGoat) {
      status = enterDatesOK && animalsToChange?.length > 0;
    } else if ((helpers.option.tagType.isBreeding(tagType) || helpers.option.tagType.isSlaughter(tagType)) && isSheepOrGoat) {
      status = enterDatesOK && animalsToChange?.length > 0 && !animalsToChange.some((item) => helpers.tag.isInvalid(item));
    } else if (helpers.option.tagType.isIndividual(tagType)) {
      status = enterDatesOK && birthDate !== null && batchNumber && tagNumber;
    }

    setSubmitDisabled(!status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending, tagType, applicationDate, applicationDateError, yearOfBirth, monthOfBirth, birthDate, birthDateError, animalsToChange, batchNumber, tagNumber, setSubmitDisabled]);

  useEffect(() => {
    const sessionApplicationDate = storeService.session.get.tagApplicationDate();
    const sessionBirthDate = storeService.session.get.tagApplicationBirthDate();
    const sessionTagType = storeService.session.get.tagApplicationTagType();
    const sessionBreed = storeService.session.get.tagApplicationBreed();
    const sessionGender = storeService.session.get.tagApplicationGender();
    const sessionComment = storeService.session.get.tagApplicationComment();

    if (sessionApplicationDate) {
      setApplicationDate(sessionApplicationDate);
      validateDate(sessionApplicationDate, constants.dateTypes.tagApplication);
    }

    if (sessionBirthDate) {
      setBirthDate(sessionBirthDate);
      validateDate(sessionBirthDate, constants.dateTypes.birth);
    }

    if (sessionTagType) {
      setTagType(sessionTagType);

      const sessionAnimalsToChange = storeService.session.get.tagApplicationAnimalsToChange();

      if (sessionAnimalsToChange) {
        setAnimalsToChange(sessionAnimalsToChange);
      } else if (helpers.option.tagType.isBreeding(sessionTagType)) {
        setAnimalsToChange([]);
      } else if (helpers.option.tagType.isSlaughter(sessionTagType)) {
        setAnimalsToChange([]);
      } else if (helpers.option.tagType.isIndividual(sessionTagType)) {
        setAnimalsToChange('');
      }

      if (helpers.option.tagType.isBreeding(sessionTagType) || helpers.option.tagType.isSlaughter(sessionTagType)) {
        const sessionYearOfBirth = storeService.session.get.tagApplicationYearOfBirth();
        const sessionMonthOfBirth = storeService.session.get.tagApplicationMonthOfBirth();
        const sessionSelectionMethod = storeService.session.get.tagApplicationSelectionMethod();

        setYearOfBirth(sessionYearOfBirth);
        setMonthOfBirth(sessionMonthOfBirth);
        setSelectionMethod(sessionSelectionMethod);
      } else if (helpers.option.tagType.isIndividual(sessionTagType)) {
        const sessionBatchNumber = storeService.session.get.tagApplicationBatchNumber();
        const sessionTagNumber = storeService.session.get.tagApplicationTagNumber();
        const sessionGenotype = storeService.session.get.tagApplicationGenotype();
        const sessionEmbryo = storeService.session.get.tagApplicationEmbryo();
        const sessionBirthDamId = storeService.session.get.tagApplicationBirthDamId();
        const sessionSireId = storeService.session.get.tagApplicationSireId();
        const sessionGeneticDamId = storeService.session.get.tagApplicationGeneticDamId();

        if (sessionBatchNumber) {
          setBatchNumber(sessionBatchNumber);
        }

        if (sessionTagNumber) {
          setTagNumber(sessionTagNumber);
        }

        if (sessionGenotype) {
          setGenotype(sessionGenotype);
        }

        if (sessionEmbryo) {
          setEmbryo(sessionEmbryo);
        }

        if (sessionBirthDamId) {
          setBirthDamId(sessionBirthDamId);
        }

        if (sessionSireId) {
          setSireId(sessionSireId);
        }

        if (sessionGeneticDamId) {
          setGeneticDamId(sessionGeneticDamId);
        }

        if (helpers.option.embryo.isTransfer(sessionEmbryo)) {
          const sessionGeneticDamAI = storeService.session.get.tagApplicationGeneticDamAI();
          const sessionGeneticDamId = storeService.session.get.tagApplicationGeneticDamId();

          if (sessionGeneticDamAI) {
            setGeneticDamAI(sessionGeneticDamAI);
          } else if (sessionGeneticDamId) {
            setGeneticDamId(sessionGeneticDamId);
          }
        }
      }
    }

    if (sessionBreed) {
      setBreedId(sessionBreed.id ? sessionBreed.id : null);
    }

    if (sessionGender) {
      setGender(sessionGender);
    }

    if (sessionComment) {
      setComment(sessionComment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.taggingApplications.length) !== Routing.taggingApplications) {
        storeService.session.remove.dataChanged();
        storeService.session.removeAll.confirm();
        storeService.session.removeAll.searchResults();
        storeService.session.removeAll.tagApplication();
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(Routing.taggingApplications);
      }
    };
  }, [history]);

  const stepPicker = (pathname) => {
    switch (pathname) {
      case Routing.taggingApplications:
      default: {
        storeService.session.removeAll.confirm();

        return (
          <TabsNavigational>
            <div active id={constants.tabs.tagApplications} label="tagging.tab1">
              <h2 className="h2">{t('tagging.application.title')}</h2>
              {submitPending &&
                <Confirmation
                  label="label.submissionPending"
                  setModal={setModal}
                  status={constants.status.pending}
                />
              }

              {!submitPending &&
                <>
                  <AboutPanel
                    descriptions={[
                      { text: desc1Label },
                      { text: 'about.tagApplications.desc2' },
                      { text: 'about.tagApplications.desc3' },
                      { text: 'about.tagApplications.desc4' },
                      { text: 'about.tagApplications.desc5' },
                      { text: 'about.tagApplications.desc6', href: constants.url.defraGuidance }
                    ]}
                    title="about.tagApplications.title"
                  />

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="section">
                        <InputDate
                          dontValidate={true}
                          error={applicationDateError}
                          id="applicationDate"
                          labelPosition="above"
                          labelText="label.dateApplication"
                          maxDate={getDates.applicationMax()}
                          onChange={(event) => handleChanges.date(event.target.value, constants.dateTypes.tagApplication)}
                          setError={setApplicationDateError}
                          value={applicationDate}
                          yearLength={4}
                        />
                        {warnings?.date &&
                          <ErrorLabel
                            id="dateWarning"
                            label={warnings.date}
                          />
                        }
                      </div>

                      <div className="section">
                        <AboutPanel
                          descriptions={[
                            { text: 'label.userCommentDescriptionTagApplication' }
                          ]}
                          title="label.userCommentTitle"
                        >
                          <TextEntry
                            id="comment"
                            maxLength={config.MAXLENGTH_COMMENT}
                            name="comment"
                            onChange={(event) => handleChanges.comment(event.target.value)}
                            placeholder={t('label.userCommentPlaceholder')}
                            value={comment}
                            width="full"
                          />
                          {warnings?.comment &&
                            <ErrorLabel
                              id="commentWarning"
                              label={warnings.comment}
                            />
                          }
                        </AboutPanel>
                      </div>

                      <div className="section">
                        <RadioButtons
                          ids={[
                            constants.option.tag.breedingTags,
                            constants.option.tag.slaughterTags,
                            constants.option.tag.individualTag
                          ]}
                          name="tagType"
                          onChange={(event) => handleChanges.tagType(event)}
                          suppressHint={true}
                          tooltip="tooltips.tagApplications.typeOfTags"
                          value={tagType}
                        />
                      </div>

                      {tagType &&
                        <>
                          <h2 className="h2">{t('label.animalDetails')}</h2>

                          {(helpers.option.tagType.isBreeding(tagType) || helpers.option.tagType.isSlaughter(tagType)) &&
                            <div className="section">
                              <DropdownYear
                                label="label.yearOfBirth"
                                name="yearOfBirth"
                                onChange={handleChanges.yearOfBirth}
                                value={yearOfBirth}
                              />
                              {!isSheepOrGoat &&
                                <DropdownMonth
                                  label="label.monthOfBirth"
                                  name="monthOfBirth"
                                  onChange={handleChanges.monthOfBirth}
                                  value={monthOfBirth}
                                />
                              }
                              {warnings?.dob &&
                                <ErrorLabel
                                  id="dobWarning"
                                  label={warnings.dob}
                                />
                              }
                            </div>
                          }

                          {helpers.option.tagType.isIndividual(tagType) &&
                            <>
                              <div className="section">
                                <InputDate
                                  dontValidate={true}
                                  error={birthDateError}
                                  id="birthDate"
                                  labelPosition="above"
                                  labelText="label.dateBirth"
                                  maxDate={getDates.birthMax(applicationDate)}
                                  onChange={(event) => handleChanges.date(event.target.value, constants.dateTypes.birth)}
                                  setError={setBirthDateError}
                                  value={birthDate}
                                  warning={birthDateWarning}
                                  yearLength={4}
                                />
                              </div>

                              {birthDate && applicationDate &&
                                <div className={'section ' + classes.addIndividualRow}>
                                  <div className={classes.batchNumber}>
                                    <DropdownBatchNumbersAvailable
                                      label={batchNumberLabel}
                                      name="availableBatches"
                                      onChange={(event) => handleChanges.batchNumber(event.target.value)}
                                      setModal={setModal}
                                      value={batchNumber}
                                    />
                                  </div>
                                  <div className={classes.tagNumber}>
                                    <NumberEntry
                                      disabled={pending}
                                      id="tagNumber"
                                      label="label.individualNumber"
                                      max={helpers.species.isGoatId(species.id) ? 999999 : 99999 }
                                      min="1"
                                      name="tagNumber"
                                      onBlur={(event) => handleChanges.padTagNumber(batchNumber, event.target.value)}
                                      onChange={(event) => handleChanges.tagNumber(event.target.value)}
                                      value={tagNumber}
                                    />
                                  </div>
                                </div>
                              }
                            </>
                          }

                          {applicationDate && (birthDate || yearOfBirth) &&
                            <>
                              <div className="section">
                                <DropdownBreeds
                                  label="label.breedIfKnown"
                                  name="breedId"
                                  onChange={handleChanges.breed}
                                  value={breedId}
                                />
                                {warnings?.breed &&
                                  <ErrorLabel
                                    id="breedWarning"
                                    label={warnings.breed}
                                  />
                                }
                              </div>

                              <div className="section">
                                <RadioButtons
                                  ids={[
                                    constants.option.gender.male,
                                    constants.option.gender.female
                                  ]}
                                  inline={true}
                                  name="gender"
                                  onChange={handleChanges.gender}
                                  optional={true}
                                  tooltip="tooltips.tagApplications.gender"
                                  value={gender}
                                />
                                {warnings?.gender &&
                                  <ErrorLabel
                                    id="genderWarning"
                                    label={warnings.gender}
                                  />
                                }
                              </div>

                              {helpers.option.tagType.isIndividual(tagType) &&
                                <>
                                  <div className="section">
                                    {isSheepOrGoat &&
                                      <>
                                        <div className="input">
                                          <TextEntry
                                            id="birthDamIdText"
                                            label="label.birthDamId"
                                            label2="label.tagExamples.damAndSireID"
                                            maxWidth={true}
                                            name="birthDamIdText"
                                            onChange={handleChanges.birthDamId}
                                            optional={true}
                                            value={birthDamId}
                                          />
                                        </div>

                                        <div className="input">
                                          <TextEntry
                                            id="geneticDamIdText"
                                            label="label.geneticDamIdText"
                                            label2="label.tagExamples.damAndSireID"
                                            maxWidth={true}
                                            name="geneticDamIdText"
                                            onChange={handleChanges.geneticDamId}
                                            optional={true}
                                            value={geneticDamId}
                                          />
                                        </div>

                                        <div className="input">
                                          <TextEntry
                                            id="sireIdText"
                                            label="label.sireId"
                                            label2="label.tagExamples.damAndSireID"
                                            maxWidth={true}
                                            name="sireIdText"
                                            onChange={handleChanges.sireId}
                                            optional={true}
                                            value={sireId}
                                          />
                                        </div>
                                      </>
                                    }

                                    <div className="input">
                                      <TextEntry
                                        id="genotype"
                                        label="label.genotype"
                                        label2="label.tagExamples.genotype"
                                        maxWidth={true}
                                        name="genotype"
                                        onChange={handleChanges.genotype}
                                        optional={true}
                                        value={genotype}
                                      />
                                    </div>

                                    {!isSheepOrGoat &&
                                      <div className="section">
                                        <RadioButtons
                                          ids={[
                                            constants.option.embryo.yes,
                                            constants.option.embryo.no
                                          ]}
                                          inline={true}
                                          name="embryo"
                                          onChange={handleChanges.embryo}
                                          value={embryo}
                                        />
                                      </div>
                                    }
                                  </div>

                                  {embryo === constants.option.embryo.yes &&
                                    <div className="section">
                                      <GeneticDamTab
                                        activeTabId={activeTabGeneticDam}
                                        geneticDamAI={geneticDamAI}
                                        geneticDamId={geneticDamId}
                                        geneticDamIdError={geneticDamIdError}
                                        geneticDamTabs={geneticDamTabs}
                                        setActiveTabId={setActiveTabGeneticDam}
                                        setGeneticDamAI={handleChanges.geneticDamAI}
                                        setGeneticDamId={handleChanges.geneticDamId}
                                        setModal={setModal}
                                      />
                                    </div>
                                  }

                                  {!isSheepOrGoat &&
                                    <div className="section">
                                      <div className="input select">
                                        <DropdownDamIds
                                          name="birthDamId"
                                          onChange={handleChanges.birthDamId}
                                          optional={true}
                                          setModal={setModal}
                                          value={birthDamId}
                                        />
                                      </div>
                                      <div className="input select">
                                        <DropdownSireIds
                                          label="label.sireId"
                                          name="sireId"
                                          onChange={handleChanges.sireId}
                                          optional={true}
                                          setModal={setModal}
                                          value={sireId}
                                        />
                                      </div>
                                    </div>
                                  }
                                </>
                              }

                              {helpers.option.tagType.isBreeding(tagType) &&
                                <>
                                  <h2 className="h2">{t('label.idMultipleAnimals')}</h2>

                                  <div className="section">
                                    <RadioButtons
                                      ids={[
                                        constants.option.selectionMethod.tagNumberRange,
                                        constants.option.selectionMethod.uploadFile,
                                        constants.option.selectionMethod.manualTagNumbers
                                      ]}
                                      name="selectionMethodTagApplication"
                                      onChange={handleChanges.selectionMethod}
                                      value={selectionMethod}
                                    />
                                  </div>

                                  {helpers.option.selectionMethod.isTagNumberRange(selectionMethod) &&
                                    <>
                                      <div className="section">
                                        <AddTagRange
                                          isDropdown={true}
                                          setAnimalsToChange={handleChanges.addAnimals}
                                          setModal={setModal}
                                          species={species}
                                        />
                                      </div>

                                      {animalsToChange.length > 0 &&
                                        <>
                                          <Table
                                            columns={tableColumns.tagApplication.availableTags({ actionFunction: handleChanges.removeAnimal })}
                                            data={animalsToChange}
                                            dataProvided={true}
                                            initialSortBy={initialSorting.tagApplication.availableTags.accessor}
                                            initialSortDirection={initialSorting.tagApplication.availableTags.direction}
                                            paginationParams={paginationParams}
                                            removeAll={handleChanges.removeAnimalsAll}
                                            removeAllInvalid={handleChanges.removeAnimalsAllInvalid}
                                            setData={setAnimalsToChange}
                                            setModal={setModal}
                                            title={{
                                              title: 'table.title.animalsEntered',
                                              data: [
                                                animalsToChange?.length
                                              ]
                                            }}
                                          />
                                        </>
                                      }
                                    </>
                                  }

                                  {helpers.option.selectionMethod.isUploadFile(selectionMethod) &&
                                    <div className="section">
                                      <FileUpload
                                        description={[
                                          'label.upload.uploadDesc',
                                          helpers.species.idToName(species.id) === 'Sheep' ? 'label.upload.uploadDescSpecies-1' : 'label.upload.uploadDescSpecies-2',
                                          'label.upload.uploadDescLink'
                                        ]}
                                        hideHint={true}
                                        hideTooltip={true}
                                        modalMessage={helpers.tag.getFormatExamples(species.id).description}
                                        modalTitle={helpers.tag.getFormatExamples(species.id).title}
                                        pending={pending}
                                        setAnimalsToChange={handleChanges.upload}
                                        setModal={setModal}
                                        setPending={setPending}
                                        species={species}
                                      />
                                      {animalsToChange.length > 0 &&
                                        <>
                                          {animalsToChange && helpers.tag.hasNonEidTagsSheep(species.id, animalsToChange) &&
                                            <ErrorLabel
                                              isWarning={true}
                                              label="formatExamples.tagBatchNonEidError"
                                              modalMessage={helpers.tag.getFormatExamples(species.id).description}
                                              modalTitle={helpers.tag.getFormatExamples(species.id).title}
                                              setModal={setModal}
                                            />
                                          }
                                          {animalsToChange?.some((tag) => helpers.tag.isInvalid(tag)) &&
                                            <ErrorLabel
                                              label="formatExamples.tagBatchError"
                                              modalMessage={helpers.tag.getFormatExamples(species.id).description}
                                              modalTitle={helpers.tag.getFormatExamples(species.id).title}
                                              setModal={setModal}
                                            />
                                          }
                                          <Table
                                            columns={tableColumns.tagApplication.availableTags({ actionFunction: handleChanges.removeAnimal })}
                                            data={animalsToChange}
                                            dataProvided={true}
                                            initialSortBy={initialSorting.tagApplication.individuals.accessor}
                                            initialSortDirection={initialSorting.tagApplication.individuals.direction}
                                            paginationParams={paginationParams}
                                            removeAll={handleChanges.removeAnimalsAll}
                                            removeAllInvalid={handleChanges.removeAnimalsAllInvalid}
                                            setData={setAnimalsToChange}
                                            setModal={setModal}
                                            title={{
                                              title: 'table.title.animalsEntered',
                                              data: [
                                                animalsToChange?.length
                                              ]
                                            }}
                                          />
                                        </>
                                      }
                                    </div>
                                  }

                                  {helpers.option.selectionMethod.isManualTagNumbers(selectionMethod) &&
                                    <div className="section">
                                      <ManualTagEntry
                                        addAnimals={handleChanges.addAnimals}
                                        animalsToChange={animalsToChange}
                                        breed={breedId}
                                        dob={yearOfBirth}
                                        genderName={gender}
                                        id="manualTagEntry"
                                        pending={pending}
                                        setModal={setModal}
                                        setPending={setPending}
                                        species={species}
                                        title="movements.animal-selection.manual-tag-entry-heading"
                                        tooltip="tooltips.movements.manualTagEntry"
                                        type={constants.option.movement.newMovement}
                                      />

                                      {animalsToChange.length > 0 &&
                                        <>
                                          {animalsToChange && helpers.tag.hasNonEidTagsSheep(species.id, animalsToChange) &&
                                            <ErrorLabel
                                              isWarning={true}
                                              label="formatExamples.tagBatchNonEidError"
                                              modalMessage={helpers.tag.getFormatExamples(species.id).description}
                                              modalTitle={helpers.tag.getFormatExamples(species.id).title}
                                              setModal={setModal}
                                            />
                                          }
                                          {animalsToChange?.some((tag) => helpers.tag.isInvalid(tag)) &&
                                            <ErrorLabel
                                              label="formatExamples.tagBatchError"
                                              modalMessage={helpers.tag.getFormatExamples(species.id).description}
                                              modalTitle={helpers.tag.getFormatExamples(species.id).title}
                                              setModal={setModal}
                                            />
                                          }
                                          <Table
                                            columns={tableColumns.tagApplication.availableTags({ actionFunction: handleChanges.removeAnimal })}
                                            data={animalsToChange}
                                            dataProvided={true}
                                            initialSortBy={initialSorting.tagApplication.individuals.accessor}
                                            initialSortDirection={initialSorting.tagApplication.individuals.direction}
                                            paginationParams={paginationParams}
                                            removeAll={handleChanges.removeAnimalsAll}
                                            removeAllInvalid={handleChanges.removeAnimalsAllInvalid}
                                            setData={setAnimalsToChange}
                                            setModal={setModal}
                                            title={{
                                              title: 'table.title.animalsEntered',
                                              data: [
                                                animalsToChange?.length
                                              ]
                                            }}
                                          />
                                        </>
                                      }
                                    </div>
                                  }
                                </>
                              }

                              {helpers.option.tagType.isSlaughter(tagType) &&
                                <div className="section">
                                  <AddBatch
                                    animalTotalLabel="movements.animal-selection.batch.input-field-two"
                                    batchHeader={batchHeaderLabel}
                                    batchList={animalsToChange}
                                    buttonLabel="button.add"
                                    pending={pending}
                                    setModal={setModal}
                                    setPending={setPending}
                                    species={species}
                                    updateBatchList={handleChanges.batchList}
                                  />

                                  {animalsToChange?.some((batch) => helpers.batch.isInvalid(batch)) &&
                                    <ErrorLabel
                                      label="formatExamples.tagBatchError"
                                      modalMessage={helpers.tag.getFormatExamples(species.id).description}
                                      modalTitle={helpers.tag.getFormatExamples(species.id).title}
                                      setModal={setModal}
                                    />
                                  }

                                  {animalsToChange?.length > 0 &&
                                    <Table
                                      columns={tableColumns.tagApplication.batches({ actionFunction: handleChanges.removeBatch, speciesId })}
                                      data={animalsToChange}
                                      dataProvided={true}
                                      initialSortBy={initialSorting.tagApplication.batches.accessor}
                                      initialSortDirection={initialSorting.tagApplication.batches.direction}
                                      paginationParams={paginationParams}
                                      setData={setAnimalsToChange}
                                      setModal={setModal}
                                      title={{
                                        title: helpers.text.pluralCheck(animalsToChange?.reduce((total, item) => total + parseInt(item.animalTotal), 0), 'table.title.batchesSelected'),
                                        data: [
                                          animalsToChange?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
                                        ]
                                      }}
                                    />
                                  }
                                </div>
                              }
                            </>
                          }

                          <div className={classes.actions}>
                            <div className={classes.submit}>
                              <Button
                                buttonType="primary"
                                disabled={submitDisabled}
                                label="button.submit"
                                onClick={handleSubmit}
                              />
                            </div>
                            <div className={classes.exit}>
                              <Button
                                buttonType="tertiary"
                                label="button.cancelExit"
                                onClick={() => history.push(Routing.keeperHolding)}
                              />
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </>
              }
            </div>
            <div id={constants.tabs.tagReplacements} label="tagging.tab2" url={Routing.taggingReplacements} />
          </TabsNavigational>
        );
      }

      case Routing.taggingApplicationsConfirm: {
        const requestId = storeService.session.get.confirmRequestId();
        const pollingStatus = storeService.session.get.confirmPollingStatus();
        const pollingErrors = storeService.session.get.confirmPollingErrors();
        const pollingWarnings = storeService.session.get.confirmPollingWarnings();
        const requestType = storeService.session.get.confirmRequestType();

        if (!requestId) {
          return <Redirect to={Routing.taggingApplications} />;
        }
        if (bffStatus.isClaPending(pollingStatus) || bffStatus.isPending(pollingStatus)) {
          return (
            <Confirmation
              confirm={{
                label: 'label.submissionPendingCheckViaUserActivity',
                onClick: () => confirmation.viewUserActivity()
              }}
              label="label.submissionPending"
              onClick={() => confirmation.viewUserActivity()}
              setModal={setModal}
              status={pollingStatus}
            />
          );
        }
        return (
          <Confirmation
            buttons={[
              requestId && !bffStatus.isClaPending(pollingStatus) ? confirmation.viewSummary(requestId, helpers.option.tagType.isIndividual(requestType) ? constants.option.requestType.birth : constants.option.requestType.tagApplication) : null
            ].filter((item) => item)}
            confirm={{
              id: requestId,
              label: 'label.reference'
            }}
            errors={pollingErrors}
            isUnamendable={true}
            label="tagging.application.submitted"
            onClick={() => history.push(Routing.taggingApplications)}
            setModal={setModal}
            status={pollingStatus}
            warnings={pollingWarnings}
          />
        );
      }
    }
  };

  return (
    <>
      {ready &&
        <>
          <Prompt
            message={(params) => {
              return (!storeService.session.get.idle() && dataChanged && (params.pathname.substring(0, Routing.taggingApplications.length) !== Routing.taggingApplications || params.pathname === Routing.taggingApplications)) ? t('warning.unsavedChanges2') : true;
            }}
          />

          <h1 className="h1">{t('menu.tagging')}</h1>

          {stepPicker(location.pathname)}
        </>
      }
    </>
  );
};

TagApplication.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func.isRequired
};

export default TagApplication;
