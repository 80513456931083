import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/dateRange/dateRange.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import Button from 'components/base/button/button';
import InputDate from 'components/base/inputDate/inputDate';

const DateRange = ({
  allowTomorrow,
  classNames,
  disabled,
  fromDate,
  fromDateError,
  fromDateRequired,
  inline,
  loadPending,
  maxRange,
  setFromDate,
  setFromDateError,
  setToDate,
  setToDateError,
  submit,
  submitDisabled,
  submitLabel,
  toDate,
  toDateError,
  toDateRequired,
  yearLength
}) => {
  const { ready, t } = useTranslation();

  const getDates = {
    fromMin: () => {
      if (maxRange && toDate) {
        return helpers.date.formatYYYYMMDD(
          helpers.date.momentToJSDate(
            helpers.date.momentMinDate(
              helpers.date.subtractPeriod(toDate, maxRange.value, maxRange.unit),
              helpers.date.todayEndOfDay()
            )
          )
        );
      }

      return null;
    },

    fromMax: () => {
      const today = new Date();

      if (toDate) {
        if (!allowTomorrow && helpers.date.is.inTheFuture(toDate)) {
          return helpers.date.formatYYYYMMDD(today);
        }

        return helpers.date.formatYYYYMMDD(toDate);
      }

      return helpers.date.formatYYYYMMDD(today);
    },

    toMin: () => {
      if (fromDate) {
        return helpers.date.formatYYYYMMDD(fromDate);
      }

      return null;
    },

    toMax: () => {
      const today = new Date();

      if (maxRange && fromDate) {
        return helpers.date.formatYYYYMMDD(
          helpers.date.momentToJSDate(
            helpers.date.momentMinDate(
              helpers.date.addPeriod(fromDate, maxRange.value, maxRange.unit),
              allowTomorrow
                ? helpers.date.addPeriod(today, 1, constants.period.days)
                : helpers.date.todayEndOfDay()
            )
          )
        );
      }

      if (allowTomorrow) {
        return helpers.date.formatYYYYMMDD(
          helpers.date.endOfDay(
            helpers.date.addPeriod(today, 1, constants.period.days)
          )
        );
      }

      return helpers.date.formatYYYYMMDD(today);
    }
  };

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [fromMin, setFromMin] = useState(getDates.fromMin());
  const [fromMax, setFromMax] = useState(getDates.fromMax());
  const [toMin, setToMin] = useState(getDates.toMin());
  const [toMax, setToMax] = useState(getDates.toMax());

  const componentClassNames = [
    'section',
    classes.dateRange,
    ...(classNames ? classNames.map((className) => classes[className]) : []),
    inline ? classes.inline : ''
  ].join(' ').trim();

  const validateDate = (date, which) => {
    let hasError = false;

    setDisableSubmit(true);

    if (helpers.flags.isFrom(which)) {
      setFromDateError('');

      const fromDateIsInTheFuture = helpers.date.is.inTheFuture(date);
      const fromDateIsAfterToDate = helpers.date.is.afterDate(date, toDate);
      const fromDateFromIsOneYearBeforeToDate = maxRange?.value === 1 && maxRange.unit === 'Year' && helpers.date.is.xPeriodBeforeDate(date, toDate, 1, constants.period.years);

      if (!hasError && date === '' && toDate !== '' && fromDateRequired) {
        setFromDateError(t('error.selectDate'));

        hasError = true;
      }

      if (!hasError && fromDateIsInTheFuture && !allowTomorrow) {
        setFromDateError(fromDateIsInTheFuture);

        hasError = true;
      }

      if (!hasError && fromDateIsAfterToDate) {
        setFromDateError('error.fromDateCannotBeAfterToDate');

        hasError = true;
      }

      if (!hasError && fromDateFromIsOneYearBeforeToDate) {
        setFromDateError('error.fromDateCannotBeOneYearBeforeToDate');
      }
    } else if (helpers.flags.isTo(which)) {
      setToDateError('');

      const toDateIsInTheFuture = helpers.date.is.inTheFuture(date);
      const toDateIsBeforeFromDate = helpers.date.is.beforeDate(date, fromDate);
      const fromDateFromIsOneYearAfterToDate = maxRange?.value === 1 && maxRange.unit === 'Year' && helpers.date.is.xPeriodAfterDate(date, fromDate, 1, constants.period.years);

      if (!hasError && date === '' && toDate !== '' && toDateRequired) {
        setToDateError(t('error.selectDate'));

        hasError = true;
      }

      if (!hasError && toDateIsInTheFuture && !allowTomorrow) {
        setToDateError(toDateIsInTheFuture);

        hasError = true;
      }

      if (!hasError && toDateIsBeforeFromDate) {
        setToDateError('error.toDateCannotBeBeforeFromDate');

        hasError = true;
      }

      if (!hasError && fromDateFromIsOneYearAfterToDate) {
        setToDateError('error.toDateCannotBeOneYearAfterFromDate');
      }
    }

    if (!hasError) {
      setDisableSubmit(false);
    }
  };

  const handleChanges = {
    fromDate: (date) => {
      setFromDate(date);
      validateDate(date, constants.flags.from);
    },

    toDate: (date) => {
      setToDate(date);
      validateDate(date, constants.flags.to);
    }
  };

  useEffect(() => {
    setFromMin(getDates.fromMin());
    setFromMax(getDates.fromMax());
    setToMin(getDates.toMin());
    setToMax(getDates.toMax());
    validateDate(fromDate, constants.flags.from);
    validateDate(toDate, constants.flags.to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowTomorrow]);

  useEffect(() => {
    setToMin(getDates.toMin());
    setToMax(getDates.toMax());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setFromMin(getDates.fromMin());
    setFromMax(getDates.fromMax());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  return (
    <>
      {ready &&
        <div className={componentClassNames}>
          <div className={classes.date}>
            <InputDate
              blockStyling={true}
              disabled={loadPending || disabled}
              dontValidate={true}
              error={fromDateError}
              id="rangeFromDate"
              labelPosition="above"
              labelText="label.fromDate"
              maxDate={fromMax}
              minDate={fromMin}
              onChange={(event) => handleChanges.fromDate(event.target.value)}
              setError={setFromDateError}
              value={fromDate}
              yearLength={yearLength ? yearLength : 4}
            />
          </div>
          <div className={classes.date}>
            <InputDate
              blockStyling={true}
              disabled={loadPending || disabled}
              dontValidate={true}
              error={toDateError}
              id="rangeToDate"
              labelPosition="above"
              labelText="label.toDate"
              maxDate={toMax}
              minDate={toMin}
              onChange={(event) => handleChanges.toDate(event.target.value)}
              setError={setToDateError}
              value={toDate}
              yearLength={yearLength ? yearLength : 4}
            />
          </div>
          {submit &&
            <div className={'col-md-3 ' + classes.enter}>
              <Button
                buttonType="primary"
                disabled={disableSubmit || fromDateError !== '' || toDateError !== '' || loadPending || submitDisabled}
                label={submitLabel ? submitLabel : 'button.enter'}
                onClick={submit}
              />
            </div>
          }
        </div>
      }
    </>
  );
};

DateRange.propTypes = {
  allowTomorrow: PropTypes.bool,
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  disabled: PropTypes.bool,
  fromDate: PropTypes.string,
  fromDateError: PropTypes.string,
  fromDateRequired: PropTypes.bool,
  inline: PropTypes.bool,
  loadPending: PropTypes.bool,
  maxRange: PropTypes.exact({
    value: PropTypes.number.isRequired,
    unit: PropTypes.oneOf([
      constants.period.days,
      constants.period.weeks,
      constants.period.months,
      constants.period.years
    ]).isRequired
  }),
  setFromDate: PropTypes.func,
  setFromDateError: PropTypes.func,
  setToDate: PropTypes.func,
  setToDateError: PropTypes.func,
  submit: PropTypes.func,
  submitDisabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  toDate: PropTypes.string,
  toDateError: PropTypes.string,
  toDateRequired: PropTypes.bool,
  yearLength: PropTypes.number
};

export default DateRange;
