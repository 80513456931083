import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/summary/addressSummary/addressSummary.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Address from 'components/address/address';
import Button from 'components/base/button/button';
import ErrorLabel from 'components/base/errorLabel/errorLabel';

const AddressSummary = ({
  address1,
  address2,
  blockLayout,
  info1,
  info2,
  info3,
  isGathering,
  isManual,
  isPermitMove,
  label,
  label1,
  label2,
  label3,
  onClick,
  queryKeeper,
  setAddress1,
  setAddress2,
  setModal,
  storeIn1,
  storeIn2
}) => {
  const { ready, t } = useTranslation();

  return (
    <>
      {ready &&
        <div className={blockLayout ? classes.block : classes.addressSummary}>
          {label && blockLayout &&
            <div className={classes.label}>{t(label)}</div>
          }
          <div className={classes.summaryRow}>
            {label && !blockLayout &&
              <div className={classes.label}>{t(label)}</div>
            }
            <div className={classes.address}>
              {label1 &&
                <span className={classes.title}>{t(label1)}</span>
              }
              {helpers.address.isUnknown(info1) &&
                <span className={classes.holdingId}>{t('label.notSpecified')}</span>
              }
              {!helpers.address.isUnknown(info1) &&
                <>
                  {isGathering &&
                    <>
                      {address1?.address?.message &&
                        <ErrorLabel
                          extraContent="label.cphNotFound"
                          label={info1}
                        />
                      }
                      {!address1?.address?.message &&
                        <>
                          <span className={classes.holdingId}>{info1}</span>
                          {!isManual &&
                            <Address
                              cph={info1}
                              format={{
                                address: constants.address.format.full
                              }}
                              queryKeeper={queryKeeper}
                              setModal={setModal}
                              setSearchedHolding={setAddress1}
                              storeIn={storeIn1}
                            />
                          }
                        </>
                      }
                    </>
                  }
                  {!isGathering &&
                    <>
                      <span className={classes.holdingId}>{info1}</span>
                      {!isManual &&
                        <Address
                          cph={info1}
                          format={{
                            address: constants.address.format.full
                          }}
                          queryKeeper={queryKeeper}
                          setModal={setModal}
                          setSearchedHolding={setAddress1}
                          storeIn={storeIn1}
                        />
                      }
                    </>
                  }
                </>
              }
            </div>

            {label2 && info2 &&
              <div className={classes.address}>
                <span className={classes.title}>{t(label2)}</span>
                {helpers.address.isUnknown(info2) &&
                  <span className={classes.holdingId}>{t('label.notSpecified')}</span>
                }
                {!helpers.address.isUnknown(info2) &&
                  <>
                    {isGathering &&
                      <>
                        {address2?.address?.message &&
                          <ErrorLabel
                            extraContent="label.cphNotFound"
                            label={info2}
                          />
                        }
                        {!address2?.address?.message &&
                          <>
                            <span className={classes.holdingId}>{info2}</span>
                            <Address
                              cph={info2}
                              format={{
                                address: constants.address.format.full
                              }}
                              queryKeeper={queryKeeper}
                              setModal={setModal}
                              setSearchedHolding={setAddress2}
                              storeIn={storeIn2}
                            />
                          </>
                        }
                      </>
                    }
                    {!isGathering &&
                      <>
                        <span className={classes.holdingId}>{info2}</span>
                        {!isPermitMove &&
                          <Address
                            cph={info2}
                            format={{
                              address: constants.address.format.full
                            }}
                            queryKeeper={queryKeeper}
                            setModal={setModal}
                            setSearchedHolding={setAddress2}
                            storeIn={storeIn2}
                          />
                        }
                      </>
                    }
                  </>
                }
              </div>
            }

            {label3 && info3 &&
              <div className={classes.commentBlock}>
                <span className={classes.title}>{t(label3)}</span>
                <span className={classes.comment}>{t(info3)}</span>
              </div>
            }

            {onClick &&
              <div className={classes.action}>
                {blockLayout &&
                  <Button
                    buttonType="secondary"
                    label="label.change"
                    onClick={onClick}
                  />
                }
                {!blockLayout &&
                  <Button
                    buttonType="link"
                    label="label.edit"
                    onClick={onClick}
                  />
                }
              </div>
            }
          </div>
        </div>
      }
    </>
  );
};

AddressSummary.propTypes = {
  address1: PropTypes.object,
  address2: PropTypes.object,
  blockLayout: PropTypes.bool,
  info1: PropTypes.string,
  info2: PropTypes.string,
  isGathering: PropTypes.bool,
  isManual: PropTypes.bool,
  isPermitMove: PropTypes.bool,
  label: PropTypes.string,
  label1: PropTypes.string,
  label2: PropTypes.string,
  onClick: PropTypes.func,
  queryKeeper: PropTypes.bool,
  setAddress1: PropTypes.func,
  setAddress2: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  storeIn1: PropTypes.string,
  storeIn2: PropTypes.string
};

export default AddressSummary;
