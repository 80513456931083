import React from 'react';
import classes from 'components/base/checkboxGroup/checkboxGroup.module.scss';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import CheckBox from 'components/base/checkbox/checkbox';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import Hint from 'components/base/hint/hint';
import Legend from 'components/base/legend/legend';
import helpers from 'services/helpers';

const CheckboxGroup = ({
  disabled,
  error,
  hint,
  inline,
  label,
  legendLabel,
  name,
  objects,
  onChange,
  optional,
  pending,
  species
}) => {
  const getLabel = (label, species) => {
    return (species) ? label + storeService.session.get.species().id : label;
  };

  const checkboxLabel = (label, species) => {
    const prefix = name ? name + '.' : '';
    return prefix + getLabel(label, species);
  };

  const className = inline ? classes.checkboxGroup + ' ' + classes.inline : classes.checkboxGroup;

  return (
    <fieldset role="group">
      {legendLabel &&
        <Legend
          legend={legendLabel}
          optional={optional}
        />
      }
      <FieldLabel
        label={getLabel(label, species)}
        optional={optional}
      />
      <Hint
        hint={hint}
      />
      <ErrorLabel
        label={error}
      />
      {pending &&
        <ErrorLabel
          isWarning={true}
          label="label.fetchingData"
        />
      }
      {!pending &&
        <div className={className}>
          {objects?.map((obj) => (
            <CheckBox
              className="col-lg"
              disabled={helpers.type.isBoolean(disabled) ? disabled : obj.disabled}
              id={obj.id ? String(obj.id) : obj.label}
              isChecked={obj.value}
              key={obj.label}
              label={checkboxLabel(obj.label, species)}
              name={obj.label}
              onChange={onChange}
              value={obj.value}
            />
          ))}
        </div>
      }
    </fieldset>
  );
};

CheckboxGroup.propTypes = {
  disabled: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool
  ]),
  error: PropTypes.string,
  hint: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  legendLabel: PropTypes.string,
  name: PropTypes.string,
  objects: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      disabled: Boolean,
      label: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.bool
    })
  ).isRequired,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  pending: PropTypes.bool,
  species: PropTypes.number
};

export default CheckboxGroup;
