import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from 'components/header/navBar/navBar.module.scss';
import constants from 'services/constants';
import navigation from 'services/navigation';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import useRoleType from 'services/userRoleType';

const NavBar = ({
  permission,
  resetErrorBoundaries,
  setShowNav,
  showNav
}) => {
  const { ready, t } = useTranslation();
  const location = useLocation();

  const species = storeService.session.get.species();
  const isGathering = permissions.gatheringRoles.includes(permission);

  const menuItems = {
    animalsOnHolding: {
      id: 'animalsOnHolding',
      linkTo: Routing.animals,
      showIf: permission === useRoleType.KEEPER,
      hideIf: location.pathname.includes(Routing.home)
    },
    births: {
      id: 'births',
      linkTo: Routing.births,
      showIf: permission === useRoleType.KEEPER,
      hideIf: location.pathname.includes(Routing.home),
      showForSpecies: [
        constants.species.internalLabel.CATTLE
      ]
    },
    boMovements: {
      id: 'movements',
      linkTo: Routing.boMovements,
      showIf: permission === useRoleType.BACKOFFICE
    },
    changePassword: {
      id: 'changePassword',
      linkTo: Routing.changePassword,
      showIf: true,
      alwaysShow: true
    },
    deaths: {
      id: 'deaths',
      linkTo: Routing.deathsStep1,
      showIf: permission === useRoleType.KEEPER,
      hideIf: location.pathname.includes(Routing.home),
      hideForSpecies: [
        constants.species.internalLabel.PIGS,
        constants.species.internalLabel.DEER
      ],
      activeIf: location.pathname.substring(0, Routing.missingFound.length) === Routing.missingFound
    },
    holdingRegister: {
      id: 'holdingRegister',
      linkTo: Routing.holdingRegisterDetails,
      showIf: permission === useRoleType.KEEPER,
      hideIf: location.pathname.includes(Routing.home),
      activeIf: (
        location.pathname.substring(0, Routing.holdingRegisterDetails.length) === Routing.holdingRegisterDetails ||
        location.pathname.substring(0, Routing.holdingRegisterTaggingReplacement.length) === Routing.holdingRegisterTaggingReplacement ||
        location.pathname.substring(0, Routing.holdingRegisterTaggingApplications.length) === Routing.holdingRegisterTaggingApplications ||
        location.pathname.substring(0, Routing.holdingRegisterMovementsOff.length) === Routing.holdingRegisterMovementsOff ||
        location.pathname.substring(0, Routing.holdingRegisterMovementsOn.length) === Routing.holdingRegisterMovementsOn ||
        location.pathname.substring(0, Routing.holdingRegisterDeaths.length) === Routing.holdingRegisterDeaths ||
        location.pathname.substring(0, Routing.holdingRegisterAnnualInventory.length) === Routing.holdingRegisterAnnualInventory ||
        location.pathname.substring(0, Routing.holdingRegisterBirths.length) === Routing.holdingRegisterBirths ||
        location.pathname.substring(0, Routing.holdingRegisterDownload.length) === Routing.holdingRegisterDownload
      )
    },
    holdingSummary: {
      id: 'holdingSummary',
      linkTo: Routing.keeperHolding,
      showIf: permission === useRoleType.KEEPER,
      hideIf: location.pathname.includes(Routing.home)
    },
    home: {
      id: 'home',
      linkTo: navigation.home(),
      showIf: permission !== useRoleType.BACKOFFICE,
      hideIf: location.pathname.includes(Routing.home)
    },
    logout: {
      id: 'logout',
      linkTo: Routing.logout,
      showIf: true,
      alwaysShow: true
    },
    abattoirAttestation: {
      id: 'abattoirAttestation',
      linkTo: navigation.abattoirAttestation(),
      showIf: permission === useRoleType.ABATTOIR,
      hideIf: location.pathname.includes(Routing.home)
    },
    movements: {
      id: permission === useRoleType.KEEPER ? 'movements' : 'reportMovements',
      linkTo: navigation.movements(),
      showIf: permission !== useRoleType.BACKOFFICE,
      hideIf: location.pathname.includes(Routing.home)
    },
    search: {
      id: 'search',
      linkTo: navigation.getSearchURL(permission),
      showIf: permission !== useRoleType.KEEPER || isGathering,
      hideIf: location.pathname.includes(Routing.home)
    },
    tagging: {
      id: 'tagging',
      linkTo: Routing.taggingApplications,
      showIf: permission === useRoleType.KEEPER,
      hideIf: location.pathname.includes(Routing.home),
      hideForSpecies: [
        constants.species.internalLabel.CATTLE,
        constants.species.internalLabel.PIGS,
        constants.species.internalLabel.DEER
      ],
      activeIf: location.pathname.substring(0, Routing.taggingReplacements.length) === Routing.taggingReplacements
    }
  };

  const menuItemsByUserRole = {
    [useRoleType.ABATTOIR]: [
      menuItems.home,
      menuItems.search,
      menuItems.movements,
      menuItems.abattoirAttestation,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.AICENTRE]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.ASSEMBLY]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.COLLECTION]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.COMMON]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.GENERIC]: [
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.KEEPER]: [
      menuItems.home,
      menuItems.holdingSummary,
      menuItems.animalsOnHolding,
      menuItems.births,
      menuItems.tagging,
      menuItems.movements,
      menuItems.deaths,
      menuItems.holdingRegister,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.MARKET]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.OTHER]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.PORT]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.QUARANTINE]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.SHOW]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.SLAUGHTERMARKET]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.STOREMARKET]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.VET]: [
      menuItems.home,
      menuItems.movements,
      menuItems.search,
      menuItems.changePassword,
      menuItems.logout
    ],
    [useRoleType.RCVSVET]: [
      menuItems.changePassword,
      menuItems.logout
    ]
  };

  const [menu, setMenu] = useState(menuItemsByUserRole[permission]);

  const menuLabel = (id) => t('menu.' + id);

  const checkMenuItemVisibility = (menuItemKey) => {
    if (permission === useRoleType.KEEPER) {
      const currentMenu = menuItemsByUserRole[permission][menuItemKey];

      if (currentMenu && !currentMenu.alwaysShow) {
        if (!species) {
          return false;
        } else if (currentMenu.showForSpecies) {
          return currentMenu.showForSpecies.includes(species.name);
        } else if (currentMenu.hideForSpecies) {
          return !currentMenu.hideForSpecies.includes(species.name);
        }
      }
    }

    return true;
  };

  React.useEffect(() => {
    setMenu(menuItemsByUserRole[permission]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission, location.pathname]);

  return (
    <>
      {ready &&
        <nav aria-label={t('menu.mainNavigation')} className={classes.nav + ' d-lg-block ' + (showNav ? 'd-block' : 'd-none')} id="headerNav">

          <div className="container">
            <ul id="navigation">
              {menu.map((menuItem, menuItemKey) => {
                if (menuItem.showIf && !menuItem.hideIf && checkMenuItemVisibility(menuItemKey)) {
                  return (
                    <li className={menuItem.id === 'abattoirAttestation' ? classes.vetMenu : classes[menuItem.id]} key={menuItemKey}>
                      <NavLink
                        activeClassName={classes.active}
                        className={menuItem.activeIf ? classes.active : ''}
                        onClick={(event) => {
                          if (menuItem.onClick) {
                            menuItem.onClick(event);
                          }
                          setShowNav(false);
                          resetErrorBoundaries();
                        }}
                        to={menuItem.linkTo}
                      >
                        <span className={classes.homeIcon}><i className="bi bi-house-door-fill" /></span>
                        <span className={classes.label}>{menuLabel(menuItem.id)}</span>
                      </NavLink>
                    </li>
                  );
                }

                return null;
              })}
            </ul>
          </div>
        </nav>
      }
    </>
  );
};

NavBar.propTypes = {
  permission: PropTypes.string.isRequired,
  resetErrorBoundaries: PropTypes.func.isRequired,
  setShowNav: PropTypes.func.isRequired,
  showNav: PropTypes.bool.isRequired
};

export default NavBar;
