import React from 'react';
import storeService from 'services/storeService';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classes from 'frontEnd/vetModule/vetModule.module.scss';

const VetClaimsInfo = ({ border, vetAbattoirInfo = [] }) => {
  const { ready, t } = useTranslation(),
    claimsDetails = storeService.session.get.vetClaims() ? storeService.session.get.vetClaims() : vetAbattoirInfo,
    vetName = claimsDetails.name ? claimsDetails.name : claimsDetails[0].vetName,
    vetEmployer = claimsDetails.vetemployer ? claimsDetails.vetemployer : claimsDetails[0].vetEmployer,
    vetEmploymentAddress = claimsDetails.vetemploymentaddress ? claimsDetails.vetemploymentaddress : claimsDetails[0].vetEmploymentAddress,
    rcvsMembershipNo = claimsDetails.rcvsmembershipno ? claimsDetails.rcvsmembershipno : claimsDetails[0].vetRCVS;
  return (
    <>
      {ready && claimsDetails &&
        <>
          <div className={classes[border] + ' ' + classes.summaryRow}>
            <div className={classes.label}>{t('vetContent.vetClaims.veterinarianName')}</div>
            <div className={classes.value}>{vetName}</div>
          </div>
          <div className={classes.summaryRow + ' ' + classes[border]}>
            <div className={classes.label}>{t('vetContent.vetClaims.practiceName')}</div>
            <div className={classes.value}>{vetEmployer}</div>
          </div>
          <div className={classes.summaryRow + ' ' + classes[border]}>
            <div className={classes.label}>{t('vetContent.vetClaims.practiceAddress')}</div>
            <div className={classes.value}>{vetEmploymentAddress}</div>
          </div>
          <div className={classes.summaryRow + ' ' + classes[border]}>
            <div className={classes.label}>{t('vetContent.vetClaims.rcvsNumber')}</div>
            <div className={classes.value}>{rcvsMembershipNo}</div>
          </div>
        </>
      }
    </>
  );
};

VetClaimsInfo.propTypes = {
  border: PropTypes.string
};

export default VetClaimsInfo;
