import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isCancel } from 'services/bff';
import classes from 'components/addBatch/addBatch.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Button from 'components/base/button/button';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Hint from 'components/base/hint/hint';
import Legend from 'components/base/legend/legend';
import NumberEntry from 'components/base/numberEntry/numberEntry';
import TextEntry from 'components/base/textEntry/textEntry';

const AddBatch = ({
  animalTotalLabel,
  batchHeader,
  batchList,
  batchNumberOptional,
  buttonLabel,
  isTagReplacement,
  pending,
  resetForm,
  setBatchTotal,
  setModal,
  setPending,
  species,
  updateBatchList
}) => {
  const { ready, t } = useTranslation();

  const speciesLabel = t('label.batchNumber' + species.id);
  const replacementErrorLabel = 'movements.animal-selection.batch.warning-desc' + species.id;
  const batchOptionalLabel = 'label.batchOptional' + species.id;

  const [totalAnimalsError, setTotalAnimalsError] = useState('');
  const [batch, setBatch] = useState({
    batchNumber: '',
    animalTotal: ''
  });

  const validate = {
    totalAnimals: (input) => {
      const regex = /^[0-9]*$/;

      if (!regex.test(input)) {
        setTotalAnimalsError('movements.animal-selection.batch.input-field-two-error1');
        return false;
      }

      if (parseInt(input) > config.MAX_TOTAL_ANIMAL_RANGE) {
        setTotalAnimalsError('movements.animal-selection.batch.input-field-two-error2');
        return false;
      }

      return true;
    }
  };

  const handleChanges = {
    batchNumber: (input) => {
      setBatch({
        ...batch,
        batchNumber: input.trim()
      });
    },

    totalAnimals: (input) => {
      if (validate.totalAnimals(input)) {
        setTotalAnimalsError('');
        setBatch({
          ...batch,
          animalTotal: parseInt(input)
        });
      }
    }
  };

  const submitBatch = () => {
    if (batch.batchNumber) {
      setPending(true);

      helpers.validate.tagNumbers(species.name, [batch.batchNumber.trim()])
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal, setPending)) {
            const validatedBatchNumber = res.data.batchNumbers.find((item) => item.originalBatchNumber === batch.batchNumber.trim());

            setPending(false);

            updateBatchList([
              ...batchList,
              {
                animalTotal: batch.animalTotal,
                batchNumber: validatedBatchNumber.formattedBatchNumber ? validatedBatchNumber.formattedBatchNumber : validatedBatchNumber.originalBatchNumber,
                valid: validatedBatchNumber.state
              }
            ]);

            setBatch({
              batchNumber: '',
              animalTotal: ''
            });
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setPending(false);
            errors.BFF(error, setModal);
          }
        });
    } else {
      updateBatchList([
        ...batchList,
        {
          animalTotal: batch.animalTotal,
          batchNumber: '',
          valid: constants.tag.valid
        }
      ]);

      setBatch({
        batchNumber: '',
        animalTotal: ''
      });
    }
  };

  const isDisabled = () => {
    if (batchNumberOptional) {
      return (
        !batch.animalTotal ||
        totalAnimalsError ||
        pending
      );
    }

    return (
      !batch.batchNumber ||
      !batch.animalTotal ||
      totalAnimalsError ||
      pending
    );
  };

  useEffect(() => {
    let totalNumberOfAnimals = 0;
    batchList.forEach((batch) => {
      if (batch.animalTotal) {
        totalNumberOfAnimals += parseInt(batch.animalTotal);
      }
    });

    // Not all calls to this component pass down a setBatchTotal prop so checking it exists before calling it
    if (typeof setBatchTotal === 'function') {
      setBatchTotal(totalNumberOfAnimals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBatchTotal, batchList]);

  useEffect(() => {
    setBatch({
      batchNumber: '',
      animalTotal: ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);

  return (
    <>
      {ready &&
        <fieldset role="group">
          <Legend
            legend={batchHeader}
          />

          {batch.batchNumber?.length > 0 &&
            batch.animalTotal?.length > 0 &&
            !isTagReplacement &&
            <ErrorLabel
              isWarning={true}
              label={replacementErrorLabel}
            />
          }

          {batchNumberOptional &&
            <Hint
              hint={batchOptionalLabel}
            />
          }
          <div className={classes.addBatch}>
            <div className={classes.totalAnimals}>
              <NumberEntry
                disabled={pending}
                error={totalAnimalsError}
                id="animalTotal"
                label={animalTotalLabel}
                max="1020"
                min="1"
                name="animalTotal"
                onChange={(event) => handleChanges.totalAnimals(event.target.value)}
                value={batch.animalTotal}
              />
            </div>
            <div className={classes.batchNumber}>
              <TextEntry
                disabled={pending}
                hideCharacterCount={true}
                id="batchNumber"
                label={speciesLabel}
                maxLength={config.WIDTH_BATCH_NUMBER}
                name="batchNumber"
                onChange={(event) => handleChanges.batchNumber(event.target.value)}
                optional={batchNumberOptional}
                value={batch.batchNumber}
              />
            </div>
            <Button
              buttonType="secondary"
              disabled={isDisabled()}
              label={buttonLabel}
              onClick={() =>
                isTagReplacement
                  ? updateBatchList(batch.batchNumber, batch.animalTotal)
                  : submitBatch()
              }
            />
          </div>
        </fieldset>
      }
    </>
  );
};

AddBatch.propTypes = {
  animalTotalLabel: PropTypes.string,
  batchHeader: PropTypes.string,
  batchList: PropTypes.array,
  batchNumberOptional: PropTypes.bool,
  buttonLabel: PropTypes.string,
  isTagReplacement: PropTypes.func,
  pending: PropTypes.bool,
  resetForm: PropTypes.bool,
  setBatchTotal: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setPending: PropTypes.func,
  species: PropTypes.object,
  updateBatchList: PropTypes.func
};

export default AddBatch;
