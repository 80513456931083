import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bff from 'services/bff';
import classes from 'components/base/modal/modal.module.scss';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import storeService from 'services/storeService';
import { useTranslation } from 'react-i18next';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import AnnualYear from 'components/annualYear/annualYear';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import InputDate from 'components/base/inputDate/inputDate';
import NumberEntry from 'components/base/numberEntry/numberEntry';

const ModalAnnualInventory = ({
  modalClose,
  previouslySubmittedYears,
  setModal,
  setModalHasChanges,
  setModalSubmitted
}) => {
  const { ready, t } = useTranslation();

  const speciesId = storeService.session.get.species().id;

  const numberHint = 'reports.annualInventory.totalNumberHint' + speciesId;
  const numberLabel = 'reports.annualInventory.totalNumber' + speciesId;

  const [reportDate, setReportDate] = React.useState(null);
  const [inventoryMonth, setInventoryMonth] = React.useState('');
  const [inventoryYear, setInventoryYear] = React.useState('DEFAULT');
  const [totalNumber, setTotalNumber] = React.useState('');

  const [entryDateError, setEntryDateError] = useState('');
  const [day, setDay] = useState();
  const [month, setMonth] = useState();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [stockType, setStockType] = useState('');
  const [requestId, setRequestId] = useState(null);

  const [loadPending, setLoadPending] = useState(true);
  const [submitPending, setSubmitPending] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const validateDate = (date) => {
    let hasError = false;

    setEntryDateError('');

    const inventoryDate = helpers.date.formatYYYYMMDD(new Date(inventoryYear, month - 1, day));

    const dateIsInTheFuture = helpers.date.is.inTheFuture(date, 'annualInventory');
    const dateIsBeforeInventoryDate = helpers.date.is.beforeDate(date, inventoryDate);
    const dateIs31DaysAfterInventoryDate = helpers.date.is.xPeriodAfterDate(date, inventoryDate, 31, constants.period.days);

    if (!hasError && dateIsBeforeInventoryDate) {
      setEntryDateError('error.inventoryDateBelowRange');

      hasError = true;
    }

    if (!hasError && dateIs31DaysAfterInventoryDate) {
      setEntryDateError('error.inventoryDateAboveRange');

      hasError = true;
    }

    if (!hasError && dateIsInTheFuture) {
      setEntryDateError(dateIsInTheFuture);
    }
  };

  const handleChanges = {
    reportDate: (date) => {
      setReportDate(date);
      validateDate(date);
      setModalHasChanges(true);
    },

    inventoryDate: (event) => {
      switch (event.target.name) {
        case 'inventoryMonth':
          setInventoryMonth(event.target.value);
          break;
        case 'inventoryYear':
          setInventoryYear(event.target.value);
          break;
        case 'day':
          setDay(event.target.value);
          break;
        case 'month':
          setMonth(event.target.value);
          break;
        case 'stockType':
          setStockType(event.target.value);
          break;
        default:
          break;
      }
    },

    totalNumber: (event) => {
      setModalHasChanges(true);

      setTotalNumber(event.target.value);
    }
  };

  const getDates = {
    reportMin: () => {
      return helpers.date.formatYYYYMMDD(new Date(inventoryYear, month - 1, day));
    },

    reportMax: () => {
      const inventoryDate = helpers.date.formatYYYYMMDD(new Date(inventoryYear, month - 1, day));

      return helpers.date.formatYYYYMMDD(
        helpers.date.momentToJSDate(
          helpers.date.momentMinDate(
            helpers.date.addPeriod(inventoryDate, 30, constants.period.days),
            helpers.date.todayEndOfDay()
          )
        )
      );
    }
  };

  const submit = () => {
    setSubmitPending(true);

    bff
      .post('/annualInventory', {
        cph: storeService.session.get.holding().value,
        species: storeService.session.get.species().name,
        dateReported: reportDate,
        totalAnimals: totalNumber,
        day: day,
        month: month,
        year: inventoryYear,
        stockType: stockType
      })
      .then((res) => {
        storeService.session.removeAll.searchResults();

        setRequestId(String(res.data.requestId));
        setShowConfirmation(true);
        setSubmitPending(false);
        setModalHasChanges(false);
        setModalSubmitted(true);
      })
      .catch((error) => {
        setSubmitPending(false);
        errors.BFF(error, setModal);
      });
  };

  useEffect(() => {
    setDisabled(Boolean(!reportDate || entryDateError || !totalNumber || inventoryYear === 'DEFAULT'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDate, entryDateError, totalNumber, inventoryYear]);

  return (
    <>
      {ready &&
        <>
          <div className={classes.modalBody}>
            {showConfirmation &&
              <Confirmation
                confirm={{ id: requestId, label: 'label.reference' }}
                label="reports.annualInventory.submitted"
                setModal={setModal}
                status={constants.status.success} // Hardcoding this until we have polling for this section
              />
            }
            {!showConfirmation &&
              <>
                <h1 className="h1">{t('reports.annualInventory.title')}</h1>
                <p>{t('reports.annualInventory.desc')}</p>
                <p>{t('reports.annualInventory.subDesc')}</p>
                <div className="padAll10" />
                <hr />
                <div className="padAll10" />
                <div className="row">
                  <div className="col-md-8">
                    <AnnualYear
                      displayName="inventoryMonth"
                      groupHint="reports.annualInventory.dateHint"
                      groupLabel="reports.annualInventory.date"
                      month={inventoryMonth}
                      name="inventoryYear"
                      onChange={handleChanges.inventoryDate}
                      previouslySubmittedYears={previouslySubmittedYears}
                      setLoadPending={setLoadPending}
                      setModal={setModal}
                      setModalHasChanges={setModalHasChanges}
                      value={String(inventoryYear)}
                    />
                    <div className="section padTop10">
                      <InputDate
                        blockStyling={true}
                        disabled={inventoryYear === 'DEFAULT'}
                        dontValidate={true}
                        error={entryDateError}
                        hint="reports.annualInventory.dateReportedHint"
                        id="reportDate"
                        maxDate={getDates.reportMax()}
                        minDate={getDates.reportMin()}
                        onChange={(event) => handleChanges.reportDate(event.target.value)}
                        setError={setEntryDateError}
                        value={reportDate}
                        yearLength={4}
                      />
                    </div>
                    <hr className="lineColor" />
                    <NumberEntry
                      hint={numberHint}
                      id="totalNumber"
                      label={numberLabel}
                      max="99999"
                      min="1"
                      name="totalNumber"
                      onChange={handleChanges.totalNumber}
                      value={totalNumber}
                    />
                  </div>
                  <div className="col-md-4">
                    <AboutPanel
                      descriptions={[
                        { text: 'about.annualInventoryModal.desc1' },
                        { text: 'about.annualInventoryModal.desc2' },
                        { text: 'about.annualInventoryModal.desc3', href: constants.url.defraGuidance }
                      ]}
                      title="about.genericTitle.page"
                    />
                  </div>
                </div>
              </>
            }
          </div>
          <div className={classes.modalFooter}>
            <div></div>
            <div className={classes.modalActions}>
              {!showConfirmation &&
                <Button
                  buttonType="secondary"
                  disabled={disabled || loadPending || submitPending}
                  label="button.submit"
                  onClick={submit}
                  type="submit"
                />
              }
              <Button
                buttonType="tertiary"
                label={showConfirmation ? 'button.close' : 'button.cancel'}
                onClick={modalClose}
                type="submit"
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

ModalAnnualInventory.propTypes = {
  modalClose: PropTypes.func,
  previouslySubmittedYears: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  setModal: PropTypes.func.isRequired,
  setModalHasChanges: PropTypes.func,
  setModalSubmitted: PropTypes.func
};

export default ModalAnnualInventory;
