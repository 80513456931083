import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classes from 'components/summary/transportInformationBox/transportInformationBox.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import Address from 'components/address/address';

const TransportInformationBox = ({
  movementData,
  numberBeingMoved,
  setModal,
  speciesName
}) => {
  const { ready, t } = useTranslation();

  const displayCPH = () => {
    if (!movementData) {
      return null;
    }

    if (movementData.otherHolding) {
      return <Address
        cph={movementData.otherHolding.value}
        displayCPH={true}
        format={{
          address: constants.address.format.inline
        }}
        queryKeeper={true}
        setModal={setModal}
      />;
    }

    return <span className={classes.bold}>{t('label.notSpecified')}</span>;
  };

  return (
    <>
      {ready &&
        <div className={classes.transportInformation}>
          <div className={classes.numberMoved}>{numberBeingMoved}</div>
          <div>
            <span className={classes.bold}>{' '}{speciesName}{' '}</span>
            {movementData && helpers.option.movement.isOn(movementData.movementType)
              ? t('movements.transport-information.destination-holding-on')
              : t('movements.transport-information.destination-holding-off')}
            {' '}
            {movementData && helpers.option.movement.isPermitMove(movementData.movementType)
              ? <span dangerouslySetInnerHTML={{ __html: t('movements.permitMove.titleBold') }} />
              : displayCPH()
            }
          </div>
        </div>
      }
    </>
  );
};

TransportInformationBox.propTypes = {
  movementData: PropTypes.object,
  numberBeingMoved: PropTypes.number,
  setModal: PropTypes.func.isRequired,
  speciesName: PropTypes.string
};

export default TransportInformationBox;
