import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/base/tooltip/tooltip.module.scss';

const Tooltip = ({
  id,
  tooltip
}) => {
  const { ready, t } = useTranslation();

  const [showTooltip, setTooltip] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener('mousedown', () => {
      setTooltip(false);
    });
  });

  const toggleTooltip = () => {
    setTooltip(!showTooltip);
  };

  return (
    <>
      {ready && tooltip &&
        <div className={classes.tooltip} tabIndex="1000">
          <i aria-label={t('label.help')} className={'bi bi-question-circle-fill ' + classes.icon} onClick={toggleTooltip} />
          <div className={showTooltip ? classes.content + ' ' + classes.show : classes.content} id={id + 'ToolTip'}>{t(tooltip)}</div>
        </div>
      }
    </>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired
};

export default Tooltip;
