import React from 'react';
import PropTypes from 'prop-types';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import Hint from 'components/base/hint/hint';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/dropdown/dropdown.module.scss';

const Dropdown = ({
  error,
  hint,
  id,
  label,
  name,
  onChange,
  optional,
  optionList,
  value
}) => {
  const { ready, t } = useTranslation();

  const options = [...optionList];

  if (value === null) {
    options.unshift({
      label: t('prompt.pleaseSelect'),
      value: null
    });
  }

  const selectOption = (event) => {
    options.shift();
    onChange(event);
  };

  return (
    <>
      {ready &&
        <>
          <FieldLabel
            label={label}
            name={id}
            optional={optional}
          />
          <Hint
            hint={hint}
          />
          <ErrorLabel
            label={error}
          />
          <select
            className={classes.select}
            defaultValue={value ? value.toString() : value}
            id={id}
            name={name}
            onChange={(event) => selectOption(event)}
            placeholder="prompt.pleaseSelect"
          >
            {options.map((option, index) => (
              <option
                key={index}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
        </>
      }
    </>
  );
};

Dropdown.propTypes = {
  error: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  optionList: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  optional: PropTypes.bool,
  value: PropTypes.any
};

export default Dropdown;
