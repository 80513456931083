import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/base/button/button.module.scss';

const Button = ({
  ariaLabel,
  autoFocus,
  buttonType,
  disabled,
  extraContent,
  icon,
  id,
  label,
  onClick,
  tabIndex,
  type
}) => {
  const { ready, t } = useTranslation();

  const componentClassNames = [
    buttonType ? classes[buttonType] : classes.primary
  ].join(' ').trim();

  return (
    <>
      {ready &&
        <button
          aria-label={t(ariaLabel)}
          autoFocus={autoFocus}
          className={componentClassNames}
          disabled={disabled}
          id={id + 'Button'}
          onClick={onClick}
          tabIndex={tabIndex}
          type={type ? type : 'button'}
        >
          {icon &&
            <i className={classes.icon + ' ' + icon} />
          }
          {label &&
            <span>
              {t(label)}
              {extraContent &&
                <span className={classes.extraContent}>{extraContent}</span>
              }
            </span>
          }
        </button>
      }
    </>
  );
};

Button.propTypes = {
  ariaLabel: PropTypes.string,
  autoFocus: PropTypes.bool,
  buttonType: PropTypes.oneOf([
    'calendar',
    'clear',
    'danger',
    'link',
    'linkDanger',
    'primary',
    'remove',
    'secondary',
    'tertiary',
    'upload'
  ]),
  disabled: PropTypes.bool,
  extraContent: PropTypes.string,
  icon: PropTypes.any,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.string,
  type: PropTypes.string
};

export default Button;
