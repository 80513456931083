import decode from 'jwt-decode';
const env = require('./env.json');
const localhost = require('./localhost.json');

const { config } = localhost;
const local = decode(config.substring(7, config.length - 7));
const keys = Object.keys(env);

const codeHelper = {};
keys.forEach((key) => {
  codeHelper[key] = typeof env[key] !== 'string' || !env[key].includes('#') ? env[key] : local[key];
});

export default codeHelper;
