import storeService from 'services/storeService';

const recentlyUsedHelper = {
  setRecentlyUsedList: (dropdownType, selectedOption) => {
    const storedDropdownName = 'dropdown' + dropdownType;
    const recentlyUsedList = storeService.local.get[storedDropdownName]();

    if (!recentlyUsedList) {
      storeService.local.set[storedDropdownName]([{
        label: selectedOption,
        name: selectedOption,
        value: selectedOption
      }]);

      return;
    }

    if (!recentlyUsedList.find((item) => item.value === selectedOption)) {
      recentlyUsedList.unshift({
        label: selectedOption,
        name: selectedOption,
        value: selectedOption
      });

      if (recentlyUsedList.length > 5) {
        recentlyUsedList.pop();
      }

      storeService.local.set[storedDropdownName](recentlyUsedList);
    }
  },

  setRecentlyUsedBreedList: (selectedOption, speciesId) => {
    const storedBreedDropdownName = 'dropdownBreed' + speciesId;
    const recentlyUsedList = storeService.local.get[storedBreedDropdownName]();

    if (!recentlyUsedList) {
      storeService.local.set[storedBreedDropdownName]([selectedOption]);
      return;
    }

    recentlyUsedList.forEach((el) => {
      if (JSON.stringify(el) === JSON.stringify(selectedOption)) {
        const index = recentlyUsedList.indexOf(el);
        recentlyUsedList.splice(index, 1);
      }
    });
    recentlyUsedList.unshift(selectedOption);
    if (recentlyUsedList.length > 5) {
      recentlyUsedList.pop();
    }
    storeService.local.set[storedBreedDropdownName](recentlyUsedList);
  },

  getRecentlyUsedList: (dropdownType) => {
    return storeService.local.get['dropdown' + dropdownType]();
  }
};

export default recentlyUsedHelper;
