import Cookie from 'js-cookie';
import { get, set } from 'lodash';

const storageType = {
  local: 'localStorage',
  session: 'sessionStorage',
  cookie: 'cookieStorage'
};

const PATHS = {
  cookie: [
    { name: 'animalTypes', path: 'animalTypes' },
    { name: 'bearer', path: 'bearer' },
    { name: 'cph', path: 'cph' },
    { name: 'jwt', path: 'JWT' },
    { name: 'msalStatus', path: 'msal.interaction.status' },
    { name: 'species', path: 'species' },
    { name: 'username', path: 'username' },
    { name: 'xOnBehalfOf', path: 'x-on-behalf-of' }
  ],

  local: [
    { name: 'dropdown', path: 'dropdown' },
    { name: 'dropdownBirthDamId', path: 'dropdown.birthDamId' },
    { name: 'dropdownBreed1', path: 'dropdown.breed1' },
    { name: 'dropdownBreed2', path: 'dropdown.breed2' },
    { name: 'dropdownBreed3', path: 'dropdown.breed3' },
    { name: 'dropdownBreed4', path: 'dropdown.breed4' },
    { name: 'dropdownBreed5', path: 'dropdown.breed5' },
    { name: 'dropdownHoldingTypes', path: 'dropdown.holdingTypes' },
    { name: 'dropdownSireId', path: 'dropdown.sireId' },
    { name: 'recentlyUsed', path: 'recentlyUsed' },
    { name: 'recentlyUsedDeathHolding', path: 'recentlyUsed.deathHolding', defaultValue: {} },
    { name: 'recentlyUsedDeathHoldings', path: 'recentlyUsed.deathHoldings', defaultValue: {} },
    { name: 'recentlyUsedDepartureHolding', path: 'recentlyUsed.departureHolding', defaultValue: {} },
    { name: 'recentlyUsedDepartureHoldings', path: 'recentlyUsed.departureHoldings', defaultValue: {} },
    { name: 'recentlyUsedDepartureTransportDetail', path: 'recentlyUsed.departureTransportDetail', defaultValue: [] },
    { name: 'recentlyUsedDestinationHolding', path: 'recentlyUsed.destinationHolding', defaultValue: {} },
    { name: 'recentlyUsedDestinationHoldings', path: 'recentlyUsed.destinationHoldings', defaultValue: {} },
    { name: 'recentlyUsedDestinationTransportDetail', path: 'recentlyUsed.destinationTransportDetail', defaultValue: [] },
    { name: 'recentlyUsedHaulierTransportDetail', path: 'recentlyUsed.haulierTransportDetail', defaultValue: [] }
  ],

  session: [
    { name: 'activeTabId', path: 'activeTabId' },
    { name: 'activeTabIdOtherHoldingSelection', path: 'activeTabId.otherHoldingSelection' },
    { name: 'animals', path: 'animals', sessionRemove: true },
    { name: 'animalsAdded', path: 'animalsAdded', sessionRemove: true },
    { name: 'birth', path: 'birth', isRemoveAll: true, sessionRemove: true },
    { name: 'birthActiveTabGeneticDam', path: 'birth.activeTabGeneticDam' },
    { name: 'birthActiveTabSire', path: 'birth.activeTabSire' },
    { name: 'birthDamId', path: 'birth.birthDamId' },
    { name: 'birthDate', path: 'birth.birthDate' },
    { name: 'birthBreed', path: 'birth.breed' },
    { name: 'birthEmbryo', path: 'birth.embryo' },
    { name: 'birthGender', path: 'birth.gender' },
    { name: 'birthGeneticDamAI', path: 'birth.geneticDamAI' },
    { name: 'birthGeneticDamId', path: 'birth.geneticDamId' },
    { name: 'birthRegisterAnother', path: 'birth.registerAnother' },
    { name: 'birthSireAI', path: 'birth.sireAI' },
    { name: 'birthSireId', path: 'birth.sireId' },
    { name: 'birthTagNumber', path: 'birth.tagNumber' },
    { name: 'breedsAll', path: 'breedsAll', sessionRemove: true },
    { name: 'breedsOnHolding', path: 'breedsOnHolding', sessionRemove: true },
    { name: 'config', path: 'config', sessionRemove: true },
    { name: 'confirm', path: 'confirm', isRemoveAll: true, sessionRemove: true },
    { name: 'confirmAsyncId', path: 'confirm.confirmAsyncId' },
    { name: 'confirmMovementReference', path: 'confirm.movementReference' },
    { name: 'confirmPaperId', path: 'confirm.paperId' },
    { name: 'confirmPmMove', path: 'confirm.pmMove' },
    { name: 'confirmPolling', path: 'confirm.polling' },
    { name: 'confirmPollingErrors', path: 'confirm.polling.errors' },
    { name: 'confirmPollingStatus', path: 'confirm.polling.status' },
    { name: 'confirmPollingWarnings', path: 'confirm.polling.warnings' },
    { name: 'confirmRequestId', path: 'confirm.requestId' },
    { name: 'confirmRequestType', path: 'confirm.requestType' },
    { name: 'confirmSubmitType', path: 'confirm.submitType' },
    { name: 'confirmStatus', path: 'confirm.status' },
    { name: 'dataChanged', path: 'dataChanged', sessionRemove: true },
    { name: 'death', path: 'death', isRemoveAll: true, sessionRemove: true },
    { name: 'deathAmend', path: 'death.amend' },
    { name: 'deathAnimalsBeingMoved', path: 'death.animalsBeingMoved' },
    { name: 'deathBatchList', path: 'death.batchList' },
    { name: 'deathBirthDate', path: 'death.birthDate' },
    { name: 'deathBirthDamId', path: 'death.birthDamId' },
    { name: 'deathBreed', path: 'death.breed' },
    { name: 'deathDate', path: 'death.deathDate' },
    { name: 'deathDiedBeforeTagging', path: 'death.diedBeforeTagging' },
    { name: 'deathIdentificationType', path: 'death.identificationType' },
    { name: 'deathOtherHolding', path: 'death.otherHolding' },
    { name: 'deathSelectionType', path: 'death.selectionType' },
    { name: 'email', path: 'email' },
    { name: 'features', path: 'features', sessionRemove: true },
    { name: 'holding', path: 'holding', sessionRemove: true },
    { name: 'holdingAddress', path: 'holding.address' },
    { name: 'holdingRegister', path: 'holdingRegister', sessionRemove: true },
    { name: 'holdingRegisterFromDate', path: 'holdingRegister.fromDate' },
    { name: 'holdingRegisterToDate', path: 'holdingRegister.toDate' },
    { name: 'holdings', path: 'holdings', sessionRemove: true },
    { name: 'idle', path: 'idle' },
    { name: 'missingAndFound', path: 'missingAndFound', isRemoveAll: true, sessionRemove: true },
    { name: 'missingAndFoundAnimalsBeingMoved', path: 'missingAndFound.animalsBeingMoved' },
    { name: 'missingAndFoundBatchList', path: 'missingAndFound.batchList' },
    { name: 'missingAndFoundDate', path: 'missingAndFound.date' },
    { name: 'missingAndFoundIdentificationType', path: 'missingAndFound.identificationType' },
    { name: 'missingAndFoundReportType', path: 'missingAndFound.reportType' },
    { name: 'movement', path: 'movement', isRemoveAll: true, sessionRemove: true },
    { name: 'movementAmend', path: 'movement.amend' },
    { name: 'movementAnimalTotal', path: 'movement.animalTotal' },
    { name: 'movementArrivalDate', path: 'movement.arrivalDate' },
    { name: 'movementComment', path: 'movement.comment' },
    { name: 'movementDepartureDate', path: 'movement.departureDate' },
    { name: 'movementExempt', path: 'movement.exempt' },
    { name: 'movementExemptions', path: 'movement.exemptions' },
    { name: 'movementFromHolding', path: 'movement.fromHolding' },
    { name: 'movementFci', path: 'movement.fci' },
    { name: 'movementFciAnimalsSatisfy', path: 'movement.fci.animalsSatisfy' },
    { name: 'movementFciHoldingRestrictions', path: 'movement.fci.holdingRestrictions' },
    { name: 'movementFciNonComplianceReason', path: 'movement.fci.nonComplianceReason' },
    { name: 'movementFciRecordFci', path: 'movement.fci.recordFCI' },
    { name: 'movementFciUnfitAnimals', path: 'movement.fci.unfitAnimals' },
    { name: 'movementFciWithdrawalPeriod', path: 'movement.fci.withdrawalPeriod' },
    { name: 'movementInBusiness', path: 'movement.inBusinessMovement' },
    { name: 'movementMarketType', path: 'movement.marketType' },
    { name: 'movementOtherHolding', path: 'movement.otherHolding' },
    { name: 'movementOtherHoldingAddress', path: 'movement.otherHolding.address' },
    { name: 'movementType', path: 'movement.movementType' },
    { name: 'movementRecordCPH', path: 'movement.recordCPH' },
    { name: 'movementReference', path: 'movement.reference' },
    { name: 'movementSelectedAnimalDetails', path: 'movement.selectedAnimalDetails' },
    { name: 'movementAnimalsBeingMoved', path: 'movement.selectedAnimalDetails.animalsBeingMoved' },
    { name: 'movementBatchList', path: 'movement.selectedAnimalDetails.batchList' },
    { name: 'movementBreed', path: 'movement.selectedAnimalDetails.breed' },
    { name: 'movementCommentAnimalDetails', path: 'movement.selectedAnimalDetails.reasonOrComment' },
    { name: 'movementGender', path: 'movement.selectedAnimalDetails.gender' },
    { name: 'movementRecordAnimalDetails', path: 'movement.selectedAnimalDetails.recordAnimalDetails' },
    { name: 'movementRecordTagAllocationDetails', path: 'movement.selectedAnimalDetails.recordTagAllocationDetails' },
    { name: 'movementTagApplicationDate', path: 'movement.selectedAnimalDetails.tagApplicationDate' },
    { name: 'movementYearOfBirth', path: 'movement.selectedAnimalDetails.yearOfBirth' },
    { name: 'movementToHolding', path: 'movement.toHolding' },
    { name: 'movementTransportInformation', path: 'movement.transportInformation' },
    { name: 'movementAuthorisationNumber', path: 'movement.transportInformation.authorisationNumber' },
    { name: 'movementContactPhone', path: 'movement.transportInformation.contactPhone' },
    { name: 'movementDepartureTime', path: 'movement.transportInformation.departureTime' },
    { name: 'movementDriverFirstName', path: 'movement.transportInformation.driverFirstName' },
    { name: 'movementDriverLastName', path: 'movement.transportInformation.driverLastName' },
    { name: 'movementDurationOfJourney', path: 'movement.transportInformation.durationOfJourney' },
    { name: 'movementOwnerFirstName', path: 'movement.transportInformation.ownerFirstName' },
    { name: 'movementOwnerLastName', path: 'movement.transportInformation.ownerLastName' },
    { name: 'movementTransportedBy', path: 'movement.transportInformation.transportedBy' },
    { name: 'movementTransporter', path: 'movement.transportInformation.transporter' },
    { name: 'movementRecordTransportInformation', path: 'movement.transportInformation.recordTransportInformation' },
    { name: 'movementVehicleRegistrationNumber', path: 'movement.transportInformation.vehicleRegistrationNumber' },
    { name: 'movementNotification', path: 'movementNotification' },
    { name: 'movementNotificationArrivalDate', path: 'movementNotification.arrivalDate' },
    { name: 'movementNotificationAddedAnimals', path: 'movementNotification.addedAnimals' },
    { name: 'movementNotificationAddedBatches', path: 'movementNotification.addedBatches' },
    { name: 'movementNotificationRemovedAnimals', path: 'movementNotification.removedAnimals' },
    { name: 'movementNotificationRemovedBatches', path: 'movementNotification.removedBatches' },
    { name: 'original', path: 'original', isRemoveAll: true, sessionRemove: true },
    { name: 'originalMovement', path: 'original.movement' },
    { name: 'originalMovementExemptions', path: 'original.movement.exemptions' },
    { name: 'permissions', path: 'permissions', sessionRemove: true },
    { name: 'restrictBackFlow', path: 'restrictBackFlow', sessionRemove: true },
    { name: 'restrictSummaryBack', path: 'restrictSummaryBack', sessionRemove: true },
    { name: 'vetCPHData', path: 'vetCPHData', sessionRemove: true },
    { name: 'abattoirCPHData', path: 'abattoirCPHData', sessionRemove: true },
    { name: 'abattoirTagsData', path: 'abattoirTagsData', sessionRemove: true },
    { name: 'vetClaims', path: 'vetClaims', sessionRemove: true },
    { name: 'profileInfo', path: 'profileInfo' },
    { name: 'requestTypes', path: 'requestTypes', sessionRemove: true },
    { name: 'request', path: 'prevRequest', sessionRemove: true },
    { name: 'resource', path: 'resource' },
    { name: 'searchResults', path: 'searchResults', isRemoveAll: true, sessionRemove: true },
    { name: 'searchResultsAnimalActivity', path: 'searchResults.animalActivity' },
    { name: 'searchResultsAnimalsOnHolding', path: 'searchResults.animalsOnHolding' },
    { name: 'searchResultsMovements', path: 'searchResults.movements' },
    { name: 'searchResultsGatheringSearch', path: 'searchResults.gatheringSearch' },
    { name: 'searchResultsHoldingActivity', path: 'searchResults.holdingActivity' },
    { name: 'searchResultsHoldingActivityAdjacentMovements', path: 'searchResults.holdingActivity.adjacentMovements' },
    { name: 'searchResultsHoldingRegister', path: 'searchResults.holdingRegister' },
    { name: 'searchResultsHoldingRegisterAdjacentMovements', path: 'searchResults.holdingRegister.adjacentMovements' },
    { name: 'searchResultsHoldingRegisterAnnualInventory', path: 'searchResults.holdingRegister.annualInventory' },
    { name: 'searchResultsHoldingRegisterDeaths', path: 'searchResults.holdingRegister.deaths' },
    { name: 'searchResultsHoldingRegisterMovementsOff', path: 'searchResults.holdingRegister.movementsOff' },
    { name: 'searchResultsHoldingRegisterMovementsOn', path: 'searchResults.holdingRegister.movementsOn' },
    { name: 'searchResultsHoldingRegisterTagApplications', path: 'searchResults.holdingRegister.taggingApplications' },
    { name: 'searchResultsHoldingRegisterTagReplacements', path: 'searchResults.holdingRegister.taggingReplacements' },
    { name: 'searchResultsHoldingRegisterVaRecords', path: 'searchResults.holdingRegister.vaRecords' },
    { name: 'searchResultsUserActivity', path: 'searchResults.userActivity' },
    { name: 'species', path: 'species', sessionRemove: true },
    { name: 'tableFilters', path: 'tableFilters', isRemoveAll: true, sessionRemove: true },
    { name: 'tableFiltersAnimalActivity', path: 'tableFilters.animalActivity', defaultValue: {} },
    { name: 'tableFiltersAnimalActivityPageIndex', path: 'tableFilters.animalActivity.pageIndex' },
    { name: 'tableFiltersAnimalActivityPageSize', path: 'tableFilters.animalActivity.pageSize' },
    { name: 'tableFiltersAnimalActivitySortBy', path: 'tableFilters.animalActivity.sortBy' },
    { name: 'tableFiltersAnimalActivitySortDirection', path: 'tableFilters.animalActivity.sortDirection' },
    { name: 'tableFiltersAnimalActivityMovementHistory', path: 'tableFilters.animalActivity.movementHistory', defaultValue: {} },
    { name: 'tableFiltersAnimalActivityMovementHistoryPageIndex', path: 'tableFilters.animalActivity.movementHistory.pageIndex' },
    { name: 'tableFiltersAnimalActivityMovementHistoryPageSize', path: 'tableFilters.animalActivity.movementHistory.pageSize' },
    { name: 'tableFiltersAnimalActivityMovementHistorySortBy', path: 'tableFilters.animalActivity.movementHistory.sortBy' },
    { name: 'tableFiltersAnimalActivityMovementHistorySortDirection', path: 'tableFilters.animalActivity.movementHistory.sortDirection' },
    { name: 'tableFiltersAnimalActivityProgeny', path: 'tableFilters.animalActivity.progeny', defaultValue: {} },
    { name: 'tableFiltersAnimalActivityProgenyPageIndex', path: 'tableFilters.animalActivity.progeny.pageIndex' },
    { name: 'tableFiltersAnimalActivityProgenyPageSize', path: 'tableFilters.animalActivity.progeny.pageSize' },
    { name: 'tableFiltersAnimalActivityProgenySortBy', path: 'tableFilters.animalActivity.progeny.sortBy' },
    { name: 'tableFiltersAnimalActivityProgenySortDirection', path: 'tableFilters.animalActivity.progeny.sortDirection' },
    { name: 'tableFiltersAnimalActivityTaggingHistory', path: 'tableFilters.animalActivity.taggingHistory', defaultValue: {} },
    { name: 'tableFiltersAnimalActivityTaggingHistoryPageIndex', path: 'tableFilters.animalActivity.taggingHistory.pageIndex' },
    { name: 'tableFiltersAnimalActivityTaggingHistoryPageSize', path: 'tableFilters.animalActivity.taggingHistory.pageSize' },
    { name: 'tableFiltersAnimalActivityTaggingHistorySortBy', path: 'tableFilters.animalActivity.taggingHistory.sortBy' },
    { name: 'tableFiltersAnimalActivityTaggingHistorySortDirection', path: 'tableFilters.animalActivity.taggingHistory.sortDirection' },
    { name: 'tableFiltersAnimalsOnHolding', path: 'tableFilters.animalsOnHolding', defaultValue: {} },
    { name: 'tableFiltersAnimalsOnHoldingPageIndex', path: 'tableFilters.animalsOnHolding.pageIndex' },
    { name: 'tableFiltersAnimalsOnHoldingPageSize', path: 'tableFilters.animalsOnHolding.pageSize' },
    { name: 'tableFiltersAnimalsOnHoldingSortBy', path: 'tableFilters.animalsOnHolding.sortBy' },
    { name: 'tableFiltersAnimalsOnHoldingSortDirection', path: 'tableFilters.animalsOnHolding.sortDirection' },
    { name: 'tableFiltersAnimalsSelected', path: 'tableFilters.animalsSelected' },
    { name: 'tableFiltersGatheringSearch', path: 'tableFilters.gatheringSearch', defaultValue: {} },
    { name: 'tableFiltersGatheringSearchPageIndex', path: 'tableFilters.gatheringSearch.pageIndex' },
    { name: 'tableFiltersGatheringSearchPageSize', path: 'tableFilters.gatheringSearch.pageSize' },
    { name: 'tableFiltersGatheringSearchSortBy', path: 'tableFilters.gatheringSearch.sortBy' },
    { name: 'tableFiltersGatheringSearchSortDirection', path: 'tableFilters.gatheringSearch.sortDirection' },
    { name: 'tableFiltersHoldingActivity', path: 'tableFilters.filtersHoldingActivity', defaultValue: {} },
    { name: 'tableFiltersHoldingActivityPageIndex', path: 'tableFilters.filtersHoldingActivity.pageIndex' },
    { name: 'tableFiltersHoldingActivityPageSize', path: 'tableFilters.filtersHoldingActivity.pageSize' },
    { name: 'tableFiltersHoldingActivitySortBy', path: 'tableFilters.filtersHoldingActivity.sortBy' },
    { name: 'tableFiltersHoldingActivitySortDirection', path: 'tableFilters.filtersHoldingActivity.sortDirection' },
    { name: 'tableFiltersHoldingSummary', path: 'tableFilters.holdingSummary', defaultValue: {} },
    { name: 'tableFiltersHoldingSummaryPageIndex', path: 'tableFilters.holdingSummary.pageIndex' },
    { name: 'tableFiltersHoldingSummaryPageSize', path: 'tableFilters.holdingSummary.pageSize' },
    { name: 'tableFiltersHoldingSummarySortBy', path: 'tableFilters.holdingSummary.sortBy' },
    { name: 'tableFiltersHoldingSummarySortDirection', path: 'tableFilters.holdingSummary.sortDirection' },
    { name: 'tableFiltersHoldingRegisterAdjacentMovements', path: 'tableFilters.holdingRegister.adjacentMovements', defaultValue: {} },
    { name: 'tableFiltersHoldingRegisterAdjacentMovementsPageIndex', path: 'tableFilters.holdingRegister.adjacentMovements.pageIndex' },
    { name: 'tableFiltersHoldingRegisterAdjacentMovementsPageSize', path: 'tableFilters.holdingRegister.adjacentMovements.pageSize' },
    { name: 'tableFiltersHoldingRegisterAdjacentMovementsSortBy', path: 'tableFilters.holdingRegister.adjacentMovements.sortBy' },
    { name: 'tableFiltersHoldingRegisterAdjacentMovementsSortDirection', path: 'tableFilters.holdingRegister.adjacentMovements.sortDirection' },
    { name: 'tableFiltersHoldingRegisterAnnualInventory', path: 'tableFilters.holdingRegister.annualInventory', defaultValue: {} },
    { name: 'tableFiltersHoldingRegisterAnnualInventoryPageIndex', path: 'tableFilters.holdingRegister.annualInventory.pageIndex' },
    { name: 'tableFiltersHoldingRegisterAnnualInventoryPageSize', path: 'tableFilters.holdingRegister.annualInventory.pageSize' },
    { name: 'tableFiltersHoldingRegisterAnnualInventorySortBy', path: 'tableFilters.holdingRegister.annualInventory.sortBy' },
    { name: 'tableFiltersHoldingRegisterAnnualInventorySortDirection', path: 'tableFilters.holdingRegister.annualInventory.sortDirection' },
    { name: 'tableFiltersHoldingRegisterDeaths', path: 'tableFilters.holdingRegister.deaths', defaultValue: {} },
    { name: 'tableFiltersHoldingRegisterDeathsPageIndex', path: 'tableFilters.holdingRegister.deaths.pageIndex' },
    { name: 'tableFiltersHoldingRegisterDeathsPageSize', path: 'tableFilters.holdingRegister.deaths.pageSize' },
    { name: 'tableFiltersHoldingRegisterDeathsSortBy', path: 'tableFilters.holdingRegister.deaths.sortBy' },
    { name: 'tableFiltersHoldingRegisterDeathsSortDirection', path: 'tableFilters.holdingRegister.deaths.sortDirection' },
    { name: 'tableFiltersHoldingRegisterDownload', path: 'tableFilters.holdingRegister.download', defaultValue: {} },
    { name: 'tableFiltersHoldingRegisterDownloadPageIndex', path: 'tableFilters.holdingRegister.download.pageIndex' },
    { name: 'tableFiltersHoldingRegisterDownloadPageSize', path: 'tableFilters.holdingRegister.download.pageSize' },
    { name: 'tableFiltersHoldingRegisterDownloadSortBy', path: 'tableFilters.holdingRegister.download.sortBy' },
    { name: 'tableFiltersHoldingRegisterDownloadSortDirection', path: 'tableFilters.holdingRegister.download.sortDirection' },
    { name: 'tableFiltersHoldingRegisterMovementsOff', path: 'tableFilters.holdingRegister.movementsOff', defaultValue: {} },
    { name: 'tableFiltersHoldingRegisterMovementsOffPageIndex', path: 'tableFilters.holdingRegister.movementsOff.pageIndex' },
    { name: 'tableFiltersHoldingRegisterMovementsOffPageSize', path: 'tableFilters.holdingRegister.movementsOff.pageSize' },
    { name: 'tableFiltersHoldingRegisterMovementsOffSortBy', path: 'tableFilters.holdingRegister.movementsOff.sortBy' },
    { name: 'tableFiltersHoldingRegisterMovementsOffSortDirection', path: 'tableFilters.holdingRegister.movementsOff.sortDirection' },
    { name: 'tableFiltersHoldingRegisterMovementsOn', path: 'tableFilters.holdingRegister.movementsOn', defaultValue: {} },
    { name: 'tableFiltersHoldingRegisterMovementsOnPageIndex', path: 'tableFilters.holdingRegister.movementsOn.pageIndex' },
    { name: 'tableFiltersHoldingRegisterMovementsOnPageSize', path: 'tableFilters.holdingRegister.movementsOn.pageSize' },
    { name: 'tableFiltersHoldingRegisterMovementsOnSortBy', path: 'tableFilters.holdingRegister.movementsOn.sortBy' },
    { name: 'tableFiltersHoldingRegisterMovementsOnSortDirection', path: 'tableFilters.holdingRegister.movementsOn.sortDirection' },
    { name: 'tableFiltersHoldingRegisterTagApplications', path: 'tableFilters.holdingRegister.taggingApplications', defaultValue: {} },
    { name: 'tableFiltersHoldingRegisterTagApplicationsPageIndex', path: 'tableFilters.holdingRegister.taggingApplications.pageIndex' },
    { name: 'tableFiltersHoldingRegisterTagApplicationsPageSize', path: 'tableFilters.holdingRegister.taggingApplications.pageSize' },
    { name: 'tableFiltersHoldingRegisterTagApplicationsSortBy', path: 'tableFilters.holdingRegister.taggingApplications.sortBy' },
    { name: 'tableFiltersHoldingRegisterTagApplicationsSortDirection', path: 'tableFilters.holdingRegister.taggingApplications.sortDirection' },
    { name: 'tableFiltersHoldingRegisterTagReplacements', path: 'tableFilters.holdingRegister.taggingReplacements', defaultValue: {} },
    { name: 'tableFiltersHoldingRegisterTagReplacementsPageIndex', path: 'tableFilters.holdingRegister.taggingReplacements.pageIndex' },
    { name: 'tableFiltersHoldingRegisterTagReplacementsPageSize', path: 'tableFilters.holdingRegister.taggingReplacements.pageSize' },
    { name: 'tableFiltersHoldingRegisterTagReplacementsSortBy', path: 'tableFilters.holdingRegister.taggingReplacements.sortBy' },
    { name: 'tableFiltersHoldingRegisterTagReplacementsSortDirection', path: 'tableFilters.holdingRegister.taggingReplacements.sortDirection' },
    { name: 'tableFiltersMovements', path: 'tableFilters.movements', defaultValue: {} },
    { name: 'tableFiltersMovementsPageIndex', path: 'tableFilters.movements.pageIndex' },
    { name: 'tableFiltersMovementsPageSize', path: 'tableFilters.movements.pageSize' },
    { name: 'tableFiltersMovementsSortBy', path: 'tableFilters.movements.sortBy' },
    { name: 'tableFiltersMovementsSortDirection', path: 'tableFilters.movements.sortDirection' },
    { name: 'tableFiltersRequestId', path: 'tableFilters.requestId', defaultValue: {} },
    { name: 'tableFiltersRequestIdPageIndex', path: 'tableFilters.requestId.pageIndex' },
    { name: 'tableFiltersRequestIdPageSize', path: 'tableFilters.requestId.pageSize' },
    { name: 'tableFiltersRequestIdSortBy', path: 'tableFilters.requestId.sortBy' },
    { name: 'tableFiltersRequestIdSortDirection', path: 'tableFilters.requestId.sortDirection' },
    { name: 'tableFiltersUser', path: 'tableFilters.user' },
    { name: 'tableFiltersUserActivity', path: 'tableFilters.userActivity', defaultValue: {} },
    { name: 'tableFiltersUserActivityPageIndex', path: 'tableFilters.userActivity.pageIndex' },
    { name: 'tableFiltersUserActivityPageSize', path: 'tableFilters.userActivity.pageSize' },
    { name: 'tableFiltersUserActivitySortBy', path: 'tableFilters.userActivity.sortBy' },
    { name: 'tableFiltersUserActivitySortDirection', path: 'tableFilters.userActivity.sortDirection' },
    { name: 'tagApplication', path: 'tagApplication', isRemoveAll: true, sessionRemove: true },
    { name: 'tagApplicationAnimalsToChange', path: 'tagApplication.animalsToChange' },
    { name: 'tagApplicationDate', path: 'tagApplication.applicationDate' },
    { name: 'tagApplicationBatchNumber', path: 'tagApplication.batchNumber' },
    { name: 'tagApplicationBirthDamId', path: 'tagApplication.birthDamId' },
    { name: 'tagApplicationBirthDate', path: 'tagApplication.birthDate' },
    { name: 'tagApplicationBreed', path: 'tagApplication.breed' },
    { name: 'tagApplicationComment', path: 'tagApplication.referenceOrComment' },
    { name: 'tagApplicationEmbryo', path: 'tagApplication.embryo' },
    { name: 'tagApplicationGender', path: 'tagApplication.gender' },
    { name: 'tagApplicationGeneticDamAI', path: 'tagApplication.geneticDamAI' },
    { name: 'tagApplicationGeneticDamId', path: 'tagApplication.geneticDamId' },
    { name: 'tagApplicationGenotype', path: 'tagApplication.genotype' },
    { name: 'tagApplicationMonthOfBirth', path: 'tagApplication.monthOfBirth' },
    { name: 'tagApplicationSelectionMethod', path: 'tagApplication.selectionMethod' },
    { name: 'tagApplicationSireId', path: 'tagApplication.sireId' },
    { name: 'tagApplicationTagNumber', path: 'tagApplication.tagNumber' },
    { name: 'tagApplicationTagType', path: 'tagApplication.tagType' },
    { name: 'tagApplicationYearOfBirth', path: 'tagApplication.yearOfBirth' },
    { name: 'tagReplacement', path: 'tagReplacement', isRemoveAll: true, sessionRemove: true },
    { name: 'tagReplacementAnimalsToChange', path: 'tagReplacement.animalsToChange' },
    { name: 'tagReplacementComment', path: 'tagReplacement.comment' },
    { name: 'tagReplacementNewBatchNumber', path: 'tagReplacement.newBatchNumber' },
    { name: 'tagReplacementNewIndividualNumber', path: 'tagReplacement.newIndividualNumber' },
    { name: 'tagReplacementNewTagNumber', path: 'tagReplacement.newTagNumber' },
    { name: 'tagReplacementOldBatchNumber', path: 'tagReplacement.oldBatchNumber' },
    { name: 'tagReplacementOldTagNumber', path: 'tagReplacement.oldTagNumber' },
    { name: 'tagReplacementQuantity', path: 'tagReplacement.quantity' },
    { name: 'tagReplacementDate', path: 'tagReplacement.replacementDate' },
    { name: 'tagReplacementSelectionMethod', path: 'tagReplacement.selectionMethod' },
    { name: 'tagReplacementTagType', path: 'tagReplacement.tagType' },
    { name: 'tableFiltersVetAttestation', path: 'tableFilters.vetAttestation', defaultValue: {} },
    { name: 'tableFiltersVetAttestationPageIndex', path: 'tableFilters.vetAttestation.pageIndex' },
    { name: 'tableFiltersVetAttestationPageSize', path: 'tableFilters.vetAttestation.pageSize' },
    { name: 'tableFiltersVetAttestationSortBy', path: 'tableFilters.vetAttestation.sortBy' },
    { name: 'tableFiltersVetAttestationSortDirection', path: 'tableFilters.vetAttestation.sortDirection' },
    { name: 'abattoirTagDetails', path: 'abattoirTagDetails', sessionRemove: true },
    { name: 'abattoirSelectedTagDetails', path: 'abattoirSelectedTagDetails' },
    { name: 'tableFiltersAbattoirTags', path: 'tableFilters.abattoirTags', defaultValue: {} },
    { name: 'tableFiltersAbattoirTagsPageIndex', path: 'tableFilters.abattoirTags.pageIndex' },
    { name: 'tableFiltersAbattoirTagsPageSize', path: 'tableFilters.abattoirTags.pageSize' },
    { name: 'tableFiltersAbattoirTagsSortBy', path: 'tableFilters.abattoirTags.sortBy' },
    { name: 'tableFiltersAbattoirTagsSortDirection', path: 'tableFilters.abattoirTags.sortDirection' }
  ]
};

const getDeepValue = (obj, pathArr) => {
  for (let i = 0; i < pathArr.length; i++) {
    obj = obj[pathArr[i]];
  }
  return obj;
};

const iterate = (arr, type) => {
  if (!storeService[type].get) {
    storeService[type].get = {};
  }
  if (!storeService[type].set) {
    storeService[type].set = {};
  }
  if (!storeService[type].remove) {
    storeService[type].remove = {};
  }

  arr.forEach((arrItem) => {
    if (type === 'cookie') {
      storeService[type].get[arrItem.name] = () => item.get(type, arrItem.path);
      storeService[type].set[arrItem.name] = (value, options) => item.set(type, arrItem, value, options);
      storeService[type].remove[arrItem.name] = () => item.remove(type, arrItem);
    } else {
      storeService[type].get[arrItem.name] = () => item.get(type, arrItem.path, arrItem.defaultValue);
      storeService[type].set[arrItem.name] = (param, index) => item.set(type, arrItem, param, index);
      storeService[type].remove[arrItem.name] = () => item.remove(type, arrItem);

      if (arrItem.isRemoveAll) {
        storeService[type].removeAll[arrItem.name] = () => item.remove(type, arrItem);
      }
    }
  });
};

const item = {
  get: (type, path, defaultValue) => {
    if (type === 'cookie') {
      return Cookie.get(path);
    }

    const pathArray = path.split('.');
    const topLevelKey = pathArray.shift();
    const topLevelValue = window[storageType[type]].getItem(topLevelKey);

    if (pathArray.length === 0) {
      try {
        return JSON.parse(topLevelValue);
      } catch (e) {
        return topLevelValue;
      }
    } else {
      try {
        return get(JSON.parse(topLevelValue), pathArray, defaultValue ? defaultValue : null);
      } catch (e) {
        return topLevelValue;
      }
    }
  },

  set: (type, arrItem, value, options) => {
    if (type === 'cookie') {
      Cookie.set(arrItem.path, value, options);
      return true;
    }

    // For local and session "options" is an index
    const pathArray = arrItem.path.split('.');
    const topLevelKey = pathArray.shift();
    let topLevelValue;

    if (pathArray.length === 0 && options === undefined) {
      if (typeof value === 'object') {
        topLevelValue = JSON.stringify(value);
      } else {
        topLevelValue = value;
      }
    } else {
      try {
        topLevelValue = JSON.parse(window[storageType[type]].getItem(topLevelKey));
      } catch (e) {
        topLevelValue = window[storageType[type]].getItem(topLevelKey);
      }

      if (options !== undefined) {
        pathArray.push(options);
      }

      topLevelValue = set({ ...topLevelValue }, pathArray, value);
      topLevelValue = JSON.stringify(topLevelValue);
    }

    window[storageType[type]].setItem(topLevelKey, topLevelValue);
  },

  remove: (type, arrItem) => {
    if (type === 'cookie') {
      return Cookie.remove(arrItem.path);
    }

    const pathArray = arrItem.path.split('.');
    const topLevelKey = [...pathArray].shift();

    let topLevelValue;

    if (pathArray.length === 1) {
      window[storageType[type]].removeItem(arrItem.path);
    } else {
      let tempPathArray;

      try {
        topLevelValue = JSON.parse(window[storageType[type]].getItem(topLevelKey));
      } catch (e) {
        topLevelValue = window[storageType[type]].getItem(topLevelKey);
      }

      tempPathArray = [...pathArray];
      tempPathArray.shift();
      const bottomLevelKey = tempPathArray.pop();
      const deepValue = getDeepValue(topLevelValue, tempPathArray);
      if (deepValue && deepValue[bottomLevelKey] !== null && deepValue[bottomLevelKey] !== undefined) {
        delete deepValue[bottomLevelKey];
      }

      tempPathArray = [...pathArray];
      tempPathArray.pop();
      const newPath = tempPathArray.join('.');

      item.set(type, { ...arrItem, path: newPath }, deepValue);
    }
  }
};

const storeService = {
  cookie: {
    removeAll: {
      all: () => {
        Cookie.remove(PATHS.cookie.animalTypes);
        Cookie.remove(PATHS.cookie.bearer);
        Cookie.remove(PATHS.cookie.cph);
        Cookie.remove(PATHS.cookie.jwt);
        Cookie.remove(PATHS.cookie.species);
        Cookie.remove(PATHS.cookie.username);
        Cookie.remove(PATHS.cookie.xOnBehalfOf);
      },

      msal: () => {
        Cookie.remove(PATHS.cookie.msalStatus);
      }
    }
  },

  local: {
    removeAll: {
      all: () => {
        // remove all localStorages except recentlyUsed
        for (let i = 0; i < window[storageType.local].length; i++) {
          const savedLocalStorage = {
            path: window[storageType.local].key(i)
          };
          const keyHasDots = savedLocalStorage.path.includes('.');

          if (savedLocalStorage.path !== 'recentlyUsed' && !keyHasDots) {
            item.remove('local', savedLocalStorage);
          } else if (keyHasDots) {
            window[storageType.local].removeItem(savedLocalStorage);
          }
        }
      }
    }
  },

  session: {
    removeAll: {
      all: () => {
        PATHS.session.filter((arrItem) => arrItem.sessionRemove).forEach((arrItem) => {
          item.remove('session', arrItem);
        });
      }
    }
  }
};

Object.keys(PATHS).forEach((key) => iterate(PATHS[key], key));

export default storeService;
