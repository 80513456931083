import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isCancel } from 'services/bff';
import PropTypes from 'prop-types';
import classes from 'components/manualTagEntry/manualTagEntry.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import Button from 'components/base/button/button';
import Legend from 'components/base/legend/legend';
import TextEntry from 'components/base/textEntry/textEntry';

const ManualTagEntry = ({
  addAnimals,
  addDisabled,
  animalsToChange,
  breed,
  description,
  dob,
  genderName,
  id,
  notes,
  pending,
  resetForm,
  setModal,
  setPending,
  species,
  title,
  tooltip,
  type
}) => {
  const { ready, t } = useTranslation();

  const [textFieldTags, setTextFieldTags] = useState('');

  const submitManual = () => {
    setPending(true);

    helpers.validate.tagNumbers(species.name, [], [...new Set(textFieldTags.trim().replace(/\s\s+/g, ' ').split(/[\n,]+/))])
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          setTextFieldTags('');
          setPending(false);

          const newTags = [];

          res.data.tagNumbers.forEach((tag) => {
            const newTagNumber = tag.formattedDevice.tagNumber ? tag.formattedDevice.tagNumber : tag.originalIdentifier;

            if (!animalsToChange.find((animal) => animal.tagNumber === newTagNumber)) {
              if (type === constants.option.movement.newMovement && (!animalsToChange || animalsToChange.length === 0 || !animalsToChange.some((animal) => animal.tagNumber === newTagNumber))) {
                newTags.push({
                  tagNumber: newTagNumber,
                  animalTotal: 1,
                  available: true,
                  valid: tag.state,
                  breedId: breed,
                  genderName,
                  dob
                });
              } else if (type === constants.option.movement.reviewMovement) {
                newTags.push({
                  beginBirthPeriod: null,
                  breed: null,
                  endBirthPeriod: null,
                  freezeBrand: null,
                  rfid: null,
                  animalTotal: 1,
                  tagNumber: newTagNumber,
                  valid: tag.state
                });
              }
            }
          });

          addAnimals(newTags);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setPending(false);
          errors.BFF(error, setModal);
        }
      });
  };

  useEffect(() => {
    setTextFieldTags('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);

  return (
    <>
      {ready &&
        <fieldset role="group">
          <Legend
            id={id}
            legend={title}
            tooltip={tooltip}
            tooltipPosition="bottom"
          />
          {description &&
            <p>{t(description)}</p>
          }

          <div className={classes.manualTagEntry}>
            <TextEntry
              disabled={pending}
              id="addIndividualAnimal"
              label="addIndividualAnimal.copyAndPasteHint"
              name="addIndividualAnimal"
              onChange={(event) => setTextFieldTags(event.target.value)}
              rows={config.ROWS_TEXTAREA}
              value={textFieldTags}
            />
            <div className={classes.notes}>
              {notes && <div> {notes}</div> }
            </div>
            <Button
              buttonType="secondary"
              disabled={pending || !textFieldTags || addDisabled}
              id={id + 'Add'}
              label="button.add"
              onClick={submitManual}
            />
          </div>
        </fieldset>
      }
    </>
  );
};

ManualTagEntry.propTypes = {
  addAnimals: PropTypes.func,
  addDisabled: PropTypes.bool,
  animalsToChange: PropTypes.array,
  breed: PropTypes.string,
  description: PropTypes.string,
  dob: PropTypes.string,
  genderName: PropTypes.string,
  id: PropTypes.string,
  notes: PropTypes.string,
  pending: PropTypes.bool,
  resetForm: PropTypes.bool,
  setModal: PropTypes.func.isRequired,
  setPending: PropTypes.func,
  species: PropTypes.object,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string
};

export default ManualTagEntry;
