import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/header/navBar/navBar.module.scss';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';

const NavBar = ({
  menuOpen,
  resetErrorBoundaries,
  setMenu
}) => {
  const { ready, t } = useTranslation();

  const menuItems = [
    {
      id: 'movements',
      icon: 'bi bi-arrows-move',
      linkTo: Routing.boMovements
    },
    {
      id: 'search_movements',
      icon: 'bi bi-search',
      linkTo: Routing.boSearchMovements,
      onClick: () => {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
        storeService.session.remove.activeTabId();
      }
    },
    {
      id: 'search_request_id',
      icon: 'bi bi-search',
      linkTo: Routing.boSearchRequestId,
      onClick: () => {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
      }
    },
    {
      id: 'holding_activity',
      icon: 'bi bi-search',
      linkTo: Routing.boHoldingActivity,
      onClick: () => {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
      }
    },
    {
      id: 'user_activity',
      icon: 'bi bi-search',
      linkTo: Routing.boUserActivity,
      onClick: () => {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
      }
    },
    {
      id: 'animal_activity',
      icon: 'bi bi-search',
      linkTo: Routing.boAnimalActivity,
      onClick: () => {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
      }
    },
    {
      id: 'verify_customers',
      icon: 'bi bi-person-check-fill',
      linkTo: Routing.boVerifyCustomer
    },
    {
      id: 'change_password',
      icon: 'bi bi-pencil-fill',
      linkTo: Routing.changePassword
    },
    {
      id: 'logout',
      icon: 'bi bi-power',
      linkTo: Routing.logout
    }
  ];

  const toggleMenu = () => {
    setMenu(!menuOpen);
  };

  return (
    <>
      {ready &&
        <div className={menuOpen ? classes.navBar + ' ' + classes.closed : classes.navBar}>
          <div className={classes.logo} />
          <div className={classes.toggleMenu} id="navBarToggleMenu" onClick={toggleMenu}>
            <div className={classes.bar} />
            <div className={classes.bar} />
            <div className={classes.bar} />
          </div>
          <div className={classes.closeIcon} onClick={toggleMenu}>
            <i className="bi bi-x-lg" />
          </div>
          <nav className={classes.navMenu}>
            <ul>
              {menuItems.map((menuItem, menuItemKey) =>
                <li className={classes[menuItem.id]} key={menuItemKey}>
                  <NavLink
                    activeClassName={classes.active}
                    onClick={() => {
                      if (menuItem.onClick && typeof menuItem.onClick === 'function') {
                        menuItem.onClick();
                      }
                      resetErrorBoundaries();
                    }}
                    to={menuItem.linkTo}
                  >
                    <i className={menuItem.icon} />
                    <span>{t('boApp.navMenu.' + menuItem.id)}</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
        </div>
      }
    </>
  );
};

NavBar.propTypes = {
  menuOpen: PropTypes.bool,
  resetErrorBoundaries: PropTypes.func,
  setMenu: PropTypes.func
};

export default NavBar;
