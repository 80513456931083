import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar.module.scss';
import { bffStatus } from 'services/bff';

const SectionTitleBar = ({
  icon,
  id,
  status,
  title
}) => {
  const { ready, t } = useTranslation();

  return (
    <>
      {ready &&
        <div className={classes.sectionTitleBar} id={id}>
          {icon &&
            <div className={classes.iconContainer}>
              <i className={classes.icon + ' ' + icon} />
            </div>
          }
          <h2 className={classes.title}>{t(title)}</h2>
          {status &&
            <>
              <div className={bffStatus.isCompletedError(status.className) ? classes.CompletedError : classes.CompletedWarning}>{t(status.label)}</div>
              <div className={bffStatus.isCompletedError(status.className) ? classes.statusErrorHint : classes.statusWarningHint}>{t(status.hint)}</div>
            </>
          }
        </div>
      }
    </>
  );
};

SectionTitleBar.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  status: PropTypes.exact({
    className: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string
  }),
  title: PropTypes.string
};

export default SectionTitleBar;
