import storeService from 'services/storeService';
import bff, { isCancel } from 'services/bff';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';

let claConfig = null;

const configHelper = {
  fetchConfig: (setModal, setLoading) => {
    bff
      .get('/config')
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          const config = {};

          res.data.data.forEach((item) => {
            if (!config[item.speciesId]) {
              config[item.speciesId] = [];
            }

            config[item.speciesId].push(item);
          });

          Object.keys(config).forEach((speciesId) => helpers.sortArrayOfObjects(config[speciesId], 'requestTypeId', constants.sorting.ascending));
          claConfig = config;

          storeService.session.set.config(config);
        }

        setLoading((prevState) => ({
          ...prevState,
          config: false
        }));
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });
  },

  findValue: (speciesId, ruleKeyFunc, requestTypeIdFunc) => {
    const config = configHelper.get.config();

    if (speciesId && typeof speciesId === 'number' &&
      ruleKeyFunc && typeof ruleKeyFunc === 'function' &&
      requestTypeIdFunc && typeof requestTypeIdFunc === 'function' &&
      config && config[speciesId]) {
      const matched = config[speciesId].filter((item) =>
        ruleKeyFunc(item.ruleKey) && requestTypeIdFunc(item.requestTypeId)
      );
      if (matched?.length > 0) {
        return parseInt(matched[0].ruleValue);
      }
    }
    return 0;
  },

  get: {
    config: () => {
      if (!claConfig) {
        claConfig = storeService.session.get.config();
      }
      return claConfig;
    },

    offMoveMaxDays: (speciesId) => configHelper.findValue(
      speciesId,
      helpers.config.isOffMoveMaxDays,
      helpers.option.requestType.isMovement
    ),

    onMoveMaxDays: (speciesId) => configHelper.findValue(
      speciesId,
      helpers.config.isOnMoveMaxDays,
      helpers.option.requestType.isMovement
    )
  }
};

export default configHelper;
