import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/modal/modal.module.scss';
import bff, { isCancel } from 'services/bff';
import helpers from 'services/helpers';
import errors from 'services/errors';
import constants from 'services/constants';
import axios from 'axios';
import storeService from 'services/storeService';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import CPHField from 'components/base/cphField/cphField';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';
import InputDate from 'components/base/inputDate/inputDate';
const sprintf = require('util').format;

const ModalAdjacentMovements = ({
  modalClose,
  modalData,
  modalMode,
  setConfirmAction,
  setDeleteData,
  setModal,
  setModalHasChanges,
  setModalSubmitted
}) => {
  const { ready, t } = useTranslation();

  const speciesId = storeService.session.get.species().id;
  const currentCph = storeService.session.get.holding().value;
  const holdings = storeService.session.get.holdings();

  const [adjacentHolding, setAdjacentHolding] = React.useState('');
  const [frequency, setFrequency] = React.useState(null);

  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);

  const [adjacentMovementId, setAdjacentMovementId] = React.useState(null);

  const [adjacentHoldingError, setAdjacentHoldingError] = React.useState('');
  const [fromDateError, setFromDateError] = React.useState('');
  const [toDateError, setToDateError] = useState('');

  const [holdingLinkTypeId, setHoldingLinkTypeId] = React.useState([]);
  const [frequencyList, setFrequencyList] = React.useState([]);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [action, setAction] = useState(null);
  const [pending, setPending] = useState(true);
  const [submitPending, setSubmitPending] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [submitError, setSubmitError] = useState('');
  const [disabled, setDisabled] = useState(true);

  const validate = {
    date: (which, date) => {
      let hasError = false;

      setFromDateError('');
      setToDateError('');

      if (helpers.flags.isFrom(which)) {
        const fromDateIsOver1DayInTheFuture = helpers.date.is.xDaysInTheFutureOverLimit(date, 1);
        const fromDateIsAfterToDate = helpers.date.is.afterDate(date, toDate);

        if (!hasError && fromDateIsOver1DayInTheFuture) {
          setFromDateError(fromDateIsOver1DayInTheFuture);

          hasError = true;
        }

        if (!hasError && fromDateIsAfterToDate) {
          setFromDateError('error.fromDateCannotBeAfterToDate');
        }
      } else if (helpers.flags.isTo(which)) {
        const toDateIsOver1DayInTheFuture = helpers.date.is.xDaysInTheFutureOverLimit(date, 1);
        const toDateIsBeforeFromDate = helpers.date.is.beforeDate(date, fromDate);

        if (!hasError && toDateIsOver1DayInTheFuture) {
          setToDateError(toDateIsOver1DayInTheFuture);

          hasError = true;
        }

        if (!hasError && toDateIsBeforeFromDate) {
          setToDateError('error.toDateCannotBeBeforeFromDate');
        }
      }
    }
  };

  const handleChanges = {
    adjacentHolding: (cph) => {
      setAdjacentHoldingError('');
      setAdjacentHolding(cph);
      setModalHasChanges(true);
    },

    date: (which, date) => {
      if (helpers.flags.isFrom(which)) {
        setFromDate(date);
      } else if (helpers.flags.isTo(which)) {
        setToDate(date);
      }
      validate.date(which, date);
      setModalHasChanges(true);
    },

    frequency: (frequency) => {
      setFrequency(frequency);

      const emptyFrequency = frequencyList.find((item) => item.label === '' && item.name === '' && item.value === '');

      if (emptyFrequency) {
        const options = [...frequencyList];
        setFrequencyList(options);
      }
      setModalHasChanges(true);
    }
  };

  const getDates = {
    fromMax: () => {
      const today = new Date();

      if (toDate) {
        return helpers.date.formatYYYYMMDD(toDate);
      }

      return helpers.date.formatYYYYMMDD(helpers.date.addPeriod(today, 1, constants.period.days));
    },

    toMin: () => {
      return fromDate;
    },

    toMax: () => {
      const today = new Date();

      return helpers.date.formatYYYYMMDD(helpers.date.addPeriod(today, 1, constants.period.days));
    }
  };

  const get = {
    confirmation: () => {
      switch (action) {
        case constants.modalActions.edit:
          return submitStatus === constants.status.error ? t('error.adjacentMovementUpdate') : t('label.adjacentMovementUpdated');
        case constants.modalActions.delete:
          return submitStatus === constants.status.error ? t('error.adjacentMovementDelete') : t('label.adjacentMovementDeleted');
        case constants.modalActions.add:
        default:
          return submitStatus === constants.status.error ? t('error.adjacentMovementSubmit') : t('label.adjacentMovementSubmitted');
      }
    },

    title: () => {
      switch (modalMode) {
        case constants.modalActions.edit:
          return t('label.updateAdjacentMovement');
        case constants.modalActions.view:
          return t('label.viewAdjacentMovement');
        case constants.modalActions.add:
        default:
          return t('label.recordAdjacentMovement');
      }
    }
  };

  const submitAdjacentMovement = () => {
    setSubmitPending(true);

    const params = {
      speciesId,
      holding: currentCph,
      adjacentHolding,
      startDate: fromDate,
      endDate: toDate,
      holdingLinkTypeId,
      holdingLinkTypeOptionId: frequency
    };

    if (Object.keys(holdings).includes(adjacentHolding)) {
      params.inBusiness = true;
    }

    if (adjacentMovementId) {
      setAction(constants.modalActions.edit);

      params.id = adjacentMovementId;
      params.fromDate = modalData.fromDate;
    } else {
      setAction(constants.modalActions.add);
    }

    bff
      .post('/adjacentMovement', params)
      .then(() => {
        storeService.session.removeAll.searchResults();

        setSubmitStatus(constants.status.success);
        setShowConfirmation(true);
        setSubmitPending(false);
        setModalHasChanges(false);
        setModalSubmitted(true);
      })
      .catch((error) => {
        const data = error.response && error.response.data ? error.response.data : undefined;
        let errorMessage = data && data.errorMessage ? data.errorMessage : data && data.message ? data.message : error;

        if (errorMessage.includes('already exists')) {
          errorMessage = sprintf(t('label.adjacentMovementExists'), currentCph, adjacentHolding, helpers.date.format(fromDate));
        }

        setSubmitStatus(constants.status.error);
        setSubmitError({
          code: error?.response?.status,
          message: errorMessage ? errorMessage : error?.response?.statusText
        });
        setModalHasChanges(false);
        setShowConfirmation(true);
        setSubmitPending(false);
      });
  };

  useEffect(() => {
    setDisabled(
      !(
        fromDate && !fromDateError &&
        (
          !toDate ||
          (toDate && !toDateError)
        ) &&
        frequency &&
        adjacentHolding && helpers.validate.cphNumber(adjacentHolding)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, fromDateError, toDate, toDateError, frequency, adjacentHolding]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    bff
      .get('/frequencyOptions', {
        cancelToken: source.token
      })
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          const options = res.data.map((item) => ({
            label: item.name,
            name: item.name,
            value: item.id
          }));

          setHoldingLinkTypeId(res.data[0].holdingLinkTypeId);
          setFrequencyList(options);

          setPending(false);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modalData) {
      setAdjacentMovementId(modalData.id);
      setFromDate(helpers.date.formatForSubmission(modalData.startDate));
      setAdjacentHolding(modalData.adjacentHolding);
      setFrequency(modalData?.holdingLinkTypeOption?.id ? modalData.holdingLinkTypeOption.id : modalData.holdingLinkTypeOptionId);

      const tempToDate = helpers.date.formatForSubmission(modalData.endDate);
      if (tempToDate !== '9999-12-31') {
        setToDate(tempToDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);

  return (
    <>
      {ready &&
        <>
          <div className={classes.modalBody}>
            {showConfirmation &&
              <Confirmation
                confirm={null}
                errors={submitError ? [submitError] : null}
                label={get.confirmation()}
                setModal={setModal}
                status={submitStatus} // Hardcoding this until we have polling for this section
              />
            }
            {!showConfirmation &&
              <>
                <h1 className="h1">{get.title()}</h1>
                <div className="row">
                  <div className="col-md-6">
                    <div className="section">
                      <InputDate
                        blockStyling={true}
                        disabled={submitPending || modalMode === constants.modalActions.view || modalMode === constants.modalActions.edit}
                        dontValidate={true}
                        error={fromDateError}
                        id="adjacentFromDate"
                        labelPosition={constants.labelPosition.above}
                        labelText="label.fromDate"
                        maxDate={getDates.fromMax()}
                        onChange={(event) => handleChanges.date(constants.flags.from, event.target.value)}
                        setError={setFromDateError}
                        value={fromDate}
                        yearLength={4}
                      />
                    </div>
                    <div className="section">
                      <CPHField
                        cph={adjacentHolding}
                        disabled={submitPending || modalMode === constants.modalActions.view || modalMode === constants.modalActions.edit}
                        error={adjacentHoldingError}
                        id="holdingCPH"
                        label="label.adjacentMoveCph"
                        onBlur={handleChanges.onBlur}
                        setCph={handleChanges.adjacentHolding}
                      />
                    </div>
                    <div className="section">
                      <DropdownSearch
                        disabled={submitPending || modalMode === constants.modalActions.view}
                        id="frequencyDropdown"
                        isSelect={false}
                        label="label.frequency"
                        name="frequency"
                        onChange={(event) => handleChanges.frequency(event.target.value)}
                        optionList={frequencyList}
                        pending={pending}
                        placeholder="prompt.pleaseSelect"
                        value={frequency}
                      />
                    </div>
                    <div className="section">
                      <InputDate
                        blockStyling={true}
                        disabled={submitPending || modalMode === constants.modalActions.view}
                        dontValidate={true}
                        error={toDateError}
                        id="adjacentToDate"
                        labelPosition={constants.labelPosition.above}
                        labelText="label.toDate"
                        maxDate={getDates.toMax()}
                        minDate={getDates.toMin()}
                        onChange={(event) => handleChanges.date(constants.flags.to, event.target.value)}
                        optional={true}
                        setError={setToDateError}
                        value={toDate}
                        yearLength={4}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <AboutPanel
                      descriptions={[
                        { text: 'about.adjacentMovement.desc1' },
                        { text: 'about.adjacentMovement.desc2' },
                        { text: 'about.adjacentMovement.desc3' },
                        { text: 'about.adjacentMovement.desc4', href: constants.url.defraGuidance }
                      ]}
                      title="about.genericTitle.page"
                    />
                  </div>
                </div>
              </>
            }
          </div>
          <div className={classes.modalFooter}>
            <div></div>
            <div className={classes.modalActions}>
              {!showConfirmation &&
                <>
                  {(modalMode === constants.modalActions.edit || modalMode === constants.modalActions.view) &&
                    <Button
                      buttonType="danger"
                      disabled={disabled || pending || submitPending}
                      label="button.delete"
                      onClick={() => {
                        setDeleteData({
                          adjacentMovementId,
                          currentCph,
                          adjacentHolding,
                          holdingLinkTypeId,
                          holdings,
                          setAction,
                          setShowConfirmation,
                          setSubmitStatus
                        });
                        setConfirmAction(constants.modalActions.delete);
                      }}
                      type="submit"
                    />
                  }
                  {(modalMode === constants.modalActions.edit || modalMode === constants.modalActions.add) &&
                    <Button
                      buttonType="secondary"
                      disabled={disabled || pending || submitPending}
                      label="button.submit"
                      onClick={submitAdjacentMovement}
                      type="submit"
                    />
                  }
                </>
              }

              <Button
                buttonType="tertiary"
                disabled={submitPending}
                label={showConfirmation ? 'button.close' : 'button.cancel'}
                onClick={modalClose}
                type="submit"
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

ModalAdjacentMovements.propTypes = {
  modalClose: PropTypes.func,
  modalData: PropTypes.any,
  modalMode: PropTypes.string,
  setConfirmAction: PropTypes.func.isRequired,
  setDeleteData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setModalHasChanges: PropTypes.func,
  setModalSubmitted: PropTypes.func
};

export default ModalAdjacentMovements;
