import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/footer/footer.module.scss';

const Footer = () => {
  const { ready, t } = useTranslation();

  return (
    <>
      {ready &&
        <footer className={classes.footer}>
          <div className="container">
            <div className={classes.content}>
              <div>
                <span className={classes.title}>{t('footer.information')}</span>
                <nav aria-label={t('footer.information')} className={classes.nav} id="informationNav">
                  <ul>
                    <li className={classes.info}><a href="https://livestockinformation.org.uk/privacy/" rel="noopener noreferrer" target="_blank">{t('footer.privacyPolicy')}</a></li>
                    <li className={classes.info}><a href="https://livestockinformation.org.uk/cookies/" rel="noopener noreferrer" target="_blank">{t('footer.cookies')}</a></li>
                  </ul>
                </nav>
              </div>
              <div>
                <span className={classes.title}>{t('footer.contactUs')}</span>
                <nav aria-label={t('footer.contactUs')} className={classes.nav} id="contactUsNav">
                  <ul>
                    <li className={classes.info}><a href="https://livestockinformation.org.uk/lis-support/" rel="noopener noreferrer" target="_blank">{t('footer.customerSupport')}</a></li>
                  </ul>
                </nav>
              </div>
              <div>
                <span className={classes.title}>{t('footer.usefulInformation')}</span>
                <nav aria-label={t('footer.usefulInformation')} className={classes.nav} id="defraNav">
                  <ul>
                    <li className={classes.info}><a href="https://www.gov.uk/guidance/sheep-and-goat-keepers-how-to-report-animal-movements" rel="noopener noreferrer" target="_blank">{t('footer.defraSheepGuidance')}</a></li>
                    {/*<li className={classes.info}><a href="https://www.gov.uk/guidance/report-and-record-cattle-movements" rel="noopener noreferrer" target="_blank">{t('footer.defraCattleGuidance')}</a></li>*/}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </footer>
      }
    </>
  );
};

export default Footer;
