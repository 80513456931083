import React, { useEffect } from 'react';
import classes from 'components/base/modal/modal.module.scss';
import PropTypes from 'prop-types';
import Backdrop from 'components/base/backdrop/backdrop';
import Button from 'components/base/button/button';

const Modal = ({
  children,
  classNames,
  footerContent,
  headerContent,
  id,
  modalClose,
  showConfirmation,
  submitChanges,
  submitDisabled,
  submitLabel,
  submitPending
}) => {
  const componentClassNames = [
    'modalInstance',
    classes.modal,
    ...(classNames ? classNames.map((className) => classes[className]) : [])
  ].join(' ').trim();

  useEffect(() => {
    document.onkeydown = (e) => {
      e = e || window.event;
      if (e.key === 'Escape') {
        modalClose();
      }
    };

    const modal = document.getElementById(id);

    if (modal) {
      const focusable = modal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const firstFocusable = focusable[0];

      modal.addEventListener('transitionend', () => {
        if (!document.getElementById('confirmCloseModal')) {
          firstFocusable.focus();
        }
      });

      setTimeout(() => {
        modal.classList.add(classes.open);
      }, 1);
    }
  });

  return (
    <>
      <Backdrop
        onClick={modalClose}
      />
      <div className={componentClassNames} id={id}>
        <input className={classes.hiddenInput} type="text" />
        {headerContent &&
          <h2 className={classes.modalHeader}>
            {modalClose &&
              <Button
                buttonType="modalClose"
                disabled={submitPending}
                onClick={modalClose}
              />
            }
            {headerContent}
          </h2>
        }
        {submitChanges &&
          <>
            <div className={classes.modalBody}>
              {children}
            </div>
            <div className={classes.modalFooter}>
              <div className={classes.modalMessage}>
                {!submitPending && !showConfirmation && footerContent}
              </div>
              <div className={classes.modalActions}>
                {showConfirmation &&
                  <Button
                    buttonType="tertiary"
                    label="button.close"
                    onClick={modalClose}
                    type="submit"
                  />
                }
                {!showConfirmation &&
                  <>
                    <Button
                      buttonType="secondary"
                      disabled={submitDisabled}
                      label={submitLabel ? submitLabel : 'button.save'}
                      onClick={submitChanges}
                    />
                    <Button
                      buttonType="tertiary"
                      disabled={submitPending}
                      label="button.cancel"
                      onClick={modalClose}
                    />
                  </>
                }
              </div>
            </div>
          </>
        }
        {!submitChanges &&
          <>
            {children}
          </>
        }
      </div>
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.object,
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  footerContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  headerContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  id: PropTypes.string.isRequired,
  modalClose: PropTypes.func,
  showConfirmation: PropTypes.bool,
  submitChanges: PropTypes.func,
  submitDisabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  submitPending: PropTypes.bool
};

export default Modal;
