import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import bff, { bffResponse } from 'services/bff';
import classes from 'frontEnd/deaths/summary/summary.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import AddressSummary from 'components/summary/addressSummary/addressSummary';
import AnimalSummary from 'components/summary/animalSummary/animalSummary';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import DateSummary from 'components/summary/dateSummary/dateSummary';

const Summary = ({
  currentHolding,
  deathData,
  missingOrFound,
  missingOrFoundData,
  setData,
  setDataChanged,
  setMissingOrFound,
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const [numberOfDeadAnimals, setNumberOfDeadAnimals] = useState();
  const [numberOfMissingOrFoundAnimals, setNumberOfMissingOrFoundAnimals] = useState();
  const [holdingInfo, setHoldingInfo] = useState();
  const [pending, setPending] = useState(false);
  const species = storeService.session.get.species();

  const dateMissingLabel = 'deaths.dateOfMissing' + species.id;
  const dateFoundLabel = 'deaths.dateOfFound' + species.id;

  const componentClassNames = [
    'row',
    classes.summary
  ].join(' ').trim();

  const nextPage = () => {
    setPending(true);

    if (deathData) {
      postDeathData();
    } else {
      postMissingOrFoundData();
    }
  };

  const postDeathData = () => {
    if (
      (deathData.animalsBeingMoved?.length > 0) ||
      (deathData.batchList?.length > 0)
    ) {
      const data = {
        poll: config.POLLS_ENABLED,
        ...deathData,
        deathDate: deathData.deathDate,
        speciesName: species.name,
        holding: currentHolding.value
      };
      delete data.otherHolding;
      delete data.batches;

      if (deathData.otherHolding) {
        if (deathData.otherHolding.isManual) {
          data.approvedDisposalRoute = deathData.otherHolding.value;
        } else {
          data.disposalHolding = deathData.otherHolding.value;
        }
      }

      bff
        .post('/death', data)
        .then((res) => {
          const { data } = res;

          storeService.session.set.confirmRequestId(bffResponse.requestId(data));
          storeService.session.set.confirmPollingStatus(bffResponse.status(data));
          storeService.session.set.confirmPollingErrors(bffResponse.errors(data));
          storeService.session.set.confirmPollingWarnings(bffResponse.warnings(data));

          storeService.session.remove.dataChanged();

          history.push(Routing.deathsConfirm);

          setData(null);
          setDataChanged(false);
          setPending(false);
        })
        .catch((error) => {
          errors.BFF(error, setModal);
          setPending(false);
        });
    }
  };

  const postMissingOrFoundData = () => {
    const data = {
      poll: config.POLLS_ENABLED,
      ...missingOrFoundData,
      date: missingOrFoundData.date,
      animalCount: numberOfMissingOrFoundAnimals,
      speciesName: species.name
    };

    delete data.reportType;

    let endpoint;
    let redirect;
    if (helpers.option.reportType.isMissing(missingOrFound)) {
      endpoint = '/missing';
      redirect = Routing.missingConfirm;

      data.poll = true;
      data.holding = currentHolding.value;
    } else if (helpers.option.reportType.isFound(missingOrFound)) {
      endpoint = '/found';
      redirect = Routing.foundConfirm;

      data.poll = true;
      data.holding = config.UNKNOWN;
      data.destinationHolding = currentHolding.value;
    }

    bff
      .post(endpoint, data)
      .then((res) => {
        const { data } = res;

        storeService.session.set.confirmRequestId(bffResponse.requestId(data));
        storeService.session.set.confirmPollingStatus(bffResponse.status(data));
        storeService.session.set.confirmPollingErrors(bffResponse.errors(data));
        storeService.session.set.confirmPollingWarnings(bffResponse.warnings(data));
        storeService.session.set.confirmMovementReference(bffResponse.movementRef(data));

        storeService.session.remove.dataChanged();

        history.push(redirect);

        setData(null);
        setMissingOrFound(null);
        setDataChanged(false);
      })
      .catch((error) => {
        errors.BFF(error, setModal);
        setPending(false);
      });
  };

  useEffect(() => {
    const holding = storeService.session.get.holding();
    if (holding) {
      setHoldingInfo(holding);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deathData) {
      if (typeof deathData.animalsBeingMoved === 'string') {
        setNumberOfDeadAnimals(1);
        return;
      }

      if (deathData.batchList?.length > 0) {
        const result = deathData.batchList.reduce((total, item) => total + parseInt(item.animalTotal), 0);
        setNumberOfDeadAnimals(result);
      } else {
        setNumberOfDeadAnimals(deathData.animalsBeingMoved.length);
      }
    }

    if (helpers.option.reportType.isFound(missingOrFound)) {
      if (missingOrFoundData.batchList?.length > 0) {
        const result = missingOrFoundData.batchList.reduce((total, item) => total + parseInt(item.animalTotal), 0);
        setNumberOfMissingOrFoundAnimals(result);
      } else {
        setNumberOfMissingOrFoundAnimals(missingOrFoundData.animalsBeingMoved.length);
      }
    }

    if (helpers.option.reportType.isMissing(missingOrFound)) {
      if (missingOrFoundData.batchList?.length > 0) {
        const result = missingOrFoundData.batchList.reduce((total, item) => total + parseInt(item.animalTotal), 0);
        setNumberOfMissingOrFoundAnimals(result);
      } else {
        setNumberOfMissingOrFoundAnimals(missingOrFoundData.animalsBeingMoved.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingInfo, deathData, missingOrFoundData]);

  const redirectDeath = (path) => {
    storeService.session.set.deathAmend(true);
    history.push(path);
  };

  return (
    <>
      {ready &&
        <>
          {pending &&
            <Confirmation
              label="label.submissionPending"
              setModal={setModal}
              status={constants.status.pending}
            />
          }

          {!pending &&
            <>
              <h2 className="h2">{deathData
                ? t('deaths.deathSummary')
                : helpers.option.reportType.isMissing(missingOrFound)
                  ? t('deaths.missingSummary')
                  : t('deaths.foundSummary')}
              </h2>
              <div className={componentClassNames}>
                <div className="col">
                  <DateSummary
                    date1={
                      deathData
                        ? deathData.deathDate
                        : missingOrFoundData.date
                    }
                    label={
                      deathData
                        ? 'deaths.dateOfDeath'
                        : helpers.option.reportType.isMissing(missingOrFound)
                          ? dateMissingLabel
                          : dateFoundLabel
                    }
                    onClick={() => {
                      deathData
                        ? redirectDeath(Routing.deathsStep1)
                        : history.push(Routing.missingFoundStep1);
                    }}
                  />
                  {deathData &&
                    <AddressSummary
                      info1={deathData.otherHolding ? deathData.otherHolding.value : t('deaths.deathsCarcassLabel')}
                      isGathering={false}
                      isManual={deathData.otherHolding ? deathData.otherHolding.isManual : true}
                      label="label.carcassLocation"
                      onClick={() => redirectDeath(Routing.deathsStep2)}
                      queryKeeper={true}
                      setModal={setModal}
                    />
                  }
                  <AnimalSummary
                    animalsData={deathData || missingOrFoundData}
                    batchesDataProp="batchList"
                    label="label.animalsEntered"
                    numberBeingMoved={numberOfDeadAnimals
                      ? numberOfDeadAnimals
                      : numberOfMissingOrFoundAnimals
                    }
                    onClick={() => {
                      numberOfDeadAnimals
                        ? redirectDeath(Routing.deathsStep1)
                        : history.push(Routing.missingFoundStep1);
                    }}
                    setAnimalsData={setData}
                    setModal={setModal}
                  />
                </div>
              </div>

              <div className={classes.actions}>
                <div className={classes.submit}>
                  <Button
                    buttonType="primary"
                    disabled={pending}
                    label="button.submit"
                    onClick={nextPage}
                  />
                </div>
                <div className={classes.exit}>
                  <Button
                    buttonType="tertiary"
                    label="button.cancelExit"
                    onClick={() => history.push(Routing.keeperHolding)}
                  />
                </div>
              </div>
            </>
          }
        </>
      }
    </>
  );
};

Summary.propTypes = {
  currentHolding: PropTypes.object,
  deathData: PropTypes.object,
  missingOrFound: PropTypes.string,
  missingOrFoundData: PropTypes.object,
  setData: PropTypes.func.isRequired,
  setDataChanged: PropTypes.func,
  setMissingOrFound: PropTypes.func,
  setModal: PropTypes.func.isRequired
};

export default Summary;
