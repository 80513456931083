import React from 'react';
import constants from 'services/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';

const buildYearsList = (doNotAllowCurrentYear) => {
  const yearsArray = [];
  const now = moment();
  const numberOfYears = doNotAllowCurrentYear ? 16 : 15;

  for (let i = 1; i <= numberOfYears; i++) {
    const year = now.format('YYYY');

    if (!doNotAllowCurrentYear || (doNotAllowCurrentYear && Number(year) !== moment().year())) {
      yearsArray.push({
        value: year,
        name: year
      });
    }

    now.subtract(1, constants.period.years);
  }

  return yearsArray;
};

const DropdownYear = ({
  className,
  doNotAllowCurrentYear,
  error,
  label,
  name,
  onChange,
  previouslySubmittedYears,
  value
}) => {
  const [itemList, setItemList] = React.useState(buildYearsList(doNotAllowCurrentYear));

  React.useEffect(() => {
    if (previouslySubmittedYears) {
      setItemList((prevState) => prevState.filter((year) => !previouslySubmittedYears.includes(year.value)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownSearch
      className={className}
      error={error}
      id="yearDropdown"
      label={label}
      name={name}
      onChange={onChange}
      optionList={itemList}
      placeholder="prompt.pleaseSelect"
      value={value}
    />
  );
};

DropdownYear.propTypes = {
  className: PropTypes.string,
  doNotAllowCurrentYear: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  previouslySubmittedYears: PropTypes.arrayOf(
    PropTypes.string
  ),
  value: PropTypes.string
};

export default DropdownYear;
