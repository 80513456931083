import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import helpers from 'services/helpers';
import classes from 'components/summary/animalDetails/animalDetails.module.scss';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AddBatch from 'components/addBatch/addBatch';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import ManualTagEntry from 'components/manualTagEntry/manualTagEntry';
import Table from 'components/table/table';

const AnimalDetails = ({
  addAnimals,
  data,
  label,
  pending,
  removeAnimal,
  removeAnimalsAll,
  removeAnimalsAllInvalid,
  removeBatch,
  removeBatchesAll,
  removeBatchesAllInvalid,
  restoreAnimal,
  restoreBatch,
  setData,
  setModal,
  setPending,
  updateBatchList
}) => {
  const { ready, t } = useTranslation();

  const species = storeService.session.get.species();
  const speciesId = species ? species.id : '';

  const reviewDevices = data.reviewDevices.filter((device) => !device.removed);
  const removedDevices = data.reviewDevices.filter((device) => device.removed);
  const reviewBatches = data.reviewBatches.filter((batch) => !batch.removed);
  const removedBatches = data.reviewBatches.filter((batch) => batch.removed);

  const [paginationParams, setPaginationParams] = useState({
    batch: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        totalLabel: 'label.totalBatches'
      }
    },
    batchRemoved: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        totalLabel: 'label.totalBatches'
      }
    },
    individual: constants.pagination.defaultParams,
    individualRemoved: constants.pagination.defaultParams
  });

  useEffect(() => {
    setPaginationParams((prevState) => ({
      batch: {
        ...prevState.batch,
        [constants.pagination.bottom]: {
          ...prevState.batch[constants.pagination.bottom],
          total: reviewBatches?.length
        }
      },
      batchRemoved: {
        ...prevState.batchRemoved,
        [constants.pagination.bottom]: {
          ...prevState.batchRemoved[constants.pagination.bottom],
          total: removedBatches?.length
        }
      },
      individual: {
        ...prevState.individual,
        [constants.pagination.bottom]: {
          ...prevState.individual[constants.pagination.bottom],
          total: reviewDevices?.length
        }
      },
      individualRemoved: {
        ...prevState.individualRemoved,
        [constants.pagination.bottom]: {
          ...prevState.individualRemoved[constants.pagination.bottom],
          total: removedDevices?.length
        }
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {ready &&
        <>
          <h2 className={'h2 ' + classes.title}>{t(label)}</h2>
          <div className="row">
            <div className="col-sm-12">
              <ErrorLabel
                isWarning={true}
                label="warning.checkAnimals"
              />

              {reviewDevices.length > 0 &&
                <>
                  {reviewDevices.some((tag) => helpers.tag.isInvalid(tag)) &&
                    <ErrorLabel
                      label="formatExamples.tagBatchError"
                      modalMessage={helpers.tag.getFormatExamples(speciesId).description}
                      modalTitle={helpers.tag.getFormatExamples(speciesId).title}
                      setModal={setModal}
                    />
                  }

                  <Table
                    columns={helpers.species.isDeerId(speciesId)
                      ? tableColumns.handshake.individual.deer({ removeAnimal })
                      : tableColumns.handshake.individual.nonDeer({ removeAnimal })
                    }
                    data={reviewDevices}
                    dataProvided={true}
                    initialSortBy={initialSorting.handshake.individual.accessor}
                    initialSortDirection={initialSorting.handshake.individual.direction}
                    paginationParams={paginationParams.individual}
                    removeAll={removeAnimalsAll}
                    removeAllInvalid={removeAnimalsAllInvalid}
                    setData={setData}
                    setDataProp="reviewDevices"
                    setModal={setModal}
                    title={{
                      title: 'table.title.individualAnimals',
                      data: [
                        reviewDevices?.length
                      ]
                    }}
                  />
                </>
              }

              {removedDevices.length > 0 &&
                <>
                  <Table
                    columns={helpers.species.isDeerId(speciesId)
                      ? tableColumns.handshake.individualRemoved.deer({ restoreAnimal })
                      : tableColumns.handshake.individualRemoved.nonDeer({ restoreAnimal })
                    }
                    data={removedDevices}
                    dataProvided={true}
                    initialSortBy={initialSorting.handshake.individual.accessor}
                    initialSortDirection={initialSorting.handshake.individual.direction}
                    paginationParams={paginationParams.individualRemoved}
                    setData={setData}
                    setDataProp="reviewDevices"
                    setModal={setModal}
                    title={{
                      title: 'table.title.individualAnimalsRemoved',
                      data: [
                        removedDevices?.length
                      ]
                    }}
                  />
                </>
              }

              <ManualTagEntry
                addAnimals={addAnimals}
                animalsToChange={data.reviewDevices}
                pending={pending}
                setModal={setModal}
                setPending={setPending}
                species={species}
                title="movements.animal-selection.add-individual-animals-heading"
                type={constants.option.movement.reviewMovement}
              />

              {reviewBatches.length > 0 &&
                <>
                  {reviewBatches.some((batch) => helpers.batch.isInvalid(batch)) &&
                    <ErrorLabel
                      label="formatExamples.tagBatchError"
                      modalMessage={helpers.batch.getFormatExamples(speciesId).description}
                      modalTitle={helpers.tag.getFormatExamples(speciesId).title}
                      setModal={setModal}
                    />
                  }

                  <Table
                    columns={tableColumns.handshake.batch({ removeBatch, speciesId })}
                    data={reviewBatches}
                    dataProvided={true}
                    initialSortBy={initialSorting.handshake.batch.accessor}
                    initialSortDirection={initialSorting.handshake.batch.direction}
                    paginationParams={paginationParams.batch}
                    removeAll={removeBatchesAll}
                    removeAllInvalid={removeBatchesAllInvalid}
                    setData={setData}
                    setDataProp="reviewBatches"
                    setModal={setModal}
                    title={{
                      title: helpers.text.pluralCheck(reviewBatches?.reduce((total, item) => total + parseInt(item.animalTotal), 0), 'table.title.batches'),
                      data: [
                        reviewBatches?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
                      ]
                    }}
                  />
                </>
              }

              {removedBatches.length > 0 &&
                <>
                  <Table
                    columns={tableColumns.handshake.batchRemoved({ restoreBatch, speciesId })}
                    data={removedBatches}
                    dataProvided={true}
                    initialSortBy={initialSorting.handshake.batch.accessor}
                    initialSortDirection={initialSorting.handshake.batch.direction}
                    paginationParams={paginationParams.batchRemoved}
                    setData={setData}
                    setDataProp="reviewBatches"
                    setModal={setModal}
                    title={{
                      title: helpers.text.pluralCheck(removedBatches?.reduce((total, item) => total + parseInt(item.animalTotal), 0), 'table.title.batchesRemoved'),
                      data: [
                        removedBatches?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
                      ]
                    }}
                  />
                </>
              }

              {!helpers.species.isCattleId(speciesId) &&
                <AddBatch
                  animalTotalLabel="movements.animal-selection.batch.input-field-two"
                  batchDescription="handshake.batch.desc"
                  batchHeader="handshake.batch.title"
                  batchList={data.reviewBatches}
                  buttonLabel="movements.animal-selection.batch.submit"
                  pending={pending}
                  setModal={setModal}
                  setPending={setPending}
                  species={species}
                  updateBatchList={updateBatchList}
                />
              }
            </div>
          </div>
        </>
      }
    </>
  );
};

AnimalDetails.propTypes = {
  addAnimals: PropTypes.func,
  data: PropTypes.object,
  label: PropTypes.string,
  pending: PropTypes.bool,
  removeAnimal: PropTypes.func,
  removeAnimalsAll: PropTypes.func,
  removeAnimalsAllInvalid: PropTypes.func,
  removeBatch: PropTypes.func,
  removeBatchesAll: PropTypes.func,
  removeBatchesAllInvalid: PropTypes.func,
  restoreAnimal: PropTypes.func,
  restoreBatch: PropTypes.func,
  setData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setPending: PropTypes.func,
  updateBatchList: PropTypes.func
};

export default AnimalDetails;
