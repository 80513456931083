import React, { useEffect } from 'react';
import classes from 'components/table/filters/filtersAnimalsOnHolding/filtersAnimalsOnHolding.module.scss';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { useTranslation } from 'react-i18next';
import DropdownAgeRange from 'components/dropdownAgeRange/dropdownAgeRange';
import DropdownBreeds from 'components/dropdownBreedsMulti/dropdownBreedsMulti';
import DropdownGender from 'components/dropdownGender/dropdownGender';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import SearchField from 'components/base/searchField/searchField';

const AnimalsOnHoldingFilters = ({
  cph,
  date,
  refreshFilters,
  setModal,
  setTableParams,
  speciesId,
  storeFilters,
  tableParams
}) => {
  const { ready } = useTranslation();

  const species = storeService.session.get.species();

  const [filterAgeRange, setFilterAgeRange] = React.useState(tableParams?.filters.ageRange ? tableParams.filters.ageRange : []);
  const [filterBreed, setFilterBreed] = React.useState(tableParams?.filters.breedName ? tableParams.filters.breedName : []);
  const [filterGender, setFilterGender] = React.useState(tableParams?.filters.gender ? tableParams.filters.gender : []);
  const [filterTagNumber, setFilterTagNumber] = React.useState(tableParams?.filters.tagNumber ? tableParams.filters.tagNumber : '');
  const [filtersInitialised, setFiltersInitialised] = React.useState(false);

  const handleChanges = {
    ageRange: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      storeService.session.removeAll.searchResults();
      setFilterAgeRange(value);

      if (value.length === 0) {
        setTableParams((prevState) => {
          // eslint-disable-next-line no-unused-vars
          const { ageRange, ...prevStateFilters } = prevState.filters;

          return {
            ...prevState,
            filters: prevStateFilters,
            page: {
              ...prevState.page,
              index: 0
            }
          };
        });

        // eslint-disable-next-line no-unused-vars
        const { ageRange, ...prevStoredFilters } = storedFilters;

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...prevStoredFilters
        });
      } else {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            ageRange: value
          },
          page: {
            ...prevState.page,
            index: 0
          }
        }));

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          ageRange: value
        });
      }
    },

    breed: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      storeService.session.removeAll.searchResults();
      setFilterBreed(value);

      if (value.length === 0) {
        setTableParams((prevState) => {
          // eslint-disable-next-line no-unused-vars
          const { breedName, ...prevStateFilters } = prevState.filters;

          return {
            ...prevState,
            filters: prevStateFilters,
            page: {
              ...prevState.page,
              index: 0
            }
          };
        });

        // eslint-disable-next-line no-unused-vars
        const { breedName, ...prevStoredFilters } = storedFilters;

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...prevStoredFilters
        });
      } else {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            breedName: value.map((item) => item.split('%20').join('_'))
          },
          page: {
            ...prevState.page,
            index: 0
          }
        }));

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          breedName: value
        });
      }
    },

    gender: (value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      storeService.session.removeAll.searchResults();
      setFilterGender(value);

      if (value.length === 0) {
        setTableParams((prevState) => {
          // eslint-disable-next-line no-unused-vars
          const { gender, ...prevStateFilters } = prevState.filters;

          return {
            ...prevState,
            filters: prevStateFilters,
            page: {
              ...prevState.page,
              index: 0
            }
          };
        });

        // eslint-disable-next-line no-unused-vars
        const { gender, ...prevStoredFilters } = storedFilters;

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...prevStoredFilters
        });
      } else {
        setTableParams((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            gender: value
          },
          page: {
            ...prevState.page,
            index: 0
          }
        }));

        storeService.session.set[`tableFilters${storeFilters}`]({
          ...storedFilters,
          gender: value
        });
      }
    },

    tagNumber: (value) => {
      setFilterTagNumber(value);
    }
  };

  useEffect(() => {
    let typingTimer;

    if (filtersInitialised) {
      typingTimer = setTimeout(() => {
        const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

        storeService.session.removeAll.searchResults();

        if (filterTagNumber.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { tagNumber, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters,
              page: {
                ...prevState.page,
                index: 0
              }
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { tagNumber, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              tagNumber: filterTagNumber
            },
            page: {
              ...prevState.page,
              index: 0
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            tagNumber: filterTagNumber
          });
        }

        // setTableParams((prevState) => ({
        //   ...prevState,
        //   filters: {
        //     ...prevState.filters,
        //     tagNumber: filterTagNumber
        //   }
        // }));
      }, 1000);
    }

    return () => {
      clearTimeout(typingTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTagNumber]);

  useEffect(() => {
    setFiltersInitialised(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <div className={classes.fields}>
          <div className={classes.fieldSearch}>
            <SearchField
              id="search"
              label="prompt.searchAnimalLocation"
              name="search"
              onChange={(event) => handleChanges.tagNumber(event.target.value)}
              placeholder="Search"
              tooltip="animalsOnHolding.tooltip"
              tooltipPosition="bottom"
              value={filterTagNumber}
            />
          </div>

          {!helpers.species.isDeerId(speciesId ? speciesId : species.id) &&
            <>
              <div className={classes.fieldBreed}>
                <FieldLabel
                  id="breed"
                  label="label.breed"
                />
                <DropdownBreeds
                  cph={cph}
                  date={date}
                  onChange={handleChanges.breed}
                  refreshFilters={refreshFilters}
                  setModal={setModal}
                  speciesId={speciesId}
                  value={filterBreed}
                />
              </div>
              <div className={classes.fieldGender}>
                <FieldLabel
                  id="gender"
                  label="label.gender"
                />
                <DropdownGender
                  onChange={handleChanges.gender}
                  unknown={true}
                  value={filterGender}
                />
              </div>
              <div className={classes.fieldAgeRange}>
                <FieldLabel
                  id="ageRange"
                  label="label.age"
                />
                <DropdownAgeRange
                  onChange={handleChanges.ageRange}
                  speciesId={speciesId}
                  value={filterAgeRange}
                />
              </div>
            </>
          }
        </div>
      }
    </>
  );
};

AnimalsOnHoldingFilters.propTypes = {
  cph: PropTypes.string,
  date: PropTypes.string,
  refreshFilters: PropTypes.object,
  setModal: PropTypes.func.isRequired,
  setTableParams: PropTypes.func.isRequired,
  speciesId: PropTypes.number,
  storeFilters: PropTypes.string.isRequired,
  tableParams: PropTypes.object.isRequired
};

export default AnimalsOnHoldingFilters;
