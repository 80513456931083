import React from 'react';
import PropTypes from 'prop-types';
import classes from 'components/base/tabs/tabs.module.scss';
import Tab from 'components/base/tab/tab';

const TabsNavigational = ({
  children
}) => {
  return (
    <div className={classes.tabs}>
      <ul className={classes.tabsList}>
        {children?.filter((child) => child).map((child) => (
          <Tab
            classNames={child.props.classNames}
            extraContent={child.props.extraContent}
            id={child.props.id}
            isActive={child.props.active}
            key={child.props.label}
            label={child.props.label}
            onClick={child.props.onClick}
            url={child.props.url}
          />
        ))}
      </ul>
      <div className={classes.tabContent}>
        {children?.filter((child) => child).map((child) => {
          return child.props.active && child.props.children;
        })}
      </div>
    </div>
  );
};

TabsNavigational.propTypes = {
  children: PropTypes.array
};

export default TabsNavigational;
