import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import breedsHelper from 'services/breedsHelper';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import AddBatch from 'components/addBatch/addBatch';
import Button from 'components/base/button/button';
import CheckBox from 'components/base/checkbox/checkbox';
import DropdownBreeds from 'components/dropdownBreeds/dropdownBreeds';
import DropdownDamIds from 'components/dropdownDamIds/dropdownDamIds';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import InputDate from 'components/base/inputDate/inputDate';
import ManualTagEntry from 'components/manualTagEntry/manualTagEntry';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import Table from 'components/table/table';
const sprintf = require('util').format;

const DeathsStep1 = ({
  setDataChanged,
  setDeathData,
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionSpecies = storeService.session.get.species();
  const speciesId = sessionSpecies ? sessionSpecies.id : '';
  const sessionHolding = storeService.session.get.holding();
  const cph = sessionHolding ? sessionHolding.value : '';
  const isCattle = helpers.species.isCattleId(speciesId);

  const desc2Label = helpers.species.idToName(speciesId) === 'Sheep' || helpers.species.idToName(speciesId) === 'Goats'
    ? 'about.deaths.desc2-' + speciesId
    : sprintf(t('about.deaths.desc2-3'), helpers.species.idToName(speciesId));

  const [animalsOnHolding, setAnimalsOnHolding] = React.useState([]);
  const [animalsBeingMoved, setAnimalsBeingMoved] = React.useState([]);
  const [identificationType, setIdentificationType] = React.useState(isCattle ? constants.option.identification.breedingTags : null);
  const [selectionType, setSelectionType] = React.useState(null);
  const [deathDate, setDeathDate] = React.useState(null);
  const [deathDateError, setDeathDateError] = React.useState('');
  const [deathDateWarning, setDeathDateWarning] = React.useState('');

  const [birthDate, setBirthDate] = React.useState(null);
  const [birthDateError, setBirthDateError] = React.useState('');
  const [birthDateWarning, setBirthDateWarning] = React.useState('');

  const [pending, setPending] = React.useState(false);
  const [batchList, setBatchList] = React.useState([]);
  const [batchTotal, setBatchTotal] = React.useState(0);
  const [calfDiedBeforeTagging, setCalfDiedBeforeTagging] = React.useState(false);
  const [birthDamId, setBirthDamId] = useState(null);
  const [breedId, setBreedId] = useState(null);
  const [breedName, setBreedName] = useState(null);
  const [birthDamIdError] = useState('');
  const [disableCalfBefore, setDisableClfBefore] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [renderTable, setRenderTable] = useState();
  const [refreshFilters, setRefreshFilters] = useState({});
  const [paginationParams, setPaginationParams] = useState({
    batch: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        totalLabel: 'label.totalBatches'
      }
    },
    individual: constants.pagination.defaultParams
  });

  const handleChanges = {
    addAnimals: (tags) => {
      const uniqueNewTags = tags.filter((tag) => !animalsBeingMoved.find((tagItem) => tagItem.tagNumber === tag.tagNumber));
      const animalsBeingMovedCopy = [
        ...animalsBeingMoved,
        ...uniqueNewTags
      ].map((item) => ({
        ...item,
        tagNumber: item.tagNumber
      }));

      setDeathData((prevState) => ({
        ...prevState,
        animalsBeingMoved: animalsBeingMovedCopy
      }));
      setAnimalsBeingMoved(animalsBeingMovedCopy);
      storeService.session.set.deathAnimalsBeingMoved(animalsBeingMovedCopy);
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    addAnimalsAll: (rows) => {
      setDeathData((prevState) => {
        const newAnimalsBeingMoved = [
          ...(prevState.animalsBeingMoved ? prevState.animalsBeingMoved : []),
          ...rows.filter((item) => !item.added)
        ];

        return {
          ...prevState,
          animalsBeingMoved: newAnimalsBeingMoved
        };
      });

      setAnimalsBeingMoved((prevState) => {
        const newAnimalsBeingMoved = [
          ...prevState,
          ...rows.filter((item) => !item.added)
        ];

        storeService.session.set.deathAnimalsBeingMoved(newAnimalsBeingMoved);

        return newAnimalsBeingMoved;
      });

      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    batchList: (batchList) => {
      setDeathData((prevState) => ({
        ...prevState,
        batchList
      }));
      setBatchList(batchList);
      storeService.session.set.deathBatchList(batchList);
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    birthDamId: (event) => {
      setDeathData((prevState) => ({
        ...prevState,
        birthDamId: event.target.value
      }));
      setBirthDamId(event.target.value);
      storeService.session.set.deathBirthDamId(event.target.value);
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    breed: (event) => {
      const breedId = event.target.value;
      const breedName = breedsHelper.getBreedName(breedId);

      setDeathData((prevState) => ({
        ...prevState,
        breed: {
          id: breedId,
          name: breedName
        }
      }));
      setBreedId(breedId);
      setBreedName(breedName);
      storeService.session.set.deathBreed({
        id: breedId,
        name: breedName
      });
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    calfDiedBeforeTagging: () => {
      if (calfDiedBeforeTagging) {
        storeService.session.remove.deathDiedBeforeTagging();
        storeService.session.remove.deathBirthDate();
        storeService.session.remove.deathBirthDamId();
        storeService.session.remove.deathBreed();
      } else {
        storeService.session.set.deathDiedBeforeTagging(true);
      }

      setDeathData((prevState) => ({
        ...prevState,
        diedBeforeTagging: !calfDiedBeforeTagging
      }));
      setCalfDiedBeforeTagging(!calfDiedBeforeTagging);
      setBirthDate(null);
      setBirthDamId(null);
      setBreedId(null);
      setAnimalsOnHolding(animalsBeingMoved);
      setAnimalsBeingMoved([]);
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    date: (date, whichDate) => {
      if (helpers.dateType.isDeath(whichDate)) {
        setDeathData((prevState) => ({
          ...prevState,
          deathDate: date
        }));
        setDeathDate(date);
        storeService.session.set.deathDate(date);
        validateDate(date, whichDate);
        storeService.session.set.dataChanged(true);

        setRefreshFilters({
          refresh: true,
          params: {
            date
          }
        });

        if (helpers.option.tagMethod.isSelectFromLists(selectionType)) {
          storeService.session.removeAll.searchResults();
          setAnimalsOnHolding([]);
          setRenderTable(true);
        }

        setDataChanged(true);
      } else if (helpers.dateType.isBirth(whichDate)) {
        setDeathData((prevState) => ({
          ...prevState,
          birthDate: date
        }));
        setBirthDate(date);
        storeService.session.set.deathBirthDate(date);
        validateDate(date, whichDate);
        storeService.session.set.dataChanged(true);
        setDataChanged(true);
      }
    },

    identificationType: (event) => {
      setDeathData((prevState) => ({
        ...prevState,
        identificationType: event.target.value
      }));
      setAnimalsOnHolding([]);

      setIdentificationType(event.target.value);
      storeService.session.set.deathIdentificationType(event.target.value);

      setSelectionType(null);
      storeService.session.remove.deathSelectionType();

      storeService.session.removeAll.searchResults();
      storeService.session.removeAll.tableFilters();
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    removeAnimal: (animal) => {
      setDeathData((prevState) => {
        const newAnimalsBeingMoved = helpers.tag.remove(prevState.animalsBeingMoved, animal);

        return {
          ...prevState,
          animalsBeingMoved: newAnimalsBeingMoved
        };
      });

      setAnimalsBeingMoved((prevState) => {
        const newState = helpers.tag.remove(prevState, animal);

        if (newState?.length === 0) {
          storeService.session.remove.deathAnimalsBeingMoved();
        } else {
          storeService.session.set.deathAnimalsBeingMoved(newState);
        }

        return newState;
      });

      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    removeAnimalsAll: () => {
      setDeathData((prevState) => ({
        ...prevState,
        animalsBeingMoved: []
      }));
      setAnimalsBeingMoved([]);
      storeService.session.remove.deathAnimalsBeingMoved();
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    removeAnimalsAllInvalid: () => {
      const newAnimalsBeingMoved = helpers.animal.removeAllInvalid(animalsBeingMoved);

      setDeathData((prevState) => ({
        ...prevState,
        animalsBeingMoved: newAnimalsBeingMoved
      }));
      setAnimalsBeingMoved(newAnimalsBeingMoved);
      storeService.session.set.deathAnimalsBeingMoved(newAnimalsBeingMoved);
    },

    removeBatch: (batch) => {
      setDeathData((prevState) => {
        const newBatchList = helpers.batch.remove(prevState.batchList, batch);

        return {
          ...prevState,
          batchList: newBatchList
        };
      });

      setBatchList((prevState) => {
        const newState = helpers.batch.remove(prevState, batch);

        if (newState?.length === 0) {
          storeService.session.remove.deathBatchList();
        } else {
          storeService.session.set.deathBatchList(newState);
        }

        return newState;
      });

      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    selectionType: (event) => {
      setDeathData((prevState) => ({
        ...prevState,
        selectionType: event.target.value
      }));
      setAnimalsOnHolding([]);
      setSelectionType(event.target.value);
      storeService.session.set.deathSelectionType(event.target.value);
      storeService.session.removeAll.searchResults();
      storeService.session.removeAll.tableFilters();
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    },

    toggleAnimal: (animal) => {
      const newAnimal = {
        ...animal,
        animalTotal: 1,
        added: !animal.added
      };

      if (animal.tagNumber !== '') {
        if (animal.added) {
          setDeathData((prevState) => {
            const newAnimalsBeingMoved = helpers.tag.remove(prevState.animalsBeingMoved, animal);

            return {
              ...prevState,
              animalsBeingMoved: newAnimalsBeingMoved
            };
          });

          setAnimalsBeingMoved((prevState) => {
            const newState = helpers.tag.remove(prevState, animal);

            if (newState?.length === 0) {
              storeService.session.remove.deathAnimalsBeingMoved();
            } else {
              storeService.session.set.deathAnimalsBeingMoved(newState);
            }

            return newState;
          });
        } else {
          setDeathData((prevState) => ({
            ...prevState,
            animalsBeingMoved: prevState.animalsBeingMoved
              ? [
                ...prevState.animalsBeingMoved,
                newAnimal
              ]
              : [newAnimal]
          }));

          setAnimalsBeingMoved((prevState) => {
            storeService.session.set.deathAnimalsBeingMoved([
              ...prevState,
              newAnimal
            ]);

            return [
              ...prevState,
              newAnimal
            ];
          });
        }

        const sessionSearchResults = storeService.session.get.searchResultsAnimalsOnHolding();
        const sessionAnimalToToggle = sessionSearchResults.data.find((item) => item.tagNumber === animal.tagNumber);
        sessionAnimalToToggle.added = !sessionAnimalToToggle.added;
        storeService.session.set.searchResultsAnimalsOnHolding(sessionSearchResults);
        storeService.session.set.dataChanged(true);
        setDataChanged(true);
      }
    }
  };

  const getDates = {
    deathMax: () => {
      const today = new Date();

      return helpers.date.formatYYYYMMDD(today);
    },

    birthMax: () => {
      const today = new Date();

      if (deathDate) {
        return helpers.date.formatYYYYMMDD(
          helpers.date.momentToJSDate(
            helpers.date.momentMinDate(
              helpers.date.stringDateToMoment(deathDate),
              helpers.date.todayEndOfDay()
            )
          )
        );
      }

      return helpers.date.formatYYYYMMDD(today);
    }
  };

  const tableParams = {
    request: {
      url: '/animals',
      params: {
        speciesId: speciesId,
        cph,
        onHoldingDate: deathDate ? helpers.date.endOfDay(deathDate) : helpers.date.endOfDay(helpers.date.now())
      },
      processData: (data) => helpers.animal.addDobAndAgeForDisplay(data)
    }
  };

  const display = {
    birthDatePicker: () => {
      return (
        <div className="section">
          <InputDate
            dontValidate={true}
            error={birthDateError}
            id="birthDate"
            labelPosition="above"
            labelText="label.dateBirth"
            maxDate={getDates.birthMax()}
            onChange={(event) => handleChanges.date(event.target.value, constants.dateTypes.birth)}
            setError={setBirthDateError}
            value={birthDate}
            warning={birthDateWarning}
            yearLength={4}
          />
        </div>
      );
    },

    calfDiedBeforeTaggingCheckbox: () => {
      return (
        <div className="section">
          <CheckBox
            disabled={disableCalfBefore}
            id="calfDiedBeforeTagging"
            isChecked={calfDiedBeforeTagging}
            label="deaths.calfBeforeTaggingLabel"
            name="calfDiedBeforeTagging"
            onChange={handleChanges.calfDiedBeforeTagging}
            value={calfDiedBeforeTagging}
          />
        </div>
      );
    },

    deathDatePicker: () => {
      return (
        <div className="section">
          <InputDate
            dontValidate={true}
            error={deathDateError}
            id="deathDate"
            labelPosition="above"
            labelText="deaths.dateOfDeath"
            maxDate={getDates.deathMax()}
            onChange={(event) => handleChanges.date(event.target.value, constants.dateTypes.death)}
            setError={setDeathDateError}
            value={deathDate}
            warning={deathDateWarning}
            yearLength={4}
          />
        </div>
      );
    },

    deathDateStaticText: () => {
      return (
        <div className="section">
          <strong className="pl-1">
            {t('deaths.dateOfDeath') + ':  '}
          </strong>
          {deathDate
            ? helpers.date.format(deathDate)
            : t('label.noDeathDateEntered')}
        </div>
      );
    },

    identificationTypeOption: () => {
      return (
        <>
          <h3 className="h3">{t('deaths.select-animals-heading')}</h3>

          <div className="section">
            <RadioButtons
              ids={[
                constants.option.identification.breedingTags,
                constants.option.identification.slaughterTags
              ]}
              name="identificationTypeDeaths"
              onChange={handleChanges.identificationType}
              value={identificationType}
            />
          </div>
        </>
      );
    },

    manualTags: () => {
      return (
        <ManualTagEntry
          addAnimals={handleChanges.addAnimals}
          animalsToChange={[]}
          pending={pending}
          setModal={setModal}
          setPending={setPending}
          species={sessionSpecies}
          title="deaths.animal-selection.manual-tag-entry-heading"
          tooltip="tooltips.movements.manualTagEntry"
          type={constants.option.movement.newMovement}
        />
      );
    },

    optionsForCattleDiedBeforeTagging: () => {
      return (
        <>
          <div className="section">
            <div className="input">
              <DropdownDamIds
                error={birthDamIdError}
                label="deaths.birthParentDamId"
                name="birthDamId"
                onChange={handleChanges.birthDamId}
                setModal={setModal}
                value={birthDamId}
              />
            </div>
          </div>
          <div className="section">
            <DropdownBreeds
              label="label.breed"
              name="breed"
              onChange={handleChanges.breed}
              value={breedId}
            />
          </div>
        </>
      );
    },

    selectedBatches: () => {
      return (
        <>
          {batchList.length > 0 &&
            <>
              {batchList.some((batch) => helpers.batch.isInvalid(batch)) &&
                <ErrorLabel
                  label="formatExamples.tagBatchError"
                  modalMessage={helpers.batch.getFormatExamples(speciesId).description}
                  modalTitle={helpers.batch.getFormatExamples(speciesId).title}
                  setModal={setModal}
                />
              }

              <Table
                columns={tableColumns.deathsMissingAndFound.batch({ removeBatch: handleChanges.removeBatch, speciesId: sessionSpecies.id, sortable: false })}
                data={batchList.map((item) => ({
                  ...item,
                  batchNumber: item.batchNumber ? item.batchNumber : t('label.notSupplied')
                }))}
                dataProvided={true}
                initialSortBy={initialSorting.deathsMissingAndFound.batch.accessor}
                initialSortDirection={initialSorting.deathsMissingAndFound.batch.direction}
                paginationParams={paginationParams.batch}
                setData={setBatchList}
                setModal={setModal}
                title={{
                  title: helpers.text.pluralCheck(batchList?.reduce((total, item) => total + parseInt(item.animalTotal), 0), 'table.title.batchesSelected'),
                  data: [
                    batchList?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
                  ]
                }}
              />
            </>
          }
        </>
      );
    },

    selectedIndividualAnimals: () => {
      return (
        <>
          {animalsBeingMoved?.length > 0 &&
            <>
              {helpers.tag.hasNonEidTagsSheep(speciesId, animalsBeingMoved) &&
                <ErrorLabel
                  isWarning={true}
                  label="formatExamples.tagBatchNonEidError"
                  modalMessage={helpers.tag.getFormatExamples(speciesId).description}
                  modalTitle={helpers.tag.getFormatExamples(speciesId).title}
                  setModal={setModal}
                />
              }

              {animalsBeingMoved.some((tag) => helpers.tag.isInvalid(tag)) &&
                <ErrorLabel
                  label="formatExamples.tagBatchError"
                  modalMessage={helpers.tag.getFormatExamples(speciesId).description}
                  modalTitle={helpers.tag.getFormatExamples(speciesId).title}
                  setModal={setModal}
                />
              }

              <Table
                columns={tableColumns.deathsMissingAndFound.individual({ actionType: 'remove', actionFunction: handleChanges.removeAnimal, sortable: false })}
                data={animalsBeingMoved}
                dataProvided={true}
                paginationParams={paginationParams.individual}
                removeAll={handleChanges.removeAnimalsAll}
                removeAllInvalid={handleChanges.removeAnimalsAllInvalid}
                setData={setAnimalsBeingMoved}
                setModal={setModal}
                title={{
                  title: 'table.title.animalsEntered',
                  data: [
                    animalsBeingMoved?.length
                  ]
                }}
              />
            </>
          }
        </>
      );
    },

    selectFromHolding: () => {
      return (
        <Table
          addAllAbove={handleChanges.addAnimalsAll}
          columns={tableColumns.deathsMissingAndFound.individual({ actionType: 'toggle', actionFunction: handleChanges.toggleAnimal })}
          data={animalsOnHolding.map((item1) =>
            animalsBeingMoved.find((item2) => item2.tagNumber === item1.tagNumber)
              ? { ...item1, added: true }
              : { ...item1, added: false }
          )}
          filtersComponents={{
            [constants.filters.inside]: 'AnimalsOnHolding'
          }}
          initialSortBy={initialSorting.deathsMissingAndFound.individual.accessor}
          initialSortDirection={initialSorting.deathsMissingAndFound.individual.direction}
          params={tableParams}
          refreshFilters={refreshFilters}
          renderTable={renderTable}
          setData={setAnimalsOnHolding}
          setModal={setModal}
          store={{
            filters: 'AnimalsOnHolding',
            data: 'AnimalsOnHolding'
          }}
          title="label.animalsOnHolding"
        />
      );
    },

    selectionType: () => {
      return (
        <div className="section">
          <RadioButtons
            ids={[
              constants.option.tagMethod.selectFromLists,
              constants.option.tagMethod.tagsManually
            ]}
            name="selectionType"
            onChange={handleChanges.selectionType}
            value={selectionType}
          />
        </div>
      );
    },

    slaughterTags: () => {
      return (
        <div className="section">
          <AddBatch
            animalTotalLabel="deaths.animal-selection.batch.input-field-two"
            batchDescription="deaths.animal-selection.batch.desc"
            batchHeader="deaths.animal-selection.batch.title"
            batchList={batchList}
            batchNumberOptional={true}
            batchTotal={batchTotal}
            buttonLabel="deaths.animal-selection.batch.submit"
            pending={pending}
            setBatchTotal={setBatchTotal}
            setModal={setModal}
            setPending={setPending}
            species={sessionSpecies}
            updateBatchList={handleChanges.batchList}
          />
        </div>
      );
    }
  };

  const submitStep = () => {
    if (isCattle && birthDamId !== '' && animalsBeingMoved?.length === 0) {
      storeService.session.set.deathAnimalsBeingMoved([{
        dob: birthDate,
        birthDamId,
        animalTotal: 1,
        batchNumber: null,
        breedName,
        genderName: null
      }]);
    }
  };

  const nextPage = () => {
    submitStep();
    history.push(Routing.deathsStep2);
    helpers.scrollToTop();
  };

  const validateDate = (date, whichDate) => {
    let hasError = false;

    setDeathDateError('');
    setDeathDateWarning('');
    setBirthDateError('');
    setBirthDateWarning('');

    if (helpers.dateType.isDeath(whichDate)) {
      const deathDateIsInTheFuture = helpers.date.is.inTheFuture(date, whichDate);
      const deathDateIsOverSevenDaysAgo = isCattle ? helpers.date.is.overSevenDaysAgo(date) : null;

      if (!hasError && deathDateIsInTheFuture) {
        setDeathDateError(deathDateIsInTheFuture);

        hasError = true;
      }

      if (!hasError && deathDateIsOverSevenDaysAgo) {
        setDeathDateWarning(deathDateIsOverSevenDaysAgo);
      }
    } else if (helpers.dateType.isBirth(whichDate)) {
      const birthDateIsInTheFuture = helpers.date.is.inTheFuture(date, whichDate);
      const birthDateIsOverSevenDaysAgo = isCattle ? helpers.date.is.overSevenDaysAgo(date) : null;
      const birthDateIsAfterDeathDate = helpers.date.is.afterDate(date, deathDate);

      if (!hasError && birthDateIsAfterDeathDate) {
        setBirthDateError('error.dateOfBirthAfterDateOfDeath');

        hasError = true;
      }

      if (!hasError && birthDateIsInTheFuture) {
        setBirthDateError(birthDateIsInTheFuture);

        hasError = true;
      }

      if (!hasError && birthDateIsOverSevenDaysAgo) {
        setBirthDateWarning(birthDateIsOverSevenDaysAgo);
      }
    }
  };

  useEffect(() => {
    const sessionDeathDate = storeService.session.get.deathDate();
    const sessionIdentificationType = storeService.session.get.deathIdentificationType();
    const sessionSelectionType = storeService.session.get.deathSelectionType();
    const sessionBatchList = storeService.session.get.deathBatchList();
    const sessionAnimalsBeingMoved = storeService.session.get.deathAnimalsBeingMoved();

    if (sessionDeathDate) {
      setDeathDate(sessionDeathDate);
      validateDate(sessionDeathDate, constants.dateTypes.death);
    }

    if (sessionIdentificationType) {
      setIdentificationType(sessionIdentificationType);
    }

    if (sessionSelectionType) {
      setSelectionType(sessionSelectionType);
    }

    if (sessionBatchList) {
      setBatchList(sessionBatchList);
    }

    if (sessionAnimalsBeingMoved) {
      setAnimalsBeingMoved(sessionAnimalsBeingMoved);
    }

    if (helpers.species.isCattleId(speciesId)) {
      const sessionCalfDiedBeforeTagging = storeService.session.get.deathDiedBeforeTagging();
      const sessionBirthDate = storeService.session.get.deathBirthDate();
      const sessionBirthDamId = storeService.session.get.deathBirthDamId();
      const sessionBreed = storeService.session.get.deathBreed();

      if (sessionCalfDiedBeforeTagging) {
        setCalfDiedBeforeTagging(true);
      }

      if (sessionBirthDate) {
        setBirthDate(sessionBirthDate);
        validateDate(sessionBirthDate, constants.dateTypes.birth);
      }

      if (sessionBirthDamId) {
        setBirthDamId(sessionBirthDamId);
      }

      if (sessionBreed) {
        setBreedId(sessionBreed.id);
        setBreedName(sessionBreed.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deathDate && deathDateError === '') {
      setDisableClfBefore(false);
    } else {
      setDisableClfBefore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deathDate, deathDateError]);

  useEffect(() => {
    if (deathDate && birthDate) {
      if (helpers.date.is.afterDate(birthDate, deathDate)) {
        setBirthDateError('error.dateOfBirthAfterDateOfDeath');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deathDate, birthDate]);

  useEffect(() => {
    let disableFlag = false;

    if (pending) {
      disableFlag = true;
    } else if (batchList?.some((batch) => helpers.batch.isInvalid(batch)) || animalsBeingMoved?.some((tag) => helpers.tag.isInvalid(tag))) {
      disableFlag = true;
    } else if (!deathDate || deathDateError !== '') {
      disableFlag = true;
    } else if (calfDiedBeforeTagging) {
      if (!birthDate || birthDateError !== '') {
        disableFlag = true;
      } else if (helpers.date.is.afterDate(birthDate, deathDate) || birthDamId === null || breedId === null) {
        disableFlag = true;
      }
    } else if (animalsBeingMoved?.length === 0 && batchList?.length === 0) {
      disableFlag = true;
    }

    setSubmitDisabled(disableFlag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending, deathDate, deathDateError, birthDate, birthDateError, birthDamId, breedId, calfDiedBeforeTagging, animalsBeingMoved, batchList]);

  useEffect(() => {
    if (renderTable) {
      setRenderTable();
    }
  }, [renderTable]);

  useEffect(() => {
    if (refreshFilters.refresh) {
      setRefreshFilters({});
    }
  }, [refreshFilters]);

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      individual: {
        ...prevState.individual,
        [constants.pagination.bottom]: {
          ...prevState.individual[constants.pagination.bottom],
          total: animalsBeingMoved?.length
        }
      }
    }));
  }, [animalsBeingMoved]);

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      batch: {
        ...prevState.batch,
        [constants.pagination.bottom]: {
          ...prevState.batch[constants.pagination.bottom],
          total: batchList?.length
        }
      }
    }));
  }, [batchList]);

  return (
    <>
      {ready &&
        <>
          <h2 className="h2">{t('deaths.date-and-animal-details')}</h2>

          <AboutPanel
            descriptions={[
              { text: 'about.deaths.desc' },
              { text: desc2Label }
            ]}
            title="about.genericTitle.step"
          />

          <div className="row">
            <div className="col">
              {calfDiedBeforeTagging ? display.deathDateStaticText() : display.deathDatePicker()}
              {isCattle ? display.calfDiedBeforeTaggingCheckbox() : display.identificationTypeOption()}
              {calfDiedBeforeTagging && display.birthDatePicker()}
              {!calfDiedBeforeTagging && helpers.option.identificationType.isBreedingTags(identificationType) && display.selectionType()}
              {isCattle && calfDiedBeforeTagging && display.optionsForCattleDiedBeforeTagging()}
            </div>
          </div>

          {!calfDiedBeforeTagging &&
            <div className="row">
              <div className="section">
                {helpers.option.identificationType.isBreedingTags(identificationType) &&
                  <>
                    {helpers.option.tagMethod.isSelectFromLists(selectionType) && display.selectFromHolding()}
                    {helpers.option.tagMethod.isTagsManually(selectionType) && display.manualTags()}
                  </>
                }
                {helpers.option.identificationType.isSlaughterTags(identificationType) && display.slaughterTags()}
                {display.selectedIndividualAnimals()}
                {display.selectedBatches()}
              </div>
            </div>
          }

          <Button
            buttonType="primary"
            disabled={submitDisabled}
            label="button.continue"
            onClick={nextPage}
          />
        </>
      }
    </>
  );
};

DeathsStep1.propTypes = {
  setDataChanged: PropTypes.func,
  setDeathData: PropTypes.func,
  setModal: PropTypes.func.isRequired
};

export default DeathsStep1;
