import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'pages/error/error.module.scss';
import PropTypes from 'prop-types';
import ErrorLabel from 'components/base/errorLabel/errorLabel';

const ErrorPage = ({
  error,
  showError
}) => {
  const { ready, t } = useTranslation();

  return (
    <>
      {ready &&
        <div className={classes.errorWrapper}>
          <h1>{t('error.unexpectedError1')}</h1>
          <p>{t('error.unexpectedError2')}</p>
          {showError &&
            <ErrorLabel
              label={error.message}
            />
          }
        </div>
      }
    </>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object.isRequired,
  showError: PropTypes.bool.isRequired
};

export default ErrorPage;
