import React from 'react';
import classes from 'components/welcomeBanner/welcomeBanner.module.scss';
import PropTypes from 'prop-types';

const WelcomeBanner = ({
  children,
  intro,
  title
}) => {
  return (
    <section className={classes.welcomeBanner}>
      <div className={'container ' + classes.welcomeBanner__container}>
        <h1 className={classes.welcomeBanner__title}>{title}</h1>
        <p className={classes.welcomeBanner__intro}>{intro}</p>
        <div className={classes.welcomeBanner__content}>{children}</div>
      </div>
    </section>
  );
};

WelcomeBanner.propTypes = {
  children: PropTypes.string,
  intro: PropTypes.string,
  title: PropTypes.string
};

export default WelcomeBanner;
