import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import BackgroundPage1 from 'templates/page1.png';
import BackgroundPage2 from 'templates/page2.png';
import BackgroundPage3Cattle from 'templates/page3-cattle.png';
import BackgroundPage3Deer from 'templates/page3-deer.png';
import BackgroundPage3Goats from 'templates/page3-goats.png';
import BackgroundPage3Pigs from 'templates/page3-pigs.png';
import BackgroundPage3Sheep from 'templates/page3-sheep.png';
import BottomPage3 from 'templates/signPage3.png';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import moment from 'moment';
const sprintf = require('util').format;

const pdfProperties = {
  page: {
    flexDirection: 'column',
    fontSize: '11px'
  },
  row: {
    flexGrow: 1
  },
  line: {
    flexDirection: 'row',
    margin: 3,
    padding: 2
  },
  section: {
    flexDirection: 'row',
    margin: 6,
    padding: 3,
    flexGrow: 1,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black'
  },
  section2: {
    margin: 6,
    padding: 3,
    flexGrow: 1,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black'
  },
  image: {
    margin: 0,
    padding: 0,
    top: 0,
    left: 0
  },
  exemptions: {
    border: '1px solid red',
    position: 'absolute',
    fontSize: '10px',
    fontWeight: 'bold',
    left: '555px',
    height: '14px',
    width: '14px'
  },
  exemptionsText: {
    position: 'absolute',
    fontSize: '10px',
    fontWeight: 'bold',
    left: '315px',
    width: '225px',
    wordWrap: 'normal',
    textAlign: 'right'
  },
  departureLocation: {
    position: 'absolute',
    top: '70px',
    width: '100%',
    height: '435px'
  },
  transportDetail: {
    position: 'absolute',
    top: '504px',
    width: '100%',
    height: '112px'
  },
  receivingLocation: {
    position: 'absolute',
    top: '616px',
    width: '100%',
    height: '200px'
  },
  fciDetail: {
    position: 'absolute',
    top: '435px',
    width: '100%',
    height: '200px'
  },
  extraSheetDetails: {
    position: 'absolute',
    top: '80px',
    width: '100%',
    height: '85px'
  },
  extraSheetTable: {
    left: '10px',
    width: '95%',
    border: '1px solid darkGrey'
  },
  addressToSendTo: {
    position: 'absolute',
    top: '780px',
    left: '28px',
    width: '90%'
  }
};

const MAX_FIELD_WIDTH = 20;
const zeroTimeList = ['0000', '0000Z'];
const haulageCompanyIgnoreList = ['Departure keeper', 'Receiving keeper'];

const MovementDocumentPDF = ({
  data
}) => {
  const { ready, t } = useTranslation();

  const { movementDocument } = data;
  const { transportDetail } = movementDocument;
  const departureDetail = movementDocument ? movementDocument.departureDetail : null;
  const transporterType = transportDetail && transportDetail.transporterType ? transportDetail.transporterType : null;

  // WORKAROUND CODED INTO THE FUNCTION BELOW FOR TICKET 31881
  const setDepartTime = (inValue) => {
    if (!inValue) {
      return '';
    }

    const arr = inValue.split('+')[0].split('T')[1].split(':');
    if (arr.length < 3) {
      return '';
    }

    return zeroTimeList.includes(arr[0] + arr[1]) ? '' : moment(inValue).format('h:mm A');
  };

  const getMetFCIWithdrawalPeriod = (updatedFciDetail) => {
    if (!data.metFCIWithdrawalPeriods) {
      return updatedFciDetail.withdrawalPeriodMetId;
    }
    const matchFCI = data.metFCIWithdrawalPeriods.filter((fciItem) => fciItem.id === updatedFciDetail.withdrawalPeriodMetId);
    return matchFCI ? matchFCI.name : updatedFciDetail.withdrawalPeriodMetId;
  };

  const truncateField = (field) => field && field.length > MAX_FIELD_WIDTH ? field.slice(0, MAX_FIELD_WIDTH - 2) + '...' : field;

  const getText = (html) => {
    const divContainer = document.createElement('div');
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || '';
  };

  const trimField = (field) => field ? truncateField(getText(field)) : '';

  const trimName = (first, second) => truncateField((first && first.length ? first.slice(0, 1) + '. ' : '') + (second ? getText(second) : ''));

  const getBatchList = (updatedBatches) => {
    let sorted = [];
    for (const batch of updatedBatches) {
      sorted.push(batch.batchNumber);
    }
    sorted = sorted.sort((a, b) => (a - b));
    let lastBatchNumber = '';
    const finalNumber = sorted[sorted.length - 1];
    const output = [];
    for (const batch of sorted) {
      if (lastBatchNumber !== batch) {
        const animalTotal = updatedBatches.filter((item) => batch === item.batchNumber).reduce((total, item) => total + item.animalTotal, 0);
        const tot = (finalNumber === batch) ? animalTotal : animalTotal + ',';
        output.push({ batchNumber: batch, animalTotal: tot });
      }
      lastBatchNumber = batch;
    }
    return output;
  };

  const tagOutput = (batchNumber, individualNumber, from, to, addComma) => sprintf('%s %s%s%s', batchNumber, individualNumber, from !== to ? '-' + to : '', addComma ? ',' : '');

  const getTagList = (deviceList) => {
    let tagList = [];
    const results = [];
    let batchNumber = '';
    let individualNumber = '';
    let activeNo1 = 0;
    let activeNo2 = 0;

    for (const device of deviceList) {
      tagList.push(device.tagNumber);
    }
    tagList = helpers.sortTags(tagList);
    for (const tagItem of tagList) {
      const rec = tagItem.split(' ');

      if (rec.length > 1) {
        const tempIndividualNumber = Number(rec[1]);

        if (batchNumber !== rec[0] || (activeNo2 + 1) !== tempIndividualNumber) {
          if (batchNumber.length > 0) {
            results.push(tagOutput(batchNumber, individualNumber, activeNo1, activeNo2, true));
          }
          batchNumber = rec[0];
          individualNumber = rec[1];
          activeNo1 = tempIndividualNumber;
        }
        activeNo2 = tempIndividualNumber;
      } else {
        results.push(tagItem);
      }
    }
    results.push(tagOutput(batchNumber, individualNumber, activeNo1, activeNo2, false));
    return results;
  };

  const getTransportTypeCheckout = () => {
    let style;
    switch (transporterType) {
      case constants.transporter.departureKeeper:
        style = { position: 'absolute', top: '17px', left: '214px', fontSize: '15px', fontWeight: 'bold' };
        break;

      case constants.transporter.destinationKeeper:
        style = { position: 'absolute', top: '17px', left: '329px', fontSize: '15px', fontWeight: 'bold' };
        break;

      case constants.transporter.haulier:
        style = { position: 'absolute', top: '17px', left: '451px', fontSize: '15px', fontWeight: 'bold' };
        break;

      default:
        return null;
    }

    return (
      <View style={style}>
        <Text>X</Text>
      </View>
    );
  };

  const getAuthNumber = (transporterAuthNumber) => {
    if (!transporterAuthNumber) {
      return null;
    }
    let style = { position: 'absolute', top: '86px', left: '170px' };

    if (transporterAuthNumber && transporterAuthNumber.length > MAX_FIELD_WIDTH) {
      // if auth number more than 20 characters, need to use smaller front, wrap text with newline and move up position a bit to fix two lines
      // otherwise use smaller front and move down position a bit
      style = { position: 'absolute', top: '86px', left: '164px', fontSize: '8px' };
      transporterAuthNumber = sprintf('%s%s', transporterAuthNumber.substr(0, 20),
        transporterAuthNumber.length > 20 ? '\n' + transporterAuthNumber.substr(20, transporterAuthNumber.length - 20) : '');
    }
    return (
      <View style={style}>
        <Text>{transporterAuthNumber}</Text>
      </View>
    );
  };

  const getFciCheckbox = (fciData) => {
    if (!fciData || fciData.isAllAnimalsFciCompliant === null) {
      return null;
    }
    const style = fciData.isAllAnimalsFciCompliant
      ? { position: 'absolute', top: '231px', left: '276px', fontSize: '12px' }
      : { position: 'absolute', top: '271px', left: '276px', fontSize: '12px' };
    return (
      <View style={style}>
        <Text>X</Text>
      </View>
    );
  };

  const displayAddress = (holding) => (
    <>
      <Text>{getText(holding.propertyName)}</Text>
      <Text>{getText(holding.address1)}</Text>
      <Text>{getText(holding.address2)}</Text>
      <Text>{getText(holding.town)}</Text>
      <Text>{getText(holding.county)}</Text>
    </>
  );

  const getHaulierCompanyName = (haulierName) => {
    if (!haulierName) {
      return haulierName;
    }
    const matchedName = haulageCompanyIgnoreList.filter((name) => name === haulierName.substr(0, name.length));
    return matchedName.length > 0 ? '' : haulierName;
  };

  const owner = (!departureDetail || !departureDetail.departureOwner) ? ''
    : trimName(departureDetail.departureOwner.firstName, departureDetail.departureOwner.lastName);

  let haulierCompanyName = '';
  let driverName = '';
  let driverTelephone = '';
  let loadingTime = '';
  let departTime = '';
  let duration = '';
  if (transportDetail && transportDetail.recordTransportInformation !== constants.option.recordTransportInformation.no) {
    if (transportDetail.transporter) {
      driverName = trimName(transportDetail.transporter.firstName, transportDetail.transporter.lastName);
      driverTelephone = transportDetail.transporter.telephoneNumber;
    }
    haulierCompanyName = getHaulierCompanyName(transportDetail.transportHaulierName);

    if (departureDetail) {
      loadingTime = departureDetail.loadingDateTime ? departureDetail.loadingDateTime : '';
      departTime = setDepartTime(departureDetail.departureDateTime);

      const durationOfJourney = departureDetail.expectedDurationOfJourney;

      if (durationOfJourney) {
        const days = sprintf('%s %s ', durationOfJourney.days, t(durationOfJourney.days > 1 ? 'label.days_lower' : 'label.day_lower').toLowerCase());
        const hours = sprintf('%s%s ', durationOfJourney.hours, t('movements.transport-information.transport-details.hours').toLowerCase().charAt(0));
        const minutes = sprintf('%s%s%s ', durationOfJourney.minutes < 10 ? '0' : '', durationOfJourney.minutes, t('movements.transport-information.transport-details.minutes').toLowerCase().charAt(0));

        if (durationOfJourney.days > 0) {
          duration += days;
        }

        if (durationOfJourney.hours > 0 || durationOfJourney.minutes > 0) {
          duration += hours + minutes;
        }
      }
    }
  }

  const styles = StyleSheet.create(pdfProperties);

  const batches = data.batches?.length > 0
    ? getBatchList(data.batches).map((batch, index) =>
      <View key={index} style={styles.line}>
        <View style={styles.row}>
          <Text>{batch.batchNumber} x {batch.animalTotal}</Text>
        </View>
      </View>) : [];

  const devices = data.devices?.length > 0
    ? getTagList(data.devices).map((device, index) =>
      <View key={index} style={styles.line}>
        <View style={styles.row}>
          <Text>{device}</Text>
        </View>
      </View>) : [];

  const devicesBreak1 = Math.ceil(devices.length / 4);
  const devicesBreak2 = devicesBreak1 * 2;
  const devicesBreak3 = devicesBreak1 * 3;

  const batchesBreak1 = Math.ceil(batches.length / 4);
  const batchesBreak2 = batchesBreak1 * 2;
  const batchesBreak3 = batchesBreak1 * 3;

  const exempt = data.processingFlags?.all?.length > 0
    ? data.processingFlags.all.map((rec) =>
      <View key={rec.id} style={styles.line}>
        <View style={{ width: '255px', textAlign: 'right', marginRight: '12px', fontSize: '7px' }}>
          <Text>{rec.label}</Text>
        </View>
        <View style={{ border: '1px solid black', fontSize: '6px', height: '10px', textAlign: 'right', width: '10px', marginRight: '10px', paddingRight: '2px', paddingTop: '1px' }}>
          <Text>{rec.value ? 'X' : ' '}</Text>
        </View>
      </View>) : <></>;

  // FCI Detail Section
  const fciDetail = data.movementDocument ? data.movementDocument.fciDetail : null;
  let tagNumberDisplayed = '';
  if (fciDetail && fciDetail.nonCompliantDevices && fciDetail.nonCompliantDevices.length) {
    fciDetail.nonCompliantDevices.forEach((tag) => {
      tagNumberDisplayed += tag.tagNumber + ' ';
    });
  }

  const departureFullName = data.departAddress ? getText([data.departAddress.firstName, data.departAddress.lastName].filter(Boolean).join(' ')) : '';
  const keeperName = data.departAddress && data.departAddress.keeper ? getText([data.departAddress.keeper.title, data.departAddress.keeper.firstName, data.departAddress.keeper.lastName].filter(Boolean).join(' ')) : '';

  const render = {
    page3Background: () => {
      switch (data.species) {
        case constants.species.internalLabel.CATTLE:
          return BackgroundPage3Cattle;
        case constants.species.internalLabel.DEER:
          return BackgroundPage3Deer;
        case constants.species.internalLabel.GOATS:
          return BackgroundPage3Goats;
        case constants.species.internalLabel.PIGS:
          return BackgroundPage3Pigs;
        case constants.species.internalLabel.SHEEP:
        default:
          return BackgroundPage3Sheep;
      }
    },

    pdfFooter: (page, totalPages) => (
      <>
        <View style={{ position: 'absolute', top: '815px', left: '20px', fontSize: '10px' }}>
          <Text>{t('label.movementRef') + ' : ' + data.documentRef + ' - ' + data.species}</Text>
        </View>
        <View style={{ position: 'absolute', top: '815px', left: '525px', fontSize: '10px' }}>
          <Text>Page {page} of {totalPages}</Text>
        </View>
      </>
    )
  };

  const PDF_Page1 = () => (
    <Page size="A4" style={styles.page}>
      <Image
        src={BackgroundPage1}
        style={styles.image}
      />

      <View style={styles.departureLocation}>
        <View style={{ position: 'absolute', top: '25px', left: '120px' }}>
          <Text>{data.fromHolding}</Text>
        </View>

        {data.departAddress &&
        <>
          <View style={{ position: 'absolute', top: '58px', left: '36px', fontSize: '8px' }}>
            <Text>{keeperName}</Text>
            {displayAddress(data.departAddress)}
          </View>
          <View style={{ position: 'absolute', top: '98px', left: '225px' }}>
            <Text>{data.departAddress.postCode}</Text>
          </View>
        </>
        }

        {data.destAddress &&
        <>
          <View style={{ position: 'absolute', top: '140px', left: '36px', fontSize: '8px' }}>
            {displayAddress(data.destAddress)}
          </View>
          <View style={{ position: 'absolute', top: '176px', left: '225px' }}>
            <Text>{data.destAddress.postCode}</Text>
          </View>
        </>
        }

        {getFciCheckbox(movementDocument.fciDetail)}

        <View style={{ position: 'absolute', top: '25px', left: '311px', width: '280px', height: '73px', textAlign: 'center', fontSize: '10px' }}>
          <View style={{ position: 'absolute', top: '0px' }}>
            <Text>{sprintf('%s %s %s', t('pdf.totalTitle1'),
              data.animalTotal, t(data.animalTotal > 1 ? 'pdf.totalTitle3' : 'pdf.totalTitle2'))}
            </Text>
          </View>
        </View>

        <View style={{ position: 'absolute', top: '50px', left: '311px', width: '280px', height: '265px' }}>
          <View style={styles.row}>
            {exempt}
          </View>
        </View>

        <View style={{ position: 'absolute', top: '345px', left: '140px' }}>
          <Text>{departureFullName}</Text>
        </View>

        <View style={{ position: 'absolute', top: '367px', left: '140px' }}>
          <Text>{data.departAddress ? data.departAddress.telephoneNumber : ''}</Text>
        </View>

        <View style={{ position: 'absolute', top: '410px', left: '140px' }}>
          <Text>{owner}</Text>
        </View>

        <View style={{ position: 'absolute', top: '322px', left: '475px' }}>
          <Text>{moment(data.movementDate).format('DD/MM/YYYY')}</Text>
        </View>

        {transportDetail &&
        <>
          <View style={{ position: 'absolute', top: '345px', left: '475px' }}>
            <Text>{loadingTime}</Text>
          </View>

          <View style={{ position: 'absolute', top: '367px', left: '475px' }}>
            <Text>{loadingTime}</Text>
          </View>

          <View style={{ position: 'absolute', top: '388px', left: '475px' }}>
            <Text>{departTime}</Text>
          </View>

          <View style={{ position: 'absolute', top: '410px', left: '475px' }}>
            <Text>{duration}</Text>
          </View>
        </>
        }
      </View>

      <View style={styles.transportDetail}>
        {getTransportTypeCheckout()}

        <View style={{ position: 'absolute', top: '42px', left: '170px' }}>
          <Text>{data.transportVehicleRegistrationNo}</Text>
        </View>

        <View style={{ position: 'absolute', top: '64px', left: '170px' }}>
          <Text>{trimField(haulierCompanyName)}</Text>
        </View>

        {transportDetail && getAuthNumber(transportDetail.transporterAuthNumber)}

        <View style={{ position: 'absolute', top: '64px', left: '425px' }}>
          <Text>{getText(driverName)}</Text>
        </View>

        <View style={{ position: 'absolute', top: '85px', left: '425px' }}>
          <Text>{driverTelephone}</Text>
        </View>
      </View>

      <View style={styles.receivingLocation}>
        <View style={{ position: 'absolute', top: '19px', left: '170px' }}>
          <Text>{data.toHolding}</Text>
        </View>

        <View style={{ position: 'absolute', top: '19px', left: '475px' }}>
          <Text>{moment(data.movementDate).format('DD/MM/YYYY')}</Text>
        </View>

        <View style={{ position: 'absolute', top: '56px', left: '170px' }}>
          <Text>{data.animalTotal}</Text>
        </View>
      </View>
      {/* Adjusted left position in addressToSendTo3 for the alignment issue */}
      <View style={styles.addressToSendTo}>
        <Text style={{ fontSize: '8px' }}>{t('pdf.addressToSendTo1')}</Text>
        <Text style={{ fontSize: '8px', position: 'absolute', left: '52px', fontFamily: 'Helvetica-Bold' }}>{t('pdf.addressToSendTo2')}</Text>
        <Text style={{ fontSize: '8px', position: 'absolute', left: '354px' }}>{t('pdf.addressToSendTo3')}</Text>
        <Text style={{ fontSize: '8px', position: 'absolute', top: '10px' }}>{t('pdf.addressToSendTo4')}</Text>
      </View>

      {render.pdfFooter(1, 3)}
    </Page>
  );

  const PDF_Page2 = () => (
    <Page size="A4" style={styles.page}>
      <Image
        src={BackgroundPage2}
        style={styles.image}
      />

      {fciDetail &&
      <View style={styles.fciDetail}>
        <View style={{ position: 'absolute', top: '25px', left: '25px', width: '540px', height: '50px' }}>
          <Text>{tagNumberDisplayed}</Text>
        </View>

        <View style={{ position: 'absolute', top: '95px', left: '25px', width: '540px', height: '40px' }}>
          <Text>{getText(fciDetail.nonCompliantReason)}</Text>
        </View>

        <View style={{ position: 'absolute', top: '150px', left: '315px', width: '255px', height: '40px' }}>
          <Text>{getMetFCIWithdrawalPeriod(fciDetail)}</Text>
        </View>

        <View style={{ position: 'absolute', top: '140px', left: '315px', width: '540px', height: '25px', fontSize: '10px' }}>
          <Text>{getText(fciDetail.withdrawalPeriodObservedText)}</Text>
        </View>

        <View style={{ position: 'absolute', top: '215px', left: '25px', width: '540px', height: '25px' }}>
          <Text>{getText(fciDetail.holdingRestrictions)}</Text>
        </View>
      </View>
      }

      {render.pdfFooter(2, 3)}
    </Page>
  );

  const PDF_Page3 = () => (
    <Page size="A4" style={styles.page}>
      <Image
        src={render.page3Background()}
        style={styles.image}
      />

      <View style={styles.extraSheetDetails}>
        <View style={{ position: 'absolute', top: '69px', left: '20px' }}>
          <Text>{data.fromHolding}</Text>
        </View>

        <View style={{ position: 'absolute', top: '69px', left: '205px' }}>
          <Text>{data.toHolding}</Text>
        </View>

        <View style={{ position: 'absolute', top: '69px', left: '415px' }}>
          <Text>{moment(data.movementDate).format('DD/MM/YYYY')}</Text>
        </View>
      </View>

      <View style={styles.extraSheetTable}>
        <View style={{ height: '18px', backgroundColor: '#365738' }} >
          <View style={{ position: 'absolute', top: '3px', color: 'white', left: '10px' }}>
            <Text>{t('pdf.animalsBatches')}{t('pdf.sep')}{data.totalBatchAnimals}</Text>
          </View>
        </View>

        {data.totalBatchAnimals > 0 &&
        <View style={{ display: 'block', height: '100px', width: '550px' }} >
          <View style={{ display: 'table-cell', top: '0', left: '0', height: '400px', width: '140px' }}>
            {batches.slice(0, batchesBreak1)}
          </View>

          <View style={{ display: 'table-cell', top: '-25px', left: '140px', height: '400px', width: '140px' }}>
            {batches.slice(batchesBreak1, batchesBreak2)}
          </View>

          <View style={{ display: 'table-cell', top: '-50px', left: '280px', height: '400px', width: '140px' }}>
            {batches.slice(batchesBreak2, batchesBreak3)}
          </View>

          <View style={{ display: 'table-cell', top: '-75px', left: '420px', height: '400px', width: '140px' }}>
            {batches.slice(batchesBreak3, batches.length)}
          </View>
        </View>
        }

        <View style={{ height: '18px', backgroundColor: '#365738' }} >
          <View style={{ position: 'absolute', top: '3px', color: 'white', left: '10px' }}>
            <Text>{t('pdf.animalsIndividual')}{t('pdf.sep')}{data.totalIndividualAnimals}</Text>
          </View>
        </View>

        {data.totalIndividualAnimals > 0 &&
        <View style={{ display: 'block', height: '400px', width: '550px' }} >
          <View style={{ display: 'table-cell', top: '0', left: '0', height: '400px', width: '140px' }}>
            {devices.slice(0, devicesBreak1)}
          </View>

          <View style={{ display: 'table-cell', top: '-100px', left: '140px', height: '400px', width: '140px' }}>
            {devices.slice(devicesBreak1, devicesBreak2)}
          </View>

          <View style={{ display: 'table-cell', top: '-200px', left: '280px', height: '400px', width: '140px' }}>
            {devices.slice(devicesBreak2, devicesBreak3)}
          </View>

          <View style={{ display: 'table-cell', top: '-300px', left: '420px', height: '400px', width: '140px' }}>
            {devices.slice(devicesBreak3, devices.length)}
          </View>
        </View>
        }
        <View style={{ height: '18px', backgroundColor: '#365738' }} >
          <View style={{ position: 'absolute', top: '3px', color: 'white', left: '10px' }}>
            <Text>{t('pdf.animalsTotal')}{t('pdf.sep')}{data.animalTotal}</Text>
          </View>
        </View>
      </View>

      <Image
        src={BottomPage3}
        style={styles.image}
      />
      {render.pdfFooter(3, 3)}
    </Page>
  );

  return (
    <>
      {ready &&
      <Document>
        {PDF_Page1()}
        {PDF_Page2()}
        {PDF_Page3()}
      </Document>
      }
    </>
  );
};

MovementDocumentPDF.propTypes = {
  data: PropTypes.object.isRequired
};

export default MovementDocumentPDF;
