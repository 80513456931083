import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/checkbox/checkbox.module.scss';
import Legend from 'components/base/legend/legend';
import Tooltip from 'components/base/tooltip/tooltip';

const Checkbox = ({
  checkboxClassNames,
  disabled,
  id,
  inline,
  isChecked,
  label,
  legend,
  name,
  onChange,
  tooltip,
  value
}) => {
  const { ready, t } = useTranslation();

  const componentClassNames = [
    classes.checkbox,
    disabled ? classes.disabled : '',
    inline ? classes.inline : ''
  ].join(' ').trim();

  const checkboxClasses = [
    classes.checkmark,
    ...(checkboxClassNames ? checkboxClassNames.map((className) => classes[className]) : [])
  ].join(' ').trim();

  return (
    <>
      {ready &&
        <>
          {legend &&
            <Legend
              legend={legend}
            />
          }

          <div className={componentClassNames}>
            <label htmlFor={id}>
              {t(label)}
              <input
                checked={isChecked}
                disabled={disabled}
                id={id}
                name={name}
                onChange={onChange}
                type="checkbox"
                value={value}
              />
              <span className={checkboxClasses} />
            </label>

            {tooltip &&
              <Tooltip
                tooltip={tooltip}
              />
            }
          </div>
        </>
      }
    </>
  );
};

Checkbox.propTypes = {
  checkboxClassNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  isChecked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  legend: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
  value: PropTypes.bool
};

export default Checkbox;
