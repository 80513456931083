import React from 'react';
import PropTypes from 'prop-types';
import helpers from 'services/helpers';
import { fieldWidthType } from 'services/constants';
import classes from 'components/base/cphField/cphField.module.scss';
import TextEntry from 'components/base/textEntry/textEntry';

const CPHField = ({
  classNames,
  cph,
  disabled,
  error,
  hideTooltip,
  id,
  isFormGroup,
  label,
  onBlur,
  optional,
  readOnly,
  setCph,
  warningLabel,
  width
}) => {
  const [key, setKey] = React.useState(undefined);

  const formGroupClassName = [
    isFormGroup ? classes.formGroup : ''
  ].join(' ').trim();

  const handleChanges = {
    onChange: (event) => {
      const element = event.target;
      let caret = element.selectionStart;
      let value = element.value.split('');

      // Update value and caret around separators
      if ((caret === 3 || caret === 7) && key !== 'Delete' && key !== 'Backspace') {
        caret++;
      } else if ((caret === 2 || caret === 6) && key === 'Backspace') {
        value.splice(caret - 1, 1);
        caret--;
      } else if ((caret === 2 || caret === 6) && key === 'Delete') {
        value.splice(caret, 1);
      }

      // Update caret for non-digits
      if (key?.length === 1 && (/[^0-9]/).test(key)) {
        caret--;
      }

      value = value.join('')
        // Limit input to only digits
        .replace(/[^0-9]+/g, '')
        // Limit input to 9 digits
        .replace(/(.{9}).*$/, '$1')
        // Insert "/" between groups of digits
        .replace(/^(.?.?)(.?.?.?)(.?.?.?.?)$/, '$1/$2/$3')
        // Remove "/" at the end
        .replace(/\/*$/, '');

      setCph(value);

      // "setTimeout" to update caret after setValue
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret);
      });
    },

    onKeyDown: (event) => {
      setKey(event.key);
    },

    onKeyUp: (event, id) => {
      const element = document.getElementById(id);

      if (event.keyCode < 48 || event.keyCode > 57) { // Any non-numeric key
        return;
      }

      const selection = window.getSelection().toString();
      if (selection !== '') {
        const selectionStart = element.selectionStart;
        const selectionEnd = element.selectionEnd;

        const newCphNumber = cph.substring(0, selectionStart) + event.key + cph.substr(selectionEnd);
        helpers.setCaretPosition(element, selectionStart + 1);
        setCph(newCphNumber);
      }
    }
  };

  return (
    <div className={formGroupClassName}>
      <TextEntry
        classNames={classNames}
        disabled={disabled}
        errorLabel={error}
        id={id}
        label={label}
        name={id}
        onBlur={onBlur}
        onChange={handleChanges.onChange}
        onKeyDown={handleChanges.onKeyDown}
        onKeyUp={handleChanges.onKeyUp}
        optional={optional}
        placeholder="   /     /"
        readOnly={readOnly}
        tooltip={hideTooltip ? '' : 'label.holdingCPHTooltip'}
        value={cph}
        warningLabel={warningLabel}
        width={width}
      />
    </div>
  );
};

CPHField.propTypes = {
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  cph: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hideTooltip: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isFormGroup: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  optional: PropTypes.bool,
  readOnly: PropTypes.bool,
  setCph: PropTypes.func,
  warningLabel: PropTypes.string,
  width: PropTypes.oneOf([
    fieldWidthType.HALF,
    fieldWidthType.QUARTER
  ])
};

export default CPHField;
