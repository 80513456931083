import React from 'react';
import { isCancel } from 'services/bff';
import classes from 'components/addReplacementTag/addReplacementTag.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import Button from 'components/base/button/button';
import DropdownBatchNumbersAvailable from 'components/dropdownBatchNumbersAvailable/dropdownBatchNumbersAvailable';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Hint from 'components/base/hint/hint';
import Legend from 'components/base/legend/legend';
import NumberEntry from 'components/base/numberEntry/numberEntry';
import TextEntry from 'components/base/textEntry/textEntry';

const invalidTagNumber = (tagNumber) => !tagNumber || (tagNumber && tagNumber.length < 14);
const invalidBatchNumber = (batchNumber) => !batchNumber || (batchNumber && batchNumber.length < 8);

const get = {
  batchNumber: (batch) => batch.formattedBatchNumber || batch.originalBatchNumber,
  isValidState: (value) => value.state === constants.tag.valid || value.state === constants.tag.reformatted,
  tagNumber: (tag) => tag.formattedDevice?.tagNumber || tag.originalIdentifier,
  state: (value) => value ? constants.tag.valid : constants.tag.invalid
};

const map = {
  with1Batch: (newBatch, quantity) => ({
    oldBatch: { batch: config.UNKNOWN.toUpperCase(), valid: constants.tag.valid },
    quantity,
    newBatch: { batch: get.batchNumber(newBatch), valid: newBatch.state },
    valid: !helpers.batch.isInvalid(newBatch) ? constants.tag.valid : constants.tag.invalid
  }),

  with1Tag: (newTag) => ({
    oldTag: { tag: config.UNKNOWN.toUpperCase(), valid: constants.tag.valid },
    newTag: { tag: get.tagNumber(newTag), valid: newTag.state },
    valid: get.state(get.isValidState(newTag))
  }),

  with2Batches: (oldBatch, newBatch, quantity) => ({
    oldBatch: { batch: get.batchNumber(oldBatch), valid: oldBatch.state },
    quantity,
    newBatch: { batch: get.batchNumber(newBatch), valid: newBatch.state },
    valid: !helpers.batch.isInvalid(oldBatch) && !helpers.batch.isInvalid(newBatch) ? constants.tag.valid : constants.tag.invalid
  }),

  with2Tags: (oldTag, newTag) => ({
    oldTag: { tag: get.tagNumber(oldTag), valid: oldTag.state },
    newTag: { tag: get.tagNumber(newTag), valid: newTag.state },
    valid: get.state(get.isValidState(oldTag) && get.isValidState(newTag))
  }),

  withTagAndBatch: (oldBatch, newTag) => ({
    oldTag: { tag: get.batchNumber(oldBatch), valid: oldBatch.state },
    newTag: { tag: get.tagNumber(newTag), valid: newTag.state },
    valid: get.state(get.isValidState(oldBatch) && get.isValidState(newTag))
  })
};

const AddReplacementTag = ({
  newBatchNumber,
  newIndividualNumber,
  newTagNumber,
  newTagNumberError,
  oldBatchNumber,
  oldTagNumber,
  oldTagNumberError,
  quantity,
  selectionMethod,
  setAnimalsToChange,
  setModal,
  setNewBatchNumber,
  setNewIndividualNumber,
  setNewTagNumber,
  setOldBatchNumber,
  setOldTagNumber,
  setQuantity,
  tagType
}) => {
  const isBreedingTags = helpers.option.tagType.isBreeding(tagType);
  const isSlaughterTag = helpers.option.tagType.isSlaughter(tagType);
  const isUpgrade = helpers.option.tagType.isUpgrade(tagType);

  const isOnHolding = helpers.option.tagMethod.isOnHolding(selectionMethod);
  const isTagsManually = helpers.option.tagMethod.isTagsManually(selectionMethod);

  const species = storeService.session.get.species();
  const speciesId = species?.id;

  const content = {
    [constants.option.tag.breedingTags]: {
      header: 'tagging.replacement.enterReplacementTag',
      hint: 'tagging.replacement.enterReplacementTagHint'
    },
    [constants.option.tag.slaughterTags]: {
      header: 'tagging.replacement.batchReplacementTitle' + speciesId,
      hint: 'tagging.replacement.batchReplacementHint' + speciesId
    },
    [constants.option.tag.upgradeTags]: {
      header: 'tagging.replacement.enterReplacementTag',
      hint: 'tagging.replacement.enterReplacementSlaughterTagHint'
    }
  };

  const [pending, setPending] = React.useState(false);

  const handleManualTag = (oldTag, newTag) => {
    helpers.validate.tagNumbers(species.name, [], oldTag ? [oldTag, newTag] : [newTag])
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          if (oldTag) {
            setAnimalsToChange(map.with2Tags(res.data.tagNumbers[0], res.data.tagNumbers[1]));
          } else {
            setAnimalsToChange(map.with1Tag(res.data.tagNumbers[0]));
          }

          setOldTagNumber('');
          setNewTagNumber('');
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  const handleUpgradeTag = (oldBatch, newTag) => {
    const oldFieldIsBatch = oldBatch && oldBatch.length <= 9;
    const oldFieldIsTag = oldBatch && oldBatch.length > 9;

    helpers.validate.tagNumbers(species.name, oldFieldIsBatch ? [oldBatch] : [], oldFieldIsTag ? [oldBatch, newTag] : [newTag])
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          if (oldFieldIsBatch) {
            setAnimalsToChange(map.withTagAndBatch(res.data.batchNumbers[0], res.data.tagNumbers[0]));
          } else if (oldFieldIsTag) {
            setAnimalsToChange(map.with2Tags(res.data.tagNumbers[0], res.data.tagNumbers[1]));
          } else {
            setAnimalsToChange(map.with1Tag(res.data.tagNumbers[0]));
          }

          setOldBatchNumber('');
          setNewTagNumber('');
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  const handleSlaughterTag = (oldBatch, newBatch, quantity) => {
    helpers.validate.tagNumbers(species.name, oldBatch ? [oldBatch, newBatch] : [newBatch])
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          const resBatches = res.data.batchNumbers.map((item) => ({
            ...item,
            valid: item.state
          }));
          if (oldBatch) {
            setAnimalsToChange(map.with2Batches(resBatches[0], resBatches[1], quantity));
          } else {
            setAnimalsToChange(map.with1Batch(resBatches[0], quantity));
          }

          setOldBatchNumber('');
          setNewBatchNumber('');
          setQuantity('');
          setPending(false);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setPending(false);
          errors.BFF(error, setModal);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  const handleOnHolding = (oldTag, newBatch, newIndividualNo) => {
    helpers.validate.tagNumbers(species.name, [], [newBatch + ' ' + newIndividualNo])
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          const firstTag = res.data.tagNumbers[0];
          setAnimalsToChange({
            oldTag: {
              tag: oldTag,
              valid: constants.tag.valid
            },
            newTag: {
              tag: firstTag.formattedDevice?.tagNumber || firstTag.originalIdentifier,
              valid: firstTag.state
            },
            valid: firstTag.state
          });

          setOldTagNumber('');
          setNewBatchNumber('');
          setNewIndividualNumber('');
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  const handleChanges = {
    padTagNumber: (batch, tag) => {
      const paddedTagNumber = helpers.tag.zerosPadLeft(speciesId, batch, tag);

      setNewIndividualNumber(paddedTagNumber);
    },

    validateTags: () => {
      setPending(true);

      if (isBreedingTags) {
        if (isOnHolding) {
          handleOnHolding(oldTagNumber, newBatchNumber, newIndividualNumber);
        } else if (isTagsManually) {
          handleManualTag(oldTagNumber, newTagNumber);
        }
      } else if (isUpgrade) {
        handleUpgradeTag(oldBatchNumber, newTagNumber);
      } else if (isSlaughterTag) {
        handleSlaughterTag(oldBatchNumber, newBatchNumber, quantity);
      }
    }
  };

  return (
    <fieldset role="group">
      <Legend
        legend={content[tagType].header}
      />

      {((isBreedingTags && isTagsManually) || (isSlaughterTag)) &&
        <Hint
          hint={content[tagType].hint}
        />
      }

      {isBreedingTags &&
        <>
          {isOnHolding &&
            <>
              <Hint hint={'prompt.selectAvailableBatch-tagReplace-' + speciesId} />
              <div className={classes.addReplacementTag}>
                <div className={classes.input}>
                  <TextEntry
                    disabled={true}
                    hideCharacterCount={true}
                    id="currentTag"
                    label={isBreedingTags ? 'tagging.replacement.oldTagNumber' : 'tagging.replacement.oldSlaughterTag'}
                    maxLength={config.WIDTH_TAGS}
                    name="currentTag"
                    value={oldTagNumber || ''}
                  />
                </div>
                <div className={classes.newTagNumberWrapper}>
                  {newTagNumberError &&
                    <ErrorLabel
                      label={newTagNumberError}
                    />
                  }
                  <div className={classes.newTagNumber}>
                    <div className={classes.input + ' ' + classes.batchNumber}>
                      <DropdownBatchNumbersAvailable
                        label={'label.newBatchNumber' + speciesId}
                        name="availableBatches"
                        onChange={(event) => setNewBatchNumber(event.target.value)}
                        setModal={setModal}
                        validationPending={pending}
                        value={newBatchNumber}
                      />
                    </div>
                    <div className={classes.input + ' ' + classes.tagNumber}>
                      <NumberEntry
                        disabled={pending}
                        id="tagNumber"
                        label="label.individualNumber"
                        max={helpers.species.isGoatId(speciesId) ? 999999 : 99999 }
                        min="1"
                        name="tagNumber"
                        onBlur={(event) => handleChanges.padTagNumber(newBatchNumber, event.target.value)}
                        onChange={(event) => setNewIndividualNumber(event.target.value)}
                        value={newIndividualNumber || ''}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  buttonType="secondary"
                  disabled={Boolean(pending ||
                    !newIndividualNumber ||
                    newTagNumberError ||
                    invalidBatchNumber(newBatchNumber) ||
                    invalidTagNumber(oldTagNumber)
                  )}
                  label="button.add"
                  onClick={handleChanges.validateTags}
                />
              </div>
            </>
          }

          {isTagsManually &&
            <div className={classes.addReplacementTag}>
              <div className={classes.input}>
                <TextEntry
                  disabled={pending}
                  errorLabel={oldTagNumberError}
                  hideCharacterCount={true}
                  id="oldTagNumber"
                  label="tagging.replacement.oldTagNumber"
                  maxLength={config.WIDTH_TAGS}
                  name="oldTagNumber"
                  onChange={(event) => setOldTagNumber(event.target.value)}
                  value={oldTagNumber || ''}
                />
              </div>
              <div className={classes.input}>
                <TextEntry
                  disabled={pending}
                  errorLabel={newTagNumberError}
                  hideCharacterCount={true}
                  id="newTagNumber"
                  label="tagging.replacement.replacementTagNumber"
                  maxLength={config.WIDTH_TAGS}
                  name="newTagNumber"
                  onChange={(event) => setNewTagNumber(event.target.value)}
                  value={newTagNumber || ''}
                />
              </div>
              <Button
                buttonType="secondary"
                disabled={Boolean((pending ||
                  oldTagNumberError ||
                  newTagNumberError ||
                  invalidTagNumber(oldTagNumber) ||
                  invalidTagNumber(newTagNumber)) &&
                  !helpers.tag.isValidTagNumber(newTagNumber)
                )}
                label="label.add"
                onClick={handleChanges.validateTags}
              />
            </div>
          }
        </>
      }

      {isSlaughterTag &&
        <div className={classes.addReplacementTag}>
          <div className={classes.input}>
            <TextEntry
              disabled={pending}
              hideCharacterCount={true}
              id="oldBatchNumber"
              label={'tagging.replacement.currentBatchNumber' + speciesId}
              maxLength={config.WIDTH_BATCH_NUMBER}
              name="oldBatchNumber"
              onChange={(event) => setOldBatchNumber(event.target.value.trim())}
              value={oldBatchNumber || ''}
            />
          </div>
          <div className={classes.input}>
            <NumberEntry
              disabled={pending}
              id="quantity"
              label="tagging.replacement.totalAnimals"
              min="1"
              name="quantity"
              onChange={(event) => setQuantity(event.target.value)}
              value={quantity || ''}
            />
          </div>
          <div className={classes.input}>
            <TextEntry
              disabled={pending}
              hideCharacterCount={true}
              id="newBatchNumber"
              label={'tagging.replacement.batchNumberReplacement' + speciesId}
              maxLength={config.WIDTH_BATCH_NUMBER}
              name="newBatchNumber"
              onChange={(event) => setNewBatchNumber(event.target.value.trim())}
              value={newBatchNumber || ''}
            />
          </div>
          <Button
            buttonType="secondary"
            disabled={Boolean((
              pending ||
              !quantity ||
              invalidBatchNumber(newBatchNumber) ||
              invalidBatchNumber(oldBatchNumber)) &&
              !helpers.batch.isValidBatch(newBatchNumber)
            )}
            label="button.add"
            onClick={handleChanges.validateTags}
          />
        </div>
      }

      {isUpgrade &&
        <div className={classes.addReplacementTag}>
          <div className={classes.input}>
            <TextEntry
              disabled={pending}
              hideCharacterCount={true}
              id="oldBatchNumber"
              label="tagging.replacement.oldSlaughterTag"
              maxLength={config.WIDTH_TAGS}
              name="oldBatchNumber"
              onChange={(event) => setOldBatchNumber(event.target.value)}
              value={oldBatchNumber || ''}
            />
          </div>
          <div className={classes.input}>
            <TextEntry
              disabled={pending}
              errorLabel={newTagNumberError}
              hideCharacterCount={true}
              id="newTagNumber"
              label="tagging.replacement.replacementTagNumber"
              maxLength={config.WIDTH_TAGS}
              name="newTagNumber"
              onChange={(event) => setNewTagNumber(event.target.value)}
              value={newTagNumber || ''}
            />
          </div>
          <Button
            buttonType="secondary"
            disabled={Boolean(
              pending ||
              newTagNumberError ||
              invalidTagNumber(newTagNumber) ||
              invalidBatchNumber(oldBatchNumber)
            )}
            label="label.add"
            onClick={handleChanges.validateTags}
          />
        </div>
      }
    </fieldset>
  );
};

AddReplacementTag.propTypes = {
  newBatchNumber: PropTypes.string,
  newIndividualNumber: PropTypes.string,
  newTagNumber: PropTypes.string,
  newTagNumberError: PropTypes.string,
  oldBatchNumber: PropTypes.string,
  oldTagNumber: PropTypes.string,
  oldTagNumberError: PropTypes.string,
  quantity: PropTypes.string,
  selectionMethod: PropTypes.string,
  setAnimalsToChange: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setNewBatchNumber: PropTypes.func,
  setNewIndividualNumber: PropTypes.func,
  setNewTagNumber: PropTypes.func,
  setOldBatchNumber: PropTypes.func,
  setOldTagNumber: PropTypes.func,
  setQuantity: PropTypes.func,
  tagType: PropTypes.string.isRequired
};

export default AddReplacementTag;
