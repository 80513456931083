import React from 'react';
import { useTranslation } from 'react-i18next';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import ErrorLabel from 'components/base/errorLabel/errorLabel';

const HoldingActivityCustomTable = ({
  data,
  pending
}) => {
  const { ready, t } = useTranslation();

  return (
    <>
      {ready &&
        <>
          {Object.keys(data).length === 0 &&
            <>
              {pending &&
                <ErrorLabel
                  isWarning={true}
                  label="label.fetchingHoldingDetails"
                />
              }

              {!pending &&
                <ErrorLabel
                  label="label.noData"
                />
              }
            </>
          }

          {Object.keys(data).length > 0 &&
            <table className="horizontal">
              <tbody>
                <tr>
                  <th>{t('label.holdingType')}</th>
                  <td>{data.holdingType?.fullName} [{data.holdingType?.name}]</td>
                </tr>
                <tr>
                  <th>{t('label.holdingAddress')}</th>
                  <td>
                    {[
                      data.address1,
                      data.address2,
                      data.town,
                      data.county,
                      data.postCode
                    ]
                      .filter((item) => item)
                      .join(', ')
                    }
                  </td>
                </tr>
                <tr>
                  <th>{t('label.batchNumbers')}</th>
                  <td>
                    {data.holdingRegisters?.holdingRegisterHoldingBatchNumbers.map((item, index) => (
                      <p key={index}>
                        {item.holdingBatchNumber.holdingBatchNumberId + ' - ' + helpers.species.idToName(item.holdingBatchNumber.speciesId)}
                      </p>
                    ))}
                  </td>
                </tr>
                {data.holdingType?.name === 'ABATTOIR' &&
                  <tr>
                    <th>{t('label.mhsNumber')}</th>
                    <td>{data.holdingRegisters?.holdingMHS.mhsNumber}</td>
                  </tr>
                }
                <tr>
                  <th>{t('label.productionTypes')}</th>
                  <td>
                    {data.holdingRegisters?.holdingRegisterProductionTypes.map((item) => item.productionType.name).join(', ')}
                  </td>
                </tr>
                <tr>
                  <th>{t('label.singleBusinessIdentifier')}</th>
                  <td>{data.holdingIntegration?.singleBusinessIdentifier}</td>
                </tr>
                <tr>
                  <th>{t('label.partyId')}</th>
                  <td>{data.holdingIntegration?.partyId}</td>
                </tr>
                <tr>
                  <th>{t('label.partyEmail')}</th>
                  <td>{data.userByCph?.samEmail}</td>
                </tr>
                <tr>
                  <th className="spacer-row"></th>
                  <td className="spacer-row"></td>
                </tr>
                <tr>
                  <th>{t('boApp.label.keeperName')}</th>
                  <td>
                    {[
                      data.keeper?.title,
                      data.keeper?.firstName,
                      data.keeper?.lastName
                    ]
                      .filter((item) => item)
                      .join(' ')
                    }
                  </td>
                </tr>
                <tr>
                  <th>{t('boApp.label.keeperAddress')}</th>
                  <td>
                    {[
                      data.keeper?.address1,
                      data.keeper?.address2,
                      data.keeper?.town,
                      data.keeper?.county,
                      data.keeper?.postCode
                    ]
                      .filter((item) => item)
                      .join(', ')
                    }
                  </td>
                </tr>
                <tr>
                  <th>{t('boApp.label.keeperTelephoneNumber')}</th>
                  <td>{data.keeper?.telephoneNumber}</td>
                </tr>
                <tr>
                  <th>{t('boApp.label.keeperMobileNumber')}</th>
                  <td>{data.keeper?.mobilePhone}</td>
                </tr>
                <tr>
                  <th>{t('boApp.label.keeperAddressPk')}</th>
                  <td>{data.holdingIntegration?.keeperAddressPK}</td>
                </tr>
              </tbody>
            </table>
          }
        </>
      }
    </>
  );
};

HoldingActivityCustomTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  pending: PropTypes.bool
};

export default HoldingActivityCustomTable;
