import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react'; // Import MsalProvider and useMsal
import { vetSignUpRequest } from 'services/b2cService';
import classes from 'components/vetAuthNavigation/vetAuthNavigation.module.scss';
import Button from 'components/base/button/button';

const VetAuthNavigation = () => {
  const { instance } = useMsal();

  const redirectToLogin = () => {
    instance.loginRedirect(vetSignUpRequest);
  };

  return (
    <div className={classes.authNavigation}>
      <AuthenticatedTemplate>
        <Button
          label={'button.vetSignIn'}
          onClick={() =>
            instance.logoutRedirect({ postLogoutRedirectUri: '/' })
          }
        />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button
          label={'button.vetSignIn'}
          onClick={redirectToLogin}
        />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default VetAuthNavigation;
