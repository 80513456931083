import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/sections/notes/userNotes.module.scss';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import storeService from 'services/storeService';
import PropTypes from 'prop-types';
import { tableColumns } from 'services/tableColumns';
import Button from 'backOfficeComponents/base/button/button';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import Table from 'components/table/table';

const UserNotes = ({
  notes,
  setDataChanged,
  setModal,
  setNotes
}) => {
  const { ready, t } = useTranslation();

  const paginationParams = constants.pagination.defaultParams;
  paginationParams[constants.pagination.bottom].total = notes?.length;

  const username = storeService.cookie.get.username();

  const [noteText, setNoteText] = useState('');

  const handleChanges = {
    addNote: () => {
      setNotes((prevState) => [
        ...prevState,
        {
          text: noteText,
          date: helpers.date.now(),
          user: username,
          canRemove: true
        }
      ]);
      setNoteText('');
      setDataChanged();
    },

    noteText: (note) => {
      setNoteText(note);
    },

    removeNote: (note) => {
      setNotes((prevState) => prevState.filter((item) => !(item.user === note.user && item.text === note.text && item.date === note.date)));
      setDataChanged();
    }
  };

  return (
    <>
      {ready &&
        <details className="accordion">
          <summary>
            <h2><i className="bi bi-sticky" /> {t('movementDetails.userNotes')}</h2>
          </summary>
          <div>
            <div className={classes.addUserNote}>
              <FieldEntry
                id="userNotes"
                labelPosition={constants.labelPosition.above}
                labelText="boApp.label.enterUserNote"
                maxLength={config.WIDTH_USER_NOTES}
                name="userNotes"
                onChange={(event) => handleChanges.noteText(event.target.value)}
                rows={config.ROWS_USER_NOTES}
                value={noteText}
                width={config.WIDTH_USER_NOTES}
              />

              <Button
                buttonType="secondary"
                disabled={!noteText.length}
                id="addNote"
                label="button.addNote"
                onClick={handleChanges.addNote}
              />
            </div>

            {notes?.length > 0 &&
              <Table
                columns={tableColumns.paperMovements.userNotes({ removeRow: handleChanges.removeNote })}
                data={notes}
                dataProvided={true}
                paginationParams={paginationParams}
                setData={setNotes}
                setModal={setModal}
              />
            }
          </div>
        </details>
      }
    </>
  );
};

UserNotes.propTypes = {
  notes: PropTypes.array,
  setDataChanged: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setNotes: PropTypes.func.isRequired
};

export default UserNotes;
