import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import classes from 'frontEnd/holdingRegister/holdingDetails/holdingDetails.module.scss';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import Address from 'components/address/address';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import NavBarSub from 'components/header/navBarSub/navBarSub';
import Button from 'components/base/button/button';
import Modal from 'components/base/modal/modal';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import ModalProductionTypes from 'components/modalProductionTypes/modalProductionTypes';

const HoldingRegisterDetails = ({
  setModal
}) => {
  const { ready, t } = useTranslation();

  const sessionHolding = storeService.session.get.holding();

  const [holding, setHolding] = React.useState(null);
  const [pending, setPending] = React.useState(true);
  const [batches, setBatches] = React.useState([]);
  const [productionTypes, setProductionTypes] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalHasChanges, setModalHasChanges] = React.useState(false);
  const [productionTypesSubmitted, setProductionTypesSubmitted] = React.useState(false);
  const [confirmCloseModal, setConfirmCloseModal] = React.useState(false);

  const getData = (source, sessionHolding) => {
    setPending(true);

    bff
      .get('/holdingProductionTypes', {
        cancelToken: source.token,
        params: {
          cph: sessionHolding.value
        }
      })
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          setBatches(res.data.holdingRegisterHoldingBatchNumbers);
          setProductionTypes(res.data.holdingRegisterProductionTypes);
          setPending(false);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setPending(false);
          errors.BFF(error, setModal);
        }
      });
  };

  const modal = {
    closeAndReset: () => {
      setModalVisible(false);
      setModalHasChanges(false);
    },

    getContent: () => {
      return (
        <ModalProductionTypes
          holdingProductionTypes={productionTypes.map((item) => ({
            id: item.productionType.id,
            name: item.productionType.name
          }))}
          modalClose={modal.hide}
          setModal={setModal}
          setModalHasChanges={setModalHasChanges}
          setProductionTypesSubmitted={setProductionTypesSubmitted}
        />
      );
    },

    getHeaderContent: () => {
      return t('label.productionTypes');
    },

    hide: (confirmClose) => {
      if (modalHasChanges && !confirmClose) {
        setConfirmCloseModal(true);
      } else if (confirmClose) {
        setConfirmCloseModal(false);
        modal.closeAndReset();
      } else {
        modal.closeAndReset();
      }
    },

    show: () => {
      setModalVisible(true);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (sessionHolding) {
      setHolding(storeService.session.get.holding());

      getData(source, sessionHolding);
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (productionTypesSubmitted) {
      getData(source, sessionHolding);
      setProductionTypesSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionTypesSubmitted]);

  return (
    <>
      {ready &&
        <>
          {modalVisible &&
            <>
              <Modal
                headerContent={modal.getHeaderContent()}
                id="modalProductionTypes"
                modalClose={() => modal.hide(false)}
              >
                {modal.getContent()}
              </Modal>

              {confirmCloseModal &&
                <ModalConfirm
                  currentOpenModalId="modalProductionTypes"
                  id="confirmCloseModal"
                  modalClose={() => setConfirmCloseModal(false)}
                  modalConfirm={() => modal.hide(true)}
                  modalMessage="warning.unsavedChanges2"
                  modalTitle="warning.youHaveUnsavedChanges"
                />
              }
            </>
          }

          <h1 className="h1">{t('reports.holdingRegister')}</h1>
          <NavBarSub />
          <h2 className="h2">{t('subMenu.holdingDetails')}</h2>
          <AboutPanel
            descriptions={[
              { text: 'about.holdingRegister.desc1', href: constants.url.defraGuidance }
            ]}
            title="about.holdingRegister.title"
          />

          {holding &&
            <>
              <div className={classes.summaryRow}>
                <div className={classes.label}>{t('label.nameOfRegisteredKeeper')}</div>
                <div className={classes.value}>
                  <Address
                    cph={holding.value}
                    format={{
                      address: constants.address.format.none,
                      name: constants.address.format.full
                    }}
                    queryKeeper={true}
                    setModal={setModal}
                  />
                </div>
              </div>
              <div className={classes.summaryRow}>
                <div className={classes.label}>{t('label.addressOfHolding')}</div>
                <div className={classes.value}>
                  <Address
                    cph={holding.value}
                    format={{
                      address: constants.address.format.full
                    }}
                    queryKeeper={true}
                    setModal={setModal}
                  />
                </div>
              </div>
              <div className={classes.summaryRow}>
                <div className={classes.label}>{t('label.holdingIdentifier')}</div>
                <div className={classes.value}>
                  {holding.value}
                </div>
              </div>
              <div className={classes.summaryRow}>
                <div className={classes.label}>{t('label.additionalIdentifiers')}</div>
                <div className={classes.value}>
                  {pending &&
                    <p className={classes.fetching}>{t('label.fetchingData')}</p>
                  }
                  {!pending &&
                    <p>---</p>
                  }
                </div>
              </div>
              <div className={classes.summaryRow}>
                <div className={classes.label}>{t('label.flockHerdNumber')}</div>
                <div className={classes.value}>
                  {pending &&
                    <p className={classes.fetching}>{t('label.fetchingData')}</p>
                  }
                  {!pending &&
                    <>
                      {batches?.length > 0 && batches.map((batch) => (
                        <div key={batch.holdingBatchNumber.holdingBatchNumberId}>{batch.holdingBatchNumber.holdingBatchNumberId}</div>
                      ))}
                      {batches?.length === 0 &&
                        <p>---</p>
                      }
                    </>
                  }
                </div>
              </div>
              <div className={classes.summaryRow}>
                <div className={classes.label}>{t('label.species')}</div>
                <div className={classes.value}>
                  {holding.animalTypes.map((animalType) => (
                    <div key={animalType.id}>{t('animal.' + animalType.id)}</div>
                  ))}
                </div>
              </div>
              <div className={classes.summaryRow}>
                <div className={classes.label}>{t('label.typesOfProduction')}</div>
                <div className={classes.value}>
                  {pending &&
                    <p className={classes.fetching}>{t('label.fetchingData')}</p>
                  }
                  {!pending &&
                    <>
                      {productionTypes?.length > 0 && productionTypes.map((type) => (
                        <div key={type.productionTypeId}>{type.productionType.name}</div>
                      ))}
                      {productionTypes?.length === 0 &&
                        <p>---</p>
                      }
                    </>
                  }
                </div>
                <div className={classes.addProductionTypesLink}>
                  <Button
                    buttonType="link"
                    label="label.addProductionTypes"
                    onClick={modal.show}
                  />
                </div>
              </div>
              <div className={classes.summaryRow}>
                <div className={classes.label}>{t('label.addressOfKeeper')}</div>
                <div className={classes.value}>
                  {pending &&
                    <p className={classes.fetching}>{t('label.fetchingData')}</p>
                  }
                  {!pending &&
                    <Address
                      cph={holding.value}
                      format={{
                        address: constants.address.format.keeper,
                        name: constants.address.format.none
                      }}
                      queryKeeper={true}
                      setModal={setModal}
                    />
                  }
                </div>
              </div>
            </>
          }
        </>
      }
    </>
  );
};

HoldingRegisterDetails.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default HoldingRegisterDetails;
