import bff, { isCancel } from 'services/bff';
import errors from 'services/errors';
import storeService from 'services/storeService';
import constants from 'services/constants';
import helpers from 'services/helpers';

const breedsHelper = {
  fetchBreeds: (setModal, setLoading) => {
    bff
      .get('/breeds')
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          const allBreeds = {};

          res.data.data.forEach((item) => {
            if (!allBreeds[item.speciesId]) {
              allBreeds[item.speciesId] = [];
            }

            allBreeds[item.speciesId].push({
              id: item.id,
              name: item.name.replace('&amp;', '&')
            });
          });

          Object.keys(allBreeds).forEach((speciesId) => helpers.sortArrayOfObjects(allBreeds[speciesId], 'name', constants.sorting.ascending));

          storeService.session.set.breedsAll(allBreeds);
        }

        setLoading((prevState) => ({
          ...prevState,
          breeds: false
        }));
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });
  },

  fetchBreedsOnHolding: (cancelToken, setModal, cph, speciesId, onHoldingDate, returnRequest) => {
    const request = bff
      .get('/breedsOnHolding', {
        cancelToken,
        params: {
          cph,
          speciesId,
          onHoldingDate: onHoldingDate ? onHoldingDate : helpers.date.now()
        }
      });

    if (returnRequest) {
      return request;
    }

    request
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          breedsHelper.prepareBreedsOnHolding(res, speciesId);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });
  },

  getBreedId: (breedName) => {
    const allBreeds = storeService.session.get.breedsAll();
    if (!allBreeds) {
      return null;
    }
    const currentSpecies = storeService.session.get.species();
    if (!currentSpecies) {
      return null;
    }
    const matchedBreeds = allBreeds[currentSpecies.id];
    if (!matchedBreeds) {
      return null;
    }
    const breedObject = matchedBreeds.find((item) => item.name === breedName);
    return breedObject ? breedObject.id : null;
  },

  getBreedName: (breedId) => {
    const allBreeds = storeService.session.get.breedsAll();
    if (!allBreeds) {
      return constants.unknown;
    }
    const currentSpecies = storeService.session.get.species();
    if (!currentSpecies) {
      return constants.unknown;
    }
    const matchedBreeds = allBreeds[currentSpecies.id];
    if (!matchedBreeds) {
      return constants.unknown;
    }
    const breedObject = matchedBreeds.find((item) => String(item.id) === breedId || item.id === breedId);
    return breedObject?.name ?? constants.unknown;
  },

  getBreeds: (addUnknown, setPending) => {
    const currentSpecies = storeService.session.get.species();
    const breedsAll = storeService.session.get.breedsAll();

    if (breedsAll && currentSpecies && breedsAll[currentSpecies.id]) {
      if (addUnknown) {
        breedsAll[currentSpecies.id].unshift({
          value: String(null),
          name: breedsHelper.getBreedName(null),
          label: breedsHelper.getBreedName(null)
        });
      }

      if (setPending) {
        setPending(false);
      }

      return breedsAll[currentSpecies.id].map((item) => ({
        ...item,
        label: item.name,
        value: String(item.id)
      }));
    }

    if (setPending) {
      setPending(false);
    }

    return [];
  },

  getBreedsOnHolding: (data) => {
    const breeds = [];
    const unknownBreedsPresent = data.filter((animal) => !animal.breedId);

    data.forEach((animal) => {
      if (animal.breedId && !breeds.find((item) => item.value === String(animal.breedId))) {
        const breedName = breedsHelper.getBreedName(animal.breedId);

        breeds.push({
          value: String(animal.breedId),
          name: breedName,
          label: breedName
        });
      }
    });

    helpers.sortArrayOfObjects(breeds, 'name', constants.sorting.ascending);

    if (unknownBreedsPresent) {
      breeds.unshift({
        value: String(null),
        name: breedsHelper.getBreedName(null),
        label: breedsHelper.getBreedName(null)
      });
    }

    return breeds;
  },

  prepareBreedsOnHolding: (res, speciesId) => {
    let breedsOnHolding = res?.data?.data?.map((breed) => ({
      id: breedsHelper.getBreedId(breed.breedName),
      name: breed.breedName
    }));

    if (breedsOnHolding) {
      const unknownBreedOnHolding = breedsOnHolding.find((breed) => breed.name === constants.unknown);

      if (unknownBreedOnHolding) {
        breedsOnHolding = breedsOnHolding.filter((breed) => breed.name !== constants.unknown);
        breedsOnHolding.unshift(unknownBreedOnHolding);
      } else {
        const allBreeds = storeService.session.get.breedsAll();

        if (Object.keys(allBreeds).length > 0 && allBreeds[speciesId]) {
          const unknownBreed = allBreeds[speciesId].find((breed) => breed.name === constants.unknown);
          breedsOnHolding.unshift(unknownBreed);
        }
      }
    }

    storeService.session.set.breedsOnHolding(breedsOnHolding);
  },

  update: (species) => {
    const sessionSpecies = storeService.session.get.species();

    if (!sessionSpecies || (sessionSpecies && species.id !== sessionSpecies.id)) {
      if (species.name !== constants.species.id.UNKNOWN) {
        storeService.session.set.species(species);
      }
    }
  }
};

export default breedsHelper;
