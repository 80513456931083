import { LogLevel } from '@azure/msal-browser';
import appInsightsService from 'services/appInsightsService';
import codeHelper from 'services/codeHelper';

const sprintf = require('util').format;

const b2cConfigJson = {
  credentials: {
    tenantName: codeHelper.B2C_TENANT,
    clientID: codeHelper.B2C_CLIENTID
  },
  policies: {
    signUpSignIn: codeHelper.B2C_POLICY,
    vetSignUp: codeHelper.B2C_VETPOLICY,
    forgotPassword: codeHelper.B2C_POLICY_FORGOT,
    editProfile: codeHelper.B2C_POLICY_CHANGE
  },
  metadata: {
    b2cDomain: codeHelper.B2C_DOMAIN
  },
  protectedRoutes: {
    bff: {
      scopes: [
        codeHelper.B2C_SCOPE
      ],
      apiEndpoint: codeHelper.BFF_URL
    }
  },
  settings: {
    redirectUrl: '/',
    cacheLocation: 'sessionStorage',
    postLogoutRedirectUri: '/logged-out',
    navigateToLoginRequestUrl: true,
    storeAuthStateInCookie: false
  }
};

const generateSecureRandomString = (length) => {
  if (typeof window !== 'undefined' && window.crypto && window.crypto.getRandomValues) {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    const randomString = Array.from(array, (byte) => byte.toString(36)).join('');
    return randomString;
  }
};

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
const baseUrl = sprintf('https://%s/%s/', b2cConfigJson.metadata.b2cDomain, b2cConfigJson.credentials.tenantName);
const getAuthority = (uri) => ({ authority: sprintf('%s%s', baseUrl, uri) });

const signUpSignIn = getAuthority(b2cConfigJson.policies.signUpSignIn);
const vetSignUp = getAuthority(b2cConfigJson.policies.vetSignUp);
const forgotPassword = getAuthority(b2cConfigJson.policies.forgotPassword);
const editProfile = getAuthority(b2cConfigJson.policies.editProfile);

export const b2cPolicies = {
  names: {
    signUpSignIn: b2cConfigJson.policies.signUpSignIn,
    vetSignUp: b2cConfigJson.policies.vetSignUp,
    forgotPassword: b2cConfigJson.policies.forgotPassword,
    editProfile: b2cConfigJson.policies.editProfile
  },
  authorities: {
    signUpSignIn,
    vetSignUp,
    forgotPassword,
    editProfile
  },
  authorityDomain: b2cConfigJson.metadata.b2cDomain
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalB2CConfig = {
  auth: {
    clientId: b2cConfigJson.credentials.clientID, // "b6cbd16e-f393-4089-83d5-9c0db776119b" This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: b2cConfigJson.settings.redirectUrl, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: b2cConfigJson.settings.postLogoutRedirectUri, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: b2cConfigJson.settings.navigateToLoginRequestUrl // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: b2cConfigJson.settings.cacheLocation, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: b2cConfigJson.settings.storeAuthStateInCookie // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error: {
            appInsightsService.trackException(message);
            break;
          }
          case LogLevel.Warning: {
            appInsightsService.trackTrace(message);
            break;
          }
          case LogLevel.Verbose: {
            appInsightsService.trackTrace(message);
            break;
          }
          default:
            break;
        }
      }
    }
  }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  bff: {
    endpoint: b2cConfigJson.protectedRoutes.bff.apiEndpoint,
    scopes: [...b2cConfigJson.protectedRoutes.bff.scopes] // e.g. api://xxxxxx/access_as_user
  }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [...protectedResources.bff.scopes]
};

export const vetSignUpRequest = {
  scopes: [...protectedResources.bff.scopes],
  authority: b2cPolicies.authorities.vetSignUp.authority,
  responseType: 'code',
  redirectUri: b2cConfigJson.settings.redirectUrl,
  state: generateSecureRandomString(16),
  nonce: generateSecureRandomString(16)
};
