import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import constants from 'services/constants';
import classes from 'components/header/navBarSub/navBarSub.module.scss';
import helpers from 'services/helpers';
import Routing from 'routing';
import storeService from 'services/storeService';

const NavBarSub = () => {
  const { ready, t } = useTranslation();

  const species = storeService.session.get.species();

  const componentClassNames = [
    classes.navSub,
    'd-lg-block',
    'd-block'
  ].join(' ').trim();

  const menuItems = [
    {
      id: 'holdingDetails',
      linkTo: Routing.holdingRegisterDetails
    },
    helpers.species.isDeerId(species.id) ? null : {
      id: 'tagReplacements',
      linkTo: Routing.holdingRegisterTaggingReplacement,
      hideForSpecies: [
        constants.species.internalLabel.CATTLE,
        constants.species.internalLabel.PIGS,
        constants.species.internalLabel.DEER
      ]
    },
    helpers.species.isDeerId(species.id) ? null : {
      id: 'tagApplications',
      linkTo: Routing.holdingRegisterTaggingApplications,
      hideForSpecies: [
        constants.species.internalLabel.CATTLE,
        constants.species.internalLabel.PIGS,
        constants.species.internalLabel.DEER
      ]
    },
    helpers.species.isDeerId(species.id) ? null : {
      id: 'births',
      linkTo: Routing.holdingRegisterBirths,
      showForSpecies: [
        constants.species.internalLabel.CATTLE
      ]
    },
    {
      id: 'movementsOff',
      linkTo: Routing.holdingRegisterMovementsOff
    },
    {
      id: 'movementsOn',
      linkTo: Routing.holdingRegisterMovementsOn
    },
    {
      id: 'adjacentMoves',
      linkTo: Routing.holdingRegisterAdjacentMovements
    },
    helpers.species.isDeerId(species.id) ? null : {
      id: 'deaths',
      linkTo: Routing.holdingRegisterDeaths
    },
    helpers.species.isDeerId(species.id) ? null : {
      id: 'annualInventory',
      linkTo: Routing.holdingRegisterAnnualInventory
    },
    {
      id: 'download',
      linkTo: Routing.holdingRegisterDownload
    }
  ].filter((item) => item);

  const menuLabel = (id) => {
    return t('subMenu.' + id);
  };

  const checkMenuItemVisibility = (menuItemKey) => {
    let isVisible = true;
    if (!species) {
      isVisible = false;
    } else if (menuItems[menuItemKey].showForSpecies) {
      isVisible = menuItems[menuItemKey].showForSpecies.includes(species.name);
    } else if (menuItems[menuItemKey].hideForSpecies) {
      isVisible = !menuItems[menuItemKey].hideForSpecies.includes(species.name);
    }

    return isVisible;
  };

  return (
    <>
      {ready &&
        <nav aria-label={t('subMenu.subNavigation')} className={componentClassNames} id="headerNavSub">
          <ul id="navigationSub">
            {menuItems.map((menuItem, menuItemKey) => {
              if (checkMenuItemVisibility(menuItemKey)) {
                return (
                  <li key={menuItemKey}>
                    <NavLink
                      activeClassName={classes.active}
                      className={menuItem.activeIf ? classes.active : ''}
                      onClick={(event) => {
                        if (menuItem.onClick) {
                          menuItem.onClick(event);
                        }
                      }}
                      to={menuItem.linkTo}
                    >
                      {menuLabel(menuItem.id)}
                    </NavLink>
                  </li>
                );
              }

              return null;
            })}
          </ul>
        </nav>
      }
    </>
  );
};

export default NavBarSub;
