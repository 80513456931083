import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import TextEntry from 'components/base/textEntry/textEntry';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import config from 'config';
import helpers from 'services/helpers';

const FciFormFields = ({
  holdingRestrictions,
  metFCIWithdrawalPeriods,
  nonComplianceReason,
  setHoldingRestrictions,
  setNonComplianceReason,
  setWithdrawalPeriod,
  speciesId,
  withdrawalPeriod
}) => {
  const { ready } = useTranslation();

  const handleChanges = {
    changeHoldingRestrictions: (event) => {
      setHoldingRestrictions(event.target.value);
    },

    nonComplianceReason: (event) => {
      setNonComplianceReason(event.target.value);
    },

    withdrawalPeriod: (eventTarget) => {
      metFCIWithdrawalPeriods.forEach((withdrawalPeriod) => {
        if (withdrawalPeriod.code === eventTarget.value) {
          setWithdrawalPeriod(withdrawalPeriod);
        }
      });
    }
  };

  return (
    <>
      {ready &&
        <>
          {metFCIWithdrawalPeriods &&
            <div className="section">
              <RadioButtons
                ids={metFCIWithdrawalPeriods.map((item) => ({
                  id: item.code,
                  label: item.name
                }))}
                name="medicineWithdrawal"
                onChange={(event) => handleChanges.withdrawalPeriod(event.target)}
                value={withdrawalPeriod ? withdrawalPeriod.code : null}
              />
            </div>
          }
          {!metFCIWithdrawalPeriods &&
            <ErrorLabel
              isWarning={true}
              label="label.noData"
            />
          }

          <div className="section">
            <div className="input">
              <TextEntry
                hint="movements.fci-information.input-field-one-hint"
                id="fciNonCompliance"
                label="movements.fci-information.input-field-one"
                maxLength={255}
                name="fciNonCompliance"
                onBlur={handleChanges.nonComplianceReason}
                onChange={(event) => setNonComplianceReason(event.target.value)}
                rows={config.ROWS_FCI_NON_COMPLIANCE}
                value={nonComplianceReason}
                width="full"
              />
            </div>

            <div className="input">
              <TextEntry
                hint="movements.fci-information.character-limit-255-hint"
                id="holdingRestrictions"
                label={helpers.species.isDeerId(speciesId) ? 'movements.fci-information.input-field-three-deer' : 'movements.fci-information.input-field-three-sheepAndGoats' }
                maxLength={255}
                name="holdingRestrictions"
                onBlur={handleChanges.changeHoldingRestrictions}
                onChange={(event) => setHoldingRestrictions(event.target.value)}
                rows={config.ROWS_HOLDING_RESTRICTIONS}
                value={holdingRestrictions}
                width="full"
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

FciFormFields.propTypes = {
  holdingRestrictions: PropTypes.string,
  metFCIWithdrawalPeriods: PropTypes.array,
  nonComplianceReason: PropTypes.string,
  setHoldingRestrictions: PropTypes.func,
  setNonComplianceReason: PropTypes.func,
  setWithdrawalPeriod: PropTypes.func,
  speciesId: PropTypes.number,
  withdrawalPeriod: PropTypes.object
};

export default FciFormFields;
