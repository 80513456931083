import React from 'react';
import PropTypes from 'prop-types';
import TextEntry from 'components/base/textEntry/textEntry';

const SearchField = ({
  id,
  label,
  name,
  onChange,
  placeholder,
  reference,
  tooltip,
  tooltipPosition,
  value
}) => {
  return (
    <TextEntry
      id={id}
      label={label}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      reduceSpace={true}
      ref={reference}
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      type="text"
      value={value}
    />
  );
};

SearchField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  reference: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  value: PropTypes.string
};

export default SearchField;
