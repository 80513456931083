import React, { useState, useEffect } from 'react';
import axios from 'axios';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import DropdownSearch from 'components/base/dropdownSearch/dropdownSearch';

const DropdownGeneticDamIds = ({
  error,
  label,
  name,
  onChange,
  setModal,
  value
}) => {
  const [itemList, setItemList] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const params = {
      cph: storeService.session.get.holding().value,
      speciesId: storeService.session.get.species().id,
      onHoldingDate: helpers.date.now(),
      gender: 'F',
      dropdown: true
    };
    helpers.get.animalsOnHolding(
      source.token,
      params,
      setModal,
      setPending,
      setItemList
    );

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownSearch
      error={error}
      id="geneticDamIdDropdown"
      label={label}
      name={name}
      onChange={onChange}
      optionList={itemList}
      pending={pending}
      pendingError="error.noBirthTags"
      placeholder="prompt.pleaseSelect"
      value={value}
    />
  );
};

DropdownGeneticDamIds.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default DropdownGeneticDamIds;
