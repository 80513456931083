import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from 'components/table/customTable/customTableBoAnimalActivity/customTableBoAnimalActivity.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import { initialSorting, tableColumns } from 'services/tableColumns';
import Address from 'backOfficeComponents/address/address';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Table from 'components/table/table';
import TabsNavigational from 'components/base/tabsNavigational/tabsNavigational';

const AnimalActivityCustomTable = ({
  data,
  pending,
  setData,
  setModal
}) => {
  const { pathname } = useLocation();
  const { ready, t } = useTranslation();

  const render = {
    address: (cph) => (
      <Address
        cph={cph}
        format={{
          address: constants.address.format.inline
        }}
        key="cphAddress"
        setModal={setModal}
      />
    ),

    summary: () => {
      let title;
      let line1;
      let line2;
      let showAddressOnLine = 0;
      let cph;

      if (data[0].animalState === 'Dead') {
        title = t('label.currentLocationDead');
        line1 = [
          t('label.dateDeath'),
          helpers.date.format(data[0].death.killDate)
        ];
        line2 = [
          t('label.deathLocation'),
          data[0].death.killHolding,
          ''
        ];
        showAddressOnLine = 2;
        cph = data[0].death.killHolding;
      } else if (data[0].notApplied) {
        title = t('label.tagNotApplied');
        line1 = [
          t('label.cphAllocatedTo'),
          data[0].holding,
          ''
        ];
        line2 = [
          t('label.tagManufactureDate'),
          helpers.date.format(data[0].tagDispatchDate)
        ];
        showAddressOnLine = 1;
        cph = data[0].holding;
      } else if (data[0].replaced) {
        title = t('label.tagReplacedBy');
        line1 = [
          data[0].tagNumber
        ];
        line2 = [
          t('label.replacementDate'),
          helpers.date.format(data[0].tagReplacements[0].replacementDate)
        ];
      } else {
        title = t('label.currentLocation');
        line1 = [
          t('label.cph'),
          data[0].locationHistoryList[data[0].locationHistoryList.length - 1].holding
        ];
        line2 = [
          t('label.address'),
          ''
        ];
        showAddressOnLine = 2;
        cph = data[0].locationHistoryList[data[0].locationHistoryList.length - 1].holding;
      }

      return (
        <div className={classes.summary}>
          <div><strong>{title}</strong></div>
          <div>
            {line1.join(' - ')}
            {showAddressOnLine === 1 && render.address(cph)}
          </div>
          <div>
            {line2.join(' - ')}
            {showAddressOnLine === 2 && render.address(cph)}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {ready &&
        <div className={classes.tagDetails}>
          {data.length === 0 &&
            <>
              {pending &&
                <ErrorLabel
                  isWarning={true}
                  label="label.fetchingAnimalActivity"
                />
              }

              {!pending &&
                <ErrorLabel
                  label="label.noData"
                />
              }
            </>
          }

          {data.length > 0 &&
            <>
              {render.summary()}

              {!data[0].notApplied &&
                <TabsNavigational>
                  <div active={pathname === Routing.boAnimalActivityMovementHistory} id={constants.tabs.movementHistory} label="label.movementHistory" url={Routing.boAnimalActivityMovementHistory}>
                    <Table
                      columns={tableColumns.bo.animalActivity.movements({ sortable: true })}
                      data={data[0].movements}
                      dataProvided={true}
                      initialSortBy={initialSorting.bo.animalActivity.movements.accessor}
                      initialSortDirection={initialSorting.bo.animalActivity.movements.direction}
                      setData={setData}
                      setDataProp={{
                        index: 0,
                        prop: 'movements'
                      }}
                      setModal={setModal}
                      store={{
                        filters: 'AnimalActivityMovementHistory'
                      }}
                    />
                  </div>

                  <div active={pathname === Routing.boAnimalActivityTaggingHistory} id={constants.tabs.tagHistory} label="label.taggingHistory" url={Routing.boAnimalActivityTaggingHistory}>
                    <Table
                      columns={tableColumns.bo.animalActivity.tagHistory({ sortable: false })}
                      data={helpers.sortArrayOfObjects(data[0].tagReplacements.concat([{
                        replacementDate: data[0].dateApplied || data[0].systemDateApplied,
                        replacedIdentifier: data[0].tagReplacements?.[0]?.replacedIdentifier,
                        replacementHolding: data[0].dateApplied ? data[0].locationHistoryList?.[0]?.holding : t('label.systemApplied'),
                        requestId: data[0].initialTaggingRequestId
                      }]), 'replacementDate', constants.sorting.ascending)}
                      dataProvided={true}
                      initialSortBy={initialSorting.bo.animalActivity.tagHistory.accessor}
                      initialSortDirection={initialSorting.bo.animalActivity.tagHistory.direction}
                      setData={setData}
                      setDataProp={[0].tagReplacements}
                      setModal={setModal}
                      store={{
                        filters: 'AnimalActivityTaggingHistory'
                      }}
                    />
                  </div>

                  <div active={pathname === Routing.boAnimalActivityAnimalDetails} id={constants.tabs.animalDetails} label="label.animalDetails" url={Routing.boAnimalActivityAnimalDetails}>
                    <table className="horizontal">
                      <tbody>
                        <tr>
                          <th>{t('label.tagAppliedLocation')}</th>
                          <td>{data[0].dateApplied ? data[0].locationHistoryList?.[0]?.holding : t('label.systemApplied')}</td>
                        </tr>
                        <tr>
                          <th>{t('label.rfidNumber')}</th>
                          <td>{data[0].rfid || '-'}</td>
                        </tr>
                        <tr>
                          <th>{t('label.breed')}</th>
                          <td>{data[0].breed?.name || data[0].breed || '-'}</td>
                        </tr>
                        <tr>
                          <th>{t('label.gender')}</th>
                          <td>{data[0].genderSpecies?.name || '-'}</td>
                        </tr>
                        <tr>
                          <th>{t('label.yearDob')}</th>
                          <td>{helpers.animal.getYearDob(data[0]) || '-'}</td>
                        </tr>
                        <tr>
                          <th>{t(data[0].animalState === 'Dead' ? 'label.ageAtDeath' : 'label.age')}</th>
                          <td>{!data[0].movements.some((item) => item.isAbattoir) && helpers.date.withSameCalendarDay(data[0].beginBirthPeriod, data[0].endBirthPeriod) ? helpers.get.age(data[0].beginBirthPeriod, data[0].death?.killDate) : '-'}</td>
                        </tr>
                        <tr>
                          <th>{t('label.damId')}</th>
                          <td>{data[0].damTagNumber || '-'}</td>
                        </tr>
                        <tr>
                          <th>{t('label.sireId')}</th>
                          <td>{data[0].sireTagNumber || '-'}</td>
                        </tr>
                        <tr>
                          <th>{t('label.geneticDamId')}</th>
                          <td>{data[0].geneticDamTagNumber || '-'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {data[0].progenies.length > 0 &&
                    <div active={pathname === Routing.boAnimalActivityProgeny} id={constants.tabs.progeny} label="label.progeny" url={Routing.boAnimalActivityProgeny}>
                      <Table
                        columns={tableColumns.bo.animalActivity.progenies}
                        data={data[0].progenies}
                        dataProvided={true}
                        initialSortBy={initialSorting.bo.animalActivity.progenies.accessor}
                        initialSortDirection={initialSorting.bo.animalActivity.progenies.direction}
                        setData={setData}
                        setDataProp={[0].progenies}
                        setModal={setModal}
                        store={{
                          filters: 'AnimalActivityProgeny'
                        }}
                      />
                    </div>
                  }
                </TabsNavigational>
              }
            </>
          }
        </div>
      }
    </>
  );
};

AnimalActivityCustomTable.propTypes = {
  data: PropTypes.array.isRequired,
  pending: PropTypes.bool,
  setData: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired
};

export default AnimalActivityCustomTable;
