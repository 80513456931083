import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import searchHelper from 'services/searchHelper';
import Routing from 'routing';
import permissions from 'services/permissions';
import { useTranslation } from 'react-i18next';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import helpers from 'services/helpers';
import { isCancel } from 'services/bff';
import storeService from 'services/storeService';
import Button from 'components/base/button/button';
import CPHField from 'components/base/cphField/cphField';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Address from 'components/address/address';
import constants from 'services/constants';

const VetHomePage = ({ setModal }) => {
  const { instance } = useMsal();
  const { ready, t } = useTranslation(),
    history = useHistory(),
    [cphNumber, setCphNumber] = React.useState(''),
    [cphNumberError, setCphNumberError] = React.useState(''),
    [buttonDisabled, setButtonDisabled] = React.useState(true),
    [searched, setSearched] = React.useState(false),
    [searchNoResults, setSearchNoResults] = React.useState(false),
    [cphHoldingData, setCPHHoldingData] = React.useState({}),
    { id: cphValue = '' } = cphHoldingData || {},
    [isNextDisabled, setIsNextDisabled] = React.useState(true),
    updateCphNumber = (cph) => {
      const status = helpers.validate.cphNumber(cph);
      setCPHHoldingData({});
      if (storeService.session.get.vetCPHData()) {
        storeService.session.remove.vetCPHData();
      }
      setCphNumberError('');
      setCphNumber(cph);
      setButtonDisabled(!status);
      setSearchNoResults(false);
      setIsNextDisabled(true);
    },
    searchForHolding = () => {
      const query = {};
      if (cphNumber && helpers.validate.cphNumber(cphNumber)) {
        query.cph = cphNumber;
      }
      searchHelper.searchCPH(query, setSearched, setSearchNoResults)
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal)) {
            const resData = res.data.data?.[0];
            if (resData) {
              setCPHHoldingData(resData);
              setSearchNoResults(false);
              setIsNextDisabled(false);
              if (storeService.session.get.vetCPHData()) {
                storeService.session.remove.vetCPHData();
                storeService.session.set.vetCPHData(resData);
              } else {
                storeService.session.set.vetCPHData(resData);
              }
            } else {
              setSearchNoResults(true);
              setIsNextDisabled(true);
            }
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setSearchNoResults(true);
          }
        });
    },
    routeToDetailsPage = () => {
      history.push(Routing.vetDetails);
    };
  if (storeService.session.get.restrictBackFlow()) {
    helpers.restrictBackFromVetDeclaration();
    instance.logoutRedirect({ postLogoutRedirectUri: '/' })
      .catch((error) => {
      console.error(error); //eslint-disable-line
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  // Reatin Holding details from session storage
  useEffect(() => {
    if (storeService.session.get.vetCPHData()) {
      const cphData = storeService.session.get.vetCPHData() || '';
      if (cphData) {
        setCphNumber(cphData.id);
        setCPHHoldingData(cphData);
        setIsNextDisabled(false);
        setButtonDisabled(false);
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {ready && permissions.isLoggedIn && !permissions.isBackOffice() &&
        <main className={classes.mainContanier}>

          <section className={classes.titleSection}>
            <h1 className={classes.pageTitle}>{t('menu.vetHome')}</h1>
          </section>

          <AboutPanel
            descriptions={[
              { text: 'about.vetHome.desc1' }
            ]}
            title="about.vetHome.title"
          />

          <section className={classes.bodySection}>
            <div className={classes.flexRow + ' ' + classes.alignFlexEnd}>
              <div className={classes.cphNumber}>
                <CPHField
                  cph={cphNumber}
                  error={cphNumberError}
                  id="vetHomeCPH"
                  label="vetHomePage.cphFieldSearchLabel"
                  setCph={updateCphNumber}
                />
              </div>
              <div className={classes.action}>
                <Button
                  buttonType="secondary"
                  disabled={buttonDisabled}
                  id="search-by-cph-detail"
                  label="button.search"
                  onClick={searchForHolding}
                />
              </div>
            </div>
            <div>
              { searched &&
                <>
                  {searchNoResults &&
                    <ErrorLabel
                      error={true}
                      label={'label.cphNotFound'}
                    />
                  }
                </>
              }
            </div>
            { cphValue &&
              <div className={classes.cphResultSection}>
                <h1 className={classes.sectionTitle}>{t('vetHomePage.holdingLabel')}</h1>
                <Address
                  cph={cphValue}
                  displayCPH = {false}
                  format={{ address: constants.address.format.full }}
                  setModal={setModal}
                />
                <div className={classes.cphValue}>{t('vetHomePage.cphLabel')}: {cphValue}</div>
              </div>
            }
          </section>

          <section className={classes.buttonSection + ' ' + classes.displayBtnRight}>
            <Button
              buttonType="primary"
              disabled={isNextDisabled}
              label="label.next"
              onClick={routeToDetailsPage}
            />
          </section>

        </main>
      }

    </>
  );
};
VetHomePage.propTypes = {
  setModal: PropTypes.func.isRequired
};
export default VetHomePage;
