import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classes from 'components/base/tab/tab.module.scss';
import PropTypes from 'prop-types';
import Button from 'components/base/button/button';

const Tab = ({
  classNames,
  extraContent,
  id,
  isActive,
  label,
  onClick,
  url
}) => {
  const { ready, t } = useTranslation();

  const componentClassNames = [
    classes.tab,
    isActive ? classes.tabSelected : '',
    ...(classNames ? classNames.map((className) => classes[className]) : [])
  ].join(' ').trim();

  if (url) {
    if (isActive) {
      return (
        <li className={componentClassNames} id={id}>
          <span>{t(label)} {extraContent}</span>
        </li>
      );
    }

    return (
      <li className={componentClassNames} id={id} onClick={onClick && typeof onClick === 'function' ? () => onClick(id) : null}>
        <Link className={classes.navLink} to={url}>
          {t(label)}
        </Link>
      </li>
    );
  } else if (onClick) {
    return (
      <li className={componentClassNames} id={id}>
        <Button
          buttonType="tab"
          extraContent={extraContent}
          label={label}
          onClick={() => onClick(id)}
        />
      </li>
    );
  }

  return (
    <>
      {ready &&
        <li className={componentClassNames} id={id}>
          <span>{t(label)} {extraContent}</span>
        </li>
      }
    </>
  );
};

Tab.propTypes = {
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  extraContent: PropTypes.string,
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string
};

export default Tab;
