import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/base/button/button';
import helpers from 'services/helpers';
import classes from 'components/summary/exemptionSummary/exemptionSummary.module.scss';
import PropTypes from 'prop-types';

const ExemptionSummary = ({
  data,
  label,
  onClick
}) => {
  const { ready, t } = useTranslation();

  const displayExemption = () => {
    if (data.exemptions?.length > 0) {
      return (
        <>
          {(helpers.option.movement.isExempt(data.exempt) &&
            <>
              {data.exemptions.filter((o) => o.value)
                .map((o) => (
                  <div className={classes.exemption} key={o.id}>{o.label}</div>
                ))}
            </>)
          }
          {(!helpers.option.movement.isExempt(data.exempt) && t('radioButtons.movementExemption.response'))}
        </>
      );
    }
    return (
      t('movements.noExemptions')
    );
  };

  return (
    <>
      {ready &&
        <div className={classes.summaryRow}>
          <div className={classes.label}>{t(label)}</div>
          <div className={classes.value}>
            {displayExemption()}
          </div>
          {onClick &&
              <div className={classes.action}>
                <Button
                  buttonType="link"
                  label="label.edit"
                  onClick={onClick}
                />
              </div>
          }
        </div>
      }
    </>
  );
};

ExemptionSummary.propTypes = {
  data: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default ExemptionSummary;
