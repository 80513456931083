import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from 'backOfficeComponents/base/tooltip/tooltip';
import classes from 'backOfficeComponents/base/fieldLabel/fieldLabel.module.scss';

const FieldLabel = ({
  extraContent,
  id,
  label,
  label2,
  name,
  optional,
  tooltip
}) => {
  const { ready, t } = useTranslation();

  const outputLabel = t(label);
  const outputLabel2 = t(label2);

  const componentClassNames = [
    classes.label
  ].join(' ').trim();

  return (
    <>
      {ready && (outputLabel || outputLabel2) &&
        <label className={componentClassNames} htmlFor={name} id={id}>
          {outputLabel}
          {outputLabel2 ? outputLabel2 : ''}
          {extraContent ? ' ' + extraContent : ''}
          {optional &&
            <>
              {' '}{t('label.optional')}
            </>
          }
          {tooltip &&
            <Tooltip
              id={id}
              tooltip={tooltip}
            />
          }
        </label>
      }
    </>
  );
};

FieldLabel.propTypes = {
  extraContent: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  label2: PropTypes.string,
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  tooltip: PropTypes.string
};

export default FieldLabel;
