import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isCancel } from 'services/bff';
import axios from 'axios';
import breedsHelper from 'services/breedsHelper';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search/dist/cjs';
import storeService from 'services/storeService';

const DropdownBreedsMulti = ({
  cph,
  date,
  onChange,
  refreshFilters,
  setModal,
  speciesId,
  value
}) => {
  const { ready, t } = useTranslation();

  const species = storeService.session.get.species();
  const holding = storeService.session.get.holding();

  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const getItemList = (date, source) => {
    breedsHelper.fetchBreedsOnHolding(source?.token, setModal, cph ? cph : holding.value, speciesId ? speciesId : species.id, date, true)
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          breedsHelper.prepareBreedsOnHolding(res, speciesId ? speciesId : species.id);

          const breedsOnHolding = storeService.session.get.breedsOnHolding();

          setOptions(breedsOnHolding
            ? breedsOnHolding.map((breed) => ({
              name: breed.name,
              label: breed.name,
              value: encodeURIComponent(breed.name)
            }))
            : []
          );

          if (value) {
            setSelectedValues(value);
          }
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    getItemList(date ? date : helpers.date.now(), source);

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  useEffect(() => {
    if (refreshFilters?.refresh) {
      getItemList(refreshFilters.params.date);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFilters]);

  return (
    <>
      {ready &&
        <SelectSearch
          className="select-search"
          closeOnSelect={false}
          filterOptions={helpers.dropdownSearch}
          id="breedDropdown"
          inputId="breedDropdown"
          multiple
          name="breed"
          onChange={onChange}
          onFocus={() => helpers.resetDropdownFocusState()}
          options={options}
          placeholder={t('dropdowns.breed.label')}
          printOptions="on-focus"
          search
          value={selectedValues}
        />
      }
    </>
  );
};

DropdownBreedsMulti.propTypes = {
  cph: PropTypes.string,
  date: PropTypes.string,
  onChange: PropTypes.func,
  refreshFilters: PropTypes.object,
  setModal: PropTypes.func.isRequired,
  speciesId: PropTypes.number,
  value: PropTypes.array
};

export default DropdownBreedsMulti;
