import React from 'react';
import classes from 'components/base/radioButtons/radioButtons.module.scss';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import Hint from 'components/base/hint/hint';
import Legend from 'components/base/legend/legend';
import RadioButton from 'components/base/radioButton/radioButton';

const RadioButtons = ({
  classNames,
  disabled,
  error,
  hideLegend,
  hint,
  id,
  ids,
  inline,
  label,
  legendClassNames,
  name,
  onChange,
  optional,
  species,
  suppressHint,
  suppressLabel,
  tooltip,
  value,
  warning
}) => {
  const currentSpecies = storeService.session.get.species() ? storeService.session.get.species().id : null;

  const componentClassNames = [
    classes.radioButtons,
    inline ? classes.inline : '',
    ...(classNames ? classNames.map((className) => classes[className]) : [])
  ].join(' ').trim();

  let allFieldsDisabled = false;
  if (disabled) {
    allFieldsDisabled = (ids.filter((item) => !disabled.includes(item))).length === 0;
  }

  const getFieldLegend = () => {
    if (hideLegend) {
      return '';
    }
    if (species) {
      return ['radioButtons', name, 'label' + currentSpecies].join('.');
    }
    if (label) {
      return label;
    }

    return ['radioButtons', name, 'label'].join('.');
  };

  const getRadioLabel = (id) => {
    return ['radioButtons', name, (species) ? id + currentSpecies : id].join('.');
  };

  const getHint = () => {
    return [
      'radioButtons',
      name,
      hint ? hint : 'hint'
    ].join('.');
  };

  return (
    <fieldset role="group">
      {!suppressLabel &&
        <Legend
          classNames={legendClassNames}
          disabled={allFieldsDisabled}
          id={id}
          legend={getFieldLegend()}
          optional={optional}
          tooltip={tooltip}
        />
      }
      {!suppressHint &&
        <Hint
          hint={getHint()}
          id={id}
        />
      }
      <ErrorLabel
        id={id}
        label={error}
      />
      <ErrorLabel
        id={id}
        isWarning={true}
        label={warning}
      />
      <div className={componentClassNames}>
        {ids.filter((item) => item.showIf !== false && item.hideIf !== true).map((item) => {
          let itemId;
          let itemLabel;
          let itemValue;

          if (typeof item === 'object') {
            itemId = `${name}_${item.id}`;
            itemLabel = item.label ? item.label : getRadioLabel(item.id);
            itemValue = item.id;
          } else {
            itemId = `${name}_${item}`;
            itemLabel = getRadioLabel(item);
            itemValue = item;
          }

          return (
            <RadioButton
              classNames={classNames}
              defaultChecked={itemValue === value}
              disabled={disabled && disabled.includes(itemValue)}
              id={itemId.toString()}
              inline={inline}
              key={itemId}
              label={itemLabel}
              name={name}
              onChange={onChange}
              value={itemValue}
            />
          );
        })}
      </div>
    </fieldset>
  );
};

RadioButtons.propTypes = {
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  disabled: PropTypes.arrayOf(
    PropTypes.string
  ),
  error: PropTypes.string,
  hideLegend: PropTypes.bool,
  hint: PropTypes.string,
  id: PropTypes.string,
  ids: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
  ).isRequired,
  inline: PropTypes.bool,
  label: PropTypes.string,
  legendClassNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  species: PropTypes.bool,
  suppressHint: PropTypes.bool,
  suppressLabel: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.string,
  warning: PropTypes.string
};

export default RadioButtons;
