import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/base/tooltip/tooltip';
import classes from 'components/base/hint/hint.module.scss';

const Hint = ({
  hint,
  tooltip
}) => {
  const { ready, t } = useTranslation();

  const outputHint = t(hint);
  const classname = classes.hint + ((hint === outputHint) ? ' ' + classes.notFound : '');

  return (
    <>
      {ready && outputHint &&
        <span className={classname}>
          <span dangerouslySetInnerHTML={{ __html: outputHint }} />
          {tooltip &&
            <Tooltip tooltip={tooltip} />
          }
        </span>
      }
    </>
  );
};

Hint.propTypes = {
  hint: PropTypes.string,
  tooltip: PropTypes.string
};

export default Hint;
