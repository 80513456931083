import constants from 'services/constants';

const config = {
  APPLICATION_VERSION: window.APPLICATION_VERSION ? window.APPLICATION_VERSION : '1.0',
  DEFAULT_TABLE_PAGE_SIZE: window.DEFAULT_TABLE_PAGE_SIZE ? window.DEFAULT_TABLE_PAGE_SIZE : 10,
  LENGTH_DESCRIPTION: window.LENGTH_DESCRIPTION ? window.LENGTH_DESCRIPTION : 30,
  LENGTH_EMAIL: window.LENGTH_EMAIL ? window.LENGTH_EMAIL : 320,
  LENGTH_HOLDING_CPH: window.LENGTH_HOLDING_CPH ? window.LENGTH_HOLDING_CPH : 11,
  LENGTH_HOLDING_EU: window.LENGTH_HOLDING_EU ? window.LENGTH_HOLDING_EU : 11,
  LENGTH_HOLDING_RESTRICTIONS: window.LENGTH_HOLDING_RESTRICTIONS ? window.LENGTH_HOLDING_RESTRICTIONS : 200,
  LENGTH_NON_COMPLIANCE_REASON: window.LENGTH_NON_COMPLIANCE_REASON ? window.LENGTH_NON_COMPLIANCE_REASON : 100,
  LENGTH_REFERENCE_OR_COMMENT: window.LENGTH_REFERENCE_OR_COMMENT ? window.LENGTH_REFERENCE_OR_COMMENT : 100,
  LENGTH_START_NUMBER: window.LENGTH_START_NUMBER ? window.LENGTH_START_NUMBER : 5,
  LENGTH_YEAR: window.LENGTH_YEAR ? window.LENGTH_YEAR : 4,
  MAX_BIRTH_DATE_RANGE: window.MAX_BIRTH_DATE_RANGE ? window.MAX_BIRTH_DATE_RANGE : 27,
  MAX_DAYS_TO_REPORT_DEATH: window.MAX_DAYS_TO_REPORT_DEATH ? window.MAX_DAYS_TO_REPORT_DEATH : 7,
  MAX_DAYS_TO_REPORT_MOVEMENT: window.MAX_DAYS_TO_REPORT_MOVEMENT ? window.MAX_DAYS_TO_REPORT_MOVEMENT : 3,
  MAXLENGTH_CPH_MANUAL: window.MAXLENGTH_CPH_MANUAL ? window.MAXLENGTH_CPH_MANUAL : 400,
  MAXLENGTH_COMMENT: window.MAXLENGTH_COMMENT ? window.MAXLENGTH_COMMENT : 125,
  MAXLENGTH_NON_CPH_LOCATION: window.MAXLENGTH_OWN_USE ? window.MAXLENGTH_OWN_USE : 125,
  MAXLENGTH_OWN_USE: window.MAXLENGTH_OWN_USE ? window.MAXLENGTH_OWN_USE : 125,
  MAX_TOTAL_ANIMAL_RANGE: window.MAX_TOTAL_ANIMAL_RANGE ? window.MAX_TOTAL_ANIMAL_RANGE : 1000,
  MAX_UPLOAD_RECORDS: window.MAX_UPLOAD_RECORDS ? window.MAX_UPLOAD_RECORDS : 1000,
  MAX_UPLOAD_RECORDS_EXTENDED_CSV: window.MAX_UPLOAD_RECORDS_EXTENDED_CSV ? window.MAX_UPLOAD_RECORDS_EXTENDED_CSV : 100,
  POLLS_ENABLED: window.POLLS_ENABLED ? window.POLLS_ENABLED : true,
  ROWS_COMMENT: window.ROWS_COMMENT ? window.ROWS_COMMENT : 1,
  ROWS_CPH_MANUAL: window.ROWS_CPH_MANUAL ? window.ROWS_CPH_MANUAL : 7,
  ROWS_FCI_NON_COMPLIANCE: window.ROWS_FCI_NON_COMPLIANCE ? window.ROWS_FCI_NON_COMPLIANCE : 4,
  ROWS_HOLDING_RESTRICTIONS: window.ROWS_HOLDING_RESTRICTIONS ? window.ROWS_HOLDING_RESTRICTIONS : 4,
  ROWS_TEXTAREA: window.ROWS_TEXTAREA ? window.ROWS_TEXTAREA : 3,
  ROWS_USER_NOTES: window.ROWS_USER_NOTES ? window.ROWS_USER_NOTES : 3,
  SPECIES_DEFAULT: window.SPECIES_DEFAULT ? window.SPECIES_DEFAULT : constants.species.animalTypes.SHEEP,
  TIMEOUT: window.TIMEOUT ? window.TIMEOUT : 30 * 60 * 1000,
  UNKNOWN: 'unknown',
  WIDTH_BATCH_NUMBER: window.WIDTH_BATCH_NUMBER ? window.WIDTH_BATCH_NUMBER : '10',
  WIDTH_CPH: window.WIDTH_CPH ? window.WIDTH_CPH : '25',
  WIDTH_CPH_MANUAL: window.WIDTH_CPH_MANUAL ? window.WIDTH_CPH_MANUAL : '20',
  WIDTH_DAY: window.WIDTH_DAY ? window.WIDTH_DAY : '2',
  WIDTH_DOCUMENT_ID: window.WIDTH_DOCUMENT_ID ? window.WIDTH_DOCUMENT_ID : '15',
  WIDTH_GROUP_ID: window.WIDTH_GROUP_ID ? window.WIDTH_GROUP_ID : '20',
  WIDTH_DAYS: window.WIDTH_DAYS ? window.WIDTH_DAYS : '2',
  WIDTH_HOURS: window.WIDTH_HOURS ? window.WIDTH_HOURS : '2',
  WIDTH_MINUTES: window.WIDTH_MINUTES ? window.WIDTH_MINUTES : '2',
  WIDTH_MONTH: window.WIDTH_MONTH ? window.WIDTH_MONTH : '2',
  WIDTH_MOVEMENT_ID: window.WIDTH_MOVEMENT_ID ? window.WIDTH_MOVEMENT_ID : '8',
  WIDTH_MOVEMENT_REF: window.WIDTH_MOVEMENT_REF ? window.WIDTH_MOVEMENT_REF : '15',
  WIDTH_NUM_RECEIVED: window.WIDTH_NUM_RECEIVED ? window.WIDTH_NUM_RECEIVED : '5',
  WIDTH_PHONE_NUMBER: window.WIDTH_PHONE_NUMBER ? window.WIDTH_PHONE_NUMBER : '13',
  WIDTH_REGISTRATION: window.WIDTH_REGISTRATION ? window.WIDTH_REGISTRATION : '10',
  WIDTH_START_NUMBER: window.WIDTH_START_NUMBER ? window.WIDTH_START_NUMBER : '5',
  WIDTH_TAG_REF: window.WIDTH_TAG_REF ? window.WIDTH_TAG_REF : '100',
  WIDTH_TAGS: window.WIDTH_TAGS ? window.WIDTH_TAGS : '20',
  WIDTH_TOTAL_NUMBER: window.WIDTH_TOTAL_NUMBER ? window.WIDTH_TOTAL_NUMBER : '5',
  WIDTH_TRANSPORTER_AUTH: window.WIDTH_TRANSPORTER_AUTH ? window.WIDTH_TRANSPORTER_AUTH : '10',
  WIDTH_TRANSPORTER_NAME: window.WIDTH_TRANSPORTER_NAME ? window.WIDTH_TRANSPORTER_NAME : '32',
  WIDTH_USER_NOTES: window.WIDTH_USER_NOTES ? window.WIDTH_USER_NOTES : '100',
  REGIONS: window.REGIONS ? window.REGIONS : [constants.regions.England]
};

export default config;
