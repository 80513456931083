import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/base/legend/legend.module.scss';
import helpers from 'services/helpers';
import Tooltip from 'backOfficeComponents/base/tooltip/tooltip';

const Legend = ({
  disabled,
  id,
  legend,
  legendStyle,
  optional,
  tooltip
}) => {
  const { ready, t } = useTranslation();

  const getLegend = () => {
    if (helpers.address.isUnknown(legend)) {
      return legend.charAt(0).toUpperCase() + legend.slice(1);
    }

    return optional ? t(legend) + ' ' + t('label.optional') : t(legend);
  };

  const outputLegend = getLegend();
  const theTooltip = (tooltip) ? tooltip : '';

  const classNames = [classes.legend + ' ' + classes[legendStyle]];
  if (disabled) {
    classNames.push(classes.disabled);
  }

  return (
    <>
      {ready && outputLegend &&
        <legend className={classNames.join(' ')} id={id + 'Legend'}>
          {outputLegend}
          {theTooltip && <Tooltip id={id} tooltip={theTooltip} />}
        </legend>
      }
    </>
  );
};

Legend.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  legend: PropTypes.string.isRequired,
  legendStyle: PropTypes.string,
  optional: PropTypes.bool,
  tooltip: PropTypes.string
};

export default Legend;
