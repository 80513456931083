import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/addBatch/addBatch.module.scss';
import config from 'config';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import Button from 'backOfficeComponents/base/button/button';
import FieldEntry from 'backOfficeComponents/base/fieldEntry/fieldEntry';
import Legend from 'backOfficeComponents/base/legend/legend';
import NumberEntry from 'components/base/numberEntry/numberEntry';

const AddBatch = ({
  batchHeader,
  batchList,
  buttonLabel,
  id,
  setBatchList,
  setBatchTotal,
  setDataChanged,
  speciesId
}) => {
  const { ready, t } = useTranslation();

  const speciesLabel = t('label.batchNumber' + speciesId);
  const [totalAnimalsError, setTotalAnimalsError] = React.useState(false);
  const [batch, setBatch] = React.useState({
    batchNumber: '',
    animalTotal: '',
    description: ''
  });

  const batchNumberChange = (input) => {
    setBatch({ ...batch, batchNumber: input });
  };

  const totalAnimalsChange = (input) => {
    setTotalAnimalsError(false);
    setBatch({ ...batch, animalTotal: input });
  };

  const submitBatch = () => {
    setBatchList([
      ...batchList,
      batch
    ]);
    if (setBatchTotal && typeof setBatchTotal === 'function') {
      setBatchTotal((prevState) => prevState + parseInt(batch.animalTotal));
    }
    setBatch({ batchNumber: '', description: '', animalTotal: '' });
    setDataChanged();
  };

  const disableAddBatch = () => {
    return (
      (!batch.animalTotal && !batch.batchNumber) ||
      totalAnimalsError
    );
  };

  const groupAnimalClassname = (totalAnimalsError ? ' formGroupError' : '');
  const warningClassname = (batch.batchNumber.length > 0 && batch.animalTotal && batch.animalTotal.length > 0 ? '' : 'hidden');

  return (
    <>
      {ready &&
        <>
          <fieldset role="group">
            <Legend id="addBatch" legend={batchHeader ? batchHeader : ''} />
            <div className={classes.addBatch}>
              <div className={classes.totalAnimals}>
                <div className={groupAnimalClassname}>
                  <NumberEntry
                    id={id + 'total'}
                    label="label.noOfAnimals"
                    name={id + 'total'}
                    onChange={(event) => totalAnimalsChange(event.target.value)}
                    value={batch.animalTotal}
                  />
                </div>
              </div>
              <div className={classes.batchNumber}>
                <div>
                  <FieldEntry
                    hideCharacterCount={true}
                    id={id + 'batchId'}
                    labelPosition={constants.labelPosition.above}
                    labelText={speciesLabel}
                    maxLength={config.WIDTH_BATCH_NUMBER}
                    name={id + 'batchId'}
                    onChange={(event) => batchNumberChange(event.target.value)}
                    value={batch.batchNumber}
                    width={config.WIDTH_BATCH_NUMBER}
                  />
                </div>
              </div>
              <div className={classes.action}>
                <Button
                  buttonType="secondary"
                  disabled={disableAddBatch()}
                  id="buttonAdd"
                  label={buttonLabel}
                  onClick={() => submitBatch()}
                />
              </div>
            </div>
            <div className={warningClassname} />
          </fieldset>

          <span>
            <p className={totalAnimalsError ? '' : classes.hidden}>
              {t('movements.animal-selection.batch.input-field-error-label')}
            </p>
          </span>
        </>
      }
    </>
  );
};

AddBatch.propTypes = {
  batchHeader: PropTypes.string,
  batchList: PropTypes.array,
  buttonLabel: PropTypes.string,
  id: PropTypes.string.isRequired,
  setBatchList: PropTypes.func.isRequired,
  setBatchTotal: PropTypes.func,
  setDataChanged: PropTypes.func.isRequired,
  speciesId: PropTypes.number
};

export default AddBatch;
