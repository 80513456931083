import React from 'react';
import PropTypes from 'prop-types';
import classes from 'components/base/modalConfirm/modalConfirm.module.scss';
import { useTranslation } from 'react-i18next';
import Backdrop from 'components/base/backdrop/backdrop';
import Button from 'components/base/button/button';

const ModalConfirm = ({
  classNames,
  cleanUp,
  currentOpenModalId,
  id,
  labelCancel,
  labelConfirm,
  modalClose,
  modalConfirm,
  modalMessage,
  modalTitle
}) => {
  const { ready, t } = useTranslation();

  const [confirmDisabled, setConfirmDisabled] = React.useState(false);

  React.useEffect(() => {
    document.onkeydown = (e) => {
      if (e.key === 'Escape' && modalClose) {
        modalClose();
      }
    };

    const modal = document.getElementById(id);

    if (modal) {
      const focusable = modal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const firstFocusable = focusable[0];

      modal.addEventListener('transitionend', () => {
        firstFocusable.focus();
      });

      setTimeout(() => {
        modal.classList.add(classes.open);
      }, 1);

      const currentOpenModal = document.getElementById(currentOpenModalId);
      if (currentOpenModalId && currentOpenModal) {
        const observer = new MutationObserver((mutations_list) => {
          mutations_list.forEach((mutation) => {
            mutation.removedNodes.forEach((removed_node) => {
              if (removed_node.id === id) {
                const currentOpenModalFocusable = currentOpenModal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
                const currentOpenModalFirstFocusable = currentOpenModalFocusable[0];

                currentOpenModalFirstFocusable.focus();
                observer.disconnect();
              }
            });
          });
        });

        observer.observe(document.querySelector('main'), { subtree: false, childList: true });
      }
    }
  });

  const componentClassNames = [
    classes.modal,
    ...(classNames ? classNames.map((className) => classes[className]) : [])
  ].join(' ').trim();

  return (
    <>
      {ready &&
        <>
          <Backdrop
            isConfirm={true}
            onClick={modalClose ? modalClose : () => cleanUp(false)}
          />
          <div className={componentClassNames} id={id}>
            <input className={classes.hiddenInput} type="text" />
            {modalTitle &&
              <div className={classes.modalHeader}>
                {t(modalTitle)}
                <Button
                  buttonType="modalClose"
                  classNames={['modalConfirm']}
                  onClick={modalClose ? modalClose : () => cleanUp && cleanUp(false)}
                />
              </div>
            }
            {modalMessage &&
              <div className={classes.modalBody}>
                {typeof modalMessage === 'string' &&
                  <div dangerouslySetInnerHTML={{ __html: t(modalMessage) }} id="modalContent" />
                }
                {Array.isArray(modalMessage) &&
                  <div dangerouslySetInnerHTML={{ __html: modalMessage.map((line) => t(line)).join('<br /><br />') }} id="modalContent" />
                }
              </div>
            }

            <div className={classes.modalFooter}>
              <div className="left">
                {(modalConfirm || cleanUp) &&
                  <Button
                    buttonType="secondary"
                    disabled={confirmDisabled}
                    id={id + 'Confirm'}
                    label={labelConfirm ? labelConfirm : 'button.yes'}
                    onClick={() => {
                      setConfirmDisabled(true);
                      if (modalConfirm) {
                        modalConfirm();
                      } else if (cleanUp) {
                        cleanUp(true);
                      }
                    }}
                  />
                }
              </div>

              <div className="right">
                <Button
                  buttonType="tertiary"
                  disabled={confirmDisabled}
                  id={id + 'Close'}
                  label={labelCancel ? labelCancel : 'button.no'}
                  onClick={modalClose ? modalClose : () => cleanUp && cleanUp(false)}
                />
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

ModalConfirm.propTypes = {
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  cleanUp: PropTypes.func,
  currentOpenModalId: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelCancel: PropTypes.string,
  labelConfirm: PropTypes.string,
  modalClose: PropTypes.func,
  modalConfirm: PropTypes.func,
  modalMessage: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.string
    ),
    PropTypes.string
  ]),
  modalTitle: PropTypes.string
};

export default ModalConfirm;
