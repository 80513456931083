import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'components/movementDates/movementDates.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import moment from 'moment';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import InputDate from 'components/base/inputDate/inputDate';

const MovementDates = ({
  arrivalDate,
  arrivalDateError,
  departureDate,
  isMovementOn,
  label,
  movementDate,
  setArrivalDate,
  setArrivalDateError
}) => {
  const { ready, t } = useTranslation();

  const arrivalDateFormatted = arrivalDate ? moment(arrivalDate).format('DD/MM/YYYY') : '';
  const departureDateFormatted = departureDate ? moment(departureDate).format('DD/MM/YYYY') : '';

  let movementDateString;
  if (movementDate?.entryDay && movementDate.entryMonth && movementDate.entryYear) {
    movementDateString = moment().year(movementDate.entryYear).month(movementDate.entryMonth - 1).date(movementDate.entryDay);
  } else if (movementDate) {
    movementDateString = movementDate;
  }

  const getDates = {
    arrivalMin: () => {
      return helpers.date.formatYYYYMMDD(new Date(movementDateString));
    },

    arrivalMax: () => {
      return helpers.date.formatYYYYMMDD(
        helpers.date.momentToJSDate(
          helpers.date.momentMinDate(
            helpers.date.addPeriod(movementDate, 1, constants.period.days),
            helpers.date.todayEndOfDay()
          )
        )
      );
    }
  };

  return (
    <>
      {ready &&
        <>
          {isMovementOn &&
            <h3 className="h3">{t(label)}</h3>
          }

          {isMovementOn &&
            <div className={classes.summaryRow + ' ' + classes.noBorder}>
              <div className={classes.label}>{isMovementOn ? t('label.departureDate') : t('label.movementDate')}</div>
              <div>{movementDateString ? moment(movementDateString).format('DD/MM/YYYY') : ''}</div>
            </div>
          }

          {!isMovementOn &&
            <>
              <div className={classes.summaryRow + ' ' + classes.noBorder}>
                <div className={classes.label}>{t('label.departureDate')}</div>
                <div>{departureDateFormatted}</div>
              </div>
              {departureDateFormatted !== arrivalDateFormatted &&
                <div className={classes.summaryRow + ' ' + classes.noBorder}>
                  <div className={classes.label}>{t('label.arrivalDate')}</div>
                  <div>{arrivalDateFormatted}</div>
                </div>
              }
            </>
          }

          {isMovementOn &&
            <div className={classes.summaryRow}>
              <div className={classes.label}>{t('label.arrivalDate')}</div>
              <div>
                <InputDate
                  blockStyling={true}
                  dontValidate={true}
                  error={arrivalDateError}
                  id="arrivalDate"
                  maxDate={getDates.arrivalMax()}
                  minDate={getDates.arrivalMin()}
                  onChange={(event) => setArrivalDate(event.target.value)}
                  setError={setArrivalDateError}
                  value={arrivalDate}
                  yearLength={4}
                />
              </div>
              {!arrivalDate &&
                <ErrorLabel
                  classNames={['inline']}
                  label="label.pleaseEnterArrivalDate"
                />
              }
            </div>
          }
        </>
      }
    </>
  );
};

MovementDates.propTypes = {
  arrivalDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  arrivalDateError: PropTypes.string,
  departureDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  isMovementOn: PropTypes.bool,
  label: PropTypes.string,
  movementDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  setArrivalDate: PropTypes.func,
  setArrivalDateError: PropTypes.func
};

export default MovementDates;
