import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from 'components/base/backdrop/backdrop.module.scss';

const Backdrop = ({
  isConfirm,
  onClick
}) => {
  const componentClassNames = [
    classes.backdrop,
    isConfirm ? classes.isConfirm : null
  ].join(' ').trim();

  useEffect(() => {
    document.body.classList.add('modal-open');

    return () => {
      if (!document.querySelector('.modalInstance')) {
        document.body.classList.remove('modal-open');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={componentClassNames} onClick={onClick} />
  );
};

Backdrop.propTypes = {
  isConfirm: PropTypes.bool,
  onClick: PropTypes.func
};

export default Backdrop;
