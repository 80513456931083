import React from 'react';
import { useTranslation } from 'react-i18next';
import storeService from 'services/storeService';
import Address from 'components/address/address';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import VetClaimsInfo from 'frontEnd/vetModule/components/vetClaimsInfo';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import helpers from 'services/helpers';

const VetSummaryInfo = ({ setModal, vanNo, visitDate = '', vetAbattoirInfo = [] }) => {
  const { ready, t } = useTranslation(),
    cphDetails = storeService.session.get.vetCPHData() ? storeService.session.get.vetCPHData() : '',
    attestationDate = cphDetails.attestationDate ? helpers.date.format(new Date(cphDetails.attestationDate)) : visitDate;

  return (
    <>
      {ready && cphDetails &&
        <>
          <div className={classes.summaryRow}>
            <div className={classes.label}>{t('vetContent.visitDate')}</div>
            <div className={classes.value}>{attestationDate}</div>
          </div>
          {vanNo &&
            <div className={classes.summaryRow}>
              <div className={classes.label}>{t('vetContent.vetAttestationNumber')}</div>
              <div className={classes.value}>{vanNo ? vanNo : '-'}</div>
            </div>
          }
          <div className={classes.summaryRow}>
            <div className={classes.label}>{t('label.holdingName')}</div>
            <div className={classes.value}>{cphDetails.propertyName ? cphDetails.propertyName : '-'}</div>
          </div>
          <div className={classes.summaryRow}>
            <div className={classes.label}>{t('label.holdingAddress')}</div>
            <div className={classes.value}>
              <Address
                cph={cphDetails.id}
                displayCPH = {false}
                format={{ address: constants.address.format.onlyAddress }}
                setModal={setModal}
              />
            </div>
          </div>
          <div className={classes.summaryRow}>
            <div className={classes.label}>{t('label.cphNumber')}</div>
            <div className={classes.value}>{cphDetails.id}</div>
          </div>
          <VetClaimsInfo vetAbattoirInfo = {vetAbattoirInfo} />
        </>
      }
    </>
  );
};

VetSummaryInfo.propTypes = {
  setModal: PropTypes.func.isRequired,
  vanNo: PropTypes.string
};

export default VetSummaryInfo;
