import React, { useEffect, useState } from 'react';
import storeService from 'services/storeService';
import { useTranslation } from 'react-i18next';
import Address from 'components/address/address';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import classes from 'frontEnd/vetModule/vetModule.module.scss';

const VetHoldingInfo = ({ setModal }) => {
  const { ready, t } = useTranslation(),
    [cphData, setCphData] = useState({});

  useEffect(() => {
    if (storeService.session.get.vetCPHData()) {
      const cphDetails = storeService.session.get.vetCPHData();
      setCphData(cphDetails);
    }
  }, []);

  return (
    <>
      {ready &&
      <>
        <div className={classes.holdingInfoContanier}>
          <div className={classes.holdingInfoBlock}>
            <div>
              <span className={classes.fieldLabel}>{t('label.cph')}: </span>
              <span className={classes.fieldvalue}>{cphData.id}</span>
            </div>
            <div>
              <span className={classes.fieldLabel}>{t('label.holdingAddress')}: </span>
              <Address
                cph={cphData.id}
                displayCPH = {false}
                format={{ address: constants.address.format.inline }}
                setModal={setModal}
              />
            </div>
          </div>
        </div>
      </>
      }
    </>
  );
};

VetHoldingInfo.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default VetHoldingInfo;
