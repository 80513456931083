import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/legend/legend.module.scss';
import PropTypes from 'prop-types';
import Tooltip from 'components/base/tooltip/tooltip';

const Legend = ({
  classNames,
  disabled,
  legend,
  optional,
  tooltip,
  tooltipPosition
}) => {
  const { ready, t } = useTranslation();

  const outputLegend = t(legend);

  const componentClassNames = [
    classes.legend,
    disabled ? classes.disabled : '',
    ...(classNames ? classNames.map((className) => classes[className]) : [])
  ].join(' ').trim();

  return (
    <>
      {ready && outputLegend &&
        <legend className={componentClassNames}>
          {outputLegend}
          {optional &&
            <>
              {' '}{t('label.optional')}
            </>
          }
          {tooltip &&
            <Tooltip tooltip={tooltip} tooltipPosition={tooltipPosition} />
          }
        </legend>
      }
    </>
  );
};

Legend.propTypes = {
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  disabled: PropTypes.bool,
  legend: PropTypes.string,
  optional: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string
};

export default Legend;
