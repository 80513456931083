import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from 'backOfficeComponents/base/button/button';
import classes from 'backOfficeComponents/summary/exemptionSummary/exemptionSummary.module.scss';
import helpers from 'services/helpers';

const ExemptionSummary = ({
  data,
  label,
  onClick
}) => {
  const { ready, t } = useTranslation();

  const displayExemption = () => {
    if (data.exemptions?.length > 0) {
      return (
        <>
          {(helpers.option.movement.isExempt(data.exempt) &&
            <>
              {data.exemptions.filter((o) => o.value)
                .map((o) => (
                  <div key={o.id}>{o.label}</div>
                ))}
            </>)
          }
          {(!helpers.option.movement.isExempt(data.exempt) && t('radioButtons.movementExemption.response'))}
        </>
      );
    }
    return (
      t('movements.noExemptions')
    );
  };

  return (
    <>
      {ready &&
        <div className={classes.summaryRow + ' ' + classes.noBorder}>
          <div className={classes.label}>{t(label)}</div>
          <div className={classes.value}>{displayExemption()}</div>

          {onClick &&
            <div className={classes.action}>
              <Button
                buttonType="link"
                id="editExemptionsButton"
                label="label.edit"
                onClick={onClick}
              />
            </div>
          }
        </div>
      }
    </>
  );
};

ExemptionSummary.propTypes = {
  data: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default ExemptionSummary;
