import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import classes from 'frontEnd/animals/animalDetails/animalDetails.module.scss';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import Button from 'components/base/button/button';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import Linker from 'components/base/linker/linker';
import Table from 'components/table/table';

const AnimalDetails = ({
  location,
  match,
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const animalsList = location?.state?.data?.animalsList;

  const isTagInMyHolding = (tag) => {
    const matchTag = animalsList?.find((animalItem) => animalItem.tagNumber === tag || animalItem.rfid === tag);
    return matchTag !== undefined;
  };

  const currentCPH = storeService.session.get.holding().value;
  const species = storeService.session.get.species();

  const [data, setData] = React.useState({});
  const [showProgeny, setShowProgeny] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(null);

  const paginationParams = {
    individual: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        hideTotal: true
      }
    },
    progeny: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        hideTotal: true
      }
    },
    tagReplacement: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        hideTotal: true
      }
    }
  };

  paginationParams.individual[constants.pagination.bottom].total = data.movements?.length;
  paginationParams.progeny[constants.pagination.bottom].total = data.progenies?.length;
  paginationParams.tagReplacement[constants.pagination.bottom].total = data.tagReplacements?.length;

  const createDataWithExtraInfo = (data) => {
    if (data.dob && data.age) {
      const animalWithSameDay = helpers.date.withSameCalendarDay(data.dob, data.age);
      const tempAge = helpers.date.ageInYears(data.dob);

      data.formattedDob = animalWithSameDay ? helpers.date.format(data.dob) : moment(data.dob).year();
      data.formattedAge = animalWithSameDay ? helpers.get.age(data.dob) : tempAge === 0 ? t('label.ageOneYear.under') : tempAge + t('label.ageOneYear.above');
    } else {
      data.formattedDob = '-';
      data.formattedAge = '-';
    }

    const initialTagging = {
      replacementDate: data.dateApplied ? data.dateApplied : null,
      requestId: data.initialTaggingRequestId
    };

    if (data.tagReplacements?.length > 0) {
      initialTagging.replacedIdentifier = data.tagReplacements[0].replacedIdentifier;
    } else {
      initialTagging.replacedIdentifier = data.tagNumber;
    }

    if (data.tagReplacements) {
      data.tagReplacements.unshift(initialTagging);
    }

    if (data.movements?.length > 0) {
      helpers.sortArrayOfObjects(data.movements, 'dom', constants.sorting.descending);
    }

    setData(data);
  };

  const displayTagNumber = (label, value) => {
    if (helpers.tag.canLink(value)) {
      return (
        <div className={classes.data}>
          <span className={classes.label}>
            {t(label)}
          </span>
          {isTagInMyHolding(value)
            ? <Linker
              label={value}
              to={Routing.animals + value}
            />
            : <span>{value}</span>
          }
        </div>
      );
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    setCurrentId(match.params.tagNumber);

    bff
      .get('/animalDetails', {
        cancelToken: source.token,
        params: {
          speciesId: species.id,
          tagNumber: match.params.tagNumber,
          cph: currentCPH
        }
      })
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          createDataWithExtraInfo(res.data.data[0]);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.tagNumber]);

  return (
    <>
      {ready &&
        <>
          <Linker
            label="button.back"
            linkType="back"
            to={Routing.animals}
          />

          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h1 className="h1">{currentId}</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className={classes.animalInfo}>
                <div className={classes.data}>
                  <span className={classes.label}>{t('label.breed')}</span>
                  {data.breedName}
                </div>
                <div className={classes.data}>
                  <span className={classes.label}>{t('label.gender')}</span>
                  {data.genderName === '-' ? t('label.unknown') : data.genderName}
                </div>
                <div className={classes.data}>
                  <span className={classes.label}>{t('label.yearDob')}</span>
                  {data.formattedDob}
                </div>
                <div className={classes.data}>
                  <span className={classes.label}>{t('label.age')}</span>
                  {data.formattedAge}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-8">
              {helpers.species.isCattleId(species.id) &&
                <div className={classes.animalInfo}>
                  {displayTagNumber('label.damId', data.damTagNumber)}
                  {displayTagNumber('label.geneticDamId', data.geneticDamTagNumber)}
                  {displayTagNumber('label.sireId', data.sireTagNumber)}
                </div>
              }

              {!helpers.species.isCattleId(species.id) &&
                <div className={classes.animalInfo}>
                  {displayTagNumber('label.damId', data.damTagNumber)}
                  {displayTagNumber('label.geneticDamId', data.geneticDamTagNumber)}
                  {displayTagNumber('label.sireId', data.sireTagNumber)}
                </div>
              }
            </div>
          </div>

          {(
            data.movements?.length > 0 ||
            data.death ||
            data.progenies?.length > 0 ||
            data.tagReplacements?.length > 0
          ) &&
            <div className={classes.accordions}>
              {data.movements?.length > 0 &&
                <details className="accordion">
                  <summary>
                    <h2>{t('label.movement')}</h2>
                  </summary>
                  <div className="section">
                    <Table
                      columns={tableColumns.animalDetails.movements}
                      data={data.movements}
                      dataProvided={true}
                      initialSortBy={initialSorting.animalDetails.movements.accessor}
                      initialSortDirection={initialSorting.animalDetails.movements.direction}
                      paginationParams={paginationParams.individual}
                      setData={setData}
                      setDataProp="movements"
                      setModal={setModal}
                    />
                  </div>
                </details>
              }

              {data.death &&
                <>
                  <div className="row padTop10 padBottom10">
                    <div className="col-md-2">
                      <h2 className="bold">{t('label.death')}</h2>
                    </div>
                  </div>
                  <div className="row padTop10 padBottom10">
                    <div className="col-md-2">
                      <FieldLabel label="label.dateDeath" />
                      <span className="bold">{data.death.killDate}</span>
                    </div>
                    <div className="col-md-6">
                      <FieldLabel label="label.locationOfCarcassOptional" />
                      <span className="bold">{data.death.killHolding}</span>
                    </div>
                    <div className="col-md-4 right">
                      <FieldLabel label="label.requestId" />
                      <span className="bold">{data.death.requestId}</span>
                    </div>
                  </div>
                  <div className="padAll10">
                    <hr />
                  </div>
                </>
              }

              {data.progenies?.length > 0 &&
                <details className="accordion" open={showProgeny}>
                  <summary>
                    <h2>{t('label.progeny')}</h2>
                  </summary>
                  <div className="section">
                    <Table
                      columns={helpers.species.isDeerId(species.id)
                        ? tableColumns.animalDetails.progenies.deer({ setShowProgeny })
                        : tableColumns.animalDetails.progenies.nonDeer({ setShowProgeny })
                      }
                      data={data.progenies}
                      dataProvided={true}
                      initialSortBy={initialSorting.animalDetails.progenies.accessor}
                      initialSortDirection={initialSorting.animalDetails.progenies.direction}
                      paginationParams={paginationParams.progeny}
                      setData={setData}
                      setDataProp="progenies"
                      setModal={setModal}
                    />
                  </div>
                </details>
              }

              {data.tagReplacements?.length > 0 &&
                <details className="accordion">
                  <summary>
                    <h2>{t('label.taggingHistory')}</h2>
                  </summary>
                  <div className="section">
                    <Table
                      columns={tableColumns.animalDetails.tagReplacements({ t })}
                      data={data.tagReplacements}
                      dataProvided={true}
                      initialSortBy={initialSorting.animalDetails.tagReplacements.accessor}
                      initialSortDirection={initialSorting.animalDetails.tagReplacements.direction}
                      paginationParams={paginationParams.tagReplacement}
                      setData={setData}
                      setDataProp="tagReplacements"
                      setModal={setModal}
                    />
                  </div>
                </details>
              }
            </div>
          }

          <div className={classes.actions}>
            <div className={classes.left} />
            <div className={classes.right}>
              <Button
                buttonType="tertiary"
                label="button.exit"
                onClick={() => history.push(Routing.animals)}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

AnimalDetails.propTypes = {
  location: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default AnimalDetails;
