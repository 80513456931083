import React, { useEffect, useState } from 'react';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import classes from 'pages/tokens/tokens.module.scss';
import storeService from 'services/storeService';
import Button from 'components/base/button/button';
import ErrorLabel from 'components/base/errorLabel/errorLabel';

const Tokens = () => {
  const cookie = {
    bearer: storeService.cookie.get.bearer(),
    xOnBehalfOf: storeService.cookie.get.xOnBehalfOf()
  };

  const [bearer, setBearer] = useState('');
  const [pending, setPending] = useState(true);
  const [tryAgain, setTryAgain] = useState(false);
  const [tokensCopied, setTokensCopied] = useState({
    bearer: false,
    xOnBehalfOf: false
  });

  const copyIntoClipboard = (which) => {
    const elm = document.getElementById(which);
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(elm);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('Copy');

    setTokensCopied({
      ...tokensCopied,
      [which]: true
    });

    setTimeout(() => {
      setTokensCopied({
        ...tokensCopied,
        [which]: false
      });
    }, 2000);
  };

  const fetchBearerToken = (source) => {
    bff
      .get('/bearerToken', {
        cancelToken: source.token
      })
      .then((res) => {
        storeService.cookie.set.bearer(res.data);
        setBearer(res.data);
        setPending(false);
        setTryAgain(false);
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setPending(false);
          setTryAgain(false);
        }
      });
  };

  const highlightParts = (token) => {
    const tokenParts = token.split('.');
    const newTokenParts = [];

    newTokenParts.push({ text: tokenParts[0], className: 'header' });
    newTokenParts.push({ text: '.' });
    newTokenParts.push({ text: tokenParts[1], className: 'body' });
    newTokenParts.push({ text: '.' });
    newTokenParts.push({ text: tokenParts[2], className: 'footer' });
    return newTokenParts;
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetchBearerToken(source);

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (tryAgain) {
      setPending(true);
      fetchBearerToken(source);
    }

    return () => source.cancel();
  }, [tryAgain]);

  return (
    <div className={classes.tokensPage}>
      <div className={classes.tokenRow}>
        <div className={classes.token}>
          {pending &&
            <ErrorLabel
              classNames={['noMargin']}
              isWarning={true}
              label="label.fetchingBearerToken"
            />
          }
          {!pending &&
            <>
              {bearer &&
                <code id="bearer">
                  {highlightParts(bearer).map((item, index) => (
                    <span className={classes[item.className]} dangerouslySetInnerHTML={{ __html: item.text }} key={index} />
                  ))}
                </code>
              }
              {!bearer &&
                <ErrorLabel
                  classNames={['noMargin']}
                  label="error.noBearerToken"
                />
              }
            </>
          }
        </div>
        <div className={classes.button}>
          {pending &&
            <Button
              classNames={['fullWidth']}
              disabled={true}
              label="label.copyBearerToken"
            />
          }
          {!pending &&
            <>
              {bearer &&
                <Button
                  classNames={['fullWidth']}
                  label="label.copyBearerToken"
                  onClick={() => copyIntoClipboard('bearer')}
                />
              }
              {!bearer &&
                <Button
                  buttonType="danger"
                  classNames={['fullWidth']}
                  label="label.tryAgain"
                  onClick={() => setTryAgain((prevState) => !prevState)}
                />
              }
            </>
          }
          {tokensCopied.bearer &&
            <p className={classes.copied}>Copied to clipboard!</p>
          }
        </div>
      </div>
      <div className={classes.tokenRow}>
        <div className={classes.token}>
          <code id="xOnBehalfOf">
            {highlightParts(cookie.xOnBehalfOf).map((item, index) => (
              <span className={classes[item.className]} dangerouslySetInnerHTML={{ __html: item.text }} key={index} />
            ))}
          </code>
        </div>
        <div className={classes.button}>
          <Button
            classNames={['fullWidth']}
            label="label.copyXOnBehalfOfToken"
            onClick={() => copyIntoClipboard('xOnBehalfOf')}
          />
          {tokensCopied.xOnBehalfOf &&
            <p className={classes.copied}>Copied to clipboard!</p>
          }
        </div>
      </div>
    </div>
  );
};
export default Tokens;
