import React, { useEffect, useState } from 'react';
import Button from 'components/base/button/button';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import storeService from 'services/storeService';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import VetSummaryInfo from 'frontEnd/vetModule/components/vetSummaryInfo';
import helpers from 'services/helpers';
import vetModuleHelper from 'services/vetModuleHelper';
import { isCancel } from 'services/bff';
import { Prompt } from 'react-router';

const VetDeclaration = ({ location, setModal }) => {
  const { instance } = useMsal(),
    { ready, t } = useTranslation(),
    [vetClaims, setVetClaims] = React.useState({}),
    [vanNo, setVanNo] = useState(''),
    cphDetails = storeService.session.get.vetCPHData() ? storeService.session.get.vetCPHData() : '',
    attestationDate = cphDetails.attestationDate;

  useEffect(() => {
    if (storeService.session.get.vetClaims()) {
      const claimsDetails = storeService.session.get.vetClaims();
      setVetClaims(claimsDetails);
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    vetModuleHelper.fetchVetDetails(cphDetails.id, 'true')
      .then((res) => {
        const vetResult = res.data.data[0];
        if (vetResult) {
          const vetAttestationNumber = helpers.vetAttestationNumber.generateVan(vetResult.vetRCVS, vetResult.holding, attestationDate);
          setVanNo(vetAttestationNumber);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          return false;
        }
      });
    helpers.scrollToTop();
  }, [cphDetails.id]);

  const printAndDownload = () => {
    window.print();
  };

  const logout = () => {
    storeService.cookie.removeAll.all();
    storeService.local.removeAll.all();
    storeService.session.removeAll.all();
    // call msal to logout and redirect UI to root
    instance.logoutRedirect({ postLogoutRedirectUri: '/' })
      .catch((error) => {
        console.error(error); //eslint-disable-line
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {ready &&
        <>
          <Prompt
            message={(params) => {
              return (
                !storeService.session.get.idle() &&
                (location.pathname !== params.pathname && params.pathname !== '/logout')
              )
                ? t('vetContent.vetDeclaration.back-restriction')
                : true;
            }}
          />
          <main className={classes.mainContanier}>
            <section className={classes.titleSection + ' ' + classes.bottomBorder}>
              <h1 className={classes.pageTitle}>{t('vetContent.attestationCertificate')}</h1>
            </section>

            <section className={classes.bodySection}>
              <div className={classes.summaryBlock + ' ' + classes.topSpace}>
                <VetSummaryInfo setModal={setModal} vanNo={vanNo} />
              </div>
              <div className={classes.declarationBlock}>
                <div className={classes.fontBold}>{t('vetContent.vetDeclaration.title')}</div>
                <p>{t('vetContent.vetDeclaration.content1')}</p>
                <div>{helpers.date.format(new Date(cphDetails.attestationDate))}</div>
                <p className={classes.declartionContent}>
                  {t('vetContent.vetDeclaration.content2-part-1')}
                  <span>&#8544;</span>&nbsp;
                  {t('vetContent.vetDeclaration.content2-part-2')}
                </p>
                <div className={classes.fontBold}>{vetClaims.name}</div>
              </div>
            </section>
            <div className={classes.notificationsWarning + ' ' + classes.notForPrint}>
              <i className={`bi bi-exclamation-circle-fill ${classes.icon}`} />
              <span className={classes.text}>{t('vetContent.vetDeclaration.vetDeclarationNotificationsRequiringAction')}</span>
            </div>

            <section className={classes.buttonSection + ' ' + classes.displayBtnBoth + ' ' + classes.notForPrint}>
              <span className={classes.buttonLink} onClick={printAndDownload}>{t('button.printSave')}</span>
              <Button
                buttonType="primary"
                label="Logout"
                onClick={logout}
              />
            </section>
          </main>
        </>
      }
    </>
  );
};

export default VetDeclaration;
