import React, { useState } from 'react';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import bff, { bffResponse, bffStatus, isCancel } from 'services/bff';
import classes from 'frontEnd/holdingSummary/handshake/handshake.module.scss';
import constants from 'services/constants';
import config from 'config';
import errors from 'services/errors';
import helpers from 'services/helpers';
import moment from 'moment';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import AddressSummary from 'components/summary/addressSummary/addressSummary';
import AnimalDetails from 'components/summary/animalDetails/animalDetails';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import Linker from 'components/base/linker/linker';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import MovementDates from 'components/movementDates/movementDates';
import TransportInformationBox from 'components/summary/transportInformationBox/transportInformationBox';
import { cloneDeep } from 'lodash';

const Handshake = ({
  location,
  match,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionDataChanged = storeService.session.get.dataChanged();
  const sessionArrivalDate = storeService.session.get.movementNotificationArrivalDate();
  const role = storeService.session.get.permissions();
  const holding = storeService.session.get.holding();

  const cph = storeService.session.get.holding().value;
  const originalData = location.state?.data;
  const species = storeService.session.get.species();

  const isMovementOn = cph === location.state?.data?.destinationHolding;

  const [data, setData] = React.useState(originalData);
  const [page, setPage] = React.useState(0);
  const [accept, setAccept] = React.useState(null);
  const [requestId, setRequestId] = React.useState(parseInt(match.params.handshakeId));
  const [dataChanged, setDataChanged] = React.useState(sessionDataChanged ? sessionDataChanged : false);
  const [animalDataChanged, setAnimalDataChanged] = React.useState(sessionDataChanged ? sessionDataChanged : false);
  const [loadPending, setLoadPending] = React.useState(true);
  const [submitPending, setSubmitPending] = React.useState(false);
  const [pollingStatus, setPollingStatus] = React.useState(null);
  const [arrivalDate, setArrivalDate] = useState(sessionArrivalDate ? sessionArrivalDate : data.arrivalDateTime);
  const [arrivalDateError, setArrivalDateError] = useState('');
  const [movementRef, setMovementRef] = React.useState(null);
  const [pollingErrors, setPollingErrors] = React.useState([]);
  const [pollingWarnings, setPollingWarnings] = React.useState([]);
  const [animalsAddedOrRemoved, setAnimalsAddedOrRemoved] = React.useState(false);
  const [showRejectModal, setShowRejectModal] = React.useState(false);
  const [sessionAnimalTotal, setSessionAnimalTotal] = React.useState(0);

  const formatInfoData = (inData) => {
    const movementType = cph === inData.destinationHolding ? constants.option.movement.on : constants.option.movement.off;

    return {
      movementType,
      otherHolding: {
        value: helpers.option.movement.isOn(movementType) ? inData.sourceHolding : inData.destinationHolding
      }
    };
  };

  const confirmation = {
    button: {
      viewSummary: (requestId, requestType) => ({
        id: 'viewSummary',
        label: 'button.viewSummary',
        onClick: () => {
          setPermission(userRoleType.GENERIC);
          storeService.session.set.permissions(userRoleType.GENERIC);
          storeService.session.remove.holding();

          setTimeout(() => {
            history.push({
              pathname: Routing.activityDetails + requestId,
              state: {
                data: {
                  requestId,
                  requestType,
                  current: {
                    returnUrl: permissions.isKeeper() ? Routing.handshake : Routing.userActivity,
                    permissions: role,
                    holding
                  }
                }
              }
            });
          }, [100]);
        }
      }),

      returnToHoldingSummary: () => ({
        id: 'returnToHoldingSummary',
        label: 'button.returnToHoldingSummary',
        onClick: () => {
          storeService.session.remove.dataChanged();
          history.push(Routing.keeperHolding);
        }
      }),

      returnToUserActivity: () => ({
        id: 'returnToUserActivity',
        label: 'button.returnToUserActivity',
        onClick: () => {
          storeService.session.remove.dataChanged();
          storeService.session.set.permissions(userRoleType.GENERIC);
          setPermission(userRoleType.GENERIC);
          history.push(Routing.userActivity);
        }
      })
    },

    link: {
      viewMovementDocument: (speciesId, ref) => ({
        label: helpers.species.isSheepId(speciesId) || helpers.species.isGoatId(speciesId) ? 'button.viewMovementDocument' : null,
        param: ref,
        type: constants.linkTypes.pdfButton
      })
    }
  };

  const postMovement = (params) => {
    setSubmitPending(true);

    bff
      .post('/movement', params)
      .then((res) => {
        const { data } = res;

        setRequestId(bffResponse.requestId(data));
        setPollingStatus(bffResponse.status(data));
        setPollingErrors(bffResponse.errors(data));
        setPollingWarnings(bffResponse.warnings(data));
        setMovementRef(bffResponse.movementRef(data));

        // postHandshake(outData);
        storeService.session.remove.dataChanged();
        setDataChanged(false);
        setPage(1);
      })
      .catch((error) => {
        errors.BFF(error, setModal);
        setAccept(null);
        setShowRejectModal(false);
      })
      .finally(() => {
        setSubmitPending(false);
      });
  };

  const postHandshake = (outData) => {
    setSubmitPending(true);

    bff
      .post('/handshake', outData)
      .then((res) => {
        const { data } = res;

        storeService.session.remove.dataChanged();
        setDataChanged(false);

        setRequestId(bffResponse.requestId(data));
        setPollingStatus(bffResponse.status(data));
        setPollingErrors(bffResponse.errors(data));
        setPollingWarnings(bffResponse.warnings(data));
        setMovementRef(bffResponse.movementRef(data));

        setPage(1);
      })
      .catch((error) => {
        errors.BFF(error, setModal);
        setAccept(null);
        setShowRejectModal(false);
      })
      .finally(() => {
        setSubmitPending(false);
      });
  };

  const processResponse = () => {
    const inData = cloneDeep(data);

    inData.addedDevices = inData.reviewDevices.filter((device) => device.added).map((device) => ({ tagNumber: device.tagNumber }));
    inData.removedDevices = inData.reviewDevices.filter((device) => device.removed).map((device) => ({ tagNumber: device.tagNumber }));
    inData.reviewDevices = inData.reviewDevices.map((device) => ({ tagNumber: device.tagNumber })).filter((reviewDevice) => !inData.addedDevices.some((addedDevice) => addedDevice.tagNumber === reviewDevice.tagNumber) && !inData.removedDevices.some((removedDevice) => removedDevice.tagNumber === reviewDevice.tagNumber));

    inData.addedBatches = inData.reviewBatches.filter((batch) => batch.added).map((batch) => ({ batchMovementId: batch.batchMovementId, animalTotal: batch.animalTotal, batchNumber: batch.batchNumber }));
    inData.removedBatches = inData.reviewBatches.filter((batch) => batch.removed).map((batch) => ({ batchMovementId: batch.batchMovementId, animalTotal: batch.animalTotal, batchNumber: batch.batchNumber }));
    inData.reviewBatches = inData.reviewBatches.map((batch) => ({ batchMovementId: batch.batchMovementId, animalTotal: batch.animalTotal, batchNumber: batch.batchNumber })).filter((reviewBatch) => !inData.addedBatches.some((addedBatch) => addedBatch.batchNumber === reviewBatch.batchNumber) && !inData.removedBatches.some((removedBatch) => removedBatch.batchNumber === reviewBatch.batchNumber));

    const animalsAdded = inData.addedDevices.length > 0 || inData.addedBatches.length > 0;
    const animalsRemoved = inData.removedDevices.length > 0 || inData.removedBatches.length > 0;
    setAnimalsAddedOrRemoved(animalsAdded || animalsRemoved);

    let isAccepted = accept;
    if (isAccepted) {
      isAccepted = (inData.reviewDevices.length > 0 || inData.reviewBatches.length > 0) && !animalsAdded;
    }

    let arrivalDateTime = null;
    if (arrivalDate) {
      arrivalDateTime = helpers.date.appendTime(arrivalDate, moment(originalData.departureDateTime).hours(), moment(originalData.departureDateTime).minutes(), moment(originalData.departureDateTime).seconds());

      if (helpers.date.is.inTheFuture(arrivalDateTime)) {
        arrivalDateTime = helpers.date.appendTime(arrivalDate, moment().hours(), moment().minutes(), moment().seconds());
      }
    }

    const outData = {
      poll: config.POLLS_ENABLED,
      requestId,
      isAccepted,
      data: {
        ...cloneDeep(inData),
        cph
      }
    };

    delete outData.data.arrivalDateTime;

    if (isMovementOn) {
      outData.data.arrivalDate = arrivalDate;
    }

    delete outData.addedDevices;
    delete outData.addedBatches;

    if (inData.addedDevices.length > 0 || inData.addedBatches.length > 0) {
      outData.data.removedDevices = originalData.reviewDevices;
      outData.data.reviewDevices = [];
      outData.data.addedDevices = [];

      outData.data.removedBatches = originalData.reviewBatches;
      outData.data.reviewBatches = [];
      outData.data.addedBatches = [];

      let animalsBeingMoved = [];
      let batchList = [];
      let numberOfAnimals = 0;

      if (inData.reviewDevices.length > 0) {
        animalsBeingMoved = inData.reviewDevices.map((animal) => ({ tagNumber: animal.tagNumber }));
        numberOfAnimals += inData.reviewDevices.length;
      }
      if (inData.addedDevices.length > 0) {
        animalsBeingMoved = animalsBeingMoved.concat(inData.addedDevices.map((animal) => ({ tagNumber: animal.tagNumber })));
        numberOfAnimals += inData.addedDevices.length;
      }

      if (inData.reviewBatches.length > 0) {
        batchList = inData.reviewBatches;
        numberOfAnimals += inData.reviewBatches.reduce((total, batch) => total + parseInt(batch.animalTotal), 0);
      }
      if (inData.addedBatches.length > 0) {
        batchList = batchList.concat(inData.addedBatches);
        numberOfAnimals += inData.addedBatches.reduce((total, batch) => total + parseInt(batch.animalTotal), 0);
      }

      const uniqueBatches = batchList.reduce((unique, reduceItem) => {
        const existingItem = unique.find((findItem) => findItem.batchNumber === reduceItem.batchNumber);

        if (existingItem) {
          return [
            ...unique.map((mapItem) => {
              if (mapItem.batchNumber === reduceItem.batchNumber) {
                return ({
                  ...mapItem,
                  animalTotal: mapItem.animalTotal + reduceItem.animalTotal
                });
              }

              return mapItem;
            })
          ];
        }

        return [...unique, reduceItem];
      }, []);

      let arrivalDate = null;
      if (arrivalDateTime) {
        // arrival date should be the date part of user entered arrival date
        arrivalDate = helpers.date.formatForSubmission(arrivalDateTime, false);
        // If the date part of departure and arrival are the same, then use the departure date instead
        if (arrivalDate === helpers.date.formatForSubmission(originalData.departureDateTime, false)) {
          arrivalDate = originalData.departureDateTime;
        }
      }
      const newMovementOutData = {
        poll: config.POLLS_ENABLED,
        movementType: cph === originalData.sourceHolding ? constants.option.movement.off : constants.option.movement.on,
        trackingId: '',
        userHolding: cph,
        sourceHolding: originalData.sourceHolding,
        destinationHolding: originalData.destinationHolding,
        animalCount: numberOfAnimals,
        species: species.name,
        departureDate: originalData.departureDateTime,
        arrivalDate,
        fciData: {},
        transportInformation: {},
        finalAnimalDetails: {
          animalsBeingMoved,
          batchList: uniqueBatches,
          numberOfAnimalsSelected: numberOfAnimals,
          recordAnimalDetails: null,
          recordAnimalTagAllocationDetails: null
        }
      };

      postMovement(newMovementOutData);
    } else {
      postHandshake(outData);
    }
  };

  const acceptHandshake = () => {
    if (data.reviewDevices?.length > 0 || data.reviewBatches?.length > 0) {
      setAccept(true);
      setSubmitPending(true);
    }
  };

  const rejectHandshake = () => {
    setData((prevState) => {
      const newData = cloneDeep(prevState);

      newData.reviewDevices = newData.reviewDevices.map((item) => ({
        ...item,
        removed: true,
        added: false
      }));
      newData.reviewBatches = newData.reviewBatches.map((item) => ({
        ...item,
        removed: true,
        added: false
      }));

      return newData;
    });

    setAccept(false);
    setSubmitPending(true);
  };

  const getConfirmLabel = () => {
    if (accept) {
      return animalsAddedOrRemoved ? 'handshake.acceptWithAmendments' : 'handshake.accept';
    }

    return 'handshake.reject';
  };

  const handleChanges = {
    addAnimals: (animals) => {
      const newData = cloneDeep(data);
      let animalsPresent = newData.reviewDevices.length;

      newData.reviewDevices.push(
        ...animals.map((animal) => ({
          ...animal,
          added: true,
          index: animalsPresent++
        }))
      );

      setData(newData);
      setDataChanged(true);
      setAnimalDataChanged(true);

      const sessionAddedAnimals = storeService.session.get.movementNotificationAddedAnimals();
      storeService.session.set.movementNotificationAddedAnimals([
        ...(sessionAddedAnimals ? sessionAddedAnimals : []),
        ...animals
      ]);
      storeService.session.set.dataChanged(true);
    },

    batchList: (batchList) => {
      const newData = cloneDeep(data);
      let batchesPresent = newData.reviewBatches.length - 1;

      newData.reviewBatches = batchList.map((batch) => ({
        ...batch,
        added: batch.added !== undefined ? batch.added : true,
        index: batchesPresent++
      }));

      setData(newData);
      setDataChanged(true);
      setAnimalDataChanged(true);

      const sessionAddedBatches = storeService.session.get.movementNotificationAddedBatches();
      storeService.session.set.movementNotificationAddedBatches([
        ...(sessionAddedBatches ? sessionAddedBatches : []),
        ...batchList.filter((batch) => batch.added === undefined)
      ]);
      storeService.session.set.dataChanged(true);
    },

    removeAnimal: (animal) => {
      setData((prevState) => {
        if (animal.added) {
          const newDeviceList = helpers.tag.remove([...prevState.reviewDevices], animal);

          const sessionAddedAnimals = storeService.session.get.movementNotificationAddedAnimals();
          storeService.session.set.movementNotificationAddedAnimals(helpers.tag.remove([...sessionAddedAnimals], animal));

          return {
            ...prevState,
            reviewDevices: newDeviceList
          };
        }

        const newDeviceList = cloneDeep(prevState.reviewDevices);
        const animalToRemove = newDeviceList.find((item) => item.tagNumber === animal.tagNumber);

        animalToRemove.removed = true;

        const sessionRemovedAnimals = storeService.session.get.movementNotificationRemovedAnimals();
        storeService.session.set.movementNotificationRemovedAnimals([
          ...(sessionRemovedAnimals ? sessionRemovedAnimals : []),
          animal
        ]);

        return {
          ...prevState,
          reviewDevices: newDeviceList
        };
      });

      setDataChanged(true);
      setAnimalDataChanged(true);
      storeService.session.set.dataChanged(true);
    },

    removeAnimalsAll: () => {
      const newData = cloneDeep(data);
      newData.reviewDevices = data.reviewDevices.filter((device) => !device.added).map((device) => ({
        ...device,
        removed: true
      }));
      setData(newData);
      setDataChanged(true);
      setAnimalDataChanged(true);

      const sessionRemovedAnimals = storeService.session.get.movementNotificationRemovedAnimals();
      storeService.session.set.movementNotificationRemovedAnimals([
        ...(sessionRemovedAnimals ? sessionRemovedAnimals : []),
        ...data.reviewDevices.filter((device) => !device.added)
      ]);
      storeService.session.set.movementNotificationAddedAnimals([]);
      storeService.session.set.dataChanged(true);
    },

    removeAnimalsAllInvalid: () => {
      const invalidAnimals = data.reviewDevices.filter((animal) => helpers.tag.isInvalid(animal)).map((animal) => animal.tagNumber);

      const newData = cloneDeep(data);
      newData.reviewDevices = data.reviewDevices.filter((device) => helpers.tag.isValid(device));
      setData(newData);

      const sessionAddedAnimals = storeService.session.get.movementNotificationAddedAnimals();
      storeService.session.set.movementNotificationAddedAnimals([
        ...sessionAddedAnimals.filter((tag) => !invalidAnimals.includes(tag.tagNumber))
      ]);
    },

    removeBatch: (batch) => {
      setData((prevState) => {
        if (batch.added) {
          const newBatchList = helpers.batch.remove([...prevState.reviewBatches], batch);

          const sessionAddedBatches = storeService.session.get.movementNotificationAddedBatches();
          storeService.session.set.movementNotificationAddedBatches(helpers.batch.remove([...sessionAddedBatches], batch));

          return {
            ...prevState,
            reviewBatches: newBatchList
          };
        }

        const newBatchList = cloneDeep(prevState.reviewBatches);
        const batchToRemove = newBatchList.find((item) => item.batchNumber === batch.batchNumber);

        batchToRemove.removed = true;

        const sessionRemovedBatches = storeService.session.get.movementNotificationRemovedBatches();
        storeService.session.set.movementNotificationRemovedBatches([
          ...(sessionRemovedBatches ? sessionRemovedBatches : []),
          batch
        ]);

        return {
          ...prevState,
          reviewBatches: newBatchList
        };
      });

      setDataChanged(true);
      setAnimalDataChanged(true);
      storeService.session.set.dataChanged(true);
    },

    removeBatchesAll: () => {
      const newData = cloneDeep(data);
      newData.reviewBatches = data.reviewBatches.filter((batch) => !batch.added).map((batch) => ({
        ...batch,
        removed: true
      }));
      setData(newData);
      setDataChanged(true);
      setAnimalDataChanged(true);

      const sessionRemovedBatches = storeService.session.get.movementNotificationRemovedBatches();
      storeService.session.set.movementNotificationRemovedBatches([
        ...(sessionRemovedBatches ? sessionRemovedBatches : []),
        ...data.reviewBatches.filter((batch) => !batch.added).map((batch) => batch.batchNumber)
      ]);
      storeService.session.set.movementNotificationAddedBatches([]);
      storeService.session.set.dataChanged(true);
    },

    removeBatchesAllInvalid: () => {
      const invalidBatches = data.reviewBatches.filter((batch) => helpers.batch.isInvalid(batch)).map((batch) => batch.batchNumber);

      const newData = cloneDeep(data);
      newData.reviewBatches = data.reviewBatches.filter((batch) => helpers.batch.isValid(batch));
      setData(newData);

      const sessionAddedBatches = storeService.session.get.movementNotificationAddedBatches();
      storeService.session.set.movementNotificationAddedBatches([
        ...sessionAddedBatches.filter((batch) => !invalidBatches.includes(batch.batchNumber))
      ]);
    },

    restoreAnimal: (animal) => {
      setData((prevState) => {
        const newDeviceList = cloneDeep(prevState.reviewDevices);
        const animalToRemove = newDeviceList.find((item) => item.tagNumber === animal.tagNumber);

        delete animalToRemove.removed;

        const sessionRemovedDevices = storeService.session.get.movementNotificationRemovedAnimals();
        storeService.session.set.movementNotificationRemovedAnimals(helpers.tag.remove([...sessionRemovedDevices], animal));

        return {
          ...prevState,
          reviewDevices: newDeviceList
        };
      });

      setDataChanged(true);
      setAnimalDataChanged(true);
      storeService.session.set.dataChanged(true);
    },

    restoreBatch: (batch) => {
      setData((prevState) => {
        const newBatchList = cloneDeep(prevState.reviewBatches);
        const batchToRemove = newBatchList.find((item) => item.batchNumber === batch.batchNumber);

        delete batchToRemove.removed;

        const sessionRemovedBatches = storeService.session.get.movementNotificationRemovedBatches();
        storeService.session.set.movementNotificationRemovedBatches(helpers.batch.remove([...sessionRemovedBatches], batch));

        return {
          ...prevState,
          reviewBatches: newBatchList
        };
      });

      setDataChanged(true);
      setAnimalDataChanged(true);
      storeService.session.set.dataChanged(true);
    },

    setMovementDateArrival: (value) => {
      setArrivalDate(value);
      storeService.session.set.dataChanged(true);
      setDataChanged(true);
    }
  };

  React.useEffect(() => {
    if (accept !== null && submitPending) {
      processResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitPending, accept]);

  React.useEffect(() => {
    if (arrivalDate) {
      const today = helpers.date.todayEndOfDay();
      const departureDateMoment = helpers.date.isoToMomentDate(data.movementDate);
      const arrivalDateMoment = helpers.date.stringDateToMoment(arrivalDate);

      if (helpers.date.is.beforeDate(arrivalDateMoment, departureDateMoment)) {
        setArrivalDateError('error.arrivalDateBeforeDepartureDate');
      } else if (helpers.date.is.xPeriodAfterDate(arrivalDateMoment, departureDateMoment, 2, constants.period.days)) {
        setArrivalDateError('movements.arrival.moreThan1DayAfterDeparture');
      } else if (helpers.date.is.afterDate(arrivalDateMoment, today)) {
        setArrivalDateError('error.arrivalDateInTheFuture');
      } else {
        setArrivalDateError('');
        storeService.session.set.movementNotificationArrivalDate(arrivalDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivalDate]);

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    setLoadPending(true);

    bff
      .get('/movementAnimals', {
        cancelToken: source.token,
        params: {
          isPreMovement: location.state.data.isPreMovement,
          movementPendingId: location.state.data.movementPendingId,
          movementId: location.state.data.movementId
        }
      })
      .then((res) => {
        setLoadPending(false);
        setData((prevState) => ({
          ...prevState,
          reviewDevices: res.data.data.devices.map((item, index) => ({
            ...item,
            index,
            added: false,
            removed: false,
            ageToDisplay: helpers.animal.ageToDisplay(item.beginBirthPeriod, item.endBirthPeriod),
            dobToDisplay: helpers.animal.dobToDisplay(item.beginBirthPeriod, item.endBirthPeriod)
          })),
          reviewBatches: res.data.data.batches.map((item, index) => ({
            ...item,
            index,
            added: false,
            removed: false
          }))
        }));
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (data) {
      const sessionAddedAnimals = storeService.session.get.movementNotificationAddedAnimals();
      const sessionAddedBatches = storeService.session.get.movementNotificationAddedBatches();
      const sessionRemovedAnimals = storeService.session.get.movementNotificationRemovedAnimals();
      const sessionRemovedBatches = storeService.session.get.movementNotificationRemovedBatches();

      if (sessionAddedAnimals || sessionAddedBatches || sessionRemovedAnimals || sessionRemovedBatches) {
        const newData = cloneDeep(data);

        if (sessionAddedAnimals) {
          let animalsPresent = newData.reviewDevices.length;

          newData.reviewDevices.push(
            ...sessionAddedAnimals.map((animal) => ({
              ...animal,
              added: true,
              index: animalsPresent++
            }))
          );
        }

        if (sessionAddedBatches) {
          newData.reviewBatches.push(
            ...sessionAddedBatches.map((batch) => ({
              ...batch,
              added: true
            }))
          );
        }

        if (sessionRemovedAnimals) {
          sessionRemovedAnimals.forEach((removedAnimal) => {
            newData.reviewDevices.find((animal) => animal.tagNumber === removedAnimal.tagNumber).removed = true;
          });
        }

        if (sessionRemovedBatches) {
          sessionRemovedBatches.forEach((removedBatch) => {
            newData.reviewBatches.find((batch) => batch.batchNumber === removedBatch.batchNumber).removed = true;
          });
        }

        setData(newData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.keeperHandshakeDetails.length) !== Routing.keeperHandshakeDetails) {
        storeService.session.remove.movementNotification();
        storeService.session.remove.dataChanged();
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(Routing.keeperHolding);
      }
    };
  }, [history]);

  React.useEffect(() => {
    helpers.scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const sessionAnimalTotalBatches = data.reviewBatches?.reduce((total, batch) => total + parseInt(batch.removed ? 0 : batch.animalTotal), 0);
    const sessionAnimalTotalIndividual = data.reviewDevices?.reduce((total, individual) => total + parseInt(individual.removed ? 0 : 1), 0);

    setSessionAnimalTotal(sessionAnimalTotalBatches + sessionAnimalTotalIndividual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {ready &&
        <>
          <Prompt
            message={(params) => {
              return (!storeService.session.get.idle() && dataChanged && params.pathname.substring(0, Routing.handshake.length) !== Routing.handshake) ? 'warning.unsavedChanges1' : true;
            }}
          />
          {submitPending &&
            <Confirmation
              label="label.submissionPending"
              setModal={setModal}
              status={constants.status.pending}
            />
          }
          {!submitPending && page === 0 &&
            <>
              <Linker
                label="button.back"
                linkType="back"
                to={Routing.keeperHolding}
              />

              <h1 className="h1">{t('label.movementNotification')}</h1>

              <AboutPanel
                descriptions={[
                  { text: 'about.handshake.desc1' },
                  { text: 'about.handshake.desc2' },
                  { text: 'about.handshake.desc3' },
                  { text: 'about.handshake.desc4' }
                ]}
                title="about.genericTitle.page"
              />

              <h2 className="h2">{t('label.movementRef')} {data.documentRef}</h2>

              <div className="row">
                <div className="col-sm-12">
                  <TransportInformationBox
                    movementData={formatInfoData(data)}
                    numberBeingMoved={data.animalTotal}
                    setModal={setModal}
                    speciesName={data.species}
                  />
                  <MovementDates
                    arrivalDate={arrivalDate}
                    arrivalDateError={arrivalDateError}
                    departureDate={data.departureDateTime}
                    isMovementOn={isMovementOn}
                    label="movements.movement-date.dateLabel"
                    movementDate={data.movementDate}
                    setArrivalDate={setArrivalDate}
                    setArrivalDateError={setArrivalDateError}
                    setDataChanged={setDataChanged}
                  />
                  <AddressSummary
                    info1={data.sourceHolding}
                    info2={data.destinationHolding}
                    label="label.movementDirection"
                    label1="label.departureHoldingFrom"
                    label2="label.destinationHoldingTo"
                    queryKeeper={true}
                    setModal={setModal}
                  />
                </div>
              </div>

              {data.reviewDevices && data.reviewBatches &&
                <AnimalDetails
                  addAnimals={handleChanges.addAnimals}
                  data={data}
                  label="label.animalDetails"
                  pending={submitPending}
                  removeAnimal={handleChanges.removeAnimal}
                  removeAnimalsAll={handleChanges.removeAnimalsAll}
                  removeAnimalsAllInvalid={handleChanges.removeAnimalsAllInvalid}
                  removeBatch={handleChanges.removeBatch}
                  removeBatchesAll={handleChanges.removeBatchesAll}
                  removeBatchesAllInvalid={handleChanges.removeBatchesAllInvalid}
                  restoreAnimal={handleChanges.restoreAnimal}
                  restoreBatch={handleChanges.restoreBatch}
                  setData={setData}
                  setModal={setModal}
                  setPending={setSubmitPending}
                  updateBatchList={handleChanges.batchList}
                />
              }

              <div className={classes.actions}>
                <div className={classes.accept}>
                  <Button
                    buttonType="primary"
                    disabled={
                      loadPending ||
                      submitPending ||
                      sessionAnimalTotal === 0 ||
                      (data && data.reviewBatches && data.reviewBatches.some((batch) => helpers.batch.isInvalid(batch))) ||
                      (data && data.reviewDevices && data.reviewDevices.some((tag) => helpers.tag.isInvalid(tag))) ||
                      (isMovementOn && (!arrivalDate || arrivalDateError !== ''))
                    }
                    label={animalDataChanged ? 'button.submitAmendmentMovement' : 'button.acceptMovement'}
                    onClick={acceptHandshake}
                  />
                </div>
                <div className={classes.reject}>
                  <Button
                    buttonType="danger"
                    disabled={loadPending || submitPending}
                    label="button.rejectMovement"
                    onClick={() => setShowRejectModal(true)}
                  />
                </div>
                <div className={classes.exitBtn}>
                  <Button
                    buttonType="tertiary"
                    label="button.exitNoChanges"
                    onClick={() => history.push(Routing.keeperHolding)}
                  />
                </div>
              </div>

              {showRejectModal &&
                <ModalConfirm
                  id="confirmReject"
                  modalClose={() => setShowRejectModal(false)}
                  modalConfirm={rejectHandshake}
                  modalMessage="warning.handshakeRejectConfirm"
                  modalTitle="label.rejectMovement"
                />
              }
            </>
          }
          {!submitPending && page === 1 &&
            <>
              {requestId && !movementRef && // Submission successful but polling timed out
                <Confirmation
                  confirm={{
                    id: requestId,
                    label: 'label.paperId',
                    onClick: confirmation.button.returnToUserActivity().onClick
                  }}
                  label={getConfirmLabel()}
                  setModal={setModal}
                  status={pollingStatus}
                />
              }
              {movementRef && // Submission and polling successful
                <Confirmation
                  buttons={[
                    requestId && !bffStatus.isClaPending(pollingStatus) ? confirmation.button.returnToHoldingSummary() : null,
                    requestId && !bffStatus.isClaPending(pollingStatus)
                      ? confirmation.button.viewSummary(requestId,
                        storeService.session.get.movementNotificationAddedAnimals()?.length > 0 ||
                        storeService.session.get.movementNotificationAddedBatches()?.length > 0
                          ? constants.option.requestType.movement
                          : constants.option.requestType.movementHandshake
                      )
                      : null
                  ].filter((item) => item)}
                  confirm={{
                    id: movementRef,
                    label: 'label.movementRef'
                  }}
                  errors={pollingErrors}
                  label={getConfirmLabel()}
                  links={[
                    confirmation.link.viewMovementDocument(species.id, { movementRef, requestId })
                  ]}
                  setModal={setModal}
                  status={pollingStatus}
                  warnings={pollingWarnings}
                />
              }
            </>
          }
        </>
      }
    </>
  );
};

Handshake.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default Handshake;
