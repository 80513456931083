import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from 'backOfficeComponents/base/hint/hint.module.scss';

const Hint = ({
  hint,
  id
}) => {
  const { ready, t } = useTranslation();

  const outputHint = t(hint);
  const classname = classes.hint + ((hint === outputHint) ? ' ' + classes.notFound : '');

  return (
    <>
      {ready && outputHint &&
        <span className={classname} id={id + 'Hint'} >
          {outputHint}
        </span>
      }
    </>
  );
};

Hint.propTypes = {
  hint: PropTypes.string,
  id: PropTypes.string.isRequired
};

export default Hint;
