import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'frontEnd/movementsGatherings/transportDetails/transportDetails.module.scss';
import config from 'config';
import constants from 'services/constants';
import helpers from 'services/helpers';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import TextEntry from 'components/base/textEntry/textEntry';

const TransportDetails = ({
  haulierNameError,
  panelTitle,
  setAuthorisationNumber,
  setTransportedBy,
  setTransporterName,
  setVehicleRegistrationNumber,
  transportAuthNumberError,
  transportDetails,
  transportDetailsError,
  vehicleRegNoError
}) => {
  const { ready, t } = useTranslation();

  const optional = !permissions.isBackOffice();

  return (
    <>
      {ready &&
        <details className="accordion top-border">
          <summary>
            <h2>{t(panelTitle)}</h2>
          </summary>
          <div>
            <div className="section">
              <RadioButtons
                error={helpers.response.isError(transportDetailsError.type) ? transportDetailsError.value : ''}
                id="transporterRadio"
                ids={[
                  constants.option.transporter.departureKeeper,
                  constants.option.transporter.destinationKeeper,
                  constants.option.transporter.haulier
                ]}
                inline={true}
                name="transportedBy"
                onChange={(event) => setTransportedBy(event.target.value)}
                reduceSpace={true}
                value={transportDetails.transportedBy}
                warning={helpers.response.isWarning(transportDetailsError.type) ? transportDetailsError.value : ''}
              />
            </div>
            {transportDetails.transportedBy &&
              <div className={classes.transportInformation}>
                <div className="section">
                  <TextEntry
                    errorLabel={vehicleRegNoError}
                    hideCharacterCount={true}
                    id="vehicleRegistrationNumber"
                    label="label.vehicleRegistration"
                    maxLength={config.WIDTH_REGISTRATION}
                    name="vehicleRegistrationNumber"
                    onChange={(event) => setVehicleRegistrationNumber(event.target.value)}
                    optional={optional}
                    value={transportDetails.vehicleRegistrationNumber}
                    width="full"
                  />
                </div>

                <div className="section">
                  <TextEntry
                    disabled={!helpers.option.transporter.isHaulier(transportDetails.transportedBy)}
                    errorLabel={helpers.response.isError(haulierNameError.type) ? haulierNameError.value : ''}
                    hideCharacterCount={true}
                    id="transporterName"
                    label="label.haulageCompany"
                    maxLength={config.WIDTH_TRANSPORTER_NAME}
                    name="transporterName"
                    onChange={(event) => setTransporterName(event.target.value)}
                    optional={optional}
                    value={transportDetails.transporterName}
                    warningLabel={helpers.response.isWarning(haulierNameError.type) ? haulierNameError.value : ''}
                    width="full"
                  />
                </div>

                <div className="section">
                  <TextEntry
                    errorLabel={helpers.response.isError(transportAuthNumberError.type) ? transportAuthNumberError.value : ''}
                    hideCharacterCount={true}
                    id="transporterAuthorisation"
                    label="label.transportAuth"
                    maxLength={config.WIDTH_TRANSPORTER_AUTH}
                    name="transporterAuthorisation"
                    onChange={(event) => setAuthorisationNumber(event.target.value)}
                    optional={optional}
                    value={transportDetails.transportAuthorisationNumber}
                    warningLabel={helpers.response.isWarning(transportAuthNumberError.type) ? transportAuthNumberError.value : ''}
                    width="full"
                  />
                </div>
              </div>
            }
          </div>
        </details>
      }
    </>
  );
};

TransportDetails.propTypes = {
  haulierNameError: PropTypes.object,
  panelTitle: PropTypes.string,
  setAuthorisationNumber: PropTypes.func.isRequired,
  setTransportedBy: PropTypes.func.isRequired,
  setTransporterName: PropTypes.func.isRequired,
  setVehicleRegistrationNumber: PropTypes.func.isRequired,
  transportAuthNumberError: PropTypes.object,
  transportDetails: PropTypes.object,
  transportDetailsError: PropTypes.object,
  vehicleRegNoError: PropTypes.string
};

export default TransportDetails;
