import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isCancel } from 'services/bff';
import axios from 'axios';
import errors from 'services/errors';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import CheckboxGroup from 'components/base/checkboxGroup/checkboxGroup';

const findPermitExemption = (mandatoryExemption, id) => Object.values(mandatoryExemption).some((exemption) => exemption.id === id) || false;

const Exemptions = ({
  groupLabel,
  isAmend,
  options,
  panelTitle,
  resetForm,
  setDataChanged,
  setModal,
  setOptions,
  speciesId
}) => {
  const { ready, t } = useTranslation();

  const exemptionChange = (event) => {
    setDataChanged();
    setOptions(options.map((o) => ({
      id: o.id,
      label: o.label,
      name: o.name,
      value: (event.target.id === String(o.id)) ? !o.value : o.value,
      disabled: findPermitExemption(constants.permitMovesMandatory, o.id)
    })));
  };

  useEffect(() => {
    if (resetForm !== undefined) {
      if (isAmend) {
        const sessionOriginalExemptions = storeService.session.get.originalMovementExemptions();

        setOptions((prevState) => prevState.map((o) => ({
          ...o,
          value: Boolean(sessionOriginalExemptions.find((ex) => ex.id === o.id && ex.value)),
          disabled: findPermitExemption(constants.permitMovesMandatory, o.id)
        })));
      } else {
        setOptions((prevState) => prevState.map((o) => ({
          ...o,
          value: false,
          disabled: findPermitExemption(constants.permitMovesMandatory, o.id)
        })));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const sessionExemptions = storeService.session.get.movementExemptions();

    helpers.get.processingFlags(source.token, constants.option.requestType.movement, speciesId)
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal)) {
          setOptions(res.data.map((item) => {
            const isPermitMoveExemption = Object.values(constants.permitMovesMandatory).some((mandatoryExemption) => mandatoryExemption.id === item.id);

            return {
              ...item,
              value: sessionExemptions ? Boolean(sessionExemptions.find((ex) => ex.id === item.id && ex.value)) : false,
              disabled: isPermitMoveExemption || false
            };
          }));
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready && options?.length > 0 &&
        <details className="accordion">
          <summary>
            <h2>{t(panelTitle)}</h2>
          </summary>
          <div className="section">
            <CheckboxGroup
              id="exemptionsGroup"
              legendLabel={groupLabel}
              objects={options}
              onChange={exemptionChange}
            />
          </div>
        </details>
      }
    </>
  );
};

Exemptions.propTypes = {
  groupLabel: PropTypes.string,
  isAmend: PropTypes.bool,
  options: PropTypes.array,
  panelTitle: PropTypes.string,
  resetForm: PropTypes.bool,
  setDataChanged: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setOptions: PropTypes.func,
  speciesId: PropTypes.number
};

export default Exemptions;
