import React, { useState } from 'react';
import classes from 'components/base/inputDate/inputDate.module.scss';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import DatePicker from 'components/base/datePicker/datePicker';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import Hint from 'components/base/hint/hint';
import Legend from 'components/base/legend/legend';

const InputDate = ({
  clear,
  disabled,
  dontValidate,
  error,
  errorPosition,
  hint,
  id,
  labelPosition,
  labelText,
  legend,
  maxDate,
  minDate,
  onChange,
  onOpen,
  optional,
  setClear,
  setError,
  setValue,
  value,
  warning,
  warningPosition,
  yearLength
}) => {
  const handleChanges = {
    onBlur: (event, id) => {
      const dateField = document.getElementById(id);
      // Dirty hack to cater for Duet Date Picker's lacks as highlighted in:
      // https://github.com/duetds/date-picker/issues/47
      // https://github.com/duetds/date-picker/issues/57

      const dateFormat = yearLength === 4 ? 'DD/MM/YYYY' : 'DD/MM/YY';
      if (dateField && helpers.date.is.invalid(dateField.value, dateFormat)) {

        const dateFieldValue = helpers.date.formatJSDatePreformatted(dateField.value, dateFormat);
        if (dateFieldValue === 'Invalid date') {
          setError('error.dateInvalid');
        } else {
          const reformattedValueForEndpoint = helpers.date.formatYYYYMMDDPreformatted(dateFieldValue, dateFormat);
          onChange({ target: { type: 'select', name: 'date', value: reformattedValueForEndpoint } });
          dateField.value = dateFieldValue;
        }
      } else {
        onChange({ target: { type: 'select', name: 'date', value: event.target.value } });
      }
    },

    onChange: (event) => {
      onChange({ target: { type: 'select', name: 'date', value: event.detail.value } });
    }
  };

  const [hasInlineWarningOrError, setHasInlineWarningOrError] = useState(false);

  const componentClassNames = [
    classes.date,
    hasInlineWarningOrError ? classes.inlineWarningOrError : ''
  ].join(' ').trim();

  React.useEffect(() => {
    if (dontValidate) {
      if (clear) {
        setValue('');
        setClear(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, setError, clear, setClear]);

  React.useEffect(() => {
    if (
      (error && helpers.labelPosition.isInline(errorPosition)) ||
      (warning && helpers.labelPosition.isInline(warningPosition))
    ) {
      setHasInlineWarningOrError(true);
    }
  }, [error, errorPosition, warning, warningPosition]);

  return (
    <fieldset role="group">
      {legend &&
        <Legend
          legend={legend}
          optional={optional}
        />
      }
      {labelText && (!labelPosition || helpers.labelPosition.isAbove(labelPosition)) &&
        <FieldLabel
          label={labelText}
          optional={optional}
        />
      }
      <Hint
        hint={hint}
      />
      {warning && (!warningPosition || helpers.labelPosition.isAbove(warningPosition)) &&
        <ErrorLabel
          classNames={['dateWidth']}
          isWarning={true}
          label={warning}
        />
      }
      {error && (!errorPosition || helpers.labelPosition.isAbove(errorPosition)) &&
        <ErrorLabel
          classNames={['dateWidth']}
          label={error}
        />
      }
      <div className={componentClassNames}>
        {labelText && helpers.labelPosition.isInline(labelPosition) &&
          <FieldLabel
            label={labelText}
            optional={optional}
          />
        }
        <DatePicker
          disabled={disabled}
          id={id}
          max={maxDate}
          min={minDate}
          onBlur={handleChanges.onBlur}
          onChange={handleChanges.onChange}
          onOpen={onOpen}
          value={value}
          yearLength={yearLength}
        />
        {warning && helpers.labelPosition.isInline(warningPosition) &&
          <ErrorLabel
            classNames={['inline']}
            isWarning={true}
            label={warning}
          />
        }
        {error && helpers.labelPosition.isInline(errorPosition) &&
          <ErrorLabel
            classNames={['inline']}
            label={error}
          />
        }
      </div>
    </fieldset>
  );
};

InputDate.propTypes = {
  clear: PropTypes.bool,
  disabled: PropTypes.bool,
  dontValidate: PropTypes.bool,
  error: PropTypes.string,
  errorPosition: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelPosition: PropTypes.oneOf([
    constants.labelPosition.above,
    constants.labelPosition.inline,
    constants.labelPosition.none
  ]),
  labelText: PropTypes.string,
  legend: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  optional: PropTypes.bool,
  setClear: PropTypes.func,
  setError: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  value: PropTypes.string,
  warning: PropTypes.string,
  warningPosition: PropTypes.string,
  yearLength: PropTypes.number
};

export default InputDate;
