import React from 'react';
import PropTypes from 'prop-types';
import classes from 'components/base/infoContainer/infoContainer.module.scss';
import { useTranslation } from 'react-i18next';

const InfoContainer = ({
  content,
  title
}) => {
  const { ready, t } = useTranslation();

  const infoTitle = t(title);

  return (
    <>
      {ready &&
        <div className={classes.informationMessage}>
          {title &&
            <p className={classes.title}>{infoTitle}</p>
          }
          {content &&
            <div className={classes.content} dangerouslySetInnerHTML={{ __html: t(content) }}></div>
          }
        </div>
      }
    </>
  );
};

InfoContainer.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string
};

export default InfoContainer;
