import React from 'react';
import classes from 'components/base/spinner/spinner.module.scss';

const Spinner = () => {
  return (
    <div className={classes.spinner}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
