import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import classes from 'components/base/modal/modal.module.scss';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import storeService from 'services/storeService';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import CheckboxGroup from 'components/base/checkboxGroup/checkboxGroup';

const ModalProductionTypes = ({
  holdingProductionTypes,
  modalClose,
  setModal,
  setModalHasChanges,
  setProductionTypesSubmitted
}) => {
  const { ready, t } = useTranslation();

  const [productionTypes, setProductionTypes] = React.useState([]);
  const [initialProductionTypes, setInitialProductionTypes] = React.useState([]);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [loadPending, setLoadPending] = useState(true);
  const [submitPending, setSubmitPending] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleChanges = {
    productionTypes: (event) => {
      const selectedTypes = productionTypes.map((o) => ({
        ...o,
        value: (event.target.id === String(o.id)) ? !o.value : o.value
      }));

      setModalHasChanges(true);
      setDisabled(false);

      setProductionTypes(selectedTypes);
    }
  };

  const submit = () => {
    setSubmitPending(true);

    const params = {
      holding: storeService.session.get.holding().value,
      productionTypes: productionTypes.filter((item1) => {
        const initialItemStatus = initialProductionTypes.find((item2) => item2.id === item1.id && item2.label === item1.label).value;

        return item1.value !== initialItemStatus;
      })
    };

    bff
      .post('/productionTypes', params)
      .then(() => {
        setShowConfirmation(true);
        setSubmitPending(false);
        setModalHasChanges(false);
        setProductionTypesSubmitted(true);
      })
      .catch((error) => {
        setSubmitPending(false);
        errors.BFF(error, setModal);
      });
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    bff
      .get('/productionTypes', {
        cancelToken: source.token
      })
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setLoadPending)) {
          const initProdTypes = res.data.map((item) => {
            const returnData = {
              id: item.id,
              label: item.name,
              value: Boolean(holdingProductionTypes.find((type) => type.name === item.name))
            };

            if (item.holdingProductionTypeId) {
              returnData.holdingProductionTypeId = item.holdingProductionTypeId;
            }

            return returnData;
          });

          setProductionTypes(initProdTypes);
          setInitialProductionTypes(initProdTypes);
          setLoadPending(false);
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <>
          <div className={classes.modalBody}>
            {showConfirmation &&
              <Confirmation
                confirm={null}
                label="label.productionTypesSubmitted"
                setModal={setModal}
                status={constants.status.success} // Hardcoding this until we have polling for this section
              />
            }
            {!showConfirmation &&
              <>
                <h1 className="h1">{t('label.productionTypes')}</h1>
                <div className="row">
                  <div className="col-md-8">
                    <CheckboxGroup
                      disabled={submitPending}
                      objects={productionTypes.map((item) => ({
                        id: item.id,
                        label: item.label,
                        value: item.value
                      }))}
                      onChange={handleChanges.productionTypes}
                      pending={loadPending}
                    />
                  </div>
                  <div className="col-md-4">
                    <AboutPanel
                      descriptions={[
                        { text: 'about.productionTypes.desc1' }
                      ]}
                      title="about.genericTitle.page"
                    />
                  </div>
                </div>
              </>
            }
          </div>
          <div className={classes.modalFooter}>
            <div></div>
            <div className={classes.modalActions}>
              {!showConfirmation &&
                <Button
                  buttonType="secondary"
                  disabled={disabled || loadPending || submitPending}
                  label="button.submit"
                  onClick={submit}
                  type="submit"
                />
              }
              <Button
                buttonType="tertiary"
                label={showConfirmation ? 'button.close' : 'button.cancel'}
                onClick={modalClose}
                type="submit"
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

ModalProductionTypes.propTypes = {
  holdingProductionTypes: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number,
      name: PropTypes.string,
      value: PropTypes.bool
    })
  ),
  modalClose: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setModalHasChanges: PropTypes.func,
  setProductionTypesSubmitted: PropTypes.func
};

export default ModalProductionTypes;
