import React, { useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useErrorBoundary } from 'use-error-boundary';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import config from 'config';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import ErrorPage from 'pages/error/error';
import Footer from 'components/footer/footer';
import Header from 'components/header/header';
import HeaderBackOffice from 'backOfficeComponents/header/headerBackOffice';
import NavBar from 'backOfficeComponents/header/navBar/navBar';

const LoginUI = React.lazy(() => import('themeWrappers/loginUI'));
const BackOfficeUI = React.lazy(() => import('themeWrappers/backOfficeUI'));
const PublicUI = React.lazy(() => import('themeWrappers/publicUI'));

const ProtectedRoute = ({
  exact,
  loading,
  MountComponent,
  path,
  permission,
  setModal,
  setPermission,
  usersAllowed
}) => {
  const { didCatch, error, ErrorBoundary, reset } = useErrorBoundary();
  const history = useHistory();
  //Resolved component style issue.
  const isLogin = !permissions.isLoggedIn();
  const isBackOffice = permissions.isBackOffice();
  const idleTimerRef = React.useRef(null);

  const [isUserIdle, setIsUserIdle] = React.useState(false);
  const [menuOpen, setMenu] = React.useState(false);

  const userActivity = {
    onIdle: () => {
      setIsUserIdle(true);
      storeService.session.set.idle(true);
      history.push(Routing.logout);
    },

    onActive: () => {
      setIsUserIdle(false);
    }
  };

  const getScreenSize = () => {
    setMenu(window.innerWidth < 576);
  };

  useEffect(() => {
    if (isBackOffice) {
      window.addEventListener('load', getScreenSize);
      window.addEventListener('resize', getScreenSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (permissions.isUserAllowed(usersAllowed)) {
    return (
      <>
        {!isUserIdle &&
          <IdleTimer
            eventsThrottle={0}
            onActive={() => userActivity.onActive()}
            onIdle={() => userActivity.onIdle()}
            ref={idleTimerRef}
            timeout={config.TIMEOUT}
          />
        }
        <React.Suspense fallback={<></>}>
          {isLogin && <LoginUI />}
          {isBackOffice && <BackOfficeUI />}
          {!isLogin && !isBackOffice && <PublicUI />}
        </React.Suspense>

        {!isBackOffice &&
          <>
            <Header
              permission={permission}
              resetErrorBoundaries={reset}
              setModal={setModal}
              setPermission={setPermission}
            />

            <div className="appContent container">
              <div className="row">
                <main className="col" role="main">
                  <Route
                    exact={exact}
                    path={path}
                    render={({ location, match }) => (
                      <>
                        {didCatch
                          ? (
                            <ErrorPage
                              error={error}
                              showError={false}
                            />
                          )
                          : (
                            <ErrorBoundary
                              render={() => (
                                <MountComponent
                                  loading={loading}
                                  location={location}
                                  match={match}
                                  setModal={setModal}
                                  setPermission={setPermission}
                                />
                              )}
                            />
                          )
                        }
                      </>
                    )}
                  />
                </main>
              </div>
            </div>

            {permissions.isLoggedIn() && <Footer />}
          </>
        }

        {isBackOffice &&
          <div className="appOuterContainer backOffice">
            <main className="appInnerContainer backOffice" role="main">
              <NavBar
                menuOpen={menuOpen}
                resetErrorBoundaries={reset}
                setMenu={setMenu}
              />

              <div className="appContent" id="appContent">
                <HeaderBackOffice
                  menuOpen={menuOpen}
                />

                <Route
                  exact={exact}
                  path={path}
                  render={({ location, match }) => (
                    <>
                      {didCatch
                        ? (
                          <ErrorPage
                            error={error}
                            showError={false}
                          />
                        )
                        : (
                          <ErrorBoundary
                            render={() => (
                              <MountComponent
                                location={location}
                                match={match}
                                setModal={setModal}
                                setPermission={setPermission}
                              />
                            )}
                          />
                        )
                      }
                    </>
                  )}
                />
              </div>
            </main>
          </div>
        }
      </>
    );
  }

  if (permissions.isLoggedIn()) {
    storeService.session.set.permissions(userRoleType.GENERIC);
    return <Redirect to={Routing.home} />;
  }

  return <Redirect to={Routing.root} />;
};

ProtectedRoute.propTypes = {
  MountComponent: PropTypes.func.isRequired,
  exact: PropTypes.bool,
  loading: PropTypes.exact({
    breeds: PropTypes.bool,
    config: PropTypes.bool,
    holdings: PropTypes.bool,
    requestTypes: PropTypes.bool
  }),
  path: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func.isRequired,
  usersAllowed: PropTypes.array.isRequired
};

export default ProtectedRoute;
