import React, { useEffect } from 'react';
import classes from 'components/base/tabs/tabs.module.scss';
import PropTypes from 'prop-types';
import Tab from 'components/base/tab/tab';

const Tabs = ({
  activeTabId,
  children,
  setActiveTabId
}) => {
  useEffect(() => {
    if (activeTabId) {
      setActiveTabId(activeTabId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabId]);

  const onClickTabItem = (tabId) => {
    setActiveTabId(tabId);
  };

  return (
    <div className={classes.tabs}>
      <ul className={classes.tabsList}>
        {children?.map((child) => {
          if (child) {
            const {
              extraContent,
              id,
              label
            } = child.props;

            return (
              <Tab
                extraContent={extraContent}
                id={id}
                isActive={id === activeTabId}
                key={label}
                label={label}
                onClick={id === activeTabId ? null : onClickTabItem}
              />
            );
          }
          return null;
        })}
      </ul>
      <div className={classes.tabContent}>
        {children?.map((child) => {
          if (child && child.props.id !== activeTabId) {
            return undefined;
          }
          return child ? child.props.children : undefined;
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  activeTabId: PropTypes.string.isRequired,
  children: PropTypes.instanceOf(Array).isRequired,
  setActiveTabId: PropTypes.func.isRequired
};

export default Tabs;
