import Routing from 'routing';
import useRoleType from 'services/userRoleType';
import storeService from 'services/storeService';

const navMapping = {
  handshakeDetails: {
    [useRoleType.KEEPER]: Routing.keeperHandshakeDetails
  },
  holdings: {
    [useRoleType.ABATTOIR]: Routing.holdings,
    [useRoleType.AICENTRE]: Routing.holdings,
    [useRoleType.ASSEMBLY]: Routing.holdings,
    [useRoleType.COLLECTION]: Routing.holdings,
    [useRoleType.COMMON]: Routing.holdings,
    [useRoleType.GENERIC]: Routing.holdings,
    [useRoleType.KEEPER]: Routing.holdings,
    [useRoleType.MARKET]: Routing.holdings,
    [useRoleType.OTHER]: Routing.holdings,
    [useRoleType.PORT]: Routing.holdings,
    [useRoleType.QUARANTINE]: Routing.holdings,
    [useRoleType.SHOW]: Routing.holdings,
    [useRoleType.SLAUGHTERMARKET]: Routing.holdings,
    [useRoleType.STOREMARKET]: Routing.holdings,
    [useRoleType.VET]: Routing.holdings
  },
  home: {
    [useRoleType.ABATTOIR]: Routing.home,
    [useRoleType.AICENTRE]: Routing.home,
    [useRoleType.ASSEMBLY]: Routing.home,
    [useRoleType.BACKOFFICE]: Routing.boVerifyCustomer,
    [useRoleType.COLLECTION]: Routing.home,
    [useRoleType.COMMON]: Routing.home,
    [useRoleType.GENERIC]: Routing.home,
    [useRoleType.KEEPER]: Routing.home,
    [useRoleType.MARKET]: Routing.home,
    [useRoleType.OTHER]: Routing.home,
    [useRoleType.PORT]: Routing.home,
    [useRoleType.QUARANTINE]: Routing.home,
    [useRoleType.SHOW]: Routing.home,
    [useRoleType.SLAUGHTERMARKET]: Routing.home,
    [useRoleType.STOREMARKET]: Routing.home,
    [useRoleType.VET]: Routing.home
  },
  movements: {
    [useRoleType.ABATTOIR]: Routing.abattoirMovements,
    [useRoleType.AICENTRE]: Routing.aiCentreMovements,
    [useRoleType.ASSEMBLY]: Routing.assemblyMovements,
    [useRoleType.BACKOFFICE]: Routing.boMovements,
    [useRoleType.COLLECTION]: Routing.collectionMovements,
    [useRoleType.COMMON]: Routing.commonMovements,
    [useRoleType.KEEPER]: Routing.movements,
    [useRoleType.MARKET]: Routing.marketMovements,
    [useRoleType.OTHER]: Routing.otherMovements,
    [useRoleType.PORT]: Routing.portMovements,
    [useRoleType.QUARANTINE]: Routing.quarantineMovements,
    [useRoleType.SHOW]: Routing.showMovements,
    [useRoleType.SLAUGHTERMARKET]: Routing.slaughterMarketMovements,
    [useRoleType.STOREMARKET]: Routing.storeMarketMovements,
    [useRoleType.VET]: Routing.vetMovements
  },
  abattoirAttestation: {
    [useRoleType.ABATTOIR]: Routing.abattoirAttestation
  },
  movementConfirm: {
    [useRoleType.ABATTOIR]: Routing.abattoirMovementConfirm,
    [useRoleType.AICENTRE]: Routing.aiCentreMovementConfirm,
    [useRoleType.ASSEMBLY]: Routing.assemblyMovementConfirm,
    [useRoleType.BACKOFFICE]: Routing.boMovementsConfirm,
    [useRoleType.COLLECTION]: Routing.collectionMovementConfirm,
    [useRoleType.COMMON]: Routing.commonMovementConfirm,
    [useRoleType.MARKET]: Routing.marketMovementConfirm,
    [useRoleType.OTHER]: Routing.otherMovementConfirm,
    [useRoleType.PORT]: Routing.portMovementConfirm,
    [useRoleType.QUARANTINE]: Routing.quarantineMovementConfirm,
    [useRoleType.SHOW]: Routing.showMovementConfirm,
    [useRoleType.SLAUGHTERMARKET]: Routing.slaughterMarketMovementConfirm,
    [useRoleType.STOREMARKET]: Routing.storeMarketMovementConfirm,
    [useRoleType.VET]: Routing.vetMovementConfirm
  },
  movementDetails: {
    [useRoleType.ABATTOIR]: Routing.abattoirMovementDetails,
    [useRoleType.AICENTRE]: Routing.aiCentreMovementDetails,
    [useRoleType.ASSEMBLY]: Routing.assemblyMovementDetails,
    [useRoleType.BACKOFFICE]: Routing.boMovementDetails,
    [useRoleType.COLLECTION]: Routing.collectionMovementDetails,
    [useRoleType.COMMON]: Routing.commonMovementDetails,
    [useRoleType.MARKET]: Routing.marketMovementDetails,
    [useRoleType.OTHER]: Routing.otherMovementDetails,
    [useRoleType.PORT]: Routing.portMovementDetails,
    [useRoleType.QUARANTINE]: Routing.quarantineMovementDetails,
    [useRoleType.SHOW]: Routing.showMovementDetails,
    [useRoleType.SLAUGHTERMARKET]: Routing.slaughterMarketMovementDetails,
    [useRoleType.STOREMARKET]: Routing.storeMarketMovementDetails,
    [useRoleType.VET]: Routing.vetMovementDetails
  },
  paperDetails: {
    [useRoleType.BACKOFFICE]: Routing.boMovements
  },
  search: {
    [useRoleType.ABATTOIR]: Routing.abattoirSearch,
    [useRoleType.AICENTRE]: Routing.aiCentreSearch,
    [useRoleType.ASSEMBLY]: Routing.assemblySearch,
    [useRoleType.BACKOFFICE]: Routing.boSearchMovements,
    [useRoleType.COLLECTION]: Routing.collectionSearch,
    [useRoleType.COMMON]: Routing.commonSearch,
    [useRoleType.MARKET]: Routing.marketSearch,
    [useRoleType.OTHER]: Routing.otherSearch,
    [useRoleType.PORT]: Routing.portSearch,
    [useRoleType.QUARANTINE]: Routing.quarantineSearch,
    [useRoleType.SHOW]: Routing.showSearch,
    [useRoleType.SLAUGHTERMARKET]: Routing.slaughterMarketSearch,
    [useRoleType.STOREMARKET]: Routing.storeMarketSearch,
    [useRoleType.VET]: Routing.vetSearch
  },
  userActivity: {
    [useRoleType.ABATTOIR]: Routing.userActivity,
    [useRoleType.AICENTRE]: Routing.userActivity,
    [useRoleType.ASSEMBLY]: Routing.userActivity,
    [useRoleType.COLLECTION]: Routing.userActivity,
    [useRoleType.COMMON]: Routing.userActivity,
    [useRoleType.GENERIC]: Routing.userActivity,
    [useRoleType.KEEPER]: Routing.userActivity,
    [useRoleType.MARKET]: Routing.userActivity,
    [useRoleType.OTHER]: Routing.userActivity,
    [useRoleType.PORT]: Routing.userActivity,
    [useRoleType.QUARANTINE]: Routing.userActivity,
    [useRoleType.SHOW]: Routing.userActivity,
    [useRoleType.SLAUGHTERMARKET]: Routing.userActivity,
    [useRoleType.STOREMARKET]: Routing.userActivity,
    [useRoleType.VET]: Routing.userActivity
  }
};

const searchMapping = {
  [useRoleType.ABATTOIR]: Routing.abattoirSearch,
  [useRoleType.AICENTRE]: Routing.aiCentreSearch,
  [useRoleType.ASSEMBLY]: Routing.assemblySearch,
  [useRoleType.COLLECTION]: Routing.collectionSearch,
  [useRoleType.COMMON]: Routing.commonSearch,
  [useRoleType.MARKET]: Routing.marketSearch,
  [useRoleType.OTHER]: Routing.otherSearch,
  [useRoleType.PORT]: Routing.portSearch,
  [useRoleType.QUARANTINE]: Routing.quarantineSearch,
  [useRoleType.SHOW]: Routing.showSearch,
  [useRoleType.SLAUGHTERMARKET]: Routing.slaughterMarketSearch,
  [useRoleType.STOREMARKET]: Routing.storeMarketSearch,
  [useRoleType.VET]: Routing.vetSearch
};

const lookupHome = (menuType) => {
  const storedPermissions = storeService.session.get.permissions();

  const matchedType = navMapping[menuType];
  return matchedType ? matchedType[storedPermissions] : null;
};

const lookupMenu = (menuType, id) => {
  const holding = storeService.session.get.holding();
  const holdingRole = holding ? holding.role : null;
  const storedPermissions = holdingRole ? holdingRole : storeService.session.get.permissions();

  const matchedType = navMapping[menuType];
  const routing = matchedType ? matchedType[storedPermissions] : null;
  const extraParam = id ? id : '';
  return routing ? routing + extraParam : '';
};

const navigation = {
  handshakeDetails: () => lookupMenu('handshakeDetails'),

  holdings: () => lookupMenu('holdings'),

  home: () => lookupHome('home'),

  movements: () => lookupMenu('movements'),

  abattoirAttestation: () => lookupMenu('abattoirAttestation'),

  movementConfirm: () => lookupMenu('movementConfirm'),

  movementDetails: (id) => lookupMenu('movementDetails', id),

  paperDetails: (id) => lookupMenu('paperDetails', id),

  search: () => lookupMenu('search'),

  userActivity: () => lookupMenu('userActivity'),

  getSearchURL: (roleToCheck) => {
    const search = navigation.search();
    if (search === undefined || !search) {
      return searchMapping[roleToCheck] ?? Routing.home;
    }
    return search;
  }
};

export default navigation;
