import React, { useEffect } from 'react';
import Routing from 'routing';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router-dom';
import { cloneDeep, isEqual, isEmpty } from 'lodash';
import axios from 'axios';
import bff, { bffResponse, bffStatus, isCancel } from 'services/bff';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import navigation from 'services/navigation';
import permissions from 'services/permissions';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import Confirmation from 'components/base/confirmation/confirmation';
import MovementStep1 from 'frontEnd/movements/step1/movementStep1';
import MovementStep2 from 'frontEnd/movements/step2/movementStep2';
import MovementStep3 from 'frontEnd/movements/step3/movementStep3';
import MovementStep4 from 'frontEnd/movements/step4/movementStep4';
import Summary from 'frontEnd/movements/summary/summary';

const Movements = ({
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const species = storeService.session.get.species();
  const role = storeService.session.get.permissions();
  const holding = storeService.session.get.holding();

  const sessionMovement = storeService.session.get.movement();
  const sessionMovementOriginal = storeService.session.get.originalMovement();
  const sessionOtherHolding = storeService.session.get.movementOtherHolding();
  const sessionDataChanged = storeService.session.get.dataChanged();

  const [movementData, setMovementData] = React.useState(sessionMovement ? { ...sessionMovement, otherHolding: sessionOtherHolding } : {});
  const [transportDetails, setTransportDetails] = React.useState(sessionMovement?.transportInformation ? sessionMovement.transportInformation : null);
  const [fciData, setFCIData] = React.useState(sessionMovement?.fci ? sessionMovement.fci : null);
  const [animalSelectionDetails, setAnimalSelectionDetails] = React.useState(sessionMovement?.selectedAnimalDetails ? sessionMovement.selectedAnimalDetails : null);
  const [finalAnimalDetails, setFinalAnimalDetails] = React.useState();
  const [movementType, setMovementType] = React.useState(null);
  const [dataChanged, setDataChanged] = React.useState(sessionDataChanged ? sessionDataChanged : false);
  const [exemptionsLoaded, setExemptionsLoaded] = React.useState(sessionMovement?.exemptions?.length > 0);
  const [isAmend, setIsAmend] = React.useState(sessionMovement?.amend ? sessionMovement.amend : false);
  const [inBusinessMovement, setInBusinessMovement] = React.useState(false);

  const [pending, setPending] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [fromSummary, setFromSummary] = React.useState(false);
  const [actions, setActions] = React.useState({});

  const STEP_01 = '1';
  const STEP_02 = '2';
  const STEP_03 = '3';
  const STEP_04 = '4';
  const STEP_05 = '5';

  const confirmation = {
    button: {
      recordAnother: () => ({
        id: 'recordAnother',
        label: 'button.recordAnotherMovement',
        onClick: () => {
          storeService.session.remove.dataChanged();
          setDataChanged(false);
          setInBusinessMovement(false);
          setMovementType(null);
          setMovementData({ exemptions: [] });
          setIsAmend(false);
          setFromSummary(false);
          setActions({});
          history.push(Routing.movementsStep1);
        }
      }),

      returnToHomePage: () => ({
        id: 'returnToHomePage',
        label: 'button.returnToHomePage',
        onClick: () => {
          storeService.session.remove.dataChanged();
          storeService.session.set.permissions(userRoleType.GENERIC);
          setPermission(userRoleType.GENERIC);
          history.push(Routing.home);
        }
      }),

      returnToUserActivity: () => ({
        id: 'returnToUserActivity',
        label: 'button.returnToUserActivity',
        onClick: () => {
          storeService.session.remove.dataChanged();
          storeService.session.set.permissions(userRoleType.GENERIC);
          setPermission(userRoleType.GENERIC);
          history.push(Routing.userActivity);
        }
      }),

      viewSummary: (requestId, movementRef, requestType) => ({
        id: 'viewSummary',
        label: 'button.viewSummary',
        onClick: () => {
          const isKeeper = permissions.isKeeper();
          const movementsUrl = navigation.movements();

          setPermission(userRoleType.GENERIC);
          storeService.session.set.permissions(userRoleType.GENERIC);
          storeService.session.remove.holding();

          setTimeout(() => {
            history.push({
              pathname: Routing.activityDetails + requestId,
              state: {
                data: {
                  requestId,
                  movementDocumentRef: movementRef,
                  requestType,
                  current: {
                    returnUrl: isKeeper ? Routing.keeperHolding : movementsUrl,
                    permissions: role,
                    holding
                  }
                }
              }
            });
          }, [100]);
        }
      })
    },

    link: {
      pdf: (speciesId, ref) => ({
        label: helpers.species.isSheepId(speciesId) || helpers.species.isGoatId(speciesId) ? 'button.viewMovementDocument' : null,
        param: ref,
        type: constants.linkTypes.pdfButton
      })
    }
  };

  const amendChangedData = () => {
    const tempMovementData = cloneDeep(storeService.session.get.movement());
    if (tempMovementData?.selectedAnimalDetails?.batchList?.length > 0) {
      tempMovementData.selectedAnimalDetails.batchList.forEach((batch) => delete batch.added);
      helpers.sortArrayOfObjects(tempMovementData.selectedAnimalDetails.batchList, 'batchNumber', constants.sorting.ascending);
    }
    if (tempMovementData?.selectedAnimalDetails?.animalsBeingMoved?.length > 0) {
      tempMovementData.selectedAnimalDetails.animalsBeingMoved.forEach((animal) => delete animal.added);
      helpers.sortArrayOfObjects(tempMovementData.selectedAnimalDetails.animalsBeingMoved, 'tagNumber', constants.sorting.ascending);
    }

    return !isEqual(sessionMovementOriginal, tempMovementData);
  };

  const pageTitle = () => {
    if (inBusinessMovement) {
      return 'movements.title-bus';
    }

    switch (movementType) {
      case constants.option.movement.on:
        return 'movements.title-on';
      case constants.option.movement.off:
        return 'movements.title-off';
      case constants.option.movement.permit:
        return 'movements.permitMove.title';
      default:
        return 'menu.movements';
    }
  };

  const submitMovement = () => {
    setSubmitted(true);
    setPending(true);

    const numberBeingMoved = finalAnimalDetails && finalAnimalDetails.numberOfAnimalsSelected;
    const storedfci = storeService.session.get.movementFci();

    const tempParams = {
      poll: config.POLLS_ENABLED,
      animalCount: numberBeingMoved,
      comment: movementData.comment !== '' ? movementData.comment : null,
      departureDate: helpers.date.formatForSubmission(movementData.departureDate),
      exemptions: movementData.exemptions ? movementData.exemptions.filter((o) => o.value) : [],
      fciData: fciData?.animalsSatisfy !== storedfci?.animalsSatisfy ? { animalsSatisfy: 'satisfy', recordFCI: 'yes' } : {
        ...fciData,
        unfitAnimals: finalAnimalDetails.animalsBeingMoved?.filter((item) => item.unfit).map((item) => item.tagNumber)
      },
      finalAnimalDetails,
      inBusinessMovement,
      movementType: inBusinessMovement ? constants.option.movement.on : movementData.movementType,
      species: species.name,
      transportInformation: transportDetails,
      userHolding: inBusinessMovement && helpers.option.movement.isOff(movementData.movementType) ? movementData.otherHolding.value : holding.value
    };

    if (movementData.arrivalDate) {
      tempParams.arrivalDate = helpers.date.formatForSubmission(movementData.arrivalDate);
    }

    if (helpers.option.movement.isOff(movementData.movementType)) {
      tempParams.sourceHolding = holding.value;
      tempParams.destinationHolding = movementData.otherHolding ? movementData.otherHolding.value : config.UNKNOWN;
    } else if (helpers.option.movement.isPermitMove(movementData.movementType)) {
      tempParams.userHolding = holding.value;
      tempParams.sourceHolding = holding.value;
      tempParams.destinationHolding = holding.value;
      tempParams.arrivalDate = helpers.date.formatForSubmission(movementData.arrivalDate);
    } else {
      tempParams.sourceHolding = movementData.otherHolding ? movementData.otherHolding.value : config.UNKNOWN;
      tempParams.destinationHolding = holding.value;
    }

    if (isAmend) {
      tempParams.amend = true;
      tempParams.originalMovementId = movementData.movementId;
    }

    bff
      .post('/movement', tempParams)
      .then((res) => {
        storeService.session.remove.dataChanged();

        const { data } = res;
        storeService.session.set.confirmRequestId(bffResponse.requestId(data));

        storeService.session.set.confirmPollingErrors(bffResponse.errors(data));
        storeService.session.set.confirmPollingWarnings(bffResponse.warnings(data));
        storeService.session.set.confirmMovementReference(bffResponse.movementRef(data));
        storeService.session.set.confirmPollingStatus(bffResponse.status(data));

        history.push(Routing.movementsConfirm);

        setSubmitted(false);
        setPending(false);
        setDataChanged(false);
        setMovementType(null);
        setInBusinessMovement(false);
        setMovementData({ exemptions: [] });
        helpers.scrollToTop();
      })
      .catch((error) => {
        errors.BFF(error, setModal);
        setPending(false);
      });
  };

  const dataHasChanged = () => {
    setDataChanged(true);
    storeService.session.set.dataChanged(true);
  };

  const stepPicker = (pathname) => {
    const routingsNeedMovementData = [Routing.movementsStep2, Routing.movementsStep3, Routing.movementsStep4, Routing.movementsSummary];

    if (routingsNeedMovementData.includes(pathname) && isEmpty(movementData)) {
      return <Redirect to={Routing.movementsStep1} />;
    }

    switch (pathname) {
      case Routing.movementsStep1:
      default: {
        storeService.session.removeAll.confirm();

        return (
          <>
            <p className="subtitle">{t('movements.subtitle')}</p>
            <span className="caption">
              {t('movements.step-heading')}
              <span className="step">{STEP_01}</span>
              {t('movements.step-heading-pages')}
            </span>
            <MovementStep1
              actions={actions}
              currentHolding={holding}
              fromSummary={fromSummary}
              inBusinessMovement={inBusinessMovement}
              isAmend={isAmend}
              movementData={movementData}
              movementType={movementType}
              setDataChanged={dataHasChanged}
              setInBusinessMovement={setInBusinessMovement}
              setIsAmend={setIsAmend}
              setModal={setModal}
              setMovementData={setMovementData}
              setMovementType={setMovementType}
              species={species}
            />
          </>
        );
      }

      case Routing.movementsStep2: {
        return (
          <>
            <p className="subtitle">{t('movements.subtitle')}</p>
            <span className="caption">
              {t('movements.step-heading')}
              {STEP_02}
              {t('movements.step-heading-pages')}
            </span>
            <MovementStep2
              fromSummary={fromSummary}
              isAmend={isAmend}
              movementData={movementData}
              setAnimalDetails={setAnimalSelectionDetails}
              setDataChanged={dataHasChanged}
              setModal={setModal}
              species={species}
            />
          </>
        );
      }

      case Routing.movementsStep3: {
        return (
          <>
            <p className="subtitle">{t('movements.subtitle')}</p>
            <span className="caption">
              {t('movements.step-heading')}
              {STEP_03}
              {t('movements.step-heading-pages')}
            </span>
            <MovementStep3
              animalDetails={finalAnimalDetails}
              fromSummary={fromSummary}
              movementData={movementData}
              movementType={movementType}
              numberBeingMoved={
                finalAnimalDetails && finalAnimalDetails.numberOfAnimalsSelected
              }
              setDataChanged={dataHasChanged}
              setModal={setModal}
              setTransportDetails={setTransportDetails}
              species={species}
            />
          </>
        );
      }

      case Routing.movementsStep4: {
        return (
          <>
            <p className="subtitle">{t('movements.subtitle')}</p>
            <span className="caption">
              {t('movements.step-heading')}
              {STEP_04}
              {t('movements.step-heading-pages')}
            </span>
            <MovementStep4
              animalsToChange={finalAnimalDetails}
              movementData={movementData}
              numberBeingMoved={finalAnimalDetails?.numberOfAnimalsSelected}
              setAnimalsToChange={setFinalAnimalDetails}
              setDataChanged={dataHasChanged}
              setFCIData={setFCIData}
              setModal={setModal}
              species={species}
            />
          </>
        );
      }

      case Routing.movementsSummary: {
        return (
          <>
            {(!submitted || (submitted && !pending)) &&
              <>
                {submitted && !pending && // Submission error
                  <Confirmation
                    label="label.submissionError"
                    setModal={setModal}
                    status={constants.status.error}
                  />
                }
                <p className="subtitle">{t('movements.subtitle')}</p>
                <span className="caption">
                  {t('movements.step-heading')}
                  {STEP_05}
                  {t('movements.step-heading-pages')}
                </span>
                <Summary
                  amend={isAmend}
                  amendChangedData={amendChangedData()}
                  currentHolding={holding}
                  fciData={fciData}
                  finalAnimalDetails={finalAnimalDetails}
                  location={location}
                  movementData={movementData}
                  pending={pending}
                  setActions={setActions}
                  setFinalAnimalDetails={setFinalAnimalDetails}
                  setFromSummary={setFromSummary}
                  setModal={setModal}
                  species={species}
                  submitMovement={submitMovement}
                  transportInformation={transportDetails}
                />
              </>
            }
            {submitted && pending &&
              <Confirmation
                label="label.submissionPending"
                setModal={setModal}
                status={constants.status.pending}
              />
            }
          </>
        );
      }

      case Routing.movementsConfirm: {
        const requestId = storeService.session.get.confirmRequestId();
        const movementRef = storeService.session.get.confirmMovementReference();
        const pollingStatus = storeService.session.get.confirmPollingStatus();
        const pollingErrors = storeService.session.get.confirmPollingErrors();
        const pollingWarnings = storeService.session.get.confirmPollingWarnings();
        const withErrorsOrWarnings = pollingErrors?.length > 0 || pollingWarnings?.length > 0;
        const amendWithErrors = isAmend && pollingErrors?.length > 0;
        const requestType = sessionMovementOriginal?.requestType ? sessionMovementOriginal.requestType : constants.option.requestType.movement;

        return (
          <>
            {!requestId && // Not yet submitted
              <Redirect to={Routing.movementsStep1} />
            }

            {requestId && !movementRef && // Submission successful but polling timed out
              <Confirmation
                buttons={[
                  withErrorsOrWarnings ? confirmation.button.returnToUserActivity() : null,
                  withErrorsOrWarnings ? confirmation.button.returnToHomePage() : null,
                  withErrorsOrWarnings && !amendWithErrors ? confirmation.button.viewSummary(requestId, movementRef, requestType) : null,
                  !withErrorsOrWarnings ? confirmation.button.recordAnother() : null
                ].filter((item) => item)}
                confirm={{ id: requestId, label: 'label.requestId', onClick: confirmation.button.returnToUserActivity().onClick }}
                errors={pollingErrors}
                label={bffStatus.isClaPending(pollingStatus) ? 'label.submissionPending' : (pollingErrors?.length > 0 ? 'label.submissionError' : (isAmend ? 'label.movementAmendmentSubmitted' : 'label.movementSubmitted'))}
                setModal={setModal}
                status={pollingStatus}
                warnings={pollingWarnings}
              />
            }

            {movementRef && // Submission and polling successful
              <Confirmation
                buttons={[
                  confirmation.button.recordAnother(),
                  confirmation.button.viewSummary(requestId, movementRef, isAmend ? constants.option.requestType.correctTransfer : constants.option.requestType.movement)
                ]}
                confirm={{ id: movementRef, label: 'label.movementRef', onClick: confirmation.button.returnToUserActivity().onClick }}
                errors={pollingErrors}
                label={pollingErrors?.length > 0 ? 'label.submissionError' : (isAmend ? 'label.movementAmendmentSubmitted' : 'label.movementSubmitted')}
                links={[
                  confirmation.link.pdf(species.id, { movementRef, requestId })
                ]}
                setModal={setModal}
                status={pollingStatus}
                warnings={pollingWarnings}
              />
            }
          </>
        );
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const sessionMovementType = storeService.session.get.movementType();
    const sessionMovementInBusiness = storeService.session.get.movementInBusiness();

    if (sessionMovementType) {
      setMovementType(sessionMovementType);
    }

    if (sessionMovementInBusiness) {
      setInBusinessMovement(sessionMovementInBusiness);
    }

    if (storeService.session.get.movementExemptions() === null) {
      setExemptionsLoaded(false);
    }

    if (!exemptionsLoaded) {
      helpers.get.processingFlags(source.token, constants.option.requestType.movement, species.id)
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal)) {
            const sessionMovementExemptions = storeService.session.get.movementExemptions();

            if (sessionMovementExemptions) {
              const newExemptions = res.data.map((item) => {
                const foundExemption = sessionMovementExemptions.find((ex) => ex.id === item.id);
                const isPermitMoveExemption = Object.values(constants.permitMovesMandatory).some((mandatoryExemption) => mandatoryExemption.id === item.id);

                return {
                  ...item,
                  value: foundExemption ? foundExemption.value : false,
                  disabled: isPermitMoveExemption || false
                };
              });

              storeService.session.set.movementExemptions(newExemptions);
              if (isAmend) {
                storeService.session.set.originalMovementExemptions(newExemptions);
              }
              setMovementData((prevState) => ({ ...prevState, exemptions: newExemptions }));
            } else {
              storeService.session.set.movementExemptions(res.data);
              if (isAmend) {
                storeService.session.set.originalMovementExemptions(res.data);
              }
              setMovementData((prevState) => ({ ...prevState, exemptions: res.data }));
            }
          }

          setExemptionsLoaded(true);
        })
        .catch((error) => {
          if (!isCancel(error)) {
            errors.BFF(error, setModal);
          }
        });
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movementData]);

  useEffect(() => {
    let numberOfAnimalsSelected = 0;

    if (animalSelectionDetails) {
      if (animalSelectionDetails.batchList) {
        animalSelectionDetails.batchList.forEach((batch) => {
          numberOfAnimalsSelected += parseInt(batch.animalTotal);
        });
      }

      if (animalSelectionDetails.animalsBeingMoved) {
        animalSelectionDetails.animalsBeingMoved.forEach(() => {
          numberOfAnimalsSelected++;
        });
      }

      setFinalAnimalDetails({
        ...animalSelectionDetails,
        numberOfAnimalsSelected
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animalSelectionDetails]);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.movements.length) !== Routing.movements || (isAmend && path.pathname === Routing.movements)) {
        storeService.session.remove.activeTabId();
        storeService.session.remove.dataChanged();
        storeService.session.removeAll.confirm();
        storeService.session.removeAll.movement();
        storeService.session.removeAll.original();
        storeService.session.remove.searchResultsMovements();
        storeService.session.remove.tableFiltersMovements();
        unListen();
      }
    });

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(navigation.movements());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      {ready &&
        <>
          <Prompt
            message={(params) => {
              const message = isAmend ? 'warning.wantToExitAmendment' : 'warning.unsavedChanges2';
              return (
                !storeService.session.get.idle() &&
                (
                  (!isAmend && dataChanged) ||
                  (isAmend && amendChangedData() && (
                    location.pathname !== Routing.movementsConfirm ||
                    location.pathname === Routing.movements
                  ))
                ) &&
                (
                  params.pathname.substring(0, Routing.movements.length) !== Routing.movements || params.pathname === Routing.movements
                )
              )
                ? t(message)
                : true;
            }}
          />
          <h1 className="h1">
            {t(pageTitle())}
            {isAmend &&
              <> ({t('label.amendment')})</>
            }
          </h1>
          {stepPicker(location.pathname)}
        </>
      }
    </>
  );
};

Movements.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func.isRequired
};

export default Movements;
