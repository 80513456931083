import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownloadIcon } from 'images/svg/Download_icon.svg';
import bff, { isCancel } from 'services/bff';
import classes from 'components/table/filters/filtersHoldingRegister/filtersHoldingRegister.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Button from 'components/base/button/button';
import CheckboxGroup from 'components/base/checkboxGroup/checkboxGroup';
import DateRange from 'components/base/dateRange/dateRange';

const HoldingRegisterFilters = ({
  columnParams,
  eventType,
  loadPending,
  setData,
  setEventType,
  setModal,
  setTableParams,
  storeFilters,
  tableParams
}) => {
  const { ready, t } = useTranslation();

  const now = helpers.date.now();
  const today = helpers.date.formatYYYYMMDD(now);
  const sixMonthsAgo = helpers.date.formatYYYYMMDD(helpers.date.subtractPeriod(now, 6, constants.period.months));

  const sessionHolding = storeService.session.get.holding();
  const sessionSpecies = storeService.session.get.species();
  const speciesId = sessionSpecies ? sessionSpecies.id : '';

  const store = helpers.holdingRegister.getReference();

  const [filterFromDate, setFilterFromDate] = React.useState(tableParams?.filters?.fromDate ? tableParams.filters.fromDate : sixMonthsAgo);
  const [filterToDate, setFilterToDate] = React.useState(tableParams?.filters?.toDate ? tableParams.filters.toDate : today);
  const [fromDateError, setFromDateError] = React.useState('');
  const [toDateError, setToDateError] = React.useState('');
  const [downloadPending, setDownloadPending] = React.useState(false);
  const [eventTypes, setEventTypes] = React.useState([
    { id: constants.holdingRegister.identifier.all, label: t('label.allEntries'), value: false },
    helpers.species.isDeerId(store.speciesId) ? null : { id: constants.holdingRegister.type.tagReplacements, label: t('label.tagReplacements'), value: false },
    helpers.species.isDeerId(store.speciesId) ? null : { id: constants.holdingRegister.type.tagApplications, label: t('label.tagApplications'), value: false },
    { id: constants.holdingRegister.type.movementsOff, label: t('label.offMovements'), value: false },
    { id: constants.holdingRegister.type.movementsOn, label: t('label.onMovements'), value: false },
    { id: constants.holdingRegister.type.adjacentMovements, label: t('label.adjacentMovements'), value: false },
    helpers.species.isDeerId(store.speciesId) ? null : { id: constants.holdingRegister.type.deaths, label: t('label.deaths'), value: false },
    helpers.species.isDeerId(store.speciesId) ? null : { id: constants.holdingRegister.type.annualInventory, label: t('label.annualInventory'), value: false }
  ].filter((item) => item));

  const handleChanges = {
    date: (which, value) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      if (helpers.flags.isFrom(which)) {
        setFilterFromDate(value);

        if (value?.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { fromDate, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { fromDate, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              fromDate: value
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            fromDate: value
          });
        }
      } else if (helpers.flags.isTo(which)) {
        setFilterToDate(value);

        if (value?.length === 0) {
          setTableParams((prevState) => {
            // eslint-disable-next-line no-unused-vars
            const { toDate, ...prevStateFilters } = prevState.filters;

            return {
              ...prevState,
              filters: prevStateFilters
            };
          });

          // eslint-disable-next-line no-unused-vars
          const { toDate, ...prevStoredFilters } = storedFilters;

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...prevStoredFilters
          });
        } else {
          setTableParams((prevState) => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              toDate: value
            }
          }));

          storeService.session.set[`tableFilters${storeFilters}`]({
            ...storedFilters,
            toDate: value
          });
        }
      }
    },

    selectedReports: (checkboxId) => {
      let selectedEventTypes;

      if (checkboxId === 'all') {
        selectedEventTypes = eventTypes.map((item) => ({
          ...item,
          value: (checkboxId === String(item.id)) ? !item.value : false
        }));
      } else {
        selectedEventTypes = eventTypes.map((item) => ({
          ...item,
          value: (checkboxId === String(item.id)) ? !item.value : (item.id === 'all' ? false : item.value)
        }));
      }

      setEventTypes(selectedEventTypes);
    }
  };

  const downloadReport = () => {
    setDownloadPending(true);

    if (helpers.holdingRegister.types.isDownload(eventType.current)) {
      let selectedEventTypes;

      if (eventTypes[0].value) { // "All" selected
        selectedEventTypes = eventTypes.filter((item) => item.id !== constants.holdingRegister.identifier.all).map((item) => item.id);
      } else {
        selectedEventTypes = eventTypes.filter((item) => item.value).map((item) => item.id);
      }

      helpers.holdingRegister.download(setModal, {
        cph: store.cph,
        speciesId: store.speciesId,
        fromDate: filterFromDate,
        toDate: filterToDate,
        eventTypes: selectedEventTypes,
        setPending: setDownloadPending
      });
    } else {
      bff
        .get('/holdingRegisterDownload', {
          params: {
            eventTypes: [eventType.current.toLowerCase()],
            cph: sessionHolding.value,
            speciesId,
            fromDate: moment(filterFromDate).format('YYYY-MM-DD'),
            toDate: helpers.date.addPeriod(filterToDate, 1, constants.period.days).format('YYYY-MM-DD')
          }
        })
        .then((res) => {
          const filename = `holdingRegister-${eventType.current}`;

          helpers.openFileFromResponse(res.data, filename, 'ods');

          setDownloadPending(false);
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setDownloadPending(false);
            errors.BFF(error, setModal);
          }
        });
    }
  };

  const runRequest = () => {
    if (!filterFromDate) {
      setFilterFromDate(sixMonthsAgo);
    }
    if (!filterToDate) {
      setFilterToDate(today);
    }

    storeService.session.set[`tableFilters${storeFilters}PageIndex`](0);
    storeService.session.remove[`tableFilters${storeFilters}SortBy`]();
    storeService.session.removeAll.searchResults();
    setData([]);

    setTableParams((prevState) => ({
      ...prevState,
      columns: typeof tableColumns.holdingRegister[eventType.current] === 'function'
        ? tableColumns.holdingRegister[eventType.current]({ ...columnParams })
        : tableColumns.holdingRegister[eventType.current],
      filters: {
        fromDate: filterFromDate,
        toDate: filterToDate
      },
      page: {
        ...prevState.page,
        index: 0
      },
      request: {
        ...prevState.request,
        params: {
          ...prevState.request.params,
          fromDate: filterFromDate,
          toDate: filterToDate
        }
      },
      sort: {
        by: initialSorting.holdingRegister[eventType.current].accessor,
        direction: initialSorting.holdingRegister[eventType.current].direction
      }
    }));
  };

  useEffect(() => {
    setTableParams((prevState) => {
      const storedFilters = storeService.session.get[`tableFilters${storeFilters}`]();

      if (eventType.previous !== '' || !storedFilters) {
        storeService.session.set[`tableFilters${storeFilters}PageIndex`](0);
        storeService.session.set[`tableFilters${storeFilters}PageSize`](prevState.page.size);
        storeService.session.set[`tableFilters${storeFilters}SortBy`](initialSorting.holdingRegister[eventType.current]?.accessor);
        storeService.session.set[`tableFilters${storeFilters}SortDirection`](initialSorting.holdingRegister[eventType.current]?.direction);
      }

      if (eventType.refresh) {
        // eslint-disable-next-line no-unused-vars
        setEventType(({ refresh, ...prevState }) => prevState);
      } else {
        return {
          ...prevState,
          columns: typeof tableColumns.holdingRegister[eventType.current] === 'function'
            ? tableColumns.holdingRegister[eventType.current]({ ...columnParams })
            : tableColumns.holdingRegister[eventType.current],
          page: {
            index: eventType.previous !== '' ? 0 : prevState.page.index,
            size: eventType.previous !== '' ? config.DEFAULT_TABLE_PAGE_SIZE : prevState.page.size
          },
          request: {
            url: constants.holdingRegister.endpoint[eventType.current],
            params: {
              ...prevState.request.params,
              eventType: eventType.current,
              fromDate: filterFromDate,
              toDate: filterToDate
            }
          },
          sort: {
            by: eventType.previous !== '' ? initialSorting.holdingRegister[eventType.current]?.accessor : prevState.sort.by,
            direction: eventType.previous !== '' ? initialSorting.holdingRegister[eventType.current]?.direction : prevState.sort.direction
          }
        };
      }

      return prevState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType]);

  return (
    <>
      {ready &&
        <div className={classes.filters}>
          {helpers.holdingRegister.types.isDownload(eventType.current) &&
            <AboutPanel
              descriptions={[
                { text: 'about.holdingRegisterDownload.desc1' },
                { text: 'about.holdingRegisterDownload.desc2' }
              ]}
              title="about.genericTitle.page"
            />
          }
          {!helpers.holdingRegister.types.isAnnualInventory(eventType.current) &&
            <>
              <p>{t('label.narrowDown')}</p>
              <DateRange
                fromDate={filterFromDate}
                fromDateError={fromDateError}
                loadPending={loadPending}
                setFromDate={(date) => handleChanges.date(constants.flags.from, date)}
                setFromDateError={setFromDateError}
                setToDate={(date) => handleChanges.date(constants.flags.to, date)}
                setToDateError={setToDateError}
                submit={!helpers.holdingRegister.types.isDownload(eventType.current) ? runRequest : undefined}
                toDate={filterToDate}
                toDateError={toDateError}
              />
            </>
          }

          {helpers.holdingRegister.types.isDownload(eventType.current) &&
            <>
              <p>{t('label.selectHoldingRegisterSections')}</p>
              <CheckboxGroup
                objects={eventTypes}
                onChange={(event) => handleChanges.selectedReports(event.target.id)}
              />
            </>
          }

          {!loadPending &&
            <div className={classes.download}>
              <Button
                buttonType="downloadReport"
                disabled={fromDateError || toDateError || downloadPending || (helpers.holdingRegister.types.isDownload(eventType.current) && !eventTypes.some((item) => item.value))}
                icon={<DownloadIcon className="downloadIcon" />}
                label="button.download"
                onClick={downloadReport}
              />
            </div>
          }
        </div>
      }
    </>
  );
};

HoldingRegisterFilters.propTypes = {
  columnParams: PropTypes.object.isRequired,
  eventType: PropTypes.object.isRequired,
  loadPending: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  setEventType: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setTableParams: PropTypes.func.isRequired,
  storeFilters: PropTypes.string.isRequired,
  tableParams: PropTypes.object.isRequired
};

export default HoldingRegisterFilters;
