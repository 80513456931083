import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import constants from 'services/constants';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { initialSorting } from 'services/tableColumns';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Table from 'components/table/table';

const UserActivity = ({
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const sessionUserActivity = storeService.session.get.searchResultsUserActivity();

  const [data, setData] = React.useState(sessionUserActivity ? sessionUserActivity.data : []);
  const [requestTypes, setRequestTypes] = React.useState([]);
  const [renderTable, setRenderTable] = React.useState(false);

  const tableParams = {
    request: {
      url: '/userActivity'
    }
  };

  useEffect(() => {
    const sessionRequestTypes = storeService.session.get.requestTypes();
    if (sessionRequestTypes) {
      setRequestTypes(sessionRequestTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem('requestTypes'), setRequestTypes]);

  useEffect(() => {
    const unListen = history.listen((path) => {
      if (path.pathname.substring(0, Routing.holdings.length) !== Routing.holdings &&
        path.pathname.substring(0, Routing.userActivity.length) !== Routing.userActivity &&
        path.pathname.substring(0, Routing.movementsSummary.length) !== Routing.movementsSummary &&
        path.pathname.substring(0, Routing.activityDetails.length) !== Routing.activityDetails &&
        path.pathname.substring(0, Routing.abattoirMovements.length) !== Routing.abattoirMovements &&
        path.pathname.substring(0, Routing.assemblyMovements.length) !== Routing.assemblyMovements &&
        path.pathname.substring(0, Routing.collectionMovements.length) !== Routing.collectionMovements &&
        path.pathname.substring(0, Routing.marketMovements.length) !== Routing.marketMovements &&
        path.pathname.substring(0, Routing.showMovements.length) !== Routing.showMovements
      ) {
        storeService.session.removeAll.tableFilters();
        storeService.session.removeAll.searchResults();
        unListen();
      }
    });
  }, [history]);

  return (
    <>
      {ready &&
        <>
          <h3 className="h3">{t('label.userActivity')}</h3>
          <AboutPanel
            descriptions={[
              { text: 'about.userActivity.desc' },
              { text: 'about.userActivity.statuses', href: constants.url.defraSupport }
            ]}
            title="about.genericTitle.page"
          />

          {requestTypes.length > 0 &&
            <Table
              data={data}
              fetchingDataLabel="label.fetchingUserActivity"
              filtersComponents={{
                [constants.filters.outside]: 'UserActivity'
              }}
              initialSortBy={initialSorting.userActivity[constants.option.requestType.movement].accessor}
              initialSortDirection={initialSorting.userActivity[constants.option.requestType.movement].direction}
              noDataLabel="label.noUserActivity"
              params={tableParams}
              renderTable={renderTable || data.length > 0}
              rowLinks={{
                url: () => Routing.activityDetails,
                accessor: 'requestId',
                linkIf: (row) =>
                  row.status.toLowerCase() !== constants.status.processing.toLowerCase() &&
                  row.status.toLowerCase() !== constants.status.pending.toLowerCase() &&
                  row.status.toLowerCase() !== constants.status.claPending.toLowerCase()
              }}
              setData={setData}
              setModal={setModal}
              setRenderTable={setRenderTable}
              store={{
                filters: 'UserActivity',
                data: 'UserActivity'
              }}
            />
          }
        </>
      }
    </>
  );
};

UserActivity.propTypes = {
  setModal: PropTypes.func.isRequired
};

export default UserActivity;
