import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'frontEnd/vetModule/vetModule.module.scss';
import VetHoldingInfo from 'frontEnd/vetModule/components/vetHoldingInfo';

const VetTitle = ({ setModal }) => {
  const { ready, t } = useTranslation();

  return (
    <>
      {ready &&
      <>
        <section className={classes.titleSection + ' ' + classes.bottomBorder}>
          <h1 className={classes.pageTitle}>{t('menu.vetHome')}</h1>
          <VetHoldingInfo setModal={setModal} />
        </section>
      </>
      }
    </>
  );
};

export default VetTitle;
