import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import bff, { isCancel } from 'services/bff';
import breedsHelper from 'services/breedsHelper';
import classes from 'frontEnd/movements/step2/movementStep2.module.scss';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import AboutPanel from 'components/base/aboutPanel/aboutPanel';
import Button from 'components/base/button/button';
import Modal from 'components/base/modal/modal';
import ModalAddBatch from 'components/modalAddBatch/modalAddBatch';
import ModalAnimalsOnHolding from 'components/modalAnimalsOnHolding/modalAnimalsOnHolding';
import ModalConfirm from 'components/base/modalConfirm/modalConfirm';
import ModalFileUpload from 'components/modalFileUpload/modalFileUpload';
import ModalTagNumberRange from 'components/modalTagNumberRange/modalTagNumberRange';
import ModalManualTagNumbers from 'components/modalManualTagNumbers/modalManualTagNumbers';
// import RecordAnimalDetails from 'frontEnd/movements/step2/recordAnimalDetails/recordAnimalDetails';
import RecordAnimalTagAllocation from 'frontEnd/movements/step2/recordAnimalTagAllocation/recordAnimalTagAllocation';
import Table from 'components/table/table';
import { cloneDeep, isEqual } from 'lodash';
import axios from 'axios';
const sprintf = require('util').format;

const MovementStep2 = ({
  fromSummary,
  isAmend,
  movementData,
  setAnimalDetails,
  setDataChanged,
  setModal,
  species
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const cph = storeService.session.get.holding().value;

  const [addByBatchOrIndividual, setAddByBatchOrIndividual] = React.useState(null);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [selectionMethod, setSelectionMethod] = React.useState(null);
  const [batchList, setBatchList] = React.useState([]);
  const [animalsToChange, setAnimalsToChange] = React.useState([]);

  const [modalAnimals, setModalAnimals] = React.useState([]);
  const [pending, setPending] = React.useState(false);
  const [breedId, setBreedId] = React.useState();
  // const [recordAnimalDetails, setRecordAnimalDetails] = React.useState(false);
  const [recordAnimalTagAllocationDetails, setRecordAnimalTagAllocationDetails] = React.useState(false);
  const [yearOfBirth, setYearOfBirth] = React.useState();
  // const [gender, setGender] = React.useState(null);
  const [applicationDate, setApplicationDate] = React.useState();
  const [dateError, setDateError] = React.useState(false);
  const [modalHasChanges, setModalHasChanges] = React.useState(false);
  const [confirmCloseModal, setConfirmCloseModal] = React.useState(false);
  const [modalAnimalsInitial, setModalAnimalsInitial] = React.useState([]);
  const [paginationParams, setPaginationParams] = React.useState({
    batch: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        totalLabel: 'label.totalBatches'
      }
    },
    individual: constants.pagination.defaultParams
  });

  const iconBatch = 'bi bi-tags';
  const iconIndividual = 'bi bi-tag';
  const iconHolding = 'bi bi-house-door';
  const iconFile = 'bi bi-file-earmark';
  const iconTagRange = 'bi bi-arrows-expand';
  const iconManualEntry = 'bi bi-list-check';

  const modal = {
    addAll: (tableParams, setLoadPending, cancelTokenSource, setCancelTokenSource) => {
      setLoadPending(true);

      bff
        .get(tableParams.request.url, {
          cancelToken: cancelTokenSource.token,
          params: {
            ...tableParams.request.params,
            ...tableParams.filters,
            pageSize: 'all'
          }
        })
        .then((res) => {
          setCancelTokenSource(axios.CancelToken.source());
          setModalAnimals((prevState) => [
            ...prevState,
            ...res.data.data
              .filter((animal1) => !prevState.some((animal2) => animal2.tagNumber === animal1.tagNumber))
              .map((animal) => ({
                ...animal,
                added: true
              }))
          ]);
          setModalHasChanges(true);
          setLoadPending(false);
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setCancelTokenSource(axios.CancelToken.source());
            errors.BFF(error, setModal);
            setLoadPending(false);
          }
        });
    },

    clearAll: () => {
      setModalAnimals([]);
      setModalHasChanges(false);
    },

    closeAndReset: () => {
      const modalAnimalsCopy = modalAnimals.map((item) => ({
        ...item,
        added: false
      }));
      setModalAnimals(modalAnimalsCopy);

      setModalVisible(false);
      setModalHasChanges(false);
      setSelectionMethod(null);
      setAddByBatchOrIndividual(null);
      storeService.session.removeAll.searchResults();
      storeService.session.removeAll.tableFilters();
    },

    getContent: () => {
      switch (selectionMethod) {
        case constants.option.selectionMethod.batch:
          return (
            <ModalAddBatch
              batchList={modalAnimals}
              clearAll={modal.clearAll}
              modalAnimals={modalAnimals}
              pending={pending}
              removeAllInvalid={modal.removeAllInvalid}
              removeBatch={modal.removeBatch}
              setModal={setModal}
              setModalAnimals={setModalAnimals}
              setPending={setPending}
              species={species}
              updateBatchList={handleChanges.batchList}
            />
          );

        case constants.option.selectionMethod.holding:
          return (
            <ModalAnimalsOnHolding
              addAll={modal.addAll}
              animalsToChange={animalsToChange}
              clearAll={modal.clearAll}
              departureDate={movementData.departureDate}
              modalAnimals={modalAnimals}
              setModal={setModal}
              setModalAnimals={setModalAnimals}
              setModalAnimalsInitial={setModalAnimalsInitial}
              toggleAllAnimals={modal.toggleAllAnimalsOnHolding}
              toggleAnimal={modal.toggleAnimalOnHolding}
            />
          );

        case constants.option.selectionMethod.uploadFile:
          return (
            <ModalFileUpload
              clearAll={modal.clearAll}
              modalAnimals={modalAnimals.filter((item1) => !animalsToChange.some((item2) => item1.tagNumber === item2.tagNumber))}
              pending={pending}
              removeAllInvalid={modal.removeAllInvalid}
              removeAnimal={modal.removeAnimal}
              setModal={setModal}
              setModalAnimals={setModalAnimals}
              setPending={setPending}
              species={species}
              upload={handleChanges.upload}
            />
          );

        case constants.option.selectionMethod.tagNumberRange:
          return (
            <ModalTagNumberRange
              addRange={handleChanges.addRange}
              clearAll={modal.clearAll}
              modalAnimals={modalAnimals.filter((item1) => !animalsToChange.some((item2) => item1.tagNumber === item2.tagNumber))}
              removeAllInvalid={modal.removeAllInvalid}
              removeAnimal={modal.removeAnimal}
              setModal={setModal}
              setModalAnimals={setModalAnimals}
              species={species}
            />
          );

        case constants.option.selectionMethod.manualTagNumbers:
          return (
            <ModalManualTagNumbers
              addAnimals={handleChanges.addAnimals}
              animalsToChange={animalsToChange}
              clearAll={modal.clearAll}
              modalAnimals={modalAnimals}
              pending={pending}
              removeAllInvalid={modal.removeAllInvalid}
              removeAnimal={modal.removeAnimal}
              setModal={setModal}
              setModalAnimals={setModalAnimals}
              setPending={setPending}
              species={species}
            />
          );

        default:
          return null;
      }
    },

    getFooterContent: () => {
      const summaryTotal = modalAnimals.filter((item) => item.added).length;

      let batchesString;
      let animalsString;
      let batchesTotal;
      let summaryText;

      switch (selectionMethod) {
        case constants.option.selectionMethod.batch:
          batchesString = summaryTotal === 1 ? t('label.batchSelected') : t('label.batchesSelected');

          batchesTotal = modalAnimals.filter((item) => item.added).reduce((sum, item) => sum + parseInt(item.animalTotal), 0);
          if (batchesTotal > 0) {
            animalsString = t(batchesTotal === 1 ? 'label.animal' : 'label.animals');
            batchesString += ' (' + batchesTotal + ' ' + animalsString + ')';
          }

          summaryText = batchesString;

          return (
            <div dangerouslySetInnerHTML={{
              __html: sprintf(
                summaryText,
                summaryTotal
              )
            }} />
          );

        case constants.option.selectionMethod.holding:
        case constants.option.selectionMethod.uploadFile:
        case constants.option.selectionMethod.manualTagNumbers:
        case constants.option.selectionMethod.tagNumberRange:
          // summaryText = modalAnimals.filter((item) => item.available && item.added).length === 1 ? t('label.animalSelected') : t('label.animalsSelected');
          summaryText = summaryTotal === 1 ? t('label.animalSelected') : t('label.animalsSelected');

          return (
            <div dangerouslySetInnerHTML={{
              __html: sprintf(
                summaryText,
                summaryTotal
              )
            }} />
          );

        default:
          return '';
      }
    },

    getHeaderContent: () => {
      switch (selectionMethod) {
        case constants.option.selectionMethod.batch:
          return (
            <>
              <i className={iconBatch} />
              {t('label.addBatch')}
            </>
          );

        case constants.option.selectionMethod.holding:
          return (
            <>
              <i className={iconHolding} />
              {sprintf(t('label.animalsOnHoldingCPH'), cph)}
            </>
          );

        case constants.option.selectionMethod.uploadFile:
          return (
            <>
              <i className={iconFile} />
              {t('label.uploadAFile')}
            </>
          );

        case constants.option.selectionMethod.tagNumberRange:
          return (
            <>
              <i className={iconTagRange} />
              {t('label.addATagNumberRange')}
            </>
          );

        case constants.option.selectionMethod.manualTagNumbers:
          return (
            <>
              <i className={iconManualEntry} />
              {t('label.enterTagNumbersManually')}
            </>
          );

        default:
          return null;
      }
    },

    getModalId: () => {
      switch (selectionMethod) {
        case constants.option.selectionMethod.batch:
          return 'modalAddAnimalsBatches';

        case constants.option.selectionMethod.holding:
          return 'modalAddAnimalsOnHolding';

        case constants.option.selectionMethod.uploadFile:
          return 'modalAddAnimalsUploadFile';

        case constants.option.selectionMethod.tagNumberRange:
          return 'modalAddAnimalsTagNumberRange';

        case constants.option.selectionMethod.manualTagNumbers:
          return 'modalAddAnimalsManualTagNumbers';

        default:
          return null;
      }
    },

    hide: (confirmClose) => {
      if (modalHasChanges && !confirmClose) {
        setConfirmCloseModal(true);
      } else if (confirmClose) {
        setConfirmCloseModal(false);
        modal.closeAndReset();
      } else {
        modal.closeAndReset();
      }
    },

    removeAllInvalid: () => {
      setModalAnimals(helpers.animal.removeAllInvalid(modalAnimals));
    },

    removeAnimal: (animal) => {
      setModalAnimals((prevState) => {
        const newModalAnimals = helpers.tag.remove([...prevState], animal);

        if (newModalAnimals.length === 0) {
          setModalHasChanges(false);
        }

        return newModalAnimals;
      });

      setTimeout(() => {
        document.activeElement.blur();
      }, 1);
    },

    removeBatch: (batch) => {
      setModalAnimals((prevState) => {
        const newModalAnimals = helpers.batch.remove([...prevState], batch);

        if (newModalAnimals.length === 0) {
          setModalHasChanges(false);
        }

        return newModalAnimals;
      });

      setTimeout(() => {
        document.activeElement.blur();
      }, 1);
    },

    saveDisabled: () => {
      if (helpers.option.selectionMethod.isHolding(selectionMethod)) {
        return pending || !modalHasChanges || modalAnimals.some((item) => helpers.tag.isInvalid(item) && item.added);
      }

      return pending || !modalAnimals.some((item) => item.added) || modalAnimals.some((item) => helpers.tag.isInvalid(item) && item.added);
    },

    show: (which) => {
      if (!helpers.option.selectionMethod.isHolding(selectionMethod)) {
        setModalAnimals([]);
      }
      setSelectionMethod(which);
      setModalVisible(true);
    },

    // toggleAnimal: (animal, id) => {
    //   const modalAnimalsCopy = cloneDeep(modalAnimals);
    //
    //   const animalToToggle = modalAnimalsCopy.find((item) => item.tagNumber === animal.tagNumber);
    //
    //   if (animalToToggle && helpers.tag.isInvalid(animalToToggle)) {
    //     const indexToRemove = modalAnimalsCopy.findIndex((item) => item.tagNumber === animal.tagNumber);
    //     modalAnimalsCopy.splice(indexToRemove, 1);
    //   } else {
    //     animalToToggle.added = !animalToToggle.added;
    //   }
    //
    //   setModalAnimals(modalAnimalsCopy);
    //
    //   if (modalAnimalsCopy.some((item) => item.added)) {
    //     setModalHasChanges(true);
    //   } else {
    //     setModalHasChanges(false);
    //   }
    //
    //   setTimeout(() => {
    //     const elementToFocus = document.getElementById(id);
    //
    //     if (elementToFocus) {
    //       elementToFocus.focus();
    //     } else {
    //       document.activeElement.blur();
    //     }
    //   }, 1);
    // },

    toggleAnimalOnHolding: (animal, added) => {
      animal.added = added !== undefined ? added : !animal.added;

      if (animal.added) {
        if (!modalAnimals.find((item) => item.tagNumber === animal.tagNumber)) {
          setModalAnimals((prevState) => [
            ...prevState,
            animal
          ]);
        }
      } else {
        setModalAnimals((prevState) => prevState.filter((item) => item.tagNumber !== animal.tagNumber));
      }
    },

    toggleAllAnimalsOnHolding: (rows, added) => {
      rows.forEach((animal) => {
        modal.toggleAnimalOnHolding(animal, added);
      });
    }
  };

  const handleChanges = {
    addAnimals: (tags) => {
      const uniqueNewTags = tags.filter((tag) => !modalAnimals.find((tagItem) => helpers.tag.trim(tagItem.tagNumber) === helpers.tag.trim(tag.tagNumber)));
      const newModalAnimals = [
        ...modalAnimals,
        ...uniqueNewTags.map((item) => ({
          rfid: helpers.tag.convertToRfid(item.tagNumber),
          tagNumber: helpers.tag.trim(item.tagNumber),
          added: true,
          valid: item.valid
        }))
      ];
      setModalHasChanges(true);
      setModalAnimals(newModalAnimals);
    },

    addRange: (tags) => {
      let uniqueNewTags;
      uniqueNewTags = tags.filter((tag) => !animalsToChange.find((tagItem) => helpers.tag.trim(tagItem.tagNumber) === helpers.tag.trim(tag.tagNumber)));
      uniqueNewTags = uniqueNewTags.filter((tag) => !modalAnimals.find((tagItem) => helpers.tag.trim(tagItem.tagNumber) === helpers.tag.trim(tag.tagNumber)));
      const newModalAnimals = [...modalAnimals, ...uniqueNewTags].map((item) => ({
        ...item,
        tagNumber: helpers.tag.trim(item.tagNumber),
        added: true
      }));
      setModalHasChanges(true);
      setModalAnimals(newModalAnimals);
    },

    addAnimalsByBatchOrIndividual: (method) => {
      setAddByBatchOrIndividual(method);
    },

    batchList: (batchList) => {
      const batchListCopy = batchList.reduce((reducedBatchList, item) => {
        const foundItem = reducedBatchList.find((elem) => helpers.tag.trim(elem.batchNumber) === helpers.tag.trim(item.batchNumber));

        if (foundItem && foundItem.added) {
          foundItem.animalTotal += item.animalTotal;
        } else if (foundItem && !foundItem.added) {
          foundItem.animalTotal = item.animalTotal;
          foundItem.added = true;
        } else {
          const existingItem = modalAnimals.find((elem) => helpers.tag.trim(elem.batchNumber) === helpers.tag.trim(item.batchNumber));

          reducedBatchList.push({
            ...item,
            batchNumber: helpers.tag.trim(item.batchNumber),
            added: existingItem ? existingItem.added : true
          });
        }

        return reducedBatchList;
      }, []);

      setModalHasChanges(true);
      setModalAnimals(batchListCopy);
    },

    breedId: (breedId) => {
      setBreedId(breedId);

      storeService.session.set.movementBreed({
        id: breedId,
        name: breedsHelper.getBreedName(breedId)
      });
    },

    // gender: (gender) => {
    //   setGender(gender);
    //   storeService.session.set.movementGender(gender);
    // },

    // recordAnimalDetails: (recordAnimalDetails) => {
    //   setRecordAnimalDetails(recordAnimalDetails);
    //   storeService.session.set.movementRecordAnimalDetails(recordAnimalDetails);

    //   if (!recordAnimalDetails) {
    //     clearTaggingAndAnimalAllocationDetails();
    //   }
    // },

    recordTagAllocationDetails: (recordTagAllocationDetails) => {
      setRecordAnimalTagAllocationDetails(recordTagAllocationDetails);
      storeService.session.set.movementRecordTagAllocationDetails(recordTagAllocationDetails);

      if (!recordTagAllocationDetails) {
        clearTaggingAndAnimalAllocationDetails();
      }
    },

    removeAnimal: (animal) => {
      setAnimalsToChange((prevState) => {
        const newAnimalsToChange = helpers.tag.remove([...prevState], animal);

        if (newAnimalsToChange.length === 0) {
          setRecordAnimalTagAllocationDetails(null);
          // setRecordAnimalDetails(null);
          setApplicationDate(null);
          setYearOfBirth(null);
          setBreedId(null);
          // setGender(null);
          storeService.session.set.movementAnimalsBeingMoved([]);
          storeService.session.remove.movementRecordAnimalDetails();
          storeService.session.remove.movementRecordTagAllocationDetails();
          storeService.session.remove.movementTagApplicationDate();
          storeService.session.remove.movementYearOfBirth();
          storeService.session.remove.movementBreed();
          storeService.session.remove.movementGender();
        } else {
          storeService.session.set.movementAnimalsBeingMoved(newAnimalsToChange);
        }

        handleChanges.ResetfciData(newAnimalsToChange);
        return newAnimalsToChange;
      });

      setTimeout(() => {
        document.activeElement.blur();
      }, 1);
    },

    removeAnimalsAll: () => {
      setAnimalsToChange([]);
      storeService.session.set.movementAnimalsBeingMoved([]);
      storeService.session.remove.movementRecordTagAllocationDetails();
      storeService.session.remove.movementTagApplicationDate();
      storeService.session.remove.movementYearOfBirth();
      storeService.session.remove.movementBreed();
      storeService.session.remove.movementGender();

      setRecordAnimalTagAllocationDetails(null);
      handleChanges.ResetfciData();
      // setRecordAnimalDetails(null);
    },

    ResetfciData: (newAnimalsToChange) => {
      const movementFci = storeService.session.get.movementFci();

      newAnimalsToChange = newAnimalsToChange !== undefined ? newAnimalsToChange : storeService.session.get.movementAnimalsBeingMoved();

      if (newAnimalsToChange?.filter((item) => item.unfit).length === 0 && movementFci?.animalsSatisfy && movementFci?.animalsSatisfy !== 'satisfy') {
        storeService.session.set.movementFciAnimalsSatisfy('satisfy');

        if (helpers.option.animals.doSatisfy('satisfy')) {
          storeService.session.remove.movementFciNonComplianceReason();
          storeService.session.remove.movementFciHoldingRestrictions();
          storeService.session.remove.movementFciWithdrawalPeriod();
        }
      }
    },
    removeBatch: (batch) => {
      setBatchList((prevState) => {
        const newBatchList = helpers.batch.remove([...prevState], batch);

        if (newBatchList.length === 0) {
          storeService.session.remove.movementBatchList();
        } else {
          storeService.session.set.movementBatchList(newBatchList);
        }

        return newBatchList;
      });

      setTimeout(() => {
        document.activeElement.blur();
      }, 1);
    },

    removeBatchesAll: () => {
      setBatchList([]);
      storeService.session.set.movementBatchList([]);
    },

    saveChanges: () => {
      if (addByBatchOrIndividual === 'batch') {
        const selectedBatches = modalAnimals.filter((item) => item.added);
        const newBatchList = cloneDeep(batchList);

        selectedBatches.forEach((batch1) => {
          const existingBatch = newBatchList.find((batch2) => helpers.tag.trim(batch2.batchNumber) === helpers.tag.trim(batch1.batchNumber));
          if (existingBatch) {
            existingBatch.animalTotal += batch1.animalTotal;
          } else {
            newBatchList.push({
              ...batch1,
              batchNumber: helpers.tag.trim(batch1.batchNumber)
            });
          }
        });

        setBatchList(newBatchList);
        storeService.session.set.movementBatchList(newBatchList);
      } else if (helpers.option.selectionMethod.isIndividual(addByBatchOrIndividual)) {
        if (helpers.option.selectionMethod.isHolding(selectionMethod)) {
          const removedAnimals = modalAnimalsInitial
            .filter((item1) => !modalAnimals.find((item2) => item2.tagNumber === item1.tagNumber));

          const addedAnimals = modalAnimals
            .filter((item1) => !modalAnimalsInitial.find((item2) => item2.tagNumber === item1.tagNumber))
            .filter((item1) => !animalsToChange.find((item2) => item2.tagNumber === item1.tagNumber));

          const newAnimalsToChange = [
            ...animalsToChange.filter((item1) => !removedAnimals.find((item2) => item2.tagNumber === item1.tagNumber)),
            ...addedAnimals.map((item) => ({
              ...item,
              onHolding: true
            }))
          ];

          setAnimalsToChange(newAnimalsToChange);
          storeService.session.set.movementAnimalsBeingMoved(newAnimalsToChange);
        } else {
          // let selectedAnimals;
          // if (helpers.option.selectionMethod.isTagNumberRange(selectionMethod)) {
          //   selectedAnimals = modalAnimals.filter((item) => item.available && item.added);
          // } else {
          //   selectedAnimals = modalAnimals.filter((item) => item.added);
          // }
          const selectedAnimals = modalAnimals.filter((item) => item.added);
          const newAnimalsToChange = cloneDeep(animalsToChange);

          selectedAnimals.forEach((animal1) => {
            const existingAnimal = newAnimalsToChange.find((animal2) => helpers.tag.trim(animal2.tagNumber) === helpers.tag.trim(animal1.tagNumber));
            if (!existingAnimal) {
              newAnimalsToChange.push({
                ...animal1,
                tagNumber: helpers.tag.trim(animal1.tagNumber)
              });
            }
          });

          setAnimalsToChange(newAnimalsToChange);
          storeService.session.set.movementAnimalsBeingMoved(newAnimalsToChange);
        }
      }

      modal.closeAndReset();
    },

    tagApplicationDate: (date) => {
      setApplicationDate(date);
    },

    yearOfBirth: (year) => {
      setYearOfBirth(year);
      storeService.session.set.movementYearOfBirth(year);
    },

    upload: (uploadedTags) => {
      setModalHasChanges(true);

      setModalAnimals([
        ...modalAnimals,
        ...uploadedTags
          .filter((el) => animalsToChange && !animalsToChange.some((animal) => animal.tagNumber === el))
          .filter((el) => !modalAnimals.some((animal) => animal.tagNumber === el.tagNumber))
          .map((animal) => ({
            ...animal,
            added: true
          }))
      ]);
    }
  };

  // const tagAllocationEnabled = () => helpers.option.movement.isOff(movementData.movementType) &&
  //   !helpers.species.isCattleId(species.id) &&
  //   !helpers.species.isDeerId(species.id) &&
  //   animalsToChange.length > 0 ;

  const tagAllocationEnabled = () => false;

  const userCannotContinue = () => {
    if ((!batchList || batchList.length === 0) && (!animalsToChange || animalsToChange.length === 0)) {
      return true;
    }

    if (
      (
        recordAnimalTagAllocationDetails &&
        moment(applicationDate).isAfter(movementData.departureDate)
      ) ||
      yearOfBirth > moment(applicationDate, 'DD/MM/YYYY').year() ||
      dateError
    ) {
      return true;
    }

    // return (
    //   recordAnimalDetails &&
    //   yearOfBirth > moment(applicationDate, 'DD/MM/YYYY').year()
    // ) ||
    // dateError;
  };

  const submitValues = () => {
    setAnimalDetails(storeService.session.get.movementSelectedAnimalDetails());
  };

  const nextPage = () => {
    submitValues();
    setDataChanged();

    history.push(fromSummary ? Routing.movementsSummary : Routing.movementsStep3);
    helpers.scrollToTop();
  };

  const clearTaggingAndAnimalAllocationDetails = () => {
    setApplicationDate(null);
    setYearOfBirth(null);
    setBreedId(null);
    // setGender(null);
    storeService.session.remove.movementRecordAnimalDetails();
    storeService.session.remove.movementRecordTagAllocationDetails();
    storeService.session.remove.movementTagApplicationDate();
    storeService.session.remove.movementYearOfBirth();
    storeService.session.remove.movementBreed();
    storeService.session.remove.movementGender();
  };

  useEffect(() => {
    const sessionBatchList = storeService.session.get.movementBatchList();
    const sessionRecordAnimalDetails = storeService.session.get.movementRecordAnimalDetails();
    const sessionRecordTagAllocationDetails = storeService.session.get.movementRecordTagAllocationDetails();
    const selectedAnimalSessionData = storeService.session.get.movementAnimalsBeingMoved();

    if (selectedAnimalSessionData) {
      setAnimalsToChange(selectedAnimalSessionData);
    }

    if (sessionBatchList) {
      setBatchList(sessionBatchList);
    }

    // if (sessionRecordAnimalDetails) {
    //   setRecordAnimalDetails(true);
    // }

    if (sessionRecordTagAllocationDetails) {
      setRecordAnimalTagAllocationDetails(true);
    }

    if (sessionRecordAnimalDetails || sessionRecordTagAllocationDetails) {
      const sessionTagApplicationDate = storeService.session.get.movementTagApplicationDate();
      const sessionYearOfBirth = storeService.session.get.movementYearOfBirth();
      const sessionBreed = storeService.session.get.movementBreed();
      // const sessionGender = storeService.session.get.movementGender();

      setApplicationDate(sessionTagApplicationDate);
      setYearOfBirth(sessionYearOfBirth);
      setBreedId(sessionBreed && sessionBreed.id ? sessionBreed.id : null);
      // setGender(sessionGender);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModalHasChanges(!isEqual(
      modalAnimals.map((item) => item.tagNumber).sort(),
      modalAnimalsInitial.map((item) => item.tagNumber).sort()
    ));
  }, [modalAnimals, modalAnimalsInitial]);

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      batch: {
        ...prevState.batch,
        [constants.pagination.bottom]: {
          ...prevState.batch[constants.pagination.bottom],
          total: batchList?.length
        }
      }
    }));
  }, [batchList]);

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      individual: {
        ...prevState.individual,
        [constants.pagination.bottom]: {
          ...prevState.individual[constants.pagination.bottom],
          total: animalsToChange?.length
        }
      }
    }));
  }, [animalsToChange]);

  return (
    <>
      {ready &&
        <>
          {modalVisible &&
            <>
              <Modal
                footerContent={modal.getFooterContent()}
                headerContent={modal.getHeaderContent()}
                id={modal.getModalId()}
                modalClose={() => modal.hide(false)}
                submitChanges={handleChanges.saveChanges}
                submitDisabled={modal.saveDisabled()}
              >
                {modal.getContent()}
              </Modal>

              {confirmCloseModal &&
                <ModalConfirm
                  currentOpenModalId={modal.getModalId()}
                  id="confirmCloseModal"
                  modalClose={() => setConfirmCloseModal(false)}
                  modalConfirm={() => modal.hide(true)}
                  modalMessage="warning.unsavedChanges2"
                  modalTitle="warning.youHaveUnsavedChanges"
                />
              }
            </>
          }

          <h2 className="h2">{t('label.addAnimals')}</h2>

          <AboutPanel
            descriptions={[
              { text: 'about.movementsStep2.desc1' },
              { text: 'about.movementsStep2.desc2' },
              { text: 'about.movementsStep2.desc3' },
              { text: 'about.movementsStep2.desc4' },
              { text: 'about.movementsStep2.desc5' }
            ]}
            title="about.genericTitle.step"
          />

          {(batchList.length > 0 || animalsToChange.length > 0) &&
            <div className={classes.animalLists}>
              <div className={classes.accordionHeader}>
                {t('label.totalAnimals')}: {batchList.reduce((sum, item) => {
                  sum += parseInt(item.animalTotal);
                  return sum;
                }, 0) + animalsToChange.length}
              </div>

              {batchList.length > 0 &&
                <details className="accordion">
                  <summary>
                    <h2>{sprintf(
                      t('label.batchAnimalsInMovement'),
                      batchList.reduce((total, batch) => total + parseInt(batch.animalTotal), 0))
                    }</h2>
                  </summary>
                  <div>
                    <Table
                      columns={tableColumns.movements.batch({ actionFunction: handleChanges.removeBatch, speciesId: species.id, isAmend })}
                      data={batchList}
                      dataProvided={true}
                      initialSortBy={initialSorting.movements.batch.accessor}
                      initialSortDirection={initialSorting.movements.batch.direction}
                      paginationParams={paginationParams.batch}
                      removeAll={handleChanges.removeBatchesAll}
                      setData={setBatchList}
                      setModal={setModal}
                    />
                  </div>
                </details>
              }

              {animalsToChange.length > 0 &&
                <details className="accordion">
                  <summary>
                    <h2>{sprintf(
                      t('label.individualAnimalsInMovement'),
                      animalsToChange.length)
                    }</h2>
                  </summary>
                  <div>
                    <Table
                      columns={helpers.species.isDeerId(species.id)
                        ? tableColumns.movements.individual.deer({ actionFunction: handleChanges.removeAnimal, sortable: true, isAmend })
                        : tableColumns.movements.individual.nonDeer({ actionFunction: handleChanges.removeAnimal, sortable: true, isAmend })
                      }
                      data={animalsToChange}
                      dataProvided={true}
                      initialSortBy={initialSorting.movements.individual.accessor}
                      initialSortDirection={initialSorting.movements.individual.direction}
                      paginationParams={paginationParams.individual}
                      removeAll={handleChanges.removeAnimalsAll}
                      setData={setAnimalsToChange}
                      setModal={setModal}
                    />
                  </div>
                </details>
              }
            </div>
          }

          {(batchList.length === 0 && animalsToChange.length === 0) &&
            <h3 className="h3">{t('label.howDoYouWantToAddAnimals')}</h3>
          }
          {(batchList.length > 0 || animalsToChange.length > 0) &&
            <h3 className="h3">{t('label.addMoreAnimals')}</h3>
          }

          <div className={classes.addAnimals}>
            {!helpers.species.isCattleId(species.id) &&
              <Button
                buttonType="iconLink"
                icon={iconBatch}
                label="label.addAnimalsByBatch"
                onClick={() => {
                  handleChanges.addAnimalsByBatchOrIndividual('batch');
                  modal.show(constants.option.selectionMethod.batch);
                }}
              />
            }
            <Button
              buttonType="iconLink"
              icon={iconIndividual}
              label="label.addIndividualAnimals"
              onClick={() => handleChanges.addAnimalsByBatchOrIndividual(constants.option.selectionMethod.individual)}
            />
          </div>

          {helpers.option.selectionMethod.isIndividual(addByBatchOrIndividual) &&
            <>
              <div className={classes.selectionMethods}>
                <h3 className="h3">{t('label.howDoYouWantToEnterIndividualAnimals')}</h3>
                {!helpers.option.movement.isOn(movementData.movementType) &&
                  <Button
                    buttonType="iconLink"
                    icon={iconHolding}
                    label="label.selectFromAnimalsOnYourHolding"
                    onClick={() => modal.show(constants.option.selectionMethod.holding)}
                  />
                }
                <Button
                  buttonType="iconLink"
                  icon={iconFile}
                  label="label.uploadAFile"
                  onClick={() => modal.show(constants.option.selectionMethod.uploadFile)}
                />
                {!helpers.species.isCattleId(species.id) &&
                  <Button
                    buttonType="iconLink"
                    icon={iconTagRange}
                    label="label.addATagNumberRange"
                    onClick={() => modal.show(constants.option.selectionMethod.tagNumberRange)}
                  />
                }
                <Button
                  buttonType="iconLink"
                  icon={iconManualEntry}
                  label="label.enterTagNumbersManually"
                  onClick={() => modal.show(constants.option.selectionMethod.manualTagNumbers)}
                />
              </div>
            </>
          }

          {/*{helpers.option.movement.isOn(movementData.movementType) &&*/}
          {/*  !helpers.species.isCattleId(species.id) &&*/}
          {/*  !helpers.species.isDeerId(species.id) &&*/}
          {/*  animalsToChange.length > 0 &&*/}
          {/*  <RecordAnimalDetails*/}
          {/*    breedId={breedId}*/}
          {/*    gender={gender}*/}
          {/*    recordAnimalDetails={recordAnimalDetails}*/}
          {/*    setBreedId={handleChanges.breedId}*/}
          {/*    setGender={handleChanges.gender}*/}
          {/*    setRecordAnimalDetails={handleChanges.recordAnimalDetails}*/}
          {/*    setYearOfBirth={handleChanges.yearOfBirth}*/}
          {/*    yearOfBirth={yearOfBirth}*/}
          {/*  />*/}
          {/*}*/}

          {tagAllocationEnabled() &&
            <RecordAnimalTagAllocation
              applicationDate={applicationDate}
              breedId={breedId}
              movementData={movementData}
              movementType={movementData.movementType}
              recordAnimalTagAllocationDetails={recordAnimalTagAllocationDetails}
              setApplicationDate={handleChanges.tagApplicationDate}
              setBreedId={handleChanges.breedId}
              setDataChanged={setDataChanged}
              setDateError={setDateError}
              setRecordAnimalTagAllocationDetails={handleChanges.recordTagAllocationDetails}
              setYearOfBirth={handleChanges.yearOfBirth}
              yearOfBirth={yearOfBirth}
            />
          }

          <div className="padAll10" />
          <Button
            buttonType="primary"
            disabled={userCannotContinue()}
            label="button.continue"
            onClick={nextPage}
          />
        </>
      }
    </>
  );
};

MovementStep2.propTypes = {
  fromSummary: PropTypes.bool,
  isAmend: PropTypes.bool,
  movementData: PropTypes.object,
  setAnimalDetails: PropTypes.func,
  setDataChanged: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  species: PropTypes.object
};

export default MovementStep2;
