import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/base/radioButton/radioButton.module.scss';
import PropTypes from 'prop-types';

const RadioButton = ({
  classNames,
  defaultChecked,
  disabled,
  id,
  inline,
  label,
  name,
  onChange,
  value
}) => {
  const { ready, t } = useTranslation();

  const componentClassNames = [
    classes.radioButton,
    disabled ? classes.disabled : '',
    inline ? classes.inline : '',
    defaultChecked ? classes.checked : '',
    ...(classNames ? classNames.map((className) => classes[className]) : [])
  ].join(' ').trim();

  return (
    <>
      {ready &&
        <div className={componentClassNames}>
          <label htmlFor={id}>
            <span dangerouslySetInnerHTML={{ __html: t(label) }} />
            <input
              checked={defaultChecked}
              disabled={disabled}
              id={id}
              name={name}
              onChange={onChange}
              type="radio"
              value={value}
            />
            <span className={classes.checkmark} />
          </label>
        </div>
      }
    </>
  );
};

RadioButton.propTypes = {
  classNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export default RadioButton;
