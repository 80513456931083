import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isCancel } from 'services/bff';
import axios from 'axios';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import CheckboxGroup from 'backOfficeComponents/base/checkboxGroup/checkboxGroup';
import ErrorLabel from 'components/base/errorLabel/errorLabel';

const Exemptions = ({
  groupLabel,
  hasErrors,
  needUpdate,
  options,
  panelTitle,
  permitMove,
  setDataChanged,
  setModal,
  setOptions,
  speciesId
}) => {
  const { ready, t } = useTranslation();

  const storeExemptions = (exemptions) => {
    setOptions(exemptions);
    setDataChanged();
    // only save the selected checkbox to session store
    storeService.session.set.movementExemptions(exemptions.filter((exemption) => exemption.value));
  };

  const exemptionChange = ({ target }) => {
    const { id } = target;
    const selectedExemptions = options.map((o) => ({
      ...o,
      value: id === String(o.id) ? !o.value : o.value
    }));

    storeExemptions(selectedExemptions);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const sessionMovementExemptions = storeService.session.get.movementExemptions();

    helpers.get.processingFlags(source.token, constants.option.requestType.paperMovementAdd, speciesId)
      .then(({ data }) => {
        if (helpers.response.isValid(data, setModal)) {
          setOptions(data.map((item) => {
            const isPermitMoveExemption = permitMove && Object.values(constants.permitMovesMandatory).some((mandatoryExemption) => mandatoryExemption.id === item.id);

            return {
              ...item,
              value: sessionMovementExemptions?.some((ex) => ex.id === item.id) || isPermitMoveExemption,
              disabled: isPermitMoveExemption
            };
          }));
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          errors.BFF(error, setModal);
        }
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speciesId, needUpdate]);

  return (
    <>
      {ready &&
        <details className="accordion" open={hasErrors}>
          <summary>
            <h2><i className="bi bi-exclamation-triangle" /> {t(panelTitle)}</h2>
          </summary>
          <div>
            {options?.length === 0 &&
              <ErrorLabel
                isWarning={true}
                label="label.fetchingData"
              />
            }
            {options?.length > 0 &&
              <CheckboxGroup
                // disabledOptions={mandatoryOptions?.length ? mandatoryOptions : null}
                id="exemptionsGroup"
                legendLabel={groupLabel}
                name=""
                objects={options}
                onChange={exemptionChange}
                reduceSpace={true}
              />
            }
          </div>
        </details>
      }
    </>
  );
};

Exemptions.propTypes = {
  groupLabel: PropTypes.string,
  hasErrors: PropTypes.string,
  needUpdate: PropTypes.bool,
  options: PropTypes.array,
  panelTitle: PropTypes.string,
  permitMove: PropTypes.bool,
  setDataChanged: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setOptions: PropTypes.func,
  speciesId: PropTypes.number
};

export default Exemptions;
