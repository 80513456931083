import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classes from 'backOfficeComponents/header/headerBackOffice.module.scss';
import Routing from 'routing';
import storeService from 'services/storeService';

const useOutsideAlerter = (ref, toggleUserDropdown) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleUserDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

const HeaderBackOffice = ({
  menuOpen
}) => {
  const { ready, t } = useTranslation();

  const wrapperRef = useRef(null);

  const username = storeService.cookie.get.username();

  const [userDropDown, setUserDropDown] = React.useState(false);

  const toggleUserDropdown = (state) => {
    if (state !== undefined) {
      setUserDropDown(state);
    } else {
      setUserDropDown(!userDropDown);
    }
  };

  useOutsideAlerter(wrapperRef, toggleUserDropdown);

  return (
    <>
      {ready &&
        <header className={'container ' + menuOpen ? classes.header + ' ' + classes.closed : classes.header}>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className={classes.logo} />
            </div>
            <div className={'col-sm-12 col-lg-6 d-lg-block ' + classes.userInfo}>
              <div
                className={classes.user}
                onClick={toggleUserDropdown}
                onKeyPress={toggleUserDropdown}
                ref={wrapperRef}
                role="button"
                tabIndex={0}
              >
                <i className={`bi bi-person-circle ${classes.icon}`} />
                {username}

                {userDropDown &&
                  <div className={classes.userOptions}>
                    {/*<Link className={classes.option} to="#">*/}
                    {/*  {t('label.changePassword')}*/}
                    {/*</Link>*/}
                    <Link
                      aria-label={t('label.logout')}
                      className={classes.option}
                      onBlur={toggleUserDropdown}
                      role="button"
                      tabIndex={0}
                      to={Routing.logout}
                    >
                      <i className={`bi bi-unlock ${classes.icon}`} />
                      {t('label.logout')}
                    </Link>
                  </div>
                }
              </div>
            </div>
          </div>
        </header>
      }
    </>
  );
};

HeaderBackOffice.propTypes = {
  menuOpen: PropTypes.bool.isRequired
};

export default HeaderBackOffice;
